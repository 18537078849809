/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeResponse,
    EmployeeResponseFromJSON,
    EmployeeResponseFromJSONTyped,
    EmployeeResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateInterviewAudioFileUploadUrlsResponse
 */
export interface CreateInterviewAudioFileUploadUrlsResponse {
    /**
     * 
     * @type {EmployeeResponse}
     * @memberof CreateInterviewAudioFileUploadUrlsResponse
     */
    suggest?: EmployeeResponse;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewAudioFileUploadUrlsResponse
     */
    partUploadId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInterviewAudioFileUploadUrlsResponse
     */
    partUploadUrls: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewAudioFileUploadUrlsResponse
     */
    tmpFilename: string;
}

export function CreateInterviewAudioFileUploadUrlsResponseFromJSON(json: any): CreateInterviewAudioFileUploadUrlsResponse {
    return CreateInterviewAudioFileUploadUrlsResponseFromJSONTyped(json, false);
}

export function CreateInterviewAudioFileUploadUrlsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInterviewAudioFileUploadUrlsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suggest': !exists(json, 'suggest') ? undefined : EmployeeResponseFromJSON(json['suggest']),
        'partUploadId': json['partUploadId'],
        'partUploadUrls': json['partUploadUrls'],
        'tmpFilename': json['tmpFilename'],
    };
}

export function CreateInterviewAudioFileUploadUrlsResponseToJSON(value?: CreateInterviewAudioFileUploadUrlsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suggest': EmployeeResponseToJSON(value.suggest),
        'partUploadId': value.partUploadId,
        'partUploadUrls': value.partUploadUrls,
        'tmpFilename': value.tmpFilename,
    };
}


