import {
  GetRuleSuggestionsByCategoryIdRequest,
  GetRuleSuggestionsBySubCategoryIdRequest,
  RuleSuggestionResponse,
  RuleSuggestionResponseMetadata,
} from "@amecloud/api-client/src-openapi-autogen";

import { defaultApi } from "../defaultApi";

import { useQueryResult } from "./utils/parseResult";
import { UseQueryOptions } from "./utils/types";

export interface RuleSuggestionModel {
  esQuery: string;
  metadata: RuleSuggestionResponseMetadata;
}

export namespace RuleSuggestionModel {
  export const fromResponse = (ruleSuggestion: RuleSuggestionResponse): RuleSuggestionModel => {
    return {
      esQuery: ruleSuggestion.esQuery,
      metadata: ruleSuggestion.metadata,
    };
  };
}

export const useGetRuleSuggestionsByCategoryId = (
  args: GetRuleSuggestionsByCategoryIdRequest,
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.getRuleSuggestionsByCategoryId.useQuery(args);
  return useQueryResult({
    result,
    fromResponse: (ruleSuggestions: RuleSuggestionResponse[]) => ruleSuggestions.map(RuleSuggestionModel.fromResponse),
    onError: options?.onError,
  });
};

export const useGetRuleSuggestionsBySubCategoryId = (
  args: GetRuleSuggestionsBySubCategoryIdRequest,
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.getRuleSuggestionsBySubCategoryId.useQuery(args);
  return useQueryResult({
    result,
    fromResponse: (ruleSuggestions: RuleSuggestionResponse[]) => ruleSuggestions.map(RuleSuggestionModel.fromResponse),
    onError: options?.onError,
  });
};
