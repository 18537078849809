import React, { useCallback, useMemo, useState, useContext } from "react";

import { useAbility } from "@casl/react";
import { isBefore, addDays, max } from "date-fns";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { Period, useCarryOverEmployeesIntoNextPeriod } from "../../../../store/hooks/periods";
import { AmeCheckbox } from "../../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeDialog } from "../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { AbilityContext, ACTION, SUBJECT } from "../Authorizer";
import { DataSourceContext } from "../DataSourceContext";

export const CarryOverDialog: React.FC = () => {
  const { latestPeriod } = useContext(DataSourceContext);
  const [ignoreUntilString, setIgnoreUntilString] = useLocalStorage<string | null>("ignoreUntilString", null);

  const ignoreUntil = useMemo(() => {
    const parsedDate = new Date(ignoreUntilString || 0);
    const ignoreUntilFromLocalStorage = isNaN(parsedDate.getTime()) ? new Date(0) : parsedDate;
    const endDate30DaysLater = addDays(latestPeriod.endDate, 30);
    return max([ignoreUntilFromLocalStorage, endDate30DaysLater]);
  }, [ignoreUntilString, latestPeriod.endDate]);

  const ability = useAbility(AbilityContext);

  if (!ability.can(ACTION.manage, SUBJECT.all)) {
    return null;
  }
  return (
    <>
      {isBefore(ignoreUntil, new Date()) ? (
        <CarryOverDialogContent period={latestPeriod} setIgnoreUntil={setIgnoreUntilString} />
      ) : null}
    </>
  );
};

interface Props {
  period: Period;
  setIgnoreUntil: (checkDate: string | null) => void;
}

const CarryOverDialogContent: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(true);
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const carryOverEmployees = useCarryOverEmployeesIntoNextPeriod();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onCheckChange = useCallback(() => {
    setIsChecked(() => !isChecked);
  }, [isChecked]);
  const onClose = useCallback(() => {
    if (isChecked) {
      props.setIgnoreUntil(addDays(new Date(), 7).toLocaleDateString());
    }
    setOpen(false);
  }, [setOpen, isChecked, props]);

  const onSubmit = useCallback(async () => {
    const result = await carryOverEmployees({ carryOverEmployeesToNextPeriodRequestBody: {} });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "来期データの作成に成功しました。" });
      props.setIgnoreUntil(null);
    }
  }, [carryOverEmployees, enqueueSuccessSnackbar, props]);
  return (
    <AmeDialog open={open} onClose={onClose}>
      <AmeDialogLayout
        title="新しい会計年度を作成してください。"
        onSubmit={onSubmit}
        onClose={onClose}
        variant="secondary"
        submitButtonText="確定"
      >
        <AmeTypography>{`今期が${props.period.endDate.getFullYear()}年${String(
          props.period.endDate.getMonth() + 1,
        ).padStart(2, "0")}月${props.period.endDate
          .getDate()
          .toString()
          .padStart(2, "0")}日に終了しています。`}</AmeTypography>
        <AmeTypography>来期のデータを最新の従業員リストを利用して作成しますか？</AmeTypography>
        <AmeBox sx={{ display: "flex" }}>
          <AmeCheckbox checked={isChecked} onChange={onCheckChange} />
          <Spacer width="8px"></Spacer>
          <AmeTypography>1週間このダイアログを表示しない</AmeTypography>
        </AmeBox>
      </AmeDialogLayout>
    </AmeDialog>
  );
};
