import React from "react";

import Slider from "@mui/material/Slider";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

interface Props {
  min?: number;
  max?: number;
  value?: number;
  onChange?: (value: number) => void;
  valueLabelDisplay?: "auto" | "on" | "off";
  valueLabelFormat?: (value: number) => string;
}

export const AmeSlider: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const [cursor, setCursor] = React.useState<number | null>(null);

  return (
    <Slider
      min={props.min}
      max={props.max}
      value={cursor === null ? props.value : cursor}
      onChange={(_, value) => setCursor(value as unknown as number)}
      onChangeCommitted={(_, value) => {
        setCursor(null);
        props.onChange && props.onChange(value as unknown as number);
      }}
      valueLabelDisplay={props.valueLabelDisplay}
      valueLabelFormat={props.valueLabelFormat}
      sx={{
        padding: "8px 0",
        "& .MuiSlider-rail": { opacity: "none", backgroundColor: theme.common.background.gray40 },
        "& .MuiSlider-thumb": { height: "12px", width: "12px", boxShadow: "none" },
      }}
    />
  );
};
