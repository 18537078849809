import React, { useEffect } from "react";

import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { RootState } from "../../../../ducks";

export const SnackbarViaReduxConsumer: React.FC = () => {
  const snackbar = useSelector((state: RootState) => state.snackbar);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (snackbar.message) {
      switch (snackbar.message.type) {
        case "success":
          enqueueSnackbar(JSON.stringify(snackbar.message));
          return;
        case "error":
          enqueueSnackbar(JSON.stringify(snackbar.message), { persist: true });
          return;
      }
    }
  }, [snackbar, enqueueSnackbar]);
  return null;
};
