import { SharedSurveySheetResponse, SurveyQuestionResponse, SurveySheetResponse } from "../store/autogenApi";

export class SurveySheet {
  constructor(
    readonly surveySheetId: string | undefined,
    readonly displayName: string,
    readonly dueDateTime: Date,
    readonly emailSubject: string | undefined,
    readonly emailBody: string | undefined,
    readonly title: string,
    readonly description: string,
    readonly questions: SurveyQuestionResponse[],
  ) {}

  public static fromResponse(response: SurveySheetResponse | SharedSurveySheetResponse): SurveySheet {
    return new SurveySheet(
      "surveySheetId" in response ? response.surveySheetId : undefined,
      response.displayName,
      new Date(response.dueDateTime),
      response.emailSubject,
      response.emailBody,
      response.title,
      response.description,
      response.questions,
    );
  }
}
