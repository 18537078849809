import React, { useMemo } from "react";

import { Checkbox, CheckboxProps } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
export type AmeCheckboxProps = Pick<CheckboxProps, "checked" | "disabled" | "onChange" | "size" | "id" | "name">;
import { CheckboxChecked } from "../icon/CheckBoxChecked";
import { CheckboxEmpty } from "../icon/CheckBoxEmpty";

export const AmeCheckbox = React.forwardRef<HTMLInputElement, AmeCheckboxProps>(
  ({ name, size, checked, disabled, onChange, id }, ref) => {
    const style = useCheckboxStyle();
    return (
      <Checkbox
        sx={style}
        size={size}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        id={id}
        inputRef={ref}
        name={name}
        icon={<CheckboxEmpty />}
        checkedIcon={<CheckboxChecked />}
      />
    );
  },
);

AmeCheckbox.displayName = AmeCheckbox.name;

export const useCheckboxStyle = (): SystemStyleObject => {
  const theme = useAmeTheme();
  return useMemo(() => {
    return {
      "&.MuiCheckbox-root": {
        padding: 0,
        color: theme.common.border.gray40,
      },
      "&.Mui-checked": {
        color: theme.brand.secondary[100],
      },
    };
  }, [theme]);
};
