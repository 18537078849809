import React, { useCallback } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { endOfDay, formatISO } from "date-fns";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SurveySendingMethod } from "../../../../../models/constants/values";
import { useCreateSurvey } from "../../../../../store/hooks/surveys";
import { useGetSurveyQuestions } from "../../../../../store/hooks/surveys-questions";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { SurveyForm } from "../../../../organisms/surveys/create/SurveyForm";

const SurveyCreatePage: React.FC = () => {
  const surveyQuestionsQuery = useGetSurveyQuestions();
  const createSurvey = useCreateSurvey();
  const navigate = useNavigate();
  const search = Route.useSearch();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onClickCancel = useCallback(async () => {
    await navigate({ to: "/surveys", search: { page: 1 } });
  }, [navigate]);

  const handleSubmit = useCallback(
    async (
      method: SurveySendingMethod,
      title: string,
      description: string,
      emailSubject: string,
      emailBody: string,
      dueDateTime: Date,
    ) => {
      const useQrCode = method === "both" || method === "qr-code";
      const sendEmail = method === "both" || method === "email";
      const result = await createSurvey({
        createSurveyRequestBody: {
          sendEmail,
          useQrcode: useQrCode,
          displayName: title,
          surveyTitle: title,
          surveyDescription: description,
          emailSubject: sendEmail ? emailSubject : undefined,
          emailBody: sendEmail ? emailBody : undefined,
          dueDateTime: formatISO(endOfDay(dueDateTime)),
        },
      });
      if (result.isSuccess) {
        await navigate({ to: "/surveys", search: { page: 1 } });
        return;
      }
      enqueueErrorSnackbar({ title: "アンケートの作成に失敗しました。" });
    },
    [createSurvey, navigate, enqueueErrorSnackbar],
  );

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "アンケート一覧", to: "/surveys" }]}
        currentTitle="アンケート新規作成"
      />
      <WaitForSuccess queryState={surveyQuestionsQuery}>
        {(questions) => (
          <SurveyForm
            method={search.method}
            questions={questions}
            onClickCancel={onClickCancel}
            handleSubmit={handleSubmit}
          />
        )}
      </WaitForSuccess>
    </PageLayoutWrapper>
  );
};

type QueryParams = {
  method: SurveySendingMethod;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/surveys/create")({
  component: SurveyCreatePage,
  validateSearch: (search: Partial<QueryParams>): QueryParams => {
    return {
      method: search.method || "both",
    };
  },
});
