import React from "react";

import { CSS_SP_MEDIA_QUERY } from "../../../../../../utils/mediaQuery";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

export const LoginPageWrapper: React.FC<Props> = ({ children }) => {
  return <AmeBox sx={{ width: "440px", [CSS_SP_MEDIA_QUERY]: { width: "345px" } }}>{children}</AmeBox>;
};
