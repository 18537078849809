import { sum } from "@amecloud/common-utils/src/utils/ArrayUtils";
import { groupByWithTransform } from "@amecloud/common-utils/src/utils/CollectionUtils";

import {
  HrInterviewCountReportResponse,
  HrInterviewQualityReportResponse,
  HrInterviewScoreReportResponse,
} from "../../store/autogenApi";

/**
 * 面談品質レポートのデータを保持するクラス
 *
 * グラフ用のデータを生成する責務も持つ
 */
export class HrInterviewQualityReport {
  private constructor(
    private readonly rateReport: HrInterviewCountReportResponse,
    private readonly scoreReport: HrInterviewScoreReportResponse,
  ) {}

  public static fromResponse(response: HrInterviewQualityReportResponse): HrInterviewQualityReport {
    return new HrInterviewQualityReport(response.rateReport, response.scoreReport);
  }

  public get totalInterviewCount() {
    return sum(this.rateReport.data.map((d) => d.interviewCount));
  }

  public generateScoreQualitySeriesData() {
    const evaluationTypeSeriesMap = this.getEvaluationTypeSeriesMap();
    return {
      overallSeries: this.scoreReport.data.map((d) => d.totalAverageScore),
      evaluationTypeSeries: Object.values(evaluationTypeSeriesMap),
      evaluationTypeNames: Object.keys(evaluationTypeSeriesMap),
      seriesLabels: this.scoreReport.data.map((data) => data.label),
    };
  }

  public generateInterviewCountSeriesData() {
    return this.rateReport.data.map((d) => ({
      interviewCount: d.interviewCount,
      completedInterviewCount: d.completedInterviewCount,
      label: d.label,
      rate: d.interviewCount > 0 ? d.completedInterviewCount / d.interviewCount : 0,
    }));
  }

  private getEvaluationTypeSeriesMap(): Record<string, (number | null)[]> {
    const mergedEvaluationTypeScoreList = this.scoreReport.data.flatMap((d) => d.averageScoreList);
    /**
     * data配列の先頭要素から、各評価項目ごとの平均点数の配列に値を詰めてくれるので、
     * 元々のdata配列の順序と評価項目に紐づく点数配列の順序が一致するようになっている。
     *
     * 詳細はgroupByWithTransformの実装を参照してください。
     */
    return groupByWithTransform(
      mergedEvaluationTypeScoreList,
      (d) => d.evaluationItem,
      (d) => d.averageScore,
    );
  }
}
