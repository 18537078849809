interface AssertOptions {
    throwError?: (message: string) => Error;
    message?: string;
}
export function requireNonEmpty(value: string | undefined | null, message?: string): string {
    if (!value) {
        throw Error(message ?? `引数に null もしくは undefined もしくは空文字列な値が渡されました。:${value}`);
    }
    return value;
}

export function requireNonNull<T>(value: T | null | undefined, message?: string): T {
    if (value === null || value === undefined) {
        throw Error(message ?? `引数に null もしくは undefined な値が渡されました。:${value}`);
    }
    return value;
}

export function mustBeTrue(value: boolean, message?: string): void {
    if (!value) {
        throw Error(message ?? `引数が false でした。:${value}`);
    }
}

export function requireExactlyOneElement<T>(values: T[], message?: string): T {
    if (values.length == 0 || values.length > 1) {
        throw Error(message ?? `valuesの長さはちょうど1である必要がありますが、${values.length}でした。: ${values}`);
    }
    return values[0];
}

export function requireStringLiteral<StringLiteral extends string>(
    literal: unknown,
    allowedLiterals: readonly StringLiteral[],
    literalName: string,
): StringLiteral {
    if (allowedLiterals.includes(literal as StringLiteral)) {
        return literal as StringLiteral;
    }
    throw Error(`${literalName} must be any of [${allowedLiterals}], but it was [${literal}]`);
}

export function isNotUndefined<T>(value: T | undefined): value is T {
    return value !== undefined;
}

export const isString = (value: unknown | undefined): value is string => {
    return value != undefined && (typeof value === "string" || value instanceof String);
};

export function requireSameLength(array1: unknown[], array2: unknown[], options?: AssertOptions) {
    if (array1.length === array2.length) {
        return;
    }
    throwError(
        options?.message ??
            `配列の長さが一致していません。${JSON.stringify({ array1: array1.length, array2: array2.length })}`,
        options?.throwError,
    );
}

function throwError(message: string, throwCustomError?: (message: string) => void): void {
    if (throwCustomError) {
        throwCustomError(message);
    } else {
        throw new Error(message);
    }
}
