import { autogenApi } from "./autogenApi";

const SETTING_TAG = "SETTING" as const;
const CATEGORY_TAG = "CATEGORY" as const;
const SUB_CATEGORY_TAG = "SUB_CATEGORY" as const;
const COMPUTATION_TAG = "COMPUTATION_TASK" as const;
const RULE_TAG = "RULE" as const;
const LABEL_TAG = "LABEL" as const;
const PERIOD_TAG = "PERIOD" as const;
const EMPLOYEE_TAG = "EMPLOYEE" as const;
const INTERVIEW_FILE_TAG = "INTERVIEW_FILE" as const;
const EX_EMPLOYEE_INTERVIEW = "EX_EMPLOYEE_INTERVIEW" as const;
const NOTE_TAG = "NOTE" as const;
const SURVEY_TAG = "SURVEY" as const;
const SURVEY_QUESTION_TAG = "SURVEY_QUESTION_TAG" as const;
const USER_TAG = "USER_TAG" as const;
const USER_GROUP_TAG = "USER_GROUP" as const;
const TRANSCRIPTION_TAG = "TRANSCRIPTION" as const;
const INTERVIEW_TYPE_TAG = "INTERVIEW_TYPE" as const;
const INTERVIEW_FORMAT_TAG = "INTERVIEW_FORMAT" as const;
const INTERVIEW_FORMAT_CONTENT_TAG = "INTERVIEW_FORMAT_CONTENT" as const;
const HR_INTERVIEW_TAG = "HR_INTERVIEW" as const;
const HR_INTERVIEW_FILE_TAG = "HR_INTERVIEW_FILE" as const;
const FEEDBACK_QUESTIONS_TAG = "FEEDBACK_QUESTIONS_TAG" as const;

const TAGS = [
  SETTING_TAG,
  CATEGORY_TAG,
  SUB_CATEGORY_TAG,
  COMPUTATION_TAG,
  RULE_TAG,
  LABEL_TAG,
  PERIOD_TAG,
  EMPLOYEE_TAG,
  INTERVIEW_FILE_TAG,
  EX_EMPLOYEE_INTERVIEW,
  NOTE_TAG,
  SURVEY_TAG,
  SURVEY_QUESTION_TAG,
  USER_TAG,
  USER_GROUP_TAG,
  TRANSCRIPTION_TAG,
  INTERVIEW_TYPE_TAG,
  INTERVIEW_FORMAT_TAG,
  INTERVIEW_FORMAT_CONTENT_TAG,
  HR_INTERVIEW_TAG,
  HR_INTERVIEW_FILE_TAG,
  FEEDBACK_QUESTIONS_TAG,
] as const;
const LIST_TAG_ID = "LIST";

// TODO: 未定義 or 未実装のエンドポイントがまだあるので、バックエンドの実装が進んだら、見直す。
// 自動生成のAPIクライアントを拡張したもの。一般に、これをフロントエンドでは使う。
export const defaultApi = autogenApi.enhanceEndpoints({
  addTagTypes: TAGS,
  endpoints: {
    getAllCategories: {
      providesTags: (result, _, __) =>
        result
          ? [
              ...result.map((category) => ({ type: CATEGORY_TAG, id: category.categoryId })),
              { type: CATEGORY_TAG, id: LIST_TAG_ID },
            ]
          : [{ type: CATEGORY_TAG, id: LIST_TAG_ID }],
    },
    getCategory: {
      providesTags: (_, __, args) => [{ type: CATEGORY_TAG, id: args.categoryId }],
    },
    createCategory: {
      invalidatesTags: [{ type: CATEGORY_TAG, id: LIST_TAG_ID }],
    },
    updateCategory: {
      invalidatesTags: (_, __, args) => [{ type: CATEGORY_TAG, id: args.categoryId }],
    },
    deleteCategory: {
      invalidatesTags: [{ type: CATEGORY_TAG, id: LIST_TAG_ID }],
    },
    getAllSubCategories: {
      providesTags: [{ type: SUB_CATEGORY_TAG, id: LIST_TAG_ID }],
    },
    getSubCategoryByCategoryId: {
      providesTags: (result, _, __) =>
        result
          ? [
              ...result.map((subCategory) => ({ type: SUB_CATEGORY_TAG, id: subCategory.subCategoryId })),
              { type: SUB_CATEGORY_TAG, id: LIST_TAG_ID },
            ]
          : [{ type: SUB_CATEGORY_TAG, id: LIST_TAG_ID }],
    },
    getSubCategoryBySubCategoryId: {
      providesTags: (_, __, args) => [{ type: SUB_CATEGORY_TAG, id: args.subCategoryId }],
    },
    createSubCategory: {
      invalidatesTags: [{ type: SUB_CATEGORY_TAG, id: LIST_TAG_ID }],
    },
    updateSubCategory: {
      invalidatesTags: (_, __, args) => [{ type: SUB_CATEGORY_TAG, id: args.subCategoryId }],
    },
    deleteSubCategory: {
      invalidatesTags: [{ type: SUB_CATEGORY_TAG, id: LIST_TAG_ID }],
    },
    createRule: {
      invalidatesTags: [
        { type: SUB_CATEGORY_TAG, id: LIST_TAG_ID },
        { type: RULE_TAG, id: LIST_TAG_ID },
        { type: COMPUTATION_TAG },
      ],
    },
    updateRule: {
      invalidatesTags: (_, __, args) => [{ type: RULE_TAG, id: args.ruleId }, { type: COMPUTATION_TAG }],
    },
    deleteRule: {
      invalidatesTags: (_, __, args) => [{ type: RULE_TAG, id: args.ruleId }],
    },
    getRulesByCategoryId: {
      providesTags: (result, _, __) =>
        result
          ? [...result.map((rule) => ({ type: RULE_TAG, id: rule.ruleId })), { type: RULE_TAG, id: LIST_TAG_ID }]
          : [{ type: RULE_TAG, id: LIST_TAG_ID }],
    },
    getStatsByRule: {
      keepUnusedDataFor: 10,
      providesTags: (_, __, args) => [{ type: RULE_TAG, id: args.ruleId }],
    },
    getLabelDocument: {
      providesTags: (result) =>
        result
          ? [
              ...result.documents.map((labelDocument) => ({ type: LABEL_TAG, id: labelDocument.labelId })),
              { type: LABEL_TAG, id: LIST_TAG_ID },
            ]
          : [{ type: LABEL_TAG, id: LIST_TAG_ID }],
    },
    setLabel: {
      invalidatesTags: (_, __, args) => [{ type: LABEL_TAG, id: args.labelId }],
    },
    getSurvey: {
      providesTags: (_, __, args) => [{ type: SURVEY_TAG, id: args.surveyId }],
    },
    getSurveys: {
      providesTags: [{ type: SURVEY_TAG, id: LIST_TAG_ID }],
    },
    createSurvey: {
      invalidatesTags: [{ type: SURVEY_TAG, id: LIST_TAG_ID }],
    },
    getLatestSurvey: {
      providesTags: [{ type: SURVEY_TAG, id: LIST_TAG_ID }],
    },
    updateSurvey: {
      invalidatesTags: (_, __, args) => [
        { type: SURVEY_TAG, id: LIST_TAG_ID },
        { type: SURVEY_TAG, id: args.surveyId },
      ],
    },
    deleteSurvey: {
      invalidatesTags: (_, __, args) => [
        { type: SURVEY_TAG, id: LIST_TAG_ID },
        { type: SURVEY_TAG, id: args.surveyId },
      ],
    },
    updateSurveyStatus: {
      invalidatesTags: (_, __, args) => [
        { type: SURVEY_TAG, id: LIST_TAG_ID },
        { type: SURVEY_TAG, id: args.surveyId },
      ],
    },
    startSurvey: {
      invalidatesTags: (_, __, args) => [
        { type: SURVEY_TAG, id: LIST_TAG_ID },
        { type: SURVEY_TAG, id: args.surveyId },
      ],
    },
    getPeriods: {
      providesTags: [{ type: PERIOD_TAG, id: LIST_TAG_ID }],
    },
    getLatestPeriod: {
      providesTags: [{ type: PERIOD_TAG, id: LIST_TAG_ID }],
    },
    createPeriod: {
      invalidatesTags: [{ type: PERIOD_TAG, id: LIST_TAG_ID }],
    },
    carryOverEmployeesIntoNextPeriod: {
      invalidatesTags: TAGS.map((tag) => ({ type: tag, id: LIST_TAG_ID })),
    },
    getEmployeeList: {
      providesTags: [{ type: EMPLOYEE_TAG, id: LIST_TAG_ID }],
    },
    applyEmployeeChangeSet: {
      invalidatesTags: [{ type: EMPLOYEE_TAG, id: LIST_TAG_ID }],
    },
    getEmployeeReportV3: {
      providesTags: [{ type: EMPLOYEE_TAG, id: LIST_TAG_ID }],
    },
    getInterviewReport: {
      providesTags: [{ type: EMPLOYEE_TAG, id: LIST_TAG_ID }],
    },
    getSurveyReport: {
      providesTags: [{ type: EMPLOYEE_TAG, id: LIST_TAG_ID }],
    },
    getInterviewFiles: {
      providesTags: () => [{ type: INTERVIEW_FILE_TAG, id: LIST_TAG_ID }],
    },
    updateInterviewFile: {
      invalidatesTags: (_, __, args) => [
        { type: INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
        { type: INTERVIEW_FILE_TAG, id: args.interviewRecordingId },
      ],
    },
    deleteInterviewFile: {
      invalidatesTags: (_, __, args) => [
        { type: INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
        { type: INTERVIEW_FILE_TAG, id: args.interviewRecordingId },
      ],
    },
    getInterviewFile: {
      providesTags: (_, __, args) => [
        { type: INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
        { type: INTERVIEW_FILE_TAG, id: args.interviewRecordingId },
      ],
    },
    createInterviewFiles: {
      invalidatesTags: () => [{ type: INTERVIEW_FILE_TAG, id: LIST_TAG_ID }],
    },
    completeInterviewFileMultipartUpload: {
      invalidatesTags: () => [{ type: INTERVIEW_FILE_TAG, id: LIST_TAG_ID }],
    },
    getTranscription: {
      providesTags: (_, __, args) => [
        { type: TRANSCRIPTION_TAG, id: LIST_TAG_ID },
        { type: INTERVIEW_FILE_TAG, id: args.interviewRecordingId },
      ],
    },
    updateTranscription: {
      invalidatesTags: (_, __, args) => [
        { type: TRANSCRIPTION_TAG, id: LIST_TAG_ID },
        { type: INTERVIEW_FILE_TAG, id: args.interviewRecordingId },
      ],
    },
    getExEmployeeInterview: {
      providesTags: (res, _, args) => [
        { type: EX_EMPLOYEE_INTERVIEW, id: args.exEmployeeInterviewId },
        { type: INTERVIEW_FILE_TAG, id: res?.interviewFile?.interviewFileId },
      ],
    },
    editExEmployeeInterview: {
      invalidatesTags: (_, __, args) => [
        { type: EX_EMPLOYEE_INTERVIEW, id: args.exEmployeeInterviewId },
        { type: INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
      ],
    },
    getExEmployeeReviews: {
      providesTags: (_, __, args) => [
        { type: CATEGORY_TAG, id: args.categoryId },
        ...(args.subCategoryIds ? JSON.parse(args.subCategoryIds) : []).map((subCategoryId) => ({
          type: SUB_CATEGORY_TAG,
          id: subCategoryId,
        })),
      ],
    },
    createExEmployeeInterview: {
      invalidatesTags: (_, __, args) => [
        { type: EMPLOYEE_TAG, id: args.employeeId },
        { type: EX_EMPLOYEE_INTERVIEW, id: LIST_TAG_ID },
      ],
    },
    analyzeExEmployeeInterview: {
      invalidatesTags: (_, __, args) => [
        { type: EX_EMPLOYEE_INTERVIEW, id: LIST_TAG_ID },
        { type: EX_EMPLOYEE_INTERVIEW, id: args.exEmployeeInterviewId },
      ],
    },
    unfixExEmployeeInterview: {
      invalidatesTags: (_, __, args) => [
        { type: EX_EMPLOYEE_INTERVIEW, id: LIST_TAG_ID },
        { type: EX_EMPLOYEE_INTERVIEW, id: args.exEmployeeInterviewId },
      ],
    },
    getExEmployeeInterviews: {
      providesTags: [{ type: EX_EMPLOYEE_INTERVIEW, id: LIST_TAG_ID }],
    },
    getSetting: {
      providesTags: [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    registerSegmentName: {
      invalidatesTags: () => [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    getSegmentDefinitions: {
      providesTags: () => [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    createSegmentDefinition: {
      invalidatesTags: () => [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    updateSegmentDefinition: {
      invalidatesTags: () => [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    getCalculatedSegmentConditionSetting: {
      providesTags: () => [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    registerCalculatedSegmentCondition: {
      invalidatesTags: () => [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    updateEmailSettings: {
      invalidatesTags: [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    updateGraphScaleSettings: {
      invalidatesTags: [{ type: SETTING_TAG, id: LIST_TAG_ID }],
    },
    getNotes: {
      providesTags: [{ type: NOTE_TAG, id: LIST_TAG_ID }],
    },
    getNote: {
      providesTags: (_, __, args) => [{ type: NOTE_TAG, id: args.noteId }],
    },
    createNote: {
      invalidatesTags: () => [{ type: NOTE_TAG, id: LIST_TAG_ID }],
    },
    updateNote: {
      invalidatesTags: (_, __, args) => [
        { type: NOTE_TAG, id: LIST_TAG_ID },
        { type: NOTE_TAG, id: args.noteId },
      ],
    },
    deleteNote: {
      invalidatesTags: (_, __, args) => [
        { type: NOTE_TAG, id: LIST_TAG_ID },
        { type: NOTE_TAG, id: args.noteId },
      ],
    },
    getSurveyQuestions: {
      providesTags: [{ type: SURVEY_QUESTION_TAG, id: LIST_TAG_ID }],
    },
    registerSurveyQuestions: {
      invalidatesTags: [{ type: SURVEY_QUESTION_TAG, id: LIST_TAG_ID }],
    },
    createUser: {
      invalidatesTags: [{ type: USER_TAG, id: LIST_TAG_ID }],
    },
    getUsers: {
      providesTags: [{ type: USER_TAG, id: LIST_TAG_ID }],
    },
    getUser: {
      providesTags: [{ type: USER_TAG, id: LIST_TAG_ID }],
    },
    updateUser: {
      invalidatesTags: [{ type: USER_TAG, id: LIST_TAG_ID }],
    },
    deleteUser: {
      invalidatesTags: [{ type: USER_TAG, id: LIST_TAG_ID }],
    },
    getUserGroup: {
      providesTags: [
        { type: USER_GROUP_TAG, id: LIST_TAG_ID },
        { type: USER_TAG, id: LIST_TAG_ID },
      ],
    },
    deleteUserGroup: {
      invalidatesTags: [{ type: USER_GROUP_TAG, id: LIST_TAG_ID }],
    },
    updateUserGroup: {
      invalidatesTags: [{ type: USER_GROUP_TAG, id: LIST_TAG_ID }],
    },
    setUsersToUserGroup: {
      invalidatesTags: [
        { type: USER_GROUP_TAG, id: LIST_TAG_ID },
        { type: USER_TAG, id: LIST_TAG_ID },
      ],
    },
    createUserGroup: {
      invalidatesTags: [{ type: USER_GROUP_TAG, id: LIST_TAG_ID }],
    },
    getUserGroups: {
      providesTags: [
        { type: USER_GROUP_TAG, id: LIST_TAG_ID },
        { type: USER_TAG, id: LIST_TAG_ID },
      ],
    },
    getInterviewTypes: {
      providesTags: [{ type: INTERVIEW_TYPE_TAG, id: LIST_TAG_ID }],
    },
    getInterviewType: {
      providesTags: [{ type: INTERVIEW_TYPE_TAG, id: LIST_TAG_ID }],
    },
    registerInterviewType: {
      invalidatesTags: [{ type: INTERVIEW_TYPE_TAG, id: LIST_TAG_ID }],
    },
    getInterviewFormatByTypeId: {
      providesTags: [{ type: INTERVIEW_FORMAT_TAG, id: LIST_TAG_ID }],
    },
    registerInterviewFormats: {
      invalidatesTags: [{ type: INTERVIEW_FORMAT_TAG, id: LIST_TAG_ID }],
    },
    getInterviewFormatContents: {
      providesTags: [{ type: INTERVIEW_FORMAT_CONTENT_TAG, id: LIST_TAG_ID }],
    },
    registerInterviewFormatContents: {
      invalidatesTags: [{ type: INTERVIEW_FORMAT_CONTENT_TAG, id: LIST_TAG_ID }],
    },
    getHrInterview: {
      providesTags: [{ type: HR_INTERVIEW_TAG, id: LIST_TAG_ID }],
    },
    updateHrInterview: {
      invalidatesTags: [{ type: HR_INTERVIEW_TAG, id: LIST_TAG_ID }],
    },
    deleteHrInterview: {
      invalidatesTags: [{ type: HR_INTERVIEW_TAG, id: LIST_TAG_ID }],
    },
    createHrInterview: {
      invalidatesTags: [{ type: HR_INTERVIEW_TAG, id: LIST_TAG_ID }],
    },
    analyzeHrInterview: {
      invalidatesTags: [{ type: HR_INTERVIEW_TAG, id: LIST_TAG_ID }],
    },
    updateFileOfHrInterview: {
      invalidatesTags: [
        { type: HR_INTERVIEW_TAG, id: LIST_TAG_ID },
        { type: HR_INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
      ],
    },
    findHrInterviewFiles: {
      providesTags: [{ type: HR_INTERVIEW_FILE_TAG, id: LIST_TAG_ID }],
    },
    getHrInterviewFile: {
      providesTags: [{ type: HR_INTERVIEW_FILE_TAG, id: LIST_TAG_ID }],
    },
    deleteHrInterviewFile: {
      invalidatesTags: [
        { type: HR_INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
        { type: HR_INTERVIEW_TAG, id: LIST_TAG_ID },
      ],
    },
    updateHrInterviewFile: {
      invalidatesTags: [
        { type: HR_INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
        { type: HR_INTERVIEW_TAG, id: LIST_TAG_ID },
      ],
    },
    createHrInterviewFile: {
      invalidatesTags: [
        { type: HR_INTERVIEW_FILE_TAG, id: LIST_TAG_ID },
        { type: HR_INTERVIEW_TAG, id: LIST_TAG_ID },
      ],
    },
    summarizeHrInterview: {
      invalidatesTags: [{ type: HR_INTERVIEW_TAG, id: LIST_TAG_ID }],
    },
    sendHrInterviewFeedback: {
      invalidatesTags: [{ type: HR_INTERVIEW_TAG, id: LIST_TAG_ID }],
    },
    getHrInterviewFeedbackQuestionList: {
      providesTags: [{ type: FEEDBACK_QUESTIONS_TAG, id: LIST_TAG_ID }],
    },
    updateHrInterviewFeedbackQuestions: {
      invalidatesTags: [{ type: FEEDBACK_QUESTIONS_TAG, id: LIST_TAG_ID }],
    },
    deleteHrInterviewFeedbackQuestion: {
      invalidatesTags: [{ type: FEEDBACK_QUESTIONS_TAG, id: LIST_TAG_ID }],
    },
    createHrInterviewFeedbackAnswers: {
      invalidatesTags: [{ type: FEEDBACK_QUESTIONS_TAG, id: LIST_TAG_ID }],
    },
  },
});
