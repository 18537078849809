import React from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { useGetInterviewTypes } from "../../../../../../store/hooks/hr-interview-setting";
import { useGetHrInterviews } from "../../../../../../store/hooks/hrInterview";
import { useGetUserListQuery } from "../../../../../../store/hooks/users";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { HrInterviewListContent, HrInterviewQuery } from "../../../../../organisms/hr-interviews/interviews/index";

const COUNT_PER_PAGE = 100;

const HrInterviewListPage: React.FC = () => {
  const searchParams = Route.useSearch();
  const navigate = useNavigate();

  const usersQuery = useGetUserListQuery();
  const interviewTypesQuery = useGetInterviewTypes(true);
  const interviewQuery = useGetHrInterviews(
    COUNT_PER_PAGE,
    0,
    {
      interviewTypeIds: searchParams.interviewTypeIds,
      interviewStatuses: searchParams.interviewStatuses,
      transcriptionStatuses: searchParams.interviewFileStatuses,
      interviewerIds: searchParams.interviewerIds,
      followupRequired: searchParams.followupRequired,
      feedbackStatuses: searchParams.interviewFeedbackStatuses,
      segments: [
        { segmentKey: "SEX", segmentValues: searchParams.SEX },
        { segmentKey: "DEPARTMENT", segmentValues: searchParams.DEPARTMENT },
        { segmentKey: "FREE1", segmentValues: searchParams.FREE1 },
        { segmentKey: "FREE2", segmentValues: searchParams.FREE2 },
        { segmentKey: "AGE", segmentValues: searchParams.AGE },
        { segmentKey: "MONTHS_OF_SERVICE", segmentValues: searchParams.MONTHS_OF_SERVICE },
      ],
      from: searchParams.from,
      to: searchParams.to,
    },
    {
      pollingInterval: 30 * 1000,
    },
  );

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader
        title={"在職者面談一覧"}
        endElement={<AmeButton to={"/hr-interviews/interviews/create"}>面談新規登録</AmeButton>}
      />
      <PageLayoutBody type={"wide"}>
        <WaitForSuccess queryState={usersQuery}>
          {(users) => (
            <WaitForSuccess queryState={interviewTypesQuery}>
              {(interviewTypes) => (
                <HrInterviewListContent
                  interviewers={users.users}
                  searchParams={searchParams}
                  navigate={navigate}
                  interviewTypes={interviewTypes}
                  interviewListQueryState={interviewQuery}
                />
              )}
            </WaitForSuccess>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/hr-interviews/interviews/")({
  component: HrInterviewListPage,
  validateSearch: (search: Partial<HrInterviewQuery>): HrInterviewQuery => {
    return {
      interviewStatuses: search.interviewStatuses ?? [],
      interviewFileStatuses: search.interviewFileStatuses ?? [],
      interviewerIds: search.interviewerIds ?? [],
      interviewFeedbackStatuses: search.interviewFeedbackStatuses ?? [],
      followupRequired: search.followupRequired ?? [],
      interviewTypeIds: search.interviewTypeIds ?? [],
      SEX: search.SEX ?? [],
      DEPARTMENT: search.DEPARTMENT ?? [],
      FREE1: search.FREE1 ?? [],
      FREE2: search.FREE2 ?? [],
      AGE: search.AGE ?? [],
      MONTHS_OF_SERVICE: search.MONTHS_OF_SERVICE ?? [],
      from: search.from,
      to: search.to,
    };
  },
});
