import React from "react";

import { AmeTableBodyCell } from "../../../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface Props {
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export const InterviewerQualityDetailTableBodyCell: React.FC<Props> = ({ icon, children }) => {
  return (
    <AmeTableBodyCell isDense>
      <AmeBox sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        {icon}
        <AmeTypography fontSize="body2">{children}</AmeTypography>
      </AmeBox>
    </AmeTableBodyCell>
  );
};
