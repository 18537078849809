import React from "react";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { HrInterviewFile } from "../../../../../../models/hrInterview/HrInterviewFile";
import { formatDateToYYYYMMDD } from "../../../../../../utils/formatter";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { Warning } from "../../../../../atoms/icon/Warning";
import { DefinitionList } from "../../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { IconText } from "../../../../../molecules/common/IconText";
import { InterviewTranscriptionStatus } from "../../../common/InterviewTranscriptionStatus";

import { InterviewUpdateButton } from "./InterviewUpdateButton";

interface Props {
  interviewDetail: HrInterviewDetail;
}

export const InterviewFileInInterviewDetail: React.FC<Props> = ({ interviewDetail }) => {
  return (
    <PaperWrapper>
      <PaperHeader
        endElement={
          <>
            {interviewDetail.isEditable ? (
              <AmeButton
                variant="outlined"
                color="basic"
                size="small"
                to={"/hr-interviews/interviews/$interviewId/interview-file"}
                params={{ interviewId: interviewDetail.hrInterviewId }}
              >
                登録
              </AmeButton>
            ) : interviewDetail.transcription?.isTranscribing ? (
              <InterviewUpdateButton interviewDetail={interviewDetail} />
            ) : null}
          </>
        }
      >
        音声データ
      </PaperHeader>
      <PaperBody>
        <DefinitionList>
          <DefinitionListItem
            label="ステータス"
            value={<InterviewTranscriptionStatus status={interviewDetail.transcription?.status} />}
          />
          {interviewDetail.interviewFile ? (
            <>
              <DefinitionListItem
                label="アップロード日"
                value={formatDateToYYYYMMDD(interviewDetail.interviewFile.createdAt)}
              />
              <DefinitionListItem
                label="アップロードファイル"
                value={
                  <AmeButton
                    variant="text"
                    color="secondary"
                    to={"/hr-interviews/interview-files/$interviewFileId"}
                    params={{ interviewFileId: interviewDetail.interviewFile.hrInterviewFileId }}
                  >
                    {interviewDetail.interviewFile.originalFileName}
                  </AmeButton>
                }
              />
            </>
          ) : null}
        </DefinitionList>
        <WarningElement status={interviewDetail.transcription?.status} />
      </PaperBody>
    </PaperWrapper>
  );
};

const WarningElement: React.FC<{ status?: HrInterviewFile["transcriptionStatus"] }> = ({ status }) => {
  if (status === undefined || status === "FAILED") {
    return <IconText icon={<Warning color={"primary"} size={30} />}>音声データを登録してください。</IconText>;
  }
  switch (status) {
    case "AI_TRANSCRIBING":
    case "BEAUTIFYING":
      return (
        <IconText icon={<Warning color={"primary"} size={30} />}>
          現在音声データを文字起し中です。完了まで30分程かかる可能性があります。
        </IconText>
      );
  }
};
