import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { defaultApi } from "../store/defaultApi";

import { snackbarSlice } from "./snackbar";

const rootReducers = combineReducers({
  [defaultApi.reducerPath]: defaultApi.reducer,
  snackbar: snackbarSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(defaultApi.middleware),
  devTools: import.meta.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
