import React from "react";

import { Link } from "@tanstack/react-router";

import { InterviewFile } from "../../../../../models/InterviewFile";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { ArrowRight } from "../../../../atoms/icon/ArrowRight";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

export type UpdateInterviewFile = (interviewFileId: string, snapshotId: string | null) => Promise<void>;

interface Props {
  periodId: number;
  interviewFiles: InterviewFile[];
  updateInterviewFile: UpdateInterviewFile;
}

export const SpInterviewFileTable: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        border: `1px solid ${theme.common.border.gray40}`,
        "& > * + *": { borderTop: `1px solid ${theme.common.border.gray40}` },
      }}
    >
      {props.interviewFiles.map((interviewFile) => {
        return (
          <AmeBox key={interviewFile.interviewRecordingId}>
            <Link
              to={"/interview-files/$interviewRecordingId"}
              params={{ interviewRecordingId: interviewFile.interviewRecordingId }}
            >
              <AmeBox sx={{ display: "flex", alignItems: "center", gap: "16px", padding: "8px 16px", minWidth: 0 }}>
                <AmeBox
                  key={interviewFile.interviewRecordingId}
                  sx={{ display: "flex", flexGrow: 1, flexShrink: 1, minWidth: 0, justifyContent: "space-between" }}
                >
                  <AmeBox
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      flexShrink: 1,
                      flexDirection: "column",
                      minWidth: 0,
                    }}
                  >
                    <AmeBox sx={{ flexShrink: 1, minWidth: 0 }}>
                      <Link
                        to={"/interview-files/$interviewRecordingId"}
                        params={{ interviewRecordingId: interviewFile.interviewRecordingId }}
                      >
                        <AmeTypography fontSize={"body2"} omit>
                          <AmeBox sx={{ fontWeight: "bold" }} component={"span"}>
                            {interviewFile.originalFileName}
                          </AmeBox>
                        </AmeTypography>
                      </Link>
                    </AmeBox>
                    <AmeBox sx={{ flexShrink: 1 }}>
                      <AmeTypography omit fontSize={"caption"}>
                        {interviewFile.createdAt.toLocaleDateString()}
                      </AmeTypography>
                    </AmeBox>
                  </AmeBox>
                  <AmeBox
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <AmeBox sx={{ flexShrink: 1, minWidth: 0 }}>
                      <AmeTypography omit fontSize={"caption"}>
                        {interviewFile.getStatusText()}
                      </AmeTypography>
                    </AmeBox>
                  </AmeBox>
                </AmeBox>
                <AmeBox>
                  <ArrowRight color={"black"} />
                </AmeBox>
              </AmeBox>
            </Link>
          </AmeBox>
        );
      })}
    </AmeBox>
  );
};
