import React, { useMemo } from "react";

import { removeUndefinedDeep } from "@amecloud/common-utils/src/utils/ObjectUtils";
import { ApexOptions } from "apexcharts";

import { formatInteger } from "../../../utils/formatNumber";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";

import { AmeChart } from "./AmeChart";

interface Props {
  series: number[];
  series2?: number[];
  seriesLabels: string[];
  names?: string[];
  max?: number;
  min?: number;
  tickAmount?: number;
  formatter?: (value: number) => string;
  height?: number;
  width?: number;
  horizontal?: boolean;
  dataLabelsFormatter?: (value: number) => string;
  legend?: {
    show?: boolean;
    position?: "left" | "top" | "bottom" | "right";
    horizontalAlign?: "left" | "right" | "center";
  };
}

const HORIZONTAL_CHART_TOP_BOTTOM_PADDING = 56;
const HORIZONTAL_BAR_MARGIN = 8;
const HORIZONTAL_BAR_HEIGHT = 16;

// react-apexchartsがbarの高さや個数に応じて、いい感じに計算してくれないので、こっちで計算をする。
const getChartHeight = (seriesLength: number) => {
  return HORIZONTAL_CHART_TOP_BOTTOM_PADDING * 2 + (HORIZONTAL_BAR_MARGIN + HORIZONTAL_BAR_HEIGHT * 2) * seriesLength;
};

export const ColumnChart: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const columnSeries = useMemo(() => {
    if (props.series.length !== props.seriesLabels.length) {
      throw new Error("series and seriesLabels should have the same length");
    }
    if (props.names && props.series2 && props.names.length < 2) {
      throw new Error("names should have at least 2 elements");
    }
    if (!props.series2) {
      return [
        {
          type: "bar",
          name: props.names ? props.names[0] : "",
          data: props.series,
          color: theme.brand.secondary[100] ?? "#00ACC1",
        },
      ];
    } else {
      return [
        {
          type: "bar",
          name: props.names ? props.names[0] : "",

          data: props.series,
          color: theme.brand.secondary[40] ?? "#B2EBF2",
        },
        {
          type: "bar",
          name: props.names ? props.names[1] : "",
          data: props.series2,
          color: theme.brand.secondary[100] ?? "#00ACC1",
        },
      ];
    }
  }, [props.series, props.series2, props.seriesLabels, props.names, theme]);

  const options: ApexOptions = useMemo(() => {
    const ops: ApexOptions = {
      chart: {
        zoom: {
          type: "xy",
          enabled: false,
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: !props.horizontal,
          },
        },
        xaxis: {
          lines: {
            show: props.horizontal,
          },
        },
      },
      stroke: {
        show: false,
      },
      yaxis: {
        max: props.max,
        min: 0,
        tickAmount: props.tickAmount,
        labels: {
          align: "right",
          offsetX: 0, // HorizontalStackedBarChartと同じく日本語を表示するなら必要
          formatter: props.formatter ?? ((value) => formatInteger(value)),
        },
      },
      xaxis: {
        categories: props.seriesLabels,
        axisTicks: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: props.horizontal ?? false,
          barHeight: props.horizontal ? HORIZONTAL_BAR_HEIGHT : undefined,
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      dataLabels: {
        enabled: !!props.dataLabelsFormatter,
        formatter: props.dataLabelsFormatter,
        style: {
          colors: [theme.common.text.white],
        },
      },
      legend: {
        show: props.legend?.show ?? true,
        position: props.legend?.position ?? "top",
        horizontalAlign: props.legend?.horizontalAlign ?? "right",
      },
    };
    return removeUndefinedDeep(ops);
  }, [
    props.dataLabelsFormatter,
    props.formatter,
    props.horizontal,
    props.legend?.horizontalAlign,
    props.legend?.position,
    props.legend?.show,
    props.max,
    props.seriesLabels,
    props.tickAmount,
    theme.common.text.white,
  ]);
  return (
    <AmeChart
      height={props.height == undefined && props.horizontal ? getChartHeight(props.seriesLabels.length) : props.height}
      width={props.width}
      options={options}
      columnSeries={columnSeries}
      type={"bar"}
    />
  );
};
