import React from "react";

import { InterviewDetailResponse } from "../../../../../../store/autogenApi";
import { formatDate } from "../../../../../../utils/formatter";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { InterviewFileMetadataCell } from "./InterviewFileMetadataCell";

interface Props {
  interviewFileMetadata: NonNullable<InterviewDetailResponse["interviewFile"]>;
}

export const InterviewFileMetadata: React.FC<Props> = ({ interviewFileMetadata }) => {
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[]} currentTitle="音声データ情報" />
      <PageLayoutBody type="wide">
        <AmeBox sx={{ display: "flex", gap: "5%" }}>
          <InterviewFileMetadataCell label="ファイル名">
            <AmeBox sx={{ display: "flex", gap: "4px", justifyContent: "flex-start" }}>
              <AmeTypography>{interviewFileMetadata.originalFileName}</AmeTypography>
            </AmeBox>
          </InterviewFileMetadataCell>
          <InterviewFileMetadataCell label="アップロード日">
            <AmeTypography>{formatDate(new Date(interviewFileMetadata.createdAt))}</AmeTypography>
          </InterviewFileMetadataCell>
          <InterviewFileMetadataCell label="登録者">
            <AmeTypography>{interviewFileMetadata.interviewerName ?? "不明"}</AmeTypography>
          </InterviewFileMetadataCell>
          <InterviewFileMetadataCell label="音声データ詳細リンク">
            <AmeTypography>
              <AmeButton
                variant="text"
                to={`/interview-files/$interviewRecordingId`}
                params={{ interviewRecordingId: interviewFileMetadata.interviewFileId }}
              >
                詳細を見る
              </AmeButton>
            </AmeTypography>
          </InterviewFileMetadataCell>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
