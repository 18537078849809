import React from "react";

import { createFileRoute } from "@tanstack/react-router";
import { Control, useFieldArray, useForm, UseFormRegister } from "react-hook-form";

import { InterviewFeedbackRating, InterviewFeedbackSettingsQuestion } from "../../../../../../store/autogenApi";
import { useGetInterviewFeedback, useSetInterviewFeedbackSetting } from "../../../../../../store/hooks/interviews";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

export const FeedbackSetting: React.FC = () => {
  const interviewFeedbackQueryState = useGetInterviewFeedback();
  const setInterviewFeedback = useSetInterviewFeedbackSetting();
  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"インタビューＦＢ設問設定"} />
      <PageLayoutBody>
        <WaitForSuccess queryState={interviewFeedbackQueryState}>
          {({ questions }) => (
            <React.Fragment>
              <FeedbackQuestions questions={questions} setInterviewFeedback={setInterviewFeedback} />
            </React.Fragment>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type FormType = {
  questions: QuestionFormType[];
};

type QuestionFormType = {
  question: string;
  answerChoices: AnswerChoiceFormType[];
};

type AnswerChoiceFormType = {
  answer: string;
  rating: InterviewFeedbackRating;
  score: number;
};

const FeedbackQuestions: React.FC<{
  questions: InterviewFeedbackSettingsQuestion[];
  setInterviewFeedback: (args: { updateInterviewFeedbackSettingsRequest: FormType }) => Promise<unknown>;
}> = ({ questions, setInterviewFeedback }) => {
  const { control, register, handleSubmit } = useForm<FormType>({ defaultValues: { questions } });
  const { fields, append, remove, move } = useFieldArray({
    name: "questions",
    control,
  });

  const onSubmit = async (data) => {
    await setInterviewFeedback({
      updateInterviewFeedbackSettingsRequest: {
        questions: data.questions.map((q, i) => ({
          question: q.question,
          priority: i,
          answerChoices: q.answerChoices.map((a) => ({
            answer: a.answer,
            rating: a.rating,
            score: a.score,
          })),
        })),
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        {fields.map((field, index) => (
          <PaperWrapper key={field.id}>
            <PaperBody>
              <button
                onClick={() => {
                  remove(index);
                }}
              >
                この設問を削除
              </button>
              <button
                onClick={() => {
                  move(index, index - 1);
                }}
              >
                ↑
              </button>
              <AmeBox>
                <label>設問</label>
                <input key={field.id} {...register(`questions.${index}.question`)} />
              </AmeBox>
              <NestedForm index={index} control={control} register={register} />
            </PaperBody>
          </PaperWrapper>
        ))}
      </div>
      <button
        type="button"
        onClick={() =>
          append({
            answerChoices: [],
            question: "",
          })
        }
      >
        add
      </button>
      <button type="submit">submit</button>
    </form>
  );
};

const NestedForm: React.FC<{
  control: Control<FormType>;
  register: UseFormRegister<FormType>;
  index: number;
}> = (props) => {
  const { register, control, index } = props;
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `questions.${props.index}.answerChoices`,
  });
  return (
    <AmeBox sx={{ border: "1px solid pink" }}>
      <label>回答</label>
      {fields.map((_, answerIndex) => (
        <AmeBox sx={{ border: "1px solid blue" }} key={answerIndex}>
          <AmeBox>
            <label>回答表示</label>
            <input key={answerIndex} {...register(`questions.${index}.answerChoices.${answerIndex}.answer`)} />
            <button
              onClick={() => {
                remove(answerIndex);
              }}
            >
              削除
            </button>
          </AmeBox>
          <AmeBox>
            <label>回答のニコチャンマーク(0,1,2)</label>
            <input key={answerIndex} {...register(`questions.${index}.answerChoices.${answerIndex}.rating`)} />
          </AmeBox>
          <AmeBox>
            <label>回答のスコア（100点満点）</label>
            <input
              key={answerIndex}
              type={"number"}
              {...register(`questions.${index}.answerChoices.${answerIndex}.score`)}
            />
          </AmeBox>
        </AmeBox>
      ))}
      <button
        type="button"
        onClick={() => {
          append({
            answer: "",
            rating: "0",
            score: 0,
          });
        }}
      >
        回答追加
      </button>
    </AmeBox>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/interview-feedbacks/")({
  component: FeedbackSetting,
});
