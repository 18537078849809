import { useMemo } from "react";

import { QuerySubState } from "@reduxjs/toolkit/dist/query/core/apiState";
import { QueryDefinition, ResultTypeFrom } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { Id, Override } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { FetchBaseQueryError, QueryStatus } from "@reduxjs/toolkit/query";
import { BaseQueryFn } from "@reduxjs/toolkit/src/query/baseQueryTypes";

import { HrPentestSnackbarMessageBody } from "../../../components/organisms/common/HrPentestSnackbar";
import { useHrPentestSnackbar } from "../../../hooks/useHrPentestSnackbar";

import { getRtkCustomHookError, parseRtkError } from "./mutationFunction";
import { RtkCustomHookError } from "./types";

export type QueryState<T> = { isFetching: boolean } & (
  | {
      /**
       * クエリに対してデータが取得できたているかどうか？
       */
      isSuccess: true;
      data: T;
    }
  | {
      /**
       * クエリに対してデータが取得できたているかどうか？
       */
      isSuccess: false;
      /**
       * クエリが異なるリクエストのデータが入っている可能性がある。
       */
      data?: T;
    }
);

/**
 * 自動生成されたApiクライアントの返り値を内部モデルにして返却するカスタムフック。
 */
export const useQueryResult = <Response, Model>({
  result,
  fromResponse,
  onError,
}: {
  result: UseQueryStateDefaultResult<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    QueryDefinition<any, BaseQueryFn<any, unknown, FetchBaseQueryError, any, any>, string, Response>
  >;
  /*
   * この変形式はdepsチェックしないので注意。
   */
  fromResponse: (response: Response) => Model;
  onError?: (error: RtkCustomHookError, enqueueErrorSnackbar: (body: HrPentestSnackbarMessageBody) => void) => void;
}): QueryState<Model> => {
  const { isFetching, isError, currentData, data, error } = result;
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  return useMemo(() => {
    if (isError) {
      const parsedError = parseRtkError(error);
      const rtkCustomHookError = getRtkCustomHookError(parsedError);
      onError
        ? onError(rtkCustomHookError, enqueueErrorSnackbar)
        : enqueueErrorSnackbar({
            title: "エラーが発生しました。",
            message: rtkCustomHookError?.message,
          });
      return { isFetching: false, isSuccess: false, data: undefined };
    }
    if (data !== undefined) {
      // TODO: レスポンスが正しいundefinedのときバグるよ。
      return { isFetching, isSuccess: currentData !== undefined && !isFetching, data: fromResponse(data) };
    } else {
      return { isFetching, isSuccess: false, isSuccessForCurrentArgs: false, data: undefined };
    }
    // fromResponseはlambda式として生成されがちなので応急処置として抜かしておく。
    // todo: lambda的に作らないようにする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, onError, isError, isFetching, currentData]);
};

/**
 * redux-toolkitの型定義だけどexportされていないのでコピーしている。
 * ほしいのはReturnType<UseQuery>だが、型定義が複雑過ぎて、きれいに取得できない(握りつぶされてanyになっちゃう)のでまるっとコピー。
 * そのうちexportしてくれるっぽい雰囲気(https://github.com/reduxjs/redux-toolkit/pull/2276)はあるので逐次確認してこのコピーを消す。
 */
type UseQueryStateDefaultResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends QueryDefinition<any, BaseQueryFn, string, any>,
> = Id<
  | Override<Extract<UseQueryStateBaseResult<D>, { status: QueryStatus.uninitialized }>, { isUninitialized: true }>
  | Override<
      UseQueryStateBaseResult<D>,
      | { isLoading: true; isFetching: boolean; data: undefined }
      | ({
          isSuccess: true;
          isFetching: true;
          error: undefined;
        } & Required<Pick<UseQueryStateBaseResult<D>, "data" | "fulfilledTimeStamp">>)
      | ({
          isSuccess: true;
          isFetching: false;
          error: undefined;
        } & Required<Pick<UseQueryStateBaseResult<D>, "data" | "fulfilledTimeStamp" | "currentData">>)
      | ({ isError: true } & Required<Pick<UseQueryStateBaseResult<D>, "error">>)
    >
> & {
  /**
   * @deprecated will be removed in the next version
   * please use the `isLoading`, `isFetching`, `isSuccess`, `isError`
   * and `isUninitialized` flags instead
   */
  status: QueryStatus;
};

/**
 * {@link UseQueryStateDefaultResult}と同じくRTK-Query内で利用されている型
 */
type UseQueryStateBaseResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends QueryDefinition<any, BaseQueryFn, string, any>,
> = QuerySubState<D> & {
  /**
   * Where `data` tries to hold data as much as possible, also re-using
   * data from the last arguments passed into the hook, this property
   * will always contain the received data from the query, for the current query arguments.
   */
  currentData?: ResultTypeFrom<D>;
  /**
   * Query has not started yet.
   */
  isUninitialized: false;
  /**
   * Query is currently loading for the first time. No data yet.
   */
  isLoading: false;
  /**
   * Query is currently fetching, but might have data from an earlier request.
   */
  isFetching: false;
  /**
   * Query has data from a successful load.
   */
  isSuccess: false;
  /**
   * Query is currently in "error" state.
   */
  isError: false;
};
