import React, { useEffect, useState } from "react";

import { CommonSuggestionAlgorithm } from "@amecloud/api-client/src-openapi-autogen";

import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { CategorySuggestionArea } from "./CategorySuggestionArea";
import { MatchedDocs } from "./MatchedDocs";
import { SubCategorySuggestionArea } from "./SubCategorySuggestionArea";
import { SuggestionAlgorithm, SuggestionHeader } from "./SuggestionHeader";

interface Props {
  categoryId: string;
  subCategoryId?: string;
}

export const RuleSuggestion: React.FC<Props> = ({ categoryId, subCategoryId }) => {
  const [algorithm, setAlgorithm] = useState<SuggestionAlgorithm>(CommonSuggestionAlgorithm.FREQUENT_WORD);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    setAlgorithm(CommonSuggestionAlgorithm.FREQUENT_WORD);
  }, [subCategoryId]);
  return (
    <AmeBox sx={{ flexShrink: 1, minHeight: 0, display: "flex", flexDirection: "column" }}>
      <SuggestionHeader
        isSubCategory={subCategoryId !== undefined}
        algorithm={algorithm}
        setAlgorithm={setAlgorithm}
        setQuery={setQuery}
        query={query}
      />
      <AmeBox sx={{ flexShrink: 1, minHeight: 0, overflowY: "scroll", overflowX: "hidden" }}>
        {subCategoryId === undefined ? (
          <CategorySuggestionArea categoryId={categoryId} algorithm={algorithm} query={query} size={200} />
        ) : (
          <SubCategorySuggestionArea subCategoryId={subCategoryId} algorithm={algorithm} query={query} size={2000} />
        )}
        <MatchedDocs />
      </AmeBox>
    </AmeBox>
  );
};
