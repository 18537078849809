import React from "react";

import { HrInterviewQualityReport } from "../../../../models/hrInterview/HrInterviewQualityReport";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { ColumnsWithRatioChart } from "../../../atoms/charts/ColumnsWithRatioChart";
import { PaperBody } from "../../../atoms/paper/PaperBody";
import { PaperWrapper } from "../../../atoms/paper/PaperWrapper";
import { V2ContentWrapper } from "../../../atoms/wrapper/V2ContentWrapper";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  hrInterviewQualityReport: HrInterviewQualityReport;
  show: boolean;
}

export const CompletedInterviewReport: React.FC<Props> = ({ hrInterviewQualityReport, show }) => {
  const seriesData = hrInterviewQualityReport.generateInterviewCountSeriesData();
  const theme = useAmeTheme();
  return (
    <PaperWrapper>
      <PaperBody>
        <V2ContentWrapper title="面談後アンケート実施率">
          <AmeBox sx={{ position: "relative" }}>
            <ColumnsWithRatioChart
              seriesAll={seriesData.map(({ interviewCount }) => interviewCount)}
              seriesSome={seriesData.map(({ completedInterviewCount }) => completedInterviewCount)}
              seriesLabels={seriesData.map(({ label }) => label)}
              names={["面談数", "面談後アンケート数", "面談後アンケート実施率"]}
              columnMax={100}
              ratioMax={1}
              height={300}
            />
            {show ? null : (
              <AmeBox
                sx={{
                  backgroundColor: theme.common.background.gray40,
                  opacity: 0.8,
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                評価項目でフィルターされている場合はアンケート実施率は表示されません
              </AmeBox>
            )}
          </AmeBox>
        </V2ContentWrapper>
      </PaperBody>
    </PaperWrapper>
  );
};
