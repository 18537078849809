import { RuleResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { ComputationTaskStatus } from "./computationTasks";
import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export interface RuleModel {
  ruleId: string;
  esQuery: string;
  subCategoryId: string;
  computationTaskStatus: ComputationTaskStatus | null;
  createdAt: Date;
  updatedAt: Date;
}

export namespace RuleModel {
  export const fromResponse = (rule: RuleResponse): RuleModel => {
    return {
      ruleId: rule.ruleId,
      esQuery: rule.esQuery,
      subCategoryId: rule.subCategoryId,
      computationTaskStatus: rule.meta?.latestComputationTask?.computationTaskStatus ?? null,
      createdAt: new Date(rule.createdAt),
      updatedAt: new Date(rule.updatedAt),
    };
  };
}

export const useGetRulesQueryByCategoryId = (categoryId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getRulesByCategoryId.useQuery(
    {
      categoryId: categoryId,
    },
    { pollingInterval: options?.pollingInterval },
  );
  return useQueryResult({
    result,
    fromResponse: (rules: RuleResponse[]) => rules.map(RuleModel.fromResponse),
    onError: options?.onError,
  });
};

export const useCreateRule = (options?: UseMutationOptions) => {
  const [createRule] = defaultApi.endpoints.createRule.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createRule,
    onError: options?.onError,
  });
};

export const useUpdateRule = (options?: UseMutationOptions) => {
  const [updateRule] = defaultApi.endpoints.updateRule.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateRule,
    onError: options?.onError,
  });
};

export const useDeleteRule = (options?: UseMutationOptions) => {
  const [deleteRule] = defaultApi.endpoints.deleteRule.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteRule,
    onError: options?.onError,
  });
};

export const filterRulesBySubCategoryId = (rules: RuleModel[], subCategoryId: string): RuleModel[] =>
  rules.filter((rule) => rule.subCategoryId === subCategoryId);
