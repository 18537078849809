import React, { useCallback, useState } from "react";

import { InputSegmentKey } from "../../../../../store/autogenApi";
import { useCreateSegmentDefinition } from "../../../../../store/hooks/segmentDefinitions";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeDialog } from "../../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../../molecules/dialog/AmeDialogLayout";

import { SegmentDefinitionCreatorForm } from "./SegmentDefinitionCraetorForm";

interface Props {
  inputSegmentKey: InputSegmentKey;
}
export const SegmentDefinitionCreator: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [definition, setDefinition] = useState<string>("");
  const createSegmentDefinition = useCreateSegmentDefinition();

  const handleChange = useCallback(
    (definition: string) => {
      setDefinition(definition);
    },
    [setDefinition],
  );

  const onClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = useCallback(async () => {
    await createSegmentDefinition({
      segmentKey: props.inputSegmentKey,
      createSegmentDefinitionRequestBody: { segmentValue: definition },
    });
    setDefinition("");
    onClose();
  }, [onClose, props.inputSegmentKey, definition, setDefinition, createSegmentDefinition]);

  return (
    <React.Fragment>
      <AmeButton onClick={onClick}>定義を新規作成</AmeButton>
      <AmeDialog open={open} onClose={onClose}>
        <AmeDialogLayout title="定義を新規作成" onSubmit={onSubmit} onClose={onClose}>
          <SegmentDefinitionCreatorForm definition={definition} handleChange={handleChange} />
        </AmeDialogLayout>
      </AmeDialog>
    </React.Fragment>
  );
};
