/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RuleSuggestionResponseMetadata,
    RuleSuggestionResponseMetadataFromJSON,
    RuleSuggestionResponseMetadataFromJSONTyped,
    RuleSuggestionResponseMetadataToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuleSuggestionResponse
 */
export interface RuleSuggestionResponse {
    /**
     * 
     * @type {string}
     * @memberof RuleSuggestionResponse
     */
    esQuery: string;
    /**
     * 
     * @type {RuleSuggestionResponseMetadata}
     * @memberof RuleSuggestionResponse
     */
    metadata: RuleSuggestionResponseMetadata;
}

export function RuleSuggestionResponseFromJSON(json: any): RuleSuggestionResponse {
    return RuleSuggestionResponseFromJSONTyped(json, false);
}

export function RuleSuggestionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleSuggestionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'esQuery': json['esQuery'],
        'metadata': RuleSuggestionResponseMetadataFromJSON(json['metadata']),
    };
}

export function RuleSuggestionResponseToJSON(value?: RuleSuggestionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'esQuery': value.esQuery,
        'metadata': RuleSuggestionResponseMetadataToJSON(value.metadata),
    };
}


