import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { CalculatedSegmentKey } from "../../../../../../models/constants/values";
import {
  useGetCalculatedSegmentConditionSetting,
  useRegisterCalculatedSegmentCondition,
} from "../../../../../../store/hooks/segmentDefinitions";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { GraphSegmentSettingTable } from "../../../../../organisms/settings/graphs/segments/GraphSegmentSettingTable";

export const SettingsGraphsSegments: React.FC = () => {
  // TODO: 全取得APIを作る
  const ageCalculatedConditionQuery = useGetCalculatedSegmentConditionSetting(CalculatedSegmentKey.AGE);
  const monthsOfServiceCalculatedConditionQuery = useGetCalculatedSegmentConditionSetting(
    CalculatedSegmentKey.MONTHS_OF_SERVICE,
  );
  const registerCalculatedSegmentDefinition = useRegisterCalculatedSegmentCondition();
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[{ title: "設定", to: "/settings" }]} currentTitle="グラフ区切り設定" />
      <PageLayoutBody type="wide">
        <AmeBox sx={{ paddingBottom: "24px" }}>
          <AmeTypography component="h6">グラフ区切り設定</AmeTypography>
        </AmeBox>
        <WaitForSuccess queryState={ageCalculatedConditionQuery}>
          {(ageCalculatedCondition) => (
            <WaitForSuccess queryState={monthsOfServiceCalculatedConditionQuery}>
              {(monthsOfServiceCalculatedCondition) => (
                <GraphSegmentSettingTable
                  calculatedSegmentConditions={[ageCalculatedCondition, monthsOfServiceCalculatedCondition]}
                  onDefinitionUpdate={(segmentKey, strategy) =>
                    registerCalculatedSegmentDefinition({
                      segmentKey,
                      registerCalculatedSegmentSettingRequestBody: { selectedCalculationStrategy: strategy },
                    })
                  }
                />
              )}
            </WaitForSuccess>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/graphs/segments")({
  component: SettingsGraphsSegments,
});
