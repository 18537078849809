import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeCard, AmeCardContent } from "../../muiWrapper/AmeCard";

export interface FormListProps {
  title: string;
  children: React.ReactNode;
}

export const FormList: React.FC<FormListProps> = (props) => {
  const theme = useAmeTheme();
  return (
    <AmeCard variant="outlined">
      <AmeBox
        sx={{
          height: "56px",
          lineHeight: "56px",
          fontWeight: 400,
          fontSize: 16,
          borderBottom: `1px solid ${theme.common.border.gray40}`,
          backgroundColor: theme.common.background.gray40,
          padding: "0 16px",
        }}
      >
        {props.title}
      </AmeBox>
      <AmeCardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          rowGap: "16px",
          flexDirection: "column",
        }}
      >
        {props.children}
      </AmeCardContent>
    </AmeCard>
  );
};
