import React from "react";

import { ArrowForwardIos } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import { Link, LinkProps } from "@tanstack/react-router";

import { useSp } from "../../../hooks/useSp";
import { CSS_SP_MEDIA_QUERY } from "../../../utils/mediaQuery";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeButton } from "../button/AmeButton";
import { AmeIconButton } from "../button/AmeIconButton";
import { ArrowLeft } from "../icon/ArrowLeft";
import { AmeTypography } from "../typography/AmeTypography";

interface Props {
  items: ({ title: string } & LinkProps)[];
  currentTitle: string;
}

export const BreadcrumbPageLayoutHeader: React.FC<Props> = ({ items, currentTitle }) => {
  const theme = useAmeTheme();
  const sp = useSp();

  return (
    <AmeBox
      sx={{
        backgroundColor: theme.common.background.gray40,
        height: "48px",
        borderBottom: `1px solid ${theme.common.border.gray40}`,
        padding: "0 24px",
        display: "flex",
        alignItems: "center",
        fontSize: theme.typography.body1.fontSize,
        [CSS_SP_MEDIA_QUERY]: {
          height: "52px",
          fontSize: theme.typography.body2.fontSize,
          backgroundColor: "unset",
        },
      }}
    >
      {sp ? (
        <>
          <AmeBox sx={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
            <AmeTypography component="h4">{currentTitle}</AmeTypography>
          </AmeBox>
          <AmeBox sx={{ position: "absolute", left: "6px" }}>
            <Link to={items[0].to}>
              <AmeIconButton>
                <ArrowLeft color={"inherit"} />
              </AmeIconButton>
            </Link>
          </AmeBox>
        </>
      ) : (
        <Breadcrumbs separator={<ArrowForwardIos sx={{ fontSize: "18px" }} />}>
          {items.map(({ title, ...rest }) => (
            <AmeButton key={title} variant="text" {...rest}>
              {title}
            </AmeButton>
          ))}
          <AmeTypography fontSize="body1">{currentTitle}</AmeTypography>
        </Breadcrumbs>
      )}
    </AmeBox>
  );
};
