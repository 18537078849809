import React from "react";

import { Autocomplete, createFilterOptions } from "@mui/material";

import { useUpdateRule } from "../../../../../store/hooks/rules";
import {
  useCreateSubCategoryMutation,
  useSubCategoriesByCategoryIdQuery,
} from "../../../../../store/hooks/subCategories";
import { AmeTextField } from "../../../../atoms/forms/AmeTextField";
import { AmeMenuItem } from "../../../../atoms/menu/AmeMenuItem";

interface Props {
  subCategoryId: string;
  categoryId: string;
  ruleId: string;
}

const filter = createFilterOptions();
export const SubCategoryCell: React.FC<Props> = (props) => {
  const { isSuccess, isFetching, data: subCategories } = useSubCategoriesByCategoryIdQuery(props.categoryId);
  const subCategory = subCategories?.find((subCat) => subCat.subCategoryId === props.subCategoryId);

  const updateRule = useUpdateRule();
  const createSubCategory = useCreateSubCategoryMutation();

  return isSuccess && !isFetching ? (
    <Autocomplete
      clearOnBlur
      options={subCategories}
      onChange={async (_, newValue) => {
        if (newValue && typeof newValue !== "string") {
          let subCategoryId: string = newValue.subCategoryId;
          if (subCategoryId === "") {
            const matched = subCategories.filter((s) => s.displayName === newValue.displayName);
            if (matched.length > 0) {
              subCategoryId = matched[0].subCategoryId;
            } else {
              const { isSuccess, data: subCategory } = await createSubCategory({
                categoryId: props.categoryId,
                createSubCategoryRequestBody: {
                  displayName: newValue.displayName,
                  subCategoryDefinition: "",
                },
              });
              if (isSuccess) {
                subCategoryId = subCategory.subCategoryId;
              }
            }
          }
          await updateRule({
            ruleId: props.ruleId,
            genericUpdateRuleRequestBodyString: { subCategoryId: subCategoryId },
          });
        }
      }}
      renderOption={(renderOptionProps, option) => (
        <AmeMenuItem {...renderOptionProps}>
          {option.subCategoryId === "" ? `"${option.displayName}"を追加` : option.displayName}
        </AmeMenuItem>
      )}
      filterOptions={(options, state) => {
        const filtered = filter(options, state) as typeof options;
        const { inputValue } = state;
        const isExisting = options.some((option) => inputValue === option.displayName);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            subCategoryId: "",
            categoryId: props.categoryId,
            displayName: inputValue,
            computationTaskStatus: null,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.displayName)}
      renderInput={(params) => <AmeTextField {...params} variant="auto-complete" />}
      freeSolo
      value={subCategory}
    />
  ) : null;
};
