import React from "react";

import { AmeIconButton } from "../../atoms/button/AmeIconButton";
import { Close } from "../../atoms/icon/Close";
import { PageLayoutHeader } from "../../atoms/layout/PageLayoutHeader";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  title: string;
  onClose?: () => void;
}
export const DrawerHeader: React.FC<Props> = ({ title, onClose }) => {
  return (
    <AmeBox sx={{ position: "relative" }}>
      {onClose ? (
        <AmeBox sx={{ position: "absolute", left: "6px" }}>
          <AmeIconButton onClick={onClose}>
            <Close color={"inherit"} />
          </AmeIconButton>
        </AmeBox>
      ) : null}
      <PageLayoutHeader title={title} />
    </AmeBox>
  );
};
