import React, { useCallback } from "react";

import { useNavigate } from "@tanstack/react-router";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { SimplePaper } from "../../atoms/paper/SimplePaper";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

export const Shortcuts: React.FC = () => {
  const theme = useAmeTheme();
  return (
    <SimplePaper noPadding>
      <AmeBox sx={{ paddingX: "32px", paddingY: "16px" }}>
        <AmeTypography component="h6">ショートカット</AmeTypography>
      </AmeBox>
      <AmeBox component="hr" sx={{ margin: 0, border: "none", borderTop: `1px solid ${theme.common.border.gray40}` }} />
      <AmeBox sx={{ padding: "16px" }}>
        <ShortcutElement title="音声データ登録" href="/interview-files/register" />
        <ShortcutElement title="従業員マスタの更新" href="/employees/register" />
        <ShortcutElement title="アンケート作成" href="/surveys/create?method=email" />
      </AmeBox>
    </SimplePaper>
  );
};

type ShortcutElementProps = {
  title: string;
  href: string;
};
const ShortcutElement: React.FC<ShortcutElementProps> = ({ title, href }) => {
  const theme = useAmeTheme();
  const navigate = useNavigate();
  const handleClick = useCallback(async () => await navigate({ to: href }), [href, navigate]);
  return (
    <AmeBox
      sx={{
        padding: "16px",
        borderRadius: "8px",
        ...theme.typography.button,
        color: theme.common.text.black,
        cursor: "pointer",
        ":hover": { bgcolor: theme.common.background.gray20 },
      }}
      onClick={handleClick}
    >
      {title}
    </AmeBox>
  );
};
