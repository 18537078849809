import React, { useCallback } from "react";

import { getStagingLevel } from "../../../../env";
import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { UserRef } from "../../../../models/User";
import { API_URL, getAuthorization } from "../../../../utils/api/ApiClientFactory";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { isGlobalCognitoKey } from "../Authenticator";

interface Props {
  user?: UserRef;
}

export const UserRegister: React.FC<Props> = ({ user }) => {
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const [isGlobalCognito] = useLocalStorage<"false" | "true">(isGlobalCognitoKey, "false");
  const createMyUser = useCallback(async () => {
    const authorization = await getAuthorization();
    const res = await fetch(API_URL + "/api/local/my-user", {
      method: "post",
      headers: { authorization: authorization!, "Content-Type": "application/json" },
      body: JSON.stringify({
        isGlobalAuth: isGlobalCognito,
        isAdmin: true,
        surname: "admin1",
        givenName: "admin",
      }),
    });
    if (res.ok) {
      enqueueSuccessSnackbar({ title: "ユーザー登録しました。" });
      location.reload();
    } else {
      enqueueErrorSnackbar({ title: "ユーザー登録に失敗しました。" });
    }
  }, [enqueueErrorSnackbar, enqueueSuccessSnackbar, isGlobalCognito]);

  if (user === undefined || user.isUnknownUser) {
    // ユーザーが共通基盤で認証されているユーザーなら未登録ユーザーを登録するボタンを表示
    if (isGlobalCognito === "true") {
      return (
        <div>
          <h1>管理ユーザー登録ページ</h1>
          <div>あなたのユーザーは未登録です。ボタンを押して登録してください。</div>
          <div>
            <AmeButton onClick={createMyUser}>登録</AmeButton>
          </div>
        </div>
      );
    }

    // ローカル環境なら未登録ユーザーを登録するボタンを表示
    if (getStagingLevel() === "local") {
      return (
        <div>
          <h1>開発用ユーザー登録ページ</h1>
          <div>あなたのユーザーは未登録です。</div>
          <div>
            <AmeButton onClick={createMyUser}>登録</AmeButton>
          </div>
        </div>
      );
    }
  }

  return null;
};
