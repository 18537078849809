/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserRequestBody
 */
export interface UpdateUserRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    givenName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequestBody
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequestBody
     */
    userGroupIds?: Array<string>;
}

export function UpdateUserRequestBodyFromJSON(json: any): UpdateUserRequestBody {
    return UpdateUserRequestBodyFromJSONTyped(json, false);
}

export function UpdateUserRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'userGroupIds': !exists(json, 'userGroupIds') ? undefined : json['userGroupIds'],
    };
}

export function UpdateUserRequestBodyToJSON(value?: UpdateUserRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surname': value.surname,
        'givenName': value.givenName,
        'isAdmin': value.isAdmin,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'userGroupIds': value.userGroupIds,
    };
}


