import React, {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import { PopoverProps } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmePopover } from "../../muiWrapper/AmePopover";

import { AmeDropboxButton } from "./AmeDropboxButton";

export type DropboxChangeEvent<Value> = { target: { value: Value | undefined; name: string } };
export type DropboxSize = "small" | "medium" | "large";

interface Props<Value> {
  /** 未選択はundefined */
  value?: Value | undefined;
  /**
   * Dropdownを表示するボタンのコンテンツ。
   * ChoosableListItemと同じElementを渡すとDropdownのリストアイテムと同じデザインになるよ。
   * */
  displaySelected: ReactNode | undefined;
  onChange?: (event: DropboxChangeEvent<Value>) => void;
  disabled?: boolean;
  error?: boolean;
  size?: DropboxSize;
  /** tableのセル内で使う場合にtrueにする */
  forTable?: boolean;
  children?: React.ReactNode;
  anchorOrigin?: PopoverProps["anchorOrigin"];
  transformOrigin?: PopoverProps["transformOrigin"];
  /** dropdown内に表示するinputコンポーネント */
  inputComponent?: ReactNode;
}

/**
 * Controlledで扱う必要があるため、react-hook-formを使う場合はControllerを使ってください。
 * @example
 * @deprecated atomsのAmeDropboxを使うようにしてください
 * <AmeDropbox
 *   value={value}
 *   onChange={setValue}
 *   displaySelected={value?.label}
 * >
 *   <ChoosableList>
 *     {options.map((option) => (
 *       <ChoosableListItem key={option.key} value={option.key}>
 *         {option.label}
 *       </ChoosableListItem>
 *     ))}
 *   </ChoosableList>
 * </AmeDropbox>
 */
export const AmeDropbox_old = <Value,>(props: Props<Value>) => {
  const theme = useAmeTheme();
  const { value, onChange, disabled, error, size, forTable, children, displaySelected, anchorOrigin, transformOrigin } =
    props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl],
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onSelect = useCallback(
    (value: Value | undefined) => {
      if (onChange !== undefined) {
        onChange({
          target: {
            value: value,
            name: "dropbox",
          },
        });
      }
      onClose();
    },
    [onChange, onClose],
  );

  const dropboxChildren = useMemo(
    () =>
      Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            onSelect: onSelect,
            forTable: forTable,
            selectedValue: value,
            forSelect: true,
            ...child.props,
          });
        }
        return child;
      }),
    [children, onSelect, forTable, value],
  );

  return (
    <>
      <AmeDropboxButton
        displaySelected={displaySelected}
        onClick={onClick}
        size={size}
        isOpen={Boolean(anchorEl)}
        forTable={forTable}
        disabled={disabled}
        error={error}
      />
      <AmePopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={{ root: { sx: { zIndex: theme.zIndex.popoverLayer } } }}
      >
        {props.inputComponent ? <AmeBox sx={{ padding: "18px 16px 8px 16px" }}>{props.inputComponent}</AmeBox> : null}
        {dropboxChildren}
      </AmePopover>
    </>
  );
};
