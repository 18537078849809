import React, { MouseEvent, useCallback } from "react";

import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { AmePopover } from "../../../../muiWrapper/AmePopover";

interface Props {
  select?: (selected: { start?: number; end?: number }) => void;
  index: number;
  lastIndex: number;
}

export const TranscriptionRowBorder: React.FC<Props> = ({ select, index, lastIndex }) => {
  const theme = useAmeTheme();

  const [open, setOpen] = React.useState<Element | null>(null);
  const onClickOpen = useCallback((e: MouseEvent) => {
    setOpen(e.currentTarget);
  }, []);
  const onSelect = useCallback(
    (selected: { start?: number; end?: number }) => {
      select ? select(selected) : undefined;
      setOpen(null);
    },
    [select],
  );
  return (
    <>
      <AmeBox sx={{ position: "relative" }}>
        <AmeBox
          onClick={select ? onClickOpen : undefined}
          sx={{
            position: "absolute",
            top: "-5px",
            left: 0,
            right: 0,
            padding: "5px 0px",
            cursor: select ? "pointer" : undefined,
            zIndex: 1,
          }}
        >
          <AmeBox sx={{ borderTop: `1px dashed ${theme.common.border.gray40}` }} />
        </AmeBox>
      </AmeBox>
      <AmePopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <AmeBox sx={{ padding: "18px 16px", "> * + *": { marginLeft: "8px" } }}>
          <AmeButton
            disabled={index === lastIndex}
            onClick={() => {
              onSelect({ start: index });
            }}
            variant={"outlined"}
          >
            範囲の始点にする
          </AmeButton>
          <AmeButton
            disabled={index === 0}
            onClick={() => {
              onSelect({ end: index - 1 });
            }}
            variant={"outlined"}
          >
            範囲の終点にする
          </AmeButton>
        </AmeBox>
      </AmePopover>
    </>
  );
};
