/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewKpiData,
    InterviewKpiDataFromJSON,
    InterviewKpiDataFromJSONTyped,
    InterviewKpiDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewKpiResponse
 */
export interface InterviewKpiResponse {
    /**
     * 
     * @type {Array<InterviewKpiData>}
     * @memberof InterviewKpiResponse
     */
    data: Array<InterviewKpiData>;
}

export function InterviewKpiResponseFromJSON(json: any): InterviewKpiResponse {
    return InterviewKpiResponseFromJSONTyped(json, false);
}

export function InterviewKpiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewKpiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(InterviewKpiDataFromJSON)),
    };
}

export function InterviewKpiResponseToJSON(value?: InterviewKpiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(InterviewKpiDataToJSON)),
    };
}


