/**
 * 同時実行数制御のためのセマフォ
 */
export class Semaphore {
    private tasks: (() => void)[];
    count: number;

    constructor(max = 1) {
        this.count = max;
        this.tasks = [];
    }

    /**
     * 資源が確保できるまで待つ
     */
    acquire(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tasks.push(() => resolve());
            this.proceed();
        });
    }

    /**
     * 資源を開放する
     */
    release(): void {
        this.count++;
        this.proceed();
    }

    /**
     * acquireとreleaseを使ってタスクを実行する
     */
    async runTask<T>(task: () => Promise<T>): Promise<T> {
        await this.acquire();
        return task().finally(() => this.release());
    }

    private proceed() {
        while (this.count > 0 && this.tasks.length > 0) {
            const nextTask = this.tasks.shift();
            if (nextTask === undefined) {
                throw "Semaphore is invalid state";
            }
            this.count--;
            nextTask();
        }
    }
}
