/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewDetailResponseInterview,
    InterviewDetailResponseInterviewFromJSON,
    InterviewDetailResponseInterviewFromJSONTyped,
    InterviewDetailResponseInterviewToJSON,
    InterviewDetailResponseInterviewFile,
    InterviewDetailResponseInterviewFileFromJSON,
    InterviewDetailResponseInterviewFileFromJSONTyped,
    InterviewDetailResponseInterviewFileToJSON,
    InterviewDetailResponseRetiredEmployee,
    InterviewDetailResponseRetiredEmployeeFromJSON,
    InterviewDetailResponseRetiredEmployeeFromJSONTyped,
    InterviewDetailResponseRetiredEmployeeToJSON,
    InterviewDetailResponseSummary,
    InterviewDetailResponseSummaryFromJSON,
    InterviewDetailResponseSummaryFromJSONTyped,
    InterviewDetailResponseSummaryToJSON,
    RetirementReasonResponse,
    RetirementReasonResponseFromJSON,
    RetirementReasonResponseFromJSONTyped,
    RetirementReasonResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewDetailResponse
 */
export interface InterviewDetailResponse {
    /**
     * 
     * @type {InterviewDetailResponseRetiredEmployee}
     * @memberof InterviewDetailResponse
     */
    retiredEmployee: InterviewDetailResponseRetiredEmployee;
    /**
     * 
     * @type {InterviewDetailResponseSummary}
     * @memberof InterviewDetailResponse
     */
    summary?: InterviewDetailResponseSummary;
    /**
     * 
     * @type {RetirementReasonResponse}
     * @memberof InterviewDetailResponse
     */
    retirementReasons: RetirementReasonResponse;
    /**
     * 
     * @type {InterviewDetailResponseInterview}
     * @memberof InterviewDetailResponse
     */
    interview?: InterviewDetailResponseInterview;
    /**
     * 
     * @type {InterviewDetailResponseInterviewFile}
     * @memberof InterviewDetailResponse
     */
    interviewFile?: InterviewDetailResponseInterviewFile;
}

export function InterviewDetailResponseFromJSON(json: any): InterviewDetailResponse {
    return InterviewDetailResponseFromJSONTyped(json, false);
}

export function InterviewDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewDetailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retiredEmployee': InterviewDetailResponseRetiredEmployeeFromJSON(json['retiredEmployee']),
        'summary': !exists(json, 'summary') ? undefined : InterviewDetailResponseSummaryFromJSON(json['summary']),
        'retirementReasons': RetirementReasonResponseFromJSON(json['retirementReasons']),
        'interview': !exists(json, 'interview') ? undefined : InterviewDetailResponseInterviewFromJSON(json['interview']),
        'interviewFile': !exists(json, 'interviewFile') ? undefined : InterviewDetailResponseInterviewFileFromJSON(json['interviewFile']),
    };
}

export function InterviewDetailResponseToJSON(value?: InterviewDetailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retiredEmployee': InterviewDetailResponseRetiredEmployeeToJSON(value.retiredEmployee),
        'summary': InterviewDetailResponseSummaryToJSON(value.summary),
        'retirementReasons': RetirementReasonResponseToJSON(value.retirementReasons),
        'interview': InterviewDetailResponseInterviewToJSON(value.interview),
        'interviewFile': InterviewDetailResponseInterviewFileToJSON(value.interviewFile),
    };
}


