import React, { useCallback } from "react";

import { requireNonNull } from "@amecloud/common-utils/src/utils/Assertions";
import { generateSurveyEmailBody } from "@amecloud/common-utils/src/utils/EmailUtils";
import { useNavigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SurveyModel } from "../../../../../models/Survey";
import { useStartSurvey } from "../../../../../store/hooks/surveys";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmeModal } from "../../../../atoms/modal/AmeModal";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  emailSignature?: string;
  survey: SurveyModel;
  isOpen: boolean;
  onClose: () => void;
  onOpenQrModal: () => void;
}

export const SendSurveyModal: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const startSurvey = useStartSurvey();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onSubmit = useCallback(async () => {
    const result = await startSurvey({
      surveyId: props.survey.surveyId,
    });
    if (result.isSuccess) {
      if (props.survey.useQrcode) {
        props.onOpenQrModal();
        return;
      }
      void navigate({ to: "/surveys", search: { page: 1 } });
      return;
    }
  }, [startSurvey, props, navigate]);

  if (props.survey.emailSubject === null || props.survey.emailBody === null) {
    enqueueErrorSnackbar({ title: "メールの件名と本文がありません。" });
    return null;
  }

  return (
    <AmeModal open={props.isOpen} onClose={props.onClose}>
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader items={[]} currentTitle="アンケートを確認" />
        <PageLayoutBody type="wide">
          <AmeBox
            sx={{
              "> *": { borderBottom: "1px solid #cccccc", padding: "16px 0" },
              "> *:first-child": { paddingTop: "0", paddingBottom: "24px" },
            }}
          >
            <AmeTypography component="h5">以下の送付内容でよろしければ、送信するボタンを押してください。</AmeTypography>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>件名</AmeTypography>
              </AmeBox>
              <AmeTypography>{props.survey.emailSubject}</AmeTypography>
            </AmeBox>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>本文</AmeTypography>
              </AmeBox>
              <AmeTypography>
                {generateSurveyEmailBody(
                  requireNonNull(props.survey.emailBody),
                  "{アンケートURL}",
                  props.emailSignature,
                )}
              </AmeTypography>
            </AmeBox>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>回答対象</AmeTypography>
              </AmeBox>
              <AmeTypography>{props.survey.targetEmployeeCount}人</AmeTypography>
            </AmeBox>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>メール送付対象</AmeTypography>
              </AmeBox>
              <AmeTypography>{props.survey.emailSheetsCount}人</AmeTypography>
            </AmeBox>
          </AmeBox>
          <Spacer height="24px" />
          <AmeBox sx={{ display: "flex", justifyContent: "right", gap: "16px" }}>
            <AmeButton variant="outlined" onClick={props.onClose}>
              戻る
            </AmeButton>
            <AmeButton onClick={onSubmit}>メールを送信</AmeButton>
          </AmeBox>
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeModal>
  );
};
