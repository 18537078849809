import {
  CalculatedSegmentKey as CalculatedSegmentKeyType,
  InputSegmentKey as InputSegmentKeyType,
  SegmentKey as SegmentKeyType,
  HrInterviewFeedbackStatus,
  HrInterviewAnalysisStatus as HrInterviewAnalysisRawStatus,
  HrInterviewTranscriptionStatus as HrInterviewTranscriptionRawStatus,
} from "../../store/autogenApi";

export const InputSegmentKey: { [key in InputSegmentKeyType]: key } = {
  SEX: "SEX",
  DEPARTMENT: "DEPARTMENT",
  FREE1: "FREE1",
  FREE2: "FREE2",
};

export const CalculatedSegmentKey: { [key in CalculatedSegmentKeyType]: key } = {
  MONTHS_OF_SERVICE: "MONTHS_OF_SERVICE",
  AGE: "AGE",
};

export const SegmentKey: { [key in SegmentKeyType]: key } = {
  ...InputSegmentKey,
  ...CalculatedSegmentKey,
};

export const isSegmentKey = (key: string | null): key is keyof typeof SegmentKey => {
  if (key === null) {
    return false;
  }
  return key in SegmentKey;
};
export const isInputSegmentKey = (key: string | null): key is keyof typeof InputSegmentKey => {
  if (key === null) {
    return false;
  }
  return key in InputSegmentKey;
};

export const SEGMENT_VALUE_QUERY_KEY = "segment-value";

export const SURVEY_SENDING_METHODS = ["email", "qr-code", "both"] as const;

export type SurveySendingMethod = (typeof SURVEY_SENDING_METHODS)[number];

export const isSurveySendingMethod = (method: string): method is SurveySendingMethod => {
  return SURVEY_SENDING_METHODS.some((m) => m === method);
};
export const SURVEY_SENDING_METHOD_QUERY_KEY = "method";

export type FeedbackStatus = HrInterviewFeedbackStatus;
export type HrInterviewAnalysisStatus = HrInterviewAnalysisRawStatus;
export type HrInterviewTranscriptionStatus = HrInterviewTranscriptionRawStatus;
