import React, { useCallback, ChangeEvent, useMemo } from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { ChoosableList } from "../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../atoms/list/ChoosableListItem";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeDropbox_old } from "../dropbox/AmeDropbox";

type Props = {
  options: { queryValue: string; name: string }[];
  selectedOption: string | null;
  filterParamKey: string;
  setFilterParams: (filterParamKey: string, optionsKey: string) => void;
};

export const FilterSelect: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const displaySelected = useMemo(
    () => props.options.find((option) => option.queryValue === props.selectedOption)?.name,
    [props.options, props.selectedOption],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const date = e.target.value;
      props.setFilterParams(props.filterParamKey, date);
    },
    [props],
  );

  return (
    <AmeBox sx={{ backgroundColor: theme.common.background.white }}>
      <AmeDropbox_old value={props.selectedOption} displaySelected={displaySelected} onChange={onChange} size="small">
        <ChoosableList size="medium">
          {props.options.map((option) => (
            <ChoosableListItem key={option.queryValue} value={option.queryValue}>
              {option.name}
            </ChoosableListItem>
          ))}
        </ChoosableList>
      </AmeDropbox_old>
    </AmeBox>
  );
};
