import { RuleStatsResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useQueryResult } from "./utils/parseResult";
import { UseQueryOptions } from "./utils/types";

export interface RuleStatsModel {
  precision: number;
  hitCount: number;
}

export namespace RuleStatsModel {
  export const fromResponse = (response: RuleStatsResponse): RuleStatsModel => {
    return {
      precision: response.precision,
      hitCount: response.hitCount,
    };
  };
}

export const useGetRuleStatsQueryByRuleId = (ruleId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getStatsByRule.useQuery({ ruleId });
  return useQueryResult({
    result,
    fromResponse: RuleStatsModel.fromResponse,
    onError: options?.onError,
  });
};

export const useGetRuleStatsLazyQueryByRuleId = (options?: UseQueryOptions) => {
  const [trigger, result] = defaultApi.endpoints.getStatsByRule.useLazyQuery();
  return {
    trigger,
    result: useQueryResult({
      result,
      fromResponse: RuleStatsModel.fromResponse,
      onError: options?.onError,
    }),
  };
};
