import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AmeButton } from "../../../atoms/button/AmeButton";
import { AmeInput } from "../../../atoms/forms/input/AmeInput";
import { Search } from "../../../atoms/icon/Search";
import { ChoosableList } from "../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../atoms/list/ChoosableListItem";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { SPDrawer } from "../../../molecules/drawer/SPDrawer";
import { AmeDropboxButton } from "../../../molecules/dropbox/AmeDropboxButton";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props<T> {
  value?: T;
  title: string;
  placeholder?: string;
  disabled?: boolean;
  getSuggests: (search: string) => Promise<T[]>;
  getId: (suggest?: T) => string;
  getLabel: (suggest: T) => string;
  renderRow?: (label: string, suggest?: T) => React.ReactNode;
  getDisabled?: (suggest?: T) => boolean;
  onSubmit: (suggest: T | undefined) => void;
}

export const SpSearchDropdown = <T,>({
  value,
  title,
  placeholder,
  disabled,
  onSubmit,
  getSuggests,
  getId,
  getDisabled,
  renderRow,
  getLabel,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<T | undefined>(value);
  const [suggests, setSuggests] = useState<T[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const displaySelected = useMemo(() => {
    if (renderRow === undefined || value === undefined) {
      return undefined;
    }
    if (renderRow && value) {
      return renderRow(getLabel(value), value);
    }
    return getLabel(value);
  }, [renderRow, value, getLabel]);

  const onClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    (async () => {
      if (searchInput === "") {
        setSuggests([]);
        return;
      }
      const suggestsResult = await getSuggests(searchInput);
      setSuggests(suggestsResult);
    })();
  }, [getSuggests, searchInput]);

  const onClickSubmitButton = useCallback(
    (data: T | undefined) => {
      onSubmit(data);
      onClose();
    },
    [onClose, onSubmit],
  );

  return (
    <>
      <AmeDropboxButton onClick={onClick} displaySelected={displaySelected} disabled={disabled} />
      <SPDrawer open={isOpen} title={title} onClose={onClose}>
        <AmeInput
          placeholder={placeholder}
          fullWidth
          endIcon={<Search size={16} />}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Spacer height={"24px"} />
        <ChoosableList selectedValue={selected} onSelect={setSelected} forSelect>
          <ChoosableListItem>選択なし</ChoosableListItem>
          {suggests.map((suggest) => {
            return (
              <ChoosableListItem disabled={getDisabled && getDisabled(suggest)} key={getId(suggest)} value={suggest}>
                {renderRow ? renderRow(getLabel(suggest), suggest) : getLabel(suggest)}
              </ChoosableListItem>
            );
          })}
        </ChoosableList>
        {searchInput === "" ? (
          <>
            <Spacer height="16px" />
            <AmeTypography fontSize="body2">
              検索ボックスに従業員番号を入力してください。予測される従業員番号が表示されます。
            </AmeTypography>
          </>
        ) : null}
        <Spacer height={"24px"} />
        <AmeBox sx={{ position: "sticky", bottom: 0 }}>
          <AmeButton
            onClick={() => {
              onClickSubmitButton(selected);
            }}
            fullWidth
          >
            確定
          </AmeButton>
        </AmeBox>
      </SPDrawer>
    </>
  );
};
