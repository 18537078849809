import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetAllCategories } from "../../../../../../../store/hooks/categories";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { SettingItemsContainer } from "../../../../../../organisms/settings/common/SettingItemsContainer";
import { SettingLinkButton } from "../../../../../../organisms/settings/common/SettingLinkButton";

const SubCategoriesRuleSettings: React.FC = () => {
  const { isSuccess, data: categories } = useGetAllCategories();
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "設定", to: "/settings" },
          { title: "ルール変更", to: "/settings/rules" },
        ]}
        currentTitle={"サブカテゴリー"}
      />
      <PageLayoutBody>
        <SettingItemsContainer title="カテゴリー" justifyContent="left">
          {isSuccess
            ? categories.map((category) => (
                <SettingLinkButton
                  key={category.categoryId}
                  to={"/settings/rules/sub-categories/$categoryId"}
                  params={{ categoryId: category.categoryId }}
                >
                  {category.displayName}
                </SettingLinkButton>
              ))
            : null}
        </SettingItemsContainer>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/rules/sub-categories/")({
  component: SubCategoriesRuleSettings,
});
