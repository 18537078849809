/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateHrInterviewFileRequestBody
 */
export interface UpdateHrInterviewFileRequestBody {
    /**
     * 
     * @type {number}
     * @memberof UpdateHrInterviewFileRequestBody
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateHrInterviewFileRequestBody
     */
    uploadUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHrInterviewFileRequestBody
     */
    interviewerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHrInterviewFileRequestBody
     */
    userGroupId?: string | null;
}

export function UpdateHrInterviewFileRequestBodyFromJSON(json: any): UpdateHrInterviewFileRequestBody {
    return UpdateHrInterviewFileRequestBodyFromJSONTyped(json, false);
}

export function UpdateHrInterviewFileRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateHrInterviewFileRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'uploadUserId': !exists(json, 'uploadUserId') ? undefined : json['uploadUserId'],
        'interviewerId': !exists(json, 'interviewerId') ? undefined : json['interviewerId'],
        'userGroupId': !exists(json, 'userGroupId') ? undefined : json['userGroupId'],
    };
}

export function UpdateHrInterviewFileRequestBodyToJSON(value?: UpdateHrInterviewFileRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': value.duration,
        'uploadUserId': value.uploadUserId,
        'interviewerId': value.interviewerId,
        'userGroupId': value.userGroupId,
    };
}


