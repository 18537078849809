/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetHrInterviewFileUploaderUrlBody
 */
export interface GetHrInterviewFileUploaderUrlBody {
    /**
     * の音声ファイルの容量（単位：byte）
     * @type {number}
     * @memberof GetHrInterviewFileUploaderUrlBody
     */
    fileSize: number;
}

export function GetHrInterviewFileUploaderUrlBodyFromJSON(json: any): GetHrInterviewFileUploaderUrlBody {
    return GetHrInterviewFileUploaderUrlBodyFromJSONTyped(json, false);
}

export function GetHrInterviewFileUploaderUrlBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetHrInterviewFileUploaderUrlBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileSize': json['fileSize'],
    };
}

export function GetHrInterviewFileUploaderUrlBodyToJSON(value?: GetHrInterviewFileUploaderUrlBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileSize': value.fileSize,
    };
}


