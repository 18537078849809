/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateHrInterviewFileRequestBodyFiles,
    CreateHrInterviewFileRequestBodyFilesFromJSON,
    CreateHrInterviewFileRequestBodyFilesFromJSONTyped,
    CreateHrInterviewFileRequestBodyFilesToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateHrInterviewFileRequestBody
 */
export interface CreateHrInterviewFileRequestBody {
    /**
     * 
     * @type {Array<CreateHrInterviewFileRequestBodyFiles>}
     * @memberof CreateHrInterviewFileRequestBody
     */
    files: Array<CreateHrInterviewFileRequestBodyFiles>;
}

export function CreateHrInterviewFileRequestBodyFromJSON(json: any): CreateHrInterviewFileRequestBody {
    return CreateHrInterviewFileRequestBodyFromJSONTyped(json, false);
}

export function CreateHrInterviewFileRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateHrInterviewFileRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'files': ((json['files'] as Array<any>).map(CreateHrInterviewFileRequestBodyFilesFromJSON)),
    };
}

export function CreateHrInterviewFileRequestBodyToJSON(value?: CreateHrInterviewFileRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'files': ((value.files as Array<any>).map(CreateHrInterviewFileRequestBodyFilesToJSON)),
    };
}


