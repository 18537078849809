import { useCallback, useState } from "react";

export const useLocalStorage = <T extends string | null>(key: string, initValue: T) => {
  const [value, setValue] = useState<T>(() => (localStorage.getItem(key) as T) || initValue);

  const setLocalStorageValue = useCallback(
    (newValue: T) => {
      if (newValue === null) {
        localStorage.removeItem(key);
        setValue(null as T);
      } else {
        localStorage.setItem(key, newValue);
        setValue(newValue);
      }
    },
    [key],
  );

  return [value, setLocalStorageValue] as const;
};
