import React, { useContext } from "react";

import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";

import { CurrentCognitoUserContext } from "..";
import { MINIMUM_PASSWORD_LENGTH, VALID_PASSWORD_REG } from "../../../../../config/PasswordPolicy";
import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { PasswordInput } from "../../../../molecules/PasswordInput";

import { LoginButtonWrapper } from "./common/LoginButtonWrapper";
import { LoginDescriptionWrapper } from "./common/LoginDescriptionWrapper";
import { LoginFormsWrapper } from "./common/LoginFormsWrapper";
import { LoginInputWrapper } from "./common/LoginInputWrapper";
import { LoginLogoTitle } from "./common/LoginLogoTitle";
import { LoginPageWrapper } from "./common/LoginPageWrapper";
import { LoginSubTitleWrapper } from "./common/LoginSubTitleWrapper";

type ForceChangePasswordFormType = {
  password: string;
  reenteredPassword: string;
};

interface Props {
  setPassword: (password: string) => void;
}

export const ForceChangePassword: React.FC<Props> = ({ setPassword }) => {
  const { currentCognitoUser, setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ForceChangePasswordFormType>({
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      password: "",
      reenteredPassword: "",
    },
  });
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onSubmit = async (data: ForceChangePasswordFormType) => {
    try {
      const updatedCognitoUser = await Auth.completeNewPassword(currentCognitoUser, data.password);
      setCurrentCognitoUser(updatedCognitoUser);
      setPassword(data.password);
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        enqueueErrorSnackbar({ title: "ユーザが見つかりません。" });
        return;
      }
      if (err.code === "NotAuthorizedException") {
        enqueueErrorSnackbar({ title: "メールアドレスかパスワードが違います。" });
        return;
      }
      enqueueErrorSnackbar();
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <LoginPageWrapper>
      <LoginLogoTitle />
      <LoginSubTitleWrapper>パスワードの設定</LoginSubTitleWrapper>
      <LoginDescriptionWrapper>
        初期パスワードから、パスワードを変更する必要があります。
        <br />
        新しいパスワードを設定してください。
      </LoginDescriptionWrapper>
      <LoginFormsWrapper onSubmit={handleSubmit(onSubmit)}>
        <LoginInputWrapper>
          <Controller
            name={"password"}
            control={control}
            rules={{
              required: "パスワードを入力してください。",
              pattern: {
                value: VALID_PASSWORD_REG,
                message: "パスワードは、大小英字、数字、特殊記号をそれぞれ１つ以上使う必要があります。",
              },
              minLength: {
                value: MINIMUM_PASSWORD_LENGTH,
                message: "パスワードは８文字以上である必要があります。",
              },
            }}
            render={({ field }) => (
              <PasswordInput field={field} placeholder={"新しいパスワード"} errors={errors.password?.types} />
            )}
          />
        </LoginInputWrapper>
        <LoginInputWrapper>
          <Controller
            name={"reenteredPassword"}
            control={control}
            rules={{
              required: "パスワードを再入力してください。",
              validate: (input) =>
                input === getValues("password") || "パスワードと再入力されたパスワードが一致していません。",
            }}
            render={({ field }) => (
              <PasswordInput field={field} placeholder={"パスワード"} errors={errors.reenteredPassword?.types} />
            )}
          />
        </LoginInputWrapper>
        <LoginButtonWrapper>
          <AmeButton type="submit" fullWidth>
            設定
          </AmeButton>
        </LoginButtonWrapper>
      </LoginFormsWrapper>
    </LoginPageWrapper>
  );
};
