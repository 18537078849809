import React, { useState } from "react";

import { useNavigate } from "@tanstack/react-router";

import { SurveyModel } from "../../../../../models/Survey";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DeleteSurveyModal } from "../modals/DeleteSurveyModal";
import { SendSampleSurveyModal } from "../modals/SendSampleSurveyModal";
import { SendSurveyModal } from "../modals/SendSurveyModal";
import { StartQrSurveyModal } from "../modals/StartQrSurveyModal";

interface Props {
  emailSignature?: string;
  survey: SurveyModel;
  setQrCodeModalIsOpen: (open: boolean) => void;
}

export const DraftSurveyDetailsHeader: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [sendSampleSurveyModalIsOpen, setSendSampleSurveyModalIsOpen] = useState<boolean>(false);
  const [sendSurveyModalIsOpen, setSendSurveyModalIsOpen] = useState<boolean>(false);
  const [startQrSurveyModalIsOpen, setStartQrSurveyModalIsOpen] = useState<boolean>(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  if (props.survey.sendEmail) {
    return (
      <>
        <SendSampleSurveyModal
          isOpen={sendSampleSurveyModalIsOpen}
          onClose={() => setSendSampleSurveyModalIsOpen(false)}
          survey={props.survey}
        />
        <SendSurveyModal
          isOpen={sendSurveyModalIsOpen}
          onClose={() => setSendSurveyModalIsOpen(false)}
          onOpenQrModal={() => props.setQrCodeModalIsOpen(true)}
          survey={props.survey}
          emailSignature={props.emailSignature}
        />
        <DeleteSurveyModal
          isOpen={deleteModalIsOpen}
          onClose={() => setDeleteModalIsOpen(false)}
          survey={props.survey}
        />
        <AmeBox sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px" }}>
          <AmeBox sx={{ marginRight: "auto" }}>
            <AmeTypography component="h3">{props.survey.displayName}</AmeTypography>
          </AmeBox>
          <AmeButton variant="outlined" onClick={() => setDeleteModalIsOpen(true)}>
            削除
          </AmeButton>
          <AmeButton
            variant="outlined"
            onClick={async () => {
              await navigate({
                to: "/surveys/$surveyId/edit",
                params: { surveyId: props.survey.surveyId },
                search: { method: SurveyModel.getSurveySendingMethod(props.survey.useQrcode, props.survey.sendEmail) },
              });
            }}
          >
            編集
          </AmeButton>
          <AmeButton variant="outlined" onClick={() => setSendSampleSurveyModalIsOpen(true)}>
            テスト送付
          </AmeButton>
          <AmeButton onClick={() => setSendSurveyModalIsOpen(true)}>確認</AmeButton>
        </AmeBox>
      </>
    );
  }

  return (
    <>
      <StartQrSurveyModal
        isOpen={startQrSurveyModalIsOpen}
        onClose={() => setStartQrSurveyModalIsOpen(false)}
        onOpenQrModal={() => props.setQrCodeModalIsOpen(true)}
        survey={props.survey}
      />
      <DeleteSurveyModal isOpen={deleteModalIsOpen} onClose={() => setDeleteModalIsOpen(false)} survey={props.survey} />
      <AmeBox sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px" }}>
        <AmeBox sx={{ marginRight: "auto" }}>
          <AmeTypography component="h3">{props.survey.displayName}</AmeTypography>
        </AmeBox>
        <AmeButton variant="outlined" onClick={() => setDeleteModalIsOpen(true)}>
          削除
        </AmeButton>
        <AmeButton
          variant="outlined"
          onClick={async () => {
            await navigate({
              to: "/surveys/$surveyId/edit",
              params: { surveyId: props.survey.surveyId },
              search: { method: SurveyModel.getSurveySendingMethod(props.survey.useQrcode, props.survey.sendEmail) },
            });
          }}
        >
          編集
        </AmeButton>
        <AmeButton onClick={() => setStartQrSurveyModalIsOpen(true)}>確認</AmeButton>
      </AmeBox>
    </>
  );
};
