import React from "react";

import { CSS_SP_MEDIA_QUERY } from "../../../utils/mediaQuery";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface PaperLayoutProps {
  children: React.ReactNode;
}

export const PageLayoutWrapper: React.FC<PaperLayoutProps> = ({ children }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        height: "inherit",
        width: "inherit",
        backgroundColor: theme.common.background.white,
        border: `1px solid ${theme.common.border.gray40}`,
        borderRadius: "4px",
        position: "relative",
        boxShadow: theme.common.shadow.main,
        [CSS_SP_MEDIA_QUERY]: {
          height: "100%",
          border: "none",
        },
      }}
    >
      {children}
    </AmeBox>
  );
};
