/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComputationTaskResponse,
    ComputationTaskResponseFromJSON,
    ComputationTaskResponseFromJSONTyped,
    ComputationTaskResponseToJSON,
    EmployeeResponse,
    EmployeeResponseFromJSON,
    EmployeeResponseFromJSONTyped,
    EmployeeResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExEmployeeInterviewResponse
 */
export interface ExEmployeeInterviewResponse {
    /**
     * 
     * @type {string}
     * @memberof ExEmployeeInterviewResponse
     */
    exEmployeeInterviewId: string;
    /**
     * 
     * @type {EmployeeResponse}
     * @memberof ExEmployeeInterviewResponse
     */
    employee: EmployeeResponse;
    /**
     * 
     * @type {string}
     * @memberof ExEmployeeInterviewResponse
     */
    interviewRecordingId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExEmployeeInterviewResponse
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof ExEmployeeInterviewResponse
     */
    answer: string;
    /**
     * 
     * @type {ComputationTaskResponse}
     * @memberof ExEmployeeInterviewResponse
     */
    task?: ComputationTaskResponse;
    /**
     * 
     * @type {Date}
     * @memberof ExEmployeeInterviewResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExEmployeeInterviewResponse
     */
    analyzedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ExEmployeeInterviewResponse
     */
    documentCount: number;
}

export function ExEmployeeInterviewResponseFromJSON(json: any): ExEmployeeInterviewResponse {
    return ExEmployeeInterviewResponseFromJSONTyped(json, false);
}

export function ExEmployeeInterviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExEmployeeInterviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exEmployeeInterviewId': json['exEmployeeInterviewId'],
        'employee': EmployeeResponseFromJSON(json['employee']),
        'interviewRecordingId': !exists(json, 'interviewRecordingId') ? undefined : json['interviewRecordingId'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'answer': json['answer'],
        'task': !exists(json, 'task') ? undefined : ComputationTaskResponseFromJSON(json['task']),
        'createdAt': (new Date(json['createdAt'])),
        'analyzedAt': !exists(json, 'analyzedAt') ? undefined : (new Date(json['analyzedAt'])),
        'documentCount': json['documentCount'],
    };
}

export function ExEmployeeInterviewResponseToJSON(value?: ExEmployeeInterviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exEmployeeInterviewId': value.exEmployeeInterviewId,
        'employee': EmployeeResponseToJSON(value.employee),
        'interviewRecordingId': value.interviewRecordingId,
        'summary': value.summary,
        'answer': value.answer,
        'task': ComputationTaskResponseToJSON(value.task),
        'createdAt': (value.createdAt.toISOString()),
        'analyzedAt': value.analyzedAt === undefined ? undefined : (value.analyzedAt.toISOString()),
        'documentCount': value.documentCount,
    };
}


