import React, { useCallback, useMemo, useState } from "react";

import { AmeModal } from "../../../../atoms/modal/AmeModal";
import { SurveyTemplate } from "../../../common/SurveyTemplate";
import {
  SurveyQuestion,
  SurveyQuestionAnswer,
  SurveyQuestionAnswers,
} from "../../../common/SurveyTemplate/SurveyTemplateBody";

export type PreviewModalQuestion = {
  surveyQuestionId?: string;
  questionText: string;
};

interface Props {
  title: string;
  description: string;
  previewIsOpen: boolean;
  onClosePreview: () => void;
  questions: PreviewModalQuestion[];
}

export const SurveyQuestionsPreviewModal: React.FC<Props> = (props) => {
  const [answers, setAnswers] = useState<SurveyQuestionAnswers>(new Map());

  const questions: SurveyQuestion[] = useMemo(
    () =>
      props.questions.map<SurveyQuestion>((question, index) => ({
        questionId: question.surveyQuestionId || index.toString(),
        questionText: question.questionText,
      })),
    [props.questions],
  );

  const handleChange = useCallback(
    (answer: SurveyQuestionAnswer) => {
      const copied = new Map(answers);
      copied.set(answer.questionId, answer);
      setAnswers(copied);
    },
    [answers, setAnswers],
  );

  const onClose = useCallback(() => {
    setAnswers(new Map());
    props.onClosePreview();
  }, [props]);

  return (
    <AmeModal open={props.previewIsOpen} onClose={onClose}>
      <SurveyTemplate
        title={props.title}
        description={props.description}
        answers={answers}
        questions={questions}
        onChange={handleChange}
        onSubmit={() => undefined}
      />
    </AmeModal>
  );
};
