import React, { useMemo } from "react";

import { useEvaluationTypeChoiceGroup } from "../../../../hooks/filterHooks/useEvaluationTypeChoiceGroup";
import { useFollowupChoiceGroup } from "../../../../hooks/filterHooks/useFollowupChoiceGroup";
import { useInterviewTypeChoiceGroup } from "../../../../hooks/filterHooks/useInterviewTypeChoiceGroup";
import { useInterviewerChoiceGroup } from "../../../../hooks/filterHooks/useInterviewerChoiceGroup";
import { useSelectedFilter } from "../../../../hooks/useSelectedFilter";
import { HrInterviewType } from "../../../../models/HrInterviewSetting";
import { User } from "../../../../models/User";
import { HrInterviewQualityReport } from "../../../../models/hrInterview/HrInterviewQualityReport";
import { HrInterviewQualityReportAxis } from "../../../../store/autogenApi";
import { formatDateToYYYYMMDD } from "../../../../utils/formatter";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { CompletedInterviewReport } from "./CompletedInterviewReport";
import { QualityReportFilter } from "./QualityReportFilter";
import { QualityReportHeader } from "./QualityReportHeader";
import { QualityScoreReport } from "./QualityScoreReport";

export interface QualityReportQuery {
  from?: string;
  to?: string;
  interviewTypeIds: string[];
  interviewerIds: string[];
  evaluationTypes: string[];
  followupRequired: boolean[];
  axis: HrInterviewQualityReportAxis;
}

interface Props {
  navigate: (arg: { search: (prev: QualityReportQuery) => QualityReportQuery }) => Promise<void>;
  searchParams: QualityReportQuery;
  interviewTypes: HrInterviewType[];
  interviewers: User[];
  evaluationTypes: string[];
  hrInterviewQualityReport: HrInterviewQualityReport;
}

export const QualityReportContent: React.FC<Props> = ({
  navigate,
  searchParams,
  interviewTypes,
  interviewers,
  evaluationTypes,
  hrInterviewQualityReport,
}) => {
  const choiceGroups = useQualityReportChoiceGroups(
    navigate,
    searchParams,
    interviewTypes,
    interviewers,
    evaluationTypes,
  );

  const selectedFilters = useSelectedFilter(choiceGroups);

  return (
    <AmeBox sx={{ display: "flex", gap: "24px" }}>
      <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
        <QualityReportFilter
          choiceGroups={choiceGroups}
          from={searchParams.from}
          to={searchParams.to}
          setDate={async (params) => {
            await navigate({
              search: (prev) => {
                const nextParams = { ...prev };
                if (params.from) {
                  nextParams.from = formatDateToYYYYMMDD(params.from);
                }
                if (params.to) {
                  nextParams.to = formatDateToYYYYMMDD(params.to);
                }
                return nextParams;
              },
            });
          }}
        />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, flexBasis: 0 }}>
        <QualityReportHeader
          count={hrInterviewQualityReport.totalInterviewCount}
          selectedFilters={selectedFilters}
          selectedAxis={searchParams.axis}
          onChangeAxis={async (e) => {
            await navigate({
              search: (prev) => {
                return { ...prev, axis: e.target.value as HrInterviewQualityReportAxis };
              },
            });
          }}
        />
        <AmeBox sx={{ display: "flex", gap: "32px", flexDirection: "column" }}>
          <CompletedInterviewReport
            hrInterviewQualityReport={hrInterviewQualityReport}
            show={searchParams.evaluationTypes.length === 0}
          />
          <QualityScoreReport hrInterviewQualityReport={hrInterviewQualityReport} />
        </AmeBox>
      </AmeBox>
    </AmeBox>
  );
};

const useQualityReportChoiceGroups = (
  navigate: (arg: { search: (prev: QualityReportQuery) => QualityReportQuery }) => Promise<void>,
  searchParams: QualityReportQuery,
  interviewTypes: HrInterviewType[],
  interviewers: User[],
  evaluationTypes: string[],
) => {
  const interviewTypeChoiceGroup = useInterviewTypeChoiceGroup(interviewTypes, {
    filterParams: searchParams,
    navigate: navigate,
  });
  const interviewerChoiceGroup = useInterviewerChoiceGroup(interviewers, {
    filterParams: searchParams,
    navigate: navigate,
  });
  const evaluationTypeChoiceGroup = useEvaluationTypeChoiceGroup(evaluationTypes, {
    filterParams: searchParams,
    navigate: navigate,
  });
  const followUpRequiredChoiceGroup = useFollowupChoiceGroup({ filterParams: searchParams, navigate: navigate });

  return useMemo(() => {
    return [interviewTypeChoiceGroup, followUpRequiredChoiceGroup, interviewerChoiceGroup, evaluationTypeChoiceGroup];
  }, [evaluationTypeChoiceGroup, followUpRequiredChoiceGroup, interviewTypeChoiceGroup, interviewerChoiceGroup]);
};
