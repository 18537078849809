import React, { PropsWithChildren } from "react";

import { Box } from "@mui/material";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";

interface Props {
  onClick: () => void;
}

export const PageTransitionText: React.FC<PropsWithChildren<Props>> = (props) => {
  const theme = useAmeTheme();
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box
        component="span"
        sx={{
          fontSize: (theme) => theme.typography.body1.fontSize,
          fontWeight: "bold",
          color: theme.common.text.gray40,
          cursor: "pointer",
        }}
        onClick={props.onClick}
      >
        {props.children}
      </Box>
    </Box>
  );
};
