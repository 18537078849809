import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const EmailWithQrCode: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g>
        <path
          d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 10.01C0.00264352 10.5387 0.214524 11.0448 0.58931 11.4178C0.964097 11.7907 1.4713 12 2 12H5.5C5.63261 12 5.75979 11.9473 5.85355 11.8536C5.94732 11.7598 6 11.6326 6 11.5C6 11.3674 5.94732 11.2402 5.85355 11.1464C5.75979 11.0527 5.63261 11 5.5 11H2C1.77988 11 1.5659 10.9274 1.39124 10.7934C1.21658 10.6595 1.09101 10.4716 1.034 10.259L6.674 6.788L8 7.583L15 3.383V5.5C15 5.63261 15.0527 5.75979 15.1464 5.85355C15.2402 5.94732 15.3674 6 15.5 6C15.6326 6 15.7598 5.94732 15.8536 5.85355C15.9473 5.75979 16 5.63261 16 5.5V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0H2ZM5.708 6.208L1 9.105V3.383L5.708 6.208ZM1 2.217V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14C14.2652 1 14.5196 1.10536 14.7071 1.29289C14.8946 1.48043 15 1.73478 15 2V2.217L8 6.417L1 2.217Z"
          fill="currentColor"
        />
      </g>
      <g transform="translate(8, 8)">
        <rect width="1" height="1" fill="white" transform="translate(-1, -1)" />
        <svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1H2V2H1V1Z" fill="currentColor" />
          <path d="M3 0V3H0V0H3ZM2.5 0.5H0.5V2.5H2.5V0.5ZM2 6H1V7H2V6Z" fill="currentColor" />
          <path d="M3 5V8H0V5H3ZM0.5 5.5V7.5H2.5V5.5H0.5ZM6 1H7V2H6V1Z" fill="currentColor" />
          <path
            d="M5 0V3H8V0H5ZM7.5 0.5V2.5H5.5V0.5H7.5ZM4 0.5V0H4.5V1H4V2H3.5V0.5H4ZM4 3V2H4.5V3H4ZM3 4V3.5H3.5V3H4V4H4.5V3.5H7V4H5V4.5H3.5V4H3ZM3 4V4.5H1V4H0.5V4.5H0V3.5H1.5V4H3ZM8 4.5H7.5V3.5H8V4.5ZM7.5 4.5H7V5.5H8V5H7.5V4.5ZM5.5 4.5H6.5V5H6V5.5H5.5V4.5ZM6.5 6V5.5H6V6H5.5V6.5H4.5V7H6V6H6.5ZM6.5 6H8V6.5H7V7H6.5V6ZM4.5 5.5V6H5V5H3.5V5.5H4.5Z"
            fill="currentColor"
          />
          <path d="M3.5 6H4V7.5H6V8H3.5V6ZM8 7V8H6.5V7.5H7.5V7H8Z" fill="currentColor" />
        </svg>
      </g>
    </SvgWrapper>
  );
};
