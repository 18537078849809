import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const BadFace: React.FC<Omit<SvgIconProps, "color">> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_10169_203798)">
        <g clipPath="url(#clip1_10169_203798)">
          <path
            d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
            fill="#FF4A49"
          />
          <path
            d="M4 10.5C4 10.3674 4.05268 10.2402 4.14645 10.1464C4.24021 10.0527 4.36739 10 4.5 10H11.5C11.6326 10 11.7598 10.0527 11.8536 10.1464C11.9473 10.2402 12 10.3674 12 10.5C12 10.6326 11.9473 10.7598 11.8536 10.8536C11.7598 10.9473 11.6326 11 11.5 11H4.5C4.36739 11 4.24021 10.9473 4.14645 10.8536C4.05268 10.7598 4 10.6326 4 10.5ZM4 6.5C4 6.36739 4.05268 6.24021 4.14645 6.14645C4.24021 6.05268 4.36739 6 4.5 6H6.5C6.63261 6 6.75979 6.05268 6.85355 6.14645C6.94732 6.24021 7 6.36739 7 6.5C7 6.63261 6.94732 6.75979 6.85355 6.85355C6.75979 6.94732 6.63261 7 6.5 7H4.5C4.36739 7 4.24021 6.94732 4.14645 6.85355C4.05268 6.75979 4 6.63261 4 6.5ZM9 6.5C9 6.36739 9.05268 6.24021 9.14645 6.14645C9.24021 6.05268 9.36739 6 9.5 6H11.5C11.6326 6 11.7598 6.05268 11.8536 6.14645C11.9473 6.24021 12 6.36739 12 6.5C12 6.63261 11.9473 6.75979 11.8536 6.85355C11.7598 6.94732 11.6326 7 11.5 7H9.5C9.36739 7 9.24021 6.94732 9.14645 6.85355C9.05268 6.75979 9 6.63261 9 6.5Z"
            fill="#FF4A49"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10169_203798">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_10169_203798">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
