import { useMemo } from "react";

import { ChoiceGroupProps } from "../components/molecules/filter/ChoiceGroup/type";
import { FilterChipContent } from "../components/organisms/v2/v2Component/ReportFilterChipList/ReportFilterChip";

export const useSelectedFilter = (filterGroups: ChoiceGroupProps[]): FilterChipContent[] => {
  return useMemo(
    () =>
      filterGroups.flatMap<FilterChipContent>((group) => {
        if (group.uiType === "flat") {
          return group.choiceOptions
            .filter((option) => option.isChecked)
            .map((option) => ({
              name: option.name,
              groupName: group.groupName,
              clearFilter: option.toggleOption,
            }));
        } else {
          return group.choiceSubGroups.flatMap((subGroup) =>
            subGroup.choiceOptions
              .filter((option) => option.isChecked)
              .map((option) => ({
                name: option.name,
                groupName: group.groupName,
                clearFilter: option.toggleOption,
              })),
          );
        }
      }),
    [filterGroups],
  );
};
