import React, { useEffect } from "react";

import { useDropzone } from "react-dropzone";

import { FileDropZone } from "../../../molecules/FileDropZone";

type Props = {
  onImported: (file: File) => void;
};
export const CsvImporter: React.FC<Props> = ({ onImported }) => {
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024 * 1024,
  });
  useEffect(() => {
    if (acceptedFiles.length < 1) return;
    onImported(acceptedFiles[0]);
  }, [onImported, acceptedFiles]);
  return (
    <FileDropZone
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      fileRejection={fileRejections[0]}
      extraText="csvファイルにのみ対応しています。1ファイル10GBまで。"
    />
  );
};
