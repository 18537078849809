import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import { AmeInput } from "../../../atoms/forms/input/AmeInput";
import { Search } from "../../../atoms/icon/Search";
import { ChoosableList } from "../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../atoms/list/ChoosableListItem";
import { AmeDropbox_old } from "../../../molecules/dropbox/AmeDropbox";

interface Props<Value> {
  value?: Value;
  placeholder?: string;
  disabled?: boolean;
  getSuggests: (search: string) => Promise<Value[]>;
  getId: (suggest?: Value) => string;
  getLabel: (suggest: Value) => string;
  renderRow?: (label: string, suggest?: Value) => React.ReactNode;
  getDisabled?: (suggest?: Value) => boolean;
  onChange: (suggest: Value | undefined) => void;
}

export const LaptopSearchDropdown = <Value,>({
  value,
  placeholder,
  disabled,
  getSuggests,
  getId,
  getLabel,
  renderRow,
  getDisabled,
  onChange,
}: Props<Value>) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [suggests, setSuggests] = useState<Value[]>([]);

  const displaySelected = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }
    if (renderRow) {
      return renderRow(getLabel(value), value);
    }
    return getLabel(value);
  }, [renderRow, value, getLabel]);

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery],
  );

  useEffect(() => {
    (async () => {
      const suggestsResult = await getSuggests(searchQuery);
      setSuggests(suggestsResult);
    })();
  }, [getSuggests, searchQuery]);

  return (
    <AmeDropbox_old
      value={value}
      disabled={disabled}
      displaySelected={displaySelected}
      onChange={(e) => onChange(e.target.value)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      inputComponent={
        <AmeInput
          placeholder={placeholder}
          value={searchQuery}
          onChange={onChangeInput}
          endIcon={<Search size={16} />}
        />
      }
    >
      <ChoosableList>
        {suggests.map((suggest) => (
          <ChoosableListItem key={getId(suggest)} value={suggest} disabled={getDisabled && getDisabled(suggest)}>
            {renderRow ? renderRow(getLabel(suggest), suggest) : getLabel(suggest)}
          </ChoosableListItem>
        ))}
        <ChoosableListItem>選択なし</ChoosableListItem>
      </ChoosableList>
    </AmeDropbox_old>
  );
};
