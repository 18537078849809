import { DefaultApi, Configuration } from "@amecloud/api-client/src-openapi-autogen";
import { Auth } from "aws-amplify";

export const API_URL =
  "//" + (window.location.hostname === "localhost" ? "localhost:3333" : "api." + window.location.host);

export const getAuthorization = async (): Promise<string | undefined> => {
  const session = await Auth.currentSession();
  if (session) {
    return session.getIdToken().getJwtToken();
  }
};

export const createApiClient = async () => {
  const headers: {
    authorization?: string;
  } = {};

  const authorization = await getAuthorization();
  if (authorization) {
    headers.authorization = authorization;
  }

  return new DefaultApi(
    new Configuration({
      basePath: API_URL,
      headers,
    }),
  );
};

export const createNonAuthorizedApiClient = () => {
  return new DefaultApi(
    new Configuration({
      basePath: API_URL,
    }),
  );
};
