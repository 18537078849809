import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Close: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g id="Icon">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8539 2.14689C13.9005 2.19334 13.9374 2.24851 13.9626 2.30926C13.9878 2.37001 14.0008 2.43513 14.0008 2.50089C14.0008 2.56666 13.9878 2.63178 13.9626 2.69253C13.9374 2.75327 13.9005 2.80845 13.8539 2.85489L2.8539 13.8549C2.76001 13.9488 2.63267 14.0015 2.4999 14.0015C2.36712 14.0015 2.23979 13.9488 2.1459 13.8549C2.05201 13.761 1.99927 13.6337 1.99927 13.5009C1.99927 13.3681 2.05201 13.2408 2.1459 13.1469L13.1459 2.14689C13.1923 2.10033 13.2475 2.06339 13.3083 2.03818C13.369 2.01297 13.4341 2 13.4999 2C13.5657 2 13.6308 2.01297 13.6915 2.03818C13.7523 2.06339 13.8075 2.10033 13.8539 2.14689Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.14592 2.14689C2.09935 2.19334 2.06241 2.24851 2.0372 2.30926C2.012 2.37001 1.99902 2.43513 1.99902 2.50089C1.99902 2.56666 2.012 2.63178 2.0372 2.69253C2.06241 2.75327 2.09935 2.80845 2.14592 2.85489L13.1459 13.8549C13.2398 13.9488 13.3671 14.0015 13.4999 14.0015C13.6327 14.0015 13.76 13.9488 13.8539 13.8549C13.9478 13.761 14.0005 13.6337 14.0005 13.5009C14.0005 13.3681 13.9478 13.2408 13.8539 13.1469L2.85392 2.14689C2.80747 2.10033 2.7523 2.06339 2.69155 2.03818C2.63081 2.01297 2.56568 2 2.49992 2C2.43415 2 2.36903 2.01297 2.30828 2.03818C2.24754 2.06339 2.19236 2.10033 2.14592 2.14689Z"
          fill="currentColor"
        />
      </g>
    </SvgWrapper>
  );
};
