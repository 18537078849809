import React, { useCallback, useContext, useEffect, useState } from "react";

import { ArrowRight } from "@mui/icons-material";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { CurrentCognitoUserContext } from "../index";

import { LoginButtonWrapper } from "./common/LoginButtonWrapper";
import { LoginDescriptionWrapper } from "./common/LoginDescriptionWrapper";
import { LoginFormsWrapper } from "./common/LoginFormsWrapper";
import { LoginInputWrapper } from "./common/LoginInputWrapper";
import { LoginLogoTitle } from "./common/LoginLogoTitle";
import { LoginPageWrapper } from "./common/LoginPageWrapper";
import { LoginSubTitleWrapper } from "./common/LoginSubTitleWrapper";

type MfaFormType = {
  verificationCode: string;
};

interface Props {
  email?: string;
  password?: string;
}

export const MfaVerification: React.FC<Props> = ({ email, password }) => {
  const { currentCognitoUser, setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);
  const { control, handleSubmit } = useForm<MfaFormType>({
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      verificationCode: "",
    },
  });
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (resendDisabled) {
      timer = setTimeout(() => {
        setResendDisabled(false);
      }, 1000 * 10);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [setResendDisabled, resendDisabled]);

  const resendConfirmationCode = useCallback(
    async (email: string, password: string) => {
      try {
        // 認証コードを再発行するAPIが存在しないため、現状この方法でしか再発行できない...
        // Reference: https://github.com/aws-amplify/amplify-js/issues/6676
        const cognitoUser = await Auth.signIn(email, password);
        enqueueSuccessSnackbar({ title: "認証コードを再送しました。" });
        setCurrentCognitoUser(cognitoUser);
        setResendDisabled(true);
      } catch (err) {
        enqueueErrorSnackbar();
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    [setCurrentCognitoUser, setResendDisabled, enqueueErrorSnackbar, enqueueSuccessSnackbar],
  );

  const onSubmit = async (data: MfaFormType) => {
    try {
      await Auth.confirmSignIn(currentCognitoUser, data.verificationCode, "SMS_MFA");
      setCurrentCognitoUser(await Auth.currentAuthenticatedUser());
    } catch (err) {
      if (err.code === "CodeMismatchException") {
        enqueueErrorSnackbar({ title: "認証コードが違います。" });
        return;
      }
      enqueueErrorSnackbar();
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <LoginPageWrapper>
      <LoginLogoTitle />
      <LoginSubTitleWrapper>二要素認証</LoginSubTitleWrapper>

      <LoginDescriptionWrapper>
        登録済みの電話番号にSMSを送信しました。
        <br />
        受信した認証コードを入力してください。
      </LoginDescriptionWrapper>

      <LoginFormsWrapper onSubmit={handleSubmit(onSubmit)}>
        <LoginInputWrapper>
          <Controller
            name={"verificationCode"}
            control={control}
            rules={{
              required: "認証コードを入力してください。",
            }}
            render={({ field }) => <AmeInput {...field} placeholder={"認証コード"} fullWidth />}
          />
        </LoginInputWrapper>
        <LoginButtonWrapper>
          <AmeButton type="submit" fullWidth>
            OK
          </AmeButton>
        </LoginButtonWrapper>{" "}
        {email !== undefined && password !== undefined ? (
          <LoginButtonWrapper>
            <AmeButton
              variant="outlined"
              onClick={() => resendConfirmationCode(email, password)}
              endIcon={<ArrowRight />}
              disabled={resendDisabled}
            >
              認証コードを再送
            </AmeButton>
          </LoginButtonWrapper>
        ) : null}
      </LoginFormsWrapper>
    </LoginPageWrapper>
  );
};
