/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeChangeSetItem2Response,
    EmployeeChangeSetItem2ResponseFromJSON,
    EmployeeChangeSetItem2ResponseFromJSONTyped,
    EmployeeChangeSetItem2ResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeChangeSetSummaryResponse
 */
export interface EmployeeChangeSetSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    employeeChangeSetId: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    isValid: boolean;
    /**
     * 
     * @type {Array<EmployeeChangeSetItem2Response>}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    changeSets: Array<EmployeeChangeSetItem2Response>;
    /**
     * 
     * @type {number}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    totalChangeSetCount: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    errorCount: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    updateCount: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    insertCount: number;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeChangeSetSummaryResponse
     */
    effectiveFrom: Date;
}

export function EmployeeChangeSetSummaryResponseFromJSON(json: any): EmployeeChangeSetSummaryResponse {
    return EmployeeChangeSetSummaryResponseFromJSONTyped(json, false);
}

export function EmployeeChangeSetSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeChangeSetSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeChangeSetId': json['employeeChangeSetId'],
        'isValid': json['isValid'],
        'changeSets': ((json['changeSets'] as Array<any>).map(EmployeeChangeSetItem2ResponseFromJSON)),
        'totalChangeSetCount': json['totalChangeSetCount'],
        'errorCount': json['errorCount'],
        'updateCount': json['updateCount'],
        'insertCount': json['insertCount'],
        'effectiveFrom': (new Date(json['effectiveFrom'])),
    };
}

export function EmployeeChangeSetSummaryResponseToJSON(value?: EmployeeChangeSetSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeChangeSetId': value.employeeChangeSetId,
        'isValid': value.isValid,
        'changeSets': ((value.changeSets as Array<any>).map(EmployeeChangeSetItem2ResponseToJSON)),
        'totalChangeSetCount': value.totalChangeSetCount,
        'errorCount': value.errorCount,
        'updateCount': value.updateCount,
        'insertCount': value.insertCount,
        'effectiveFrom': (value.effectiveFrom.toISOString()),
    };
}


