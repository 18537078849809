import {
  ChangeSetErrorType,
  ChangeSetItemAttributeResponse,
  EmployeeChangeSetItem2Response,
  EmployeeChangeSetSummaryResponse,
} from "../store/autogenApi";

export class EmployeeChangeSet {
  constructor(
    readonly employeeChangeSetId: string,
    readonly isValid: boolean,
    readonly totalChangeSetCount: number,
    readonly updatedCount: number,
    readonly insertedCount: number,
    readonly errorCount: number,
    readonly noChangedCount: number,
    readonly effectiveFrom: string,
    readonly changeSets: EmployeeChangeSetItem[],
  ) {}

  public static fromResponse(res: EmployeeChangeSetSummaryResponse): EmployeeChangeSet {
    return new EmployeeChangeSet(
      res.employeeChangeSetId,
      res.isValid,
      res.totalChangeSetCount,
      res.updateCount,
      res.insertCount,
      res.errorCount,
      0,
      res.effectiveFrom,
      res.changeSets.map((item) => EmployeeChangeSetItem.fromResponse(item, res.employeeChangeSetId)),
    );
  }
}

export class EmployeeInfoChangeRequest {
  constructor(
    readonly requestValue?: string,
    readonly currentValue?: string,
  ) {}

  public static fromData(requestValue?: string, currentValue?: string): EmployeeInfoChangeRequest {
    return new EmployeeInfoChangeRequest(requestValue, currentValue);
  }
}

export class EmployeeChangeSetItem {
  constructor(
    readonly employeeChangeSetId: string,
    readonly rowNumber: number,
    readonly customerCreatedId: string,
    readonly name: string,
    readonly email: string,
    readonly hireDate: string,
    readonly retireDate: string,
    readonly dateOfBirth: string,
    readonly attributes: ChangeSetItemAttribute[],
    readonly infoChangeRequests: {
      name: EmployeeInfoChangeRequest;
      email: EmployeeInfoChangeRequest;
      dateOfBirth: EmployeeInfoChangeRequest;
      hireDate: EmployeeInfoChangeRequest;
      retireDate: EmployeeInfoChangeRequest;
    },
    readonly changeType: string,
    readonly errorMessage?: ChangeSetErrorType,
  ) {}

  public getErrorMessage(): string {
    if (!this.errorMessage) {
      return "";
    }
    switch (this.errorMessage) {
      case "DUPLICATE_TENURE":
        return "在籍期間が重複しています。";
      case "INVALID_HIRE_DATE":
        return "入社日が不正です。退職日と生年月日の間に入社日が存在するようにしてください。";
      case "DUPLICATE_CUSTOMER_CREATED_ID":
        return "従業員番号が重複しています。";
      case "MISSING_ATTRIBUTE_DEFINITION":
        return "属性定義が見つかりません。";
      case "NOT_ALLOWED_TO_CHANGE_RETIRE_DATE":
        return "退職日の修正は許可されていません。";
      case "INCONSISTENT_RETIRE_DATE_AND_EFFECTIVE_FROM":
        return "登録しようとしている退職日より未来に従業員属性の有効日が存在しています。\nCSに問い合わせてください。";
      case "UNKNOWN":
        return "不明なエラーが発生しました。CSに問い合わせてください。";
    }
  }

  public static fromResponse(res: EmployeeChangeSetItem2Response, employeeChangeSetId: string): EmployeeChangeSetItem {
    return new EmployeeChangeSetItem(
      employeeChangeSetId,
      res.rowNumber,
      res.customerCreatedId,
      res.name || "",
      res.email || "",
      res.hireDate!,
      res.retireDate || "",
      res.dateOfBirth || "",
      res.attributes.map(ChangeSetItemAttribute.fromResponse),
      {
        name: EmployeeInfoChangeRequest.fromData(
          res.infoChangeRequests.name.requestValue,
          res.infoChangeRequests.name.currentValue,
        ),
        email: EmployeeInfoChangeRequest.fromData(
          res.infoChangeRequests.email.requestValue,
          res.infoChangeRequests.email.currentValue,
        ),
        dateOfBirth: EmployeeInfoChangeRequest.fromData(
          res.infoChangeRequests.dateOfBirth.requestValue,
          res.infoChangeRequests.dateOfBirth.currentValue,
        ),
        hireDate: EmployeeInfoChangeRequest.fromData(
          res.infoChangeRequests.hireDate.requestValue,
          res.infoChangeRequests.hireDate.currentValue,
        ),
        retireDate: EmployeeInfoChangeRequest.fromData(
          res.infoChangeRequests.retireDate.requestValue,
          res.infoChangeRequests.retireDate.currentValue,
        ),
      },
      res.changeType,
      res.errorMessage,
    );
  }
}

export class ChangeSetItemAttribute {
  constructor(
    readonly attributeKey: string,
    readonly attributeValue?: string,
    readonly isValidValue?: boolean,
    readonly currentValue?: string,
    readonly currentDefinitionId?: string,
  ) {}

  public getChangeType(): "error" | "changed" | "unchanged" | "create" {
    if (!this.isValidValue) {
      return "error";
    }
    if (this.currentValue !== this.attributeValue) {
      return "changed";
    }
    return "unchanged";
  }

  public static fromResponse(res: ChangeSetItemAttributeResponse): ChangeSetItemAttribute {
    return new ChangeSetItemAttribute(
      res.attributeKey,
      res.attributeValue,
      res.isValidValue,
      res.currentValue,
      res.currentDefinitionId,
    );
  }
}
