import React, { useMemo } from "react";

import { InterviewFile, InterviewFileStatus } from "../../../../../models/InterviewFile";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { CenterDotIcon } from "../../../../atoms/icon/CenterDot";
import { CheckV2Icon } from "../../../../atoms/icon/CheckV2";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  interviewFile: InterviewFile;
}

export const InterviewFileStatusCell: React.FC<Props> = ({ interviewFile }) => {
  const icon = useFileStatusIcon(interviewFile.getStatus());
  return (
    <AmeBox
      component="span"
      sx={{ display: "inline-flex", justifyContent: "left", alignItems: "center", gap: "8px", width: "100%" }}
    >
      {icon}
      <AmeTypography fontSize="body2">{interviewFile.getStatusText() || "インタビュー紐付け前"}</AmeTypography>
    </AmeBox>
  );
};

export const useFileStatusIcon = (status: InterviewFileStatus | undefined): React.ReactNode => {
  const theme = useAmeTheme();
  return useMemo(() => {
    switch (status) {
      case "CHECKED":
        return (
          <AmeBox
            component="span"
            sx={{
              color: theme.common.text.success,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CheckV2Icon color="inherit" />
          </AmeBox>
        );
      default:
        return (
          <AmeBox
            component="span"
            sx={{
              color: theme.common.text.gray20,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CenterDotIcon size={6} color="inherit" />
          </AmeBox>
        );
    }
  }, [status, theme]);
};
