import React from "react";

import { SelectChangeEvent } from "@mui/material";

import { HrInterviewQualityReportAxis } from "../../../../store/autogenApi";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { ReportFilterChipList } from "../../v2/v2Component/ReportFilterChipList";
import type { FilterChipContent } from "../../v2/v2Component/ReportFilterChipList/ReportFilterChip";
import { AxisSelect } from "../../v2/v2Component/ReportHeader/AxisSelect";
import { DisplayedInterviewsCount } from "../../v2/v2Component/ReportHeader/DisplayedInterviewsCount";

type Props = {
  count: number;
  selectedFilters: FilterChipContent[];
  selectedAxis: HrInterviewQualityReportAxis;
  onChangeAxis: (e: SelectChangeEvent<HrInterviewQualityReportAxis>) => void;
};

const axisDisplayNames: Record<HrInterviewQualityReportAxis, string> = {
  NONE: "軸なし",
  INTERVIEWER: "面談者",
  MONTHLY: "年月",
};

export const QualityReportHeader: React.FC<Props> = ({ count, selectedFilters, onChangeAxis, selectedAxis }) => {
  return (
    <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "56px" }}>
      <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
        <DisplayedInterviewsCount count={count} />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, flexShrink: 1, overflowX: "scroll", margin: "0 16px", paddingTop: "8px" }}>
        <ReportFilterChipList selectedFilters={selectedFilters} />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
        <AxisSelect axisDisplayNames={axisDisplayNames} selectedAxis={selectedAxis} onChange={onChangeAxis} />
      </AmeBox>
    </AmeBox>
  );
};
