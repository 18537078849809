import React, { useCallback, useMemo } from "react";

import { isEqual } from "lodash";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { HrInterviewType } from "../../../../models/HrInterviewSetting";
import { useRegisterInterviewType } from "../../../../store/hooks/hr-interview-setting";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { PageLayoutBody } from "../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../atoms/layout/PageLayoutWrapper";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { HrInterviewTypeSettingTable, HrInterviewTypesForm } from "./HrInterviewTypesSettingTable";

interface Props {
  defaultInterviewTypes: HrInterviewType[];
}

export const HrInterviewTypesSettingContent: React.FC<Props> = (props) => {
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();

  const registerInterviewType = useRegisterInterviewType();

  const { register, control, handleSubmit, watch } = useForm<HrInterviewTypesForm>({
    defaultValues: {
      hrInterviewTypes: props.defaultInterviewTypes,
    },
  });

  const watchedValues = watch();

  const isFormChanged = useMemo(() => {
    return !isEqual(watchedValues.hrInterviewTypes, props.defaultInterviewTypes);
  }, [watchedValues.hrInterviewTypes, props.defaultInterviewTypes]);

  const onSubmit = useCallback(
    async (data: HrInterviewTypesForm) => {
      try {
        await registerInterviewType({
          registerInterviewTypesRequestBody: {
            types: data.hrInterviewTypes.map((type, index) => ({
              order: index + 1,
              displayName: type.hrInterviewTypeText,
              interviewTypeId: type.hrInterviewTypeId,
            })),
          },
        });

        enqueueSuccessSnackbar({ title: "保存に成功しました。" });
      } catch (error) {
        enqueueErrorSnackbar({ title: "保存に失敗しました。" });
      }
    },
    [registerInterviewType, enqueueSuccessSnackbar, enqueueErrorSnackbar],
  );

  const onSubmitError = useCallback(() => {
    enqueueErrorSnackbar({ title: "赤枠のセルに値が入っていません。" });
  }, [enqueueErrorSnackbar]);

  return (
    <AmeBox component="form" onSubmit={handleSubmit(onSubmit, onSubmitError)}>
      <PageLayoutWrapper>
        <PageLayoutHeader
          title="在職者面談種類"
          endElement={
            <AmeBox component="span" sx={{ display: "inline-flex", gap: "8px", justifyContent: "space-between" }}>
              <AmeButton type="submit" disabled={!isFormChanged}>
                保存
              </AmeButton>
            </AmeBox>
          }
        />
        <PageLayoutBody type="wide">
          <HrInterviewTypeSettingTable register={register} control={control} />
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeBox>
  );
};
