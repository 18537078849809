import React from "react";

import { Control, UseFormRegister, useFieldArray, useFormState } from "react-hook-form";

import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../atoms/table/AmeTable";
import { EditableCell } from "../../../atoms/table/EditableCell";

import { HrInterviewAddingRow } from "./HrInterviewAddingRow";
import { HrInterviewTypeSettingOperationsCell } from "./HrInterviewTypesSettingOperationCell";

export interface HrInterviewTypesForm {
  hrInterviewTypes: {
    hrInterviewTypeId?: string;
    hrInterviewTypeText: string;
  }[];
}

interface Props {
  register: UseFormRegister<HrInterviewTypesForm>;
  control: Control<HrInterviewTypesForm, never>;
}

export const HrInterviewTypeSettingTable: React.FC<Props> = (props) => {
  const { fields, prepend, swap, remove } = useFieldArray({
    control: props.control,
    name: "hrInterviewTypes",
  });
  const formState = useFormState({ control: props.control, name: "hrInterviewTypes" });

  return (
    <React.Fragment>
      <AmeTable useCustomCellWidth>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell width="128px">表示順番</AmeTableHeadCell>
            <AmeTableHeadCell>面談種類</AmeTableHeadCell>
            <AmeTableHeadCell width="384px">操作</AmeTableHeadCell>
          </AmeTableRow>
          <AmeTableRow>
            <AmeTableHeadCell noPadding colSpan={3}>
              <HrInterviewAddingRow append={() => prepend({ hrInterviewTypeId: undefined, hrInterviewTypeText: "" })} />
            </AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {fields.map((field, index) => (
            <AmeTableRow key={field.id} isDense>
              <AmeTableBodyCell>{index + 1}</AmeTableBodyCell>
              <AmeTableBodyCell noPadding>
                <EditableCell
                  {...props.register(`hrInterviewTypes.${index}.hrInterviewTypeText`, { required: "必須項目です。" })}
                  error={Boolean(formState.errors.hrInterviewTypes?.[index]?.hrInterviewTypeText)}
                />
              </AmeTableBodyCell>
              <AmeTableBodyCell>
                <HrInterviewTypeSettingOperationsCell
                  index={index}
                  rowLastIndex={fields.length - 1}
                  swap={swap}
                  hrInterviewTypeId={field.hrInterviewTypeId!}
                  remove={remove}
                />
              </AmeTableBodyCell>
            </AmeTableRow>
          ))}
        </AmeTableBody>
      </AmeTable>
    </React.Fragment>
  );
};
