import React, { useCallback } from "react";

import { Info } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { Employee } from "../../../../../../models/Employee";
import { useGetEmployeeListLazy } from "../../../../../../store/hooks/employees";
import { formatDateToYYYYMMDD } from "../../../../../../utils/formatter";
import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { SearchDropdown } from "../../../../common/SearchDropdown";
import { getUploadStatus, InterviewFileTmpData } from "../../common/useInterviewFileTmpDataList";

interface Props {
  interviewFileMap: InterviewFileTmpData;
  setInterviewFileTmpData: (updateParams: Partial<InterviewFileTmpData>) => void;
  changePage: (page: number) => void;
  page: number;
}

export const FileEmployeeMapping: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const getEmployee = useGetEmployeeListLazy();
  const searchEmployees = useCallback(
    async (search: string) => {
      const result = await getEmployee({
        at: formatDateToYYYYMMDD(new Date()),
        count: 10,
        offset: 0,
        customerCreatedId: search,
      });
      return result.data?.employees.map(Employee.fromResponse) ?? [];
    },
    [getEmployee],
  );

  return (
    <>
      <AmeTypography component="h6">音声データに該当する従業員番号を選択してください。</AmeTypography>
      <AmeBox
        sx={{
          borderRadius: "8px",
          border: `1px solid ${theme.common.border.gray40}`,
          padding: "16px",
          margin: "24px 0",
        }}
      >
        <AmeTypography component="h6">{props.interviewFileMap.file.name}</AmeTypography>
        <AmeTypography fontSize={"caption"}>{getUploadStatus(props.interviewFileMap)}</AmeTypography>
        <AmeBox sx={{ borderTop: `1px solid ${theme.common.border.gray40}`, width: "100%", margin: "16px 0" }} />
        <AmeBox>
          <AmeTypography fontSize="body2">紐付き先従業員番号</AmeTypography>
          <SearchDropdown
            key={props.interviewFileMap.employee?.employeeId}
            disabled={props.interviewFileMap.status !== "uploaded"}
            title={"紐付き先従業員番号"}
            placeholder={"従業員番号を入力してください"}
            currentValue={props.interviewFileMap.employee}
            onSelect={(data) => {
              props.setInterviewFileTmpData({ employee: data });
            }}
            getId={(data: Employee) => data?.employeeId}
            getLabel={(data: Employee) => data?.customerCreatedId || "選択なし"}
            getDisabled={(data: Employee) => !!data?.hasInterviewAssign}
            renderRow={(label: string, employee?: Employee) => (
              <AmeBox>
                <span>{label}</span>
                <span>
                  {employee?.hasInterviewAssign ? (
                    <Tooltip title="すでに紐づけ済みです。" placement="right" sx={{ zIndex: theme.zIndex.tooltip }}>
                      <IconButton size="small">
                        <Info fontSize="inherit" sx={{ color: theme.common.text.gray20 }} />
                      </IconButton>
                    </Tooltip>
                  ) : employee?.retireDate === undefined ? (
                    <Tooltip
                      title="データ上では現職者で登録済みです。"
                      placement="right"
                      sx={{ zIndex: theme.zIndex.tooltip }}
                    >
                      <IconButton size="small">
                        <Info fontSize="inherit" sx={{ color: theme.common.text.gray20 }} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </span>
              </AmeBox>
            )}
            getSuggests={searchEmployees}
          />
        </AmeBox>
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1 }} />
      <AmeBox>
        <AmeButton
          onClick={() => props.changePage(props.page + 1)}
          disabled={props.interviewFileMap.status !== "uploaded"}
          fullWidth
          color={"primary"}
          variant={"contained"}
        >
          次へ
        </AmeButton>
      </AmeBox>
      <Spacer height="12px" />
      <AmeBox>
        <AmeButton
          disabled={props.page === 0}
          onClick={() => props.changePage(props.page - 1)}
          fullWidth
          color={"basic"}
          variant={"outlined"}
        >
          前へ
        </AmeButton>
      </AmeBox>
    </>
  );
};
