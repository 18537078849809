import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { AmeButton } from "../../../../../../../atoms/button/AmeButton";
import { AmeDateInput } from "../../../../../../../atoms/forms/input/AmeDateInput";
import { AmeDropbox } from "../../../../../../../atoms/forms/input/AmeDropbox";
import { BreadcrumbPageLayoutHeader } from "../../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../../atoms/paper/PaperWrapper";
import { AmeTypography } from "../../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../../muiWrapper/AmeBox";

const ElementsEdit: React.FC = () => {
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "従業員一覧", to: "/employees" },
          { title: "従業員詳細", to: "/employees/$employeeId" },
        ]}
        currentTitle="面談概要編集"
      />
      <PageLayoutBody type="basic">
        <PaperWrapper>
          <PaperHeader>属性定義の編集</PaperHeader>
          <PaperBody>
            <AmeTypography>有効日</AmeTypography>
            <AmeDateInput /*value={at} onCommitDate={(date) => ()}*/ />
            <AmeTypography>
              有効日から設定した属性定義が有効となります。退職日以降の日付を有効日とすることはできません。
            </AmeTypography>
            <AmeTypography>部門</AmeTypography>
            <AmeDropbox size="large">プロジェクト事業本部</AmeDropbox>
            <AmeTypography>フリー1</AmeTypography>
            <AmeDropbox size="large">契約社員</AmeDropbox>
            <AmeTypography>フリー2</AmeTypography>
            <AmeDropbox size="large">非常勤嘱託参与</AmeDropbox>
            <AmeTypography>性別</AmeTypography>
            <AmeDropbox size="large">男性</AmeDropbox>
            <AmeBox sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <AmeButton variant="outlined" color="basic" size="small">
                キャンセル
              </AmeButton>
              <AmeButton variant="contained" color="primary" size="small">
                更新
              </AmeButton>
            </AmeBox>
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-admin/employees/$employeeId/attributes/$employeeAttributeId/",
)({
  component: ElementsEdit,
});
