import React, { useCallback, useMemo } from "react";
import { useContext } from "react";

import { EMAIL_REG } from "@amecloud/common-utils/src/utils/Validation";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { PasswordInput } from "../../../../molecules/PasswordInput";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { CurrentCognitoUserContext } from "../index";

import { LoginButtonWrapper } from "./common/LoginButtonWrapper";
import { LoginFormsWrapper } from "./common/LoginFormsWrapper";
import { LoginInputWrapper } from "./common/LoginInputWrapper";
import { LoginLogoTitle } from "./common/LoginLogoTitle";
import { LoginPageWrapper } from "./common/LoginPageWrapper";

import { UserStateBeforeSignIn } from ".";

type SignInFormType = {
  email: string;
  password: string;
};

interface Props {
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  setUserStateBeforeSignIn: (userState: UserStateBeforeSignIn) => void;
}

export const SignIn: React.FC<Props> = ({ setEmail, setPassword, setUserStateBeforeSignIn }) => {
  const { setCurrentCognitoUser, isGlobalCognito, setIsGlobalCognito } = useContext(CurrentCognitoUserContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormType>({
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onSubmit = async (data: SignInFormType) => {
    try {
      const cognitoUser = await Auth.signIn(data.email, data.password);
      setCurrentCognitoUser(cognitoUser);
      setEmail(data.email);
      setPassword(data.password);
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        // CognitoUserAuthenticator 側で currentCognitoUser の変更が検知されて
        // 再レンダリングされ、パスワード変更画面に遷移する
        return;
      }
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        enqueueErrorSnackbar({ title: "ユーザが見つかりません。" });
        return;
      }
      if (err.code === "NotAuthorizedException") {
        enqueueErrorSnackbar({ title: "メールアドレスかパスワードが違います。" });
        return;
      }
      enqueueErrorSnackbar();
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const onClickToForgetPassword = useCallback(() => {
    setUserStateBeforeSignIn("forget-password");
  }, [setUserStateBeforeSignIn]);

  const onClickToUseGlobalCognito = useCallback(() => {
    setIsGlobalCognito(isGlobalCognito === "true" ? "false" : "true");
  }, [isGlobalCognito, setIsGlobalCognito]);

  const toggleGlobalCognitoText = useMemo(() => {
    return isGlobalCognito === "true" ? "運営会社関係者以外の方はこちら" : "運営会社用ログインページ";
  }, [isGlobalCognito]);

  const theme = useAmeTheme();
  return (
    <LoginPageWrapper>
      <LoginLogoTitle />
      {isGlobalCognito === "true" ? (
        <>
          <AmeBox sx={{ color: theme.common.text.black, textAlign: "center" }}>運営会社用ログイン</AmeBox>
          <Spacer height="24px" />
          <AmeBox
            sx={{ padding: "24px", background: theme.common.background.warning20, typography: theme.typography.body2 }}
          >
            <AmeBox sx={{ color: theme.common.text.warning }}>
              このページはHRpentest運営会社(Ame&Company株式会社)関係者専用のログインページです。
            </AmeBox>

            <AmeBox
              onClick={onClickToUseGlobalCognito}
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: theme.brand.secondary[100],
                cursor: "pointer",
                textDecoration: "underline",
                display: "inline",
              }}
            >
              関係者以外の方はこちら
            </AmeBox>
            <AmeBox sx={{ color: theme.common.text.warning, display: "inline" }}>からログインしてください</AmeBox>
          </AmeBox>
          <Spacer height="24px" />
        </>
      ) : null}

      <AmeBox
        sx={{
          padding: "40px 24px",
          border: `1px solid ${theme.common.border.gray40}`,
          background: theme.common.background.white,
          borderRadius: "4px",
        }}
      >
        <LoginFormsWrapper onSubmit={handleSubmit(onSubmit)}>
          <LoginInputWrapper>
            <AmeBox sx={{ margin: "8px 0", color: theme.common.text.black, textAlign: "left" }}>メールアドレス</AmeBox>
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: "メールアドレスを入力してください。",
                pattern: { value: EMAIL_REG, message: "有効なメールアドレスを入力してください。" },
              }}
              render={({ field }) => <AmeInput {...field} placeholder={"メールアドレス"} fullWidth />}
            />
            <AmeBox sx={{ margin: "8px 0", color: theme.common.text.error, textAlign: "left" }}>
              {Object.values(errors.email?.types || {}).join("")}
            </AmeBox>
          </LoginInputWrapper>
          <LoginInputWrapper>
            <AmeBox sx={{ margin: "8px 0", color: theme.common.text.black, textAlign: "left" }}>パスワード</AmeBox>
            <Controller
              name={"password"}
              control={control}
              rules={{ required: "パスワードを入力してください。" }}
              render={({ field, fieldState }) => (
                <PasswordInput
                  field={field}
                  placeholder={"パスワード"}
                  error={fieldState.error !== undefined}
                  errors={errors.password?.types}
                />
              )}
            />
          </LoginInputWrapper>
          <LoginButtonWrapper>
            <AmeButton type="submit" fullWidth>
              ログイン
            </AmeButton>
          </LoginButtonWrapper>
        </LoginFormsWrapper>
        <AmeBox
          onClick={onClickToForgetPassword}
          sx={{
            textAlign: "center",
            fontSize: theme.typography.body1.fontSize,
            fontWeight: "bold",
            color: theme.brand.secondary[100],
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          パスワードを忘れた方はこちら
        </AmeBox>
        <Spacer height="15px" />
        <AmeBox
          onClick={onClickToUseGlobalCognito}
          sx={{
            textAlign: "center",
            fontSize: theme.typography.body1.fontSize,
            fontWeight: "bold",
            color: theme.brand.secondary[100],
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {toggleGlobalCognitoText}
        </AmeBox>
      </AmeBox>
    </LoginPageWrapper>
  );
};
