import React, { useCallback } from "react";

import { createFileRoute } from "@tanstack/react-router";
import { useFieldArray, useForm } from "react-hook-form";

import {
  useDeleteFeedbackQuestion,
  useGetFeedbackSurvey,
  useUpdateFeedbackQuestion,
} from "../../../../../store/hooks/feedback-survey";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";

export const FeedbackSurveySettings: React.FC = () => {
  const getFeedbackSurveySettingQuery = useGetFeedbackSurvey();
  const registerFeedbackAnswerRaw = useUpdateFeedbackQuestion();
  const deleteFeedbackQuestionRaw = useDeleteFeedbackQuestion();
  const registerFeedbackSurvey = useCallback(
    async (data: QuestionFormType) => {
      await registerFeedbackAnswerRaw({
        updateHrInterviewFeedbackQuestionsRequestBody: data.questions.map((q) => ({
          hrInterviewFeedbackQuestionId: q.hrInterviewFeedbackQuestionId,
          question: q.question,
          evaluationItem: q.evaluationItem,
        })),
      });
    },
    [registerFeedbackAnswerRaw],
  );
  const deleteFeedbackQuestion = useCallback(
    async (hrInterviewFeedbackQuestionId?: string) => {
      if (hrInterviewFeedbackQuestionId) {
        await deleteFeedbackQuestionRaw({ hrInterviewFeedbackQuestionId });
      }
    },
    [deleteFeedbackQuestionRaw],
  );

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"面談後ＦＢ設定"} />
      <PageLayoutBody>
        <WaitForSuccess queryState={getFeedbackSurveySettingQuery}>
          {({ questions }) => (
            <React.Fragment>
              <QuestionForm
                deleteFeedbackQuestion={deleteFeedbackQuestion}
                registerFeedbackQuestion={registerFeedbackSurvey}
                defaultQuestions={questions}
              />
            </React.Fragment>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type FormType = {
  hrInterviewFeedbackQuestionId?: string;
  question: string;
  evaluationItem: string;
};

type QuestionFormType = {
  questions: FormType[];
};

const QuestionForm: React.FC<{
  defaultQuestions: FormType[];
  registerFeedbackQuestion: (data: QuestionFormType) => void;
  deleteFeedbackQuestion: (hrInterviewFeedbackQuestionId?: string) => void;
}> = ({ registerFeedbackQuestion, defaultQuestions, deleteFeedbackQuestion }) => {
  const { register, control, handleSubmit } = useForm<QuestionFormType>({
    defaultValues: { questions: defaultQuestions },
  });
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "questions",
  });

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>
          <div style={{ display: "inline-block" }}>
            id: {field.hrInterviewFeedbackQuestionId}
            <br />
            <input {...register(`questions.${index}.question`, { required: "必須です" })} placeholder={"設問名"} />
            <br />
            <input
              {...register(`questions.${index}.evaluationItem`, { required: "必須です" })}
              placeholder={"評価項目"}
            />
          </div>
          <div style={{ display: "inline-block" }}>
            <AmeButton
              onClick={() => {
                deleteFeedbackQuestion(field.hrInterviewFeedbackQuestionId);
                remove(index);
              }}
            >
              削除
            </AmeButton>
          </div>

          <br />
          <br />
        </div>
      ))}
      <AmeButton
        onClick={() =>
          append({
            hrInterviewFeedbackQuestionId: undefined,
            question: "",
            evaluationItem: "",
          })
        }
      >
        追加
      </AmeButton>
      <br />
      <br />
      <br />
      <br />
      <br />
      <AmeButton onClick={handleSubmit(registerFeedbackQuestion)}>登録</AmeButton>
    </>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/feedback-surveys")({
  component: FeedbackSurveySettings,
});
