/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LabelType,
    LabelTypeFromJSON,
    LabelTypeFromJSONTyped,
    LabelTypeToJSON,
    NominalTypingDocumentString,
    NominalTypingDocumentStringFromJSON,
    NominalTypingDocumentStringFromJSONTyped,
    NominalTypingDocumentStringToJSON,
} from './';

/**
 * ラベリング済みのドキュメント。
 * ラベリング結果と、解析結果が付与されている。
 * @export
 * @interface LabeledDocumentResponse
 */
export interface LabeledDocumentResponse {
    /**
     * 
     * @type {NominalTypingDocumentString}
     * @memberof LabeledDocumentResponse
     */
    documentId: NominalTypingDocumentString;
    /**
     * 
     * @type {string}
     * @memberof LabeledDocumentResponse
     */
    content: string;
    /**
     * 
     * @type {LabelType}
     * @memberof LabeledDocumentResponse
     */
    label: LabelType;
    /**
     * 
     * @type {boolean}
     * @memberof LabeledDocumentResponse
     */
    predicted: boolean;
}

export function LabeledDocumentResponseFromJSON(json: any): LabeledDocumentResponse {
    return LabeledDocumentResponseFromJSONTyped(json, false);
}

export function LabeledDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabeledDocumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': NominalTypingDocumentStringFromJSON(json['documentId']),
        'content': json['content'],
        'label': LabelTypeFromJSON(json['label']),
        'predicted': json['predicted'],
    };
}

export function LabeledDocumentResponseToJSON(value?: LabeledDocumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': NominalTypingDocumentStringToJSON(value.documentId),
        'content': value.content,
        'label': LabelTypeToJSON(value.label),
        'predicted': value.predicted,
    };
}


