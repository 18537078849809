import React, { ChangeEventHandler, forwardRef } from "react";

import { AmeTextField, AmeTextFieldProps } from "../AmeTextField";

type Props = Omit<AmeTextFieldProps, "multiline"> & { onChange?: ChangeEventHandler<HTMLTextAreaElement> };

export const AmeTextarea = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return <AmeTextField {...props} ref={ref} multiline />;
});

AmeTextarea.displayName = AmeTextarea.name;
