import React, { ChangeEvent, useCallback, useState, useMemo } from "react";

import {
  AGE_CONDITIONS_OPTIONS,
  MONTHS_OF_SERVICE_CONDITIONS_OPTIONS,
  CalculationStrategyMap,
} from "@amecloud/common-utils/src/constants/calculatedSegmentDefinition";

import { CalculatedSegmentKey } from "../../../../../store/autogenApi";
import { useGetSetting } from "../../../../../store/hooks/settings";
import { ChoosableList } from "../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../atoms/list/ChoosableListItem";
import { AmeTableBodyCell, AmeTableRow } from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeDropbox_old } from "../../../../molecules/dropbox/AmeDropbox";
interface Props {
  segmentKey: CalculatedSegmentKey;
  currentStrategy: string;
  onDefinitionUpdate: (segmentKey: CalculatedSegmentKey, strategy: string) => void;
}

type Option = {
  key: string;
  label: string;
};

export const GraphSegmentSettingTableRow: React.FC<Props> = ({ segmentKey, currentStrategy, onDefinitionUpdate }) => {
  const options = useMemo(() => getSegmentKeyOptions(segmentKey), [segmentKey]);
  const settingQuery = useGetSetting();

  const [option, setOption] = useState<string>(currentStrategy);
  const optionObject = useMemo(
    () => options.find((option) => option.key === currentStrategy),
    [options, currentStrategy],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const strategy = e.target.value;
      onDefinitionUpdate(segmentKey, strategy);
      setOption(strategy);
    },
    [setOption, onDefinitionUpdate, segmentKey],
  );

  return (
    <AmeTableRow>
      <AmeTableBodyCell>
        <WaitForSuccess queryState={settingQuery}>
          {(setting) => <AmeTypography fontSize="body1">{setting.segmentNames[segmentKey]}</AmeTypography>}
        </WaitForSuccess>
      </AmeTableBodyCell>
      <AmeTableBodyCell noPadding>
        <AmeDropbox_old
          disabled={options.length === 1}
          value={option}
          onChange={onChange}
          displaySelected={optionObject?.label}
          forTable
        >
          <ChoosableList>
            {options.map((option) => (
              <ChoosableListItem key={option.key} value={option.key}>
                {option.label}
              </ChoosableListItem>
            ))}
          </ChoosableList>
        </AmeDropbox_old>
      </AmeTableBodyCell>
    </AmeTableRow>
  );
};

const getSegmentKeyOptions = (calculatedSegmentKey: CalculatedSegmentKey): Option[] => {
  const convertToOptions = (strategyMap: CalculationStrategyMap): Option[] => {
    return Object.entries(strategyMap).reduce<Option[]>((prev, [currentKey, currentValue]) => {
      prev.push({
        key: currentKey,
        label: `${currentValue.displayName}(${currentValue.conditions.map(({ mappedTo }) => mappedTo).join(",")})`,
      });
      return prev;
    }, []);
  };
  switch (calculatedSegmentKey) {
    case "AGE":
      return convertToOptions(AGE_CONDITIONS_OPTIONS);
    case "MONTHS_OF_SERVICE":
      return convertToOptions(MONTHS_OF_SERVICE_CONDITIONS_OPTIONS);
  }
};
