import React from "react";

import { Employee } from "../../../../../models/Employee";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AudioFilesImporter } from "../common/AudioFilesImporter";
import { InterviewFileTmpData } from "../common/useInterviewFileTmpDataList";

import { InterviewFileEmployeeMapper } from "./InterviewFileEmployeeMapper";

export const InterviewFileRegisterContent: React.FC<{
  setInterviewFiles: (files: File[]) => void;
  interviewFiles: File[];
  periodId: number;
  uploadInterviewFile: (
    file: File,
    updatePercentage: (percentage: number) => void,
  ) => Promise<{ tmpFilename: string; suggest?: Employee }>;
  onSubmitInterviewFileMaps: (interviewFileMaps: InterviewFileTmpData[]) => Promise<void>;
}> = (props) => {
  return (
    <>
      <AmeTypography component="h6">音声データのファイルを選択してください</AmeTypography>
      <Spacer height="24px" />
      <AudioFilesImporter onImported={props.setInterviewFiles} />
      <Spacer height="24px" />
      {props.interviewFiles.length ? (
        <InterviewFileEmployeeMapper
          files={props.interviewFiles}
          uploadInterviewFile={props.uploadInterviewFile}
          onSubmitInterviewFileMaps={props.onSubmitInterviewFileMaps}
        />
      ) : undefined}
    </>
  );
};
