import React, { PropsWithChildren, useMemo } from "react";

import { Box, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

type Color = "default" | "primary" | "success" | "error" | "warning" | "black" | "inherit" | "white";
export interface SvgIconProps {
  className?: string;
  size?: number;
  color?: Color;
  sx?: SystemStyleObject<Theme>;
  onClick?: React.MouseEventHandler;
}

interface SvgWrapperProps extends SvgIconProps {
  viewBox: string;
}

export const SvgWrapper: React.FC<PropsWithChildren<SvgWrapperProps>> = ({
  size = 14,
  children,
  color = "default",
  viewBox,
  sx = null,
  className,
  onClick,
}) => {
  const colorHex = useColorHex(color);
  return (
    <Box
      onClick={onClick}
      component={"svg"}
      className={className}
      sx={[{ color: colorHex }, sx]}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
    >
      {children}
    </Box>
  );
};

export const useColorHex = (color?: Color) => {
  const theme = useAmeTheme();
  return useMemo(() => {
    switch (color) {
      case "default":
        return theme.common.text.gray20;
      case "primary":
        return theme.brand.primary[100];
      case "success":
        return theme.common.text.success;
      case "error":
        return theme.common.text.error;
      case "warning":
        return theme.common.text.warning;
      case "black":
        return theme.common.text.black;
      case "white":
        return theme.common.text.white;
      case "inherit":
        return "inherit";
      default:
        return undefined;
    }
  }, [color, theme]);
};
