import React, { useCallback } from "react";

import { EMAIL_REG } from "@amecloud/common-utils/src/utils/Validation";
import { useNavigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../../hooks/useHrPentestSnackbar";
import { useSendHrInterviewFeedback } from "../../../../../../store/hooks/hrInterview";
import { AmeFormset } from "../../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../../atoms/forms/input/AmeInput";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeFormButtonsLayout } from "../../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";

interface Props {
  interviewId: string;
  email?: string;
}

export const SendFeedbackEmailContent: React.FC<Props> = ({ interviewId, email }) => {
  const navigate = useNavigate();
  const backToInterviewPage = useCallback(async () => {
    await navigate({ to: "/hr-interviews/interviews/$interviewId", params: { interviewId } });
  }, [interviewId, navigate]);

  const [emailValue, setEmailValue] = React.useState(email ?? "");
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const sendEmail = useSendHrInterviewFeedback();
  const send = useCallback(async () => {
    if (!EMAIL_REG.test(emailValue)) {
      enqueueErrorSnackbar({ title: "メールアドレスが不正です。", message: "メールアドレスを確認してください。" });
      return;
    }
    await sendEmail({ hrInterviewId: interviewId, sendHrInterviewFeedbackRequestBody: { email: emailValue } });
    await backToInterviewPage();
  }, [backToInterviewPage, emailValue, enqueueErrorSnackbar, interviewId, sendEmail]);

  return (
    <>
      <PaperWrapper>
        <PaperHeader>アンケートメール送信</PaperHeader>
        <PaperBody>
          <AmeFormset label={"メールアドレス"} required>
            <AmeInput fullWidth onChange={(e) => setEmailValue(e.target.value)} value={emailValue} />
          </AmeFormset>
        </PaperBody>
      </PaperWrapper>
      <Spacer height={"24px"} />
      <AmeFormButtonsLayout onPrimary={send} primaryText={"送信"} onSecondary={backToInterviewPage} />
    </>
  );
};
