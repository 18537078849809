import React from "react";

import { SubCategoryModel } from "../../../../../../models/SubCategory";
import { AmeChip } from "../../../../../atoms/chip/AmeChip";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  selectedRetirementReasonId: string | undefined;
  subCategory: SubCategoryModel;
  retirementReasonIds: string[];
  onChangeSelectedRetirementReason: (reasonId: string) => void;
}

export const RetirementReasonChip: React.FC<Props> = ({
  selectedRetirementReasonId,
  subCategory,
  retirementReasonIds,
  onChangeSelectedRetirementReason,
}) => {
  return (
    <AmeBox sx={{ width: "240px" }}>
      <AmeChip
        variant="warning"
        onClick={() => onChangeSelectedRetirementReason(subCategory.subCategoryId)}
        selected={selectedRetirementReasonId === subCategory.subCategoryId}
        disabled={!retirementReasonIds.includes(subCategory.subCategoryId)}
        label={subCategory.displayName}
        fullWidth
      />
    </AmeBox>
  );
};
