import React from "react";

import { HrInterviewTranscriptionStatus } from "../../../../models/constants/values";
import { Analyzing } from "../../../atoms/icon/Analyzing";
import { CheckV2Icon } from "../../../atoms/icon/CheckV2";
import { Elipse } from "../../../atoms/icon/Elipse";
import { IconText } from "../../../molecules/common/IconText";

interface Props {
  status?: HrInterviewTranscriptionStatus;
}

export const InterviewTranscriptionStatus = ({ status }: Props): React.ReactElement => {
  switch (status) {
    case "AI_TRANSCRIBING":
    case "BEAUTIFYING":
    case "OVERALL_SUMMARIZING":
    case "TOPIC_SUMMARIZING":
      return <IconText icon={<Analyzing />}>AI文字起こし中</IconText>;
    case "FAILED":
      return <IconText icon={<Elipse color={"error"} />}>エラー</IconText>;
    case "COMPLETED":
      return <IconText icon={<CheckV2Icon color={"success"} />}>AI文字起こし完了</IconText>;
    case undefined:
      return <IconText icon={<Elipse color={"default"} />}>音声データなし</IconText>;
  }
};
