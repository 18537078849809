/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NoteResponse,
    NoteResponseFromJSON,
    NoteResponseFromJSONTyped,
    NoteResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface NoteListResponse
 */
export interface NoteListResponse {
    /**
     * 
     * @type {Array<NoteResponse>}
     * @memberof NoteListResponse
     */
    notes: Array<NoteResponse>;
    /**
     * 
     * @type {number}
     * @memberof NoteListResponse
     */
    totalCount: number;
}

export function NoteListResponseFromJSON(json: any): NoteListResponse {
    return NoteListResponseFromJSONTyped(json, false);
}

export function NoteListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notes': ((json['notes'] as Array<any>).map(NoteResponseFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function NoteListResponseToJSON(value?: NoteListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notes': ((value.notes as Array<any>).map(NoteResponseToJSON)),
        'totalCount': value.totalCount,
    };
}


