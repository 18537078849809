import React, { ReactNode, useMemo } from "react";

import { SystemStyleObject } from "@mui/system";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeButton, AmeButtonColor } from "../../atoms/button/AmeButton";
import { AmeBox } from "../../muiWrapper/AmeBox";

export type DialogVariant = "basic" | "secondary" | "caution" | "warning";

interface Props {
  onClose: () => void;
  onSubmit: () => Promise<void>;
  children: ReactNode;
  title: string;
  variant?: DialogVariant;
  submitButtonText?: string;
  submitButtonDisabled?: boolean;
}

export const AmeDialogLayout: React.FC<Props> = ({ variant = "basic", ...props }) => {
  const submitButtonColor = useSubmitButtonColor(variant);
  const headStyle = useHeadStyles(variant);

  return (
    <>
      <AmeBox sx={headStyle}>{props.title}</AmeBox>
      <AmeBox sx={{ padding: "24px" }}>{props.children}</AmeBox>
      <AmeBox sx={{ display: "flex", justifyContent: "end", gap: "16px", padding: "0 24px 24px 24px" }}>
        <AmeButton variant="outlined" onClick={props.onClose}>
          キャンセル
        </AmeButton>
        <AmeButton
          variant="contained"
          onClick={props.onSubmit}
          color={submitButtonColor}
          disabled={props.submitButtonDisabled}
        >
          {props.submitButtonText ?? "完了"}
        </AmeButton>
      </AmeBox>
    </>
  );
};

const useSubmitButtonColor = (variant: DialogVariant): Extract<AmeButtonColor, "primary" | "warning"> => {
  return useMemo(() => {
    switch (variant) {
      case "basic":
        return "primary";
      case "secondary":
        return "primary";
      case "caution":
        return "primary";
      case "warning":
        return "warning";
    }
  }, [variant]);
};

const useHeadStyles = (variant: DialogVariant) => {
  const theme = useAmeTheme();
  return useMemo(() => {
    const styles: SystemStyleObject[] = [
      {
        padding: "0 24px",
        display: "flex",
        alignItems: "center",
        borderBottom: `1px solid ${theme.common.border.gray40}`,
      },
    ];
    switch (variant) {
      case "basic":
        styles.push({
          ...theme.typography.body1,
          height: "48px",
          backgroundColor: theme.common.background.gray40,
          justifyContent: "start",
          color: theme.common.text.black,
        });
        return styles;
      case "secondary":
        styles.push({
          ...theme.typography.h5,
          height: "56px",
          backgroundColor: theme.brand.secondary[20],
          justifyContent: "start",
          color: theme.brand.secondary[100],
        });
        return styles;
      case "caution":
        styles.push({
          ...theme.typography.h5,
          height: "56px",
          backgroundColor: theme.common.background.warning20,
          justifyContent: "start",
          color: theme.common.text.warning,
        });
        return styles;
      case "warning":
        styles.push({
          ...theme.typography.h5,
          height: "56px",
          backgroundColor: theme.common.background.warning20,
          justifyContent: "start",
          color: theme.common.text.warning,
        });
        return styles;
    }
  }, [theme, variant]);
};
