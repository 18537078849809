import React, { useCallback, useContext, useEffect, useState } from "react";

import { Margin, usePDF } from "react-to-pdf";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../common/DataSourceContext";
import { RetirementReasons } from "../../../../v2/interviews/interviewId/RetirementReasons";

import { InterviewAnalysisScore } from "./InterviewAnalysisScores";
import { InterviewAnswers } from "./InterviewAnswers";
import { InterviewFileInInterviewDetail } from "./InterviewFileInInterviewDetail";
import { InterviewFollowUp } from "./InterviewFollowUp";
import { InterviewHeader } from "./InterviewHeader";
import { InterviewOutline } from "./InterviewOutline";
import { InterviewSummary } from "./InterviewSummary";
import { InterviewWarning } from "./InterviewWarning";
import { IntervieweeAttributes } from "./IntervieweeAttributes";

const useFullPagePDF = (filename: string) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const { toPDF, targetRef } = usePDF({
    filename: filename,
    page: { margin: Margin.MEDIUM },
    overrides: { pdf: { format: [80, (80 * size.height) / size.width], unit: "px" } },
  });

  useEffect(() => {
    const element = targetRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [targetRef]);

  return { toPDF, targetRef };
};

export const InterviewPageContent: React.FC<{ interviewDetail: HrInterviewDetail; highlightedDocumentId?: string }> = ({
  interviewDetail,
  highlightedDocumentId,
}) => {
  const { subCategories } = useContext(DataSourceContext);

  const [selectedRetirementReasonId, setSelectedRetirementReasonId] = useState<string | undefined>(undefined);

  const selectedSubCategory = selectedRetirementReasonId
    ? subCategories.findBySubCategoryId(selectedRetirementReasonId)
    : undefined;

  const onChangeSelectedRetirementReason = useCallback(
    (retirementReasonId: string) => {
      if (selectedRetirementReasonId !== retirementReasonId) {
        setSelectedRetirementReasonId(retirementReasonId);
        return;
      }
      setSelectedRetirementReasonId(undefined);
    },
    [selectedRetirementReasonId],
  );

  const { toPDF, targetRef } = useFullPagePDF(
    `${interviewDetail.interviewType.displayName}_${interviewDetail.employee?.customerCreatedId || "従業員番号なし"}.pdf`,
  );

  return (
    <>
      <InterviewHeader toPDF={() => toPDF()} interviewDetail={interviewDetail} />
      <InterviewWarning interviewDetail={interviewDetail} />
      <AmeBox
        ref={targetRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <AmeBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <AmeBox sx={{ flex: 1, marginRight: "16px" }}>
            <InterviewOutline interviewDetail={interviewDetail} />
          </AmeBox>
          <AmeBox sx={{ flex: 1 }}>
            <IntervieweeAttributes interviewDetail={interviewDetail} />
          </AmeBox>
        </AmeBox>
        <InterviewFileInInterviewDetail interviewDetail={interviewDetail} />
        <InterviewSummary interviewDetail={interviewDetail} />
        <InterviewAnalysisScore interviewDetail={interviewDetail} selectedSubCategory={selectedSubCategory} />
        {interviewDetail.analysisStatus === "ANALYZED" ? (
          <RetirementReasons
            subCategories={subCategories.subCategories}
            retirementReasons={interviewDetail.analysisScores || []}
            selectedRetirementReasonId={selectedRetirementReasonId}
            onChangeSelectedRetirementReason={onChangeSelectedRetirementReason}
            title={"AIによる組織課題判定結果"}
          />
        ) : null}
        <InterviewAnswers
          highlightedDocumentId={highlightedDocumentId}
          selectedSubCategoryId={selectedSubCategory?.subCategoryId}
          interviewDetail={interviewDetail}
        />
        <InterviewFollowUp interviewDetail={interviewDetail} />
      </AmeBox>
    </>
  );
};
