import React, { useState } from "react";

import { InputSegmentDefinitionModel } from "../../../../models/Setting";
import { InputSegmentKey } from "../../../../models/constants/values";
import { AmeTable, AmeTableBody, AmeTableHead, AmeTableHeadCell, AmeTableRow } from "../../../atoms/table/AmeTable";

import { SegmentNameRegister } from "./SegmentNameRegister";
import { SegmentRow } from "./SegmentRow";

interface Props {
  definitionDisplayNames: Record<keyof typeof InputSegmentKey, string>;
  sexSegments: InputSegmentDefinitionModel[];
  departmentSegments: InputSegmentDefinitionModel[];
  free1Segments: InputSegmentDefinitionModel[];
  free2Segments: InputSegmentDefinitionModel[];
  registerSegmentName: (inputSegmentKey: keyof typeof InputSegmentKey, displayName: string) => Promise<void>;
}

export const SettingsSegmentsTable: React.FC<Props> = (props) => {
  const [openSegmentNameRegister, setOpenSegmentNameRegister] = useState<keyof typeof InputSegmentKey | undefined>(
    undefined,
  );
  return (
    <>
      {openSegmentNameRegister && (
        <SegmentNameRegister
          inputSegmentKey={openSegmentNameRegister}
          currentSegmentName={props.definitionDisplayNames[openSegmentNameRegister]}
          registerSegmentName={props.registerSegmentName}
          onClose={() => setOpenSegmentNameRegister(undefined)}
        />
      )}
      <AmeTable useCustomCellWidth>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell>属性</AmeTableHeadCell>
            <AmeTableHeadCell>定義</AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          <SegmentRow
            onOpenSegmentNameRegister={setOpenSegmentNameRegister}
            segmentKey={InputSegmentKey.SEX}
            segmentDisplayName={props.definitionDisplayNames[InputSegmentKey.SEX]}
            segmentValues={props.sexSegments.map((d) => d.segmentValue).join(", ")}
          />
          <SegmentRow
            onOpenSegmentNameRegister={setOpenSegmentNameRegister}
            segmentKey={InputSegmentKey.DEPARTMENT}
            segmentDisplayName={props.definitionDisplayNames[InputSegmentKey.DEPARTMENT]}
            segmentValues={props.departmentSegments.map((d) => d.segmentValue).join(", ")}
          />
          <SegmentRow
            onOpenSegmentNameRegister={setOpenSegmentNameRegister}
            segmentKey={InputSegmentKey.FREE1}
            segmentDisplayName={props.definitionDisplayNames[InputSegmentKey.FREE1]}
            segmentValues={props.free1Segments.map((d) => d.segmentValue).join(", ")}
          />
          <SegmentRow
            onOpenSegmentNameRegister={setOpenSegmentNameRegister}
            segmentKey={InputSegmentKey.FREE2}
            segmentDisplayName={props.definitionDisplayNames[InputSegmentKey.FREE2]}
            segmentValues={props.free2Segments.map((d) => d.segmentValue).join(", ")}
          />
        </AmeTableBody>
      </AmeTable>
    </>
  );
};
