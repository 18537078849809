import React from "react";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { Email } from "../../../../atoms/icon/Email";
import { EmailWithQrCode } from "../../../../atoms/icon/EmailWithQrCode";
import { QrCode } from "../../../../atoms/icon/QrCode";

import { SurveyTableBodyCellWithPrefixIconTemplate } from "./SurveyTableBodyCellWithPrefixIconTemplate";

interface Props {
  useQrcode: boolean;
  sendEmail: boolean;
}

export const SurveySendingMethodBodyCellContent: React.FC<Props> = (props) => {
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  if (props.useQrcode && props.sendEmail) {
    return <SurveyTableBodyCellWithPrefixIconTemplate icon={<EmailWithQrCode size={18} color="black" />} text="併用" />;
  }
  if (props.useQrcode) {
    return <SurveyTableBodyCellWithPrefixIconTemplate icon={<QrCode size={16} color="black" />} text="QRコード" />;
  }
  if (props.sendEmail) {
    return <SurveyTableBodyCellWithPrefixIconTemplate icon={<Email size={18} color="black" />} text="メール" />;
  }
  enqueueErrorSnackbar();
  return null;
};
