import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { InterviewTranscriptionStatus } from "../../utils/constants";

import { UseChoiceGroupParams } from "./type";

export const useInterviewFileStatusChoiceGroup = ({
  filterParams,
  navigate,
}: UseChoiceGroupParams<{
  interviewFileStatuses: InterviewTranscriptionStatus[];
}>): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    const followUpRequiredChoices = [
      { value: InterviewTranscriptionStatus.NO_FILE, label: "音声データなし" },
      { value: InterviewTranscriptionStatus.IN_PROGRESS, label: "AI文字起こし中" },
      { value: InterviewTranscriptionStatus.COMPLETED, label: "AI文字起こし完了" },
      { value: InterviewTranscriptionStatus.FAILED, label: "エラー" },
    ];
    return {
      groupName: "音声ステータス",
      uiType: "flat",
      choiceOptions: followUpRequiredChoices.map((choice) => ({
        name: choice.label,
        toggleOption: async () =>
          await navigate({
            search: (prev) => {
              const next = new Set(prev.interviewFileStatuses);
              if (!next.delete(choice.value!)) {
                next.add(choice.value!);
              }
              return { ...prev, interviewFileStatuses: Array.from(next) };
            },
          }),
        isChecked: filterParams.interviewFileStatuses.includes(choice.value),
      })),
    };
  }, [filterParams, navigate]);
};
