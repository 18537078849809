import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Download: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g id="Icon">
        <path
          id="Vector"
          d="M0.5 9.8999C0.632608 9.8999 0.759785 9.95258 0.853553 10.0463C0.947322 10.1401 1 10.2673 1 10.3999V12.8999C1 13.1651 1.10536 13.4195 1.29289 13.607C1.48043 13.7945 1.73478 13.8999 2 13.8999H14C14.2652 13.8999 14.5196 13.7945 14.7071 13.607C14.8946 13.4195 15 13.1651 15 12.8999V10.3999C15 10.2673 15.0527 10.1401 15.1464 10.0463C15.2402 9.95258 15.3674 9.8999 15.5 9.8999C15.6326 9.8999 15.7598 9.95258 15.8536 10.0463C15.9473 10.1401 16 10.2673 16 10.3999V12.8999C16 13.4303 15.7893 13.939 15.4142 14.3141C15.0391 14.6892 14.5304 14.8999 14 14.8999H2C1.46957 14.8999 0.960859 14.6892 0.585786 14.3141C0.210714 13.939 0 13.4303 0 12.8999V10.3999C0 10.2673 0.0526784 10.1401 0.146447 10.0463C0.240215 9.95258 0.367392 9.8999 0.5 9.8999Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M7.64566 11.854C7.6921 11.9006 7.74728 11.9375 7.80802 11.9627C7.86877 11.9879 7.93389 12.0009 7.99966 12.0009C8.06542 12.0009 8.13054 11.9879 8.19129 11.9627C8.25203 11.9375 8.30721 11.9006 8.35366 11.854L11.3537 8.854C11.4475 8.76011 11.5003 8.63278 11.5003 8.5C11.5003 8.36722 11.4475 8.23989 11.3537 8.146C11.2598 8.05211 11.1324 7.99937 10.9997 7.99937C10.8669 7.99937 10.7395 8.05211 10.6457 8.146L8.49966 10.293V1.5C8.49966 1.36739 8.44698 1.24021 8.35321 1.14645C8.25944 1.05268 8.13226 1 7.99966 1C7.86705 1 7.73987 1.05268 7.6461 1.14645C7.55233 1.24021 7.49966 1.36739 7.49966 1.5V10.293L5.35366 8.146C5.25977 8.05211 5.13243 7.99937 4.99966 7.99937C4.86688 7.99937 4.73954 8.05211 4.64566 8.146C4.55177 8.23989 4.49902 8.36722 4.49902 8.5C4.49902 8.63278 4.55177 8.76011 4.64566 8.854L7.64566 11.854Z"
          fill="currentColor"
        />
      </g>
    </SvgWrapper>
  );
};
