import { useCallback, useEffect, useMemo, useState } from "react";

import { useHotkeys } from "react-hotkeys-hook";

export type AmeSound = {
  playIfAutoPlayIsEnabled: (sec?: number) => void;
  autoPlay: boolean;
  togglePlay: (sec?: number) => void;
  isPlaying: boolean;
  backward: () => void;
  forward: () => void;
  toggleAutoPlay: () => void;
  incrementRate: () => void;
  changeRate: (rate: number) => void;
  sound: Howl;
  rate: number;
};

export const useAmeSound = (sound: Howl): AmeSound => {
  const [rate, setRate] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    sound?.on("play", () => {
      setIsPlaying(true);
    });
    sound?.on("pause", () => {
      setIsPlaying(false);
    });
    return () => {
      sound?.off("play");
      sound?.off("pause");
    };
  }, [setIsPlaying, sound]);

  useEffect(() => {
    return () => {
      sound?.stop();
    };
  }, [sound]);

  const toggleAutoPlay = useCallback(() => {
    setAutoPlay((current) => !current);
  }, [setAutoPlay]);

  const play = useCallback(
    (sec?: number) => {
      if (sec !== undefined) {
        sound?.seek(sec);
      }
      sound?.play();
    },
    [sound],
  );

  const togglePlay = useCallback(
    (sec?: number) => {
      if (sound?.playing()) {
        sound?.pause();
      } else {
        play(sec);
      }
    },
    [play, sound],
  );

  const backward = useCallback(() => {
    sound?.seek(Math.max(sound?.seek() - 5, 0));
  }, [sound]);
  const forward = useCallback(() => {
    sound?.seek(Math.min(sound?.seek() + 5, sound?.duration()));
  }, [sound]);

  useHotkeys("Escape", () => togglePlay());
  useHotkeys("Space", () => togglePlay());
  useHotkeys("ArrowLeft", () => backward());
  useHotkeys("ArrowRight", () => forward());

  const incrementRate = useCallback(() => {
    setRate((current) => {
      const next = current + 0.25 > 2 ? 0.25 : current + 0.25;
      sound.rate(next);
      return next;
    });
  }, [setRate, sound]);

  const playIfAutoPlayIsEnabled = useCallback(
    (sec?: number) => {
      if (autoPlay) {
        play(sec);
      }
    },
    [play, autoPlay],
  );
  const changeRate = useCallback(
    (rate: number) => {
      sound.rate(rate);
      setRate(rate);
    },
    [setRate, sound],
  );

  return useMemo(
    () => ({
      playIfAutoPlayIsEnabled,
      autoPlay,
      togglePlay,
      isPlaying,
      backward,
      forward,
      toggleAutoPlay,
      incrementRate,
      sound: sound,
      rate: rate,
      changeRate: changeRate,
    }),
    [
      autoPlay,
      backward,
      changeRate,
      forward,
      incrementRate,
      isPlaying,
      playIfAutoPlayIsEnabled,
      rate,
      sound,
      toggleAutoPlay,
      togglePlay,
    ],
  );
};
