import React from "react";

import { Tooltip, TooltipProps } from "@mui/material";

import { AmeTypography } from "../typography/AmeTypography";

type Props = Pick<TooltipProps, "title" | "placement" | "children">;

export const AmeTooltip: React.FC<Props> = (props) => {
  return (
    <Tooltip
      title={
        <AmeTypography fontSize="caption" color="white">
          {props.title}
        </AmeTypography>
      }
      placement={props.placement}
    >
      {props.children}
    </Tooltip>
  );
};
