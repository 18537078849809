import React from "react";

import { Feedback } from "../../../../../../store/hooks/interviewer";
import { formatNumber } from "../../../../../../utils/formatNumber";
import { LineChart } from "../../../../../atoms/charts/LineChart";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";

interface Props {
  feedbacks: Feedback[];
}

export const InterviewerScoreTimeSeriesGraph: React.FC<Props> = ({ feedbacks }) => {
  // グラフは右から左に最新順で出したいので逆順にする。
  const reversedFeedbacks = [...feedbacks].reverse();
  return (
    <PaperWrapper>
      <PaperHeader>品質スコアの時系列推移</PaperHeader>
      <PaperBody>
        <LineChart
          series={reversedFeedbacks.map((feedback) => feedback.qualityScore)}
          seriesLabels={reversedFeedbacks.map((feedbacks) => feedbacks.originalFileName)}
          formatter={(value) => `${formatNumber(value)}点`}
          height={300}
          max={100}
        />
      </PaperBody>
    </PaperWrapper>
  );
};
