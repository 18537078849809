import React from "react";

import { Link } from "@tanstack/react-router";

import { useSp } from "../../../../../hooks/useSp";
import { InterviewFile } from "../../../../../models/InterviewFile";
import { formatTime } from "../../../../../utils/formatter";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";

import { InterviewFileStatusCell } from "./InterviewFileStatusCell";
import { LinkedEmployeeIdCell } from "./LinkedEmployeeIdCell";
import { SpInterviewFileTable } from "./SpInterviewFileTable";

interface Props {
  periodId: number;
  interviewFiles: InterviewFile[];
  updateInterviewFile: (interviewFileId: string, snapshotId: string | null) => Promise<void>;
}

export const InterviewFileTable: React.FC<Props> = (props) => {
  const sp = useSp();
  if (sp) {
    return (
      <SpInterviewFileTable
        interviewFiles={props.interviewFiles}
        updateInterviewFile={props.updateInterviewFile}
        periodId={props.periodId}
      />
    );
  }
  return (
    <AmeTable useCustomCellWidth>
      <AmeTableHead>
        <AmeTableRow>
          {/* WARNING: 見栄えのためにwidthを指定しているので、後でカラムを追加する場合は再度調整してください。 */}
          <AmeTableHeadCell width="30%">タイトル</AmeTableHeadCell>
          <AmeTableHeadCell width="10%">紐付き先従業員番号</AmeTableHeadCell>
          <AmeTableHeadCell width="10%">グループ名</AmeTableHeadCell>
          <AmeTableHeadCell width="10%">登録者</AmeTableHeadCell>
          <AmeTableHeadCell width="10%">録音時間</AmeTableHeadCell>
          <AmeTableHeadCell width="10%">インタビュー詳細</AmeTableHeadCell>
          <AmeTableHeadCell width="20%">ステータス</AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {props.interviewFiles.map((file, i) => (
          <AmeTableRow key={i}>
            <AmeTableBodyCell>
              <AmeButton
                variant="text"
                to={`/interview-files/$interviewRecordingId`}
                params={{ interviewRecordingId: file.interviewRecordingId }}
              >
                {file.originalFileName}
              </AmeButton>
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              <LinkedEmployeeIdCell file={file} updateInterviewFile={props.updateInterviewFile} />
            </AmeTableBodyCell>
            <AmeTableBodyCell>{file.userGroup ? file.userGroup.displayName : "グループなし"}</AmeTableBodyCell>
            <AmeTableBodyCell>{file.interviewer ? file.interviewer.getFullName() : "不明なユーザー"}</AmeTableBodyCell>
            <AmeTableBodyCell>{file.duration ? formatTime(file.duration) : "-"}</AmeTableBodyCell>
            <AmeTableBodyCell>
              {file.exEmployeeInterviewId && file.getStatus() === "CHECKED" && (
                <Link to={"/interviews/$interviewId"} params={{ interviewId: file.exEmployeeInterviewId }}>
                  <AmeTypography component={"p"} color={"secondary"}>
                    詳細
                  </AmeTypography>
                </Link>
              )}
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              <InterviewFileStatusCell interviewFile={file} />
            </AmeTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
