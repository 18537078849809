/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEmailSettingsBody
 */
export interface UpdateEmailSettingsBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    feedbackSurveyTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    feedbackSurveyBody?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    feedbackMailSignature?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    feedbackMailBody?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    feedbackMailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    feedbackMailSenderName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    emailSignature?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailSettingsBody
     */
    emailSenderName?: string;
}

export function UpdateEmailSettingsBodyFromJSON(json: any): UpdateEmailSettingsBody {
    return UpdateEmailSettingsBodyFromJSONTyped(json, false);
}

export function UpdateEmailSettingsBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmailSettingsBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbackSurveyTitle': !exists(json, 'feedbackSurveyTitle') ? undefined : json['feedbackSurveyTitle'],
        'feedbackSurveyBody': !exists(json, 'feedbackSurveyBody') ? undefined : json['feedbackSurveyBody'],
        'feedbackMailSignature': !exists(json, 'feedbackMailSignature') ? undefined : json['feedbackMailSignature'],
        'feedbackMailBody': !exists(json, 'feedbackMailBody') ? undefined : json['feedbackMailBody'],
        'feedbackMailSubject': !exists(json, 'feedbackMailSubject') ? undefined : json['feedbackMailSubject'],
        'feedbackMailSenderName': !exists(json, 'feedbackMailSenderName') ? undefined : json['feedbackMailSenderName'],
        'emailSignature': !exists(json, 'emailSignature') ? undefined : json['emailSignature'],
        'emailSenderName': !exists(json, 'emailSenderName') ? undefined : json['emailSenderName'],
    };
}

export function UpdateEmailSettingsBodyToJSON(value?: UpdateEmailSettingsBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbackSurveyTitle': value.feedbackSurveyTitle,
        'feedbackSurveyBody': value.feedbackSurveyBody,
        'feedbackMailSignature': value.feedbackMailSignature,
        'feedbackMailBody': value.feedbackMailBody,
        'feedbackMailSubject': value.feedbackMailSubject,
        'feedbackMailSenderName': value.feedbackMailSenderName,
        'emailSignature': value.emailSignature,
        'emailSenderName': value.emailSenderName,
    };
}


