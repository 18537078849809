import React, { useMemo, useState } from "react";

import { UserGroup, UserGroupList } from "../../../../../../../models/UserGroup";
import { QueryState } from "../../../../../../../store/hooks/utils/parseResult";
import { useAmeTheme } from "../../../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { ChoosableList } from "../../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../../atoms/list/ChoosableListItem";
import { BaseWrapper } from "../../../../../../atoms/wrapper/BaseWrapper";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { EditingGroup, GroupEditMenu } from "./GroupEditMenu";
import { MemberList } from "./MemberList";

interface Props {
  userGroupList: UserGroupList;
  updateGroup: (groupId: string, groupName: string) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;
  useGetGroup: (userGroupId: string) => QueryState<UserGroup>;
}

export const GroupTable: React.FC<Props> = ({ userGroupList, updateGroup, deleteGroup, useGetGroup }) => {
  const theme = useAmeTheme();
  const [editingGroup, setEditingGroup] = useState<EditingGroup | undefined>(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(undefined);
  const selectedGroup = useMemo(
    () => userGroupList.userGroups.find((userGroup) => userGroup.userGroupId === selectedGroupId),
    [userGroupList, selectedGroupId],
  );

  return (
    <>
      {editingGroup ? (
        <GroupEditMenu
          editingGroup={editingGroup}
          onClose={() => setEditingGroup(undefined)}
          editGroupName={updateGroup}
          deleteGroup={deleteGroup}
        />
      ) : null}

      <BaseWrapper>
        <AmeBox sx={{ display: "flex", width: "100%", height: "90vh" }}>
          <AmeBox
            sx={{
              flexGrow: 1,
              flexBasis: 0,
              height: "100%",
              maxHeight: "100%",
              display: "flex",
              flexDirection: "column",
              borderRight: `1px solid ${theme.common.border.gray40}`,
            }}
          >
            <AmeBox
              sx={{
                padding: "16px 8px",
                backgroundColor: theme.common.background.gray40,
                borderBottom: `1px solid ${theme.common.border.gray40}`,
              }}
            >
              グループ
            </AmeBox>
            <AmeBox
              sx={{
                width: "100%",
                borderBottom: `1px solid ${theme.common.border.gray40}`,
                minHeight: 0,
              }}
            >
              <ChoosableList
                selectedValue={selectedGroupId}
                onSelect={(value) => setSelectedGroupId(value ?? undefined)}
              >
                {userGroupList.userGroups.map((userGroup) => (
                  <ChoosableListItem key={userGroup.userGroupId} value={userGroup.userGroupId}>
                    <AmeBox
                      sx={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span>{userGroup.displayName}</span>
                      <AmeButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingGroup({
                            element: e.currentTarget,
                            groupId: userGroup.userGroupId,
                            groupName: userGroup.displayName,
                          });
                        }}
                        variant={"outlined"}
                        color={"basic"}
                        size={"small"}
                      >
                        編集
                      </AmeButton>
                    </AmeBox>
                  </ChoosableListItem>
                ))}
              </ChoosableList>
            </AmeBox>
          </AmeBox>
          <AmeBox sx={{ flexGrow: 1, display: "flex", flexDirection: "column", flexBasis: 0 }}>
            <AmeBox
              sx={{
                padding: "16px 8px",
                backgroundColor: theme.common.background.gray40,
                borderBottom: `1px solid ${theme.common.border.gray40}`,
              }}
            >
              メンバー
            </AmeBox>
            <AmeBox sx={{ padding: "16px", overflow: "auto" }}>
              {selectedGroup ? <MemberList groupId={selectedGroup.userGroupId} useGetGroup={useGetGroup} /> : null}
            </AmeBox>
          </AmeBox>
        </AmeBox>
      </BaseWrapper>
    </>
  );
};
