/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewKpiData
 */
export interface InterviewKpiData {
    /**
     * 
     * @type {string}
     * @memberof InterviewKpiData
     */
    axis: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewKpiData
     */
    turnoverCount: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewKpiData
     */
    interviewCount: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewKpiData
     */
    avgDuration: number;
}

export function InterviewKpiDataFromJSON(json: any): InterviewKpiData {
    return InterviewKpiDataFromJSONTyped(json, false);
}

export function InterviewKpiDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewKpiData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'axis': json['axis'],
        'turnoverCount': json['turnoverCount'],
        'interviewCount': json['interviewCount'],
        'avgDuration': json['avgDuration'],
    };
}

export function InterviewKpiDataToJSON(value?: InterviewKpiData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'axis': value.axis,
        'turnoverCount': value.turnoverCount,
        'interviewCount': value.interviewCount,
        'avgDuration': value.avgDuration,
    };
}


