/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateInterviewFeedbackSettingsQuestion,
    UpdateInterviewFeedbackSettingsQuestionFromJSON,
    UpdateInterviewFeedbackSettingsQuestionFromJSONTyped,
    UpdateInterviewFeedbackSettingsQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateInterviewFeedbackSettingsRequest
 */
export interface UpdateInterviewFeedbackSettingsRequest {
    /**
     * 
     * @type {Array<UpdateInterviewFeedbackSettingsQuestion>}
     * @memberof UpdateInterviewFeedbackSettingsRequest
     */
    questions: Array<UpdateInterviewFeedbackSettingsQuestion>;
}

export function UpdateInterviewFeedbackSettingsRequestFromJSON(json: any): UpdateInterviewFeedbackSettingsRequest {
    return UpdateInterviewFeedbackSettingsRequestFromJSONTyped(json, false);
}

export function UpdateInterviewFeedbackSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInterviewFeedbackSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questions': ((json['questions'] as Array<any>).map(UpdateInterviewFeedbackSettingsQuestionFromJSON)),
    };
}

export function UpdateInterviewFeedbackSettingsRequestToJSON(value?: UpdateInterviewFeedbackSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questions': ((value.questions as Array<any>).map(UpdateInterviewFeedbackSettingsQuestionToJSON)),
    };
}


