import React from "react";

import { useSp } from "../../../hooks/useSp";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeButton } from "../../atoms/button/AmeButton";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

export const InterivewConfirmAlertContent: React.FC<Props> = ({ onClose, onSubmit }) => {
  const sp = useSp();
  const theme = useAmeTheme();

  return (
    <>
      <AmeBox
        sx={{
          padding: "0 24px",
          display: "flex",
          alignItems: "center",
          borderBottom: `1px solid ${theme.common.border.gray40}`,
          height: "56px",
          backgroundColor: theme.common.background.gray40,
          justifyContent: sp ? "center" : "start",
        }}
      >
        <AmeTypography component="h5">確定して解析を開始する</AmeTypography>
      </AmeBox>
      <AmeBox sx={{ padding: "24px" }}>
        <AmeTypography>面談詳細について解析を開始します。</AmeTypography>
        <AmeTypography>確定後は「フォローアップ要否」以外が編集不可となります。</AmeTypography>
      </AmeBox>
      <AmeBox
        sx={{
          display: "flex",
          flexDirection: sp ? "column-reverse" : "row",
          justifyContent: sp ? "center" : "end",
          gap: "16px",
          padding: "0 24px 24px 24px",
        }}
      >
        <AmeButton variant="outlined" onClick={onClose}>
          キャンセル
        </AmeButton>
        <AmeButton variant="contained" onClick={onSubmit} color={"primary"}>
          確定
        </AmeButton>
      </AmeBox>
    </>
  );
};
