import React from "react";

import { AmeCheckbox, AmeCheckboxProps } from "../../atoms/checkbox/AmeCheckbox";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  id?: string;
  checked?: boolean;
  onChange?: AmeCheckboxProps["onChange"];
  label?: string;
}

export const CheckboxWithLabel: React.FC<Props> = ({ id, checked, onChange, label }) => {
  return (
    <AmeBox component={"label"} htmlFor={id} sx={{ display: "flex", gap: "8px" }}>
      <AmeCheckbox id={id} checked={checked} onChange={onChange} />
      <AmeTypography>{label}</AmeTypography>
    </AmeBox>
  );
};
