import React, { useCallback, useMemo, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { HrInterviewFeedbackQuestionResponse } from "../../../../../../store/autogenApi";
import {
  useGetHrInterviewFeedbackQuestions,
  useSubmitHrInterviewFeedbacks,
} from "../../../../../../store/hooks/hr-interview-feedback";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { PublicRouteTemplate } from "../../../../../molecules/layout/PublicRouteTemplate";
import { SurveyTemplate } from "../../../../../organisms/common/SurveyTemplate";
import { SurveyQuestionAnswer } from "../../../../../organisms/common/SurveyTemplate/SurveyTemplateBody";

const AnswerFeedback: React.FC = () => {
  const questionListQuery = useGetHrInterviewFeedbackQuestions();
  // MEMO: 回答済みだったら、回答済みページに遷移した方が良い？
  return (
    <WaitForSuccess queryState={questionListQuery}>
      {({ questions, surveyTitle, surveyBody }) => (
        <AnswerFeedbackContent questionList={questions} title={surveyTitle} description={surveyBody} />
      )}
    </WaitForSuccess>
  );
};

interface Props {
  questionList: HrInterviewFeedbackQuestionResponse[];
  title: string;
  description: string;
}

const AnswerFeedbackContent: React.FC<Props> = ({ questionList, title, description }) => {
  const { interviewId } = Route.useParams();
  const [answerMap, setAnswerMap] = useState<Map<string, SurveyQuestionAnswer>>(new Map());
  const navigate = Route.useNavigate();

  const onChange = useCallback(
    (params: SurveyQuestionAnswer) => {
      const copied = new Map(answerMap);
      copied.set(params.questionId, params);
      setAnswerMap(copied);
    },
    [answerMap],
  );

  const submitHrInterviewFeedbacks = useSubmitHrInterviewFeedbacks();

  const onSubmit = useCallback(async () => {
    // バリデーションを突破しないと、onSubmitが呼ばれない(ボタンが押せない)ので、ここでのバリデーションはしない
    const requestBody = Array.from(answerMap.values()).map((value) => ({
      score: Number(value.value),
      hrInterviewFeedbackQuestionId: value.questionId,
      hrInterviewId: interviewId,
    }));
    const result = await submitHrInterviewFeedbacks({
      createHrInterviewFeedbackAnswersRequestBody: requestBody,
    });
    if (result.isSuccess) {
      await navigate({
        to: `/hr-interviews/interviews/${interviewId}/answer-feedback/complete`,
      });
      return;
    }
  }, [answerMap, interviewId, navigate, submitHrInterviewFeedbacks]);

  const questions = useMemo(
    () => questionList.map((q) => ({ questionId: q.hrInterviewFeedbackQuestionId, questionText: q.question })),
    [questionList],
  );

  return (
    <PublicRouteTemplate>
      <SurveyTemplate
        title={title}
        description={description}
        onSubmit={onSubmit}
        questions={questions}
        answers={answerMap}
        onChange={onChange}
      />
    </PublicRouteTemplate>
  );
};

export const Route = createFileRoute("/hr-interviews/interviews/$interviewId/answer-feedback/")({
  component: AnswerFeedback,
});
