import React from "react";

import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

type SurveyQuestionnaireHeaderProps = {
  total: number;
  answered: number;
  title: string;
};
export const SurveyTemplateHeader: React.FC<SurveyQuestionnaireHeaderProps> = ({ total, answered, title }) => {
  const theme = useAmeTheme();
  const percentage = (answered / total) * 100;
  return (
    <div>
      <AmeBox
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "16px 24px",
        }}
      >
        <AmeBox sx={{ flexGrow: 1 }}>
          <AmeTypography component="h3">{title}</AmeTypography>
        </AmeBox>
        <AmeTypography component="h6">
          <AmeBox component="span" sx={{ color: theme.brand.secondary[100] }}>
            {answered}
          </AmeBox>
          /{total}
        </AmeTypography>
      </AmeBox>
      <BorderLinearProgress variant="determinate" value={percentage} />
    </div>
  );
};

const BorderLinearProgress = styled(LinearProgress)(() => {
  const theme = useAmeTheme();
  return {
    height: "8px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.brand.secondary[20],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "0px 4px 4px 0px",
      backgroundColor: theme.brand.secondary[100],
    },
  };
});
