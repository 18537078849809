import React from "react";

import useSound from "use-sound";

import { useAmeSound } from "../../../../../../../hooks/useAmeSound";
import { HrInterviewTranscription } from "../../../../../../../models/hrInterview/HrInterviewTranscription";
import { ACCEPTABLE_AUDIO_FILE_EXTENSIONS } from "../../../../../../../utils/constants";
import { useAmeTheme } from "../../../../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { FilePlayer } from "./FilePlayer";
import { Paragraph } from "./Paragraph";

interface Props {
  transcription: HrInterviewTranscription;
  copyParagraph?: (paragraph: string) => void;
  interviewFileUrl: string;
}

export const Transcription: React.FC<Props> = ({ copyParagraph, interviewFileUrl, transcription }) => {
  const [, { sound }] = useSound(interviewFileUrl, {
    html5: true,
    interrupt: true,
    format: ACCEPTABLE_AUDIO_FILE_EXTENSIONS,
  });
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        backgroundColor: theme.common.background.gray20,
        width: "100%",
        position: "relative",
      }}
    >
      <TranscriptionContentLoader transcription={transcription} sound={sound} copyParagraph={copyParagraph} />
    </AmeBox>
  );
};

const TranscriptionContentLoader: React.FC<{
  sound: Howl | null;
  copyParagraph?: (paragraph: string) => void;
  transcription: HrInterviewTranscription;
}> = ({ sound, copyParagraph, transcription }) => {
  return (
    <>
      {sound !== null ? (
        <TranscriptionContent transcription={transcription} sound={sound} copyParagraph={copyParagraph} />
      ) : (
        "Loading..."
      )}
    </>
  );
};

const TranscriptionContent: React.FC<{
  sound: Howl;
  copyParagraph?: (paragraph: string) => void;
  transcription: HrInterviewTranscription;
}> = ({ sound, copyParagraph, transcription }) => {
  const ameSound = useAmeSound(sound);
  const theme = useAmeTheme();
  return (
    <>
      <AmeBox
        sx={{
          padding: "24px 20px",
          background: theme.common.background.gray20,
          borderBottom: `1px solid ${theme.common.border.gray40}`,
        }}
      >
        <FilePlayer ameSound={ameSound} />
      </AmeBox>
      <AmeBox
        sx={{
          // ヘッダーを除いたviewportの高さ。
          height: "calc(100vh - 260px)",
          overflowY: "scroll",
          "& > * + *": {
            borderTop: `1px solid ${theme.common.border.gray40}`,
          },
        }}
      >
        {transcription.paragraphs.map((paragraph) => (
          <Paragraph
            key={paragraph.hrInterviewTranscriptionParagraphId}
            copyParagraph={copyParagraph}
            paragraphId={paragraph.hrInterviewTranscriptionParagraphId}
            content={paragraph.content}
            speakerLabel={paragraph.speakerIndex.toString()}
            startTimeSec={paragraph.startTime}
            isPlaying={ameSound.isPlaying}
            togglePlay={ameSound.togglePlay}
          />
        ))}
      </AmeBox>
    </>
  );
};
