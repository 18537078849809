import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { PublicRouteTemplate } from "../../../../../molecules/layout/PublicRouteTemplate";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

const AnswerFeedbackComplete: React.FC = () => {
  const theme = useAmeTheme();
  return (
    <PublicRouteTemplate>
      <AmeBox
        sx={{
          bgcolor: theme.common.background.white,
          border: `1px solid ${theme.common.border.gray40}`,
          borderRadius: "8px",
        }}
      >
        <AmeBox
          component="h5"
          sx={{
            display: "flex",
            justifyContent: "left",
            bgcolor: theme.brand.secondary[20],
            borderBottom: `1px solid ${theme.common.border.gray40}`,
            padding: "16px",
            color: theme.brand.secondary[100],
            ...theme.typography.h5,
          }}
        >
          アンケートを送信しました。
        </AmeBox>
        <AmeBox
          sx={{
            paddingY: "24px",
            paddingX: "16px",
          }}
        >
          <AmeTypography fontSize="body1" textAlign="left">
            ご協力ありがとうございました。
          </AmeTypography>
        </AmeBox>
      </AmeBox>
    </PublicRouteTemplate>
  );
};

export const Route = createFileRoute("/hr-interviews/interviews/$interviewId/answer-feedback/complete")({
  component: AnswerFeedbackComplete,
});
