import React, { useMemo } from "react";

import { removeUndefinedDeep } from "@amecloud/common-utils/src/utils/ObjectUtils";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { formatPercent } from "../../../utils/formatNumber";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";

type CellElement = {
  value: number;
  name: string;
};

export type SeriesElement = {
  name: string;
  data: CellElement[];
};

interface Props {
  series: SeriesElement[];
  height?: number;
  width?: number;
  legend?: {
    show?: boolean;
    formatter?: NonNullable<ApexOptions["legend"]>["formatter"];
  };
  dataLabels?: {
    enabled?: boolean;
    formatter?: ApexDataLabels["formatter"];
  };
  annotations?: ApexOptions["annotations"];
  sortByfirstSeries?: boolean;
  highlightColumnNames?: string[];
}

const CHART_PADDING = 40;
const BAR_MARGIN = 8;
const BAR_HEIGHT = 32;

// react-apexchartsがbarの高さや個数に応じて、いい感じに計算してくれないので、こっちで計算をする。
const getChartHeight = (seriesLength: number) => {
  return CHART_PADDING * 2 + (BAR_MARGIN + BAR_HEIGHT) * seriesLength;
};

export const HorizontalStackedBarChart: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  // 行指向と列指向を入れ替える
  const columnNames = useMemo(() => {
    const names = new Set<string>();
    props.series.forEach((s) => {
      s.data.forEach((cell) => {
        cell.name && names.add(cell.name);
      });
    });
    return Array.from(names);
  }, [props.series]);

  const colors = useMemo(() => {
    return [
      theme.brand.secondary[100],
      theme.brand.secondary[40],
      theme.brand.secondary[20],
      theme.common.background.gray40,
      theme.common.background.gray20,
    ];
  }, [theme]);

  const columnSeries = useMemo(() => {
    const length = columnNames.length;
    const result = Array.from({ length }, (_, i) => ({
      data: props.series.map((s) => s.data.find((cell) => cell.name === columnNames[i])?.value ?? 0),
      color: "", // 並び替えやハイライトを考慮して、後で設定する
      name: columnNames[i],
    }));

    if (props.sortByfirstSeries ?? false) {
      result.sort((a, b) => {
        return b.data[0] - a.data[0];
      });
    }

    result.forEach((cell, i) => {
      const index = props.highlightColumnNames?.indexOf(cell.name) ?? i;
      if (index >= 0) {
        cell.color = colors[Math.min(index, colors.length - 1)] ?? "";
      } else {
        cell.color = colors[colors.length - 1] ?? "";
      }
    });
    return result;
  }, [props.series, columnNames, props.sortByfirstSeries, props.highlightColumnNames, colors]);

  const options: ApexOptions = useMemo(() => {
    const ops: ApexOptions = {
      chart: {
        height: "100%",
        type: "bar",
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
          type: "xy",
        },
      },
      stroke: {
        width: 1,
        colors: [theme.common.border.gray40],
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: BAR_HEIGHT,
        },
      },
      dataLabels: {
        enabled: props.dataLabels?.enabled ?? true,
        style: {
          colors: columnSeries.map((cell) =>
            cell.color === colors[0] ? theme.common.text.white : theme.common.text.black,
          ),
        },
        // デフォルトの位置だと、縦方向の改行がうまくいかないので、少し上にずらす
        offsetY: -8,
        formatter:
          props?.dataLabels?.formatter ??
          ((_, opt) => {
            return [
              opt.w.globals.seriesNames[opt.seriesIndex].slice(0, 5),
              formatPercent(opt.w.globals.seriesPercent[opt.seriesIndex][opt.dataPointIndex]),
            ] as unknown as string;
          }),
      },
      fill: {
        opacity: 1,
      },
      grid: {
        show: false,
      },
      legend: {
        show: props.legend?.show ?? false,
        formatter: props.legend?.formatter,
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      xaxis: {
        categories: props.series.map((cell) => cell.name),
      },
      yaxis: {
        show: true,
        labels: {
          align: "left",
          offsetX: -20, // 日本語のせいか横幅が足りないので調整
        },
      },
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      tooltip: {
        y: {
          formatter: (_, opt) => {
            return formatPercent(opt.w.globals.seriesPercent[opt.seriesIndex][opt.dataPointIndex]);
          },
        },
      },
      annotations: props.annotations,
    };
    return removeUndefinedDeep(ops);
  }, [props, theme, columnSeries, colors]);

  return (
    <ReactApexChart
      height={props.height ?? getChartHeight(props.series.length)}
      width={props.width}
      options={options}
      series={columnSeries}
      type={"bar"}
    />
  );
};
