/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackSettingsQuestion,
    InterviewFeedbackSettingsQuestionFromJSON,
    InterviewFeedbackSettingsQuestionFromJSONTyped,
    InterviewFeedbackSettingsQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFeedbackSettingsResponse
 */
export interface InterviewFeedbackSettingsResponse {
    /**
     * 
     * @type {Array<InterviewFeedbackSettingsQuestion>}
     * @memberof InterviewFeedbackSettingsResponse
     */
    questions: Array<InterviewFeedbackSettingsQuestion>;
}

export function InterviewFeedbackSettingsResponseFromJSON(json: any): InterviewFeedbackSettingsResponse {
    return InterviewFeedbackSettingsResponseFromJSONTyped(json, false);
}

export function InterviewFeedbackSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFeedbackSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questions': ((json['questions'] as Array<any>).map(InterviewFeedbackSettingsQuestionFromJSON)),
    };
}

export function InterviewFeedbackSettingsResponseToJSON(value?: InterviewFeedbackSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questions': ((value.questions as Array<any>).map(InterviewFeedbackSettingsQuestionToJSON)),
    };
}


