import React from "react";

import { HrInterviewAnalysisStatus } from "../../../../models/constants/values";
import { Analyzing } from "../../../atoms/icon/Analyzing";
import { CheckV2Icon } from "../../../atoms/icon/CheckV2";
import { Elipse } from "../../../atoms/icon/Elipse";
import { IconText } from "../../../molecules/common/IconText";

interface Props {
  status?: HrInterviewAnalysisStatus;
}

export const InterviewAnalysisStatus = ({ status }: Props): React.ReactElement => {
  switch (status) {
    case "ANALYZING":
      return <IconText icon={<Analyzing />}>解析中</IconText>;
    case "FAILED":
      return <IconText icon={<Elipse color={"error"} />}>エラー</IconText>;
    case "ANALYZED":
      return <IconText icon={<CheckV2Icon color={"success"} />}>確定済</IconText>;
    case undefined:
      return <IconText icon={<Elipse color={"default"} />}>下書き</IconText>;
  }
};
