import React, { ReactElement } from "react";

import { Link } from "@tanstack/react-router";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { Warning } from "../../../../../atoms/icon/Warning";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { IconText } from "../../../../../molecules/common/IconText";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  interviewDetail: HrInterviewDetail;
}

export const InterviewWarning: React.FC<Props> = ({ interviewDetail }) => {
  const message = createWarningText(
    interviewDetail.hrInterviewId,
    interviewDetail.analysisStatus,
    false,
    interviewDetail.interviewAnswers.every((answer) => answer.answer),
    interviewDetail.transcription?.status,
    false,
  );

  if (message === null) {
    return null;
  }
  return (
    <>
      <IconText icon={<Warning color={"primary"} size={30} />}>
        <AmeBox sx={{ display: "inline-flex", alignItems: "center" }}>{message}</AmeBox>
      </IconText>
      <Spacer height={"16px"} />
    </>
  );
};

const createWarningText = (
  interviewId: string,
  interviewStatus: MappingCandidate[0],
  fbStatus: MappingCandidate[1],
  answerStatus: MappingCandidate[2],
  transcriptionStatus: MappingCandidate[3],
  followUpStatus: MappingCandidate[4],
): Exclude<React.ReactNode, undefined> | null => {
  const messageType = WARNING_MAPPER.find(
    (candidate) =>
      candidate[0] === interviewStatus &&
      candidate[1] === fbStatus &&
      candidate[2] === answerStatus &&
      candidate[3] === transcriptionStatus &&
      candidate[4] === followUpStatus,
  )?.[5];
  if (messageType === null) {
    return null;
  }
  if (messageType === undefined) {
    return "不明なエラーです。CSにお問い合わせください。";
  }
  return createInterviewWarningMessage(messageType, interviewId);
};

const createInterviewWarningMessage = (messageType: WarningMessage, interviewId: string): ReactElement | string => {
  switch (messageType) {
    case "SHOULD_ANALYZE_AND_FOLLOWUP":
      return (
        <>
          「確定して解析」ボタンを押してください。必要に応じて
          <AmeTypography color={"secondary"}>
            <Link to={"/hr-interviews/interviews/$interviewId/followup"} params={{ interviewId: interviewId }}>
              フォローアップ要否
            </Link>
          </AmeTypography>
          を登録してください。
        </>
      );
    case "PLEASE_REGISTER_FILE":
      return (
        <>
          <AmeTypography color={"secondary"}>
            <Link to={"/hr-interviews/interviews/$interviewId/interview-file"} params={{ interviewId: interviewId }}>
              音声データ
            </Link>
          </AmeTypography>
          を登録してください。音声データがない場合は
          <AmeTypography color={"secondary"}>
            <Link to={"/hr-interviews/interviews/$interviewId/transcribe"} params={{ interviewId: interviewId }}>
              議事録
            </Link>
          </AmeTypography>
          を登録してください。
        </>
      );
    case "TRANSCRIBING":
      return "音声データを文字起し中です。文字起しが完了すると、音声データを元にサマリーと議事録が出力されます。";
    case "SUMMARIZING":
      return "サマリ、議事録出力中です。議事録出力後、内容を確認の上必要に応じ編集してください。";
    case "FILE_ERROR":
      return "音声データが登録エラーです。再登録をお願いします。";
    case "SHOULD_ANALYZE":
      return "「確定して解析」ボタンを押してください。";
    case "ANALYZING":
      return "AI解析中です。完了まで30分程かかる可能性があります。";
    case "ANALYSIS_ERROR":
      return "AI解析エラーです。お手数ですがCS担当者まで連絡ください。";
  }
};

const WarningMessage = {
  SHOULD_ANALYZE_AND_FOLLOWUP: "SHOULD_ANALYZE_AND_FOLLOWUP",
  PLEASE_REGISTER_FILE: "PLEASE_REGISTER_FILE",
  TRANSCRIBING: "TRANSCRIBING",
  SUMMARIZING: "SUMMARIZING",
  FILE_ERROR: "FILE_ERROR",
  SHOULD_ANALYZE: "SHOULD_ANALYZE",
  ANALYZING: "ANALYZING",
  ANALYSIS_ERROR: "ANALYSIS_ERROR",
} as const satisfies Record<string, string>;
type WarningMessage = keyof typeof WarningMessage;

type MappingCandidate = [
  undefined | "ANALYZING" | "ANALYZED" | "FAILED",
  boolean | undefined,
  boolean,
  "AI_TRANSCRIBING" | "BEAUTIFYING" | "OVERALL_SUMMARIZING" | "TOPIC_SUMMARIZING" | "COMPLETED" | "FAILED" | undefined,
  boolean | undefined,
  WarningMessage | null,
];

// 頭バグるので、エクセルフローをそのまま書き起こす。あとは知らん。
//  ref: https://docs.google.com/spreadsheets/d/18ThJCNuWftjeOTfiLjtPBm3wVdYlEA35/edit?gid=1467687931#gid=1467687931
const WARNING_MAPPER: MappingCandidate[] = [
  [undefined, undefined, true, undefined, undefined, "SHOULD_ANALYZE_AND_FOLLOWUP"],
  [undefined, false, true, undefined, undefined, "SHOULD_ANALYZE_AND_FOLLOWUP"],
  [undefined, true, true, undefined, undefined, "SHOULD_ANALYZE_AND_FOLLOWUP"],
  [undefined, undefined, false, undefined, undefined, "PLEASE_REGISTER_FILE"],
  [undefined, false, false, undefined, undefined, "PLEASE_REGISTER_FILE"],
  [undefined, true, false, undefined, undefined, "PLEASE_REGISTER_FILE"],
  [undefined, undefined, true, "AI_TRANSCRIBING", undefined, "TRANSCRIBING"],
  [undefined, false, true, "AI_TRANSCRIBING", undefined, "TRANSCRIBING"],
  [undefined, true, true, "AI_TRANSCRIBING", undefined, "TRANSCRIBING"],
  [undefined, undefined, false, "AI_TRANSCRIBING", undefined, "TRANSCRIBING"],
  [undefined, false, false, "AI_TRANSCRIBING", undefined, "TRANSCRIBING"],
  [undefined, true, false, "AI_TRANSCRIBING", undefined, "TRANSCRIBING"],
  [undefined, undefined, true, "BEAUTIFYING", undefined, "TRANSCRIBING"],
  [undefined, false, true, "BEAUTIFYING", undefined, "TRANSCRIBING"],
  [undefined, true, true, "BEAUTIFYING", undefined, "TRANSCRIBING"],
  [undefined, undefined, false, "BEAUTIFYING", undefined, "TRANSCRIBING"],
  [undefined, false, false, "BEAUTIFYING", undefined, "TRANSCRIBING"],
  [undefined, true, false, "BEAUTIFYING", undefined, "TRANSCRIBING"],
  [undefined, undefined, true, "OVERALL_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, false, true, "OVERALL_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, true, true, "OVERALL_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, undefined, false, "OVERALL_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, false, false, "OVERALL_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, true, false, "OVERALL_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, undefined, true, "TOPIC_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, false, true, "TOPIC_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, true, true, "TOPIC_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, undefined, false, "TOPIC_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, false, false, "TOPIC_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, true, false, "TOPIC_SUMMARIZING", undefined, "SUMMARIZING"],
  [undefined, undefined, true, "COMPLETED", undefined, "SHOULD_ANALYZE_AND_FOLLOWUP"],
  [undefined, false, true, "COMPLETED", undefined, "SHOULD_ANALYZE_AND_FOLLOWUP"],
  [undefined, true, true, "COMPLETED", undefined, "SHOULD_ANALYZE_AND_FOLLOWUP"],
  [undefined, undefined, false, "COMPLETED", undefined, "PLEASE_REGISTER_FILE"],
  [undefined, false, false, "COMPLETED", undefined, "PLEASE_REGISTER_FILE"],
  [undefined, true, false, "COMPLETED", undefined, "PLEASE_REGISTER_FILE"],
  [undefined, undefined, true, "FAILED", undefined, "FILE_ERROR"],
  [undefined, false, true, "FAILED", undefined, "FILE_ERROR"],
  [undefined, true, true, "FAILED", undefined, "FILE_ERROR"],
  [undefined, undefined, false, "FAILED", undefined, "FILE_ERROR"],
  [undefined, false, false, "FAILED", undefined, "FILE_ERROR"],
  [undefined, true, false, "FAILED", undefined, "FILE_ERROR"],
  [undefined, undefined, true, undefined, true, "SHOULD_ANALYZE"],
  [undefined, false, true, undefined, true, "SHOULD_ANALYZE"],
  [undefined, true, true, undefined, true, "SHOULD_ANALYZE"],
  [undefined, undefined, false, undefined, true, "PLEASE_REGISTER_FILE"],
  [undefined, false, false, undefined, true, "PLEASE_REGISTER_FILE"],
  [undefined, true, false, undefined, true, "PLEASE_REGISTER_FILE"],
  [undefined, undefined, true, "AI_TRANSCRIBING", true, "TRANSCRIBING"],
  [undefined, false, true, "AI_TRANSCRIBING", true, "TRANSCRIBING"],
  [undefined, true, true, "AI_TRANSCRIBING", true, "TRANSCRIBING"],
  [undefined, undefined, false, "AI_TRANSCRIBING", true, "TRANSCRIBING"],
  [undefined, false, false, "AI_TRANSCRIBING", true, "TRANSCRIBING"],
  [undefined, true, false, "AI_TRANSCRIBING", true, "TRANSCRIBING"],
  [undefined, undefined, true, "BEAUTIFYING", true, "TRANSCRIBING"],
  [undefined, false, true, "BEAUTIFYING", true, "TRANSCRIBING"],
  [undefined, true, true, "BEAUTIFYING", true, "TRANSCRIBING"],
  [undefined, undefined, false, "BEAUTIFYING", true, "TRANSCRIBING"],
  [undefined, false, false, "BEAUTIFYING", true, "TRANSCRIBING"],
  [undefined, true, false, "BEAUTIFYING", true, "TRANSCRIBING"],
  [undefined, undefined, true, "OVERALL_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, false, true, "OVERALL_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, true, true, "OVERALL_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, undefined, false, "OVERALL_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, false, false, "OVERALL_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, true, false, "OVERALL_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, undefined, true, "TOPIC_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, false, true, "TOPIC_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, true, true, "TOPIC_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, undefined, false, "TOPIC_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, false, false, "TOPIC_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, true, false, "TOPIC_SUMMARIZING", true, "SUMMARIZING"],
  [undefined, undefined, true, "COMPLETED", true, "SHOULD_ANALYZE"],
  [undefined, false, true, "COMPLETED", true, "SHOULD_ANALYZE"],
  [undefined, true, true, "COMPLETED", true, "SHOULD_ANALYZE"],
  [undefined, undefined, false, "COMPLETED", true, "PLEASE_REGISTER_FILE"],
  [undefined, false, false, "COMPLETED", true, "PLEASE_REGISTER_FILE"],
  [undefined, true, false, "COMPLETED", true, "PLEASE_REGISTER_FILE"],
  [undefined, undefined, true, "FAILED", true, "FILE_ERROR"],
  [undefined, false, true, "FAILED", true, "FILE_ERROR"],
  [undefined, true, true, "FAILED", true, "FILE_ERROR"],
  [undefined, undefined, false, "FAILED", true, "FILE_ERROR"],
  [undefined, false, false, "FAILED", true, "FILE_ERROR"],
  [undefined, true, false, "FAILED", true, "FILE_ERROR"],
  [undefined, undefined, true, undefined, false, "SHOULD_ANALYZE"],
  [undefined, false, true, undefined, false, "SHOULD_ANALYZE"],
  [undefined, true, true, undefined, false, "SHOULD_ANALYZE"],
  [undefined, undefined, false, undefined, false, "PLEASE_REGISTER_FILE"],
  [undefined, false, false, undefined, false, "PLEASE_REGISTER_FILE"],
  [undefined, true, false, undefined, false, "PLEASE_REGISTER_FILE"],
  [undefined, undefined, true, "AI_TRANSCRIBING", false, "TRANSCRIBING"],
  [undefined, false, true, "AI_TRANSCRIBING", false, "TRANSCRIBING"],
  [undefined, true, true, "AI_TRANSCRIBING", false, "TRANSCRIBING"],
  [undefined, undefined, false, "AI_TRANSCRIBING", false, "TRANSCRIBING"],
  [undefined, false, false, "AI_TRANSCRIBING", false, "TRANSCRIBING"],
  [undefined, true, false, "AI_TRANSCRIBING", false, "TRANSCRIBING"],
  [undefined, undefined, true, "BEAUTIFYING", false, "TRANSCRIBING"],
  [undefined, false, true, "BEAUTIFYING", false, "TRANSCRIBING"],
  [undefined, true, true, "BEAUTIFYING", false, "TRANSCRIBING"],
  [undefined, undefined, false, "BEAUTIFYING", false, "TRANSCRIBING"],
  [undefined, false, false, "BEAUTIFYING", false, "TRANSCRIBING"],
  [undefined, true, false, "BEAUTIFYING", false, "TRANSCRIBING"],
  [undefined, undefined, true, "OVERALL_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, false, true, "OVERALL_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, true, true, "OVERALL_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, undefined, false, "OVERALL_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, false, false, "OVERALL_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, true, false, "OVERALL_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, undefined, true, "TOPIC_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, false, true, "TOPIC_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, true, true, "TOPIC_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, undefined, false, "TOPIC_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, false, false, "TOPIC_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, true, false, "TOPIC_SUMMARIZING", false, "SUMMARIZING"],
  [undefined, undefined, true, "COMPLETED", false, "SHOULD_ANALYZE"],
  [undefined, false, true, "COMPLETED", false, "SHOULD_ANALYZE"],
  [undefined, true, true, "COMPLETED", false, "SHOULD_ANALYZE"],
  [undefined, undefined, false, "COMPLETED", false, "PLEASE_REGISTER_FILE"],
  [undefined, false, false, "COMPLETED", false, "PLEASE_REGISTER_FILE"],
  [undefined, true, false, "COMPLETED", false, "PLEASE_REGISTER_FILE"],
  [undefined, undefined, true, "FAILED", false, "FILE_ERROR"],
  [undefined, false, true, "FAILED", false, "FILE_ERROR"],
  [undefined, true, true, "FAILED", false, "FILE_ERROR"],
  [undefined, undefined, false, "FAILED", false, "FILE_ERROR"],
  [undefined, false, false, "FAILED", false, "FILE_ERROR"],
  [undefined, true, false, "FAILED", false, "FILE_ERROR"],
  ["ANALYZING", undefined, true, undefined, undefined, "ANALYZING"],
  ["ANALYZING", false, true, undefined, undefined, "ANALYZING"],
  ["ANALYZING", true, true, undefined, undefined, "ANALYZING"],
  ["ANALYZING", undefined, false, undefined, undefined, "ANALYZING"],
  ["ANALYZING", false, false, undefined, undefined, "ANALYZING"],
  ["ANALYZING", true, false, undefined, undefined, "ANALYZING"],
  ["ANALYZING", undefined, true, "AI_TRANSCRIBING", undefined, "ANALYZING"],
  ["ANALYZING", false, true, "AI_TRANSCRIBING", undefined, "ANALYZING"],
  ["ANALYZING", true, true, "AI_TRANSCRIBING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, false, "AI_TRANSCRIBING", undefined, "ANALYZING"],
  ["ANALYZING", false, false, "AI_TRANSCRIBING", undefined, "ANALYZING"],
  ["ANALYZING", true, false, "AI_TRANSCRIBING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, true, "BEAUTIFYING", undefined, "ANALYZING"],
  ["ANALYZING", false, true, "BEAUTIFYING", undefined, "ANALYZING"],
  ["ANALYZING", true, true, "BEAUTIFYING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, false, "BEAUTIFYING", undefined, "ANALYZING"],
  ["ANALYZING", false, false, "BEAUTIFYING", undefined, "ANALYZING"],
  ["ANALYZING", true, false, "BEAUTIFYING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, true, "OVERALL_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", false, true, "OVERALL_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", true, true, "OVERALL_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, false, "OVERALL_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", false, false, "OVERALL_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", true, false, "OVERALL_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, true, "TOPIC_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", false, true, "TOPIC_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", true, true, "TOPIC_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, false, "TOPIC_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", false, false, "TOPIC_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", true, false, "TOPIC_SUMMARIZING", undefined, "ANALYZING"],
  ["ANALYZING", undefined, true, "COMPLETED", undefined, "ANALYZING"],
  ["ANALYZING", false, true, "COMPLETED", undefined, "ANALYZING"],
  ["ANALYZING", true, true, "COMPLETED", undefined, "ANALYZING"],
  ["ANALYZING", undefined, false, "COMPLETED", undefined, "ANALYZING"],
  ["ANALYZING", false, false, "COMPLETED", undefined, "ANALYZING"],
  ["ANALYZING", true, false, "COMPLETED", undefined, "ANALYZING"],
  ["ANALYZING", undefined, true, "FAILED", undefined, "ANALYZING"],
  ["ANALYZING", false, true, "FAILED", undefined, "ANALYZING"],
  ["ANALYZING", true, true, "FAILED", undefined, "ANALYZING"],
  ["ANALYZING", undefined, false, "FAILED", undefined, "ANALYZING"],
  ["ANALYZING", false, false, "FAILED", undefined, "ANALYZING"],
  ["ANALYZING", true, false, "FAILED", undefined, "ANALYZING"],
  ["ANALYZING", undefined, true, undefined, true, "ANALYZING"],
  ["ANALYZING", false, true, undefined, true, "ANALYZING"],
  ["ANALYZING", true, true, undefined, true, "ANALYZING"],
  ["ANALYZING", undefined, false, undefined, true, "ANALYZING"],
  ["ANALYZING", false, false, undefined, true, "ANALYZING"],
  ["ANALYZING", true, false, undefined, true, "ANALYZING"],
  ["ANALYZING", undefined, true, "AI_TRANSCRIBING", true, "ANALYZING"],
  ["ANALYZING", false, true, "AI_TRANSCRIBING", true, "ANALYZING"],
  ["ANALYZING", true, true, "AI_TRANSCRIBING", true, "ANALYZING"],
  ["ANALYZING", undefined, false, "AI_TRANSCRIBING", true, "ANALYZING"],
  ["ANALYZING", false, false, "AI_TRANSCRIBING", true, "ANALYZING"],
  ["ANALYZING", true, false, "AI_TRANSCRIBING", true, "ANALYZING"],
  ["ANALYZING", undefined, true, "BEAUTIFYING", true, "ANALYZING"],
  ["ANALYZING", false, true, "BEAUTIFYING", true, "ANALYZING"],
  ["ANALYZING", true, true, "BEAUTIFYING", true, "ANALYZING"],
  ["ANALYZING", undefined, false, "BEAUTIFYING", true, "ANALYZING"],
  ["ANALYZING", false, false, "BEAUTIFYING", true, "ANALYZING"],
  ["ANALYZING", true, false, "BEAUTIFYING", true, "ANALYZING"],
  ["ANALYZING", undefined, true, "OVERALL_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", false, true, "OVERALL_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", true, true, "OVERALL_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", undefined, false, "OVERALL_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", false, false, "OVERALL_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", true, false, "OVERALL_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", undefined, true, "TOPIC_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", false, true, "TOPIC_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", true, true, "TOPIC_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", undefined, false, "TOPIC_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", false, false, "TOPIC_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", true, false, "TOPIC_SUMMARIZING", true, "ANALYZING"],
  ["ANALYZING", undefined, true, "COMPLETED", true, "ANALYZING"],
  ["ANALYZING", false, true, "COMPLETED", true, "ANALYZING"],
  ["ANALYZING", true, true, "COMPLETED", true, "ANALYZING"],
  ["ANALYZING", undefined, false, "COMPLETED", true, "ANALYZING"],
  ["ANALYZING", false, false, "COMPLETED", true, "ANALYZING"],
  ["ANALYZING", true, false, "COMPLETED", true, "ANALYZING"],
  ["ANALYZING", undefined, true, "FAILED", true, "ANALYZING"],
  ["ANALYZING", false, true, "FAILED", true, "ANALYZING"],
  ["ANALYZING", true, true, "FAILED", true, "ANALYZING"],
  ["ANALYZING", undefined, false, "FAILED", true, "ANALYZING"],
  ["ANALYZING", false, false, "FAILED", true, "ANALYZING"],
  ["ANALYZING", true, false, "FAILED", true, "ANALYZING"],
  ["ANALYZING", undefined, true, undefined, false, "ANALYZING"],
  ["ANALYZING", false, true, undefined, false, "ANALYZING"],
  ["ANALYZING", true, true, undefined, false, "ANALYZING"],
  ["ANALYZING", undefined, false, undefined, false, "ANALYZING"],
  ["ANALYZING", false, false, undefined, false, "ANALYZING"],
  ["ANALYZING", true, false, undefined, false, "ANALYZING"],
  ["ANALYZING", undefined, true, "AI_TRANSCRIBING", false, "ANALYZING"],
  ["ANALYZING", false, true, "AI_TRANSCRIBING", false, "ANALYZING"],
  ["ANALYZING", true, true, "AI_TRANSCRIBING", false, "ANALYZING"],
  ["ANALYZING", undefined, false, "AI_TRANSCRIBING", false, "ANALYZING"],
  ["ANALYZING", false, false, "AI_TRANSCRIBING", false, "ANALYZING"],
  ["ANALYZING", true, false, "AI_TRANSCRIBING", false, "ANALYZING"],
  ["ANALYZING", undefined, true, "BEAUTIFYING", false, "ANALYZING"],
  ["ANALYZING", false, true, "BEAUTIFYING", false, "ANALYZING"],
  ["ANALYZING", true, true, "BEAUTIFYING", false, "ANALYZING"],
  ["ANALYZING", undefined, false, "BEAUTIFYING", false, "ANALYZING"],
  ["ANALYZING", false, false, "BEAUTIFYING", false, "ANALYZING"],
  ["ANALYZING", true, false, "BEAUTIFYING", false, "ANALYZING"],
  ["ANALYZING", undefined, true, "OVERALL_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", false, true, "OVERALL_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", true, true, "OVERALL_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", undefined, false, "OVERALL_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", false, false, "OVERALL_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", true, false, "OVERALL_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", undefined, true, "TOPIC_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", false, true, "TOPIC_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", true, true, "TOPIC_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", undefined, false, "TOPIC_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", false, false, "TOPIC_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", true, false, "TOPIC_SUMMARIZING", false, "ANALYZING"],
  ["ANALYZING", undefined, true, "COMPLETED", false, "ANALYZING"],
  ["ANALYZING", false, true, "COMPLETED", false, "ANALYZING"],
  ["ANALYZING", true, true, "COMPLETED", false, "ANALYZING"],
  ["ANALYZING", undefined, false, "COMPLETED", false, "ANALYZING"],
  ["ANALYZING", false, false, "COMPLETED", false, "ANALYZING"],
  ["ANALYZING", true, false, "COMPLETED", false, "ANALYZING"],
  ["ANALYZING", undefined, true, "FAILED", false, "ANALYZING"],
  ["ANALYZING", false, true, "FAILED", false, "ANALYZING"],
  ["ANALYZING", true, true, "FAILED", false, "ANALYZING"],
  ["ANALYZING", undefined, false, "FAILED", false, "ANALYZING"],
  ["ANALYZING", false, false, "FAILED", false, "ANALYZING"],
  ["ANALYZING", true, false, "FAILED", false, "ANALYZING"],
  ["ANALYZED", undefined, true, undefined, undefined, null],
  ["ANALYZED", false, true, undefined, undefined, null],
  ["ANALYZED", true, true, undefined, undefined, null],
  ["ANALYZED", undefined, false, undefined, undefined, null],
  ["ANALYZED", false, false, undefined, undefined, null],
  ["ANALYZED", true, false, undefined, undefined, null],
  ["ANALYZED", undefined, true, "AI_TRANSCRIBING", undefined, null],
  ["ANALYZED", false, true, "AI_TRANSCRIBING", undefined, null],
  ["ANALYZED", true, true, "AI_TRANSCRIBING", undefined, null],
  ["ANALYZED", undefined, false, "AI_TRANSCRIBING", undefined, null],
  ["ANALYZED", false, false, "AI_TRANSCRIBING", undefined, null],
  ["ANALYZED", true, false, "AI_TRANSCRIBING", undefined, null],
  ["ANALYZED", undefined, true, "BEAUTIFYING", undefined, null],
  ["ANALYZED", false, true, "BEAUTIFYING", undefined, null],
  ["ANALYZED", true, true, "BEAUTIFYING", undefined, null],
  ["ANALYZED", undefined, false, "BEAUTIFYING", undefined, null],
  ["ANALYZED", false, false, "BEAUTIFYING", undefined, null],
  ["ANALYZED", true, false, "BEAUTIFYING", undefined, null],
  ["ANALYZED", undefined, true, "OVERALL_SUMMARIZING", undefined, null],
  ["ANALYZED", false, true, "OVERALL_SUMMARIZING", undefined, null],
  ["ANALYZED", true, true, "OVERALL_SUMMARIZING", undefined, null],
  ["ANALYZED", undefined, false, "OVERALL_SUMMARIZING", undefined, null],
  ["ANALYZED", false, false, "OVERALL_SUMMARIZING", undefined, null],
  ["ANALYZED", true, false, "OVERALL_SUMMARIZING", undefined, null],
  ["ANALYZED", undefined, true, "TOPIC_SUMMARIZING", undefined, null],
  ["ANALYZED", false, true, "TOPIC_SUMMARIZING", undefined, null],
  ["ANALYZED", true, true, "TOPIC_SUMMARIZING", undefined, null],
  ["ANALYZED", undefined, false, "TOPIC_SUMMARIZING", undefined, null],
  ["ANALYZED", false, false, "TOPIC_SUMMARIZING", undefined, null],
  ["ANALYZED", true, false, "TOPIC_SUMMARIZING", undefined, null],
  ["ANALYZED", undefined, true, "COMPLETED", undefined, null],
  ["ANALYZED", false, true, "COMPLETED", undefined, null],
  ["ANALYZED", true, true, "COMPLETED", undefined, null],
  ["ANALYZED", undefined, false, "COMPLETED", undefined, null],
  ["ANALYZED", false, false, "COMPLETED", undefined, null],
  ["ANALYZED", true, false, "COMPLETED", undefined, null],
  ["ANALYZED", undefined, true, "FAILED", undefined, null],
  ["ANALYZED", false, true, "FAILED", undefined, null],
  ["ANALYZED", true, true, "FAILED", undefined, null],
  ["ANALYZED", undefined, false, "FAILED", undefined, null],
  ["ANALYZED", false, false, "FAILED", undefined, null],
  ["ANALYZED", true, false, "FAILED", undefined, null],
  ["ANALYZED", undefined, true, undefined, true, null],
  ["ANALYZED", false, true, undefined, true, null],
  ["ANALYZED", true, true, undefined, true, null],
  ["ANALYZED", undefined, false, undefined, true, null],
  ["ANALYZED", false, false, undefined, true, null],
  ["ANALYZED", true, false, undefined, true, null],
  ["ANALYZED", undefined, true, "AI_TRANSCRIBING", true, null],
  ["ANALYZED", false, true, "AI_TRANSCRIBING", true, null],
  ["ANALYZED", true, true, "AI_TRANSCRIBING", true, null],
  ["ANALYZED", undefined, false, "AI_TRANSCRIBING", true, null],
  ["ANALYZED", false, false, "AI_TRANSCRIBING", true, null],
  ["ANALYZED", true, false, "AI_TRANSCRIBING", true, null],
  ["ANALYZED", undefined, true, "BEAUTIFYING", true, null],
  ["ANALYZED", false, true, "BEAUTIFYING", true, null],
  ["ANALYZED", true, true, "BEAUTIFYING", true, null],
  ["ANALYZED", undefined, false, "BEAUTIFYING", true, null],
  ["ANALYZED", false, false, "BEAUTIFYING", true, null],
  ["ANALYZED", true, false, "BEAUTIFYING", true, null],
  ["ANALYZED", undefined, true, "OVERALL_SUMMARIZING", true, null],
  ["ANALYZED", false, true, "OVERALL_SUMMARIZING", true, null],
  ["ANALYZED", true, true, "OVERALL_SUMMARIZING", true, null],
  ["ANALYZED", undefined, false, "OVERALL_SUMMARIZING", true, null],
  ["ANALYZED", false, false, "OVERALL_SUMMARIZING", true, null],
  ["ANALYZED", true, false, "OVERALL_SUMMARIZING", true, null],
  ["ANALYZED", undefined, true, "TOPIC_SUMMARIZING", true, null],
  ["ANALYZED", false, true, "TOPIC_SUMMARIZING", true, null],
  ["ANALYZED", true, true, "TOPIC_SUMMARIZING", true, null],
  ["ANALYZED", undefined, false, "TOPIC_SUMMARIZING", true, null],
  ["ANALYZED", false, false, "TOPIC_SUMMARIZING", true, null],
  ["ANALYZED", true, false, "TOPIC_SUMMARIZING", true, null],
  ["ANALYZED", undefined, true, "COMPLETED", true, null],
  ["ANALYZED", false, true, "COMPLETED", true, null],
  ["ANALYZED", true, true, "COMPLETED", true, null],
  ["ANALYZED", undefined, false, "COMPLETED", true, null],
  ["ANALYZED", false, false, "COMPLETED", true, null],
  ["ANALYZED", true, false, "COMPLETED", true, null],
  ["ANALYZED", undefined, true, "FAILED", true, null],
  ["ANALYZED", false, true, "FAILED", true, null],
  ["ANALYZED", true, true, "FAILED", true, null],
  ["ANALYZED", undefined, false, "FAILED", true, null],
  ["ANALYZED", false, false, "FAILED", true, null],
  ["ANALYZED", true, false, "FAILED", true, null],
  ["ANALYZED", undefined, true, undefined, false, null],
  ["ANALYZED", false, true, undefined, false, null],
  ["ANALYZED", true, true, undefined, false, null],
  ["ANALYZED", undefined, false, undefined, false, null],
  ["ANALYZED", false, false, undefined, false, null],
  ["ANALYZED", true, false, undefined, false, null],
  ["ANALYZED", undefined, true, "AI_TRANSCRIBING", false, null],
  ["ANALYZED", false, true, "AI_TRANSCRIBING", false, null],
  ["ANALYZED", true, true, "AI_TRANSCRIBING", false, null],
  ["ANALYZED", undefined, false, "AI_TRANSCRIBING", false, null],
  ["ANALYZED", false, false, "AI_TRANSCRIBING", false, null],
  ["ANALYZED", true, false, "AI_TRANSCRIBING", false, null],
  ["ANALYZED", undefined, true, "BEAUTIFYING", false, null],
  ["ANALYZED", false, true, "BEAUTIFYING", false, null],
  ["ANALYZED", true, true, "BEAUTIFYING", false, null],
  ["ANALYZED", undefined, false, "BEAUTIFYING", false, null],
  ["ANALYZED", false, false, "BEAUTIFYING", false, null],
  ["ANALYZED", true, false, "BEAUTIFYING", false, null],
  ["ANALYZED", undefined, true, "OVERALL_SUMMARIZING", false, null],
  ["ANALYZED", false, true, "OVERALL_SUMMARIZING", false, null],
  ["ANALYZED", true, true, "OVERALL_SUMMARIZING", false, null],
  ["ANALYZED", undefined, false, "OVERALL_SUMMARIZING", false, null],
  ["ANALYZED", false, false, "OVERALL_SUMMARIZING", false, null],
  ["ANALYZED", true, false, "OVERALL_SUMMARIZING", false, null],
  ["ANALYZED", undefined, true, "TOPIC_SUMMARIZING", false, null],
  ["ANALYZED", false, true, "TOPIC_SUMMARIZING", false, null],
  ["ANALYZED", true, true, "TOPIC_SUMMARIZING", false, null],
  ["ANALYZED", undefined, false, "TOPIC_SUMMARIZING", false, null],
  ["ANALYZED", false, false, "TOPIC_SUMMARIZING", false, null],
  ["ANALYZED", true, false, "TOPIC_SUMMARIZING", false, null],
  ["ANALYZED", undefined, true, "COMPLETED", false, null],
  ["ANALYZED", false, true, "COMPLETED", false, null],
  ["ANALYZED", true, true, "COMPLETED", false, null],
  ["ANALYZED", undefined, false, "COMPLETED", false, null],
  ["ANALYZED", false, false, "COMPLETED", false, null],
  ["ANALYZED", true, false, "COMPLETED", false, null],
  ["ANALYZED", undefined, true, "FAILED", false, null],
  ["ANALYZED", false, true, "FAILED", false, null],
  ["ANALYZED", true, true, "FAILED", false, null],
  ["ANALYZED", undefined, false, "FAILED", false, null],
  ["ANALYZED", false, false, "FAILED", false, null],
  ["ANALYZED", true, false, "FAILED", false, null],
  ["FAILED", undefined, true, undefined, undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, true, undefined, undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, true, undefined, undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, undefined, undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, false, undefined, undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, false, undefined, undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "AI_TRANSCRIBING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "AI_TRANSCRIBING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "AI_TRANSCRIBING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "AI_TRANSCRIBING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "AI_TRANSCRIBING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "AI_TRANSCRIBING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "BEAUTIFYING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "BEAUTIFYING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "BEAUTIFYING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "BEAUTIFYING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "BEAUTIFYING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "BEAUTIFYING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "OVERALL_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "OVERALL_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "OVERALL_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "OVERALL_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "OVERALL_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "OVERALL_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "TOPIC_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "TOPIC_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "TOPIC_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "TOPIC_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "TOPIC_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "TOPIC_SUMMARIZING", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "COMPLETED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "COMPLETED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "COMPLETED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "COMPLETED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "COMPLETED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "COMPLETED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "FAILED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "FAILED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "FAILED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "FAILED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "FAILED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "FAILED", undefined, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, undefined, true, "ANALYSIS_ERROR"],
  ["FAILED", false, true, undefined, true, "ANALYSIS_ERROR"],
  ["FAILED", true, true, undefined, true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, undefined, true, "ANALYSIS_ERROR"],
  ["FAILED", false, false, undefined, true, "ANALYSIS_ERROR"],
  ["FAILED", true, false, undefined, true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "AI_TRANSCRIBING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "AI_TRANSCRIBING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "AI_TRANSCRIBING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "AI_TRANSCRIBING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "AI_TRANSCRIBING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "AI_TRANSCRIBING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "BEAUTIFYING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "BEAUTIFYING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "BEAUTIFYING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "BEAUTIFYING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "BEAUTIFYING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "BEAUTIFYING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "OVERALL_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "OVERALL_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "OVERALL_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "OVERALL_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "OVERALL_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "OVERALL_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "TOPIC_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "TOPIC_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "TOPIC_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "TOPIC_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "TOPIC_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "TOPIC_SUMMARIZING", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "COMPLETED", true, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "COMPLETED", true, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "COMPLETED", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "COMPLETED", true, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "COMPLETED", true, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "COMPLETED", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "FAILED", true, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "FAILED", true, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "FAILED", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "FAILED", true, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "FAILED", true, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "FAILED", true, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, undefined, false, "ANALYSIS_ERROR"],
  ["FAILED", false, true, undefined, false, "ANALYSIS_ERROR"],
  ["FAILED", true, true, undefined, false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, undefined, false, "ANALYSIS_ERROR"],
  ["FAILED", false, false, undefined, false, "ANALYSIS_ERROR"],
  ["FAILED", true, false, undefined, false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "AI_TRANSCRIBING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "AI_TRANSCRIBING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "AI_TRANSCRIBING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "AI_TRANSCRIBING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "AI_TRANSCRIBING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "AI_TRANSCRIBING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "BEAUTIFYING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "BEAUTIFYING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "BEAUTIFYING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "BEAUTIFYING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "BEAUTIFYING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "BEAUTIFYING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "OVERALL_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "OVERALL_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "OVERALL_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "OVERALL_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "OVERALL_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "OVERALL_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "TOPIC_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "TOPIC_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "TOPIC_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "TOPIC_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "TOPIC_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "TOPIC_SUMMARIZING", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "COMPLETED", false, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "COMPLETED", false, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "COMPLETED", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "COMPLETED", false, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "COMPLETED", false, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "COMPLETED", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, true, "FAILED", false, "ANALYSIS_ERROR"],
  ["FAILED", false, true, "FAILED", false, "ANALYSIS_ERROR"],
  ["FAILED", true, true, "FAILED", false, "ANALYSIS_ERROR"],
  ["FAILED", undefined, false, "FAILED", false, "ANALYSIS_ERROR"],
  ["FAILED", false, false, "FAILED", false, "ANALYSIS_ERROR"],
  ["FAILED", true, false, "FAILED", false, "ANALYSIS_ERROR"],
] as const;
