import React from "react";

import { createFileRoute, Outlet } from "@tanstack/react-router";

import { Authenticator } from "../../organisms/common/Authenticator";
import { Authorizer } from "../../organisms/common/Authorizer";
import { DataSourceContextProvider } from "../../organisms/common/DataSourceContext";
import { MyselfContextProvider } from "../../organisms/common/MyselfContext";
import { PageTemplate } from "../../organisms/common/template";

export const AuthLayout: React.FC = () => {
  return (
    <>
      <Authenticator>
        <MyselfContextProvider>
          <Authorizer>
            <DataSourceContextProvider>
              <PageTemplate>
                <Outlet />
              </PageTemplate>
            </DataSourceContextProvider>
          </Authorizer>
        </MyselfContextProvider>
      </Authenticator>
    </>
  );
};
export const Route = createFileRoute("/_authenticated")({
  component: AuthLayout,
});
