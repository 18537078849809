import React from "react";

import { Link } from "@tanstack/react-router";

import { User } from "../../../../../models/User";
import { Alert } from "../../../../atoms/icon/Alert";
import { AmeTableRow, AmeTableBodyCell } from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { IconPopover } from "../../../../molecules/IconPopover/index";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
type Props = {
  user: User;
};

export const UserDataTableRow: React.FC<Props> = (props) => {
  const numberOfBelongingGroups = props.user.userGroups ? props.user.userGroups.length : 0;
  return (
    <AmeTableRow>
      <AmeTableBodyCell>
        <Link to={"/hidden-pages/members/users/$userId"} params={{ userId: props.user.userId! }}>
          <AmeTypography color={"secondary"} fontSize="body1">
            {props.user.getFullName()}
          </AmeTypography>
        </Link>
      </AmeTableBodyCell>
      <AmeTableBodyCell>
        <AmeTypography fontSize="body1">{props.user.isAdmin ? "管理者" : "インタビュワー"}</AmeTypography>
      </AmeTableBodyCell>
      <AmeTableBodyCell>
        <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <AmeTypography fontSize="body1">{numberOfBelongingGroups}</AmeTypography>
          {!numberOfBelongingGroups && !props.user.isAdmin ? (
            <IconPopover icon={<Alert color="warning" />}>
              <AmeBox sx={{ width: "187px", padding: "14px 16px 14px 16px" }}>
                <AmeTypography fontSize="caption" color="basic">
                  グループが未設定です。 詳細画面からグループの設定を行なってください。
                </AmeTypography>
              </AmeBox>
            </IconPopover>
          ) : undefined}
        </AmeBox>
      </AmeTableBodyCell>
      <AmeTableBodyCell>
        <AmeTypography fontSize="body1">{props.user.createdAt?.toLocaleDateString()}</AmeTypography>
      </AmeTableBodyCell>
    </AmeTableRow>
  );
};
