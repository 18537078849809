export const removeUndefined = <T extends Record<string, unknown>>(obj: T): Partial<T> => {
    const newObj: Partial<T> = {};
    for (const key in obj) {
        if (obj[key] !== undefined) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
};

export const removeUndefinedDeep = <T>(obj: T): Partial<T> => {
    const newObj: Partial<T> = {};
    for (const key in obj) {
        if (obj[key] !== undefined) {
            if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
                newObj[key] = removeUndefinedDeep(obj[key] as Record<string, unknown>) as T[Extract<keyof T, string>];
            } else {
                newObj[key] = obj[key];
            }
        }
    }
    return newObj;
};
