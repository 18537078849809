/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Answer,
    AnswerFromJSON,
    AnswerFromJSONTyped,
    AnswerToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmitSurveyAnswerWithCustomerCreatedIdRequestBody
 */
export interface SubmitSurveyAnswerWithCustomerCreatedIdRequestBody {
    /**
     * 
     * @type {Array<Answer>}
     * @memberof SubmitSurveyAnswerWithCustomerCreatedIdRequestBody
     */
    answers: Array<Answer>;
    /**
     * 
     * @type {string}
     * @memberof SubmitSurveyAnswerWithCustomerCreatedIdRequestBody
     */
    customerCreatedId: string;
}

export function SubmitSurveyAnswerWithCustomerCreatedIdRequestBodyFromJSON(json: any): SubmitSurveyAnswerWithCustomerCreatedIdRequestBody {
    return SubmitSurveyAnswerWithCustomerCreatedIdRequestBodyFromJSONTyped(json, false);
}

export function SubmitSurveyAnswerWithCustomerCreatedIdRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitSurveyAnswerWithCustomerCreatedIdRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answers': ((json['answers'] as Array<any>).map(AnswerFromJSON)),
        'customerCreatedId': json['customerCreatedId'],
    };
}

export function SubmitSurveyAnswerWithCustomerCreatedIdRequestBodyToJSON(value?: SubmitSurveyAnswerWithCustomerCreatedIdRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answers': ((value.answers as Array<any>).map(AnswerToJSON)),
        'customerCreatedId': value.customerCreatedId,
    };
}


