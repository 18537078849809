import React from "react";

import { usePeriodsQuery } from "../../../store/hooks/periods";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../molecules/Loading/WaitForSuccess";
import { ChoiceGroup } from "../../molecules/filter/ChoiceGroup";
import { ChoiceGroupProps } from "../../molecules/filter/ChoiceGroup/type";
import { FilterSelect } from "../../molecules/filter/FilterSelect";
import { FilterWrapper } from "../../molecules/filter/FilterWrapper";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  choiceGroupList: ChoiceGroupProps[];
  periodId?: number;
  setPeriod: (periodId: number) => void;
};

export const EmployeeFilter: React.FC<Props> = ({ choiceGroupList, periodId, setPeriod }) => {
  const theme = useAmeTheme();
  const periodsQuery = usePeriodsQuery();

  return (
    <WaitForSuccess queryState={periodsQuery}>
      {(periods) => (
        <FilterWrapper>
          <AmeBox sx={{ borderBottom: `1px solid ${theme.common.border.gray40}` }}>
            <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "8px", padding: "16px", flexShrink: 1 }}>
              <AmeTypography component="h6">{"対象期間"}</AmeTypography>
              <AmeTypography fontSize="body2">{"会計期間"}</AmeTypography>
              <FilterSelect
                options={periods.map((period) => ({
                  queryValue: period.periodId.toString(),
                  name: period.getDisplayName(),
                }))}
                selectedOption={periodId?.toString() ?? null}
                setFilterParams={(_, periodId) => setPeriod(parseInt(periodId))}
                filterParamKey="periodId"
              />
              {/*<AmeTypography fontSize="body2">{"月(未整備)"}</AmeTypography>*/}
              {/*<MonthFilter*/}
              {/*  selectedFromMonth={searchParams.get("fromMonth")}*/}
              {/*  selectedToMonth={searchParams.get("toMonth")}*/}
              {/*  setMonth={setMonth}*/}
              {/*/>*/}
            </AmeBox>
          </AmeBox>

          {choiceGroupList.map((choiceGroup, index) => {
            return <ChoiceGroup key={index} {...choiceGroup} />;
          })}
        </FilterWrapper>
      )}
    </WaitForSuccess>
  );
};
