import React, { useCallback, useContext } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";

import {
  ChangeSetItemAttribute,
  EmployeeChangeSetItem,
  EmployeeInfoChangeRequest,
} from "../../../../models/EmployeeChangeSet";
import { SegmentKey } from "../../../../models/constants/values";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { Spacer } from "../../../atoms/spacers/Spacer";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../atoms/table/AmeTable";
import { AmeTooltip } from "../../../atoms/tooltip/AmeTooltip";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeFormButtonsLayout } from "../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../common/DataSourceContext";

interface Props {
  onSubmit: (changeSetId: string) => Promise<void>;
  changeSetItems: EmployeeChangeSetItem[];
  errorCount: number;
  insertedCount: number;
  updatedCount: number;
  changeSetId: string;
}

export const EmployeeChangeSetPreviewContent2: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const {
    setting: { segmentNames },
  } = useContext(DataSourceContext);
  const onSubmit = useCallback(async () => {
    await props.onSubmit(props.changeSetId);
  }, [props]);
  return (
    <>
      <Spacer height={"24px"} />
      <AmeFormButtonsLayout primaryDisabled={props.errorCount > 0} onPrimary={onSubmit} primaryText="一括登録" />
      <Spacer height={"40px"} />
      <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <AmeTypography component={"h4"}>データを確認してください</AmeTypography>
        <AmeBox sx={{ display: "flex", alignItems: "center" }}>
          <AmeTypography component={"p"} fontSize={"body2"}>
            {/*{props.totalCount}中{page * ITEMS_PER_PAGE + 1}~{(page + 1) * ITEMS_PER_PAGE}件目表示*/}
          </AmeTypography>
          <span
            style={{
              margin: "0 8px 0 16px",
              height: 16,
              width: 16,
              backgroundColor: theme.brand.secondary[20],
            }}
          />
          <AmeTypography component={"p"} fontSize={"body2"}>
            新規追加: {props.insertedCount}件
          </AmeTypography>
          <span
            style={{
              margin: "0 8px 0 16px",
              height: 16,
              width: 16,
              backgroundColor: theme.common.background.warning20,
            }}
          />
          <AmeTypography component={"p"} fontSize={"body2"}>
            変更: {props.updatedCount}件
          </AmeTypography>
        </AmeBox>
      </AmeBox>
      {props.errorCount ? <AmeTypography color={"warning"}>{props.errorCount}件のエラー</AmeTypography> : null}

      <Spacer height={"24px"} />
      <AmeTable>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell>行数</AmeTableHeadCell>
            <AmeTableHeadCell>従業員番号</AmeTableHeadCell>
            <AmeTableHeadCell>氏名</AmeTableHeadCell>
            <AmeTableHeadCell>メールアドレス</AmeTableHeadCell>
            <AmeTableHeadCell>入社日</AmeTableHeadCell>
            <AmeTableHeadCell>退職日</AmeTableHeadCell>
            <AmeTableHeadCell>生年月日</AmeTableHeadCell>
            <AmeTableHeadCell>{segmentNames.SEX}</AmeTableHeadCell>
            <AmeTableHeadCell>{segmentNames.DEPARTMENT}</AmeTableHeadCell>
            <AmeTableHeadCell>{segmentNames.FREE1}</AmeTableHeadCell>
            <AmeTableHeadCell>{segmentNames.FREE2}</AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {props.changeSetItems.map((item) => (
            <AmeTableRow
              key={item.customerCreatedId}
              highlightType={
                item.changeType === "ERROR" ? "warning" : item.changeType === "INSERT" ? "informing" : undefined
              }
            >
              <AmeTableBodyCell>
                {item.rowNumber}
                <ErrorTooltip message={item.errorMessage && item.getErrorMessage()} />
              </AmeTableBodyCell>
              <AmeTableBodyCell>{item.customerCreatedId}</AmeTableBodyCell>
              <InfoCell infoChangeRequest={item.infoChangeRequests.name} isInsertItem={item.changeType === "INSERT"} />
              <InfoCell infoChangeRequest={item.infoChangeRequests.email} isInsertItem={item.changeType === "INSERT"} />
              <AmeTableBodyCell>
                {item.infoChangeRequests.hireDate.requestValue}
                {item.errorMessage === "INVALID_HIRE_DATE" && <ErrorTooltip message={item.getErrorMessage()} />}
              </AmeTableBodyCell>
              <AmeTableBodyCell>
                {item.infoChangeRequests.retireDate.requestValue}
                {["INCONSISTENT_RETIRE_DATE_AND_EFFECTIVE_FROM", "NOT_ALLOWED_TO_CHANGE_RETIRE_DATE"].includes(
                  item.errorMessage as string,
                ) && <ErrorTooltip message={item.getErrorMessage()} />}
              </AmeTableBodyCell>
              <InfoCell
                infoChangeRequest={item.infoChangeRequests.dateOfBirth}
                isInsertItem={item.changeType === "INSERT"}
              />
              <AttributeCell
                isInsertedItem={item.changeType === "INSERT"}
                attribute={item.attributes.find((attr) => attr.attributeKey === SegmentKey.SEX)}
              />
              <AttributeCell
                isInsertedItem={item.changeType === "INSERT"}
                attribute={item.attributes.find((attr) => attr.attributeKey === SegmentKey.DEPARTMENT)}
              />
              <AttributeCell
                isInsertedItem={item.changeType === "INSERT"}
                attribute={item.attributes.find((attr) => attr.attributeKey === SegmentKey.FREE1)}
              />
              <AttributeCell
                isInsertedItem={item.changeType === "INSERT"}
                attribute={item.attributes.find((attr) => attr.attributeKey === SegmentKey.FREE2)}
              />
            </AmeTableRow>
          ))}
        </AmeTableBody>
      </AmeTable>
      <Spacer height={"8px"} />
    </>
  );
};

const ErrorTooltip: React.FC<{ message?: string }> = ({ message }) => {
  const theme = useAmeTheme();
  if (message === undefined) {
    return null;
  }
  return (
    <AmeTooltip title={message} placement="right">
      <IconButton size="small">
        <InfoIcon fontSize="inherit" sx={{ color: theme.common.text.error }} />
      </IconButton>
    </AmeTooltip>
  );
};

const WarnTooltip: React.FC<{ message?: string }> = ({ message }) => {
  const theme = useAmeTheme();
  if (message === undefined) {
    return null;
  }
  return (
    <AmeTooltip title={message} placement="right">
      <IconButton size="small">
        <InfoIcon fontSize="inherit" sx={{ color: theme.common.text.warning }} />
      </IconButton>
    </AmeTooltip>
  );
};

const InfoCell: React.FC<{ infoChangeRequest: EmployeeInfoChangeRequest; isInsertItem?: boolean }> = ({
  infoChangeRequest,
  isInsertItem,
}) => {
  return (
    <AmeTableBodyCell
      highlightType={infoChangeRequest.requestValue === infoChangeRequest.currentValue ? undefined : "warning"}
    >
      {infoChangeRequest.requestValue}
      {infoChangeRequest.currentValue !== infoChangeRequest.requestValue && !isInsertItem && (
        <WarnTooltip message={`${infoChangeRequest.currentValue}から変更されています。`} />
      )}
    </AmeTableBodyCell>
  );
};

const AttributeCell: React.FC<{ isInsertedItem?: boolean; attribute?: ChangeSetItemAttribute }> = ({
  isInsertedItem,
  attribute,
}) => {
  if (!attribute) {
    return <AmeTableBodyCell />;
  }
  return (
    <AmeTableBodyCell
      highlightType={attribute.getChangeType() === "changed" && !isInsertedItem ? "warning" : undefined}
    >
      {attribute.attributeValue}
      {attribute.getChangeType() === "error" && !attribute.isValidValue && (
        <ErrorTooltip message={"属性定義が見つかりません。"} />
      )}
      {attribute.getChangeType() === "changed" && !isInsertedItem && (
        <WarnTooltip
          message={`${
            attribute.currentValue !== undefined ? `"${attribute.currentValue}"` : "不明"
          }から変更されています。`}
        />
      )}
    </AmeTableBodyCell>
  );
};
