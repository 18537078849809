import { CategoryResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { ComputationTaskStatus } from "./computationTasks";
import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export interface CategoryModel {
  categoryId: string;
  displayName: string;
  defaultSubCategoryId?: string;
  computationTaskStatus: ComputationTaskStatus | null;
}

export namespace CategoryModel {
  export const fromResponse = (category: CategoryResponse): CategoryModel => {
    return {
      categoryId: category.categoryId,
      displayName: category.displayName,
      defaultSubCategoryId: category.defaultSubCategoryId,
      computationTaskStatus: category.meta?.latestComputationTask?.computationTaskStatus ?? null,
    };
  };
}

export const useGetCategoryQuery = (categoryId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getCategory.useQuery({ categoryId: categoryId });
  return useQueryResult({
    result,
    fromResponse: CategoryModel.fromResponse,
    onError: options?.onError,
  });
};

export const useGetAllCategories = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getAllCategories.useQuery(undefined, {
    pollingInterval: options?.pollingInterval,
  });
  return useQueryResult({
    result,
    fromResponse: (categories: CategoryResponse[]) => categories.map(CategoryModel.fromResponse),
    onError: options?.onError,
  });
};

export const useCreateCategoryMutation = (options?: UseMutationOptions) => {
  const [createCategory] = defaultApi.endpoints.createCategory.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createCategory,
    onError: options?.onError,
  });
};

export const useUpdateCategoryMutation = (options?: UseMutationOptions) => {
  const [updateCategory] = defaultApi.endpoints.updateCategory.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateCategory,
    onError: options?.onError,
  });
};

export const useDeleteCategoryMutation = (options?: UseMutationOptions) => {
  const [deleteCategory] = defaultApi.endpoints.deleteCategory.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteCategory,
    onError: options?.onError,
  });
};
