import React, { useState, useCallback } from "react";

import { useNavigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../../hooks/useHrPentestSnackbar";
import { UserDetail } from "../../../../../../models/User";
import { useResendInvitation } from "../../../../../../store/hooks/users";
import { useDeleteUserMutation } from "../../../../../../store/hooks/users";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeDialog } from "../../../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { DeleteUserDialogLayout } from "./DeleteUserDialogLayout";
import { UserProfilePage } from "./UserProfilePage";

interface Props {
  user: UserDetail;
  userId: string;
}

export const UserDetailPage: React.FC<Props> = (props) => {
  const deleteUser = useDeleteUserMutation();
  const navigate = useNavigate();
  const onEditClick = () => {
    void navigate({ to: "/hidden-pages/members/users/$userId/edit", params: { userId: props.userId } });
  };
  const resendInvitation = useResendInvitation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useHrPentestSnackbar();
  const generateOnetimePassword = useCallback(async () => {
    const response = await resendInvitation({ resendInvitationRequestBody: { email: props.user.email } });
    if (response.isSuccess) {
      enqueueSuccessSnackbar({ message: "ワンタイムパスワードの再発行をしました" });
    } else {
      enqueueErrorSnackbar({ message: "ワンタイムパスワードの再発行に失敗しました" });
    }
  }, [props, resendInvitation, enqueueErrorSnackbar, enqueueSuccessSnackbar]);

  const deleteUserAccount = async () => {
    await deleteUser({ userId: props.userId });
    setIsDeleteDialogOpen(false);
    void navigate({ to: "/hidden-pages/members/users" });
  };
  const onDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState<boolean>(false);

  return (
    <>
      <AmeDialog
        open={isPasswordDialogOpen}
        onClose={() => {
          setIsPasswordDialogOpen(false);
        }}
      >
        <AmeDialogLayout
          title="仮パスワードの再発行"
          onSubmit={async () => generateOnetimePassword()}
          onClose={() => {
            setIsPasswordDialogOpen(false);
          }}
          submitButtonText="送信"
        >
          <AmeTypography fontSize="body1">登録されているメールアドレスに仮パスワードを再発行します。</AmeTypography>
          <Spacer height="24px" />
          <AmeTypography fontSize="body1">{props.user.email}</AmeTypography>
        </AmeDialogLayout>
      </AmeDialog>
      <AmeDialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
      >
        <DeleteUserDialogLayout
          onSubmit={async () => deleteUserAccount()}
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
        />
      </AmeDialog>
      <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "right", gap: "8px" }}>
        <AmeBox sx={{ display: props.user.userStatus == "CONFIRMED" ? "none" : "block" }}>
          <AmeButton
            variant="outlined"
            onClick={() => {
              setIsPasswordDialogOpen(true);
            }}
          >
            仮パスワードの再発行
          </AmeButton>
        </AmeBox>

        <AmeButton variant="outlined" onClick={onEditClick}>
          編集
        </AmeButton>
        {/*ユーザー削除すると、Feedbackページが壊れるので一旦削除できないようにする*/}
        <AmeBox sx={{ display: "none" }}>
          <AmeButton variant="outlined" onClick={onDeleteClick}>
            削除
          </AmeButton>
        </AmeBox>
      </AmeBox>
      <Spacer height="24px" />
      <UserProfilePage user={props.user} userId={props.userId}></UserProfilePage>
    </>
  );
};
