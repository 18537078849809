import React from "react";

import { UserRef } from "../../../../models/User";
import { useGetMyselfQuery } from "../../../../store/hooks/users";
import { WaitForFetch } from "../../../molecules/Loading/WaitForFetch";
import { UserRegister } from "../Authorizer/UserRegister";

interface MyselfContext {
  myUserData: UserRef;
}

export const MyselfContext = React.createContext<MyselfContext>({
  myUserData: {} as never,
});

export const MyselfContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const myselfState = useGetMyselfQuery();
  return (
    <WaitForFetch queryState={myselfState}>
      {(user) =>
        user && !user.isUnknownUser ? (
          <MyselfContext.Provider value={{ myUserData: user }}>{props.children}</MyselfContext.Provider>
        ) : (
          <UserRegister user={user} />
        )
      }
    </WaitForFetch>
  );
};
