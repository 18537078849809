import React, { useMemo } from "react";

import { AmeBox } from "../../muiWrapper/AmeBox";
import { Spacer } from "../spacers/Spacer";
import { AmeTypography } from "../typography/AmeTypography";

interface Props {
  children: React.ReactNode;
  required?: boolean;
  label: string;
  errorMessage?: string;
  disabled?: boolean;
}
export const AmeFormset: React.FC<Props> = (props) => {
  const labelColor = useMemo(() => {
    if (props.disabled) {
      return "disabled";
    }
    return undefined;
  }, [props.disabled]);

  return (
    <>
      <AmeBox sx={{ display: "flex", alignItems: "baseline", gap: "8px" }}>
        <AmeTypography fontSize="body2" color={labelColor}>
          {props.label}
        </AmeTypography>
        {props.required ? (
          <AmeTypography color={"warning"} fontSize={"caption"}>
            必須
          </AmeTypography>
        ) : null}
      </AmeBox>
      <Spacer height={"8px"} />
      {props.children}
      {props.errorMessage && (
        <>
          <Spacer height={"8px"} />
          <AmeTypography fontSize={"caption"} color={"warning"}>
            {props.errorMessage}
          </AmeTypography>
        </>
      )}
    </>
  );
};
