import React from "react";

import { createFileRoute } from "@tanstack/react-router";
import deepEqual from "fast-deep-equal/es6/react";

import { CategoryModel, useGetAllCategories } from "../../../../../../../store/hooks/categories";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { CategoryList } from "../../../../../../organisms/settings/rules/CategoryList";
import { RulePaginationContainer } from "../../../../../../organisms/settings/rules/RuleTable/RulePaginationContainer";

const RuleSettingsByCategory: React.FC = () => {
  const { isSuccess, data: categories } = useGetAllCategories({ pollingInterval: 1000 * 10 });
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          {
            title: "設定",
            to: "/settings",
          },
          {
            title: "ルール変更",
            to: "/settings/rules",
          },
        ]}
        currentTitle="カテゴリー別"
      />
      <PageLayoutBody type="wide">
        <MemoizedRulePaginationContainerWrapper isSuccess={isSuccess} categories={categories} />
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
const MemoizedRulePaginationContainerWrapper = React.memo<{ isSuccess: boolean; categories?: CategoryModel[] }>(
  function RulePaginationContainerWrapper(props) {
    return props.isSuccess ? (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <RulePaginationContainer allItems={props.categories!}>
        {(currentPageItems) => <CategoryList categories={currentPageItems} />}
      </RulePaginationContainer>
    ) : null;
  },
  deepEqual,
);

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/rules/categories/")({
  component: RuleSettingsByCategory,
});
