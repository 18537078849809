import { useCallback } from "react";

import { useDispatch } from "react-redux";

import { HrPentestSnackbarMessageBody } from "../components/organisms/common/HrPentestSnackbar";
import { snackbarActions } from "../ducks/snackbar";

export const useHrPentestSnackbar = () => {
  const dispatch = useDispatch();

  const enqueueSuccessSnackbar = useCallback(
    (body: HrPentestSnackbarMessageBody) => {
      dispatch(snackbarActions.enqueueSuccessSnackbar(body));
    },
    [dispatch],
  );

  const enqueueErrorSnackbar = useCallback(
    (body?: HrPentestSnackbarMessageBody) => {
      dispatch(
        snackbarActions.enqueueErrorSnackbar({
          title: body?.title || "エラーが発生しました。",
          message: body?.message || "時間をおいて再試行してください。繰り返し発生する場合はCSにご連絡ください。",
        }),
      );
    },
    [dispatch],
  );

  return { enqueueSuccessSnackbar, enqueueErrorSnackbar };
};
