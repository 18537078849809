import React, { PropsWithChildren } from "react";

import { AmeSound } from "../../../../../hooks/useAmeSound";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { SoundPlayer } from "../../../../molecules/SoundPlayer";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  sound: AmeSound;
}
export const TranscriptionContainer: React.FC<PropsWithChildren<Props>> = ({ children, sound }) => {
  const theme = useAmeTheme();

  return (
    <AmeBox
      sx={{
        height: "calc(100vh - 408px)",
        borderRadius: "8px",
        border: `1px solid ${theme.common.border.gray40}`,
        margin: "24px 0",
        overflow: "scroll",
      }}
    >
      <AmeBox sx={{ padding: "5px 24px" }}>{children}</AmeBox>
      <AmeBox sx={{ position: "sticky", bottom: 0, backgroundColor: "#fff" }}>
        <SoundPlayer sound={sound} />
      </AmeBox>
    </AmeBox>
  );
};
