import { FeedbackStatus } from "../../models/constants/values";
import { HrInterview, HrInterviewDetail } from "../../models/hrInterview/HrInterview";
import { PaginatedList } from "../../models/utils/PaginatedList";
import { InterviewStatus, InterviewTranscriptionStatus } from "../../utils/constants";
import {
  AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory,
  HrInterviewAnalysisStatus,
  HrInterviewTranscriptionStatus,
} from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useGetHrInterview = (hrInterviewId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getHrInterview.useQuery(
    { hrInterviewId },
    { skip: options?.skip, pollingInterval: options?.pollingInterval },
  );
  return useQueryResult({
    result,
    fromResponse: HrInterviewDetail.fromResponse,
    onError: options?.onError,
  });
};

/**
 * フロント表示用ステータスとAPI用ステータスの変換
 */
const convertInterviewStatus = (status: InterviewStatus): (HrInterviewAnalysisStatus | "null")[] => {
  switch (status) {
    case "DRAFT":
      return ["null"];
    case "ANALYZING":
      return ["ANALYZING"];
    case "ANALYZED":
      return ["ANALYZED"];
    case "FAILED":
      return ["FAILED"];
  }
};

/**
 * フロント表示用ステータスとAPI用ステータスの変換
 */
const convertTranscriptionStatus = (
  status: InterviewTranscriptionStatus,
): (HrInterviewTranscriptionStatus | "NULL")[] => {
  switch (status) {
    case "NO_FILE":
      return ["NULL"];
      return [];
    case "IN_PROGRESS":
      return ["AI_TRANSCRIBING", "BEAUTIFYING", "OVERALL_SUMMARIZING", "TOPIC_SUMMARIZING"];
    case "COMPLETED":
      return ["COMPLETED"];
    case "FAILED":
      return ["FAILED"];
  }
};

export const useGetHrInterviews = (
  count: number,
  offset: number,
  searchParams: {
    interviewTypeIds?: string[];
    interviewStatuses?: InterviewStatus[];
    transcriptionStatuses?: InterviewTranscriptionStatus[];
    feedbackStatuses?: FeedbackStatus[];
    interviewerIds?: string[];
    followupRequired?: boolean[];
    segments?: { segmentKey: string; segmentValues: string[] }[];
    from?: string;
    to?: string;
  },
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.getHrInterviews.useQuery(
    {
      count,
      offset,
      interviewTypeIds: searchParams.interviewTypeIds,
      interviewStatuses: searchParams.interviewStatuses?.flatMap(convertInterviewStatus),
      transcriptionStatuses: searchParams.transcriptionStatuses?.flatMap(convertTranscriptionStatus),
      followUpIsRequired: searchParams.followupRequired,
      feedbackStatuses: searchParams.feedbackStatuses,
      interviewerIds: searchParams.interviewerIds,
      segmentFilters: searchParams.segments
        ? JSON.stringify(
            searchParams.segments.map((s) => ({
              segmentKey: s.segmentKey,
              segmentValues: s.segmentValues,
            })),
          )
        : undefined,
      interviewDateStart: searchParams.from,
      interviewDateEnd: searchParams.to,
    },
    {
      skip: options?.skip,
      pollingInterval: options?.pollingInterval,
    },
  );
  return useQueryResult({
    result,
    fromResponse: (res): PaginatedList<HrInterview> => {
      return {
        items: res.hrInterviews.map(HrInterview.fromResponse),
        totalCount: res.totalCount,
      };
    },
    onError: options?.onError,
  });
};

export const useCreateHrInterview = (options?: UseMutationOptions) => {
  const [createHrInterview] = defaultApi.endpoints.createHrInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createHrInterview,
    onError: options?.onError,
  });
};

export const useDeleteHrInterview = (options?: UseMutationOptions) => {
  const [createHrInterview] = defaultApi.endpoints.deleteHrInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createHrInterview,
    onError: options?.onError,
  });
};

export const useAnalyzeHrInterview = (options?: UseMutationOptions) => {
  const [analyzeHrInterview] = defaultApi.endpoints.analyzeHrInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: analyzeHrInterview,
    onError: options?.onError,
  });
};

export const useUpdateHrInterview = (options?: UseMutationOptions) => {
  const [updateHrInterview] = defaultApi.endpoints.updateHrInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateHrInterview,
    onError: options?.onError,
  });
};

export const useSendHrInterviewFeedback = (options?: UseMutationOptions) => {
  const [analyzeHrInterview] = defaultApi.endpoints.sendHrInterviewFeedback.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: analyzeHrInterview,
    onError: options?.onError,
  });
};

export const useSummarizeHrInterview = (options?: UseMutationOptions) => {
  const [summarizeHrInterview] = defaultApi.endpoints.summarizeHrInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: summarizeHrInterview,
    onError: options?.onError,
  });
};

export const useGetPresignedUrlToGetHrInterviewFile = (
  hrInterviewFileId: string | undefined,
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.getInterviewFileDownloadUrl.useQuery(
    { hrInterviewFileId: hrInterviewFileId! },
    { skip: options?.skip || hrInterviewFileId === undefined },
  );

  return useQueryResult({
    result,
    fromResponse: (res) => res,
    onError: options?.onError,
  });
};

export const useEditHrInterview = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.updateHrInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};

export const useUpdateFileOfHrInterview = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.updateFileOfHrInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};

export const useGetHrInterviewReport = (
  axis: AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory,
  searchParams: {
    interviewTypeIds?: string[];
    followupRequired?: boolean[];
    subCategoryIds?: string[];
    segments?: { segmentKey: string; segmentValue: string[] }[];
    interviewDateFrom?: string;
    interviewDateTo?: string;
  },
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.getHrInterviewReport.useQuery({
    axis: axis,
    interviewTypeIds: searchParams.interviewTypeIds,
    followUpIsRequired: searchParams.followupRequired,
    segmentFilters: searchParams.segments
      ? JSON.stringify(
          searchParams.segments.map((s) => ({
            segmentKey: s.segmentKey,
            segmentValues: s.segmentValue,
          })),
        )
      : undefined,
    subCategoryIds: searchParams.subCategoryIds,
    interviewDateFrom: searchParams.interviewDateFrom,
    interviewDateTo: searchParams.interviewDateTo,
  });
  return useQueryResult({
    result,
    fromResponse: (res) => res,
    onError: options?.onError,
  });
};
