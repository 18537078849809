/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TranscriptionSentence
 */
export interface TranscriptionSentence {
    /**
     * 
     * @type {number}
     * @memberof TranscriptionSentence
     */
    endTime?: number;
    /**
     * 
     * @type {number}
     * @memberof TranscriptionSentence
     */
    startTime?: number;
    /**
     * 
     * @type {string}
     * @memberof TranscriptionSentence
     */
    speakerLabel: string;
    /**
     * 
     * @type {boolean}
     * @memberof TranscriptionSentence
     */
    isBeautified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TranscriptionSentence
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof TranscriptionSentence
     */
    text: string;
}

export function TranscriptionSentenceFromJSON(json: any): TranscriptionSentence {
    return TranscriptionSentenceFromJSONTyped(json, false);
}

export function TranscriptionSentenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptionSentence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'speakerLabel': json['speakerLabel'],
        'isBeautified': !exists(json, 'isBeautified') ? undefined : json['isBeautified'],
        'enabled': json['enabled'],
        'text': json['text'],
    };
}

export function TranscriptionSentenceToJSON(value?: TranscriptionSentence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endTime': value.endTime,
        'startTime': value.startTime,
        'speakerLabel': value.speakerLabel,
        'isBeautified': value.isBeautified,
        'enabled': value.enabled,
        'text': value.text,
    };
}


