/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PeriodResponse
 */
export interface PeriodResponse {
    /**
     * 
     * @type {number}
     * @memberof PeriodResponse
     */
    periodId: number;
    /**
     * 
     * @type {Date}
     * @memberof PeriodResponse
     */
    beginningDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PeriodResponse
     */
    endDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PeriodResponse
     */
    displayName?: string;
}

export function PeriodResponseFromJSON(json: any): PeriodResponse {
    return PeriodResponseFromJSONTyped(json, false);
}

export function PeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'periodId': json['periodId'],
        'beginningDate': (new Date(json['beginningDate'])),
        'endDate': (new Date(json['endDate'])),
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
    };
}

export function PeriodResponseToJSON(value?: PeriodResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'periodId': value.periodId,
        'beginningDate': (value.beginningDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
        'displayName': value.displayName,
    };
}


