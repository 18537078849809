import React from "react";

import { InterviewFeedbackAnswer } from "../../../store/hooks/interviewer";
import { BadFace } from "../../atoms/icon/BadFace";
import { ExcellentFace } from "../../atoms/icon/ExcellentFace";
import { GoodFace } from "../../atoms/icon/GoodFace";

interface Props {
  rating: InterviewFeedbackAnswer["rating"];
}

export const NicoChanFace: React.FC<Props> = ({ rating }) => {
  switch (rating) {
    case "0":
      return <BadFace />;
    case "1":
      return <GoodFace />;
    case "2":
      return <ExcellentFace />;
  }
};
