/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateInterviewAudioFileUploadUrlsRequestBody
 */
export interface CreateInterviewAudioFileUploadUrlsRequestBody {
    /**
     * 
     * @type {number}
     * @memberof CreateInterviewAudioFileUploadUrlsRequestBody
     */
    fileSize: number;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewAudioFileUploadUrlsRequestBody
     */
    fileExtension?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateInterviewAudioFileUploadUrlsRequestBody
     */
    partSize: number;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewAudioFileUploadUrlsRequestBody
     */
    fileBasename: string;
}

export function CreateInterviewAudioFileUploadUrlsRequestBodyFromJSON(json: any): CreateInterviewAudioFileUploadUrlsRequestBody {
    return CreateInterviewAudioFileUploadUrlsRequestBodyFromJSONTyped(json, false);
}

export function CreateInterviewAudioFileUploadUrlsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInterviewAudioFileUploadUrlsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileSize': json['fileSize'],
        'fileExtension': !exists(json, 'fileExtension') ? undefined : json['fileExtension'],
        'partSize': json['partSize'],
        'fileBasename': json['fileBasename'],
    };
}

export function CreateInterviewAudioFileUploadUrlsRequestBodyToJSON(value?: CreateInterviewAudioFileUploadUrlsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileSize': value.fileSize,
        'fileExtension': value.fileExtension,
        'partSize': value.partSize,
        'fileBasename': value.fileBasename,
    };
}


