import React from "react";

import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

export const LoginButtonWrapper: React.FC<Props> = ({ children }) => {
  return <AmeBox sx={{ display: "flex", justifyContent: "center", paddingTop: "16px" }}>{children}</AmeBox>;
};
