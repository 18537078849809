import React, { useContext } from "react";

import { Control, Controller, FormState, UseFormRegister } from "react-hook-form";

import { HrInterviewType } from "../../../../../models/HrInterviewSetting";
import { UserGroup } from "../../../../../models/UserGroup";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeFormset } from "../../../../atoms/forms/AmeFormset";
import { AmeDateInput } from "../../../../atoms/forms/input/AmeDateInput";
import { AmeDropbox } from "../../../../atoms/forms/input/AmeDropbox";
import { AmeCommonListItem } from "../../../../atoms/list/AmeCommonListItem";
import { AmeCommonListMainContent } from "../../../../atoms/list/AmeCommonListMainContent";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { MyselfContext } from "../../../common/MyselfContext";

import { EmployeeTenureSelector } from "./EmployeeTenureSelector";
import { InterviewCreationFrom } from "./InterviewCreationForm";
import { InterviewTypeSelector } from "./InterviewTypeSelector";

interface Props {
  userGroups: UserGroup[];
  interviewTypes: HrInterviewType[];
  register: UseFormRegister<InterviewCreationFrom>;
  control: Control<InterviewCreationFrom>;
  formState: FormState<InterviewCreationFrom>;
  clearFormatId: () => void;
  isDraft: boolean;
}

export const CreateHrInterviewPageContent: React.FC<Props> = ({
  isDraft,
  interviewTypes,
  control,
  userGroups,
  formState,
  clearFormatId,
}) => {
  const { myUserData } = useContext(MyselfContext);

  return (
    <>
      <AmeFormset label={"面談種類"} required={true} errorMessage={formState.errors.interviewTypeId?.message}>
        <Controller
          rules={{ required: "必須です。" }}
          name={"interviewTypeId"}
          control={control}
          disabled={!isDraft}
          render={({ field }) => (
            <AmeDropbox
              error={false}
              disabled={field.disabled}
              size="full"
              selectedLabel={
                interviewTypes.find(({ hrInterviewTypeId }) => hrInterviewTypeId == field.value)?.hrInterviewTypeText ??
                "選択なし"
              }
            >
              {interviewTypes.map((interviewType) => {
                return (
                  <AmeCommonListItem
                    onClick={() => {
                      field.onChange(interviewType.hrInterviewTypeId);
                      clearFormatId();
                    }}
                    key={interviewType.hrInterviewTypeId}
                  >
                    <AmeCommonListMainContent>{interviewType.hrInterviewTypeText}</AmeCommonListMainContent>
                  </AmeCommonListItem>
                );
              })}
            </AmeDropbox>
          )}
        />
      </AmeFormset>
      <Spacer height="16px" />
      <AmeFormset label={"面談フォーマット"} required={true} errorMessage={formState.errors.interviewFormatId?.message}>
        <Controller
          name={"interviewTypeId"}
          control={control}
          render={({ field }) =>
            field.value ? (
              <InterviewTypeSelector disabled={!isDraft} interviewTypeId={field.value} control={control} />
            ) : (
              <AmeBox sx={{ display: "flex", width: "100%" }}>
                <AmeDropbox size={"full"} disabled>
                  {null}
                </AmeDropbox>
                <Spacer width="14px" />
                <AmeButton variant={"outlined"} color={"basic"} disabled>
                  フォーマット確認
                </AmeButton>
              </AmeBox>
            )
          }
        />
      </AmeFormset>
      <Spacer height="16px" />

      <AmeFormset
        label={"面談対象者従業員番号"}
        required={true}
        errorMessage={formState.errors.employeeTenure?.message}
      >
        <Controller
          rules={{ required: "必須です。" }}
          name={"employeeTenure"}
          disabled={!isDraft}
          control={control}
          render={({ field }) => (
            <EmployeeTenureSelector
              disabled={field.disabled}
              value={field.value}
              onSelectEmployee={(employee) => {
                field.onChange(employee);
              }}
            />
          )}
        />
      </AmeFormset>
      <Spacer height="16px" />

      <AmeFormset label={"面談日"} errorMessage={formState.errors.interviewDate?.message}>
        <Controller
          name={"interviewDate"}
          control={control}
          render={({ field }) => (
            <AmeDateInput onCommitDate={(newDate) => field.onChange(newDate ?? undefined)} value={field.value} />
          )}
        />
      </AmeFormset>
      <Spacer height="16px" />

      <AmeFormset label={"グループ"} errorMessage={formState.errors.groupId?.message}>
        <Controller
          rules={{
            validate: (value) => {
              if (!value && !myUserData.isAdmin) {
                return "必須です。";
              }
            },
          }}
          name={"groupId"}
          control={control}
          render={({ field }) => (
            <AmeDropbox
              error={false}
              disabled={false}
              size="full"
              selectedLabel={
                userGroups.find(({ userGroupId }) => userGroupId == field.value)?.displayName ?? "選択なし"
              }
            >
              <AmeCommonListItem
                onClick={() => {
                  field.onChange("");
                }}
              >
                <AmeCommonListMainContent>削除</AmeCommonListMainContent>
              </AmeCommonListItem>
              {userGroups.map((userGroup) => {
                return (
                  <AmeCommonListItem
                    onClick={() => {
                      field.onChange(userGroup.userGroupId);
                    }}
                    key={userGroup.userGroupId}
                  >
                    <AmeCommonListMainContent>{userGroup.displayName}</AmeCommonListMainContent>
                  </AmeCommonListItem>
                );
              })}
            </AmeDropbox>
          )}
        />
      </AmeFormset>
    </>
  );
};
