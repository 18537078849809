import React from "react";

import { SurveyStatus } from "@amecloud/api-client/src-openapi-autogen";

import { SurveyModel } from "../../../../models/Survey";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { PaperBody } from "../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { SURVEY_SENDING_METHOD_TEXT_AND_ICON } from "../common/constants";
import { SurveyStatusBodyCellContent } from "../index/cells/SurveyStatusBodyCellContent";

import { SurveyDetailContentItem } from "./SurveyDetailContentItem";

interface Props {
  survey: SurveyModel;
}

export const SurveyDetails: React.FC<Props> = (props) => {
  const theme = useAmeTheme();

  return (
    <>
      <PaperWrapper>
        <PaperHeader>アンケート概要</PaperHeader>
        <PaperBody>
          <AmeBox sx={{ display: "flex", gap: "48px" }}>
            <SurveyDetailContentItem label="ステータス">
              <SurveyStatusBodyCellContent status={props.survey.status} />
            </SurveyDetailContentItem>
            <SurveyDetailContentItem label="作成日">
              {props.survey.createdAt.toLocaleDateString("ja-JP")}
            </SurveyDetailContentItem>
            <SurveyDetailContentItem label="開始日">
              {props.survey.startedAt ? props.survey.startedAt.toLocaleDateString("ja-JP") : "-"}
            </SurveyDetailContentItem>
            <SurveyDetailContentItem label="回答対象">{props.survey.targetEmployeeCount}</SurveyDetailContentItem>
            <SurveyDetailContentItem label="メール送付対象">{props.survey.emailSheetsCount}</SurveyDetailContentItem>
            {/* <SurveyDetailContentItem label="メール送付済">TODO: 送信済みメールをカウントするAPIができたら、それを使う</SurveyDetailContentItem> */}
            <SurveyDetailContentItem label="回答済">
              {props.survey.status !== SurveyStatus.DRAFT ? props.survey.collectedCount : "-"}
            </SurveyDetailContentItem>
          </AmeBox>
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperHeader>基本設定</PaperHeader>
        <PaperBody>
          <AmeBox sx={{ paddingBottom: "16px", borderBottom: `1px solid ${theme.common.border.gray40}` }}>
            <SurveyDetailContentItem label="アンケート送付方法">
              {
                SURVEY_SENDING_METHOD_TEXT_AND_ICON[
                  SurveyModel.getSurveySendingMethod(props.survey.useQrcode, props.survey.sendEmail)
                ].text
              }
            </SurveyDetailContentItem>
          </AmeBox>
          <AmeBox sx={{ paddingTop: "16px" }}>
            <SurveyDetailContentItem label="期日設定">
              {props.survey.dueDateTime.toLocaleDateString("ja-JP")}
            </SurveyDetailContentItem>
          </AmeBox>
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperHeader>アンケート内容</PaperHeader>
        <PaperBody>
          <AmeBox sx={{ paddingBottom: "16px", borderBottom: `1px solid ${theme.common.border.gray40}` }}>
            <SurveyDetailContentItem label="タイトル">{props.survey.surveyTitle}</SurveyDetailContentItem>
          </AmeBox>
          <AmeBox sx={{ paddingTop: "16px" }}>
            <SurveyDetailContentItem label="説明">{props.survey.surveyDescription}</SurveyDetailContentItem>
          </AmeBox>
        </PaperBody>
      </PaperWrapper>
      {props.survey.sendEmail ? (
        <>
          <Spacer height="24px" />
          <PaperWrapper>
            <PaperHeader>メール内容</PaperHeader>
            <PaperBody>
              <AmeBox sx={{ paddingBottom: "16px", borderBottom: `1px solid ${theme.common.border.gray40}` }}>
                <SurveyDetailContentItem label="タイトル">{props.survey.emailSubject}</SurveyDetailContentItem>
              </AmeBox>
              <AmeBox sx={{ paddingTop: "16px" }}>
                <SurveyDetailContentItem label="説明">{props.survey.emailBody}</SurveyDetailContentItem>
              </AmeBox>
            </PaperBody>
          </PaperWrapper>
        </>
      ) : null}
    </>
  );
};
