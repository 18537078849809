import React, { useCallback, useState } from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { useDownloadInterviewFile } from "../../../../../hooks/useDownloadInterviewFile";
import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { TranscriptionSentence } from "../../../../../models/Transcription";
import { TranscriptionStatus } from "../../../../../store/autogenApi";
import { useInterviewFeedbackSetting, useSubmitInterviewFeedback } from "../../../../../store/hooks/interviewer";
import { useSubmitTranscription, useUpdateTranscription } from "../../../../../store/hooks/transcription";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeTextarea } from "../../../../atoms/forms/textarea/AmeTextarea";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeDialog } from "../../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { InterviewerFeedbackTable, InterviewerFormType } from "./InterviewerFeedbackTable";
import { TranscriptionAudioPlayer } from "./TranscriptionAudioPlayer";
import { TranscriptionTextareaWrapper } from "./TranscriptionTextareaWrapper";

interface Props {
  sound: Howl;
  transcriptions: TranscriptionSentence[];
  speakerMap: Map<string, number>;
  interviewRecordingId: string;
  transcriptionStatus: TranscriptionStatus;
  transcriptionText: string;
}

export const TranscriptionFinalChecker: React.FC<Props> = ({
  sound,
  transcriptions,
  speakerMap,
  interviewRecordingId,
  transcriptionStatus,
  transcriptionText,
}) => {
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const interviewFeedbackSettingQuery = useInterviewFeedbackSetting();
  const { control, register, watch } = useForm<InterviewerFormType>({
    defaultValues: {
      interviewContent: transcriptionText,
      comment: "",
      qualities: [],
    },
  });

  const updateTranscription = useUpdateTranscription();
  const submitFeedback = useSubmitInterviewFeedback();

  const updateTranscriptionText = useCallback(async () => {
    const result = await updateTranscription({
      interviewRecordingId: interviewRecordingId,
      updateTranscriptionRequestBody: {
        text: watch("interviewContent"),
      },
    });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "文字起こし文章を更新しました。" });
    }
  }, [updateTranscription, interviewRecordingId, watch, enqueueSuccessSnackbar]);

  const downloadInterviewFile = useDownloadInterviewFile();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const submitTranscription = useSubmitTranscription();
  const submit = useCallback(async () => {
    const result = await submitTranscription({
      interviewRecordingId: interviewRecordingId,
    });
    await submitFeedback({
      interviewFileId: interviewRecordingId,
      submitInterviewFeedbackRequest: {
        answers: watch("qualities").map((q) => ({
          interviewFeedbackAnswerId: q.interviewFeedbackAnswerId,
          interviewFeedbackQuestionId: q.interviewFeedbackQuestionId,
        })),
        comment: watch("comment"),
      },
    });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "解析を開始しました。" });
      await navigate({ to: "/hidden-pages/transcriptions", search: { page: 1 } });
    }
  }, [submitTranscription, interviewRecordingId, submitFeedback, watch, enqueueSuccessSnackbar, navigate]);

  return (
    <>
      <AmeDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <AmeDialogLayout
          variant={"caution"}
          onClose={() => setIsOpen(false)}
          submitButtonText={"開始"}
          onSubmit={submit}
          title={"解析を開始しますか?"}
        >
          <AmeTypography>本当に解析を開始しますか？</AmeTypography>
        </AmeDialogLayout>
      </AmeDialog>
      <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <TranscriptionTextareaWrapper>
          <AmeTextarea
            disabled={transcriptionStatus === "checked"}
            sx={{ "& .MuiInputBase-input": { fontSize: "8px" } }}
            rows={12}
            fullWidth
            {...register("interviewContent")}
          />
        </TranscriptionTextareaWrapper>
        <TranscriptionAudioPlayer sound={sound} transcriptions={transcriptions} speakerMap={speakerMap} />
        <WaitForSuccess queryState={interviewFeedbackSettingQuery}>
          {(questions) => (
            <InterviewerFeedbackTable watch={watch} control={control} interviewFeedbackQuestions={questions} />
          )}
        </WaitForSuccess>
        <Spacer height={"24px"} />
        <PaperWrapper>
          <PaperHeader>コメント</PaperHeader>
          <PaperBody>
            <AmeTextarea rows={4} fullWidth {...register("comment")} />
          </PaperBody>
        </PaperWrapper>
        <Spacer height={"24px"} />
        {/* TODO(saito): PaperLayout系のBody要素とデザイン上の依存関係があるため、追従にできていない。
            どうするべきか判断がまだついていないので、一旦通常フォームレイアウトを採用。
            但し、ボタンの配置に関しては追従フォームボタンレイアウトに従わせている。
        */}
        <AmeBox sx={{ display: "flex", gap: "8px" }}>
          {transcriptionStatus === "checking" ? (
            <AmeButton disabled={transcriptionText !== watch("interviewContent")} onClick={() => setIsOpen(true)}>
              解析開始
            </AmeButton>
          ) : null}
          <AmeButton disabled={transcriptionStatus === "checked"} variant="outlined" onClick={updateTranscriptionText}>
            保存
          </AmeButton>
          <AmeBox sx={{ marginLeft: "auto" }}>
            <AmeButton variant="outlined" onClick={() => downloadInterviewFile(interviewRecordingId)}>
              音声データダウンロード
            </AmeButton>
          </AmeBox>
        </AmeBox>
      </AmeBox>
    </>
  );
};
