import React from "react";

import { Close } from "@mui/icons-material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

export interface FileProperties {
  file: File;
  fileName: string;
  percentage: number;
  status: "pending" | "uploaded" | "uploading" | "error" | "exist";
  s3FileName?: string;
  duration: number;
}

interface Props {
  interviewFileProperties: Array<FileProperties>;
  removeFile?: (index: number) => void;
}

export const InterviewFileNameMapper: React.FC<Props> = ({ interviewFileProperties, removeFile }) => {
  const theme = useAmeTheme();

  const getUploadStatus = (interviewFileProperty: FileProperties): string => {
    switch (interviewFileProperty.status) {
      case "pending":
        return "待機中";
      case "uploading":
        return `アップロード中(${interviewFileProperty.percentage.toFixed(1)}%)`;
      case "uploaded":
        return "アップロード完了";
      case "error":
        return "アップロードエラー";
      case "exist":
        return "既存ファイル";
    }
  };

  return (
    <>
      {interviewFileProperties.map((interviewFilePropety, i) => (
        <AmeBox
          key={i}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: `1px solid ${theme.common.border.gray40}`,
            padding: "8px 0",
            "&: last-child": { borderBottom: "none" },
          }}
        >
          <AmeBox sx={{ width: "70%" }}>
            <AmeTypography>{interviewFilePropety.fileName}</AmeTypography>
          </AmeBox>
          <AmeBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <AmeTypography>{getUploadStatus(interviewFilePropety)}</AmeTypography>
            <AmeBox
              onClick={() => removeFile?.(i)}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Close />
            </AmeBox>
          </AmeBox>
        </AmeBox>
      ))}
    </>
  );
};
