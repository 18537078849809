import React from "react";

import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeDialogLayout } from "../../../../../../molecules/dialog/AmeDialogLayout";
import { CheckboxWithLabel } from "../../../../../../molecules/form/CheckboxWithLabel";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

export const InterviewDeletionDialogContent: React.FC<{ onClose: () => void; onSubmit: () => Promise<void> }> = ({
  onClose,
  onSubmit,
}) => {
  const [isChecked, setIsChecked] = React.useState(false);
  return (
    <AmeDialogLayout
      onClose={onClose}
      onSubmit={onSubmit}
      title={"本当に削除しますか？"}
      variant="warning"
      submitButtonText={"削除"}
      submitButtonDisabled={!isChecked}
    >
      <AmeTypography>削除すると、元に戻すことができません。</AmeTypography>
      <AmeTypography>問題ない場合は削除ボタンを押してください。</AmeTypography>
      <Spacer height="24px" />
      <AmeBox sx={{ display: "inline-flex", justifyContent: "start", gap: "8px" }}>
        <CheckboxWithLabel
          checked={isChecked}
          onChange={(_, checked) => setIsChecked(checked)}
          label={"注意事項を確認しました。"}
        />
      </AmeBox>
    </AmeDialogLayout>
  );
};
