/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChangeSetErrorType {
    DUPLICATE_CUSTOMER_CREATED_ID = 'DUPLICATE_CUSTOMER_CREATED_ID',
    MISSING_ATTRIBUTE_DEFINITION = 'MISSING_ATTRIBUTE_DEFINITION',
    INCONSISTENT_RETIRE_DATE_AND_EFFECTIVE_FROM = 'INCONSISTENT_RETIRE_DATE_AND_EFFECTIVE_FROM',
    INVALID_HIRE_DATE = 'INVALID_HIRE_DATE',
    DUPLICATE_TENURE = 'DUPLICATE_TENURE',
    NOT_ALLOWED_TO_CHANGE_RETIRE_DATE = 'NOT_ALLOWED_TO_CHANGE_RETIRE_DATE',
    UNKNOWN = 'UNKNOWN'
}

export function ChangeSetErrorTypeFromJSON(json: any): ChangeSetErrorType {
    return ChangeSetErrorTypeFromJSONTyped(json, false);
}

export function ChangeSetErrorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeSetErrorType {
    return json as ChangeSetErrorType;
}

export function ChangeSetErrorTypeToJSON(value?: ChangeSetErrorType | null): any {
    return value as any;
}

