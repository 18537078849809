/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryResponseMeta,
    CategoryResponseMetaFromJSON,
    CategoryResponseMetaFromJSONTyped,
    CategoryResponseMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface CategoryResponse
 */
export interface CategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    defaultSubCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    displayName: string;
    /**
     * 
     * @type {Date}
     * @memberof CategoryResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CategoryResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {CategoryResponseMeta}
     * @memberof CategoryResponse
     */
    meta?: CategoryResponseMeta;
}

export function CategoryResponseFromJSON(json: any): CategoryResponse {
    return CategoryResponseFromJSONTyped(json, false);
}

export function CategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': json['categoryId'],
        'defaultSubCategoryId': !exists(json, 'defaultSubCategoryId') ? undefined : json['defaultSubCategoryId'],
        'displayName': json['displayName'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'meta': !exists(json, 'meta') ? undefined : CategoryResponseMetaFromJSON(json['meta']),
    };
}

export function CategoryResponseToJSON(value?: CategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'defaultSubCategoryId': value.defaultSubCategoryId,
        'displayName': value.displayName,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'meta': CategoryResponseMetaToJSON(value.meta),
    };
}


