import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Warning: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper viewBox="0 0 30 30" size={16} {...props}>
      <g id="Icon">
        <path
          id="Vector"
          d="M13.8514 8.10788C13.9674 7.92315 14.1346 7.76967 14.3363 7.66294C14.5379 7.5562 14.7667 7.5 14.9997 7.5C15.2326 7.5 15.4615 7.5562 15.6631 7.66294C15.8647 7.76967 16.0319 7.92315 16.1479 8.10788L24.182 20.607C24.7175 21.4405 24.0754 22.5 23.0338 22.5H6.9667C5.92509 22.5 5.28185 21.4394 5.81847 20.607L13.8514 8.10788ZM15.002 11.7868C14.3752 11.7868 13.8842 12.2817 13.9475 12.8528L14.3576 16.6099C14.3714 16.7575 14.4452 16.895 14.5646 16.9952C14.6839 17.0954 14.84 17.151 15.002 17.151C15.164 17.151 15.3201 17.0954 15.4395 16.9952C15.5588 16.895 15.6326 16.7575 15.6464 16.6099L16.0565 12.8528C16.0712 12.718 16.0549 12.582 16.0086 12.4533C15.9624 12.3247 15.8871 12.2064 15.7878 12.106C15.6884 12.0055 15.5672 11.9253 15.4319 11.8703C15.2965 11.8153 15.1501 11.7869 15.002 11.7868ZM14.9997 18.2147C14.6889 18.2147 14.3909 18.3276 14.1712 18.5285C13.9514 18.7294 13.828 19.0019 13.828 19.286C13.828 19.5702 13.9514 19.8427 14.1712 20.0436C14.3909 20.2445 14.6889 20.3574 14.9997 20.3574C15.3104 20.3574 15.6084 20.2445 15.8282 20.0436C16.0479 19.8427 16.1713 19.5702 16.1713 19.286C16.1713 19.0019 16.0479 18.7294 15.8282 18.5285C15.6084 18.3276 15.3104 18.2147 14.9997 18.2147Z"
          fill="currentColor"
        />
      </g>
    </SvgWrapper>
  );
};
