/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserGroupRefResponse,
    UserGroupRefResponseFromJSON,
    UserGroupRefResponseFromJSONTyped,
    UserGroupRefResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<UserGroupRefResponse>}
     * @memberof UserResponse
     */
    userGroups: Array<UserGroupRefResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    isGlobalAuth: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    isAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    userAuthId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    userId: string;
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'userGroups': ((json['userGroups'] as Array<any>).map(UserGroupRefResponseFromJSON)),
        'isGlobalAuth': json['isGlobalAuth'],
        'isAdmin': json['isAdmin'],
        'givenName': json['givenName'],
        'surname': json['surname'],
        'userAuthId': !exists(json, 'userAuthId') ? undefined : json['userAuthId'],
        'userId': json['userId'],
    };
}

export function UserResponseToJSON(value?: UserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'userGroups': ((value.userGroups as Array<any>).map(UserGroupRefResponseToJSON)),
        'isGlobalAuth': value.isGlobalAuth,
        'isAdmin': value.isAdmin,
        'givenName': value.givenName,
        'surname': value.surname,
        'userAuthId': value.userAuthId,
        'userId': value.userId,
    };
}


