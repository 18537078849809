import React from "react";

import { useMatchRoute } from "@tanstack/react-router";

import { useSp } from "../../../../hooks/useSp";
import { CSS_SP_MEDIA_QUERY } from "../../../../utils/mediaQuery";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { PageLayoutBody } from "../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { CarryOverDialog } from "../CarryOverDialog";
import { Footer } from "../Footer";
import { Header } from "../Header";

import { InterviewerNavigator } from "./InterviewerNavigator";

type Props = {
  children: React.ReactNode;
};

export const PageTemplate: React.FC<Props> = ({ children }) => {
  const theme = useAmeTheme();
  const sp = useSp();
  const matchRoute = useMatchRoute();
  const interviewFileMatch = matchRoute({ to: "/interview-files", fuzzy: true });
  const hrInterviewFileMatch = matchRoute({ to: "/hr-interviews/interview-files", fuzzy: true });
  const match = interviewFileMatch || hrInterviewFileMatch;

  return (
    <>
      <CarryOverDialog />
      <Header />
      <AmeBox
        component="main"
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          boxSizing: "border-box",
          padding: "24px 48px",
          [CSS_SP_MEDIA_QUERY]: { backgroundColor: theme.common.background.white, padding: "0px" },
        }}
      >
        {!match && sp ? (
          <PageLayoutWrapper>
            <PageLayoutBody>
              <AmeTypography fontSize="body2">
                こちらのページはPCで確認してください
                <br /> スマートフォンからは下記ページのみご覧頂けます
              </AmeTypography>
              <Spacer height="24px" />
              <InterviewerNavigator />
            </PageLayoutBody>
          </PageLayoutWrapper>
        ) : (
          children
        )}
      </AmeBox>
      <Footer />
    </>
  );
};
