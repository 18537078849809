import React, { useCallback, useState } from "react";

import { useNavigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SurveyModel } from "../../../../../models/Survey";
import { useDeleteSurvey } from "../../../../../store/hooks/surveys";
import { AmeCheckbox } from "../../../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeDialog } from "../../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  survey: SurveyModel;
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteSurveyModal: React.FC<Props> = (props) => {
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const navigate = useNavigate();
  const deleteSurvey = useDeleteSurvey();
  const [checked, setChecked] = useState<boolean>(false);

  const onChangeChecked = useCallback(() => {
    setChecked(!checked);
  }, [checked, setChecked]);

  const onDelete = useCallback(async () => {
    const result = await deleteSurvey({
      surveyId: props.survey.surveyId,
    });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "アンケートを削除しました。" });
      void navigate({ to: "/surveys", search: { page: 1 } });
      return;
    }
    enqueueErrorSnackbar({ title: "アンケートの削除に失敗しました。" });
  }, [enqueueErrorSnackbar, enqueueSuccessSnackbar, navigate, deleteSurvey, props]);

  return (
    <AmeDialog open={props.isOpen} onClose={props.onClose}>
      <AmeDialogLayout
        onClose={props.onClose}
        onSubmit={onDelete}
        submitButtonText="削除"
        variant="warning"
        title="本当に削除しますか？"
        submitButtonDisabled={!checked}
      >
        <AmeTypography>削除すると、元に戻すことができません。</AmeTypography>
        <AmeTypography>問題ない場合は削除ボタンを押してください。</AmeTypography>
        <Spacer height="24px" />
        <AmeBox sx={{ display: "inline-flex", justifyContent: "start", gap: "8px" }}>
          <AmeCheckbox checked={checked} onChange={onChangeChecked} />
          <AmeTypography>注意事項を確認しました</AmeTypography>
        </AmeBox>
      </AmeDialogLayout>
    </AmeDialog>
  );
};
