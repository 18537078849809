import React from "react";

import { Pagination, PaginationItem } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

interface Props {
  boundaryCount?: number;
  page?: number;
  count: number;
  onChangePage: (page: number) => void;
}

export const AmePagination: React.FC<Props> = ({ boundaryCount = 1, onChangePage, count, page }) => {
  const theme = useAmeTheme();
  return (
    <Pagination
      page={page}
      boundaryCount={boundaryCount}
      onChange={(_, newPage) => onChangePage(newPage)}
      count={Math.max(count, 1)}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          sx={{
            "&.MuiPaginationItem-root": {
              height: "48px",
              width: "48px",
              borderRadius: "100px",
              border: `1px solid ${theme.common.border.gray40}`,
              "&.Mui-selected": {
                backgroundColor: theme.brand.secondary[20],
              },
              "&:hover": {
                backgroundColor: theme.common.background.gray40,
              },
            },
            "& .MuiPaginationItem-icon": {
              fontSize: "32px",
            },
          }}
        />
      )}
    />
  );
};
