import React from "react";

import { useDropzone } from "react-dropzone";

import { useSp } from "../../../../../hooks/useSp";
import { FileDropZone } from "../../../../molecules/FileDropZone";
import { SpFileDropZone } from "../../../../molecules/FileDropZone/SpFileDropZone";

type Props = {
  onImported: (files: File[]) => void;
};
export const AudioFilesImporter: React.FC<Props> = ({ onImported }) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "audio/aac": [".m4a", ".aac"],
      "audio/mpeg": [".mp3"],
      "audio/amr": [".amr"],
      "audio/wav": [".wav"],
    },
    onDropAccepted: (acceptedFiles) => {
      onImported(acceptedFiles);
    },
    maxSize: 100 * 1024 * 1024 * 1024,
  });

  const sp = useSp();

  if (sp) {
    return (
      <SpFileDropZone getRootProps={getRootProps} getInputProps={getInputProps} fileRejection={fileRejections[0]} />
    );
  } else {
    return (
      <FileDropZone
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        fileRejection={fileRejections[0]}
        extraText="m4a・mp3・aac・amr・wavファイルに対応しています。1ファイル2GBまで。"
      />
    );
  }
};

// hr-interview/interview-files/create.tsxで一時的に使う。sp版デザイン確定後この項目を削除する。
export const AudioFilesImporterTemp: React.FC<Props> = ({ onImported }) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "audio/aac": [".m4a", ".aac"],
      "audio/mpeg": [".mp3"],
      "audio/amr": [".amr"],
      "audio/wav": [".wav"],
    },
    onDropAccepted: (acceptedFiles) => {
      onImported(acceptedFiles);
    },
    maxSize: 100 * 1024 * 1024 * 1024,
  });

  const sp = useSp();

  if (sp) {
    return (
      <FileDropZone
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        fileRejection={fileRejections[0]}
        helperText=""
        extraText="m4a・mp3・aac・amr・wavファイルに対応しています。1ファイル2GBまで。"
      />
    );
  } else {
    return (
      <FileDropZone
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        fileRejection={fileRejections[0]}
        extraText="m4a・mp3・aac・amr・wavファイルに対応しています。1ファイル2GBまで。"
      />
    );
  }
};
