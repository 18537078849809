import React, { useCallback } from "react";

import { createFileRoute, Navigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../../../hooks/useHrPentestSnackbar";
import { isInputSegmentKey } from "../../../../../../../models/constants/values";
import { InputSegmentKey as InputSegmentKeyType } from "../../../../../../../store/autogenApi";
import {
  useGetSegmentDefinition,
  useUpdateSegmentDefinition,
} from "../../../../../../../store/hooks/segmentDefinitions";
import { useGetSetting } from "../../../../../../../store/hooks/settings";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { SettingsSegmentsSegmentKeyContent } from "../../../../../../organisms/settings/segments/segmentKey/SettingsSegmentsSegmentKeyContent";

const SettingsSegmentsSegmentKey: React.FC = () => {
  const { segmentKey } = Route.useParams();
  const settingQuery = useGetSetting();

  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const updateSegmentDefinition = useUpdateSegmentDefinition();
  const onUpdateSegmentDefinition = useCallback(
    async (segmentKey: InputSegmentKeyType, definitionId: string, isDeprecated: boolean) => {
      await updateSegmentDefinition({
        segmentKey,
        inputSegmentDefinitionId: definitionId,
        editSegmentDefinitionRequestBody: { isDeprecated },
      });
    },
    [updateSegmentDefinition],
  );

  if (!isInputSegmentKey(segmentKey)) {
    enqueueErrorSnackbar({
      title: "segmentKeyが不正です。",
      message: `${JSON.stringify({
        segmentKey,
      })}はsegmentKeyではありません。セグメント設定トップページにリダイレクトします。`,
    });
    return <Navigate replace to="/settings/segments" />;
  }

  // segmentKeyがなければリダイレクトされるので、safe。暇で気になった人はリファクタしてください。
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const segmentDefinitionQuery = useGetSegmentDefinition(segmentKey);

  return (
    <PageLayoutWrapper>
      <WaitForSuccess queryState={settingQuery}>
        {(setting) => (
          <BreadcrumbPageLayoutHeader
            items={[
              {
                title: "設定",
                to: "/settings",
              },
              {
                title: "属性定義設定",
                to: "/settings/segments",
              },
            ]}
            currentTitle={setting.segmentNames[segmentKey]}
          />
        )}
      </WaitForSuccess>

      <PageLayoutBody type="wide">
        <WaitForSuccess queryState={segmentDefinitionQuery}>
          {(data) => (
            <SettingsSegmentsSegmentKeyContent
              onUpdateDefinition={onUpdateSegmentDefinition}
              segmentDefinitions={data}
              inputSegmentKey={segmentKey}
            />
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/segments/$segmentKey/")({
  component: SettingsSegmentsSegmentKey,
});
