/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserRequestBody
 */
export interface CreateUserRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    givenName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequestBody
     */
    isAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestBody
     */
    phoneNumber: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequestBody
     */
    userGroupIds: Array<string>;
}

export function CreateUserRequestBodyFromJSON(json: any): CreateUserRequestBody {
    return CreateUserRequestBodyFromJSONTyped(json, false);
}

export function CreateUserRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surname': json['surname'],
        'givenName': json['givenName'],
        'isAdmin': json['isAdmin'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'userGroupIds': json['userGroupIds'],
    };
}

export function CreateUserRequestBodyToJSON(value?: CreateUserRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surname': value.surname,
        'givenName': value.givenName,
        'isAdmin': value.isAdmin,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'userGroupIds': value.userGroupIds,
    };
}


