/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRefResponse,
    UserRefResponseFromJSON,
    UserRefResponseFromJSONTyped,
    UserRefResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserGroupResponse
 */
export interface UserGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof UserGroupResponse
     */
    userGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupResponse
     */
    displayName: string;
    /**
     * 
     * @type {number}
     * @memberof UserGroupResponse
     */
    totalUserCount?: number;
    /**
     * 
     * @type {Array<UserRefResponse>}
     * @memberof UserGroupResponse
     */
    users?: Array<UserRefResponse>;
}

export function UserGroupResponseFromJSON(json: any): UserGroupResponse {
    return UserGroupResponseFromJSONTyped(json, false);
}

export function UserGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroupId': json['userGroupId'],
        'displayName': json['displayName'],
        'totalUserCount': !exists(json, 'totalUserCount') ? undefined : json['totalUserCount'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(UserRefResponseFromJSON)),
    };
}

export function UserGroupResponseToJSON(value?: UserGroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userGroupId': value.userGroupId,
        'displayName': value.displayName,
        'totalUserCount': value.totalUserCount,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(UserRefResponseToJSON)),
    };
}


