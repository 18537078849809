import React, { ChangeEvent, useCallback, useState } from "react";

import { InputSegmentKey } from "../../../../store/autogenApi";
import { AmeInput } from "../../../atoms/forms/input/AmeInput";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeDialog } from "../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  inputSegmentKey: InputSegmentKey;
  currentSegmentName: string;
  registerSegmentName: (inputSegmentKey: InputSegmentKey, displayName: string) => Promise<void>;
  onClose: () => void;
}

export const SegmentNameRegister: React.FC<Props> = ({
  registerSegmentName,
  inputSegmentKey,
  onClose,
  currentSegmentName,
}) => {
  const [segmentName, setSegmentName] = useState(currentSegmentName);
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSegmentName(e.target.value);
  }, []);

  const onSubmit = useCallback(async () => {
    await registerSegmentName(inputSegmentKey, segmentName);
    onClose();
  }, [inputSegmentKey, onClose, registerSegmentName, segmentName]);

  return (
    <AmeDialog open={true} onClose={onClose}>
      <AmeDialogLayout title="定義を新規作成" onSubmit={onSubmit} onClose={onClose}>
        <AmeBox sx={{ display: "flex", gap: "8px", alignItems: "baseline", paddingBottom: "4px" }}>
          <AmeTypography fontSize="body2">名前</AmeTypography>
        </AmeBox>
        <AmeInput value={segmentName} onChange={onChange} fullWidth />
      </AmeDialogLayout>
    </AmeDialog>
  );
};
