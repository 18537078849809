/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseFromJSONTyped,
    SurveyQuestionResponseToJSON,
    SurveyStatus,
    SurveyStatusFromJSON,
    SurveyStatusFromJSONTyped,
    SurveyStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface SurveyResponse
 */
export interface SurveyResponse {
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    surveyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyResponse
     */
    useQrcode: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyResponse
     */
    sendEmail: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    surveyTitle: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    surveyDescription: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    emailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    emailBody?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyResponse
     */
    targetEmployeeCount: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyResponse
     */
    collectedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyResponse
     */
    emailSheetsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyResponse
     */
    unansweredEmailSheetsCount: number;
    /**
     * 
     * @type {Date}
     * @memberof SurveyResponse
     */
    dueDateTime: Date;
    /**
     * 
     * @type {SurveyStatus}
     * @memberof SurveyResponse
     */
    status: SurveyStatus;
    /**
     * 
     * @type {Array<SurveyQuestionResponse>}
     * @memberof SurveyResponse
     */
    questions: Array<SurveyQuestionResponse>;
    /**
     * 
     * @type {Date}
     * @memberof SurveyResponse
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SurveyResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SurveyResponse
     */
    updatedAt: Date;
}

export function SurveyResponseFromJSON(json: any): SurveyResponse {
    return SurveyResponseFromJSONTyped(json, false);
}

export function SurveyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyId': json['surveyId'],
        'useQrcode': json['useQrcode'],
        'sendEmail': json['sendEmail'],
        'displayName': json['displayName'],
        'surveyTitle': json['surveyTitle'],
        'surveyDescription': json['surveyDescription'],
        'emailSubject': !exists(json, 'emailSubject') ? undefined : json['emailSubject'],
        'emailBody': !exists(json, 'emailBody') ? undefined : json['emailBody'],
        'targetEmployeeCount': json['targetEmployeeCount'],
        'collectedCount': json['collectedCount'],
        'emailSheetsCount': json['emailSheetsCount'],
        'unansweredEmailSheetsCount': json['unansweredEmailSheetsCount'],
        'dueDateTime': (new Date(json['dueDateTime'])),
        'status': SurveyStatusFromJSON(json['status']),
        'questions': ((json['questions'] as Array<any>).map(SurveyQuestionResponseFromJSON)),
        'startedAt': !exists(json, 'startedAt') ? undefined : (new Date(json['startedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function SurveyResponseToJSON(value?: SurveyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyId': value.surveyId,
        'useQrcode': value.useQrcode,
        'sendEmail': value.sendEmail,
        'displayName': value.displayName,
        'surveyTitle': value.surveyTitle,
        'surveyDescription': value.surveyDescription,
        'emailSubject': value.emailSubject,
        'emailBody': value.emailBody,
        'targetEmployeeCount': value.targetEmployeeCount,
        'collectedCount': value.collectedCount,
        'emailSheetsCount': value.emailSheetsCount,
        'unansweredEmailSheetsCount': value.unansweredEmailSheetsCount,
        'dueDateTime': (value.dueDateTime.toISOString()),
        'status': SurveyStatusToJSON(value.status),
        'questions': ((value.questions as Array<any>).map(SurveyQuestionResponseToJSON)),
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


