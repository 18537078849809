import React from "react";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

export const LoginDescriptionWrapper: React.FC<Props> = ({ children }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox sx={{ textAlign: "center", paddingBottom: "16px", typography: theme.typography.body2 }}>
      <AmeTypography>{children}</AmeTypography>
    </AmeBox>
  );
};
