/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewReportScoreListByAxis,
    InterviewReportScoreListByAxisFromJSON,
    InterviewReportScoreListByAxisFromJSONTyped,
    InterviewReportScoreListByAxisToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewReportScoreResponse
 */
export interface InterviewReportScoreResponse {
    /**
     * 
     * @type {number}
     * @memberof InterviewReportScoreResponse
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewReportScoreResponse
     */
    count: number;
    /**
     * 
     * @type {Array<InterviewReportScoreListByAxis>}
     * @memberof InterviewReportScoreResponse
     */
    data: Array<InterviewReportScoreListByAxis>;
}

export function InterviewReportScoreResponseFromJSON(json: any): InterviewReportScoreResponse {
    return InterviewReportScoreResponseFromJSONTyped(json, false);
}

export function InterviewReportScoreResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewReportScoreResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'count': json['count'],
        'data': ((json['data'] as Array<any>).map(InterviewReportScoreListByAxisFromJSON)),
    };
}

export function InterviewReportScoreResponseToJSON(value?: InterviewReportScoreResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'count': value.count,
        'data': ((value.data as Array<any>).map(InterviewReportScoreListByAxisToJSON)),
    };
}


