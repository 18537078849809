import React, { useState } from "react";

import { AmeCheckbox } from "../../../../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeDialogLayout } from "../../../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

type Props = {
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

export const DeleteUserDialogLayout: React.FC<Props> = ({ onSubmit, onClose }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  return (
    <AmeDialogLayout
      title="本当に削除しますか？"
      onSubmit={onSubmit}
      onClose={onClose}
      variant="warning"
      submitButtonText="削除"
      submitButtonDisabled={!isChecked}
    >
      <AmeTypography fontSize="body1">
        削除すると、元に戻すことができません。
        <br />
        問題ない場合は削除ボタンを押してください。
      </AmeTypography>
      <Spacer height="24px" />
      <AmeBox sx={{ display: "flex", gap: "8px" }}>
        <AmeCheckbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
        注意事項を確認しました
      </AmeBox>
    </AmeDialogLayout>
  );
};
