/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GlobalSettingResponse,
    GlobalSettingResponseFromJSON,
    GlobalSettingResponseFromJSONTyped,
    GlobalSettingResponseToJSON,
    InputSegmentDefinitionResponse,
    InputSegmentDefinitionResponseFromJSON,
    InputSegmentDefinitionResponseFromJSONTyped,
    InputSegmentDefinitionResponseToJSON,
    SegmentNameSettingResponse,
    SegmentNameSettingResponseFromJSON,
    SegmentNameSettingResponseFromJSONTyped,
    SegmentNameSettingResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettingResponse
 */
export interface SettingResponse {
    /**
     * 
     * @type {SegmentNameSettingResponse}
     * @memberof SettingResponse
     */
    segmentName: SegmentNameSettingResponse;
    /**
     * 
     * @type {Array<InputSegmentDefinitionResponse>}
     * @memberof SettingResponse
     */
    inputSegmentDefinitions: Array<InputSegmentDefinitionResponse>;
    /**
     * 
     * @type {GlobalSettingResponse}
     * @memberof SettingResponse
     */
    globalSetting: GlobalSettingResponse;
}

export function SettingResponseFromJSON(json: any): SettingResponse {
    return SettingResponseFromJSONTyped(json, false);
}

export function SettingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'segmentName': SegmentNameSettingResponseFromJSON(json['segmentName']),
        'inputSegmentDefinitions': ((json['inputSegmentDefinitions'] as Array<any>).map(InputSegmentDefinitionResponseFromJSON)),
        'globalSetting': GlobalSettingResponseFromJSON(json['globalSetting']),
    };
}

export function SettingResponseToJSON(value?: SettingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'segmentName': SegmentNameSettingResponseToJSON(value.segmentName),
        'inputSegmentDefinitions': ((value.inputSegmentDefinitions as Array<any>).map(InputSegmentDefinitionResponseToJSON)),
        'globalSetting': GlobalSettingResponseToJSON(value.globalSetting),
    };
}


