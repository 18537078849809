export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const promiseRetry = async <T>(
    fn: () => Promise<T>,
    options?: { retryTimes?: number; factor?: number; baseWaitMs?: number },
): Promise<T> => {
    const retryTimes = options?.retryTimes || 3;
    const factor = options?.factor || 2;
    const baseWaitMs = options?.baseWaitMs || 1000;

    let attempt = 0;
    for (;;) {
        attempt++;
        try {
            return await fn();
        } catch (e) {
            if (attempt < retryTimes) {
                await sleep(Math.pow(factor, attempt) * baseWaitMs);
                continue;
            }
            throw e;
        }
    }
};
