import React from "react";

import { CSS_SP_MEDIA_QUERY } from "../../../utils/mediaQuery";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
  endElement?: React.ReactNode;
}

export const PaperHeader: React.FC<Props> = ({ children, endElement }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        backgroundColor: theme.common.background.gray40,
        height: "56px",
        borderBottom: `1px solid ${theme.common.border.gray40}`,
        padding: "0 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: theme.typography.body1.fontSize,
        [CSS_SP_MEDIA_QUERY]: {
          height: "56px",
        },
      }}
    >
      <AmeBox>{children}</AmeBox>
      {endElement && <AmeBox sx={{ display: "flex" }}>{endElement}</AmeBox>}
    </AmeBox>
  );
};
