/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LabelingDocumentResponse,
    LabelingDocumentResponseFromJSON,
    LabelingDocumentResponseFromJSONTyped,
    LabelingDocumentResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface LabelingDocumentListResponse
 */
export interface LabelingDocumentListResponse {
    /**
     * 
     * @type {Array<LabelingDocumentResponse>}
     * @memberof LabelingDocumentListResponse
     */
    documents: Array<LabelingDocumentResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof LabelingDocumentListResponse
     */
    isEnd: boolean;
}

export function LabelingDocumentListResponseFromJSON(json: any): LabelingDocumentListResponse {
    return LabelingDocumentListResponseFromJSONTyped(json, false);
}

export function LabelingDocumentListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelingDocumentListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(LabelingDocumentResponseFromJSON)),
        'isEnd': json['isEnd'],
    };
}

export function LabelingDocumentListResponseToJSON(value?: LabelingDocumentListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documents': ((value.documents as Array<any>).map(LabelingDocumentResponseToJSON)),
        'isEnd': value.isEnd,
    };
}


