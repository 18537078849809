import React, { useCallback, useState } from "react";

import { Control, Controller } from "react-hook-form";

import { useGetInterviewFormats } from "../../../../../store/hooks/hr-interview-setting";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeDropbox } from "../../../../atoms/forms/input/AmeDropbox";
import { AmeCommonListItem } from "../../../../atoms/list/AmeCommonListItem";
import { AmeCommonListMainContent } from "../../../../atoms/list/AmeCommonListMainContent";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeDialog } from "../../../../molecules/dialog/AmeDialog";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { FormatMapper } from "../../FormatMapper";

import { InterviewCreationFrom } from "./InterviewCreationForm";

interface InterviewTypeSelectorProps {
  interviewTypeId: string;
  control: Control<InterviewCreationFrom>;
  disabled: boolean;
}

export const InterviewTypeSelector: React.FC<InterviewTypeSelectorProps> = ({ interviewTypeId, control, disabled }) => {
  const interviewFormatsQuery = useGetInterviewFormats(interviewTypeId);
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <Controller
      rules={{ required: "必須です。" }}
      control={control}
      disabled={disabled}
      name={"interviewFormatId"}
      render={({ field }) => (
        <>
          <WaitForSuccess queryState={interviewFormatsQuery}>
            {(formats) => (
              <AmeBox sx={{ display: "flex", width: "100%" }}>
                <AmeDropbox
                  error={false}
                  disabled={field.disabled}
                  size="full"
                  selectedLabel={
                    formats.find(({ hrInterviewFormatId }) => hrInterviewFormatId == field.value)
                      ?.hrInterviewFormatText ?? "選択なし"
                  }
                >
                  {formats.map((hrInterviewFormat) => {
                    return (
                      <AmeCommonListItem
                        onClick={() => {
                          field.onChange(hrInterviewFormat.hrInterviewFormatId);
                        }}
                        key={hrInterviewFormat.hrInterviewFormatId}
                      >
                        <AmeCommonListMainContent>{hrInterviewFormat.hrInterviewFormatText}</AmeCommonListMainContent>
                      </AmeCommonListItem>
                    );
                  })}
                </AmeDropbox>
                <Spacer width="14px" />
                <AmeButton variant="outlined" onClick={onOpen} disabled={!field.value}>
                  フォーマット確認
                </AmeButton>
              </AmeBox>
            )}
          </WaitForSuccess>

          <AmeDialog open={isOpen} onClose={onClose}>
            <FormatMapper onClose={onClose} interviewFormatId={field.value} />
          </AmeDialog>
        </>
      )}
    />
  );
};
