/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationItemAverageScoreResponse,
    EvaluationItemAverageScoreResponseFromJSON,
    EvaluationItemAverageScoreResponseFromJSONTyped,
    EvaluationItemAverageScoreResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewScoreReportResponseData
 */
export interface HrInterviewScoreReportResponseData {
    /**
     * 
     * @type {Array<EvaluationItemAverageScoreResponse>}
     * @memberof HrInterviewScoreReportResponseData
     */
    averageScoreList: Array<EvaluationItemAverageScoreResponse>;
    /**
     * 
     * @type {number}
     * @memberof HrInterviewScoreReportResponseData
     */
    totalAverageScore: number;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewScoreReportResponseData
     */
    label: string;
}

export function HrInterviewScoreReportResponseDataFromJSON(json: any): HrInterviewScoreReportResponseData {
    return HrInterviewScoreReportResponseDataFromJSONTyped(json, false);
}

export function HrInterviewScoreReportResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewScoreReportResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'averageScoreList': ((json['averageScoreList'] as Array<any>).map(EvaluationItemAverageScoreResponseFromJSON)),
        'totalAverageScore': json['totalAverageScore'],
        'label': json['label'],
    };
}

export function HrInterviewScoreReportResponseDataToJSON(value?: HrInterviewScoreReportResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'averageScoreList': ((value.averageScoreList as Array<any>).map(EvaluationItemAverageScoreResponseToJSON)),
        'totalAverageScore': value.totalAverageScore,
        'label': value.label,
    };
}


