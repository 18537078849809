import React from "react";

import { AmeTypography } from "../typography/AmeTypography";

interface Props {
  children: React.ReactNode;
}

export const AmeCommonListMainContent: React.FC<Props> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexGrow: "1",
        flexShrink: "1",
      }}
    >
      <AmeTypography>{children}</AmeTypography>
    </div>
  );
};
