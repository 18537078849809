/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RuleResponse,
    RuleResponseFromJSON,
    RuleResponseFromJSONTyped,
    RuleResponseToJSON,
    SubCategoryResponse,
    SubCategoryResponseFromJSON,
    SubCategoryResponseFromJSONTyped,
    SubCategoryResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuleWithSubCategoryResponse
 */
export interface RuleWithSubCategoryResponse {
    /**
     * 
     * @type {RuleResponse}
     * @memberof RuleWithSubCategoryResponse
     */
    rule: RuleResponse;
    /**
     * 
     * @type {SubCategoryResponse}
     * @memberof RuleWithSubCategoryResponse
     */
    subCategory: SubCategoryResponse;
}

export function RuleWithSubCategoryResponseFromJSON(json: any): RuleWithSubCategoryResponse {
    return RuleWithSubCategoryResponseFromJSONTyped(json, false);
}

export function RuleWithSubCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleWithSubCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rule': RuleResponseFromJSON(json['rule']),
        'subCategory': SubCategoryResponseFromJSON(json['subCategory']),
    };
}

export function RuleWithSubCategoryResponseToJSON(value?: RuleWithSubCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rule': RuleResponseToJSON(value.rule),
        'subCategory': SubCategoryResponseToJSON(value.subCategory),
    };
}


