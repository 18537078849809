import React, { useEffect, useState } from "react";

import { AmeSound } from "../../../hooks/useAmeSound";
import { useHrPentestSnackbar } from "../../../hooks/useHrPentestSnackbar";
import { generateTimeDisplay } from "../../../hooks/useTimer";
import { AmeButton } from "../../atoms/button/AmeButton";
import { Clockwise } from "../../atoms/icon/Clockwise";
import { CounterClockwise } from "../../atoms/icon/CounterClockwise";
import { Pause } from "../../atoms/icon/Pause";
import { Play } from "../../atoms/icon/Play";
import { ChoosableList } from "../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../atoms/list/ChoosableListItem";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../muiWrapper/AmeIconButton";
import { AmePopover } from "../../muiWrapper/AmePopover";

export const SoundPlayer: React.FC<{
  sound: AmeSound;
}> = ({
  sound: { sound: howlSound, isPlaying, backward, forward, autoPlay, toggleAutoPlay, incrementRate, rate, togglePlay },
}) => {
  const [currentTimeMs, setCurrentTimeMs] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimeMs(howlSound.seek() * 1000);
    }, 100);
    return () => clearInterval(interval);
  }, [howlSound]);
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  useEffect(() => {
    howlSound.on("loaderror", () => {
      enqueueErrorSnackbar({ title: "ロードエラーが発生しました。", message: "リロードしてください。" });
    });
  }, [enqueueErrorSnackbar, howlSound]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <AmeBox sx={{ padding: "12px 24px", position: "relative", height: "40px" }}>
      <AmePopover open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <ChoosableList>
          <ChoosableListItem onSelect={() => toggleAutoPlay()}>自動再生: {autoPlay ? "ON" : "OFF"}</ChoosableListItem>
          <ChoosableListItem onSelect={() => incrementRate()}>再生速度: {rate}</ChoosableListItem>
        </ChoosableList>
      </AmePopover>
      <AmeBox
        sx={{ position: "absolute", left: 24, top: "50%", transform: "translate(0, -50%)" }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <AmeButton variant={"outlined"}>設定</AmeButton>
      </AmeBox>
      <AmeBox
        sx={{
          display: "flex",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <AmeIconButton onClick={backward}>
          <CounterClockwise size={16} color={"black"} />
        </AmeIconButton>
        <AmeIconButton onClick={() => togglePlay()}>
          {isPlaying ? <Pause size={16} color={"black"} /> : <Play size={16} color={"black"} />}
        </AmeIconButton>
        <AmeIconButton onClick={forward}>
          <Clockwise size={16} color={"black"} />
        </AmeIconButton>
      </AmeBox>
      <AmeBox sx={{ display: "flex", position: "absolute", top: "50%", right: 24, transform: "translate(0, -50%)" }}>
        {generateTimeDisplay(Math.floor(currentTimeMs / 1000))} / {generateTimeDisplay(howlSound.duration() || 0)}
      </AmeBox>
    </AmeBox>
  );
};
