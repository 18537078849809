import React from "react";

import { ComputationTaskStatus } from "../../../store/hooks/computationTasks";
import { SvgIconProps } from "../../atoms/icon";
import { Analyzing } from "../../atoms/icon/Analyzing";
import { Check } from "../../atoms/icon/Check";
import { Error } from "../../atoms/icon/Error";
import { Help } from "../../atoms/icon/Help";

interface Props {
  taskStatus?: ComputationTaskStatus | null;
  DefaultIcon?: React.FC<SvgIconProps>;
  size?: number;
}

export const TaskStatusIcon: React.FC<Props> = ({ size = 16, taskStatus, DefaultIcon = Help }) => {
  if (taskStatus) {
    return <ComputationTaskStatusIcon size={size} taskStatus={taskStatus} />;
  } else {
    return <DefaultIcon size={size} />;
  }
};

type ComputationTaskStatusIconProps = {
  taskStatus: ComputationTaskStatus;
  size: number;
};

const ComputationTaskStatusIcon: React.FC<ComputationTaskStatusIconProps> = ({ taskStatus, size }) => {
  switch (taskStatus) {
    case ComputationTaskStatus.CREATING:
    case ComputationTaskStatus.UPDATING:
    case ComputationTaskStatus.DELETING:
    case ComputationTaskStatus.PENDING:
      return <Analyzing size={size} />;
    case ComputationTaskStatus.FAILED:
      return <Error color={"error"} size={size} />;
    case ComputationTaskStatus.SUCCESSFUL:
      return <Check color={"success"} size={size} />;
  }
};
