import React from "react";
import { useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ControllerRenderProps, FieldValues, MultipleFieldErrors, Path } from "react-hook-form";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeInput } from "../../atoms/forms/input/AmeInput";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../muiWrapper/AmeIconButton";

type Props<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = {
  field: ControllerRenderProps<TFieldValues, TName>;
  label?: string;
  placeholder?: string;
  errors?: MultipleFieldErrors;
  size?: "small" | "medium";
  error?: boolean;
};

export const PasswordInput = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({
  field,
  label,
  placeholder,
  errors,
  size,
  error,
}: Props<TFieldValues, TName>): React.ReactElement => {
  const theme = useAmeTheme();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      {label ? (
        <AmeBox sx={{ margin: "8px 0", textAlign: "left", color: theme.common.text.black }}>{label}</AmeBox>
      ) : null}
      <AmeInput
        {...field}
        placeholder={placeholder}
        type={showPassword ? "text" : "password"}
        size={size}
        endIcon={
          <AmeIconButton onClick={handleClickShowPassword}>
            {showPassword ? (
              <Visibility htmlColor={theme.common.text.gray40} />
            ) : (
              <VisibilityOff htmlColor={theme.common.text.gray40} />
            )}
          </AmeIconButton>
        }
        error={error}
        fullWidth
      />
      <AmeBox sx={{ color: theme.common.text.error, textAlign: "left", margin: "8px 0" }}>
        {Object.values(errors || {}).join("")}
      </AmeBox>
    </>
  );
};
