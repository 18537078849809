/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputSegmentKey,
    InputSegmentKeyFromJSON,
    InputSegmentKeyFromJSONTyped,
    InputSegmentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface InputSegmentDefinitionResponse
 */
export interface InputSegmentDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof InputSegmentDefinitionResponse
     */
    inputSegmentDefinitionId: string;
    /**
     * 
     * @type {InputSegmentKey}
     * @memberof InputSegmentDefinitionResponse
     */
    segmentKey: InputSegmentKey;
    /**
     * 
     * @type {string}
     * @memberof InputSegmentDefinitionResponse
     */
    segmentValue: string;
    /**
     * 
     * @type {string}
     * @memberof InputSegmentDefinitionResponse
     */
    mappedTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InputSegmentDefinitionResponse
     */
    isDeprecated: boolean;
}

export function InputSegmentDefinitionResponseFromJSON(json: any): InputSegmentDefinitionResponse {
    return InputSegmentDefinitionResponseFromJSONTyped(json, false);
}

export function InputSegmentDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputSegmentDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inputSegmentDefinitionId': json['inputSegmentDefinitionId'],
        'segmentKey': InputSegmentKeyFromJSON(json['segmentKey']),
        'segmentValue': json['segmentValue'],
        'mappedTo': !exists(json, 'mappedTo') ? undefined : json['mappedTo'],
        'isDeprecated': json['isDeprecated'],
    };
}

export function InputSegmentDefinitionResponseToJSON(value?: InputSegmentDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inputSegmentDefinitionId': value.inputSegmentDefinitionId,
        'segmentKey': InputSegmentKeyToJSON(value.segmentKey),
        'segmentValue': value.segmentValue,
        'mappedTo': value.mappedTo,
        'isDeprecated': value.isDeprecated,
    };
}


