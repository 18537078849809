import React from "react";

import { SelectChangeEvent } from "@mui/material";

import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { ReportFilterChipList } from "../ReportFilterChipList";
import { FilterChipContent } from "../ReportFilterChipList/ReportFilterChip";

import { AxisSelect } from "./AxisSelect";
import { DisplayedInterviewsCount } from "./DisplayedInterviewsCount";

interface Props<T extends string> {
  count?: number;
  selectedFilters: FilterChipContent[];
  onChangeAxis: (e: SelectChangeEvent<T>) => void;
  selectedAxis: T;
  axisDisplayNames: Record<T, string>;
}

export const ReportHeader = <T extends string>({
  count,
  selectedFilters,
  onChangeAxis,
  axisDisplayNames,
  selectedAxis,
}: Props<T>) => {
  return (
    <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "56px" }}>
      <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
        {count !== undefined ? <DisplayedInterviewsCount count={count} /> : null}
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, flexShrink: 1, overflowX: "scroll", margin: "0 16px", paddingTop: "8px" }}>
        <ReportFilterChipList selectedFilters={selectedFilters} />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
        <AxisSelect axisDisplayNames={axisDisplayNames} selectedAxis={selectedAxis} onChange={onChangeAxis} />
      </AmeBox>
    </AmeBox>
  );
};
