import React, { useState } from "react";

import { Employee } from "../../../../../../models/Employee";
import { InterviewFileTmpData, useInterviewFileTmpDataList } from "../../common/useInterviewFileTmpDataList";

import { FileEmployeeMapping } from "./FileEmployeeMapping";
import { FileMappingConfirmation } from "./FileMappingConfirmation";

interface Props {
  setInterviewFiles: (files: File[]) => void;
  interviewFiles: File[];
  periodId: number;
  uploadInterviewFile: (
    file: File,
    updatePercentage: (percentage: number) => void,
  ) => Promise<{ tmpFilename: string; suggest?: Employee }>;
  onSubmitInterviewFileMaps: (tmpDataList: InterviewFileTmpData[]) => Promise<void>;
}

export const FileListRegisterWizard: React.FC<Props> = (props) => {
  const { fileTmpDataList, setInterviewFileTmpData } = useInterviewFileTmpDataList(
    props.interviewFiles,
    props.uploadInterviewFile,
  );

  const [currentPage, setCurrentPage] = useState<number>(0);
  if (fileTmpDataList.length === 0) {
    return null;
  }
  if (currentPage === fileTmpDataList.length) {
    return (
      <FileMappingConfirmation
        changePage={setCurrentPage}
        interviewFileTmpDataList={fileTmpDataList}
        onSubmitInterviewFileMaps={props.onSubmitInterviewFileMaps}
        cancelWizard={() => props.setInterviewFiles([])}
      />
    );
  }
  return (
    <FileEmployeeMapping
      page={currentPage}
      changePage={setCurrentPage}
      interviewFileMap={fileTmpDataList[currentPage]}
      setInterviewFileTmpData={(updateParams) => {
        setInterviewFileTmpData(currentPage, updateParams);
      }}
    />
  );
};
