import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const GoodFace: React.FC<Omit<SvgIconProps, "color">> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_10169_203790)">
        <path
          d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
          fill="#FFBE04"
        />
        <path
          d="M4 10.5C4 10.6326 4.05268 10.7598 4.14645 10.8536C4.24021 10.9473 4.36739 11 4.5 11H11.5C11.6326 11 11.7598 10.9473 11.8536 10.8536C11.9473 10.7598 12 10.6326 12 10.5C12 10.3674 11.9473 10.2402 11.8536 10.1464C11.7598 10.0527 11.6326 10 11.5 10H4.5C4.36739 10 4.24021 10.0527 4.14645 10.1464C4.05268 10.2402 4 10.3674 4 10.5ZM7 6.5C7 5.672 6.552 5 6 5C5.448 5 5 5.672 5 6.5C5 7.328 5.448 8 6 8C6.552 8 7 7.328 7 6.5ZM11 6.5C11 5.672 10.552 5 10 5C9.448 5 9 5.672 9 6.5C9 7.328 9.448 8 10 8C10.552 8 11 7.328 11 6.5Z"
          fill="#FFBE04"
        />
      </g>
      <defs>
        <clipPath id="clip0_10169_203790">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
