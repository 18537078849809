import React, { useCallback, useMemo } from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { defaultApi } from "../../../../../../store/defaultApi";
import { useUpdateHrInterview } from "../../../../../../store/hooks/hrInterview";
import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { Reload } from "../../../../../atoms/icon/Reload";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeFormFollowingButtonsLayout } from "../../../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { InterviewSheet } from "./InterviewSheet";
import { InterviewSheetFormType } from "./InterviewSheet/type";
import { Transcription } from "./Transcription";

interface Props {
  hrInterview: HrInterviewDetail;
  interviewFileUrl?: string;
}

export const TranscriptionContent: React.FC<Props> = ({ hrInterview, interviewFileUrl }) => {
  const theme = useAmeTheme();
  const navigate = useNavigate();

  const { register, control, handleSubmit } = useForm<InterviewSheetFormType>({
    defaultValues: {
      contents: hrInterview.interviewAnswers.map((answer) => ({
        hrInterviewAnswerId: answer.interviewAnswerId,
        question: answer.question,
        answer: answer.answer,
      })),
    },
  });
  const [focusedElement, setFocusedElement] = React.useState<HTMLTextAreaElement | HTMLInputElement | null>(null);
  const addParagraphIntoFocusedInput = useMemo(() => {
    if (focusedElement) {
      return (paragraph: string) => {
        if (focusedElement) {
          focusedElement.value = focusedElement.value + paragraph;
          focusedElement.scrollTo();
          focusedElement.focus();
        }
      };
    } else {
      return undefined;
    }
  }, [focusedElement]);

  const updateInterview = useUpdateHrInterview();

  const onSubmit = useCallback(
    async (data: InterviewSheetFormType) => {
      await updateInterview({
        hrInterviewId: hrInterview.hrInterviewId,
        updateHrInterviewRequestBody: {
          interviewFormatAnswers: data.contents.map((content) => ({
            interviewFormatAnswerId: content.hrInterviewAnswerId,
            answer: content.answer,
          })),
        },
      });
      await navigate({
        to: "/hr-interviews/interviews/$interviewId",
        params: { interviewId: hrInterview.hrInterviewId },
      });
    },
    [hrInterview.hrInterviewId, navigate, updateInterview],
  );

  const { refetch } = defaultApi.endpoints.getHrInterview.useQuerySubscription({
    hrInterviewId: hrInterview.hrInterviewId,
  });

  return (
    <>
      <PageLayoutBody type={"wide"}>
        <AmeBox sx={{ display: "flex", gap: "24px", position: "relative", alignItems: "start" }}>
          <AmeBox sx={{ flexGrow: 1 }}>
            <PaperWrapper>
              <PaperHeader>議事録</PaperHeader>
              <PaperBody>
                <InterviewSheet register={register} control={control} setFocusedInput={setFocusedElement} />
              </PaperBody>
            </PaperWrapper>
          </AmeBox>
          <AmeBox sx={{ position: "sticky", top: "64px" }}>
            <PaperWrapper>
              <PaperHeader>音声データ</PaperHeader>
              <AmeBox sx={{ width: "550px", position: "relative" }}>
                {interviewFileUrl ? (
                  hrInterview.transcription ? (
                    <Transcription
                      transcription={hrInterview.transcription}
                      interviewFileUrl={interviewFileUrl}
                      copyParagraph={addParagraphIntoFocusedInput}
                    />
                  ) : (
                    <AmeBox
                      sx={{
                        height: "50vh",
                        width: "100%",
                        backgroundColor: theme.common.background.gray20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "24px",
                      }}
                    >
                      <AmeTypography component={"h5"}>音声データがありません。</AmeTypography>
                      <AmeTypography fontSize={"body1"} textAlign={"center"}>
                        現在音声データを文字起し中です。
                        <br />
                        完了まで30分程度かかる可能性があります。
                      </AmeTypography>
                      <AmeButton onClick={refetch}>
                        <Reload size={16} color={"inherit"} />
                      </AmeButton>
                    </AmeBox>
                  )
                ) : (
                  <AmeBox
                    sx={{
                      height: "50vh",
                      width: "100%",
                      backgroundColor: theme.common.background.gray20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <AmeTypography fontSize={"body1"}>音声データがありません。</AmeTypography>
                    <AmeButton
                      to={"/hr-interviews/interviews/$interviewId/interview-file"}
                      params={{ interviewId: hrInterview.hrInterviewId }}
                    >
                      音声データ登録
                    </AmeButton>
                  </AmeBox>
                )}
              </AmeBox>
            </PaperWrapper>
          </AmeBox>
        </AmeBox>
      </PageLayoutBody>
      <AmeFormFollowingButtonsLayout onPrimary={handleSubmit(onSubmit)} />
    </>
  );
};
