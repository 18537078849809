import React from "react";

import { UseFormRegister, FormState, FieldErrors } from "react-hook-form";

import { UpdateGraphScaleSettingsBody } from "../../../../../store/autogenApi";
import { AmeFormset } from "../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  register: UseFormRegister<UpdateGraphScaleSettingsBody>;
  formState: FormState<UpdateGraphScaleSettingsBody>;
}

const InputWithUnit: React.FC<{
  label: string;
  name: keyof UpdateGraphScaleSettingsBody;
  register: UseFormRegister<UpdateGraphScaleSettingsBody>;
  errors: FieldErrors<UpdateGraphScaleSettingsBody>;
  unit: string;
  min?: number;
  max?: number;
}> = ({ label, name, register, errors, unit, min, max }) => (
  <AmeFormset label={label} errorMessage={errors[name]?.message}>
    <AmeBox sx={{ display: "flex", alignItems: "center" }}>
      <AmeInput
        {...register(name, {
          validate: (value) => {
            if (value === null || value === undefined) return true;
            if (min !== undefined && value < min) return `${min}以上の数値を入力してください。`;
            if (max !== undefined && value > max) return `${max}以下の数値を入力してください。`;
            return true;
          },
        })}
        InputProps={{ style: { width: "150px" } }}
        type="number"
        placeholder="0"
      />
      <AmeBox sx={{ marginLeft: "8px" }}>
        <AmeTypography fontSize="body1">{unit}</AmeTypography>
      </AmeBox>
    </AmeBox>
  </AmeFormset>
);

export const GraphScaleEditor: React.FC<Props> = ({ register, formState }) => {
  return (
    <>
      <PaperWrapper>
        <PaperHeader>
          <AmeTypography fontSize="body1">インタビューKPIレポート</AmeTypography>
        </PaperHeader>
        <PaperBody>
          <InputWithUnit
            label="データ取得数"
            name="maxScaleValueInKpiReport"
            register={register}
            errors={formState.errors}
            unit="人"
            min={0}
          />
          <Spacer height="24px" />
          <InputWithUnit
            label="データ取得率"
            name="maxPercentageInKpiReport"
            register={register}
            errors={formState.errors}
            unit="%"
            min={0}
          />
          <Spacer height="24px" />
          <InputWithUnit
            label="平均時間長さ"
            name="maxMinutesInKpiReport"
            register={register}
            errors={formState.errors}
            unit="分"
            min={0}
          />
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperHeader>
          <AmeTypography fontSize="body1">従業員情報レポート</AmeTypography>
        </PaperHeader>
        <PaperBody>
          <InputWithUnit
            label="在籍人数"
            name="maxScaleCurrentEmployeeValueInEmployeeReport"
            register={register}
            errors={formState.errors}
            unit="人"
            min={0}
          />
          <Spacer height="24px" />
          <InputWithUnit
            label="退職人数、入職人数"
            name="maxScaleValueInEmployeeReport"
            register={register}
            errors={formState.errors}
            unit="人"
            min={0}
          />
          <Spacer height="24px" />
          <InputWithUnit
            label="退職率、入職率"
            name="maxScalePercentageInEmployeeReport"
            register={register}
            errors={formState.errors}
            unit="%"
            min={0}
            max={100}
          />
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperHeader>
          <AmeTypography fontSize="body1">アンケートレポート</AmeTypography>
        </PaperHeader>
        <PaperBody>
          <InputWithUnit
            label="アンケート回答率"
            name="maxScalePercentageInSurveyReport"
            register={register}
            errors={formState.errors}
            unit="%"
            min={0}
            max={100}
          />
          <Spacer height="24px" />
          <InputWithUnit
            label="アンケート回答数"
            name="maxScaleValueInSurveyReport"
            register={register}
            errors={formState.errors}
            unit="人"
            min={0}
          />
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
    </>
  );
};
