import React, { MouseEventHandler, useMemo, useState, MouseEvent, useCallback } from "react";

import { Button } from "@mui/material";
import { Link, LinkProps, AnyRouter, RegisteredRouter } from "@tanstack/react-router";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { Loading } from "../icon/Loading/Loading";

export type AmeButtonVariant = "contained" | "outlined" | "text";
export type AmeButtonColor = "primary" | "secondary" | "basic" | "warning";
/**
 * まだデザインができていないので一部のペアしか使えないようにする。
 */
type ColorProps<V extends AmeButtonVariant> = V extends "contained"
  ? "primary" | "warning"
  : V extends "outlined"
    ? "secondary" | "basic"
    : V extends "text"
      ? "secondary"
      : never;

export type AmeButtonProps<
  V extends AmeButtonVariant,
  TRouter extends AnyRouter = RegisteredRouter,
  TFrom extends string = string,
  TTo extends string = "",
  TMaskFrom extends string = TFrom,
  TMaskTo extends string = "",
> = {
  children?: React.ReactNode;
  size?: "large" | "medium" | "small";
  variant?: V;
  color?: ColorProps<V>;
  type?: "submit" | "reset" | "button";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
} & (NonNullable<unknown> | LinkProps<TRouter, TFrom, TTo, TMaskFrom, TMaskTo>);

export const AmeButton = <
  V extends AmeButtonVariant = "contained",
  TRouter extends AnyRouter = RegisteredRouter,
  TFrom extends string = string,
  TTo extends string = "",
  TMaskFrom extends string = TFrom,
  TMaskTo extends string = "",
>(
  props: AmeButtonProps<V, TRouter, TFrom, TTo, TMaskFrom, TMaskTo>,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (props.onClick) {
        setIsLoading(true);
        try {
          await props.onClick(e);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [props, setIsLoading],
  );
  const style = useButtonStyle(props.color, props.fullWidth);
  const variant = props.variant || "contained";
  const baseButton = (
    <Button
      sx={style}
      onClick={onClick}
      size={props.size}
      type={props.type}
      variant={variant}
      fullWidth={props.fullWidth}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disabled={isLoading || props.disabled}
      disableRipple={variant === "text"}
    >
      <AmeBox component="span" sx={{ visibility: isLoading ? "hidden" : undefined }}>
        {props.children}
      </AmeBox>
      {isLoading ? (
        <AmeBox
          component="span"
          sx={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "center",
            position: "absolute",
            right: 0,
            left: 0,
            margin: "auto",
          }}
        >
          <Loading color={props.variant === "outlined" ? "gray" : "white"} />
        </AmeBox>
      ) : null}
    </Button>
  );
  if ("to" in props) {
    return (
      <Link to={props.to} search={props.search} params={props.params}>
        {baseButton}
      </Link>
    );
  } else {
    return baseButton;
  }
};

const useButtonStyle = (color: AmeButtonColor | undefined, fullWidth: boolean | undefined) => {
  const theme = useAmeTheme();
  return useMemo(
    () => ({
      flexShrink: 0,
      "&.MuiButton-root": {
        borderRadius: "8px",
        textTransform: "none",
        boxShadow: "none",
        fontWeight: "bold",
        minWidth: "unset",
        "&.MuiButton-sizeSmall": {
          lineHeight: "20px",
          padding: "7px 15px",
        },
        "&.MuiButton-sizeMedium": {
          padding: "10px 16px",
        },
        "& .MuiButton-endIcon": {
          marginLeft: fullWidth ? "auto" : "8px",
        },
        "&.MuiButton-contained":
          color === "warning"
            ? {
                border: `1px solid ${theme.common.background.warning100}`,
                backgroundColor: theme.common.background.warning100,
                color: theme.common.text.white,
                "&:hover": {
                  border: `1px solid ${theme.common.background.warning120}`,
                  backgroundColor: theme.common.background.warning120,
                },
                "&.Mui-disabled": {
                  border: `1px solid ${theme.common.background.gray120}`,
                  backgroundColor: theme.common.background.gray120,
                },
              }
            : {
                border: `1px solid ${theme.brand.primary[100]}`,
                backgroundColor: theme.brand.primary[100],
                color: theme.brand.primary.contrastText,
                "&:hover": {
                  border: `1px solid ${theme.brand.primary[120]}`,
                  backgroundColor: theme.brand.primary[120],
                },
                "&.Mui-disabled": {
                  border: `1px solid ${theme.common.background.gray120}`,
                  backgroundColor: theme.common.background.gray120,
                },
              },
        "&.MuiButton-outlined":
          color === "secondary"
            ? {
                border: `1px solid ${theme.brand.secondary[100]}`,
                backgroundColor: theme.common.background.white,
                color: theme.brand.secondary[100],
                "&:hover": {
                  backgroundColor: theme.brand.secondary[20],
                },
                "&.Mui-disabled": {
                  border: `1px solid ${theme.common.border.gray40}`,
                  backgroundColor: theme.common.background.gray40,
                  color: theme.common.text.gray20,
                },
              }
            : {
                border: `1px solid ${theme.common.border.gray40}`,
                backgroundColor: theme.common.background.white,
                color: theme.common.text.black,
                "&:hover": {
                  backgroundColor: theme.common.background.gray40,
                },
                "&.Mui-disabled": {
                  border: `1px solid ${theme.common.border.gray40}`,
                  backgroundColor: theme.common.background.gray40,
                  color: theme.common.text.gray20,
                },
              },
        "&.MuiButton-text": {
          padding: 0,
          border: "none",
          textDecoration: "underline",
          color: theme.brand.secondary[100],
          backgroundColor: "transparent",
          "&:hover": {
            color: theme.brand.secondary[120],
          },
          "&.Mui-disabled": {
            color: theme.common.text.gray20,
          },
        },
      },
    }),
    [theme, color, fullWidth],
  );
};
