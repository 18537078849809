import React from "react";

import { FeedbackStatus } from "../../../../models/constants/values";
import { CheckV2Icon } from "../../../atoms/icon/CheckV2";
import { Elipse } from "../../../atoms/icon/Elipse";
import { IconText } from "../../../molecules/common/IconText";

interface Props {
  status: FeedbackStatus;
}

export const InterviewFeedbackStatus = ({ status }: Props): React.ReactElement => {
  switch (status) {
    case "UNSENT":
      return <IconText icon={<Elipse color={"default"} />}>未送信</IconText>;
    case "UNANSWERED":
      return <IconText icon={<Elipse color={"primary"} />}>未回答</IconText>;
    case "ANSWERED":
      return <IconText icon={<CheckV2Icon color={"success"} />}>回答済</IconText>;
  }
};
