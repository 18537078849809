import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

interface Props {
  children: React.ReactNode;
}
export const BaseWrapper: React.FC<Props> = ({ children }) => {
  const theme = useAmeTheme();
  return (
    <div
      style={{
        width: "100%",
        border: `1px solid ${theme.common.border.gray40}`,
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};
