import React, { useState } from "react";

import { UserStateBeforeSignIn } from "../LoginManager";

import { ConfirmSignUp } from "./ConfirmSignUp";
import { SendConfirmationCode } from "./SendConfirmationCode";

interface Props {
  setUserStateBeforeSignIn: (userState: UserStateBeforeSignIn) => void;
}
export const ForgetPassword: React.FC<Props> = ({ setUserStateBeforeSignIn }) => {
  const [email, setEmail] = useState<string | undefined>();
  return email ? (
    <ConfirmSignUp email={email} setUserStateBeforeSignIn={setUserStateBeforeSignIn} />
  ) : (
    <SendConfirmationCode setEmail={setEmail} setUserStateBeforeSignIn={setUserStateBeforeSignIn} />
  );
};
