import React from "react";

import { Control, Controller } from "react-hook-form";

import { SubCategoryModel } from "../../../../../../models/SubCategory";
import { ChoosableList } from "../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../atoms/list/ChoosableListItem";
import { AmeDropbox_old } from "../../../../../molecules/dropbox/AmeDropbox";
import { QuestionSettingFormType } from "../SurveyQuestionSettingContent";

interface Props {
  index: number;
  control: Control<QuestionSettingFormType, never>;
  subCategoryId: string;
  subCategories: SubCategoryModel[];
}

export const SubCategorySelectCell: React.FC<Props> = (props) => {
  return (
    <Controller
      name={`questions.${props.index}.subCategoryId`}
      control={props.control}
      rules={{ required: true }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <AmeDropbox_old
          size="large"
          value={value}
          onChange={onChange}
          displaySelected={props.subCategories.find((sub) => sub.subCategoryId === value)?.displayName}
          error={error !== undefined}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          forTable
        >
          <ChoosableList>
            {props.subCategories.map((subCategory) => (
              <ChoosableListItem key={subCategory.subCategoryId} value={subCategory.subCategoryId}>
                {subCategory.displayName}
              </ChoosableListItem>
            ))}
          </ChoosableList>
        </AmeDropbox_old>
      )}
    />
  );
};
