import React, { useMemo } from "react";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  title: string;
  children: React.ReactNode;
  justifyContent?: "space-between" | "left";
}

export const SettingItemsContainer: React.FC<Props> = ({ title, children, justifyContent = "space-between" }) => {
  const theme = useAmeTheme();
  const flexGap = useFlexGap(justifyContent);
  return (
    <AmeBox sx={{ borderBottom: `1px solid ${theme.common.border.gray40}`, paddingTop: "12px", marginBottom: "24px" }}>
      <AmeBox sx={{ paddingBottom: "28px" }}>
        <AmeTypography component="h5">{title}</AmeTypography>
      </AmeBox>
      <AmeBox
        sx={{
          paddingBottom: "40px",
          display: "flex",
          justifyContent: justifyContent,
          gap: flexGap,
          flexWrap: "wrap",
        }}
      >
        {children}
      </AmeBox>
    </AmeBox>
  );
};

const useFlexGap = (justifyContent: "space-between" | "left") =>
  useMemo(() => {
    if (justifyContent === "space-between") {
      return "24px 0";
    }
    return "24px";
  }, [justifyContent]);
