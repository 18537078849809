/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Exclude from T those types that are assignable to U
 * @export
 * @enum {string}
 */
export enum ExcludeTranscriptionStatusChecked {
    INITIALIZED = 'initialized',
    TRANSCRIBED = 'transcribed',
    CREATED = 'created',
    CHECKING = 'checking',
    FAILED = 'failed'
}

export function ExcludeTranscriptionStatusCheckedFromJSON(json: any): ExcludeTranscriptionStatusChecked {
    return ExcludeTranscriptionStatusCheckedFromJSONTyped(json, false);
}

export function ExcludeTranscriptionStatusCheckedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcludeTranscriptionStatusChecked {
    return json as ExcludeTranscriptionStatusChecked;
}

export function ExcludeTranscriptionStatusCheckedToJSON(value?: ExcludeTranscriptionStatusChecked | null): any {
    return value as any;
}

