import React from "react";

import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  label: string;
  value: string;
}

export const InterviewerBasicInformationItem: React.FC<Props> = ({ label, value }) => {
  return (
    <AmeBox sx={{ width: "20%" }}>
      <AmeTypography fontSize="caption" color="basicLight">
        {label}
      </AmeTypography>
      <Spacer height="8px" />
      <AmeTypography fontSize="body1">{value}</AmeTypography>
    </AmeBox>
  );
};
