import React, { useContext } from "react";

import { createFileRoute, Outlet } from "@tanstack/react-router";

import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { MyselfContext } from "../../../organisms/common/MyselfContext";
import { InterviewerNavigator } from "../../../organisms/common/template/InterviewerNavigator";

const AuthorizedForAdmin: React.FC = () => {
  const { myUserData } = useContext(MyselfContext);
  if (myUserData.isAdmin) {
    return <Outlet />;
  } else {
    return (
      <>
        <AmeTypography fontSize="body2">
          こちらのページを閲覧する権限がありません。
          <br />
          下記ページから必要な操作を行ってください。
        </AmeTypography>
        <Spacer height="24px" />
        <InterviewerNavigator />
      </>
    );
  }
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin")({
  component: AuthorizedForAdmin,
});
