import React, { useMemo } from "react";

import { TagCheckboxesFormType } from "..";
import { Sentence } from "../../../../../../../store/hooks/interviewReport";
import { useAmeTheme } from "../../../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface Props {
  tagsAreChecked: TagCheckboxesFormType;
  sentence: Sentence;
}

export const RetirementVoiceListRow: React.FC<Props> = ({ sentence, tagsAreChecked }) => {
  const theme = useAmeTheme();
  const tags = useMemo(() => {
    const results: string[] = [];
    for (const [key, value] of Object.entries(tagsAreChecked)) {
      if (value) {
        if (key === "subCategoryId") {
          // results.push(...sentence.subCategories);
        } else {
          results.push(sentence.segments[key]);
        }
      }
    }
    return results;
  }, [tagsAreChecked, sentence]);

  const tagText = useMemo(() => tags.map((tag) => `#${tag}`).join(" "), [tags]);

  return (
    <AmeBox
      sx={{
        display: "flex",
        gap: "8px",
        paddingBottom: "8px",
        borderBottom: `1px solid ${theme.common.border.gray40}`,
      }}
    >
      <AmeBox sx={{ flexGrow: 1 }}>
        <AmeTypography>
          {sentence.sentence}
          <AmeBox component="span" sx={{ color: theme.common.text.gray20 }}>
            {tagText}
          </AmeBox>
        </AmeTypography>
      </AmeBox>
      <AmeButton
        variant="text"
        to={"/interviews/$interviewId"}
        params={{ interviewId: sentence.exEmployeeInterviewId }}
        search={{ voice: encodeURI(sentence.sentence) }}
      >
        詳細
      </AmeButton>
    </AmeBox>
  );
};
