import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetUserQuery } from "../../../../../../../../store/hooks/users";
import { BreadcrumbPageLayoutHeader } from "../../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../../molecules/Loading/WaitForSuccess";
import { UserDetailPage } from "../../../../../../../organisms/settings/members/users/userId/UserDetail";

export const UserPage = () => {
  const { userId } = Route.useParams();
  const userQueryState = useGetUserQuery(userId!);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          {
            title: "設定",
            to: "/settings",
          },
          {
            title: "ユーザー管理",
            to: "/hidden-pages/members/users",
          },
        ]}
        currentTitle="ユーザー詳細"
      />
      <PageLayoutBody>
        <WaitForSuccess queryState={userQueryState}>
          {(user) => <UserDetailPage user={user} userId={userId!} />}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/members/users/$userId/")({
  component: UserPage,
});
