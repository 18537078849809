import React, { useCallback, useContext } from "react";

import { Link } from "@tanstack/react-router";
import { Auth } from "aws-amplify";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { ArrowRight } from "../../../atoms/icon/ArrowRight";
import { Close } from "../../../atoms/icon/Close";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { CurrentCognitoUserContext } from "../Authenticator";
import { DataSourceContext } from "../DataSourceContext";

interface SideBarProps {
  onClose: () => void;
}

export const SideBar: React.FC<SideBarProps> = ({ onClose }) => {
  const theme = useAmeTheme();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const { setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);
  const {
    setting: {
      globalSetting: { activateHrInterview },
    },
  } = useContext(DataSourceContext);

  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      setCurrentCognitoUser(undefined);
    } catch (e) {
      enqueueErrorSnackbar();
    }
  }, [setCurrentCognitoUser, enqueueErrorSnackbar]);

  const handleLogout = async () => {
    await logout();
    onClose();
  };

  return (
    <AmeBox
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        width: "300px",
        padding: "16px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AmeBox
        sx={{
          height: "36px",
          display: "flex",
          paddingRight: "8px",
          paddingTop: "2px",
          justifyContent: "flex-end",
        }}
      >
        <Close onClick={onClose} color="black" size={16} />
      </AmeBox>
      <Spacer height="10px" />
      <AmeBox>
        <Link
          to="/interview-files"
          search={{ page: 1 } as never}
          onClick={onClose}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <AmeBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 10px 16px",
              borderBottom: `1px solid ${theme.common.border.gray40}`,
            }}
          >
            <AmeTypography fontSize="body1">退職者音声一覧</AmeTypography>
            <ArrowRight color="black" size={16} />
          </AmeBox>
        </Link>
        <Spacer height="8px" />
        {activateHrInterview ? (
          <Link
            to="/hr-interviews/interview-files"
            search={{ page: 1 } as never}
            onClick={onClose}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <AmeBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px 16px 10px 16px",
                borderBottom: `1px solid ${theme.common.border.gray40}`,
              }}
            >
              <AmeTypography fontSize="body1">在職者音声一覧</AmeTypography>
              <ArrowRight color="black" size={16} />
            </AmeBox>
          </Link>
        ) : null}
        <Spacer height="8px" />
        <AmeBox
          onClick={handleLogout}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 10px 16px",
            borderBottom: `1px solid ${theme.common.border.gray40}`,
            cursor: "pointer",
          }}
        >
          <AmeTypography fontSize="body1">ログアウト</AmeTypography>
          <ArrowRight color="black" size={16} />
        </AmeBox>
      </AmeBox>
    </AmeBox>
  );
};
