/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackSettingsAnswer,
    InterviewFeedbackSettingsAnswerFromJSON,
    InterviewFeedbackSettingsAnswerFromJSONTyped,
    InterviewFeedbackSettingsAnswerToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFeedbackSettingsQuestion
 */
export interface InterviewFeedbackSettingsQuestion {
    /**
     * 
     * @type {Array<InterviewFeedbackSettingsAnswer>}
     * @memberof InterviewFeedbackSettingsQuestion
     */
    answerChoices: Array<InterviewFeedbackSettingsAnswer>;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackSettingsQuestion
     */
    question: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewFeedbackSettingsQuestion
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackSettingsQuestion
     */
    interviewFeedbackQuestionId: string;
}

export function InterviewFeedbackSettingsQuestionFromJSON(json: any): InterviewFeedbackSettingsQuestion {
    return InterviewFeedbackSettingsQuestionFromJSONTyped(json, false);
}

export function InterviewFeedbackSettingsQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFeedbackSettingsQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answerChoices': ((json['answerChoices'] as Array<any>).map(InterviewFeedbackSettingsAnswerFromJSON)),
        'question': json['question'],
        'priority': json['priority'],
        'interviewFeedbackQuestionId': json['interviewFeedbackQuestionId'],
    };
}

export function InterviewFeedbackSettingsQuestionToJSON(value?: InterviewFeedbackSettingsQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answerChoices': ((value.answerChoices as Array<any>).map(InterviewFeedbackSettingsAnswerToJSON)),
        'question': value.question,
        'priority': value.priority,
        'interviewFeedbackQuestionId': value.interviewFeedbackQuestionId,
    };
}


