/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFileResponse,
    InterviewFileResponseFromJSON,
    InterviewFileResponseFromJSONTyped,
    InterviewFileResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetInterviewFilesResponse
 */
export interface GetInterviewFilesResponse {
    /**
     * 
     * @type {Array<InterviewFileResponse>}
     * @memberof GetInterviewFilesResponse
     */
    interviewFiles: Array<InterviewFileResponse>;
    /**
     * 
     * @type {number}
     * @memberof GetInterviewFilesResponse
     */
    totalCount: number;
}

export function GetInterviewFilesResponseFromJSON(json: any): GetInterviewFilesResponse {
    return GetInterviewFilesResponseFromJSONTyped(json, false);
}

export function GetInterviewFilesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInterviewFilesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interviewFiles': ((json['interviewFiles'] as Array<any>).map(InterviewFileResponseFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function GetInterviewFilesResponseToJSON(value?: GetInterviewFilesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interviewFiles': ((value.interviewFiles as Array<any>).map(InterviewFileResponseToJSON)),
        'totalCount': value.totalCount,
    };
}


