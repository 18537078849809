export const createBlobFromText = (text: string, mimeType: string) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  return new Blob([bom, text], { type: mimeType });
};

export const createBlobFromStringArray = (array: string[][], mimeType: string): Blob => {
  return createBlobFromText(array.map((v) => v.join(",")).join("\n"), mimeType);
};

export const saveFile = (fileName: string, content: Blob) => {
  const url = window.URL.createObjectURL(content);
  const aTag = document.createElement("a");
  aTag.href = url;
  aTag.download = fileName;
  aTag.click();
  aTag.remove();
};
