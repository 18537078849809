/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComputationTaskStatuses,
    ComputationTaskStatusesFromJSON,
    ComputationTaskStatusesFromJSONTyped,
    ComputationTaskStatusesToJSON,
} from './';

/**
 * 
 * @export
 * @interface ComputationTaskResponse
 */
export interface ComputationTaskResponse {
    /**
     * 
     * @type {string}
     * @memberof ComputationTaskResponse
     */
    computationTaskId: string;
    /**
     * 
     * @type {string}
     * @memberof ComputationTaskResponse
     */
    ruleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComputationTaskResponse
     */
    subCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof ComputationTaskResponse
     */
    categoryId?: string;
    /**
     * 
     * @type {ComputationTaskStatuses}
     * @memberof ComputationTaskResponse
     */
    computationTaskStatus?: ComputationTaskStatuses;
    /**
     * 
     * @type {string}
     * @memberof ComputationTaskResponse
     */
    processingEvent?: string;
    /**
     * 
     * @type {Date}
     * @memberof ComputationTaskResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ComputationTaskResponse
     */
    updatedAt: Date;
}

export function ComputationTaskResponseFromJSON(json: any): ComputationTaskResponse {
    return ComputationTaskResponseFromJSONTyped(json, false);
}

export function ComputationTaskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComputationTaskResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'computationTaskId': json['computationTaskId'],
        'ruleId': !exists(json, 'ruleId') ? undefined : json['ruleId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'computationTaskStatus': !exists(json, 'computationTaskStatus') ? undefined : ComputationTaskStatusesFromJSON(json['computationTaskStatus']),
        'processingEvent': !exists(json, 'processingEvent') ? undefined : json['processingEvent'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ComputationTaskResponseToJSON(value?: ComputationTaskResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'computationTaskId': value.computationTaskId,
        'ruleId': value.ruleId,
        'subCategoryId': value.subCategoryId,
        'categoryId': value.categoryId,
        'computationTaskStatus': ComputationTaskStatusesToJSON(value.computationTaskStatus),
        'processingEvent': value.processingEvent,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


