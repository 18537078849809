/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateInterviewFormatRequestBodyContents,
    UpdateInterviewFormatRequestBodyContentsFromJSON,
    UpdateInterviewFormatRequestBodyContentsFromJSONTyped,
    UpdateInterviewFormatRequestBodyContentsToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateInterviewFormatRequestBody
 */
export interface UpdateInterviewFormatRequestBody {
    /**
     * idがある場合は更新、ない場合は新規作成
     * @type {Array<UpdateInterviewFormatRequestBodyContents>}
     * @memberof UpdateInterviewFormatRequestBody
     */
    contents?: Array<UpdateInterviewFormatRequestBodyContents>;
    /**
     * 
     * @type {number}
     * @memberof UpdateInterviewFormatRequestBody
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateInterviewFormatRequestBody
     */
    displayName?: string;
}

export function UpdateInterviewFormatRequestBodyFromJSON(json: any): UpdateInterviewFormatRequestBody {
    return UpdateInterviewFormatRequestBodyFromJSONTyped(json, false);
}

export function UpdateInterviewFormatRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInterviewFormatRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contents': !exists(json, 'contents') ? undefined : ((json['contents'] as Array<any>).map(UpdateInterviewFormatRequestBodyContentsFromJSON)),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
    };
}

export function UpdateInterviewFormatRequestBodyToJSON(value?: UpdateInterviewFormatRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contents': value.contents === undefined ? undefined : ((value.contents as Array<any>).map(UpdateInterviewFormatRequestBodyContentsToJSON)),
        'order': value.order,
        'displayName': value.displayName,
    };
}


