import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { PasswordSettingForm } from "../../../../../organisms/settings/passwords/PasswordSettingForm";

const PasswordSetting: React.FC = () => {
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[{ title: "設定", to: "/settings" }]} currentTitle="アンケートメール設定" />
      <PageLayoutBody type="basic">
        <PaperWrapper>
          <PaperHeader>パスワード設定</PaperHeader>
          <PaperBody>
            <PasswordSettingForm />
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/passwords/")({
  component: PasswordSetting,
});
