import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetHrInterview } from "../../../../../../../store/hooks/hrInterview";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { SendFeedbackEmailContent } from "../../../../../../organisms/hr-interviews/interviews/$interviewId/send-email";

export const SendFeedbackEmailPage = () => {
  const { interviewId } = Route.useParams();
  const interviewDetailQueryState = useGetHrInterview(interviewId);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "在職者面談一覧", to: "/hr-interviews/interviews" },
          { title: "在職者面談詳細", to: "/hr-interviews/interviews/$interviewId", params: { interviewId } },
        ]}
        currentTitle={"アンケートメール送信"}
      />
      <PageLayoutBody>
        <WaitForSuccess queryState={interviewDetailQueryState}>
          {(interviewDetail) => (
            <>
              <SendFeedbackEmailContent interviewId={interviewId} email={interviewDetail.employee?.email} />
            </>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-all/hr-interviews/interviews/$interviewId/send-email",
)({
  component: SendFeedbackEmailPage,
});
