import React, { useContext } from "react";

import { differenceInMonths, differenceInYears } from "date-fns";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { DefinitionList } from "../../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { DataSourceContext } from "../../../../common/DataSourceContext";

interface Props {
  interviewDetail: HrInterviewDetail;
}

export const IntervieweeAttributes: React.FC<Props> = ({ interviewDetail }) => {
  const {
    setting: { segmentNames },
  } = useContext(DataSourceContext);
  return (
    <PaperWrapper>
      <PaperHeader>面談者属性情報</PaperHeader>
      <PaperBody>
        <DefinitionList>
          <DefinitionListItem label="従業員番号" value={interviewDetail.employee?.customerCreatedId} />
          <DefinitionListItem label="名前" value={interviewDetail.employee?.name} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            label="勤続月数"
            value={
              interviewDetail.interviewDate && interviewDetail.employee?.hireDate
                ? differenceInMonths(interviewDetail.interviewDate, interviewDetail.employee.hireDate) + "ヶ月"
                : "-"
            }
          />
          <DefinitionListItem
            label="年齢"
            value={
              interviewDetail.interviewDate && interviewDetail.employee?.dateOfBirth
                ? differenceInYears(interviewDetail.interviewDate, interviewDetail.employee.dateOfBirth) + "歳"
                : "-"
            }
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="入社日" value={interviewDetail.employee?.getHireDate()} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            label={segmentNames.SEX}
            value={interviewDetail.attributes?.getAttributeByKey("SEX")?.attributeValue}
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            label={segmentNames.DEPARTMENT}
            value={interviewDetail.attributes?.getAttributeByKey("DEPARTMENT")?.attributeValue}
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            label={segmentNames.FREE1}
            value={interviewDetail.attributes?.getAttributeByKey("FREE1")?.attributeValue}
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            label={segmentNames.FREE2}
            value={interviewDetail.attributes?.getAttributeByKey("FREE2")?.attributeValue}
          />
        </DefinitionList>
      </PaperBody>
    </PaperWrapper>
  );
};
