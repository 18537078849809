/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SegmentKey {
    SEX = 'SEX',
    DEPARTMENT = 'DEPARTMENT',
    FREE1 = 'FREE1',
    FREE2 = 'FREE2',
    MONTHS_OF_SERVICE = 'MONTHS_OF_SERVICE',
    AGE = 'AGE'
}

export function SegmentKeyFromJSON(json: any): SegmentKey {
    return SegmentKeyFromJSONTyped(json, false);
}

export function SegmentKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SegmentKey {
    return json as SegmentKey;
}

export function SegmentKeyToJSON(value?: SegmentKey | null): any {
    return value as any;
}

