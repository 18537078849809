/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HrInterviewAnalysisStatus {
    FAILED = 'FAILED',
    ANALYZING = 'ANALYZING',
    ANALYZED = 'ANALYZED'
}

export function HrInterviewAnalysisStatusFromJSON(json: any): HrInterviewAnalysisStatus {
    return HrInterviewAnalysisStatusFromJSONTyped(json, false);
}

export function HrInterviewAnalysisStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewAnalysisStatus {
    return json as HrInterviewAnalysisStatus;
}

export function HrInterviewAnalysisStatusToJSON(value?: HrInterviewAnalysisStatus | null): any {
    return value as any;
}

