import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { Spacer } from "../../atoms/spacers/Spacer";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  groupName: string;
  children: NonNullable<React.ReactNode>;
};

export const FilterGroup: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        display: "flex",
        flexDirection: "column",
        flexShrink: 1,
        borderBottom: `1px solid ${theme.common.border.gray40}`,
      }}
    >
      <Spacer height="10px"></Spacer>
      <AmeBox sx={{ padding: "0px 16px 0px 16px" }}>
        <AmeTypography component="h6">{props.groupName}</AmeTypography>
      </AmeBox>
      <Spacer height="8px"></Spacer>
      {props.children}
      <Spacer height="10px"></Spacer>
    </AmeBox>
  );
};
