/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddEmployeeAttributesBody,
    AddEmployeeAttributesBodyFromJSON,
    AddEmployeeAttributesBodyToJSON,
    AddEmployeeDefinitionRequestBody,
    AddEmployeeDefinitionRequestBodyFromJSON,
    AddEmployeeDefinitionRequestBodyToJSON,
    AddSubCategoriesRequestBody,
    AddSubCategoriesRequestBodyFromJSON,
    AddSubCategoriesRequestBodyToJSON,
    AxisLiteralOfTypeofEMPLOYEEREPORTAXISFACTORY,
    AxisLiteralOfTypeofEMPLOYEEREPORTAXISFACTORYFromJSON,
    AxisLiteralOfTypeofEMPLOYEEREPORTAXISFACTORYToJSON,
    AxisLiteralOfTypeofHRINTERVIEWREPORTGRAPHAXISFACTORY,
    AxisLiteralOfTypeofHRINTERVIEWREPORTGRAPHAXISFACTORYFromJSON,
    AxisLiteralOfTypeofHRINTERVIEWREPORTGRAPHAXISFACTORYToJSON,
    AxisLiteralOfTypeofINTERVIEWREPORTGRAPHAXISFACTORY,
    AxisLiteralOfTypeofINTERVIEWREPORTGRAPHAXISFACTORYFromJSON,
    AxisLiteralOfTypeofINTERVIEWREPORTGRAPHAXISFACTORYToJSON,
    AxisLiteralOfTypeofSURVEYREPORTAXISFACTORY,
    AxisLiteralOfTypeofSURVEYREPORTAXISFACTORYFromJSON,
    AxisLiteralOfTypeofSURVEYREPORTAXISFACTORYToJSON,
    CalculatedSegmentKey,
    CalculatedSegmentKeyFromJSON,
    CalculatedSegmentKeyToJSON,
    CalculatedSegmentSettingResponse,
    CalculatedSegmentSettingResponseFromJSON,
    CalculatedSegmentSettingResponseToJSON,
    CarryOverEmployeesToNextPeriodRequestBody,
    CarryOverEmployeesToNextPeriodRequestBodyFromJSON,
    CarryOverEmployeesToNextPeriodRequestBodyToJSON,
    CategoryResponse,
    CategoryResponseFromJSON,
    CategoryResponseToJSON,
    CompleteHrInterviewFileUploadBody,
    CompleteHrInterviewFileUploadBodyFromJSON,
    CompleteHrInterviewFileUploadBodyToJSON,
    CompleteInterviewAudioFileUploadRequestBody,
    CompleteInterviewAudioFileUploadRequestBodyFromJSON,
    CompleteInterviewAudioFileUploadRequestBodyToJSON,
    ComputationTaskResponse,
    ComputationTaskResponseFromJSON,
    ComputationTaskResponseToJSON,
    ConfigurationResponse,
    ConfigurationResponseFromJSON,
    ConfigurationResponseToJSON,
    CreateCategoryRequestBody,
    CreateCategoryRequestBodyFromJSON,
    CreateCategoryRequestBodyToJSON,
    CreateEmployeeChangeSetResponse,
    CreateEmployeeChangeSetResponseFromJSON,
    CreateEmployeeChangeSetResponseToJSON,
    CreateExEmployeeInterviewRequestBody,
    CreateExEmployeeInterviewRequestBodyFromJSON,
    CreateExEmployeeInterviewRequestBodyToJSON,
    CreateHrInterviewFileRequestBody,
    CreateHrInterviewFileRequestBodyFromJSON,
    CreateHrInterviewFileRequestBodyToJSON,
    CreateHrInterviewRequestBody,
    CreateHrInterviewRequestBodyFromJSON,
    CreateHrInterviewRequestBodyToJSON,
    CreateInterviewAudioFileUploadUrlsRequestBody,
    CreateInterviewAudioFileUploadUrlsRequestBodyFromJSON,
    CreateInterviewAudioFileUploadUrlsRequestBodyToJSON,
    CreateInterviewAudioFileUploadUrlsResponse,
    CreateInterviewAudioFileUploadUrlsResponseFromJSON,
    CreateInterviewAudioFileUploadUrlsResponseToJSON,
    CreateInterviewFilesRequestBody,
    CreateInterviewFilesRequestBodyFromJSON,
    CreateInterviewFilesRequestBodyToJSON,
    CreateInterviewFormatRequestBody,
    CreateInterviewFormatRequestBodyFromJSON,
    CreateInterviewFormatRequestBodyToJSON,
    CreateInterviewTypeRequestBody,
    CreateInterviewTypeRequestBodyFromJSON,
    CreateInterviewTypeRequestBodyToJSON,
    CreateNoteRequestBody,
    CreateNoteRequestBodyFromJSON,
    CreateNoteRequestBodyToJSON,
    CreatePeriodRequestBody,
    CreatePeriodRequestBodyFromJSON,
    CreatePeriodRequestBodyToJSON,
    CreateRuleRequestBodyString,
    CreateRuleRequestBodyStringFromJSON,
    CreateRuleRequestBodyStringToJSON,
    CreateSegmentDefinitionRequestBody,
    CreateSegmentDefinitionRequestBodyFromJSON,
    CreateSegmentDefinitionRequestBodyToJSON,
    CreateSubCategoryRequestBody,
    CreateSubCategoryRequestBodyFromJSON,
    CreateSubCategoryRequestBodyToJSON,
    CreateSurveyRequestBody,
    CreateSurveyRequestBodyFromJSON,
    CreateSurveyRequestBodyToJSON,
    CreateUserGroupRequest,
    CreateUserGroupRequestFromJSON,
    CreateUserGroupRequestToJSON,
    CreateUserRequestBody,
    CreateUserRequestBodyFromJSON,
    CreateUserRequestBodyToJSON,
    DocumentListResponse,
    DocumentListResponseFromJSON,
    DocumentListResponseToJSON,
    DocumentResponse,
    DocumentResponseFromJSON,
    DocumentResponseToJSON,
    EditExEmployeeInterviewRequestBody,
    EditExEmployeeInterviewRequestBodyFromJSON,
    EditExEmployeeInterviewRequestBodyToJSON,
    EditSegmentDefinitionRequestBody,
    EditSegmentDefinitionRequestBodyFromJSON,
    EditSegmentDefinitionRequestBodyToJSON,
    EmployeeAttributeResponse,
    EmployeeAttributeResponseFromJSON,
    EmployeeAttributeResponseToJSON,
    EmployeeChangeSetSummaryResponse,
    EmployeeChangeSetSummaryResponseFromJSON,
    EmployeeChangeSetSummaryResponseToJSON,
    EmployeeLifeLogResponse,
    EmployeeLifeLogResponseFromJSON,
    EmployeeLifeLogResponseToJSON,
    EmployeeListResponse,
    EmployeeListResponseFromJSON,
    EmployeeListResponseToJSON,
    EmployeeReportResponse,
    EmployeeReportResponseFromJSON,
    EmployeeReportResponseToJSON,
    EmployeeTenureResponse,
    EmployeeTenureResponseFromJSON,
    EmployeeTenureResponseToJSON,
    ExEmployeeInterviewListResponse,
    ExEmployeeInterviewListResponseFromJSON,
    ExEmployeeInterviewListResponseToJSON,
    ExEmployeeInterviewResponse,
    ExEmployeeInterviewResponseFromJSON,
    ExEmployeeInterviewResponseToJSON,
    ExEmployeeReviewsSummaryResponse,
    ExEmployeeReviewsSummaryResponseFromJSON,
    ExEmployeeReviewsSummaryResponseToJSON,
    GenericStartLabelingSessionRequestBodyString,
    GenericStartLabelingSessionRequestBodyStringFromJSON,
    GenericStartLabelingSessionRequestBodyStringToJSON,
    GenericUpdateRuleRequestBodyString,
    GenericUpdateRuleRequestBodyStringFromJSON,
    GenericUpdateRuleRequestBodyStringToJSON,
    GetHrInterviewFileUploaderUrlBody,
    GetHrInterviewFileUploaderUrlBodyFromJSON,
    GetHrInterviewFileUploaderUrlBodyToJSON,
    GetHrInterviewFileUploaderUrlResponse,
    GetHrInterviewFileUploaderUrlResponseFromJSON,
    GetHrInterviewFileUploaderUrlResponseToJSON,
    GetInterviewAudioFileUrlResponse,
    GetInterviewAudioFileUrlResponseFromJSON,
    GetInterviewAudioFileUrlResponseToJSON,
    GetInterviewFilesResponse,
    GetInterviewFilesResponseFromJSON,
    GetInterviewFilesResponseToJSON,
    HealthStatusResponse,
    HealthStatusResponseFromJSON,
    HealthStatusResponseToJSON,
    HrInterviewAnalysisStatusRequest,
    HrInterviewAnalysisStatusRequestFromJSON,
    HrInterviewAnalysisStatusRequestToJSON,
    HrInterviewDetailResponse,
    HrInterviewDetailResponseFromJSON,
    HrInterviewDetailResponseToJSON,
    HrInterviewFeedbackQuestionListResponse,
    HrInterviewFeedbackQuestionListResponseFromJSON,
    HrInterviewFeedbackQuestionListResponseToJSON,
    HrInterviewFeedbackStatus,
    HrInterviewFeedbackStatusFromJSON,
    HrInterviewFeedbackStatusToJSON,
    HrInterviewFileListResponse,
    HrInterviewFileListResponseFromJSON,
    HrInterviewFileListResponseToJSON,
    HrInterviewFileResponse,
    HrInterviewFileResponseFromJSON,
    HrInterviewFileResponseToJSON,
    HrInterviewListResponse,
    HrInterviewListResponseFromJSON,
    HrInterviewListResponseToJSON,
    HrInterviewQualityReportAxis,
    HrInterviewQualityReportAxisFromJSON,
    HrInterviewQualityReportAxisToJSON,
    HrInterviewQualityReportResponse,
    HrInterviewQualityReportResponseFromJSON,
    HrInterviewQualityReportResponseToJSON,
    HrInterviewReportResponse,
    HrInterviewReportResponseFromJSON,
    HrInterviewReportResponseToJSON,
    HrInterviewResponse,
    HrInterviewResponseFromJSON,
    HrInterviewResponseToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InputSegmentDefinitionResponse,
    InputSegmentDefinitionResponseFromJSON,
    InputSegmentDefinitionResponseToJSON,
    InputSegmentKey,
    InputSegmentKeyFromJSON,
    InputSegmentKeyToJSON,
    InterviewDetailResponse,
    InterviewDetailResponseFromJSON,
    InterviewDetailResponseToJSON,
    InterviewFeedbackListResponse,
    InterviewFeedbackListResponseFromJSON,
    InterviewFeedbackListResponseToJSON,
    InterviewFeedbackSettingsResponse,
    InterviewFeedbackSettingsResponseFromJSON,
    InterviewFeedbackSettingsResponseToJSON,
    InterviewFileResponse,
    InterviewFileResponseFromJSON,
    InterviewFileResponseToJSON,
    InterviewFormatContentResponse,
    InterviewFormatContentResponseFromJSON,
    InterviewFormatContentResponseToJSON,
    InterviewFormatListResponse,
    InterviewFormatListResponseFromJSON,
    InterviewFormatListResponseToJSON,
    InterviewFormatResponse,
    InterviewFormatResponseFromJSON,
    InterviewFormatResponseToJSON,
    InterviewKpiResponse,
    InterviewKpiResponseFromJSON,
    InterviewKpiResponseToJSON,
    InterviewQualityReportListResponse,
    InterviewQualityReportListResponseFromJSON,
    InterviewQualityReportListResponseToJSON,
    InterviewQualityReportResponse,
    InterviewQualityReportResponseFromJSON,
    InterviewQualityReportResponseToJSON,
    InterviewReportScoreResponse,
    InterviewReportScoreResponseFromJSON,
    InterviewReportScoreResponseToJSON,
    InterviewTypeListResponse,
    InterviewTypeListResponseFromJSON,
    InterviewTypeListResponseToJSON,
    InterviewTypeResponse,
    InterviewTypeResponseFromJSON,
    InterviewTypeResponseToJSON,
    LabelStatsResponse,
    LabelStatsResponseFromJSON,
    LabelStatsResponseToJSON,
    LabelType,
    LabelTypeFromJSON,
    LabelTypeToJSON,
    LabeledDocumentListResponse,
    LabeledDocumentListResponseFromJSON,
    LabeledDocumentListResponseToJSON,
    LabelingDocumentListResponse,
    LabelingDocumentListResponseFromJSON,
    LabelingDocumentListResponseToJSON,
    NoteListResponse,
    NoteListResponseFromJSON,
    NoteListResponseToJSON,
    NoteResponse,
    NoteResponseFromJSON,
    NoteResponseToJSON,
    NullHrInterviewTranscriptionStatusRequest,
    NullHrInterviewTranscriptionStatusRequestFromJSON,
    NullHrInterviewTranscriptionStatusRequestToJSON,
    PartialCreateUserGroupRequest,
    PartialCreateUserGroupRequestFromJSON,
    PartialCreateUserGroupRequestToJSON,
    PeriodResponse,
    PeriodResponseFromJSON,
    PeriodResponseToJSON,
    RegisterCalculatedSegmentSettingRequestBody,
    RegisterCalculatedSegmentSettingRequestBodyFromJSON,
    RegisterCalculatedSegmentSettingRequestBodyToJSON,
    RegisterInterviewFormatContentsRequestBody,
    RegisterInterviewFormatContentsRequestBodyFromJSON,
    RegisterInterviewFormatContentsRequestBodyToJSON,
    RegisterInterviewFormatsRequestBody,
    RegisterInterviewFormatsRequestBodyFromJSON,
    RegisterInterviewFormatsRequestBodyToJSON,
    RegisterInterviewTypesRequestBody,
    RegisterInterviewTypesRequestBodyFromJSON,
    RegisterInterviewTypesRequestBodyToJSON,
    RegisterSegmentNameRequestBody,
    RegisterSegmentNameRequestBodyFromJSON,
    RegisterSegmentNameRequestBodyToJSON,
    RegisterSurveyQuestionRequestBody,
    RegisterSurveyQuestionRequestBodyFromJSON,
    RegisterSurveyQuestionRequestBodyToJSON,
    ResendInvitationRequestBody,
    ResendInvitationRequestBodyFromJSON,
    ResendInvitationRequestBodyToJSON,
    RuleResponse,
    RuleResponseFromJSON,
    RuleResponseToJSON,
    RuleStatsResponse,
    RuleStatsResponseFromJSON,
    RuleStatsResponseToJSON,
    RuleSuggestionResponse,
    RuleSuggestionResponseFromJSON,
    RuleSuggestionResponseToJSON,
    RuleWithSubCategoryResponse,
    RuleWithSubCategoryResponseFromJSON,
    RuleWithSubCategoryResponseToJSON,
    SearchInterviewDocumentsResponse,
    SearchInterviewDocumentsResponseFromJSON,
    SearchInterviewDocumentsResponseToJSON,
    SegmentKey,
    SegmentKeyFromJSON,
    SegmentKeyToJSON,
    SendHrInterviewFeedbackRequestBody,
    SendHrInterviewFeedbackRequestBodyFromJSON,
    SendHrInterviewFeedbackRequestBodyToJSON,
    SendSampleSurveySheetRequestBody,
    SendSampleSurveySheetRequestBodyFromJSON,
    SendSampleSurveySheetRequestBodyToJSON,
    SetLabelRequestBody,
    SetLabelRequestBodyFromJSON,
    SetLabelRequestBodyToJSON,
    SettingResponse,
    SettingResponseFromJSON,
    SettingResponseToJSON,
    SharedSurveySheetResponse,
    SharedSurveySheetResponseFromJSON,
    SharedSurveySheetResponseToJSON,
    StartLabelingSessionResponseBody,
    StartLabelingSessionResponseBodyFromJSON,
    StartLabelingSessionResponseBodyToJSON,
    SubCategoryResponse,
    SubCategoryResponseFromJSON,
    SubCategoryResponseToJSON,
    SubmitInterviewFeedbackRequest,
    SubmitInterviewFeedbackRequestFromJSON,
    SubmitInterviewFeedbackRequestToJSON,
    SubmitSurveyAnswerRequestBody,
    SubmitSurveyAnswerRequestBodyFromJSON,
    SubmitSurveyAnswerRequestBodyToJSON,
    SubmitSurveyAnswerWithCustomerCreatedIdRequestBody,
    SubmitSurveyAnswerWithCustomerCreatedIdRequestBodyFromJSON,
    SubmitSurveyAnswerWithCustomerCreatedIdRequestBodyToJSON,
    SurveyAnswerResponse,
    SurveyAnswerResponseFromJSON,
    SurveyAnswerResponseToJSON,
    SurveyListResponse,
    SurveyListResponseFromJSON,
    SurveyListResponseToJSON,
    SurveyQuestionMoldResponse,
    SurveyQuestionMoldResponseFromJSON,
    SurveyQuestionMoldResponseToJSON,
    SurveyReportResponse,
    SurveyReportResponseFromJSON,
    SurveyReportResponseToJSON,
    SurveyResponse,
    SurveyResponseFromJSON,
    SurveyResponseToJSON,
    SurveySheetResponse,
    SurveySheetResponseFromJSON,
    SurveySheetResponseToJSON,
    SyncCognitoUsersRequestBody,
    SyncCognitoUsersRequestBodyFromJSON,
    SyncCognitoUsersRequestBodyToJSON,
    SyncCognitoUsersResponse,
    SyncCognitoUsersResponseFromJSON,
    SyncCognitoUsersResponseToJSON,
    TranscriptionResponse,
    TranscriptionResponseFromJSON,
    TranscriptionResponseToJSON,
    UpdateCategoryRequestBody,
    UpdateCategoryRequestBodyFromJSON,
    UpdateCategoryRequestBodyToJSON,
    UpdateEmailSettingsBody,
    UpdateEmailSettingsBodyFromJSON,
    UpdateEmailSettingsBodyToJSON,
    UpdateEmployeeTenureRequestBody,
    UpdateEmployeeTenureRequestBodyFromJSON,
    UpdateEmployeeTenureRequestBodyToJSON,
    UpdateFileOfHrInterviewRequestBody,
    UpdateFileOfHrInterviewRequestBodyFromJSON,
    UpdateFileOfHrInterviewRequestBodyToJSON,
    UpdateGraphScaleSettingsBody,
    UpdateGraphScaleSettingsBodyFromJSON,
    UpdateGraphScaleSettingsBodyToJSON,
    UpdateHrInterviewFileRequestBody,
    UpdateHrInterviewFileRequestBodyFromJSON,
    UpdateHrInterviewFileRequestBodyToJSON,
    UpdateHrInterviewRequestBody,
    UpdateHrInterviewRequestBodyFromJSON,
    UpdateHrInterviewRequestBodyToJSON,
    UpdateInterviewFeedbackSettingsRequest,
    UpdateInterviewFeedbackSettingsRequestFromJSON,
    UpdateInterviewFeedbackSettingsRequestToJSON,
    UpdateInterviewFileRequestBody,
    UpdateInterviewFileRequestBodyFromJSON,
    UpdateInterviewFileRequestBodyToJSON,
    UpdateInterviewFormatRequestBody,
    UpdateInterviewFormatRequestBodyFromJSON,
    UpdateInterviewFormatRequestBodyToJSON,
    UpdateNoteRequestBody,
    UpdateNoteRequestBodyFromJSON,
    UpdateNoteRequestBodyToJSON,
    UpdateSubCategoryRequestBody,
    UpdateSubCategoryRequestBodyFromJSON,
    UpdateSubCategoryRequestBodyToJSON,
    UpdateSurveyRequestBody,
    UpdateSurveyRequestBodyFromJSON,
    UpdateSurveyRequestBodyToJSON,
    UpdateSurveyStatusRequestBody,
    UpdateSurveyStatusRequestBodyFromJSON,
    UpdateSurveyStatusRequestBodyToJSON,
    UpdateTranscriptionRequestBody,
    UpdateTranscriptionRequestBodyFromJSON,
    UpdateTranscriptionRequestBodyToJSON,
    UpdateUserRequestBody,
    UpdateUserRequestBodyFromJSON,
    UpdateUserRequestBodyToJSON,
    UserDetailResponse,
    UserDetailResponseFromJSON,
    UserDetailResponseToJSON,
    UserGroupMembersRequest,
    UserGroupMembersRequestFromJSON,
    UserGroupMembersRequestToJSON,
    UserGroupResponse,
    UserGroupResponseFromJSON,
    UserGroupResponseToJSON,
    UserGroupsResponse,
    UserGroupsResponseFromJSON,
    UserGroupsResponseToJSON,
    UserListResponse,
    UserListResponseFromJSON,
    UserListResponseToJSON,
    UserRefResponse,
    UserRefResponseFromJSON,
    UserRefResponseToJSON,
    UserResponse,
    UserResponseFromJSON,
    UserResponseToJSON,
} from '../models';

export interface AddEmployeeAttributeDefinitionRequest {
    addEmployeeDefinitionRequestBody: AddEmployeeDefinitionRequestBody;
}

export interface AddEmployeeChangeSetRequest {
    file: Blob;
    effectiveFrom: string;
}

export interface AddSubcategoriesToDocumentRequest {
    documentId: string;
    addSubCategoriesRequestBody: AddSubCategoriesRequestBody;
}

export interface AnalyzeExEmployeeInterviewRequest {
    exEmployeeInterviewId: string;
}

export interface AnalyzeHrInterviewRequest {
    hrInterviewId: string;
}

export interface ApplyEmployeeChangeSetRequest {
    changeSetId: string;
}

export interface CarryOverEmployeesIntoNextPeriodRequest {
    carryOverEmployeesToNextPeriodRequestBody?: CarryOverEmployeesToNextPeriodRequestBody;
}

export interface CompleteHrInterviewFileUploadRequest {
    uploadId: string;
    completeHrInterviewFileUploadBody: CompleteHrInterviewFileUploadBody;
}

export interface CompleteInterviewFileMultipartUploadRequest {
    uploadId: string;
    completeInterviewAudioFileUploadRequestBody: CompleteInterviewAudioFileUploadRequestBody;
}

export interface CreateCategoryRequest {
    createCategoryRequestBody: CreateCategoryRequestBody;
}

export interface CreateExEmployeeInterviewRequest {
    periodId: number;
    employeeId: string;
    createExEmployeeInterviewRequestBody: CreateExEmployeeInterviewRequestBody;
}

export interface CreateExEmployeeInterviewByEmployeeTenureIdRequest {
    employeeTenureId: string;
    createExEmployeeInterviewRequestBody: CreateExEmployeeInterviewRequestBody;
}

export interface CreateHrInterviewRequest {
    createHrInterviewRequestBody: CreateHrInterviewRequestBody;
}

export interface CreateHrInterviewFeedbackAnswersRequest {
    requestBody: Array<object>;
}

export interface CreateHrInterviewFileRequest {
    createHrInterviewFileRequestBody: CreateHrInterviewFileRequestBody;
}

export interface CreateInterviewAudioFileUploadUrlsRequest {
    createInterviewAudioFileUploadUrlsRequestBody: CreateInterviewAudioFileUploadUrlsRequestBody;
}

export interface CreateInterviewFilesRequest {
    createInterviewFilesRequestBody: Array<CreateInterviewFilesRequestBody>;
}

export interface CreateInterviewFormatRequest {
    interviewTypeId: string;
    createInterviewFormatRequestBody: CreateInterviewFormatRequestBody;
}

export interface CreateInterviewTypeRequest {
    createInterviewTypeRequestBody: CreateInterviewTypeRequestBody;
}

export interface CreateNoteRequest {
    createNoteRequestBody: CreateNoteRequestBody;
}

export interface CreatePeriodRequest {
    createPeriodRequestBody: CreatePeriodRequestBody;
}

export interface CreateRuleRequest {
    categoryId: string;
    createRuleRequestBodyString: CreateRuleRequestBodyString;
}

export interface CreateSegmentDefinitionRequest {
    segmentKey: InputSegmentKey;
    createSegmentDefinitionRequestBody: CreateSegmentDefinitionRequestBody;
}

export interface CreateSubCategoryRequest {
    categoryId: string;
    createSubCategoryRequestBody: CreateSubCategoryRequestBody;
}

export interface CreateSurveyRequest {
    createSurveyRequestBody: CreateSurveyRequestBody;
}

export interface CreateUserRequest {
    createUserRequestBody: CreateUserRequestBody;
}

export interface CreateUserGroupOperationRequest {
    createUserGroupRequest: CreateUserGroupRequest;
}

export interface CustomerCreatedIdExistsRequest {
    customerCreatedId: string;
}

export interface DeleteCategoryRequest {
    categoryId: string;
}

export interface DeleteEmployeeAttributesRequest {
    employeeTenureId: string;
    at: Date;
}

export interface DeleteExEmployeeInterviewRequest {
    exEmployeeInterviewId: string;
}

export interface DeleteHrInterviewRequest {
    hrInterviewId: string;
}

export interface DeleteHrInterviewFeedbackQuestionRequest {
    hrInterviewFeedbackQuestionId: string;
}

export interface DeleteHrInterviewFileRequest {
    hrInterviewFileId: string;
}

export interface DeleteInterviewFileRequest {
    interviewRecordingId: string;
}

export interface DeleteNoteRequest {
    noteId: string;
}

export interface DeleteRuleRequest {
    ruleId: string;
}

export interface DeleteSubCategoryRequest {
    subCategoryId: string;
}

export interface DeleteSurveyRequest {
    surveyId: string;
}

export interface DeleteTenureRequest {
    employeeTenureId: string;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface DeleteUserGroupRequest {
    userGroupId: string;
}

export interface DownloadEmployeeCSVRequest {
    at: string;
}

export interface EditExEmployeeInterviewRequest {
    exEmployeeInterviewId: string;
    editExEmployeeInterviewRequestBody: EditExEmployeeInterviewRequestBody;
}

export interface FindHrInterviewFilesRequest {
    count: number;
    offset: number;
    linkWithInterview?: Array<boolean>;
    uploadUserIds?: Array<string>;
    createdAtStart?: string;
    createdAtEnd?: string;
}

export interface GetCalculatedSegmentConditionSettingRequest {
    segmentKey: CalculatedSegmentKey;
}

export interface GetCategoryRequest {
    categoryId: string;
}

export interface GetComputationTaskRequest {
    computationTaskId: string;
}

export interface GetDocumentsByExEmployeeInterviewIdRequest {
    exEmployeeInterviewId: string;
}

export interface GetEmployeeRequest {
    employeeId: string;
}

export interface GetEmployeeAttributesRequest {
    employeeTenureId: string;
    at: Date;
}

export interface GetEmployeeChangeSetSummaryRequest {
    changeSetId: string;
    count: number;
    offset: number;
}

export interface GetEmployeeListRequest {
    at: string;
    count: number;
    offset: number;
    customerCreatedId?: string;
}

export interface GetEmployeeReportV3Request {
    periodId: number;
    axis: AxisLiteralOfTypeofEMPLOYEEREPORTAXISFACTORY;
    from?: Date;
    to?: Date;
    sexes?: Array<string>;
    departments?: Array<string>;
    free1s?: Array<string>;
    free2s?: Array<string>;
    monthsOfServices?: Array<string>;
    ages?: Array<string>;
}

export interface GetEmployeeTenureRequest {
    employeeTenureId: string;
}

export interface GetExEmployeeInterviewRequest {
    exEmployeeInterviewId: string;
}

export interface GetExEmployeeInterviewsRequest {
    count: number;
    offset: number;
}

export interface GetExEmployeeReviewsRequest {
    count: number;
    offset: number;
    categoryId?: string;
    subCategoryIds?: string;
    segmentKey?: SegmentKey;
    segmentValue?: string;
    endCreatedAt?: Date;
}

export interface GetHrInterviewRequest {
    hrInterviewId: string;
}

export interface GetHrInterviewFileRequest {
    hrInterviewFileId: string;
}

export interface GetHrInterviewFileUploaderUrlRequest {
    getHrInterviewFileUploaderUrlBody: GetHrInterviewFileUploaderUrlBody;
}

export interface GetHrInterviewQualityReportRequest {
    axis: HrInterviewQualityReportAxis;
    startDate?: Date;
    endDate?: Date;
    followUpIsRequired?: Array<boolean>;
    interviewTypeIds?: Array<string>;
    interviewerIds?: Array<string>;
    evaluationItems?: Array<string>;
}

export interface GetHrInterviewReportRequest {
    axis: AxisLiteralOfTypeofHRINTERVIEWREPORTGRAPHAXISFACTORY;
    interviewTypeIds?: Array<string>;
    followUpIsRequired?: Array<boolean>;
    interviewDateFrom?: Date;
    interviewDateTo?: Date;
    subCategoryIds?: Array<string>;
    segmentFilters?: string;
}

export interface GetHrInterviewsRequest {
    count: number;
    offset: number;
    interviewTypeIds?: Array<string>;
    interviewStatuses?: Array<HrInterviewAnalysisStatusRequest>;
    transcriptionStatuses?: Array<NullHrInterviewTranscriptionStatusRequest>;
    interviewerIds?: Array<string>;
    followUpIsRequired?: Array<boolean>;
    segmentFilters?: string;
    interviewDateStart?: Date;
    interviewDateEnd?: Date;
    feedbackStatuses?: Array<HrInterviewFeedbackStatus>;
}

export interface GetInterviewAudioFileUrlRequest {
    interviewFileId: string;
}

export interface GetInterviewFeedbacksRequest {
    userId: string;
    count: number;
    offset: number;
}

export interface GetInterviewFileRequest {
    interviewRecordingId: string;
}

export interface GetInterviewFileDownloadUrlRequest {
    hrInterviewFileId: string;
}

export interface GetInterviewFilesRequest {
    count: number;
    offset: number;
}

export interface GetInterviewFormatByTypeIdRequest {
    interviewTypeId: string;
}

export interface GetInterviewFormatContentsRequest {
    interviewFormatId: string;
}

export interface GetInterviewKpiReportRequest {
    periodId: number;
    sexes?: Array<string>;
    departments?: Array<string>;
    free1s?: Array<string>;
    free2s?: Array<string>;
    monthsOfServices?: Array<string>;
    ages?: Array<string>;
}

export interface GetInterviewQualityReportRequest {
    userId: string;
}

export interface GetInterviewReportRequest {
    axis: AxisLiteralOfTypeofINTERVIEWREPORTGRAPHAXISFACTORY;
    from?: Date;
    to?: Date;
    sexes?: Array<string>;
    departments?: Array<string>;
    free1s?: Array<string>;
    free2s?: Array<string>;
    monthsOfServices?: Array<string>;
    ages?: Array<string>;
    subCategoryIds?: Array<string>;
}

export interface GetInterviewTypeRequest {
    interviewTypeId: string;
}

export interface GetInterviewTypesRequest {
    includeDeleted?: boolean;
}

export interface GetLabelDocumentRequest {
    sessionId: string;
    count: number;
    offset: number;
}

export interface GetLabelStatsBySubCategoryIdRequest {
    subCategoryId: string;
}

export interface GetLabelingResultRequest {
    subCategoryId: string;
    count: number;
    offset: number;
}

export interface GetLatestComputationTaskByDocumentIdRequest {
    documentId: string;
}

export interface GetLatestComputationTaskByExEmployeeInterviewIdRequest {
    exEmployeeInterviewId: string;
}

export interface GetLatestComputationTaskByRuleIdRequest {
    ruleId: string;
}

export interface GetLatestComputationTaskBySurveyIdRequest {
    surveyId: string;
}

export interface GetNoteRequest {
    noteId: string;
}

export interface GetNotesRequest {
    segmentKey?: SegmentKey;
}

export interface GetRuleSuggestionsByCategoryIdRequest {
    categoryId: string;
    algorithm: any;
    query?: string;
    size?: number;
    significantTerms?: boolean;
    filterNonEffectiveRules?: boolean;
}

export interface GetRuleSuggestionsBySubCategoryIdRequest {
    subCategoryId: string;
    algorithm: any;
    query?: string;
    size?: number;
    significantTerms?: boolean;
    filterNonEffectiveRules?: boolean;
}

export interface GetRulesByCategoryIdRequest {
    categoryId: string;
}

export interface GetSegmentDefinitionsRequest {
    segmentKey: InputSegmentKey;
}

export interface GetSharedSurveySheetRequest {
    surveyId: string;
}

export interface GetStatsByRuleRequest {
    ruleId: string;
}

export interface GetSubCategoryByCategoryIdRequest {
    categoryId: string;
}

export interface GetSubCategoryBySubCategoryIdRequest {
    subCategoryId: string;
}

export interface GetSurveyRequest {
    surveyId: string;
}

export interface GetSurveyAnswersRequest {
    surveyId: string;
    count?: number;
    offset?: number;
}

export interface GetSurveyReportRequest {
    surveyId: string;
    axis: AxisLiteralOfTypeofSURVEYREPORTAXISFACTORY;
    sexes?: Array<string>;
    departments?: Array<string>;
    free1s?: Array<string>;
    free2s?: Array<string>;
    monthsOfServices?: Array<string>;
    ages?: Array<string>;
    subCategoryIds?: Array<string>;
}

export interface GetSurveySheetRequest {
    surveySheetId: string;
}

export interface GetSurveysRequest {
    count?: number;
    offset?: number;
}

export interface GetTranscriptionRequest {
    interviewRecordingId: string;
}

export interface GetUserRequest {
    userId: string;
}

export interface GetUserGroupRequest {
    userGroupId: string;
    count?: number;
    offset?: number;
}

export interface GetUserGroupsRequest {
    count: number;
    offset: number;
}

export interface GetUserGroupsOfUserRequest {
    userId: string;
}

export interface RebuildAnalysisFromScratchRequest {
    password?: string;
}

export interface RegisterCalculatedSegmentConditionRequest {
    segmentKey: CalculatedSegmentKey;
    registerCalculatedSegmentSettingRequestBody: RegisterCalculatedSegmentSettingRequestBody;
}

export interface RegisterEmployeesRequest {
    file: Blob;
    effectiveFrom: string;
}

export interface RegisterInterviewFormatContentsRequest {
    interviewFormatId: string;
    registerInterviewFormatContentsRequestBody: RegisterInterviewFormatContentsRequestBody;
}

export interface RegisterInterviewFormatsRequest {
    interviewTypeId: string;
    registerInterviewFormatsRequestBody: RegisterInterviewFormatsRequestBody;
}

export interface RegisterInterviewTypeRequest {
    registerInterviewTypesRequestBody: RegisterInterviewTypesRequestBody;
}

export interface RegisterSegmentNameRequest {
    segmentKey: InputSegmentKey;
    registerSegmentNameRequestBody: RegisterSegmentNameRequestBody;
}

export interface RegisterSurveyQuestionsRequest {
    registerSurveyQuestionRequestBody: RegisterSurveyQuestionRequestBody;
}

export interface ResendInvitationRequest {
    resendInvitationRequestBody: ResendInvitationRequestBody;
}

export interface RestoreInterviewAttributeRequest {
    periodId: number;
    target: RestoreInterviewAttributeTargetEnum;
}

export interface SearchForInterviewDocumentsRequest {
    count: number;
    offset: number;
    subCategoryId?: Array<string>;
    sex?: Array<string>;
    department?: Array<string>;
    free1?: Array<string>;
    free2?: Array<string>;
    age?: Array<string>;
    monthsOfService?: Array<string>;
    retireDateBegin?: Date;
    retireDateEnd?: Date;
}

export interface SearchInterviewDocumentsByRawRuleRequest {
    esQuery: string;
    count: number;
    offset: number;
}

export interface SendHrInterviewFeedbackRequest {
    hrInterviewId: string;
    sendHrInterviewFeedbackRequestBody: SendHrInterviewFeedbackRequestBody;
}

export interface SendRemindSurveySheetRequest {
    surveyId: string;
}

export interface SendSampleSurveySheetRequest {
    surveyId: string;
    sendSampleSurveySheetRequestBody: SendSampleSurveySheetRequestBody;
}

export interface SetLabelRequest {
    labelId: number;
    setLabelRequestBody: SetLabelRequestBody;
}

export interface SetUsersToUserGroupRequest {
    userGroupId: string;
    userGroupMembersRequest: UserGroupMembersRequest;
}

export interface StartLabelingSessionRequest {
    body: GenericStartLabelingSessionRequestBodyString;
}

export interface StartSurveyRequest {
    surveyId: string;
}

export interface StartTranscriptionRequest {
    interviewRecordingId: string;
}

export interface SubmitInterviewFeedbackOperationRequest {
    interviewFileId: string;
    submitInterviewFeedbackRequest: SubmitInterviewFeedbackRequest;
}

export interface SubmitSurveyAnswerRequest {
    surveySheetId: string;
    submitSurveyAnswerRequestBody: SubmitSurveyAnswerRequestBody;
}

export interface SubmitSurveyAnswerWithIdRequest {
    surveyId: string;
    submitSurveyAnswerWithCustomerCreatedIdRequestBody: SubmitSurveyAnswerWithCustomerCreatedIdRequestBody;
}

export interface SubmitTranscriptionRequest {
    interviewRecordingId: string;
}

export interface SummarizeExEmployeeInterviewRequest {
    exEmployeeInterviewId: string;
}

export interface SummarizeHrInterviewRequest {
    hrInterviewId: string;
    fromTranscription?: boolean;
}

export interface SyncCognitoUsersRequest {
    syncCognitoUsersRequestBody: SyncCognitoUsersRequestBody;
}

export interface UnfixExEmployeeInterviewRequest {
    exEmployeeInterviewId: string;
}

export interface UpdateCategoryRequest {
    categoryId: string;
    updateCategoryRequestBody: UpdateCategoryRequestBody;
}

export interface UpdateEmailSettingsRequest {
    updateEmailSettingsBody: UpdateEmailSettingsBody;
}

export interface UpdateEmployeeTenureRequest {
    employeeTenureId: string;
    updateEmployeeTenureRequestBody: UpdateEmployeeTenureRequestBody;
}

export interface UpdateFileOfHrInterviewRequest {
    hrInterviewId: string;
    updateFileOfHrInterviewRequestBody: UpdateFileOfHrInterviewRequestBody;
}

export interface UpdateGraphScaleSettingsRequest {
    updateGraphScaleSettingsBody: UpdateGraphScaleSettingsBody;
}

export interface UpdateHrInterviewRequest {
    hrInterviewId: string;
    updateHrInterviewRequestBody: UpdateHrInterviewRequestBody;
}

export interface UpdateHrInterviewFeedbackQuestionsRequest {
    requestBody: Array<object>;
}

export interface UpdateHrInterviewFileRequest {
    hrInterviewFileId: string;
    updateHrInterviewFileRequestBody: UpdateHrInterviewFileRequestBody;
}

export interface UpdateInterviewFeedbackSettingsOperationRequest {
    updateInterviewFeedbackSettingsRequest: UpdateInterviewFeedbackSettingsRequest;
}

export interface UpdateInterviewFileRequest {
    interviewRecordingId: string;
    updateInterviewFileRequestBody: UpdateInterviewFileRequestBody;
}

export interface UpdateInterviewFormatRequest {
    interviewFormatId: string;
    updateInterviewFormatRequestBody: UpdateInterviewFormatRequestBody;
}

export interface UpdateNoteRequest {
    noteId: string;
    updateNoteRequestBody: UpdateNoteRequestBody;
}

export interface UpdateRuleRequest {
    ruleId: string;
    body: GenericUpdateRuleRequestBodyString;
}

export interface UpdateSegmentDefinitionRequest {
    segmentKey: InputSegmentKey;
    inputSegmentDefinitionId: string;
    editSegmentDefinitionRequestBody: EditSegmentDefinitionRequestBody;
}

export interface UpdateSubCategoryRequest {
    subCategoryId: string;
    updateSubCategoryRequestBody: UpdateSubCategoryRequestBody;
}

export interface UpdateSurveyRequest {
    surveyId: string;
    updateSurveyRequestBody: UpdateSurveyRequestBody;
}

export interface UpdateSurveyStatusRequest {
    surveyId: string;
    updateSurveyStatusRequestBody: UpdateSurveyStatusRequestBody;
}

export interface UpdateTranscriptionRequest {
    interviewRecordingId: string;
    updateTranscriptionRequestBody: UpdateTranscriptionRequestBody;
}

export interface UpdateUserRequest {
    userId: string;
    updateUserRequestBody: UpdateUserRequestBody;
}

export interface UpdateUserGroupRequest {
    userGroupId: string;
    body: PartialCreateUserGroupRequest;
}

export interface UpsertEmployeeAttributesRequest {
    employeeTenureId: string;
    addEmployeeAttributesBody: AddEmployeeAttributesBody;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addEmployeeAttributeDefinitionRaw(requestParameters: AddEmployeeAttributeDefinitionRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.addEmployeeDefinitionRequestBody === null || requestParameters.addEmployeeDefinitionRequestBody === undefined) {
            throw new runtime.RequiredError('addEmployeeDefinitionRequestBody','Required parameter requestParameters.addEmployeeDefinitionRequestBody was null or undefined when calling addEmployeeAttributeDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/employee-master/attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddEmployeeDefinitionRequestBodyToJSON(requestParameters.addEmployeeDefinitionRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async addEmployeeAttributeDefinition(requestParameters: AddEmployeeAttributeDefinitionRequest): Promise<string> {
        const response = await this.addEmployeeAttributeDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * 従業員changesetを作成する。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async addEmployeeChangeSetRaw(requestParameters: AddEmployeeChangeSetRequest): Promise<runtime.ApiResponse<CreateEmployeeChangeSetResponse>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addEmployeeChangeSet.');
        }

        if (requestParameters.effectiveFrom === null || requestParameters.effectiveFrom === undefined) {
            throw new runtime.RequiredError('effectiveFrom','Required parameter requestParameters.effectiveFrom was null or undefined when calling addEmployeeChangeSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.effectiveFrom !== undefined) {
            formParams.append('effectiveFrom', requestParameters.effectiveFrom as any);
        }

        const response = await this.request({
            path: `/api/v5/employee-master/employee-change-set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmployeeChangeSetResponseFromJSON(jsonValue));
    }

    /**
     * 従業員changesetを作成する。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async addEmployeeChangeSet(requestParameters: AddEmployeeChangeSetRequest): Promise<CreateEmployeeChangeSetResponse> {
        const response = await this.addEmployeeChangeSetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async addSubcategoriesToDocumentRaw(requestParameters: AddSubcategoriesToDocumentRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling addSubcategoriesToDocument.');
        }

        if (requestParameters.addSubCategoriesRequestBody === null || requestParameters.addSubCategoriesRequestBody === undefined) {
            throw new runtime.RequiredError('addSubCategoriesRequestBody','Required parameter requestParameters.addSubCategoriesRequestBody was null or undefined when calling addSubcategoriesToDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddSubCategoriesRequestBodyToJSON(requestParameters.addSubCategoriesRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async addSubcategoriesToDocument(requestParameters: AddSubcategoriesToDocumentRequest): Promise<string> {
        const response = await this.addSubcategoriesToDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビューを解析する。 従業員情報を見て退職していなければ仮確定、退職していれば確定になる。
     */
    async analyzeExEmployeeInterviewRaw(requestParameters: AnalyzeExEmployeeInterviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling analyzeExEmployeeInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}/analysis`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * インタビューを解析する。 従業員情報を見て退職していなければ仮確定、退職していれば確定になる。
     */
    async analyzeExEmployeeInterview(requestParameters: AnalyzeExEmployeeInterviewRequest): Promise<void> {
        await this.analyzeExEmployeeInterviewRaw(requestParameters);
    }

    /**
     */
    async analyzeHrInterviewRaw(requestParameters: AnalyzeHrInterviewRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.hrInterviewId === null || requestParameters.hrInterviewId === undefined) {
            throw new runtime.RequiredError('hrInterviewId','Required parameter requestParameters.hrInterviewId was null or undefined when calling analyzeHrInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews/{hrInterviewId}/analyze`.replace(`{${"hrInterviewId"}}`, encodeURIComponent(String(requestParameters.hrInterviewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async analyzeHrInterview(requestParameters: AnalyzeHrInterviewRequest): Promise<string> {
        const response = await this.analyzeHrInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * 従業員changesetを適用する。
     */
    async applyEmployeeChangeSetRaw(requestParameters: ApplyEmployeeChangeSetRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.changeSetId === null || requestParameters.changeSetId === undefined) {
            throw new runtime.RequiredError('changeSetId','Required parameter requestParameters.changeSetId was null or undefined when calling applyEmployeeChangeSet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employee-change-set/{changeSetId}`.replace(`{${"changeSetId"}}`, encodeURIComponent(String(requestParameters.changeSetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 従業員changesetを適用する。
     */
    async applyEmployeeChangeSet(requestParameters: ApplyEmployeeChangeSetRequest): Promise<string> {
        const response = await this.applyEmployeeChangeSetRaw(requestParameters);
        return await response.value();
    }

    /**
     * 会計期間を次の期間にキャリーオーバーする
     */
    async carryOverEmployeesIntoNextPeriodRaw(requestParameters: CarryOverEmployeesIntoNextPeriodRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/periods/latest-period/carry-over`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CarryOverEmployeesToNextPeriodRequestBodyToJSON(requestParameters.carryOverEmployeesToNextPeriodRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 会計期間を次の期間にキャリーオーバーする
     */
    async carryOverEmployeesIntoNextPeriod(requestParameters: CarryOverEmployeesIntoNextPeriodRequest): Promise<string> {
        const response = await this.carryOverEmployeesIntoNextPeriodRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async completeHrInterviewFileUploadRaw(requestParameters: CompleteHrInterviewFileUploadRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling completeHrInterviewFileUpload.');
        }

        if (requestParameters.completeHrInterviewFileUploadBody === null || requestParameters.completeHrInterviewFileUploadBody === undefined) {
            throw new runtime.RequiredError('completeHrInterviewFileUploadBody','Required parameter requestParameters.completeHrInterviewFileUploadBody was null or undefined when calling completeHrInterviewFileUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-file-uploader-urls/{uploadId}/complete`.replace(`{${"uploadId"}}`, encodeURIComponent(String(requestParameters.uploadId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteHrInterviewFileUploadBodyToJSON(requestParameters.completeHrInterviewFileUploadBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async completeHrInterviewFileUpload(requestParameters: CompleteHrInterviewFileUploadRequest): Promise<void> {
        await this.completeHrInterviewFileUploadRaw(requestParameters);
    }

    /**
     * 録音データのマルチパートアップロードを完了させる
     */
    async completeInterviewFileMultipartUploadRaw(requestParameters: CompleteInterviewFileMultipartUploadRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling completeInterviewFileMultipartUpload.');
        }

        if (requestParameters.completeInterviewAudioFileUploadRequestBody === null || requestParameters.completeInterviewAudioFileUploadRequestBody === undefined) {
            throw new runtime.RequiredError('completeInterviewAudioFileUploadRequestBody','Required parameter requestParameters.completeInterviewAudioFileUploadRequestBody was null or undefined when calling completeInterviewFileMultipartUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/presigned-urls/interview-audio-files/{uploadId}`.replace(`{${"uploadId"}}`, encodeURIComponent(String(requestParameters.uploadId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteInterviewAudioFileUploadRequestBodyToJSON(requestParameters.completeInterviewAudioFileUploadRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 録音データのマルチパートアップロードを完了させる
     */
    async completeInterviewFileMultipartUpload(requestParameters: CompleteInterviewFileMultipartUploadRequest): Promise<string> {
        const response = await this.completeInterviewFileMultipartUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * カテゴリを作成する。
     */
    async createCategoryRaw(requestParameters: CreateCategoryRequest): Promise<runtime.ApiResponse<CategoryResponse>> {
        if (requestParameters.createCategoryRequestBody === null || requestParameters.createCategoryRequestBody === undefined) {
            throw new runtime.RequiredError('createCategoryRequestBody','Required parameter requestParameters.createCategoryRequestBody was null or undefined when calling createCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCategoryRequestBodyToJSON(requestParameters.createCategoryRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryResponseFromJSON(jsonValue));
    }

    /**
     * カテゴリを作成する。
     */
    async createCategory(requestParameters: CreateCategoryRequest): Promise<CategoryResponse> {
        const response = await this.createCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * 音声データなしでインタビューを直作成する。
     */
    async createExEmployeeInterviewRaw(requestParameters: CreateExEmployeeInterviewRequest): Promise<runtime.ApiResponse<ExEmployeeInterviewResponse>> {
        if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
            throw new runtime.RequiredError('periodId','Required parameter requestParameters.periodId was null or undefined when calling createExEmployeeInterview.');
        }

        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling createExEmployeeInterview.');
        }

        if (requestParameters.createExEmployeeInterviewRequestBody === null || requestParameters.createExEmployeeInterviewRequestBody === undefined) {
            throw new runtime.RequiredError('createExEmployeeInterviewRequestBody','Required parameter requestParameters.createExEmployeeInterviewRequestBody was null or undefined when calling createExEmployeeInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/periods/{periodId}/employees/{employeeId}/interviews`.replace(`{${"periodId"}}`, encodeURIComponent(String(requestParameters.periodId))).replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExEmployeeInterviewRequestBodyToJSON(requestParameters.createExEmployeeInterviewRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExEmployeeInterviewResponseFromJSON(jsonValue));
    }

    /**
     * 音声データなしでインタビューを直作成する。
     */
    async createExEmployeeInterview(requestParameters: CreateExEmployeeInterviewRequest): Promise<ExEmployeeInterviewResponse> {
        const response = await this.createExEmployeeInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createExEmployeeInterviewByEmployeeTenureIdRaw(requestParameters: CreateExEmployeeInterviewByEmployeeTenureIdRequest): Promise<runtime.ApiResponse<ExEmployeeInterviewResponse>> {
        if (requestParameters.employeeTenureId === null || requestParameters.employeeTenureId === undefined) {
            throw new runtime.RequiredError('employeeTenureId','Required parameter requestParameters.employeeTenureId was null or undefined when calling createExEmployeeInterviewByEmployeeTenureId.');
        }

        if (requestParameters.createExEmployeeInterviewRequestBody === null || requestParameters.createExEmployeeInterviewRequestBody === undefined) {
            throw new runtime.RequiredError('createExEmployeeInterviewRequestBody','Required parameter requestParameters.createExEmployeeInterviewRequestBody was null or undefined when calling createExEmployeeInterviewByEmployeeTenureId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/employee-tenures/{employeeTenureId}/ex-employee-interviews`.replace(`{${"employeeTenureId"}}`, encodeURIComponent(String(requestParameters.employeeTenureId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExEmployeeInterviewRequestBodyToJSON(requestParameters.createExEmployeeInterviewRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExEmployeeInterviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async createExEmployeeInterviewByEmployeeTenureId(requestParameters: CreateExEmployeeInterviewByEmployeeTenureIdRequest): Promise<ExEmployeeInterviewResponse> {
        const response = await this.createExEmployeeInterviewByEmployeeTenureIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createHrInterviewRaw(requestParameters: CreateHrInterviewRequest): Promise<runtime.ApiResponse<HrInterviewResponse>> {
        if (requestParameters.createHrInterviewRequestBody === null || requestParameters.createHrInterviewRequestBody === undefined) {
            throw new runtime.RequiredError('createHrInterviewRequestBody','Required parameter requestParameters.createHrInterviewRequestBody was null or undefined when calling createHrInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHrInterviewRequestBodyToJSON(requestParameters.createHrInterviewRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async createHrInterview(requestParameters: CreateHrInterviewRequest): Promise<HrInterviewResponse> {
        const response = await this.createHrInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createHrInterviewFeedbackAnswersRaw(requestParameters: CreateHrInterviewFeedbackAnswersRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling createHrInterviewFeedbackAnswers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/feedbacks/answers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createHrInterviewFeedbackAnswers(requestParameters: CreateHrInterviewFeedbackAnswersRequest): Promise<void> {
        await this.createHrInterviewFeedbackAnswersRaw(requestParameters);
    }

    /**
     */
    async createHrInterviewFileRaw(requestParameters: CreateHrInterviewFileRequest): Promise<runtime.ApiResponse<Array<HrInterviewFileResponse>>> {
        if (requestParameters.createHrInterviewFileRequestBody === null || requestParameters.createHrInterviewFileRequestBody === undefined) {
            throw new runtime.RequiredError('createHrInterviewFileRequestBody','Required parameter requestParameters.createHrInterviewFileRequestBody was null or undefined when calling createHrInterviewFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHrInterviewFileRequestBodyToJSON(requestParameters.createHrInterviewFileRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HrInterviewFileResponseFromJSON));
    }

    /**
     */
    async createHrInterviewFile(requestParameters: CreateHrInterviewFileRequest): Promise<Array<HrInterviewFileResponse>> {
        const response = await this.createHrInterviewFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * 録音データをアップロードするためのpresigned url(mulripart)を取得する
     */
    async createInterviewAudioFileUploadUrlsRaw(requestParameters: CreateInterviewAudioFileUploadUrlsRequest): Promise<runtime.ApiResponse<CreateInterviewAudioFileUploadUrlsResponse>> {
        if (requestParameters.createInterviewAudioFileUploadUrlsRequestBody === null || requestParameters.createInterviewAudioFileUploadUrlsRequestBody === undefined) {
            throw new runtime.RequiredError('createInterviewAudioFileUploadUrlsRequestBody','Required parameter requestParameters.createInterviewAudioFileUploadUrlsRequestBody was null or undefined when calling createInterviewAudioFileUploadUrls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/presigned-urls/interview-audio-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInterviewAudioFileUploadUrlsRequestBodyToJSON(requestParameters.createInterviewAudioFileUploadUrlsRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateInterviewAudioFileUploadUrlsResponseFromJSON(jsonValue));
    }

    /**
     * 録音データをアップロードするためのpresigned url(mulripart)を取得する
     */
    async createInterviewAudioFileUploadUrls(requestParameters: CreateInterviewAudioFileUploadUrlsRequest): Promise<CreateInterviewAudioFileUploadUrlsResponse> {
        const response = await this.createInterviewAudioFileUploadUrlsRaw(requestParameters);
        return await response.value();
    }

    /**
     * 音声データの本登録 & AI 文字起こしの生成 & 従業員番号の紐づけがあれば、紐付ける
     */
    async createInterviewFilesRaw(requestParameters: CreateInterviewFilesRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.createInterviewFilesRequestBody === null || requestParameters.createInterviewFilesRequestBody === undefined) {
            throw new runtime.RequiredError('createInterviewFilesRequestBody','Required parameter requestParameters.createInterviewFilesRequestBody was null or undefined when calling createInterviewFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/interview-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createInterviewFilesRequestBody.map(CreateInterviewFilesRequestBodyToJSON),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 音声データの本登録 & AI 文字起こしの生成 & 従業員番号の紐づけがあれば、紐付ける
     */
    async createInterviewFiles(requestParameters: CreateInterviewFilesRequest): Promise<string> {
        const response = await this.createInterviewFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createInterviewFormatRaw(requestParameters: CreateInterviewFormatRequest): Promise<runtime.ApiResponse<InterviewFormatResponse>> {
        if (requestParameters.interviewTypeId === null || requestParameters.interviewTypeId === undefined) {
            throw new runtime.RequiredError('interviewTypeId','Required parameter requestParameters.interviewTypeId was null or undefined when calling createInterviewFormat.');
        }

        if (requestParameters.createInterviewFormatRequestBody === null || requestParameters.createInterviewFormatRequestBody === undefined) {
            throw new runtime.RequiredError('createInterviewFormatRequestBody','Required parameter requestParameters.createInterviewFormatRequestBody was null or undefined when calling createInterviewFormat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-types/{interviewTypeId}/formats`.replace(`{${"interviewTypeId"}}`, encodeURIComponent(String(requestParameters.interviewTypeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInterviewFormatRequestBodyToJSON(requestParameters.createInterviewFormatRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewFormatResponseFromJSON(jsonValue));
    }

    /**
     */
    async createInterviewFormat(requestParameters: CreateInterviewFormatRequest): Promise<InterviewFormatResponse> {
        const response = await this.createInterviewFormatRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createInterviewTypeRaw(requestParameters: CreateInterviewTypeRequest): Promise<runtime.ApiResponse<InterviewTypeResponse>> {
        if (requestParameters.createInterviewTypeRequestBody === null || requestParameters.createInterviewTypeRequestBody === undefined) {
            throw new runtime.RequiredError('createInterviewTypeRequestBody','Required parameter requestParameters.createInterviewTypeRequestBody was null or undefined when calling createInterviewType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInterviewTypeRequestBodyToJSON(requestParameters.createInterviewTypeRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewTypeResponseFromJSON(jsonValue));
    }

    /**
     */
    async createInterviewType(requestParameters: CreateInterviewTypeRequest): Promise<InterviewTypeResponse> {
        const response = await this.createInterviewTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createNoteRaw(requestParameters: CreateNoteRequest): Promise<runtime.ApiResponse<NoteResponse>> {
        if (requestParameters.createNoteRequestBody === null || requestParameters.createNoteRequestBody === undefined) {
            throw new runtime.RequiredError('createNoteRequestBody','Required parameter requestParameters.createNoteRequestBody was null or undefined when calling createNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateNoteRequestBodyToJSON(requestParameters.createNoteRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteResponseFromJSON(jsonValue));
    }

    /**
     */
    async createNote(requestParameters: CreateNoteRequest): Promise<NoteResponse> {
        const response = await this.createNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     * 新規期間を作成 endDateがなければちょうど一年をendDateに入れる （ex 2000/1/1がstartでendが空の時、2000/12/31） start、endがすべての期間と重複がないかチェックする 各periodの連続性はチェックしなくてよい。
     */
    async createPeriodRaw(requestParameters: CreatePeriodRequest): Promise<runtime.ApiResponse<PeriodResponse>> {
        if (requestParameters.createPeriodRequestBody === null || requestParameters.createPeriodRequestBody === undefined) {
            throw new runtime.RequiredError('createPeriodRequestBody','Required parameter requestParameters.createPeriodRequestBody was null or undefined when calling createPeriod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/periods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePeriodRequestBodyToJSON(requestParameters.createPeriodRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeriodResponseFromJSON(jsonValue));
    }

    /**
     * 新規期間を作成 endDateがなければちょうど一年をendDateに入れる （ex 2000/1/1がstartでendが空の時、2000/12/31） start、endがすべての期間と重複がないかチェックする 各periodの連続性はチェックしなくてよい。
     */
    async createPeriod(requestParameters: CreatePeriodRequest): Promise<PeriodResponse> {
        const response = await this.createPeriodRaw(requestParameters);
        return await response.value();
    }

    /**
     * ルールを作成する。
     */
    async createRuleRaw(requestParameters: CreateRuleRequest): Promise<runtime.ApiResponse<RuleWithSubCategoryResponse>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling createRule.');
        }

        if (requestParameters.createRuleRequestBodyString === null || requestParameters.createRuleRequestBodyString === undefined) {
            throw new runtime.RequiredError('createRuleRequestBodyString','Required parameter requestParameters.createRuleRequestBodyString was null or undefined when calling createRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}/rules`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRuleRequestBodyStringToJSON(requestParameters.createRuleRequestBodyString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleWithSubCategoryResponseFromJSON(jsonValue));
    }

    /**
     * ルールを作成する。
     */
    async createRule(requestParameters: CreateRuleRequest): Promise<RuleWithSubCategoryResponse> {
        const response = await this.createRuleRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー入力セグメント定義を登録する。 許せる入力値一覧とマッピング先
     */
    async createSegmentDefinitionRaw(requestParameters: CreateSegmentDefinitionRequest): Promise<runtime.ApiResponse<InputSegmentDefinitionResponse>> {
        if (requestParameters.segmentKey === null || requestParameters.segmentKey === undefined) {
            throw new runtime.RequiredError('segmentKey','Required parameter requestParameters.segmentKey was null or undefined when calling createSegmentDefinition.');
        }

        if (requestParameters.createSegmentDefinitionRequestBody === null || requestParameters.createSegmentDefinitionRequestBody === undefined) {
            throw new runtime.RequiredError('createSegmentDefinitionRequestBody','Required parameter requestParameters.createSegmentDefinitionRequestBody was null or undefined when calling createSegmentDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/settings/segments/{segmentKey}/definitions`.replace(`{${"segmentKey"}}`, encodeURIComponent(String(requestParameters.segmentKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSegmentDefinitionRequestBodyToJSON(requestParameters.createSegmentDefinitionRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InputSegmentDefinitionResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー入力セグメント定義を登録する。 許せる入力値一覧とマッピング先
     */
    async createSegmentDefinition(requestParameters: CreateSegmentDefinitionRequest): Promise<InputSegmentDefinitionResponse> {
        const response = await this.createSegmentDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * サブカテゴリを作成する。
     */
    async createSubCategoryRaw(requestParameters: CreateSubCategoryRequest): Promise<runtime.ApiResponse<SubCategoryResponse>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling createSubCategory.');
        }

        if (requestParameters.createSubCategoryRequestBody === null || requestParameters.createSubCategoryRequestBody === undefined) {
            throw new runtime.RequiredError('createSubCategoryRequestBody','Required parameter requestParameters.createSubCategoryRequestBody was null or undefined when calling createSubCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}/sub-categories`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSubCategoryRequestBodyToJSON(requestParameters.createSubCategoryRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubCategoryResponseFromJSON(jsonValue));
    }

    /**
     * サブカテゴリを作成する。
     */
    async createSubCategory(requestParameters: CreateSubCategoryRequest): Promise<SubCategoryResponse> {
        const response = await this.createSubCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートを作成する。
     */
    async createSurveyRaw(requestParameters: CreateSurveyRequest): Promise<runtime.ApiResponse<SurveyResponse>> {
        if (requestParameters.createSurveyRequestBody === null || requestParameters.createSurveyRequestBody === undefined) {
            throw new runtime.RequiredError('createSurveyRequestBody','Required parameter requestParameters.createSurveyRequestBody was null or undefined when calling createSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/survey/surveys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSurveyRequestBodyToJSON(requestParameters.createSurveyRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyResponseFromJSON(jsonValue));
    }

    /**
     * アンケートを作成する。
     */
    async createSurvey(requestParameters: CreateSurveyRequest): Promise<SurveyResponse> {
        const response = await this.createSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー作成
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<UserResponse>> {
        if (requestParameters.createUserRequestBody === null || requestParameters.createUserRequestBody === undefined) {
            throw new runtime.RequiredError('createUserRequestBody','Required parameter requestParameters.createUserRequestBody was null or undefined when calling createUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserRequestBodyToJSON(requestParameters.createUserRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー作成
     */
    async createUser(requestParameters: CreateUserRequest): Promise<UserResponse> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザグループを作成する。
     */
    async createUserGroupRaw(requestParameters: CreateUserGroupOperationRequest): Promise<runtime.ApiResponse<UserGroupResponse>> {
        if (requestParameters.createUserGroupRequest === null || requestParameters.createUserGroupRequest === undefined) {
            throw new runtime.RequiredError('createUserGroupRequest','Required parameter requestParameters.createUserGroupRequest was null or undefined when calling createUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/user-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserGroupRequestToJSON(requestParameters.createUserGroupRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResponseFromJSON(jsonValue));
    }

    /**
     * ユーザグループを作成する。
     */
    async createUserGroup(requestParameters: CreateUserGroupOperationRequest): Promise<UserGroupResponse> {
        const response = await this.createUserGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * customerCreatedIdの存在有無をチェックする
     */
    async customerCreatedIdExistsRaw(requestParameters: CustomerCreatedIdExistsRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.customerCreatedId === null || requestParameters.customerCreatedId === undefined) {
            throw new runtime.RequiredError('customerCreatedId','Required parameter requestParameters.customerCreatedId was null or undefined when calling customerCreatedIdExists.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employees/customer-created-id/exists/{customerCreatedId}`.replace(`{${"customerCreatedId"}}`, encodeURIComponent(String(requestParameters.customerCreatedId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * customerCreatedIdの存在有無をチェックする
     */
    async customerCreatedIdExists(requestParameters: CustomerCreatedIdExistsRequest): Promise<InlineResponse200> {
        const response = await this.customerCreatedIdExistsRaw(requestParameters);
        return await response.value();
    }

    /**
     * カテゴリを削除する
     */
    async deleteCategoryRaw(requestParameters: DeleteCategoryRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * カテゴリを削除する
     */
    async deleteCategory(requestParameters: DeleteCategoryRequest): Promise<boolean> {
        const response = await this.deleteCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteEmployeeAttributesRaw(requestParameters: DeleteEmployeeAttributesRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.employeeTenureId === null || requestParameters.employeeTenureId === undefined) {
            throw new runtime.RequiredError('employeeTenureId','Required parameter requestParameters.employeeTenureId was null or undefined when calling deleteEmployeeAttributes.');
        }

        if (requestParameters.at === null || requestParameters.at === undefined) {
            throw new runtime.RequiredError('at','Required parameter requestParameters.at was null or undefined when calling deleteEmployeeAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.at !== undefined) {
            queryParameters['at'] = (requestParameters.at as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employee-tenures/{employeeTenureId}/attributes`.replace(`{${"employeeTenureId"}}`, encodeURIComponent(String(requestParameters.employeeTenureId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteEmployeeAttributes(requestParameters: DeleteEmployeeAttributesRequest): Promise<string> {
        const response = await this.deleteEmployeeAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * 解析前のインタビューを削除する。 音声データと紐付いているインタビューの場合、音声データと従業員の紐付きも切るので、フロントでユーザーに確認させる UI にしてほしい。
     */
    async deleteExEmployeeInterviewRaw(requestParameters: DeleteExEmployeeInterviewRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling deleteExEmployeeInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 解析前のインタビューを削除する。 音声データと紐付いているインタビューの場合、音声データと従業員の紐付きも切るので、フロントでユーザーに確認させる UI にしてほしい。
     */
    async deleteExEmployeeInterview(requestParameters: DeleteExEmployeeInterviewRequest): Promise<string> {
        const response = await this.deleteExEmployeeInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteHrInterviewRaw(requestParameters: DeleteHrInterviewRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.hrInterviewId === null || requestParameters.hrInterviewId === undefined) {
            throw new runtime.RequiredError('hrInterviewId','Required parameter requestParameters.hrInterviewId was null or undefined when calling deleteHrInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews/{hrInterviewId}`.replace(`{${"hrInterviewId"}}`, encodeURIComponent(String(requestParameters.hrInterviewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteHrInterview(requestParameters: DeleteHrInterviewRequest): Promise<string> {
        const response = await this.deleteHrInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteHrInterviewFeedbackQuestionRaw(requestParameters: DeleteHrInterviewFeedbackQuestionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hrInterviewFeedbackQuestionId === null || requestParameters.hrInterviewFeedbackQuestionId === undefined) {
            throw new runtime.RequiredError('hrInterviewFeedbackQuestionId','Required parameter requestParameters.hrInterviewFeedbackQuestionId was null or undefined when calling deleteHrInterviewFeedbackQuestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/feedbacks/questions/{hrInterviewFeedbackQuestionId}`.replace(`{${"hrInterviewFeedbackQuestionId"}}`, encodeURIComponent(String(requestParameters.hrInterviewFeedbackQuestionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteHrInterviewFeedbackQuestion(requestParameters: DeleteHrInterviewFeedbackQuestionRequest): Promise<void> {
        await this.deleteHrInterviewFeedbackQuestionRaw(requestParameters);
    }

    /**
     */
    async deleteHrInterviewFileRaw(requestParameters: DeleteHrInterviewFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hrInterviewFileId === null || requestParameters.hrInterviewFileId === undefined) {
            throw new runtime.RequiredError('hrInterviewFileId','Required parameter requestParameters.hrInterviewFileId was null or undefined when calling deleteHrInterviewFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-files/{hrInterviewFileId}`.replace(`{${"hrInterviewFileId"}}`, encodeURIComponent(String(requestParameters.hrInterviewFileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteHrInterviewFile(requestParameters: DeleteHrInterviewFileRequest): Promise<void> {
        await this.deleteHrInterviewFileRaw(requestParameters);
    }

    /**
     * インタビュー音声データを削除する
     */
    async deleteInterviewFileRaw(requestParameters: DeleteInterviewFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.interviewRecordingId === null || requestParameters.interviewRecordingId === undefined) {
            throw new runtime.RequiredError('interviewRecordingId','Required parameter requestParameters.interviewRecordingId was null or undefined when calling deleteInterviewFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-files/{interviewRecordingId}`.replace(`{${"interviewRecordingId"}}`, encodeURIComponent(String(requestParameters.interviewRecordingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * インタビュー音声データを削除する
     */
    async deleteInterviewFile(requestParameters: DeleteInterviewFileRequest): Promise<void> {
        await this.deleteInterviewFileRaw(requestParameters);
    }

    /**
     */
    async deleteNoteRaw(requestParameters: DeleteNoteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling deleteNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notes/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNote(requestParameters: DeleteNoteRequest): Promise<void> {
        await this.deleteNoteRaw(requestParameters);
    }

    /**
     * ルールを削除する。
     */
    async deleteRuleRaw(requestParameters: DeleteRuleRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling deleteRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/rules/{ruleId}`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * ルールを削除する。
     */
    async deleteRule(requestParameters: DeleteRuleRequest): Promise<boolean> {
        const response = await this.deleteRuleRaw(requestParameters);
        return await response.value();
    }

    /**
     * サブカテゴリを削除する
     */
    async deleteSubCategoryRaw(requestParameters: DeleteSubCategoryRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.subCategoryId === null || requestParameters.subCategoryId === undefined) {
            throw new runtime.RequiredError('subCategoryId','Required parameter requestParameters.subCategoryId was null or undefined when calling deleteSubCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/sub-categories/{subCategoryId}`.replace(`{${"subCategoryId"}}`, encodeURIComponent(String(requestParameters.subCategoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * サブカテゴリを削除する
     */
    async deleteSubCategory(requestParameters: DeleteSubCategoryRequest): Promise<boolean> {
        const response = await this.deleteSubCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートを削除する。
     */
    async deleteSurveyRaw(requestParameters: DeleteSurveyRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * アンケートを削除する。
     */
    async deleteSurvey(requestParameters: DeleteSurveyRequest): Promise<string> {
        const response = await this.deleteSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteTenureRaw(requestParameters: DeleteTenureRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.employeeTenureId === null || requestParameters.employeeTenureId === undefined) {
            throw new runtime.RequiredError('employeeTenureId','Required parameter requestParameters.employeeTenureId was null or undefined when calling deleteTenure.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employee-tenures/{employeeTenureId}`.replace(`{${"employeeTenureId"}}`, encodeURIComponent(String(requestParameters.employeeTenureId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteTenure(requestParameters: DeleteTenureRequest): Promise<string> {
        const response = await this.deleteTenureRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー削除
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザー削除
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     * ユーザグループを削除する。
     */
    async deleteUserGroupRaw(requestParameters: DeleteUserGroupRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling deleteUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/user-groups/{userGroupId}`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * ユーザグループを削除する。
     */
    async deleteUserGroup(requestParameters: DeleteUserGroupRequest): Promise<string> {
        const response = await this.deleteUserGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async downloadEmployeeCSVRaw(requestParameters: DownloadEmployeeCSVRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.at === null || requestParameters.at === undefined) {
            throw new runtime.RequiredError('at','Required parameter requestParameters.at was null or undefined when calling downloadEmployeeCSV.');
        }

        const queryParameters: any = {};

        if (requestParameters.at !== undefined) {
            queryParameters['at'] = requestParameters.at;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/download-employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async downloadEmployeeCSV(requestParameters: DownloadEmployeeCSVRequest): Promise<string> {
        const response = await this.downloadEmployeeCSVRaw(requestParameters);
        return await response.value();
    }

    /**
     * 今あるルールをCSVとしてダンプする。
     */
    async dumpRulesRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/rules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 今あるルールをCSVとしてダンプする。
     */
    async dumpRules(): Promise<string> {
        const response = await this.dumpRulesRaw();
        return await response.value();
    }

    /**
     * 文字起こし内容を変更する。 仮確定・確定済みは変更不可。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async editExEmployeeInterviewRaw(requestParameters: EditExEmployeeInterviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling editExEmployeeInterview.');
        }

        if (requestParameters.editExEmployeeInterviewRequestBody === null || requestParameters.editExEmployeeInterviewRequestBody === undefined) {
            throw new runtime.RequiredError('editExEmployeeInterviewRequestBody','Required parameter requestParameters.editExEmployeeInterviewRequestBody was null or undefined when calling editExEmployeeInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditExEmployeeInterviewRequestBodyToJSON(requestParameters.editExEmployeeInterviewRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 文字起こし内容を変更する。 仮確定・確定済みは変更不可。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async editExEmployeeInterview(requestParameters: EditExEmployeeInterviewRequest): Promise<void> {
        await this.editExEmployeeInterviewRaw(requestParameters);
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async findHrInterviewFilesRaw(requestParameters: FindHrInterviewFilesRequest): Promise<runtime.ApiResponse<HrInterviewFileListResponse>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling findHrInterviewFiles.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling findHrInterviewFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.linkWithInterview) {
            queryParameters['linkWithInterview'] = requestParameters.linkWithInterview;
        }

        if (requestParameters.uploadUserIds) {
            queryParameters['uploadUserIds'] = requestParameters.uploadUserIds;
        }

        if (requestParameters.createdAtStart !== undefined) {
            queryParameters['createdAtStart'] = requestParameters.createdAtStart;
        }

        if (requestParameters.createdAtEnd !== undefined) {
            queryParameters['createdAtEnd'] = requestParameters.createdAtEnd;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewFileListResponseFromJSON(jsonValue));
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async findHrInterviewFiles(requestParameters: FindHrInterviewFilesRequest): Promise<HrInterviewFileListResponse> {
        const response = await this.findHrInterviewFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * プロジェクトに含まれるすべてのカテゴリを取得する。
     */
    async getAllCategoriesRaw(): Promise<runtime.ApiResponse<Array<CategoryResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryResponseFromJSON));
    }

    /**
     * プロジェクトに含まれるすべてのカテゴリを取得する。
     */
    async getAllCategories(): Promise<Array<CategoryResponse>> {
        const response = await this.getAllCategoriesRaw();
        return await response.value();
    }

    /**
     * プロジェクトに含まれるすべてのサブカテゴリを取得する。
     */
    async getAllSubCategoriesRaw(): Promise<runtime.ApiResponse<Array<SubCategoryResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/sub-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubCategoryResponseFromJSON));
    }

    /**
     * プロジェクトに含まれるすべてのサブカテゴリを取得する。
     */
    async getAllSubCategories(): Promise<Array<SubCategoryResponse>> {
        const response = await this.getAllSubCategoriesRaw();
        return await response.value();
    }

    /**
     * 自動計算セグメントの設定を取得する。
     */
    async getCalculatedSegmentConditionSettingRaw(requestParameters: GetCalculatedSegmentConditionSettingRequest): Promise<runtime.ApiResponse<CalculatedSegmentSettingResponse>> {
        if (requestParameters.segmentKey === null || requestParameters.segmentKey === undefined) {
            throw new runtime.RequiredError('segmentKey','Required parameter requestParameters.segmentKey was null or undefined when calling getCalculatedSegmentConditionSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/settings/segments/{segmentKey}/conditions`.replace(`{${"segmentKey"}}`, encodeURIComponent(String(requestParameters.segmentKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculatedSegmentSettingResponseFromJSON(jsonValue));
    }

    /**
     * 自動計算セグメントの設定を取得する。
     */
    async getCalculatedSegmentConditionSetting(requestParameters: GetCalculatedSegmentConditionSettingRequest): Promise<CalculatedSegmentSettingResponse> {
        const response = await this.getCalculatedSegmentConditionSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * カテゴリの情報を取得する。
     */
    async getCategoryRaw(requestParameters: GetCategoryRequest): Promise<runtime.ApiResponse<CategoryResponse>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryResponseFromJSON(jsonValue));
    }

    /**
     * カテゴリの情報を取得する。
     */
    async getCategory(requestParameters: GetCategoryRequest): Promise<CategoryResponse> {
        const response = await this.getCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Computation Taskのステータスを取得する。
     */
    async getComputationTaskRaw(requestParameters: GetComputationTaskRequest): Promise<runtime.ApiResponse<ComputationTaskResponse>> {
        if (requestParameters.computationTaskId === null || requestParameters.computationTaskId === undefined) {
            throw new runtime.RequiredError('computationTaskId','Required parameter requestParameters.computationTaskId was null or undefined when calling getComputationTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/computation-tasks/{computationTaskId}`.replace(`{${"computationTaskId"}}`, encodeURIComponent(String(requestParameters.computationTaskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ComputationTaskResponseFromJSON(jsonValue));
    }

    /**
     * Computation Taskのステータスを取得する。
     */
    async getComputationTask(requestParameters: GetComputationTaskRequest): Promise<ComputationTaskResponse> {
        const response = await this.getComputationTaskRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getConfigRaw(): Promise<runtime.ApiResponse<ConfigurationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigurationResponseFromJSON(jsonValue));
    }

    /**
     */
    async getConfig(): Promise<ConfigurationResponse> {
        const response = await this.getConfigRaw();
        return await response.value();
    }

    /**
     */
    async getDocumentsByExEmployeeInterviewIdRaw(requestParameters: GetDocumentsByExEmployeeInterviewIdRequest): Promise<runtime.ApiResponse<DocumentListResponse>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling getDocumentsByExEmployeeInterviewId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}/documents`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDocumentsByExEmployeeInterviewId(requestParameters: GetDocumentsByExEmployeeInterviewIdRequest): Promise<DocumentListResponse> {
        const response = await this.getDocumentsByExEmployeeInterviewIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getEmployeeRaw(requestParameters: GetEmployeeRequest): Promise<runtime.ApiResponse<EmployeeLifeLogResponse>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employees/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeLifeLogResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEmployee(requestParameters: GetEmployeeRequest): Promise<EmployeeLifeLogResponse> {
        const response = await this.getEmployeeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getEmployeeAttributesRaw(requestParameters: GetEmployeeAttributesRequest): Promise<runtime.ApiResponse<Array<EmployeeAttributeResponse>>> {
        if (requestParameters.employeeTenureId === null || requestParameters.employeeTenureId === undefined) {
            throw new runtime.RequiredError('employeeTenureId','Required parameter requestParameters.employeeTenureId was null or undefined when calling getEmployeeAttributes.');
        }

        if (requestParameters.at === null || requestParameters.at === undefined) {
            throw new runtime.RequiredError('at','Required parameter requestParameters.at was null or undefined when calling getEmployeeAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.at !== undefined) {
            queryParameters['at'] = (requestParameters.at as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employee-tenures/{employeeTenureId}/attributes`.replace(`{${"employeeTenureId"}}`, encodeURIComponent(String(requestParameters.employeeTenureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmployeeAttributeResponseFromJSON));
    }

    /**
     */
    async getEmployeeAttributes(requestParameters: GetEmployeeAttributesRequest): Promise<Array<EmployeeAttributeResponse>> {
        const response = await this.getEmployeeAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * 従業員changesetと既存listとのdiffを見る。
     */
    async getEmployeeChangeSetSummaryRaw(requestParameters: GetEmployeeChangeSetSummaryRequest): Promise<runtime.ApiResponse<EmployeeChangeSetSummaryResponse>> {
        if (requestParameters.changeSetId === null || requestParameters.changeSetId === undefined) {
            throw new runtime.RequiredError('changeSetId','Required parameter requestParameters.changeSetId was null or undefined when calling getEmployeeChangeSetSummary.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getEmployeeChangeSetSummary.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getEmployeeChangeSetSummary.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employee-change-set/{changeSetId}`.replace(`{${"changeSetId"}}`, encodeURIComponent(String(requestParameters.changeSetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeChangeSetSummaryResponseFromJSON(jsonValue));
    }

    /**
     * 従業員changesetと既存listとのdiffを見る。
     */
    async getEmployeeChangeSetSummary(requestParameters: GetEmployeeChangeSetSummaryRequest): Promise<EmployeeChangeSetSummaryResponse> {
        const response = await this.getEmployeeChangeSetSummaryRaw(requestParameters);
        return await response.value();
    }

    /**
     * 従業員リストを取得する
     */
    async getEmployeeListRaw(requestParameters: GetEmployeeListRequest): Promise<runtime.ApiResponse<EmployeeListResponse>> {
        if (requestParameters.at === null || requestParameters.at === undefined) {
            throw new runtime.RequiredError('at','Required parameter requestParameters.at was null or undefined when calling getEmployeeList.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getEmployeeList.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getEmployeeList.');
        }

        const queryParameters: any = {};

        if (requestParameters.at !== undefined) {
            queryParameters['at'] = requestParameters.at;
        }

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.customerCreatedId !== undefined) {
            queryParameters['customerCreatedId'] = requestParameters.customerCreatedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeListResponseFromJSON(jsonValue));
    }

    /**
     * 従業員リストを取得する
     */
    async getEmployeeList(requestParameters: GetEmployeeListRequest): Promise<EmployeeListResponse> {
        const response = await this.getEmployeeListRaw(requestParameters);
        return await response.value();
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getEmployeeReportV3Raw(requestParameters: GetEmployeeReportV3Request): Promise<runtime.ApiResponse<EmployeeReportResponse>> {
        if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
            throw new runtime.RequiredError('periodId','Required parameter requestParameters.periodId was null or undefined when calling getEmployeeReportV3.');
        }

        if (requestParameters.axis === null || requestParameters.axis === undefined) {
            throw new runtime.RequiredError('axis','Required parameter requestParameters.axis was null or undefined when calling getEmployeeReportV3.');
        }

        const queryParameters: any = {};

        if (requestParameters.axis !== undefined) {
            queryParameters['axis'] = requestParameters.axis;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.sexes) {
            queryParameters['sexes'] = requestParameters.sexes;
        }

        if (requestParameters.departments) {
            queryParameters['departments'] = requestParameters.departments;
        }

        if (requestParameters.free1s) {
            queryParameters['free1s'] = requestParameters.free1s;
        }

        if (requestParameters.free2s) {
            queryParameters['free2s'] = requestParameters.free2s;
        }

        if (requestParameters.monthsOfServices) {
            queryParameters['monthsOfServices'] = requestParameters.monthsOfServices;
        }

        if (requestParameters.ages) {
            queryParameters['ages'] = requestParameters.ages;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employee-reports/periods/{periodId}`.replace(`{${"periodId"}}`, encodeURIComponent(String(requestParameters.periodId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeReportResponseFromJSON(jsonValue));
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getEmployeeReportV3(requestParameters: GetEmployeeReportV3Request): Promise<EmployeeReportResponse> {
        const response = await this.getEmployeeReportV3Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getEmployeeTenureRaw(requestParameters: GetEmployeeTenureRequest): Promise<runtime.ApiResponse<EmployeeTenureResponse>> {
        if (requestParameters.employeeTenureId === null || requestParameters.employeeTenureId === undefined) {
            throw new runtime.RequiredError('employeeTenureId','Required parameter requestParameters.employeeTenureId was null or undefined when calling getEmployeeTenure.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/employee-master/employee-tenures/{employeeTenureId}`.replace(`{${"employeeTenureId"}}`, encodeURIComponent(String(requestParameters.employeeTenureId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeTenureResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEmployeeTenure(requestParameters: GetEmployeeTenureRequest): Promise<EmployeeTenureResponse> {
        const response = await this.getEmployeeTenureRaw(requestParameters);
        return await response.value();
    }

    /**
     * 退職者インタビューとその解析結果を取得する。
     */
    async getExEmployeeInterviewRaw(requestParameters: GetExEmployeeInterviewRequest): Promise<runtime.ApiResponse<InterviewDetailResponse>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling getExEmployeeInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewDetailResponseFromJSON(jsonValue));
    }

    /**
     * 退職者インタビューとその解析結果を取得する。
     */
    async getExEmployeeInterview(requestParameters: GetExEmployeeInterviewRequest): Promise<InterviewDetailResponse> {
        const response = await this.getExEmployeeInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * 退職者インタビュー一覧を取得する。
     */
    async getExEmployeeInterviewsRaw(requestParameters: GetExEmployeeInterviewsRequest): Promise<runtime.ApiResponse<ExEmployeeInterviewListResponse>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getExEmployeeInterviews.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getExEmployeeInterviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExEmployeeInterviewListResponseFromJSON(jsonValue));
    }

    /**
     * 退職者インタビュー一覧を取得する。
     */
    async getExEmployeeInterviews(requestParameters: GetExEmployeeInterviewsRequest): Promise<ExEmployeeInterviewListResponse> {
        const response = await this.getExEmployeeInterviewsRaw(requestParameters);
        return await response.value();
    }

    /**
     * 退職者の声を取得する。       WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、       パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getExEmployeeReviewsRaw(requestParameters: GetExEmployeeReviewsRequest): Promise<runtime.ApiResponse<ExEmployeeReviewsSummaryResponse>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getExEmployeeReviews.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getExEmployeeReviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.subCategoryIds !== undefined) {
            queryParameters['subCategoryIds'] = requestParameters.subCategoryIds;
        }

        if (requestParameters.segmentKey !== undefined) {
            queryParameters['segmentKey'] = requestParameters.segmentKey;
        }

        if (requestParameters.segmentValue !== undefined) {
            queryParameters['segmentValue'] = requestParameters.segmentValue;
        }

        if (requestParameters.endCreatedAt !== undefined) {
            queryParameters['endCreatedAt'] = (requestParameters.endCreatedAt as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/summaries/reviews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExEmployeeReviewsSummaryResponseFromJSON(jsonValue));
    }

    /**
     * 退職者の声を取得する。       WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、       パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getExEmployeeReviews(requestParameters: GetExEmployeeReviewsRequest): Promise<ExEmployeeReviewsSummaryResponse> {
        const response = await this.getExEmployeeReviewsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getHrInterviewRaw(requestParameters: GetHrInterviewRequest): Promise<runtime.ApiResponse<HrInterviewDetailResponse>> {
        if (requestParameters.hrInterviewId === null || requestParameters.hrInterviewId === undefined) {
            throw new runtime.RequiredError('hrInterviewId','Required parameter requestParameters.hrInterviewId was null or undefined when calling getHrInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews/{hrInterviewId}`.replace(`{${"hrInterviewId"}}`, encodeURIComponent(String(requestParameters.hrInterviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewDetailResponseFromJSON(jsonValue));
    }

    /**
     */
    async getHrInterview(requestParameters: GetHrInterviewRequest): Promise<HrInterviewDetailResponse> {
        const response = await this.getHrInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getHrInterviewFeedbackQuestionListRaw(): Promise<runtime.ApiResponse<HrInterviewFeedbackQuestionListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/feedbacks/questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewFeedbackQuestionListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getHrInterviewFeedbackQuestionList(): Promise<HrInterviewFeedbackQuestionListResponse> {
        const response = await this.getHrInterviewFeedbackQuestionListRaw();
        return await response.value();
    }

    /**
     */
    async getHrInterviewFileRaw(requestParameters: GetHrInterviewFileRequest): Promise<runtime.ApiResponse<HrInterviewFileResponse>> {
        if (requestParameters.hrInterviewFileId === null || requestParameters.hrInterviewFileId === undefined) {
            throw new runtime.RequiredError('hrInterviewFileId','Required parameter requestParameters.hrInterviewFileId was null or undefined when calling getHrInterviewFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-files/{hrInterviewFileId}`.replace(`{${"hrInterviewFileId"}}`, encodeURIComponent(String(requestParameters.hrInterviewFileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewFileResponseFromJSON(jsonValue));
    }

    /**
     */
    async getHrInterviewFile(requestParameters: GetHrInterviewFileRequest): Promise<HrInterviewFileResponse> {
        const response = await this.getHrInterviewFileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getHrInterviewFileUploaderUrlRaw(requestParameters: GetHrInterviewFileUploaderUrlRequest): Promise<runtime.ApiResponse<GetHrInterviewFileUploaderUrlResponse>> {
        if (requestParameters.getHrInterviewFileUploaderUrlBody === null || requestParameters.getHrInterviewFileUploaderUrlBody === undefined) {
            throw new runtime.RequiredError('getHrInterviewFileUploaderUrlBody','Required parameter requestParameters.getHrInterviewFileUploaderUrlBody was null or undefined when calling getHrInterviewFileUploaderUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-file-uploader-urls`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GetHrInterviewFileUploaderUrlBodyToJSON(requestParameters.getHrInterviewFileUploaderUrlBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHrInterviewFileUploaderUrlResponseFromJSON(jsonValue));
    }

    /**
     */
    async getHrInterviewFileUploaderUrl(requestParameters: GetHrInterviewFileUploaderUrlRequest): Promise<GetHrInterviewFileUploaderUrlResponse> {
        const response = await this.getHrInterviewFileUploaderUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async getHrInterviewQualityReportRaw(requestParameters: GetHrInterviewQualityReportRequest): Promise<runtime.ApiResponse<HrInterviewQualityReportResponse>> {
        if (requestParameters.axis === null || requestParameters.axis === undefined) {
            throw new runtime.RequiredError('axis','Required parameter requestParameters.axis was null or undefined when calling getHrInterviewQualityReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.axis !== undefined) {
            queryParameters['axis'] = requestParameters.axis;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.followUpIsRequired) {
            queryParameters['followUpIsRequired'] = requestParameters.followUpIsRequired;
        }

        if (requestParameters.interviewTypeIds) {
            queryParameters['interviewTypeIds'] = requestParameters.interviewTypeIds;
        }

        if (requestParameters.interviewerIds) {
            queryParameters['interviewerIds'] = requestParameters.interviewerIds;
        }

        if (requestParameters.evaluationItems) {
            queryParameters['evaluationItems'] = requestParameters.evaluationItems;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/quality-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewQualityReportResponseFromJSON(jsonValue));
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async getHrInterviewQualityReport(requestParameters: GetHrInterviewQualityReportRequest): Promise<HrInterviewQualityReportResponse> {
        const response = await this.getHrInterviewQualityReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async getHrInterviewReportRaw(requestParameters: GetHrInterviewReportRequest): Promise<runtime.ApiResponse<HrInterviewReportResponse>> {
        if (requestParameters.axis === null || requestParameters.axis === undefined) {
            throw new runtime.RequiredError('axis','Required parameter requestParameters.axis was null or undefined when calling getHrInterviewReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.axis !== undefined) {
            queryParameters['axis'] = requestParameters.axis;
        }

        if (requestParameters.interviewTypeIds) {
            queryParameters['interviewTypeIds'] = requestParameters.interviewTypeIds;
        }

        if (requestParameters.followUpIsRequired) {
            queryParameters['followUpIsRequired'] = requestParameters.followUpIsRequired;
        }

        if (requestParameters.interviewDateFrom !== undefined) {
            queryParameters['interviewDateFrom'] = (requestParameters.interviewDateFrom as any).toISOString();
        }

        if (requestParameters.interviewDateTo !== undefined) {
            queryParameters['interviewDateTo'] = (requestParameters.interviewDateTo as any).toISOString();
        }

        if (requestParameters.subCategoryIds) {
            queryParameters['subCategoryIds'] = requestParameters.subCategoryIds;
        }

        if (requestParameters.segmentFilters !== undefined) {
            queryParameters['segmentFilters'] = requestParameters.segmentFilters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewReportResponseFromJSON(jsonValue));
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async getHrInterviewReport(requestParameters: GetHrInterviewReportRequest): Promise<HrInterviewReportResponse> {
        const response = await this.getHrInterviewReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async getHrInterviewsRaw(requestParameters: GetHrInterviewsRequest): Promise<runtime.ApiResponse<HrInterviewListResponse>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getHrInterviews.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getHrInterviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.interviewTypeIds) {
            queryParameters['interviewTypeIds'] = requestParameters.interviewTypeIds;
        }

        if (requestParameters.interviewStatuses) {
            queryParameters['interviewStatuses'] = requestParameters.interviewStatuses;
        }

        if (requestParameters.transcriptionStatuses) {
            queryParameters['transcriptionStatuses'] = requestParameters.transcriptionStatuses;
        }

        if (requestParameters.interviewerIds) {
            queryParameters['interviewerIds'] = requestParameters.interviewerIds;
        }

        if (requestParameters.followUpIsRequired) {
            queryParameters['followUpIsRequired'] = requestParameters.followUpIsRequired;
        }

        if (requestParameters.segmentFilters !== undefined) {
            queryParameters['segmentFilters'] = requestParameters.segmentFilters;
        }

        if (requestParameters.interviewDateStart !== undefined) {
            queryParameters['interviewDateStart'] = (requestParameters.interviewDateStart as any).toISOString();
        }

        if (requestParameters.interviewDateEnd !== undefined) {
            queryParameters['interviewDateEnd'] = (requestParameters.interviewDateEnd as any).toISOString();
        }

        if (requestParameters.feedbackStatuses) {
            queryParameters['feedbackStatuses'] = requestParameters.feedbackStatuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewListResponseFromJSON(jsonValue));
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async getHrInterviews(requestParameters: GetHrInterviewsRequest): Promise<HrInterviewListResponse> {
        const response = await this.getHrInterviewsRaw(requestParameters);
        return await response.value();
    }

    /**
     * 録音データを取得するためのpresigned urlを取得
     */
    async getInterviewAudioFileUrlRaw(requestParameters: GetInterviewAudioFileUrlRequest): Promise<runtime.ApiResponse<GetInterviewAudioFileUrlResponse>> {
        if (requestParameters.interviewFileId === null || requestParameters.interviewFileId === undefined) {
            throw new runtime.RequiredError('interviewFileId','Required parameter requestParameters.interviewFileId was null or undefined when calling getInterviewAudioFileUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/presigned-urls/interview-audio-files/{interviewFileId}`.replace(`{${"interviewFileId"}}`, encodeURIComponent(String(requestParameters.interviewFileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewAudioFileUrlResponseFromJSON(jsonValue));
    }

    /**
     * 録音データを取得するためのpresigned urlを取得
     */
    async getInterviewAudioFileUrl(requestParameters: GetInterviewAudioFileUrlRequest): Promise<GetInterviewAudioFileUrlResponse> {
        const response = await this.getInterviewAudioFileUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * 現在のインタビューフィードバックの設問を取得する。
     */
    async getInterviewFeedbackSettingsRaw(): Promise<runtime.ApiResponse<InterviewFeedbackSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/interview-feedback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewFeedbackSettingsResponseFromJSON(jsonValue));
    }

    /**
     * 現在のインタビューフィードバックの設問を取得する。
     */
    async getInterviewFeedbackSettings(): Promise<InterviewFeedbackSettingsResponse> {
        const response = await this.getInterviewFeedbackSettingsRaw();
        return await response.value();
    }

    /**
     * ユーザのインタビューフィードバックを取得する。
     */
    async getInterviewFeedbacksRaw(requestParameters: GetInterviewFeedbacksRequest): Promise<runtime.ApiResponse<InterviewFeedbackListResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getInterviewFeedbacks.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getInterviewFeedbacks.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getInterviewFeedbacks.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/users/{userId}/interview-feedbacks`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewFeedbackListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザのインタビューフィードバックを取得する。
     */
    async getInterviewFeedbacks(requestParameters: GetInterviewFeedbacksRequest): Promise<InterviewFeedbackListResponse> {
        const response = await this.getInterviewFeedbacksRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビュー音声データ情報を取得する。
     */
    async getInterviewFileRaw(requestParameters: GetInterviewFileRequest): Promise<runtime.ApiResponse<InterviewFileResponse>> {
        if (requestParameters.interviewRecordingId === null || requestParameters.interviewRecordingId === undefined) {
            throw new runtime.RequiredError('interviewRecordingId','Required parameter requestParameters.interviewRecordingId was null or undefined when calling getInterviewFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-files/{interviewRecordingId}`.replace(`{${"interviewRecordingId"}}`, encodeURIComponent(String(requestParameters.interviewRecordingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewFileResponseFromJSON(jsonValue));
    }

    /**
     * インタビュー音声データ情報を取得する。
     */
    async getInterviewFile(requestParameters: GetInterviewFileRequest): Promise<InterviewFileResponse> {
        const response = await this.getInterviewFileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInterviewFileDownloadUrlRaw(requestParameters: GetInterviewFileDownloadUrlRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.hrInterviewFileId === null || requestParameters.hrInterviewFileId === undefined) {
            throw new runtime.RequiredError('hrInterviewFileId','Required parameter requestParameters.hrInterviewFileId was null or undefined when calling getInterviewFileDownloadUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-files/{hrInterviewFileId}/download-url`.replace(`{${"hrInterviewFileId"}}`, encodeURIComponent(String(requestParameters.hrInterviewFileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getInterviewFileDownloadUrl(requestParameters: GetInterviewFileDownloadUrlRequest): Promise<string> {
        const response = await this.getInterviewFileDownloadUrlRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビュー音声データ一覧を取得する。
     */
    async getInterviewFilesRaw(requestParameters: GetInterviewFilesRequest): Promise<runtime.ApiResponse<GetInterviewFilesResponse>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getInterviewFiles.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getInterviewFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewFilesResponseFromJSON(jsonValue));
    }

    /**
     * インタビュー音声データ一覧を取得する。
     */
    async getInterviewFiles(requestParameters: GetInterviewFilesRequest): Promise<GetInterviewFilesResponse> {
        const response = await this.getInterviewFilesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInterviewFormatByTypeIdRaw(requestParameters: GetInterviewFormatByTypeIdRequest): Promise<runtime.ApiResponse<InterviewFormatListResponse>> {
        if (requestParameters.interviewTypeId === null || requestParameters.interviewTypeId === undefined) {
            throw new runtime.RequiredError('interviewTypeId','Required parameter requestParameters.interviewTypeId was null or undefined when calling getInterviewFormatByTypeId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-types/{interviewTypeId}/formats`.replace(`{${"interviewTypeId"}}`, encodeURIComponent(String(requestParameters.interviewTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewFormatListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getInterviewFormatByTypeId(requestParameters: GetInterviewFormatByTypeIdRequest): Promise<InterviewFormatListResponse> {
        const response = await this.getInterviewFormatByTypeIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInterviewFormatContentsRaw(requestParameters: GetInterviewFormatContentsRequest): Promise<runtime.ApiResponse<Array<InterviewFormatContentResponse>>> {
        if (requestParameters.interviewFormatId === null || requestParameters.interviewFormatId === undefined) {
            throw new runtime.RequiredError('interviewFormatId','Required parameter requestParameters.interviewFormatId was null or undefined when calling getInterviewFormatContents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-formats/{interviewFormatId}/contents`.replace(`{${"interviewFormatId"}}`, encodeURIComponent(String(requestParameters.interviewFormatId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InterviewFormatContentResponseFromJSON));
    }

    /**
     */
    async getInterviewFormatContents(requestParameters: GetInterviewFormatContentsRequest): Promise<Array<InterviewFormatContentResponse>> {
        const response = await this.getInterviewFormatContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getInterviewKpiReportRaw(requestParameters: GetInterviewKpiReportRequest): Promise<runtime.ApiResponse<InterviewKpiResponse>> {
        if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
            throw new runtime.RequiredError('periodId','Required parameter requestParameters.periodId was null or undefined when calling getInterviewKpiReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.sexes) {
            queryParameters['sexes'] = requestParameters.sexes;
        }

        if (requestParameters.departments) {
            queryParameters['departments'] = requestParameters.departments;
        }

        if (requestParameters.free1s) {
            queryParameters['free1s'] = requestParameters.free1s;
        }

        if (requestParameters.free2s) {
            queryParameters['free2s'] = requestParameters.free2s;
        }

        if (requestParameters.monthsOfServices) {
            queryParameters['monthsOfServices'] = requestParameters.monthsOfServices;
        }

        if (requestParameters.ages) {
            queryParameters['ages'] = requestParameters.ages;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-kpi-reports/{periodId}`.replace(`{${"periodId"}}`, encodeURIComponent(String(requestParameters.periodId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewKpiResponseFromJSON(jsonValue));
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getInterviewKpiReport(requestParameters: GetInterviewKpiReportRequest): Promise<InterviewKpiResponse> {
        const response = await this.getInterviewKpiReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザのインタビュー品質管理レポートを取得する。
     */
    async getInterviewQualityReportRaw(requestParameters: GetInterviewQualityReportRequest): Promise<runtime.ApiResponse<InterviewQualityReportResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getInterviewQualityReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-quality-reports/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewQualityReportResponseFromJSON(jsonValue));
    }

    /**
     * ユーザのインタビュー品質管理レポートを取得する。
     */
    async getInterviewQualityReport(requestParameters: GetInterviewQualityReportRequest): Promise<InterviewQualityReportResponse> {
        const response = await this.getInterviewQualityReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザごとのインタビュー品質管理レポート一覧を取得する。
     */
    async getInterviewQualityReportListRaw(): Promise<runtime.ApiResponse<InterviewQualityReportListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-quality-reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewQualityReportListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザごとのインタビュー品質管理レポート一覧を取得する。
     */
    async getInterviewQualityReportList(): Promise<InterviewQualityReportListResponse> {
        const response = await this.getInterviewQualityReportListRaw();
        return await response.value();
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getInterviewReportRaw(requestParameters: GetInterviewReportRequest): Promise<runtime.ApiResponse<InterviewReportScoreResponse>> {
        if (requestParameters.axis === null || requestParameters.axis === undefined) {
            throw new runtime.RequiredError('axis','Required parameter requestParameters.axis was null or undefined when calling getInterviewReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.axis !== undefined) {
            queryParameters['axis'] = requestParameters.axis;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.sexes) {
            queryParameters['sexes'] = requestParameters.sexes;
        }

        if (requestParameters.departments) {
            queryParameters['departments'] = requestParameters.departments;
        }

        if (requestParameters.free1s) {
            queryParameters['free1s'] = requestParameters.free1s;
        }

        if (requestParameters.free2s) {
            queryParameters['free2s'] = requestParameters.free2s;
        }

        if (requestParameters.monthsOfServices) {
            queryParameters['monthsOfServices'] = requestParameters.monthsOfServices;
        }

        if (requestParameters.ages) {
            queryParameters['ages'] = requestParameters.ages;
        }

        if (requestParameters.subCategoryIds) {
            queryParameters['subCategoryIds'] = requestParameters.subCategoryIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interviews/scores`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewReportScoreResponseFromJSON(jsonValue));
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async getInterviewReport(requestParameters: GetInterviewReportRequest): Promise<InterviewReportScoreResponse> {
        const response = await this.getInterviewReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInterviewTypeRaw(requestParameters: GetInterviewTypeRequest): Promise<runtime.ApiResponse<InterviewTypeResponse>> {
        if (requestParameters.interviewTypeId === null || requestParameters.interviewTypeId === undefined) {
            throw new runtime.RequiredError('interviewTypeId','Required parameter requestParameters.interviewTypeId was null or undefined when calling getInterviewType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-types/{interviewTypeId}`.replace(`{${"interviewTypeId"}}`, encodeURIComponent(String(requestParameters.interviewTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewTypeResponseFromJSON(jsonValue));
    }

    /**
     */
    async getInterviewType(requestParameters: GetInterviewTypeRequest): Promise<InterviewTypeResponse> {
        const response = await this.getInterviewTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getInterviewTypesRaw(requestParameters: GetInterviewTypesRequest): Promise<runtime.ApiResponse<InterviewTypeListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.includeDeleted !== undefined) {
            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewTypeListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getInterviewTypes(requestParameters: GetInterviewTypesRequest): Promise<InterviewTypeListResponse> {
        const response = await this.getInterviewTypesRaw(requestParameters);
        return await response.value();
    }

    /**
     * ラベリング対象のドキュメントを取得する。
     */
    async getLabelDocumentRaw(requestParameters: GetLabelDocumentRequest): Promise<runtime.ApiResponse<LabelingDocumentListResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling getLabelDocument.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getLabelDocument.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getLabelDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/labels/sessions/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LabelingDocumentListResponseFromJSON(jsonValue));
    }

    /**
     * ラベリング対象のドキュメントを取得する。
     */
    async getLabelDocument(requestParameters: GetLabelDocumentRequest): Promise<LabelingDocumentListResponse> {
        const response = await this.getLabelDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * サブカテゴリごとにルールの精度に関する統計情報を取得する。
     */
    async getLabelStatsBySubCategoryIdRaw(requestParameters: GetLabelStatsBySubCategoryIdRequest): Promise<runtime.ApiResponse<LabelStatsResponse>> {
        if (requestParameters.subCategoryId === null || requestParameters.subCategoryId === undefined) {
            throw new runtime.RequiredError('subCategoryId','Required parameter requestParameters.subCategoryId was null or undefined when calling getLabelStatsBySubCategoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/sub-categories/{subCategoryId}/label-stats`.replace(`{${"subCategoryId"}}`, encodeURIComponent(String(requestParameters.subCategoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LabelStatsResponseFromJSON(jsonValue));
    }

    /**
     * サブカテゴリごとにルールの精度に関する統計情報を取得する。
     */
    async getLabelStatsBySubCategoryId(requestParameters: GetLabelStatsBySubCategoryIdRequest): Promise<LabelStatsResponse> {
        const response = await this.getLabelStatsBySubCategoryIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * ドキュメントに付与されているラベルを取得する
     */
    async getLabelingResultRaw(requestParameters: GetLabelingResultRequest): Promise<runtime.ApiResponse<LabeledDocumentListResponse>> {
        if (requestParameters.subCategoryId === null || requestParameters.subCategoryId === undefined) {
            throw new runtime.RequiredError('subCategoryId','Required parameter requestParameters.subCategoryId was null or undefined when calling getLabelingResult.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getLabelingResult.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getLabelingResult.');
        }

        const queryParameters: any = {};

        if (requestParameters.subCategoryId !== undefined) {
            queryParameters['subCategoryId'] = requestParameters.subCategoryId;
        }

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/labels/results`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LabeledDocumentListResponseFromJSON(jsonValue));
    }

    /**
     * ドキュメントに付与されているラベルを取得する
     */
    async getLabelingResult(requestParameters: GetLabelingResultRequest): Promise<LabeledDocumentListResponse> {
        const response = await this.getLabelingResultRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLatestComputationTaskByDocumentIdRaw(requestParameters: GetLatestComputationTaskByDocumentIdRequest): Promise<runtime.ApiResponse<ComputationTaskResponse>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getLatestComputationTaskByDocumentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/documents/{documentId}/computation-tasks/latest`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ComputationTaskResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLatestComputationTaskByDocumentId(requestParameters: GetLatestComputationTaskByDocumentIdRequest): Promise<ComputationTaskResponse> {
        const response = await this.getLatestComputationTaskByDocumentIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLatestComputationTaskByExEmployeeInterviewIdRaw(requestParameters: GetLatestComputationTaskByExEmployeeInterviewIdRequest): Promise<runtime.ApiResponse<ComputationTaskResponse>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling getLatestComputationTaskByExEmployeeInterviewId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}/computation-task/latest`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ComputationTaskResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLatestComputationTaskByExEmployeeInterviewId(requestParameters: GetLatestComputationTaskByExEmployeeInterviewIdRequest): Promise<ComputationTaskResponse> {
        const response = await this.getLatestComputationTaskByExEmployeeInterviewIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * 最新のタスクの実行状況を返す
     */
    async getLatestComputationTaskByRuleIdRaw(requestParameters: GetLatestComputationTaskByRuleIdRequest): Promise<runtime.ApiResponse<ComputationTaskResponse>> {
        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling getLatestComputationTaskByRuleId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/rules/{ruleId}/computation-task/latest`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ComputationTaskResponseFromJSON(jsonValue));
    }

    /**
     * 最新のタスクの実行状況を返す
     */
    async getLatestComputationTaskByRuleId(requestParameters: GetLatestComputationTaskByRuleIdRequest): Promise<ComputationTaskResponse> {
        const response = await this.getLatestComputationTaskByRuleIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLatestComputationTaskBySurveyIdRaw(requestParameters: GetLatestComputationTaskBySurveyIdRequest): Promise<runtime.ApiResponse<ComputationTaskResponse>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling getLatestComputationTaskBySurveyId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/computation-tasks/latest`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ComputationTaskResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLatestComputationTaskBySurveyId(requestParameters: GetLatestComputationTaskBySurveyIdRequest): Promise<ComputationTaskResponse> {
        const response = await this.getLatestComputationTaskBySurveyIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * 最新の会計期間を取得する
     */
    async getLatestPeriodRaw(): Promise<runtime.ApiResponse<PeriodResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/periods/latest-period`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PeriodResponseFromJSON(jsonValue));
    }

    /**
     * 最新の会計期間を取得する
     */
    async getLatestPeriod(): Promise<PeriodResponse> {
        const response = await this.getLatestPeriodRaw();
        return await response.value();
    }

    /**
     * 最新のアンケートを取得する
     */
    async getLatestSurveyRaw(): Promise<runtime.ApiResponse<SurveyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/latest-survey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyResponseFromJSON(jsonValue));
    }

    /**
     * 最新のアンケートを取得する
     */
    async getLatestSurvey(): Promise<SurveyResponse> {
        const response = await this.getLatestSurveyRaw();
        return await response.value();
    }

    /**
     * 自分自身の情報を取得
     */
    async getMyselfRaw(): Promise<runtime.ApiResponse<UserRefResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRefResponseFromJSON(jsonValue));
    }

    /**
     * 自分自身の情報を取得
     */
    async getMyself(): Promise<UserRefResponse> {
        const response = await this.getMyselfRaw();
        return await response.value();
    }

    /**
     */
    async getNoteRaw(requestParameters: GetNoteRequest): Promise<runtime.ApiResponse<NoteResponse>> {
        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling getNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notes/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNote(requestParameters: GetNoteRequest): Promise<NoteResponse> {
        const response = await this.getNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNotesRaw(requestParameters: GetNotesRequest): Promise<runtime.ApiResponse<NoteListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.segmentKey !== undefined) {
            queryParameters['segmentKey'] = requestParameters.segmentKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNotes(requestParameters: GetNotesRequest): Promise<NoteListResponse> {
        const response = await this.getNotesRaw(requestParameters);
        return await response.value();
    }

    /**
     * paginationなしですべての期間を返す
     */
    async getPeriodsRaw(): Promise<runtime.ApiResponse<Array<PeriodResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/periods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeriodResponseFromJSON));
    }

    /**
     * paginationなしですべての期間を返す
     */
    async getPeriods(): Promise<Array<PeriodResponse>> {
        const response = await this.getPeriodsRaw();
        return await response.value();
    }

    /**
     */
    async getRuleSuggestionsByCategoryIdRaw(requestParameters: GetRuleSuggestionsByCategoryIdRequest): Promise<runtime.ApiResponse<Array<RuleSuggestionResponse>>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getRuleSuggestionsByCategoryId.');
        }

        if (requestParameters.algorithm === null || requestParameters.algorithm === undefined) {
            throw new runtime.RequiredError('algorithm','Required parameter requestParameters.algorithm was null or undefined when calling getRuleSuggestionsByCategoryId.');
        }

        const queryParameters: any = {};

        if (requestParameters.algorithm !== undefined) {
            queryParameters['algorithm'] = requestParameters.algorithm;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.significantTerms !== undefined) {
            queryParameters['significantTerms'] = requestParameters.significantTerms;
        }

        if (requestParameters.filterNonEffectiveRules !== undefined) {
            queryParameters['filterNonEffectiveRules'] = requestParameters.filterNonEffectiveRules;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}/rule-suggestions`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RuleSuggestionResponseFromJSON));
    }

    /**
     */
    async getRuleSuggestionsByCategoryId(requestParameters: GetRuleSuggestionsByCategoryIdRequest): Promise<Array<RuleSuggestionResponse>> {
        const response = await this.getRuleSuggestionsByCategoryIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * サブカテゴリに対するおすすめルールを取得する。
     */
    async getRuleSuggestionsBySubCategoryIdRaw(requestParameters: GetRuleSuggestionsBySubCategoryIdRequest): Promise<runtime.ApiResponse<Array<RuleSuggestionResponse>>> {
        if (requestParameters.subCategoryId === null || requestParameters.subCategoryId === undefined) {
            throw new runtime.RequiredError('subCategoryId','Required parameter requestParameters.subCategoryId was null or undefined when calling getRuleSuggestionsBySubCategoryId.');
        }

        if (requestParameters.algorithm === null || requestParameters.algorithm === undefined) {
            throw new runtime.RequiredError('algorithm','Required parameter requestParameters.algorithm was null or undefined when calling getRuleSuggestionsBySubCategoryId.');
        }

        const queryParameters: any = {};

        if (requestParameters.algorithm !== undefined) {
            queryParameters['algorithm'] = requestParameters.algorithm;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.significantTerms !== undefined) {
            queryParameters['significantTerms'] = requestParameters.significantTerms;
        }

        if (requestParameters.filterNonEffectiveRules !== undefined) {
            queryParameters['filterNonEffectiveRules'] = requestParameters.filterNonEffectiveRules;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/sub-categories/{subCategoryId}/rule-suggestions`.replace(`{${"subCategoryId"}}`, encodeURIComponent(String(requestParameters.subCategoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RuleSuggestionResponseFromJSON));
    }

    /**
     * サブカテゴリに対するおすすめルールを取得する。
     */
    async getRuleSuggestionsBySubCategoryId(requestParameters: GetRuleSuggestionsBySubCategoryIdRequest): Promise<Array<RuleSuggestionResponse>> {
        const response = await this.getRuleSuggestionsBySubCategoryIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * カテゴリが持つすべてのルールを取得する
     */
    async getRulesByCategoryIdRaw(requestParameters: GetRulesByCategoryIdRequest): Promise<runtime.ApiResponse<Array<RuleResponse>>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getRulesByCategoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}/rules`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RuleResponseFromJSON));
    }

    /**
     * カテゴリが持つすべてのルールを取得する
     */
    async getRulesByCategoryId(requestParameters: GetRulesByCategoryIdRequest): Promise<Array<RuleResponse>> {
        const response = await this.getRulesByCategoryIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー入力セグメント定義を返す
     */
    async getSegmentDefinitionsRaw(requestParameters: GetSegmentDefinitionsRequest): Promise<runtime.ApiResponse<Array<InputSegmentDefinitionResponse>>> {
        if (requestParameters.segmentKey === null || requestParameters.segmentKey === undefined) {
            throw new runtime.RequiredError('segmentKey','Required parameter requestParameters.segmentKey was null or undefined when calling getSegmentDefinitions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/settings/segments/{segmentKey}/definitions`.replace(`{${"segmentKey"}}`, encodeURIComponent(String(requestParameters.segmentKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InputSegmentDefinitionResponseFromJSON));
    }

    /**
     * ユーザー入力セグメント定義を返す
     */
    async getSegmentDefinitions(requestParameters: GetSegmentDefinitionsRequest): Promise<Array<InputSegmentDefinitionResponse>> {
        const response = await this.getSegmentDefinitionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * 設定一覧を返す。
     */
    async getSettingRaw(): Promise<runtime.ApiResponse<SettingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingResponseFromJSON(jsonValue));
    }

    /**
     * 設定一覧を返す。
     */
    async getSetting(): Promise<SettingResponse> {
        const response = await this.getSettingRaw();
        return await response.value();
    }

    /**
     * QRコード提出用に、Survey IDからアンケートのデータを取得する。
     */
    async getSharedSurveySheetRaw(requestParameters: GetSharedSurveySheetRequest): Promise<runtime.ApiResponse<SharedSurveySheetResponse>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling getSharedSurveySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/survey-sheets`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SharedSurveySheetResponseFromJSON(jsonValue));
    }

    /**
     * QRコード提出用に、Survey IDからアンケートのデータを取得する。
     */
    async getSharedSurveySheet(requestParameters: GetSharedSurveySheetRequest): Promise<SharedSurveySheetResponse> {
        const response = await this.getSharedSurveySheetRaw(requestParameters);
        return await response.value();
    }

    /**
     * ルールの精度に関する統計情報を取得する。
     */
    async getStatsByRuleRaw(requestParameters: GetStatsByRuleRequest): Promise<runtime.ApiResponse<RuleStatsResponse>> {
        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling getStatsByRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/rules/{ruleId}/stats`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RuleStatsResponseFromJSON(jsonValue));
    }

    /**
     * ルールの精度に関する統計情報を取得する。
     */
    async getStatsByRule(requestParameters: GetStatsByRuleRequest): Promise<RuleStatsResponse> {
        const response = await this.getStatsByRuleRaw(requestParameters);
        return await response.value();
    }

    /**
     * カテゴリに含まれるサブカテゴリを取得する。
     */
    async getSubCategoryByCategoryIdRaw(requestParameters: GetSubCategoryByCategoryIdRequest): Promise<runtime.ApiResponse<Array<SubCategoryResponse>>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getSubCategoryByCategoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}/sub-categories`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubCategoryResponseFromJSON));
    }

    /**
     * カテゴリに含まれるサブカテゴリを取得する。
     */
    async getSubCategoryByCategoryId(requestParameters: GetSubCategoryByCategoryIdRequest): Promise<Array<SubCategoryResponse>> {
        const response = await this.getSubCategoryByCategoryIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * サブカテゴリーを取得する。
     */
    async getSubCategoryBySubCategoryIdRaw(requestParameters: GetSubCategoryBySubCategoryIdRequest): Promise<runtime.ApiResponse<SubCategoryResponse>> {
        if (requestParameters.subCategoryId === null || requestParameters.subCategoryId === undefined) {
            throw new runtime.RequiredError('subCategoryId','Required parameter requestParameters.subCategoryId was null or undefined when calling getSubCategoryBySubCategoryId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/sub-categories/{subCategoryId}`.replace(`{${"subCategoryId"}}`, encodeURIComponent(String(requestParameters.subCategoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubCategoryResponseFromJSON(jsonValue));
    }

    /**
     * サブカテゴリーを取得する。
     */
    async getSubCategoryBySubCategoryId(requestParameters: GetSubCategoryBySubCategoryIdRequest): Promise<SubCategoryResponse> {
        const response = await this.getSubCategoryBySubCategoryIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートを取得する
     */
    async getSurveyRaw(requestParameters: GetSurveyRequest): Promise<runtime.ApiResponse<SurveyResponse>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling getSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyResponseFromJSON(jsonValue));
    }

    /**
     * アンケートを取得する
     */
    async getSurvey(requestParameters: GetSurveyRequest): Promise<SurveyResponse> {
        const response = await this.getSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートの回答状況を取得する。
     */
    async getSurveyAnswersRaw(requestParameters: GetSurveyAnswersRequest): Promise<runtime.ApiResponse<Array<SurveyAnswerResponse>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling getSurveyAnswers.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/answers`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyAnswerResponseFromJSON));
    }

    /**
     * アンケートの回答状況を取得する。
     */
    async getSurveyAnswers(requestParameters: GetSurveyAnswersRequest): Promise<Array<SurveyAnswerResponse>> {
        const response = await this.getSurveyAnswersRaw(requestParameters);
        return await response.value();
    }

    /**
     * 現在設定されているアンケートの設問を取得する。
     */
    async getSurveyQuestionsRaw(): Promise<runtime.ApiResponse<Array<SurveyQuestionMoldResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/survey-questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyQuestionMoldResponseFromJSON));
    }

    /**
     * 現在設定されているアンケートの設問を取得する。
     */
    async getSurveyQuestions(): Promise<Array<SurveyQuestionMoldResponse>> {
        const response = await this.getSurveyQuestionsRaw();
        return await response.value();
    }

    /**
     */
    async getSurveyReportRaw(requestParameters: GetSurveyReportRequest): Promise<runtime.ApiResponse<SurveyReportResponse>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling getSurveyReport.');
        }

        if (requestParameters.axis === null || requestParameters.axis === undefined) {
            throw new runtime.RequiredError('axis','Required parameter requestParameters.axis was null or undefined when calling getSurveyReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.axis !== undefined) {
            queryParameters['axis'] = requestParameters.axis;
        }

        if (requestParameters.sexes) {
            queryParameters['sexes'] = requestParameters.sexes;
        }

        if (requestParameters.departments) {
            queryParameters['departments'] = requestParameters.departments;
        }

        if (requestParameters.free1s) {
            queryParameters['free1s'] = requestParameters.free1s;
        }

        if (requestParameters.free2s) {
            queryParameters['free2s'] = requestParameters.free2s;
        }

        if (requestParameters.monthsOfServices) {
            queryParameters['monthsOfServices'] = requestParameters.monthsOfServices;
        }

        if (requestParameters.ages) {
            queryParameters['ages'] = requestParameters.ages;
        }

        if (requestParameters.subCategoryIds) {
            queryParameters['subCategoryIds'] = requestParameters.subCategoryIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/reports/survey-reports/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyReportResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSurveyReport(requestParameters: GetSurveyReportRequest): Promise<SurveyReportResponse> {
        const response = await this.getSurveyReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートのデータを取得する。  surveyTargetがなければ404エラー 回答済みなら409エラー  surveyTargetはsurveyとsurveyAnswerを紐付ける概念 surveyはアンケートのタイトルとかを保存する surveyTargetはsurveyとemployeeを結びつけるための中間テーブル surveyAnswerは回答をいれる（質問と回答値のkey-value、surveyTargetにリレーション）
     */
    async getSurveySheetRaw(requestParameters: GetSurveySheetRequest): Promise<runtime.ApiResponse<SurveySheetResponse>> {
        if (requestParameters.surveySheetId === null || requestParameters.surveySheetId === undefined) {
            throw new runtime.RequiredError('surveySheetId','Required parameter requestParameters.surveySheetId was null or undefined when calling getSurveySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/survey-sheets/{surveySheetId}`.replace(`{${"surveySheetId"}}`, encodeURIComponent(String(requestParameters.surveySheetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveySheetResponseFromJSON(jsonValue));
    }

    /**
     * アンケートのデータを取得する。  surveyTargetがなければ404エラー 回答済みなら409エラー  surveyTargetはsurveyとsurveyAnswerを紐付ける概念 surveyはアンケートのタイトルとかを保存する surveyTargetはsurveyとemployeeを結びつけるための中間テーブル surveyAnswerは回答をいれる（質問と回答値のkey-value、surveyTargetにリレーション）
     */
    async getSurveySheet(requestParameters: GetSurveySheetRequest): Promise<SurveySheetResponse> {
        const response = await this.getSurveySheetRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケート一覧を取得する。
     */
    async getSurveysRaw(requestParameters: GetSurveysRequest): Promise<runtime.ApiResponse<SurveyListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyListResponseFromJSON(jsonValue));
    }

    /**
     * アンケート一覧を取得する。
     */
    async getSurveys(requestParameters: GetSurveysRequest): Promise<SurveyListResponse> {
        const response = await this.getSurveysRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビュー音声データの文字起こしを取得する。
     */
    async getTranscriptionRaw(requestParameters: GetTranscriptionRequest): Promise<runtime.ApiResponse<TranscriptionResponse>> {
        if (requestParameters.interviewRecordingId === null || requestParameters.interviewRecordingId === undefined) {
            throw new runtime.RequiredError('interviewRecordingId','Required parameter requestParameters.interviewRecordingId was null or undefined when calling getTranscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-file-transcriptions/{interviewRecordingId}/transcription`.replace(`{${"interviewRecordingId"}}`, encodeURIComponent(String(requestParameters.interviewRecordingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TranscriptionResponseFromJSON(jsonValue));
    }

    /**
     * インタビュー音声データの文字起こしを取得する。
     */
    async getTranscription(requestParameters: GetTranscriptionRequest): Promise<TranscriptionResponse> {
        const response = await this.getTranscriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー取得
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UserDetailResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー取得
     */
    async getUser(requestParameters: GetUserRequest): Promise<UserDetailResponse> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザグループの情報を取得する。
     */
    async getUserGroupRaw(requestParameters: GetUserGroupRequest): Promise<runtime.ApiResponse<UserGroupResponse>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling getUserGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/user-groups/{userGroupId}`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResponseFromJSON(jsonValue));
    }

    /**
     * ユーザグループの情報を取得する。
     */
    async getUserGroup(requestParameters: GetUserGroupRequest): Promise<UserGroupResponse> {
        const response = await this.getUserGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザグループの一覧を取得する。
     */
    async getUserGroupsRaw(requestParameters: GetUserGroupsRequest): Promise<runtime.ApiResponse<UserGroupsResponse>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling getUserGroups.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getUserGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/user-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupsResponseFromJSON(jsonValue));
    }

    /**
     * ユーザグループの一覧を取得する。
     */
    async getUserGroups(requestParameters: GetUserGroupsRequest): Promise<UserGroupsResponse> {
        const response = await this.getUserGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザーが属しているユーザーグループの一覧を取得
     */
    async getUserGroupsOfUserRaw(requestParameters: GetUserGroupsOfUserRequest): Promise<runtime.ApiResponse<UserGroupsResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserGroupsOfUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/users/{userId}/groups`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupsResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーが属しているユーザーグループの一覧を取得
     */
    async getUserGroupsOfUser(requestParameters: GetUserGroupsOfUserRequest): Promise<UserGroupsResponse> {
        const response = await this.getUserGroupsOfUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー一覧の取得
     */
    async getUsersRaw(): Promise<runtime.ApiResponse<UserListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー一覧の取得
     */
    async getUsers(): Promise<UserListResponse> {
        const response = await this.getUsersRaw();
        return await response.value();
    }

    /**
     */
    async getVersionRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/healthcheck/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getVersion(): Promise<string> {
        const response = await this.getVersionRaw();
        return await response.value();
    }

    /**
     */
    async healthcheckRaw(): Promise<runtime.ApiResponse<HealthStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/healthcheck`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async healthcheck(): Promise<HealthStatusResponse> {
        const response = await this.healthcheckRaw();
        return await response.value();
    }

    /**
     */
    async rebuildAnalysisFromScratchRaw(requestParameters: RebuildAnalysisFromScratchRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/rebuildAnalysisFromScratch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async rebuildAnalysisFromScratch(requestParameters: RebuildAnalysisFromScratchRequest): Promise<string> {
        const response = await this.rebuildAnalysisFromScratchRaw(requestParameters);
        return await response.value();
    }

    /**
     * 音声データのdurationをrebuildする
     */
    async rebuildDurationOfInterviewFilesRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-files`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 音声データのdurationをrebuildする
     */
    async rebuildDurationOfInterviewFiles(): Promise<string> {
        const response = await this.rebuildDurationOfInterviewFilesRaw();
        return await response.value();
    }

    /**
     * 自動計算セグメントの設定を更新する。
     */
    async registerCalculatedSegmentConditionRaw(requestParameters: RegisterCalculatedSegmentConditionRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.segmentKey === null || requestParameters.segmentKey === undefined) {
            throw new runtime.RequiredError('segmentKey','Required parameter requestParameters.segmentKey was null or undefined when calling registerCalculatedSegmentCondition.');
        }

        if (requestParameters.registerCalculatedSegmentSettingRequestBody === null || requestParameters.registerCalculatedSegmentSettingRequestBody === undefined) {
            throw new runtime.RequiredError('registerCalculatedSegmentSettingRequestBody','Required parameter requestParameters.registerCalculatedSegmentSettingRequestBody was null or undefined when calling registerCalculatedSegmentCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/settings/segments/{segmentKey}/conditions`.replace(`{${"segmentKey"}}`, encodeURIComponent(String(requestParameters.segmentKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterCalculatedSegmentSettingRequestBodyToJSON(requestParameters.registerCalculatedSegmentSettingRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 自動計算セグメントの設定を更新する。
     */
    async registerCalculatedSegmentCondition(requestParameters: RegisterCalculatedSegmentConditionRequest): Promise<string> {
        const response = await this.registerCalculatedSegmentConditionRaw(requestParameters);
        return await response.value();
    }

    /**
     * 従業員リストを登録（作成・更新）する。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async registerEmployeesRaw(requestParameters: RegisterEmployeesRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling registerEmployees.');
        }

        if (requestParameters.effectiveFrom === null || requestParameters.effectiveFrom === undefined) {
            throw new runtime.RequiredError('effectiveFrom','Required parameter requestParameters.effectiveFrom was null or undefined when calling registerEmployees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.effectiveFrom !== undefined) {
            formParams.append('effectiveFrom', requestParameters.effectiveFrom as any);
        }

        const response = await this.request({
            path: `/api/v5/employee-master/employees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 従業員リストを登録（作成・更新）する。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async registerEmployees(requestParameters: RegisterEmployeesRequest): Promise<string> {
        const response = await this.registerEmployeesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async registerInterviewFormatContentsRaw(requestParameters: RegisterInterviewFormatContentsRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.interviewFormatId === null || requestParameters.interviewFormatId === undefined) {
            throw new runtime.RequiredError('interviewFormatId','Required parameter requestParameters.interviewFormatId was null or undefined when calling registerInterviewFormatContents.');
        }

        if (requestParameters.registerInterviewFormatContentsRequestBody === null || requestParameters.registerInterviewFormatContentsRequestBody === undefined) {
            throw new runtime.RequiredError('registerInterviewFormatContentsRequestBody','Required parameter requestParameters.registerInterviewFormatContentsRequestBody was null or undefined when calling registerInterviewFormatContents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-formats/{interviewFormatId}/contents`.replace(`{${"interviewFormatId"}}`, encodeURIComponent(String(requestParameters.interviewFormatId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterInterviewFormatContentsRequestBodyToJSON(requestParameters.registerInterviewFormatContentsRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async registerInterviewFormatContents(requestParameters: RegisterInterviewFormatContentsRequest): Promise<string> {
        const response = await this.registerInterviewFormatContentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async registerInterviewFormatsRaw(requestParameters: RegisterInterviewFormatsRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.interviewTypeId === null || requestParameters.interviewTypeId === undefined) {
            throw new runtime.RequiredError('interviewTypeId','Required parameter requestParameters.interviewTypeId was null or undefined when calling registerInterviewFormats.');
        }

        if (requestParameters.registerInterviewFormatsRequestBody === null || requestParameters.registerInterviewFormatsRequestBody === undefined) {
            throw new runtime.RequiredError('registerInterviewFormatsRequestBody','Required parameter requestParameters.registerInterviewFormatsRequestBody was null or undefined when calling registerInterviewFormats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-types/{interviewTypeId}/formats`.replace(`{${"interviewTypeId"}}`, encodeURIComponent(String(requestParameters.interviewTypeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterInterviewFormatsRequestBodyToJSON(requestParameters.registerInterviewFormatsRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async registerInterviewFormats(requestParameters: RegisterInterviewFormatsRequest): Promise<string> {
        const response = await this.registerInterviewFormatsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async registerInterviewTypeRaw(requestParameters: RegisterInterviewTypeRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.registerInterviewTypesRequestBody === null || requestParameters.registerInterviewTypesRequestBody === undefined) {
            throw new runtime.RequiredError('registerInterviewTypesRequestBody','Required parameter requestParameters.registerInterviewTypesRequestBody was null or undefined when calling registerInterviewType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-types`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterInterviewTypesRequestBodyToJSON(requestParameters.registerInterviewTypesRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async registerInterviewType(requestParameters: RegisterInterviewTypeRequest): Promise<string> {
        const response = await this.registerInterviewTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * フリーセグメント1,2の名称の設定ができる
     */
    async registerSegmentNameRaw(requestParameters: RegisterSegmentNameRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.segmentKey === null || requestParameters.segmentKey === undefined) {
            throw new runtime.RequiredError('segmentKey','Required parameter requestParameters.segmentKey was null or undefined when calling registerSegmentName.');
        }

        if (requestParameters.registerSegmentNameRequestBody === null || requestParameters.registerSegmentNameRequestBody === undefined) {
            throw new runtime.RequiredError('registerSegmentNameRequestBody','Required parameter requestParameters.registerSegmentNameRequestBody was null or undefined when calling registerSegmentName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/settings/segments/{segmentKey}/name`.replace(`{${"segmentKey"}}`, encodeURIComponent(String(requestParameters.segmentKey))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterSegmentNameRequestBodyToJSON(requestParameters.registerSegmentNameRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * フリーセグメント1,2の名称の設定ができる
     */
    async registerSegmentName(requestParameters: RegisterSegmentNameRequest): Promise<string> {
        const response = await this.registerSegmentNameRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートの設問を更新する。
     */
    async registerSurveyQuestionsRaw(requestParameters: RegisterSurveyQuestionsRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.registerSurveyQuestionRequestBody === null || requestParameters.registerSurveyQuestionRequestBody === undefined) {
            throw new runtime.RequiredError('registerSurveyQuestionRequestBody','Required parameter requestParameters.registerSurveyQuestionRequestBody was null or undefined when calling registerSurveyQuestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/survey-questions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterSurveyQuestionRequestBodyToJSON(requestParameters.registerSurveyQuestionRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * アンケートの設問を更新する。
     */
    async registerSurveyQuestions(requestParameters: RegisterSurveyQuestionsRequest): Promise<string> {
        const response = await this.registerSurveyQuestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * 仮パスワードの再発行
     */
    async resendInvitationRaw(requestParameters: ResendInvitationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resendInvitationRequestBody === null || requestParameters.resendInvitationRequestBody === undefined) {
            throw new runtime.RequiredError('resendInvitationRequestBody','Required parameter requestParameters.resendInvitationRequestBody was null or undefined when calling resendInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/users/resend-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInvitationRequestBodyToJSON(requestParameters.resendInvitationRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 仮パスワードの再発行
     */
    async resendInvitation(requestParameters: ResendInvitationRequest): Promise<void> {
        await this.resendInvitationRaw(requestParameters);
    }

    /**
     */
    async restoreInterviewAttributeRaw(requestParameters: RestoreInterviewAttributeRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.periodId === null || requestParameters.periodId === undefined) {
            throw new runtime.RequiredError('periodId','Required parameter requestParameters.periodId was null or undefined when calling restoreInterviewAttribute.');
        }

        if (requestParameters.target === null || requestParameters.target === undefined) {
            throw new runtime.RequiredError('target','Required parameter requestParameters.target was null or undefined when calling restoreInterviewAttribute.');
        }

        const queryParameters: any = {};

        if (requestParameters.target !== undefined) {
            queryParameters['target'] = requestParameters.target;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/periods/{periodId}/restore-interview-attribute`.replace(`{${"periodId"}}`, encodeURIComponent(String(requestParameters.periodId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async restoreInterviewAttribute(requestParameters: RestoreInterviewAttributeRequest): Promise<string> {
        const response = await this.restoreInterviewAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * 退職者の声を検索する WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async searchForInterviewDocumentsRaw(requestParameters: SearchForInterviewDocumentsRequest): Promise<runtime.ApiResponse<SearchInterviewDocumentsResponse>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling searchForInterviewDocuments.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling searchForInterviewDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.subCategoryId) {
            queryParameters['subCategoryId'] = requestParameters.subCategoryId;
        }

        if (requestParameters.sex) {
            queryParameters['sex'] = requestParameters.sex;
        }

        if (requestParameters.department) {
            queryParameters['department'] = requestParameters.department;
        }

        if (requestParameters.free1) {
            queryParameters['free1'] = requestParameters.free1;
        }

        if (requestParameters.free2) {
            queryParameters['free2'] = requestParameters.free2;
        }

        if (requestParameters.age) {
            queryParameters['age'] = requestParameters.age;
        }

        if (requestParameters.monthsOfService) {
            queryParameters['monthsOfService'] = requestParameters.monthsOfService;
        }

        if (requestParameters.retireDateBegin !== undefined) {
            queryParameters['retireDateBegin'] = (requestParameters.retireDateBegin as any).toISOString();
        }

        if (requestParameters.retireDateEnd !== undefined) {
            queryParameters['retireDateEnd'] = (requestParameters.retireDateEnd as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchInterviewDocumentsResponseFromJSON(jsonValue));
    }

    /**
     * 退職者の声を検索する WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAccountSecurityStackの設定も変更すること。
     */
    async searchForInterviewDocuments(requestParameters: SearchForInterviewDocumentsRequest): Promise<SearchInterviewDocumentsResponse> {
        const response = await this.searchForInterviewDocumentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * おすすめルールでマッチするドキュメントを検索する。
     */
    async searchInterviewDocumentsByRawRuleRaw(requestParameters: SearchInterviewDocumentsByRawRuleRequest): Promise<runtime.ApiResponse<Array<DocumentResponse>>> {
        if (requestParameters.esQuery === null || requestParameters.esQuery === undefined) {
            throw new runtime.RequiredError('esQuery','Required parameter requestParameters.esQuery was null or undefined when calling searchInterviewDocumentsByRawRule.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling searchInterviewDocumentsByRawRule.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling searchInterviewDocumentsByRawRule.');
        }

        const queryParameters: any = {};

        if (requestParameters.esQuery !== undefined) {
            queryParameters['esQuery'] = requestParameters.esQuery;
        }

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/settings/retirements/interviews/documents/search-by-rule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentResponseFromJSON));
    }

    /**
     * おすすめルールでマッチするドキュメントを検索する。
     */
    async searchInterviewDocumentsByRawRule(requestParameters: SearchInterviewDocumentsByRawRuleRequest): Promise<Array<DocumentResponse>> {
        const response = await this.searchInterviewDocumentsByRawRuleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async sendHrInterviewFeedbackRaw(requestParameters: SendHrInterviewFeedbackRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hrInterviewId === null || requestParameters.hrInterviewId === undefined) {
            throw new runtime.RequiredError('hrInterviewId','Required parameter requestParameters.hrInterviewId was null or undefined when calling sendHrInterviewFeedback.');
        }

        if (requestParameters.sendHrInterviewFeedbackRequestBody === null || requestParameters.sendHrInterviewFeedbackRequestBody === undefined) {
            throw new runtime.RequiredError('sendHrInterviewFeedbackRequestBody','Required parameter requestParameters.sendHrInterviewFeedbackRequestBody was null or undefined when calling sendHrInterviewFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews/{hrInterviewId}/send-feedback`.replace(`{${"hrInterviewId"}}`, encodeURIComponent(String(requestParameters.hrInterviewId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SendHrInterviewFeedbackRequestBodyToJSON(requestParameters.sendHrInterviewFeedbackRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendHrInterviewFeedback(requestParameters: SendHrInterviewFeedbackRequest): Promise<void> {
        await this.sendHrInterviewFeedbackRaw(requestParameters);
    }

    /**
     * アンケートのリマインドメールを送る。
     */
    async sendRemindSurveySheetRaw(requestParameters: SendRemindSurveySheetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling sendRemindSurveySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/remind`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アンケートのリマインドメールを送る。
     */
    async sendRemindSurveySheet(requestParameters: SendRemindSurveySheetRequest): Promise<void> {
        await this.sendRemindSurveySheetRaw(requestParameters);
    }

    /**
     * サンプルアンケートメールを任意のメールアドレスに送信する。
     */
    async sendSampleSurveySheetRaw(requestParameters: SendSampleSurveySheetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling sendSampleSurveySheet.');
        }

        if (requestParameters.sendSampleSurveySheetRequestBody === null || requestParameters.sendSampleSurveySheetRequestBody === undefined) {
            throw new runtime.RequiredError('sendSampleSurveySheetRequestBody','Required parameter requestParameters.sendSampleSurveySheetRequestBody was null or undefined when calling sendSampleSurveySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/sample`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendSampleSurveySheetRequestBodyToJSON(requestParameters.sendSampleSurveySheetRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * サンプルアンケートメールを任意のメールアドレスに送信する。
     */
    async sendSampleSurveySheet(requestParameters: SendSampleSurveySheetRequest): Promise<void> {
        await this.sendSampleSurveySheetRaw(requestParameters);
    }

    /**
     * ラベルをドキュメントに付与する。
     */
    async setLabelRaw(requestParameters: SetLabelRequest): Promise<runtime.ApiResponse<LabelType>> {
        if (requestParameters.labelId === null || requestParameters.labelId === undefined) {
            throw new runtime.RequiredError('labelId','Required parameter requestParameters.labelId was null or undefined when calling setLabel.');
        }

        if (requestParameters.setLabelRequestBody === null || requestParameters.setLabelRequestBody === undefined) {
            throw new runtime.RequiredError('setLabelRequestBody','Required parameter requestParameters.setLabelRequestBody was null or undefined when calling setLabel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/labels/{labelId}`.replace(`{${"labelId"}}`, encodeURIComponent(String(requestParameters.labelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetLabelRequestBodyToJSON(requestParameters.setLabelRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LabelTypeFromJSON(jsonValue));
    }

    /**
     * ラベルをドキュメントに付与する。
     */
    async setLabel(requestParameters: SetLabelRequest): Promise<LabelType> {
        const response = await this.setLabelRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザグループに属するユーザを更新する。
     */
    async setUsersToUserGroupRaw(requestParameters: SetUsersToUserGroupRequest): Promise<runtime.ApiResponse<UserGroupResponse>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling setUsersToUserGroup.');
        }

        if (requestParameters.userGroupMembersRequest === null || requestParameters.userGroupMembersRequest === undefined) {
            throw new runtime.RequiredError('userGroupMembersRequest','Required parameter requestParameters.userGroupMembersRequest was null or undefined when calling setUsersToUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/user-groups/{userGroupId}/users`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupMembersRequestToJSON(requestParameters.userGroupMembersRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResponseFromJSON(jsonValue));
    }

    /**
     * ユーザグループに属するユーザを更新する。
     */
    async setUsersToUserGroup(requestParameters: SetUsersToUserGroupRequest): Promise<UserGroupResponse> {
        const response = await this.setUsersToUserGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * ラベリングを開始する。
     */
    async startLabelingSessionRaw(requestParameters: StartLabelingSessionRequest): Promise<runtime.ApiResponse<StartLabelingSessionResponseBody>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling startLabelingSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/labels/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StartLabelingSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * ラベリングを開始する。
     */
    async startLabelingSession(requestParameters: StartLabelingSessionRequest): Promise<StartLabelingSessionResponseBody> {
        const response = await this.startLabelingSessionRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートを開始する。
     */
    async startSurveyRaw(requestParameters: StartSurveyRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling startSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/start`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * アンケートを開始する。
     */
    async startSurvey(requestParameters: StartSurveyRequest): Promise<string> {
        const response = await this.startSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビュー音声データを文字起こしする。
     */
    async startTranscriptionRaw(requestParameters: StartTranscriptionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.interviewRecordingId === null || requestParameters.interviewRecordingId === undefined) {
            throw new runtime.RequiredError('interviewRecordingId','Required parameter requestParameters.interviewRecordingId was null or undefined when calling startTranscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-file-transcriptions/{interviewRecordingId}/transcription`.replace(`{${"interviewRecordingId"}}`, encodeURIComponent(String(requestParameters.interviewRecordingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * インタビュー音声データを文字起こしする。
     */
    async startTranscription(requestParameters: StartTranscriptionRequest): Promise<void> {
        await this.startTranscriptionRaw(requestParameters);
    }

    /**
     * インタビューのフィードバックを保存する  TODO: インタビュー確定APIと混ぜる？
     */
    async submitInterviewFeedbackRaw(requestParameters: SubmitInterviewFeedbackOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.interviewFileId === null || requestParameters.interviewFileId === undefined) {
            throw new runtime.RequiredError('interviewFileId','Required parameter requestParameters.interviewFileId was null or undefined when calling submitInterviewFeedback.');
        }

        if (requestParameters.submitInterviewFeedbackRequest === null || requestParameters.submitInterviewFeedbackRequest === undefined) {
            throw new runtime.RequiredError('submitInterviewFeedbackRequest','Required parameter requestParameters.submitInterviewFeedbackRequest was null or undefined when calling submitInterviewFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/interview-files/{interviewFileId}/interview-feedback`.replace(`{${"interviewFileId"}}`, encodeURIComponent(String(requestParameters.interviewFileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitInterviewFeedbackRequestToJSON(requestParameters.submitInterviewFeedbackRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * インタビューのフィードバックを保存する  TODO: インタビュー確定APIと混ぜる？
     */
    async submitInterviewFeedback(requestParameters: SubmitInterviewFeedbackOperationRequest): Promise<void> {
        await this.submitInterviewFeedbackRaw(requestParameters);
    }

    /**
     * アンケートの回答送信  回答済みなら409 surveyTargetがなければ404
     */
    async submitSurveyAnswerRaw(requestParameters: SubmitSurveyAnswerRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.surveySheetId === null || requestParameters.surveySheetId === undefined) {
            throw new runtime.RequiredError('surveySheetId','Required parameter requestParameters.surveySheetId was null or undefined when calling submitSurveyAnswer.');
        }

        if (requestParameters.submitSurveyAnswerRequestBody === null || requestParameters.submitSurveyAnswerRequestBody === undefined) {
            throw new runtime.RequiredError('submitSurveyAnswerRequestBody','Required parameter requestParameters.submitSurveyAnswerRequestBody was null or undefined when calling submitSurveyAnswer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/survey/survey-sheets/{surveySheetId}`.replace(`{${"surveySheetId"}}`, encodeURIComponent(String(requestParameters.surveySheetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitSurveyAnswerRequestBodyToJSON(requestParameters.submitSurveyAnswerRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * アンケートの回答送信  回答済みなら409 surveyTargetがなければ404
     */
    async submitSurveyAnswer(requestParameters: SubmitSurveyAnswerRequest): Promise<string> {
        const response = await this.submitSurveyAnswerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Survey IDとCustomerCreated IDからアンケートの回答を送信する。（QRコード提出）
     */
    async submitSurveyAnswerWithIdRaw(requestParameters: SubmitSurveyAnswerWithIdRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling submitSurveyAnswerWithId.');
        }

        if (requestParameters.submitSurveyAnswerWithCustomerCreatedIdRequestBody === null || requestParameters.submitSurveyAnswerWithCustomerCreatedIdRequestBody === undefined) {
            throw new runtime.RequiredError('submitSurveyAnswerWithCustomerCreatedIdRequestBody','Required parameter requestParameters.submitSurveyAnswerWithCustomerCreatedIdRequestBody was null or undefined when calling submitSurveyAnswerWithId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/survey-sheets`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitSurveyAnswerWithCustomerCreatedIdRequestBodyToJSON(requestParameters.submitSurveyAnswerWithCustomerCreatedIdRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Survey IDとCustomerCreated IDからアンケートの回答を送信する。（QRコード提出）
     */
    async submitSurveyAnswerWithId(requestParameters: SubmitSurveyAnswerWithIdRequest): Promise<string> {
        const response = await this.submitSurveyAnswerWithIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * AI文字起こしを確定する。
     */
    async submitTranscriptionRaw(requestParameters: SubmitTranscriptionRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.interviewRecordingId === null || requestParameters.interviewRecordingId === undefined) {
            throw new runtime.RequiredError('interviewRecordingId','Required parameter requestParameters.interviewRecordingId was null or undefined when calling submitTranscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/interview-file-transcriptions/{interviewRecordingId}/transcription/submit`.replace(`{${"interviewRecordingId"}}`, encodeURIComponent(String(requestParameters.interviewRecordingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * AI文字起こしを確定する。
     */
    async submitTranscription(requestParameters: SubmitTranscriptionRequest): Promise<string> {
        const response = await this.submitTranscriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビューのサマリーを生成する。
     */
    async summarizeExEmployeeInterviewRaw(requestParameters: SummarizeExEmployeeInterviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling summarizeExEmployeeInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}/summary`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * インタビューのサマリーを生成する。
     */
    async summarizeExEmployeeInterview(requestParameters: SummarizeExEmployeeInterviewRequest): Promise<void> {
        await this.summarizeExEmployeeInterviewRaw(requestParameters);
    }

    /**
     */
    async summarizeHrInterviewRaw(requestParameters: SummarizeHrInterviewRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.hrInterviewId === null || requestParameters.hrInterviewId === undefined) {
            throw new runtime.RequiredError('hrInterviewId','Required parameter requestParameters.hrInterviewId was null or undefined when calling summarizeHrInterview.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromTranscription !== undefined) {
            queryParameters['fromTranscription'] = requestParameters.fromTranscription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews/{hrInterviewId}/summarize`.replace(`{${"hrInterviewId"}}`, encodeURIComponent(String(requestParameters.hrInterviewId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async summarizeHrInterview(requestParameters: SummarizeHrInterviewRequest): Promise<string> {
        const response = await this.summarizeHrInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Cognito User Pool にあって、DB にないユーザを DB に追加する。
     */
    async syncCognitoUsersRaw(requestParameters: SyncCognitoUsersRequest): Promise<runtime.ApiResponse<SyncCognitoUsersResponse>> {
        if (requestParameters.syncCognitoUsersRequestBody === null || requestParameters.syncCognitoUsersRequestBody === undefined) {
            throw new runtime.RequiredError('syncCognitoUsersRequestBody','Required parameter requestParameters.syncCognitoUsersRequestBody was null or undefined when calling syncCognitoUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/users/sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncCognitoUsersRequestBodyToJSON(requestParameters.syncCognitoUsersRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncCognitoUsersResponseFromJSON(jsonValue));
    }

    /**
     * Cognito User Pool にあって、DB にないユーザを DB に追加する。
     */
    async syncCognitoUsers(requestParameters: SyncCognitoUsersRequest): Promise<SyncCognitoUsersResponse> {
        const response = await this.syncCognitoUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビューの確定を解除。 データベースやESのDocumentも消える。
     */
    async unfixExEmployeeInterviewRaw(requestParameters: UnfixExEmployeeInterviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exEmployeeInterviewId === null || requestParameters.exEmployeeInterviewId === undefined) {
            throw new runtime.RequiredError('exEmployeeInterviewId','Required parameter requestParameters.exEmployeeInterviewId was null or undefined when calling unfixExEmployeeInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v5/interview/ex-employee-interviews/{exEmployeeInterviewId}/analysis`.replace(`{${"exEmployeeInterviewId"}}`, encodeURIComponent(String(requestParameters.exEmployeeInterviewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * インタビューの確定を解除。 データベースやESのDocumentも消える。
     */
    async unfixExEmployeeInterview(requestParameters: UnfixExEmployeeInterviewRequest): Promise<void> {
        await this.unfixExEmployeeInterviewRaw(requestParameters);
    }

    /**
     * カテゴリを更新する。
     */
    async updateCategoryRaw(requestParameters: UpdateCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateCategory.');
        }

        if (requestParameters.updateCategoryRequestBody === null || requestParameters.updateCategoryRequestBody === undefined) {
            throw new runtime.RequiredError('updateCategoryRequestBody','Required parameter requestParameters.updateCategoryRequestBody was null or undefined when calling updateCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCategoryRequestBodyToJSON(requestParameters.updateCategoryRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * カテゴリを更新する。
     */
    async updateCategory(requestParameters: UpdateCategoryRequest): Promise<void> {
        await this.updateCategoryRaw(requestParameters);
    }

    /**
     * アンケートメール設定を更新する。
     */
    async updateEmailSettingsRaw(requestParameters: UpdateEmailSettingsRequest): Promise<runtime.ApiResponse<SettingResponse>> {
        if (requestParameters.updateEmailSettingsBody === null || requestParameters.updateEmailSettingsBody === undefined) {
            throw new runtime.RequiredError('updateEmailSettingsBody','Required parameter requestParameters.updateEmailSettingsBody was null or undefined when calling updateEmailSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/settings/emails`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmailSettingsBodyToJSON(requestParameters.updateEmailSettingsBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingResponseFromJSON(jsonValue));
    }

    /**
     * アンケートメール設定を更新する。
     */
    async updateEmailSettings(requestParameters: UpdateEmailSettingsRequest): Promise<SettingResponse> {
        const response = await this.updateEmailSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateEmployeeTenureRaw(requestParameters: UpdateEmployeeTenureRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.employeeTenureId === null || requestParameters.employeeTenureId === undefined) {
            throw new runtime.RequiredError('employeeTenureId','Required parameter requestParameters.employeeTenureId was null or undefined when calling updateEmployeeTenure.');
        }

        if (requestParameters.updateEmployeeTenureRequestBody === null || requestParameters.updateEmployeeTenureRequestBody === undefined) {
            throw new runtime.RequiredError('updateEmployeeTenureRequestBody','Required parameter requestParameters.updateEmployeeTenureRequestBody was null or undefined when calling updateEmployeeTenure.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/employee-master/employee-tenures/{employeeTenureId}`.replace(`{${"employeeTenureId"}}`, encodeURIComponent(String(requestParameters.employeeTenureId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeTenureRequestBodyToJSON(requestParameters.updateEmployeeTenureRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateEmployeeTenure(requestParameters: UpdateEmployeeTenureRequest): Promise<string> {
        const response = await this.updateEmployeeTenureRaw(requestParameters);
        return await response.value();
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async updateFileOfHrInterviewRaw(requestParameters: UpdateFileOfHrInterviewRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.hrInterviewId === null || requestParameters.hrInterviewId === undefined) {
            throw new runtime.RequiredError('hrInterviewId','Required parameter requestParameters.hrInterviewId was null or undefined when calling updateFileOfHrInterview.');
        }

        if (requestParameters.updateFileOfHrInterviewRequestBody === null || requestParameters.updateFileOfHrInterviewRequestBody === undefined) {
            throw new runtime.RequiredError('updateFileOfHrInterviewRequestBody','Required parameter requestParameters.updateFileOfHrInterviewRequestBody was null or undefined when calling updateFileOfHrInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews/{hrInterviewId}/interview-files`.replace(`{${"hrInterviewId"}}`, encodeURIComponent(String(requestParameters.hrInterviewId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFileOfHrInterviewRequestBodyToJSON(requestParameters.updateFileOfHrInterviewRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * WAFのSizeRestrictions_Queryのルールを無効にする設定をしているため、 パスを変更する際はAWS-CDK:AccountSecurityStackの設定も変更すること。
     */
    async updateFileOfHrInterview(requestParameters: UpdateFileOfHrInterviewRequest): Promise<string> {
        const response = await this.updateFileOfHrInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * グラフ目盛り設定を更新する
     */
    async updateGraphScaleSettingsRaw(requestParameters: UpdateGraphScaleSettingsRequest): Promise<runtime.ApiResponse<SettingResponse>> {
        if (requestParameters.updateGraphScaleSettingsBody === null || requestParameters.updateGraphScaleSettingsBody === undefined) {
            throw new runtime.RequiredError('updateGraphScaleSettingsBody','Required parameter requestParameters.updateGraphScaleSettingsBody was null or undefined when calling updateGraphScaleSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/settings/graph-scales`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGraphScaleSettingsBodyToJSON(requestParameters.updateGraphScaleSettingsBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SettingResponseFromJSON(jsonValue));
    }

    /**
     * グラフ目盛り設定を更新する
     */
    async updateGraphScaleSettings(requestParameters: UpdateGraphScaleSettingsRequest): Promise<SettingResponse> {
        const response = await this.updateGraphScaleSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateHrInterviewRaw(requestParameters: UpdateHrInterviewRequest): Promise<runtime.ApiResponse<HrInterviewResponse>> {
        if (requestParameters.hrInterviewId === null || requestParameters.hrInterviewId === undefined) {
            throw new runtime.RequiredError('hrInterviewId','Required parameter requestParameters.hrInterviewId was null or undefined when calling updateHrInterview.');
        }

        if (requestParameters.updateHrInterviewRequestBody === null || requestParameters.updateHrInterviewRequestBody === undefined) {
            throw new runtime.RequiredError('updateHrInterviewRequestBody','Required parameter requestParameters.updateHrInterviewRequestBody was null or undefined when calling updateHrInterview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interviews/{hrInterviewId}`.replace(`{${"hrInterviewId"}}`, encodeURIComponent(String(requestParameters.hrInterviewId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHrInterviewRequestBodyToJSON(requestParameters.updateHrInterviewRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HrInterviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateHrInterview(requestParameters: UpdateHrInterviewRequest): Promise<HrInterviewResponse> {
        const response = await this.updateHrInterviewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateHrInterviewFeedbackQuestionsRaw(requestParameters: UpdateHrInterviewFeedbackQuestionsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateHrInterviewFeedbackQuestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/feedbacks/questions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateHrInterviewFeedbackQuestions(requestParameters: UpdateHrInterviewFeedbackQuestionsRequest): Promise<void> {
        await this.updateHrInterviewFeedbackQuestionsRaw(requestParameters);
    }

    /**
     */
    async updateHrInterviewFileRaw(requestParameters: UpdateHrInterviewFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hrInterviewFileId === null || requestParameters.hrInterviewFileId === undefined) {
            throw new runtime.RequiredError('hrInterviewFileId','Required parameter requestParameters.hrInterviewFileId was null or undefined when calling updateHrInterviewFile.');
        }

        if (requestParameters.updateHrInterviewFileRequestBody === null || requestParameters.updateHrInterviewFileRequestBody === undefined) {
            throw new runtime.RequiredError('updateHrInterviewFileRequestBody','Required parameter requestParameters.updateHrInterviewFileRequestBody was null or undefined when calling updateHrInterviewFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-files/{hrInterviewFileId}`.replace(`{${"hrInterviewFileId"}}`, encodeURIComponent(String(requestParameters.hrInterviewFileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHrInterviewFileRequestBodyToJSON(requestParameters.updateHrInterviewFileRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateHrInterviewFile(requestParameters: UpdateHrInterviewFileRequest): Promise<void> {
        await this.updateHrInterviewFileRaw(requestParameters);
    }

    /**
     * インタビューフィードバックの設問を更新する
     */
    async updateInterviewFeedbackSettingsRaw(requestParameters: UpdateInterviewFeedbackSettingsOperationRequest): Promise<runtime.ApiResponse<InterviewFeedbackSettingsResponse>> {
        if (requestParameters.updateInterviewFeedbackSettingsRequest === null || requestParameters.updateInterviewFeedbackSettingsRequest === undefined) {
            throw new runtime.RequiredError('updateInterviewFeedbackSettingsRequest','Required parameter requestParameters.updateInterviewFeedbackSettingsRequest was null or undefined when calling updateInterviewFeedbackSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/interview-feedback`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInterviewFeedbackSettingsRequestToJSON(requestParameters.updateInterviewFeedbackSettingsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewFeedbackSettingsResponseFromJSON(jsonValue));
    }

    /**
     * インタビューフィードバックの設問を更新する
     */
    async updateInterviewFeedbackSettings(requestParameters: UpdateInterviewFeedbackSettingsOperationRequest): Promise<InterviewFeedbackSettingsResponse> {
        const response = await this.updateInterviewFeedbackSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビュー音声データと退職インタビューを結びつける。
     */
    async updateInterviewFileRaw(requestParameters: UpdateInterviewFileRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.interviewRecordingId === null || requestParameters.interviewRecordingId === undefined) {
            throw new runtime.RequiredError('interviewRecordingId','Required parameter requestParameters.interviewRecordingId was null or undefined when calling updateInterviewFile.');
        }

        if (requestParameters.updateInterviewFileRequestBody === null || requestParameters.updateInterviewFileRequestBody === undefined) {
            throw new runtime.RequiredError('updateInterviewFileRequestBody','Required parameter requestParameters.updateInterviewFileRequestBody was null or undefined when calling updateInterviewFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/interview-files/{interviewRecordingId}`.replace(`{${"interviewRecordingId"}}`, encodeURIComponent(String(requestParameters.interviewRecordingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInterviewFileRequestBodyToJSON(requestParameters.updateInterviewFileRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * インタビュー音声データと退職インタビューを結びつける。
     */
    async updateInterviewFile(requestParameters: UpdateInterviewFileRequest): Promise<string> {
        const response = await this.updateInterviewFileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateInterviewFormatRaw(requestParameters: UpdateInterviewFormatRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.interviewFormatId === null || requestParameters.interviewFormatId === undefined) {
            throw new runtime.RequiredError('interviewFormatId','Required parameter requestParameters.interviewFormatId was null or undefined when calling updateInterviewFormat.');
        }

        if (requestParameters.updateInterviewFormatRequestBody === null || requestParameters.updateInterviewFormatRequestBody === undefined) {
            throw new runtime.RequiredError('updateInterviewFormatRequestBody','Required parameter requestParameters.updateInterviewFormatRequestBody was null or undefined when calling updateInterviewFormat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/hr-interview/interview-formats/{interviewFormatId}`.replace(`{${"interviewFormatId"}}`, encodeURIComponent(String(requestParameters.interviewFormatId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInterviewFormatRequestBodyToJSON(requestParameters.updateInterviewFormatRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateInterviewFormat(requestParameters: UpdateInterviewFormatRequest): Promise<string> {
        const response = await this.updateInterviewFormatRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateNoteRaw(requestParameters: UpdateNoteRequest): Promise<runtime.ApiResponse<NoteResponse>> {
        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling updateNote.');
        }

        if (requestParameters.updateNoteRequestBody === null || requestParameters.updateNoteRequestBody === undefined) {
            throw new runtime.RequiredError('updateNoteRequestBody','Required parameter requestParameters.updateNoteRequestBody was null or undefined when calling updateNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/notes/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNoteRequestBodyToJSON(requestParameters.updateNoteRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateNote(requestParameters: UpdateNoteRequest): Promise<NoteResponse> {
        const response = await this.updateNoteRaw(requestParameters);
        return await response.value();
    }

    /**
     * ルールを更新する。
     */
    async updateRuleRaw(requestParameters: UpdateRuleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ruleId === null || requestParameters.ruleId === undefined) {
            throw new runtime.RequiredError('ruleId','Required parameter requestParameters.ruleId was null or undefined when calling updateRule.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateRule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/rules/{ruleId}`.replace(`{${"ruleId"}}`, encodeURIComponent(String(requestParameters.ruleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ルールを更新する。
     */
    async updateRule(requestParameters: UpdateRuleRequest): Promise<void> {
        await this.updateRuleRaw(requestParameters);
    }

    /**
     * ユーザー入力セグメント定義を更新する。 入力値とマッピング先、使用不可フラグ TODO: 要追加実装: mappedToが変わると、ESへのinputも変わるのでrebuildが必要
     */
    async updateSegmentDefinitionRaw(requestParameters: UpdateSegmentDefinitionRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.segmentKey === null || requestParameters.segmentKey === undefined) {
            throw new runtime.RequiredError('segmentKey','Required parameter requestParameters.segmentKey was null or undefined when calling updateSegmentDefinition.');
        }

        if (requestParameters.inputSegmentDefinitionId === null || requestParameters.inputSegmentDefinitionId === undefined) {
            throw new runtime.RequiredError('inputSegmentDefinitionId','Required parameter requestParameters.inputSegmentDefinitionId was null or undefined when calling updateSegmentDefinition.');
        }

        if (requestParameters.editSegmentDefinitionRequestBody === null || requestParameters.editSegmentDefinitionRequestBody === undefined) {
            throw new runtime.RequiredError('editSegmentDefinitionRequestBody','Required parameter requestParameters.editSegmentDefinitionRequestBody was null or undefined when calling updateSegmentDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/settings/segments/{segmentKey}/definitions/{inputSegmentDefinitionId}`.replace(`{${"segmentKey"}}`, encodeURIComponent(String(requestParameters.segmentKey))).replace(`{${"inputSegmentDefinitionId"}}`, encodeURIComponent(String(requestParameters.inputSegmentDefinitionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditSegmentDefinitionRequestBodyToJSON(requestParameters.editSegmentDefinitionRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * ユーザー入力セグメント定義を更新する。 入力値とマッピング先、使用不可フラグ TODO: 要追加実装: mappedToが変わると、ESへのinputも変わるのでrebuildが必要
     */
    async updateSegmentDefinition(requestParameters: UpdateSegmentDefinitionRequest): Promise<boolean> {
        const response = await this.updateSegmentDefinitionRaw(requestParameters);
        return await response.value();
    }

    /**
     * サブカテゴリの情報を更新する。
     */
    async updateSubCategoryRaw(requestParameters: UpdateSubCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subCategoryId === null || requestParameters.subCategoryId === undefined) {
            throw new runtime.RequiredError('subCategoryId','Required parameter requestParameters.subCategoryId was null or undefined when calling updateSubCategory.');
        }

        if (requestParameters.updateSubCategoryRequestBody === null || requestParameters.updateSubCategoryRequestBody === undefined) {
            throw new runtime.RequiredError('updateSubCategoryRequestBody','Required parameter requestParameters.updateSubCategoryRequestBody was null or undefined when calling updateSubCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/sub-categories/{subCategoryId}`.replace(`{${"subCategoryId"}}`, encodeURIComponent(String(requestParameters.subCategoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubCategoryRequestBodyToJSON(requestParameters.updateSubCategoryRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * サブカテゴリの情報を更新する。
     */
    async updateSubCategory(requestParameters: UpdateSubCategoryRequest): Promise<void> {
        await this.updateSubCategoryRaw(requestParameters);
    }

    /**
     * アンケートを更新する。
     */
    async updateSurveyRaw(requestParameters: UpdateSurveyRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurvey.');
        }

        if (requestParameters.updateSurveyRequestBody === null || requestParameters.updateSurveyRequestBody === undefined) {
            throw new runtime.RequiredError('updateSurveyRequestBody','Required parameter requestParameters.updateSurveyRequestBody was null or undefined when calling updateSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSurveyRequestBodyToJSON(requestParameters.updateSurveyRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * アンケートを更新する。
     */
    async updateSurvey(requestParameters: UpdateSurveyRequest): Promise<string> {
        const response = await this.updateSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     * アンケートのステータスを更新する。
     */
    async updateSurveyStatusRaw(requestParameters: UpdateSurveyStatusRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurveyStatus.');
        }

        if (requestParameters.updateSurveyStatusRequestBody === null || requestParameters.updateSurveyStatusRequestBody === undefined) {
            throw new runtime.RequiredError('updateSurveyStatusRequestBody','Required parameter requestParameters.updateSurveyStatusRequestBody was null or undefined when calling updateSurveyStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/survey/surveys/{surveyId}/status`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSurveyStatusRequestBodyToJSON(requestParameters.updateSurveyStatusRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * アンケートのステータスを更新する。
     */
    async updateSurveyStatus(requestParameters: UpdateSurveyStatusRequest): Promise<string> {
        const response = await this.updateSurveyStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * インタビュー音声データの文字起こしを更新する。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async updateTranscriptionRaw(requestParameters: UpdateTranscriptionRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.interviewRecordingId === null || requestParameters.interviewRecordingId === undefined) {
            throw new runtime.RequiredError('interviewRecordingId','Required parameter requestParameters.interviewRecordingId was null or undefined when calling updateTranscription.');
        }

        if (requestParameters.updateTranscriptionRequestBody === null || requestParameters.updateTranscriptionRequestBody === undefined) {
            throw new runtime.RequiredError('updateTranscriptionRequestBody','Required parameter requestParameters.updateTranscriptionRequestBody was null or undefined when calling updateTranscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/interview/interview-file-transcriptions/{interviewRecordingId}/transcription`.replace(`{${"interviewRecordingId"}}`, encodeURIComponent(String(requestParameters.interviewRecordingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTranscriptionRequestBodyToJSON(requestParameters.updateTranscriptionRequestBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * インタビュー音声データの文字起こしを更新する。 wafのSizeRestrictions_Bodyのルールを無効にする設定をしているため、パスを変更する際はAWS-CDKの設定も変更すること。
     */
    async updateTranscription(requestParameters: UpdateTranscriptionRequest): Promise<string> {
        const response = await this.updateTranscriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー情報更新
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }

        if (requestParameters.updateUserRequestBody === null || requestParameters.updateUserRequestBody === undefined) {
            throw new runtime.RequiredError('updateUserRequestBody','Required parameter requestParameters.updateUserRequestBody was null or undefined when calling updateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestBodyToJSON(requestParameters.updateUserRequestBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザー情報更新
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<void> {
        await this.updateUserRaw(requestParameters);
    }

    /**
     * ユーザグループの設定を更新する。
     */
    async updateUserGroupRaw(requestParameters: UpdateUserGroupRequest): Promise<runtime.ApiResponse<UserGroupResponse>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling updateUserGroup.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/settings/user-groups/{userGroupId}`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResponseFromJSON(jsonValue));
    }

    /**
     * ユーザグループの設定を更新する。
     */
    async updateUserGroup(requestParameters: UpdateUserGroupRequest): Promise<UserGroupResponse> {
        const response = await this.updateUserGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async upsertEmployeeAttributesRaw(requestParameters: UpsertEmployeeAttributesRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.employeeTenureId === null || requestParameters.employeeTenureId === undefined) {
            throw new runtime.RequiredError('employeeTenureId','Required parameter requestParameters.employeeTenureId was null or undefined when calling upsertEmployeeAttributes.');
        }

        if (requestParameters.addEmployeeAttributesBody === null || requestParameters.addEmployeeAttributesBody === undefined) {
            throw new runtime.RequiredError('addEmployeeAttributesBody','Required parameter requestParameters.addEmployeeAttributesBody was null or undefined when calling upsertEmployeeAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v5/employee-master/employee-tenures/{employeeTenureId}/attributes`.replace(`{${"employeeTenureId"}}`, encodeURIComponent(String(requestParameters.employeeTenureId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddEmployeeAttributesBodyToJSON(requestParameters.addEmployeeAttributesBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async upsertEmployeeAttributes(requestParameters: UpsertEmployeeAttributesRequest): Promise<string> {
        const response = await this.upsertEmployeeAttributesRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum RestoreInterviewAttributeTargetEnum {
    ALL = 'all',
    UNFIXED = 'unfixed'
}
