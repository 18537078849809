/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HrInterviewFeedbackQuestionResponse,
    HrInterviewFeedbackQuestionResponseFromJSON,
    HrInterviewFeedbackQuestionResponseFromJSONTyped,
    HrInterviewFeedbackQuestionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewFeedbackQuestionListResponse
 */
export interface HrInterviewFeedbackQuestionListResponse {
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFeedbackQuestionListResponse
     */
    surveyTitle: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFeedbackQuestionListResponse
     */
    surveyBody: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HrInterviewFeedbackQuestionListResponse
     */
    evaluationItems: Array<string>;
    /**
     * 
     * @type {Array<HrInterviewFeedbackQuestionResponse>}
     * @memberof HrInterviewFeedbackQuestionListResponse
     */
    questions: Array<HrInterviewFeedbackQuestionResponse>;
}

export function HrInterviewFeedbackQuestionListResponseFromJSON(json: any): HrInterviewFeedbackQuestionListResponse {
    return HrInterviewFeedbackQuestionListResponseFromJSONTyped(json, false);
}

export function HrInterviewFeedbackQuestionListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewFeedbackQuestionListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyTitle': json['surveyTitle'],
        'surveyBody': json['surveyBody'],
        'evaluationItems': json['evaluationItems'],
        'questions': ((json['questions'] as Array<any>).map(HrInterviewFeedbackQuestionResponseFromJSON)),
    };
}

export function HrInterviewFeedbackQuestionListResponseToJSON(value?: HrInterviewFeedbackQuestionListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyTitle': value.surveyTitle,
        'surveyBody': value.surveyBody,
        'evaluationItems': value.evaluationItems,
        'questions': ((value.questions as Array<any>).map(HrInterviewFeedbackQuestionResponseToJSON)),
    };
}


