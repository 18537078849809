/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateInterviewAnswersParam,
    UpdateInterviewAnswersParamFromJSON,
    UpdateInterviewAnswersParamFromJSONTyped,
    UpdateInterviewAnswersParamToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateHrInterviewRequestBody
 */
export interface UpdateHrInterviewRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateHrInterviewRequestBody
     */
    userGroupId?: string | null;
    /**
     * 
     * @type {Array<UpdateInterviewAnswersParam>}
     * @memberof UpdateHrInterviewRequestBody
     */
    interviewFormatAnswers?: Array<UpdateInterviewAnswersParam>;
    /**
     * 
     * @type {string}
     * @memberof UpdateHrInterviewRequestBody
     */
    followUpMemo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHrInterviewRequestBody
     */
    followUpIsRequired?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UpdateHrInterviewRequestBody
     */
    interviewDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateHrInterviewRequestBody
     */
    interviewFormatId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHrInterviewRequestBody
     */
    employeeTenureId?: string;
}

export function UpdateHrInterviewRequestBodyFromJSON(json: any): UpdateHrInterviewRequestBody {
    return UpdateHrInterviewRequestBodyFromJSONTyped(json, false);
}

export function UpdateHrInterviewRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateHrInterviewRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroupId': !exists(json, 'userGroupId') ? undefined : json['userGroupId'],
        'interviewFormatAnswers': !exists(json, 'interviewFormatAnswers') ? undefined : ((json['interviewFormatAnswers'] as Array<any>).map(UpdateInterviewAnswersParamFromJSON)),
        'followUpMemo': !exists(json, 'followUpMemo') ? undefined : json['followUpMemo'],
        'followUpIsRequired': !exists(json, 'followUpIsRequired') ? undefined : json['followUpIsRequired'],
        'interviewDate': !exists(json, 'interviewDate') ? undefined : (new Date(json['interviewDate'])),
        'interviewFormatId': !exists(json, 'interviewFormatId') ? undefined : json['interviewFormatId'],
        'employeeTenureId': !exists(json, 'employeeTenureId') ? undefined : json['employeeTenureId'],
    };
}

export function UpdateHrInterviewRequestBodyToJSON(value?: UpdateHrInterviewRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userGroupId': value.userGroupId,
        'interviewFormatAnswers': value.interviewFormatAnswers === undefined ? undefined : ((value.interviewFormatAnswers as Array<any>).map(UpdateInterviewAnswersParamToJSON)),
        'followUpMemo': value.followUpMemo,
        'followUpIsRequired': value.followUpIsRequired,
        'interviewDate': value.interviewDate === undefined ? undefined : (value.interviewDate.toISOString()),
        'interviewFormatId': value.interviewFormatId,
        'employeeTenureId': value.employeeTenureId,
    };
}


