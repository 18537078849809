import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { AmeBox } from "../../../muiWrapper/AmeBox";
import { CheckingRetirementRate } from "../../../organisms/home/CheckingRetimentRate";
import { Shortcuts } from "../../../organisms/home/Shortcuts";
import { StartingAnalysis } from "../../../organisms/home/StartingAnalysis";

export const HomePage: React.FC = () => {
  return (
    <AmeBox sx={{ display: "flex", gap: "48px", alignItems: "flex-start" }}>
      <AmeBox sx={{ flexBasis: "288px", flexShrink: 0, flexGrow: 0 }}>
        <Shortcuts />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1 }}>
        <AmeBox sx={{ paddingBottom: "24px" }}>
          <CheckingRetirementRate />
        </AmeBox>
        <AmeBox sx={{ paddingBottom: "24px" }}>
          <StartingAnalysis />
        </AmeBox>
      </AmeBox>
    </AmeBox>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/")({
  component: HomePage,
});
