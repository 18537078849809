import React from "react";

import { useDownloadHrInterviewFile } from "../../../../../../hooks/useDownloadInterviewFile";
import { useSp } from "../../../../../../hooks/useSp";
import { HrInterviewFile } from "../../../../../../models/hrInterview/HrInterviewFile";
import { formatDateToYYYYMMDD } from "../../../../../../utils/formatter";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { AudioFile } from "../../../../../atoms/icon/AudioFile";
import { CheckV2Icon } from "../../../../../atoms/icon/CheckV2";
import { Elipse } from "../../../../../atoms/icon/Elipse";
import { DefinitionList } from "../../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../../atoms/list/DefinitionList/DefinitionListItem";
import { IconText } from "../../../../../molecules/common/IconText";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  interviewFile: HrInterviewFile;
}

export const InterviewFileContent: React.FC<Props> = ({ interviewFile }) => {
  const sp = useSp();
  const downloadInterviewFile = useDownloadHrInterviewFile();
  return (
    <AmeBox>
      <DefinitionList>
        <DefinitionListItem
          label="ステータス"
          value={
            interviewFile.hrInterview ? (
              <IconText icon={<CheckV2Icon color={"success"} />}>面談紐づけ済</IconText>
            ) : (
              <IconText icon={<Elipse />}>面談紐づけ前</IconText>
            )
          }
        />
      </DefinitionList>
      <DefinitionList>
        <DefinitionListItem label="音声ファイル名称" value={interviewFile.originalFileName} />
      </DefinitionList>
      <DefinitionList>
        <DefinitionListItem label="アップロード日" value={formatDateToYYYYMMDD(interviewFile.createdAt)} />
      </DefinitionList>
      <DefinitionList>
        <DefinitionListItem label="登録者" value={interviewFile.uploadUser?.getFullName()} />
      </DefinitionList>
      <DefinitionList>
        <DefinitionListItem label="グループ" value={interviewFile.userGroup?.displayName} />
      </DefinitionList>
      <AmeBox sx={{ marginTop: "16px", display: "flex", justifyContent: "end" }}>
        <AmeButton
          onClick={async () => {
            await downloadInterviewFile(interviewFile.hrInterviewFileId);
          }}
          variant={"outlined"}
          color={"basic"}
          size={sp ? "medium" : "small"}
          fullWidth={sp}
          startIcon={<AudioFile color={"black"} />}
        >
          音声データ
        </AmeButton>
      </AmeBox>
    </AmeBox>
  );
};
