interface CalculationCondition {
    lower: number;
    upper: number;
    mappedTo: string;
}
export type CalculationConditions = CalculationCondition[];
type CalculationStrategy = { conditions: CalculationConditions; displayName: string };
export type CalculationStrategyMap = { DEFAULT: CalculationStrategy } & Record<string, CalculationStrategy>;
export const AGE_CONDITIONS_OPTIONS: CalculationStrategyMap = {
    DEFAULT: {
        conditions: [
            { lower: -Infinity, upper: 30, mappedTo: "20代以下" },
            { lower: 30, upper: 40, mappedTo: "30代" },
            { lower: 40, upper: 50, mappedTo: "40代" },
            { lower: 50, upper: 60, mappedTo: "50代" },
            { lower: 60, upper: Infinity, mappedTo: "60代以上" },
        ],
        displayName: "10歳刻み",
    },
};

export const MONTHS_OF_SERVICE_CONDITIONS_OPTIONS: CalculationStrategyMap = {
    DEFAULT: {
        conditions: [
            { lower: -Infinity, upper: 6, mappedTo: "半年未満" },
            { lower: 6, upper: 12, mappedTo: "半年〜1年" },
            { lower: 12, upper: 36, mappedTo: "1~3年" },
            { lower: 36, upper: 60, mappedTo: "3~5年" },
            { lower: 60, upper: Infinity, mappedTo: "5年以上" },
        ],
        displayName: "短期",
    },
    MIDDLE: {
        conditions: [
            { lower: -Infinity, upper: 12, mappedTo: "1年未満" },
            { lower: 12, upper: 36, mappedTo: "1~3年" },
            { lower: 36, upper: 60, mappedTo: "3~5年" },
            { lower: 60, upper: 120, mappedTo: "5~10年" },
            { lower: 120, upper: Infinity, mappedTo: "10年以上" },
        ],
        displayName: "中期",
    },
    LONG: {
        conditions: [
            { lower: -Infinity, upper: 36, mappedTo: "3年未満" },
            { lower: 36, upper: 60, mappedTo: "3~5年" },
            { lower: 60, upper: 120, mappedTo: "5~10年" },
            { lower: 120, upper: 240, mappedTo: "10~20年" },
            { lower: 240, upper: Infinity, mappedTo: "20年以上" },
        ],
        displayName: "長期",
    },
};
