import React from "react";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeCheckbox } from "../../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { ChoiceOptionParams } from "./type";

export const ChoiceOption: React.FC<ChoiceOptionParams> = React.memo(({ isChecked, name, toggleOption }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{ display: "flex", border: 0, alignItems: "center", padding: "8px 16px", cursor: "pointer" }}
      onClick={toggleOption}
    >
      <AmeCheckbox checked={isChecked}></AmeCheckbox>
      <Spacer width="8px"></Spacer>
      <AmeBox
        component="p"
        sx={{
          fontFamily: "'Noto Sans CJK JP', sans-serif",
          color: theme.common.text.black,
          fontSize: theme.typography.body2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          lineHeight: "unset",
        }}
      >
        {name}
      </AmeBox>
    </AmeBox>
  );
});

ChoiceOption.displayName = ChoiceOption.name;
