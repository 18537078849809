import { useMemo } from "react";

import { createTheme } from "@mui/material";

const AME_THEME: AmeTheme = {
  brand: {
    primary: { 100: "#FFBE04", 40: "#FFF8E1", 120: "#C79102", contrastText: "#FFF" },
    secondary: {
      100: "#00ACC1",
      40: "#B2EBF2",
      20: "#EAF5F5",
      120: "#016878",
      110: "#ADC2C5",
      contrastText: "#FFF",
    },
    accent: { pink: { 100: "#F06292", 40: "#F8BACF", 20: "#FCE4EC" }, surveyBlue: { 100: "#6994BB" } },
  },
  common: {
    text: {
      black: "#3C4956",
      gray40: "#666666",
      gray20: "#C3C3C3",
      warning: "#FF4A49",
      link: "#1A6EE2",
      success: "#34C759",
      white: "#FFF",
      error: "#EC0000",
    },
    background: {
      white: "#FFF",
      warning120: "#97272D",
      warning100: "#FF4A49",
      warning40: "#FFECEC",
      warning20: "#FFF3F3",
      success40: "#E0F5E5",
      gray40: "#EFEFEF",
      gray20: "#F8F9FA",
      gray120: "#C3C3C3",
    },
    shadow: { main: "0px 1px 5px 0px rgba(46,62,79,0.1)" },
    border: { black: "#3C4956", gray40: "#DDDDDD", success: "#34C759", warning: "#FF4A49", error: "#EC0000" },
  },
  typography: {
    h1: { fontSize: "42px", lineHeight: "63px", fontWeight: 700, letterSpacing: "0.04em" },
    h2: { fontSize: "32px", lineHeight: "38px", fontWeight: 700, letterSpacing: "0.04em" },
    h3: { fontSize: "20px", lineHeight: "39px", fontWeight: 700, letterSpacing: "0.04em" },
    h4: { fontSize: "22px", lineHeight: "33px", fontWeight: 700, letterSpacing: "0.04em" },
    h5: { fontSize: "20px", lineHeight: "30px", fontWeight: 700, letterSpacing: "0.04em" },
    h6: { fontSize: "18px", lineHeight: "27px", fontWeight: 700, letterSpacing: "0.04em" },
    body1: { fontSize: "16px", lineHeight: "28px", fontWeight: 400, letterSpacing: "0.04em" },
    body2: { fontSize: "14px", lineHeight: "24.5px", fontWeight: 400, letterSpacing: "0.04em" },
    caption: { fontSize: "12px", lineHeight: "18px", fontWeight: 400, letterSpacing: "0.04em" },
    button: { fontSize: "16px", lineHeight: "24px", fontWeight: 700, letterSpacing: "0.04em" },
  },
  zIndex: {
    header: 900,
    sidebar: 5000,
    modal: 10000,
    popoverLayer: 50000,
    tooltip: 75000,
    snackbar: 100000,
  },
};
export const useAmeTheme = (): AmeTheme => {
  return useMemo(() => AME_THEME, []);
};

export const customizedMuiTheme = createTheme({
  palette: {
    primary: {
      main: AME_THEME.brand.primary[100],
      light: AME_THEME.brand.primary[40],
      dark: AME_THEME.brand.primary[120],
      contrastText: AME_THEME.brand.primary.contrastText,
    },
    secondary: {
      main: AME_THEME.brand.secondary[100],
      light: AME_THEME.brand.secondary[40],
      dark: AME_THEME.brand.secondary[120],
      contrastText: AME_THEME.brand.secondary.contrastText,
    },
    text: {
      primary: AME_THEME.common.text.black,
      secondary: AME_THEME.common.text.gray40,
    },
    error: {
      main: AME_THEME.common.text.error,
    },
  },
  typography: {
    h1: { ...AME_THEME.typography.h1, color: AME_THEME.common.text.black },
    h2: { ...AME_THEME.typography.h2, color: AME_THEME.common.text.black },
    h3: { ...AME_THEME.typography.h3, color: AME_THEME.common.text.black },
    h4: { ...AME_THEME.typography.h4, color: AME_THEME.common.text.black },
    h5: { ...AME_THEME.typography.h5, color: AME_THEME.common.text.black },
    h6: { ...AME_THEME.typography.h6, color: AME_THEME.common.text.black },
    body1: { ...AME_THEME.typography.body1, color: AME_THEME.common.text.black },
    body2: { ...AME_THEME.typography.body2, color: AME_THEME.common.text.black },
    button: { ...AME_THEME.typography.button, color: AME_THEME.common.text.black },
  },
});

export type AmeTheme = {
  brand: Brand;
  common: Common;
  typography: Typography;
  zIndex: ZIndex;
};

type ZIndex = {
  header: number;
  sidebar: number;
  modal: number;
  popoverLayer: number;
  tooltip: number;
  snackbar: number;
};

type Brand = {
  primary: BrandColor;
  secondary: BrandColor;
  accent: Accent;
};

type Accent = {
  pink: BrandColor;
  surveyBlue: BrandColor;
};

type Common = {
  text: TextColor;
  background: BackgroundColor;
  shadow: ShadowStyle;
  border: BorderColor;
};

type FontStyle = {
  fontSize: `${number}px`;
  lineHeight: `${number}px`;
  fontWeight: number;
  letterSpacing: `${number}em`;
};

type Typography = {
  body1: FontStyle;
  body2: FontStyle;
  caption: FontStyle;
  button: FontStyle;
  h1: FontStyle;
  h2: FontStyle;
  h3: FontStyle;
  h4: FontStyle;
  h5: FontStyle;
  h6: FontStyle;
};

type BrandColor = {
  120?: string;
  110?: string;
  100: string;
  40?: string;
  20?: string;
  contrastText?: string;
};

type TextColor = {
  black: string;
  gray20: string;
  gray40: string;
  white: string;
  warning: string;
  link: string;
  success: string;
  error: string;
};

type BackgroundColor = {
  white: string;
  gray120: string;
  gray40: string;
  gray20: string;
  warning120: string;
  warning100: string;
  warning40: string;
  warning20: string;
  success40: string;
};

type ShadowStyle = {
  main: string;
};

type BorderColor = {
  black: string;
  gray40: string;
  success: string;
  warning: string;
  error: string;
};
