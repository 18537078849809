import React, { useContext, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { usePeriodsQuery } from "../../../../../store/hooks/periods";
import { useRestoreInterviewAttributeMutation } from "../../../../../store/hooks/settings";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeCheckbox } from "../../../../atoms/checkbox/AmeCheckbox";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { ChoosableList } from "../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../atoms/list/ChoosableListItem";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeDropbox_old } from "../../../../molecules/dropbox/AmeDropbox";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { RebuildDuration } from "../../../../organisms/settings/rebuild/RebuildDuration";

const Rebuild = () => {
  const [targetAll, setTargetAll] = useState(false);
  const rebuildInterviewAttribute = useRestoreInterviewAttributeMutation();
  const periodsQuery = usePeriodsQuery();
  const { period } = useContext(DataSourceContext);
  const [selectedPeriod, setSelectedPeriod] = useState(period.periodId);
  return (
    <PaperWrapper>
      <BreadcrumbPageLayoutHeader items={[{ title: "設定", to: "/settings" }]} currentTitle="rebuild" />
      <PaperBody>
        <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <AmeBox sx={{ width: "55%" }}>
            <PaperWrapper>
              <PaperHeader>インタビューの従業員属性のアップデート</PaperHeader>
              <PaperBody>
                <WaitForSuccess queryState={periodsQuery}>
                  {(periods) => (
                    <>
                      <AmeBox sx={{ display: "flex", alignItems: "center" }}>
                        <label>どの期間の情報でアップデートする？</label>
                        <AmeDropbox_old
                          value={selectedPeriod}
                          displaySelected={periods.find((p) => p.periodId === selectedPeriod)?.getDisplayName()}
                          onChange={(e) => setSelectedPeriod(e.target.value as number)}
                          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        >
                          <ChoosableList>
                            {periods.map((p) => (
                              <ChoosableListItem key={p.periodId} value={p.periodId}>
                                {p.getDisplayName()}
                              </ChoosableListItem>
                            ))}
                          </ChoosableList>
                        </AmeDropbox_old>
                      </AmeBox>
                      <AmeBox sx={{ display: "flex", alignItems: "center" }}>
                        <label htmlFor={"targetAll"}>すべてのインタビューを対象にする</label>
                        <AmeCheckbox
                          id={"targetAll"}
                          checked={targetAll}
                          onChange={(e) => setTargetAll(e.target.checked)}
                        />
                      </AmeBox>
                      <AmeButton
                        onClick={async () => {
                          await rebuildInterviewAttribute({
                            periodId: selectedPeriod,
                            target: targetAll ? "all" : "unfixed",
                          });
                        }}
                      >
                        rebuild
                      </AmeButton>
                    </>
                  )}
                </WaitForSuccess>
              </PaperBody>
            </PaperWrapper>
            <Spacer height="36px" />
            <AmeBox sx={{ width: "100%" }}>
              <RebuildDuration />
            </AmeBox>
          </AmeBox>
        </AmeBox>
      </PaperBody>
    </PaperWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/rebuild")({
  component: Rebuild,
});
