/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HrInterviewFeedbackStatus {
    UNSENT = 'UNSENT',
    ANSWERED = 'ANSWERED',
    UNANSWERED = 'UNANSWERED'
}

export function HrInterviewFeedbackStatusFromJSON(json: any): HrInterviewFeedbackStatus {
    return HrInterviewFeedbackStatusFromJSONTyped(json, false);
}

export function HrInterviewFeedbackStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewFeedbackStatus {
    return json as HrInterviewFeedbackStatus;
}

export function HrInterviewFeedbackStatusToJSON(value?: HrInterviewFeedbackStatus | null): any {
    return value as any;
}

