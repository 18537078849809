import React from "react";

import { requireNonEmpty } from "@amecloud/common-utils/src/utils/Assertions";
import { createFileRoute } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";

import { useDownloadInterviewFile } from "../../../../../../hooks/useDownloadInterviewFile";
import { useSp } from "../../../../../../hooks/useSp";
import { InterviewFile } from "../../../../../../models/InterviewFile";
import { useGetInterviewFileQuery } from "../../../../../../store/hooks/interview-files";
import { formatDate } from "../../../../../../utils/formatter";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { AudioFile } from "../../../../../atoms/icon/AudioFile";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { useFileStatusIcon } from "../../../../../organisms/interview-files/index/InterviewFileTable/InterviewFileStatusCell";
import { InterviewRecordingDeletingButtonWithDialog } from "../../../../../organisms/interview-files/interviewRecordingId/InterviewRecordingDeletingDialog";
import { InterviewRecordingDetailItem } from "../../../../../organisms/interview-files/interviewRecordingId/InterviewRecordingDetailItem";

export const InterviewRecordingDetailPage: React.FC = () => {
  const { interviewRecordingId } = Route.useParams();
  const interviewFileQuery = useGetInterviewFileQuery(requireNonEmpty(interviewRecordingId));
  const downloadInterviewFile = useDownloadInterviewFile();
  return (
    <WaitForSuccess queryState={interviewFileQuery}>
      {(interviewFile) => (
        <InterviewRecordingDetailPageContent
          interviewFile={interviewFile}
          downloadInterviewFile={downloadInterviewFile}
        />
      )}
    </WaitForSuccess>
  );
};

interface Props {
  interviewFile: InterviewFile;
  downloadInterviewFile: (interviewFileId: string) => Promise<void>;
}

const InterviewRecordingDetailPageContent: React.FC<Props> = (props) => {
  const icon = useFileStatusIcon(props.interviewFile.getStatus());
  const sp = useSp();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "音声データ一覧", to: "/interview-files" }]}
        currentTitle="音声データ詳細"
      />
      <PageLayoutBody>
        <Spacer height="12px" />
        <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <AmeBox sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px" }}>
            <AmeBox sx={{ marginRight: "auto" }}>
              <AmeTypography component="h3">{props.interviewFile.originalFileName}</AmeTypography>
            </AmeBox>
            <AmeButton
              variant="outlined"
              to={`/interview-files/$interviewRecordingId/edit`}
              params={{ interviewRecordingId: props.interviewFile.interviewRecordingId }}
            >
              編集
            </AmeButton>
            <InterviewRecordingDeletingButtonWithDialog
              interviewRecordingId={props.interviewFile.interviewRecordingId}
              disabled={props.interviewFile.getStatus() === "CHECKED"}
            />
          </AmeBox>
          <Spacer height="32px" />
          <PaperWrapper>
            <PaperHeader>音声データ詳細</PaperHeader>
            <PaperBody>
              <InterviewRecordingDetailItem label="ステータス">
                <AmeBox
                  component="span"
                  sx={{
                    display: "inline-flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {icon}
                  <AmeTypography fontSize="body2">{props.interviewFile.getStatusText()}</AmeTypography>
                </AmeBox>
              </InterviewRecordingDetailItem>
              {props.interviewFile.exEmployeeInterviewId ? (
                <AmeBox sx={{ paddingTop: "16px" }}>
                  <InterviewRecordingDetailItem label="インタビュー詳細ページ">
                    <Link
                      to={"/interviews/$interviewId"}
                      params={{ interviewId: props.interviewFile.exEmployeeInterviewId }}
                    >
                      <AmeTypography color={"secondary"}>リンク</AmeTypography>
                    </Link>
                  </InterviewRecordingDetailItem>
                </AmeBox>
              ) : null}
              <AmeBox sx={{ paddingTop: "16px" }}>
                <InterviewRecordingDetailItem label="アップロード日">
                  {formatDate(props.interviewFile.createdAt)}
                </InterviewRecordingDetailItem>
              </AmeBox>
              <AmeBox sx={{ paddingTop: "16px" }}>
                <InterviewRecordingDetailItem label="紐付き先従業員番号">
                  {props.interviewFile.employee?.customerCreatedId ?? "選択なし"}
                </InterviewRecordingDetailItem>
              </AmeBox>
              <AmeBox sx={{ marginTop: "16px" }}>
                <InterviewRecordingDetailItem label="登録者">
                  {props.interviewFile.interviewer?.getFullName() || "不明なユーザー"}
                </InterviewRecordingDetailItem>
              </AmeBox>
              <AmeBox sx={{ marginTop: "16px" }}>
                <InterviewRecordingDetailItem label="グループ">
                  {props.interviewFile.userGroup
                    ? (props.interviewFile.userGroup.displayName ?? "不明なユーザーグループ")
                    : "グループなし"}
                </InterviewRecordingDetailItem>
              </AmeBox>
              <AmeBox sx={{ marginTop: "16px", display: "flex", justifyContent: "end" }}>
                <AmeButton
                  onClick={async () => {
                    await props.downloadInterviewFile(props.interviewFile.interviewRecordingId);
                  }}
                  variant={"outlined"}
                  color={"basic"}
                  size={sp ? "medium" : "small"}
                  fullWidth={sp}
                  startIcon={<AudioFile color={"black"} />}
                >
                  音声データ
                </AmeButton>
              </AmeBox>
            </PaperBody>
          </PaperWrapper>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/interview-files/$interviewRecordingId/")({
  component: InterviewRecordingDetailPage,
});
