import React from "react";

import { useFieldArray } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { Control } from "react-hook-form/dist/types/form";
import type { UseFormRegister } from "react-hook-form/dist/types/form";
import { v4 as uuid } from "uuid";

import type { UserGroup } from "../../../../../../../models/UserGroup";
import { AmeRadioGroup } from "../../../../../../atoms/radio/AmeRadioGroup";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";

import { BelongingUserGroupSearchDropDown } from "./BelongingUserGroupSearchDropDown";
import type { UserEditFormType } from "./UserEditor";

interface Props {
  errors: FieldErrors<UserEditFormType>;
  control: Control<UserEditFormType>;
  userGroups: UserGroup[];
  register: UseFormRegister<UserEditFormType>;
}

export const AuthInfoForm: React.FC<Props> = ({ errors, control, userGroups, register }) => {
  const { fields, update, remove, append } = useFieldArray({
    name: "userGroupIds",
    control,
  });
  const unBelongingUserGroups = userGroups.filter(
    (userGroup) => !fields.some((field) => field.userGroupId === userGroup.userGroupId),
  );

  const updateUserGroups = (group: UserGroup | undefined, indexOfBelongingUserGroup: number) => {
    if (group) {
      update(indexOfBelongingUserGroup, { userGroupId: group.userGroupId });
    } else {
      remove(indexOfBelongingUserGroup);
    }
  };

  const appendUserGroups = (group: UserGroup | undefined) => {
    if (group) {
      append({ userGroupId: group.userGroupId });
    }
  };
  const deleteUserGroup = (userBelongingGroupId: string | undefined) => {
    remove(fields.findIndex((field) => field.userGroupId == userBelongingGroupId));
  };
  return (
    <>
      <AmeTypography fontSize="body2">{"ユーザー区分"}</AmeTypography>
      <Spacer height="8px" />
      <AmeRadioGroup
        data={[
          { value: "true", label: "管理者" },
          { value: "false", label: "インタビュワー" },
        ]}
        {...register("isAdmin")}
      />
      <Spacer height="24px" />
      <AmeTypography fontSize="body2">グループ</AmeTypography>
      {fields.map((field, index) => {
        return (
          <BelongingUserGroupSearchDropDown
            key={field.userGroupId}
            errors={errors}
            userGroups={unBelongingUserGroups}
            currentUserGroup={userGroups.find((userGroup) => {
              return userGroup.userGroupId === field.userGroupId;
            })}
            indexOfcurrentUserGroup={index}
            onGroupChange={updateUserGroups}
            onDeleteClick={() => deleteUserGroup(field.userGroupId)}
          />
        );
      })}
      <BelongingUserGroupSearchDropDown
        key={uuid()}
        errors={errors}
        userGroups={unBelongingUserGroups}
        currentUserGroup={undefined}
        indexOfcurrentUserGroup={-1}
        onDeleteClick={() => deleteUserGroup(undefined)}
        onGroupChange={appendUserGroups}
      />
    </>
  );
};
