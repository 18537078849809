import React, { useCallback, useEffect, useState } from "react";

import { toDataURL } from "qrcode";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SurveyModel } from "../../../../../models/Survey";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmeModal } from "../../../../atoms/modal/AmeModal";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  survey: SurveyModel;
}

export const GetQrCodeModal: React.FC<Props> = (props) => {
  const url = window.location.origin + "/surveys/" + props.survey.surveyId + "/answer-page";
  const [qrCode, setQrCode] = useState<string>();
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();

  const onClickCopyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(url);
    enqueueSuccessSnackbar({ title: "アンケートURLをコピーしました。" });
  }, [url, enqueueSuccessSnackbar]);

  useEffect(() => {
    toDataURL(url, { width: 160 }, (_, qrCodeUrl) => {
      setQrCode(qrCodeUrl);
    });
  }, [url, setQrCode]);

  return (
    <AmeModal open={props.isOpen} onClose={props.onClose}>
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader items={[]} currentTitle="QRコードを取得" />
        <PageLayoutBody type="wide">
          <AmeBox
            sx={{
              "> *": { borderBottom: "1px solid #cccccc", padding: "16px 0" },
              "> *:first-child": { paddingTop: "0" },
            }}
          >
            <AmeBox>
              <AmeTypography component="h5">QRコードやURLを配布してください。</AmeTypography>
            </AmeBox>
            <AmeBox>
              <AmeTypography color="basicLight" fontSize="caption">
                QRコード
              </AmeTypography>
              <Spacer height="8px" />
              <AmeBox sx={{ display: "flex", justifyContent: "center" }}>
                <img src={qrCode} />
              </AmeBox>
            </AmeBox>
            <AmeBox>
              <AmeTypography color="basicLight" fontSize="caption">
                URL
              </AmeTypography>
              <Spacer height="4px" />
              <AmeBox sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <AmeTypography omit>{url}</AmeTypography>
                <AmeButton variant="text" onClick={onClickCopyToClipboard}>
                  リンクをコピー
                </AmeButton>
              </AmeBox>
            </AmeBox>
          </AmeBox>
          <Spacer height="24px" />
          <AmeBox sx={{ display: "flex", gap: "16px" }}>
            <a href={qrCode} download={`${props.survey.displayName}_QrCode.png`}>
              <AmeButton variant="outlined">QRコードを保存</AmeButton>
            </a>
            <AmeBox sx={{ marginLeft: "auto" }}>
              <AmeButton onClick={props.onClose}>完了</AmeButton>
            </AmeBox>
          </AmeBox>
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeModal>
  );
};
