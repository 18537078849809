import React from "react";

import { AmeBox } from "../../muiWrapper/AmeBox";

interface IconTextProps {
  icon: React.ReactNode;
  children: React.ReactNode;
}

export const IconText: React.FC<IconTextProps> = (props) => (
  <AmeBox sx={{ display: "inline-flex", alignItems: "center", gap: "10px" }} component={"span"}>
    {props.icon}
    {props.children}
  </AmeBox>
);
