import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";

import { UseChoiceGroupParams } from "./type";

export const useFollowupChoiceGroup = ({
  filterParams,
  navigate,
}: UseChoiceGroupParams<{ followupRequired: boolean[] }>): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    const followUpRequiredChoices = [
      { value: true, label: "要" },
      { value: false, label: "不要" },
    ];
    return {
      groupName: "フォローアップ要否",
      uiType: "flat",
      choiceOptions: followUpRequiredChoices.map((choice) => ({
        name: choice.label,
        toggleOption: async () =>
          await navigate({
            search: (prev: { followupRequired: boolean[] }) => {
              const followupRequiredSet = new Set(prev.followupRequired);
              if (!followupRequiredSet.delete(choice.value!)) {
                followupRequiredSet.add(choice.value!);
              }
              return { ...prev, followupRequired: Array.from(followupRequiredSet) };
            },
          }),
        isChecked: filterParams.followupRequired.includes(choice.value),
      })),
    };
  }, [filterParams, navigate]);
};
