import React, { ChangeEvent, useCallback, useState } from "react";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { TranscriptionSentence } from "../../../../../models/Transcription";
import { useUpdateTranscription } from "../../../../../store/hooks/transcription";
import { AmeCheckbox } from "../../../../atoms/checkbox/AmeCheckbox";
import { AmeTextarea } from "../../../../atoms/forms/textarea/AmeTextarea";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeFormButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { AmeDialog } from "../../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { TranscriptionAudioPlayer } from "./TranscriptionAudioPlayer";
import { TranscriptionTextareaWrapper } from "./TranscriptionTextareaWrapper";

interface Props {
  sound: Howl;
  transcriptions: TranscriptionSentence[];
  speakerMap: Map<string, number>;
  interviewRecordingId: string;
  transcriptionText: string;
}

export const TranscriptionTextEditor: React.FC<Props> = ({
  sound,
  transcriptions,
  speakerMap,
  interviewRecordingId,
  transcriptionText,
}) => {
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const [text, setText] = useState<string>(transcriptionText);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setText(e.target.value);
    },
    [setText],
  );

  const updateTranscription = useUpdateTranscription();

  const updateTranscriptionText = useCallback(async () => {
    const result = await updateTranscription({
      interviewRecordingId: interviewRecordingId,
      updateTranscriptionRequestBody: {
        text: text,
      },
    });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "文字起こし文章を更新しました。" });
    }
  }, [updateTranscription, interviewRecordingId, text, enqueueSuccessSnackbar]);

  const fixTranscription = useCallback(async () => {
    const result = await updateTranscription({
      interviewRecordingId: interviewRecordingId,
      updateTranscriptionRequestBody: {
        text: text,
        status: "checking",
      },
    });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "文字起こしを確定しました。" });
    }
  }, [updateTranscription, interviewRecordingId, text, enqueueSuccessSnackbar]);

  const [confirmChecked, setConfirmChecked] = useState<boolean>(false);

  return (
    <>
      <AmeDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <AmeDialogLayout
          onClose={() => setIsOpen(false)}
          onSubmit={fixTranscription}
          title={"本当に確定しますか？"}
          submitButtonDisabled={!confirmChecked}
          variant="caution"
          submitButtonText="確定"
        >
          <AmeBox sx={{ display: "flex", justifyContent: "left" }}>
            <AmeTypography fontSize="caption">
              文字起こしを確定すると、後から変更ができません。
              <br />
              問題ない場合は確定するボタンを押してください。
            </AmeTypography>
          </AmeBox>
          <Spacer height="24px" />
          <AmeBox sx={{ display: "flex", justifyContent: "left", alignItems: "center", columnGap: 0.7 }}>
            <AmeCheckbox
              size="small"
              disabled={false}
              checked={confirmChecked}
              onChange={() => setConfirmChecked(!confirmChecked)}
            />
            <AmeTypography fontSize="caption">注意事項を確認しました。</AmeTypography>
          </AmeBox>
        </AmeDialogLayout>
      </AmeDialog>
      <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <TranscriptionTextareaWrapper>
          <AmeTextarea
            sx={{ "& .MuiInputBase-input": { fontSize: "8px" } }}
            rows={12}
            fullWidth
            value={text}
            onChange={onChange}
          />
        </TranscriptionTextareaWrapper>
        <TranscriptionAudioPlayer sound={sound} transcriptions={transcriptions} speakerMap={speakerMap} />
        <AmeFormButtonsLayout
          onPrimary={() => setIsOpen(true)}
          primaryText="確定"
          onSecondary={updateTranscriptionText}
          secondaryText="保存"
        />
      </AmeBox>
    </>
  );
};
