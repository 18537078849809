import React, { useContext } from "react";

import { useSelectedFilter } from "../../../../hooks/useSelectedFilter";
import { HrInterviewType } from "../../../../models/HrInterviewSetting";
import { useGetHrInterviewReport } from "../../../../store/hooks/hrInterview";
import { formatDateToYYYYMMDD } from "../../../../utils/formatter";
import { HorizontalStackedBarChart } from "../../../atoms/charts/HorizontalStackedBarChart";
import { PaperBody } from "../../../atoms/paper/PaperBody";
import { PaperWrapper } from "../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { V2ContentWrapper } from "../../../atoms/wrapper/V2ContentWrapper";
import { WaitForSuccess } from "../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../common/DataSourceContext";

import { HrInterviewReportFilter } from "./HrInterviewReportFilter";
import { HrInterviewReportHeader } from "./HrInterviewReportHeader";
import { HrInterviewReportQuery, useHrInterviewReportChoiceGroups } from "./HrInterviewReportQuery";
import { SentenceTable } from "./SentenceTable";

interface Props {
  searchParams: HrInterviewReportQuery;
  navigate: (params: { search: (prev: HrInterviewReportQuery) => HrInterviewReportQuery }) => Promise<void>;
  interviewTypes: HrInterviewType[];
}

export const HrInterviewReportContent: React.FC<Props> = ({ searchParams, navigate, interviewTypes }) => {
  const choiceGroups = useHrInterviewReportChoiceGroups(navigate, searchParams, interviewTypes);
  const selectedFilter = useSelectedFilter(choiceGroups);
  const getReportQuery = useGetHrInterviewReport(searchParams.axis, {
    interviewTypeIds: searchParams.interviewTypeIds,
    followupRequired: searchParams.followupRequired,
    interviewDateFrom: searchParams.interviewDateFrom,
    interviewDateTo: searchParams.interviewDateTo,
    subCategoryIds: searchParams.subCategoryIds,
    segments: [
      { segmentKey: "SEX", segmentValue: searchParams.SEX },
      { segmentKey: "DEPARTMENT", segmentValue: searchParams.DEPARTMENT },
      { segmentKey: "FREE1", segmentValue: searchParams.FREE1 },
      { segmentKey: "FREE2", segmentValue: searchParams.FREE2 },
      { segmentKey: "AGE", segmentValue: searchParams.AGE },
      { segmentKey: "MONTHS_OF_SERVICE", segmentValue: searchParams.MONTHS_OF_SERVICE },
    ],
  });
  const { subCategories } = useContext(DataSourceContext);

  return (
    <AmeBox sx={{ display: "flex", gap: "24px" }}>
      <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
        <HrInterviewReportFilter
          choiceGroups={choiceGroups}
          from={searchParams.interviewDateFrom}
          to={searchParams.interviewDateTo}
          setDate={async (params) => {
            await navigate({
              search: (prev) => {
                const nextParams = { ...prev };
                if (params.from) {
                  nextParams.interviewDateFrom = formatDateToYYYYMMDD(params.from);
                }
                if (params.to) {
                  nextParams.interviewDateTo = formatDateToYYYYMMDD(params.to);
                }
                return nextParams;
              },
            });
          }}
        />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, minWidth: 0 }}>
        <WaitForSuccess queryState={getReportQuery}>
          {(data) => (
            <>
              <HrInterviewReportHeader
                count={data.totalCount}
                selectedFilters={selectedFilter}
                selectedAxis={searchParams.axis}
                onChangeAxis={async (axis) => {
                  await navigate({
                    search: (prev) => ({ ...prev, axis: axis }),
                  });
                }}
              />
              <AmeBox>
                <PaperWrapper>
                  <PaperBody>
                    <V2ContentWrapper title={"AIによる組織課題の割合"}>
                      <HorizontalStackedBarChart
                        sortByfirstSeries
                        highlightColumnNames={
                          searchParams.subCategoryIds.length
                            ? searchParams.subCategoryIds.map(
                                (id) => subCategories.findBySubCategoryId(id)?.displayName ?? "",
                              )
                            : undefined
                        }
                        series={data.analysisScores.map((d) => ({
                          name: d.axis,
                          data: d.dimensions.map((score) => ({
                            name: score.label,
                            value: score.score,
                          })),
                        }))}
                      />
                    </V2ContentWrapper>
                  </PaperBody>
                </PaperWrapper>
                <Spacer height={"24px"} />
                <PaperWrapper>
                  <PaperBody>
                    <V2ContentWrapper title={"従業員の声"}>
                      {data.documents.map((document) => {
                        return (
                          <SentenceTable
                            key={document.documentId}
                            documentId={document.documentId}
                            tags={document.tags}
                            content={document.content}
                            hrInterviewId={document.hrInterviewId}
                          />
                        );
                      })}
                    </V2ContentWrapper>
                  </PaperBody>
                </PaperWrapper>
              </AmeBox>
            </>
          )}
        </WaitForSuccess>
      </AmeBox>
    </AmeBox>
  );
};
