import React from "react";

import { createFileRoute } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";

import { useGetUserListQuery } from "../../../../../../../store/hooks/users";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { UserDataTable } from "../../../../../../organisms/settings/members/users/UserDataTable";

export const UserListPage: React.FC = () => {
  const userListQueryState = useGetUserListQuery();
  const navigate = useNavigate();
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          {
            title: "設定",
            to: "/settings",
          },
        ]}
        currentTitle="ユーザー管理 "
      />
      <PageLayoutBody type="wide">
        <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <AmeTypography component={"h4"}>ユーザー管理</AmeTypography>
          <AmeButton onClick={() => void navigate({ to: "/hidden-pages/members/users/create" })}>
            ユーザーを新規登録
          </AmeButton>
        </AmeBox>
        <Spacer height="24px"></Spacer>
        <WaitForSuccess queryState={userListQueryState}>{(data) => <UserDataTable data={data} />}</WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/members/users/")({
  component: UserListPage,
});
