import React, { useCallback, useState } from "react";

import { AmeCheckbox } from "../../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeDialog } from "../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

export const InterviewFilesRegisterationCautionDialog: React.FC<Props> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);

  const onClickCheckbox = useCallback(() => {
    setChecked(!checked);
  }, [setChecked, checked]);

  return (
    <AmeDialog open={props.isOpen} onClose={props.onClose}>
      <AmeDialogLayout
        variant="caution"
        onClose={props.onClose}
        onSubmit={props.onSubmit}
        title="本当に登録しますか？"
        submitButtonText="確定"
        submitButtonDisabled={!checked}
      >
        <AmeTypography>紐付き先従業員番号が選択されていません。</AmeTypography>
        <AmeTypography>問題ない場合は確定ボタンを押してください。</AmeTypography>
        <Spacer height="24px" />
        <AmeBox sx={{ display: "inline-flex", justifyContent: "start", gap: "8px" }}>
          <AmeCheckbox checked={checked} onChange={onClickCheckbox} />
          <AmeTypography>注意事項を確認しました</AmeTypography>
        </AmeBox>
      </AmeDialogLayout>
    </AmeDialog>
  );
};
