import React, { useState } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { useGetTenures } from "../..";
import { AmeButton } from "../../../../../../../atoms/button/AmeButton";
import { AmeDateInput } from "../../../../../../../atoms/forms/input/AmeDateInput";
import { BreadcrumbPageLayoutHeader } from "../../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../../../atoms/spacers/Spacer";
import { AmeBox } from "../../../../../../../muiWrapper/AmeBox";

const TenureEdit: React.FC = () => {
  const { tenures } = useGetTenures();
  const { index } = Route.useSearch();
  const navigate = useNavigate();
  const { employeeId } = Route.useParams();
  const [startDate, setStartDate] = useState(index != undefined ? tenures[index].startDate : undefined);
  const [endDate, setEndDate] = useState(index != undefined ? tenures[index].endDate : undefined);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "従業員一覧", to: "/employees" },
          { title: "従業員詳細", to: "/employees/$employeeId" },
        ]}
        currentTitle="入社日と退社日の編集"
      />
      <PageLayoutBody type="basic">
        <PaperWrapper>
          <PaperHeader>入社日と退社日の編集</PaperHeader>
          <PaperBody>
            <AmeBox>入社日</AmeBox>
            <Spacer height="8px" />
            <AmeDateInput
              value={startDate}
              onCommitDate={(date: Date | null) => {
                setStartDate(date ?? undefined);
              }}
            />
            <Spacer height="24px" />
            <AmeBox>退社日</AmeBox>
            <Spacer height="8px" />
            <AmeDateInput
              value={endDate}
              onCommitDate={(date: Date | null) => {
                setEndDate(date ?? undefined);
              }}
            />
            <Spacer height="24px" />
            <AmeBox sx={{ display: "flex", gap: "16px", justifyContent: "right" }}>
              <AmeButton
                variant="outlined"
                color="basic"
                onClick={async () => {
                  await navigate({ to: "/employees/" + employeeId });
                }}
              >
                キャンセル
              </AmeButton>
              <AmeButton
                variant="contained"
                color="primary"
                onClick={async () => {
                  // eslint-disable-next-line no-console
                  console.log("startDate = ", startDate);
                  // eslint-disable-next-line no-console
                  console.log("endDate = ", endDate);
                  await navigate({ to: "/employees/" + employeeId });
                }}
              >
                更新
              </AmeButton>
            </AmeBox>
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-admin/employees/$employeeId/tenures/$employeeTenureId/",
)({
  component: TenureEdit,
  validateSearch: (search: Partial<{ index: number }>): { index?: number } => {
    return {
      index: search.index,
    };
  },
});
