import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetFeedbackSurvey } from "../../../../../store/hooks/feedback-survey";
import { useGetHrInterviewQualityScoreReport } from "../../../../../store/hooks/hr-interview-feedback";
import { useGetInterviewTypes } from "../../../../../store/hooks/hr-interview-setting";
import { useGetUserListQuery } from "../../../../../store/hooks/users";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { QualityReportContent, QualityReportQuery } from "../../../../organisms/hr-interviews/quality-report";

const QualityReport: React.FC = () => {
  const navigate = Route.useNavigate();
  const searchParams = Route.useSearch();

  const getFeedbackSurveySettingQuery = useGetFeedbackSurvey();
  const interviewTypesQuery = useGetInterviewTypes(true);
  const userQuery = useGetUserListQuery();
  const qualityReportQuery = useGetHrInterviewQualityScoreReport({
    // TODO: 軸選択セレクタの実装
    axis: searchParams.axis,
    from: searchParams.from,
    to: searchParams.to,
    interviewTypeIds: searchParams.interviewTypeIds,
    interviewerIds: searchParams.interviewerIds,
    evaluationTypes: searchParams.evaluationTypes,
    followUpIsRequired: searchParams.followupRequired,
  });

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"面談品質レポート"} />
      <PageLayoutBody type={"wide"}>
        <WaitForSuccess queryState={interviewTypesQuery}>
          {(types) => (
            <WaitForSuccess queryState={userQuery}>
              {({ users }) => (
                <WaitForSuccess queryState={qualityReportQuery}>
                  {(interviewQualityReport) => (
                    <WaitForSuccess queryState={getFeedbackSurveySettingQuery}>
                      {({ evaluationItems }) => (
                        <QualityReportContent
                          navigate={navigate}
                          searchParams={searchParams}
                          interviewTypes={types}
                          evaluationTypes={evaluationItems}
                          // TODO: Ame管理ユーザーは除外する
                          interviewers={users}
                          hrInterviewQualityReport={interviewQualityReport}
                        />
                      )}
                    </WaitForSuccess>
                  )}
                </WaitForSuccess>
              )}
            </WaitForSuccess>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hr-interviews/quality-report")({
  component: QualityReport,
  validateSearch: (searchParams: Partial<QualityReportQuery>): QualityReportQuery => {
    return {
      axis: searchParams.axis ?? "NONE",
      interviewTypeIds: searchParams.interviewTypeIds ?? [],
      interviewerIds: searchParams.interviewerIds ?? [],
      evaluationTypes: searchParams.evaluationTypes ?? [],
      followupRequired: searchParams.followupRequired ?? [],
      from: searchParams.from
        ? Number.isNaN(new Date(searchParams.from).getDate())
          ? undefined
          : searchParams.from
        : undefined,
      to: searchParams.to
        ? Number.isNaN(new Date(searchParams.to).getDate())
          ? undefined
          : searchParams.to
        : undefined,
    };
  },
});
