/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFileResponseEmployee,
    InterviewFileResponseEmployeeFromJSON,
    InterviewFileResponseEmployeeFromJSONTyped,
    InterviewFileResponseEmployeeToJSON,
    TranscriptionStatus,
    TranscriptionStatusFromJSON,
    TranscriptionStatusFromJSONTyped,
    TranscriptionStatusToJSON,
    UserGroupRefResponse,
    UserGroupRefResponseFromJSON,
    UserGroupRefResponseFromJSONTyped,
    UserGroupRefResponseToJSON,
    UserRefResponse,
    UserRefResponseFromJSON,
    UserRefResponseFromJSONTyped,
    UserRefResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFileResponse
 */
export interface InterviewFileResponse {
    /**
     * 
     * @type {Date}
     * @memberof InterviewFileResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {InterviewFileResponseEmployee}
     * @memberof InterviewFileResponse
     */
    employee?: InterviewFileResponseEmployee;
    /**
     * 
     * @type {TranscriptionStatus}
     * @memberof InterviewFileResponse
     */
    transcriptionStatus?: TranscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof InterviewFileResponse
     */
    exEmployeeInterviewId?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewFileResponse
     */
    originalFileName: string;
    /**
     * 
     * @type {UserRefResponse}
     * @memberof InterviewFileResponse
     */
    interviewer?: UserRefResponse;
    /**
     * 
     * @type {UserGroupRefResponse}
     * @memberof InterviewFileResponse
     */
    userGroup?: UserGroupRefResponse;
    /**
     * 
     * @type {string}
     * @memberof InterviewFileResponse
     */
    interviewerId?: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewFileResponse
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof InterviewFileResponse
     */
    interviewRecordingId: string;
}

export function InterviewFileResponseFromJSON(json: any): InterviewFileResponse {
    return InterviewFileResponseFromJSONTyped(json, false);
}

export function InterviewFileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'employee': !exists(json, 'employee') ? undefined : InterviewFileResponseEmployeeFromJSON(json['employee']),
        'transcriptionStatus': !exists(json, 'transcriptionStatus') ? undefined : TranscriptionStatusFromJSON(json['transcriptionStatus']),
        'exEmployeeInterviewId': !exists(json, 'exEmployeeInterviewId') ? undefined : json['exEmployeeInterviewId'],
        'originalFileName': json['originalFileName'],
        'interviewer': !exists(json, 'interviewer') ? undefined : UserRefResponseFromJSON(json['interviewer']),
        'userGroup': !exists(json, 'userGroup') ? undefined : UserGroupRefResponseFromJSON(json['userGroup']),
        'interviewerId': !exists(json, 'interviewerId') ? undefined : json['interviewerId'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'interviewRecordingId': json['interviewRecordingId'],
    };
}

export function InterviewFileResponseToJSON(value?: InterviewFileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'employee': InterviewFileResponseEmployeeToJSON(value.employee),
        'transcriptionStatus': TranscriptionStatusToJSON(value.transcriptionStatus),
        'exEmployeeInterviewId': value.exEmployeeInterviewId,
        'originalFileName': value.originalFileName,
        'interviewer': UserRefResponseToJSON(value.interviewer),
        'userGroup': UserGroupRefResponseToJSON(value.userGroup),
        'interviewerId': value.interviewerId,
        'duration': value.duration,
        'interviewRecordingId': value.interviewRecordingId,
    };
}


