import React, { useContext, useMemo } from "react";

import { AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory } from "../../../../store/autogenApi";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../common/DataSourceContext";
import { ReportFilterChipList } from "../../v2/v2Component/ReportFilterChipList";
import { FilterChipContent } from "../../v2/v2Component/ReportFilterChipList/ReportFilterChip";
import { AxisSelect } from "../../v2/v2Component/ReportHeader/AxisSelect";
import { DisplayedInterviewsCount } from "../../v2/v2Component/ReportHeader/DisplayedInterviewsCount";

type Props = {
  count: number;
  selectedFilters: FilterChipContent[];
  selectedAxis: AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory;
  onChangeAxis: (axis: AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory) => void;
};

const useAxisDisplayNames = (): Record<AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory, string> => {
  const {
    setting: { segmentNames },
  } = useContext(DataSourceContext);
  return useMemo(
    () => ({
      NONE: "軸なし",
      SEX: segmentNames.SEX,
      DEPARTMENT: segmentNames.DEPARTMENT,
      FREE1: segmentNames.FREE1,
      FREE2: segmentNames.FREE2,
      AGE: segmentNames.AGE,
      MONTHS_OF_SERVICE: segmentNames.MONTHS_OF_SERVICE,
    }),
    [segmentNames],
  );
};

export const HrInterviewReportHeader: React.FC<Props> = ({ count, selectedFilters, selectedAxis, onChangeAxis }) => {
  const axisDisplayNames = useAxisDisplayNames();
  return (
    <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "56px" }}>
      <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
        <DisplayedInterviewsCount count={count} />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, flexShrink: 1, overflowX: "scroll", margin: "0 16px", paddingTop: "8px" }}>
        <ReportFilterChipList selectedFilters={selectedFilters} />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
        <AxisSelect
          axisDisplayNames={axisDisplayNames}
          selectedAxis={selectedAxis}
          onChange={(e) => {
            onChangeAxis(e.target.value as AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory);
          }}
        />
      </AmeBox>
    </AmeBox>
  );
};
