/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HrInterviewTranscriptionParagraphResponse
 */
export interface HrInterviewTranscriptionParagraphResponse {
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    isBeautified: boolean;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    endTime: number;
    /**
     * 
     * @type {number}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    startTime: number;
    /**
     * 
     * @type {number}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    speakerIndex: number;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    hrInterviewTranscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewTranscriptionParagraphResponse
     */
    hrInterviewTranscriptionParagraphId: string;
}

export function HrInterviewTranscriptionParagraphResponseFromJSON(json: any): HrInterviewTranscriptionParagraphResponse {
    return HrInterviewTranscriptionParagraphResponseFromJSONTyped(json, false);
}

export function HrInterviewTranscriptionParagraphResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewTranscriptionParagraphResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'isBeautified': json['isBeautified'],
        'content': json['content'],
        'endTime': json['endTime'],
        'startTime': json['startTime'],
        'speakerIndex': json['speakerIndex'],
        'hrInterviewTranscriptionId': json['hrInterviewTranscriptionId'],
        'hrInterviewTranscriptionParagraphId': json['hrInterviewTranscriptionParagraphId'],
    };
}

export function HrInterviewTranscriptionParagraphResponseToJSON(value?: HrInterviewTranscriptionParagraphResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'isBeautified': value.isBeautified,
        'content': value.content,
        'endTime': value.endTime,
        'startTime': value.startTime,
        'speakerIndex': value.speakerIndex,
        'hrInterviewTranscriptionId': value.hrInterviewTranscriptionId,
        'hrInterviewTranscriptionParagraphId': value.hrInterviewTranscriptionParagraphId,
    };
}


