import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetHrInterview } from "../../../../../../../store/hooks/hrInterview";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { InterviewPageContent } from "../../../../../../organisms/hr-interviews/interviews/$interviewId/index";

const InterviewPage: React.FC = () => {
  const { interviewId } = Route.useParams<{ interviewId: string }>();
  const { documentId } = Route.useSearch();
  const interviewDetailQueryState = useGetHrInterview(interviewId);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "在職者面談一覧", to: "/hr-interviews/interviews" }]}
        currentTitle={"在職者面談詳細"}
      />
      <PageLayoutBody type="wide">
        <WaitForSuccess queryState={interviewDetailQueryState}>
          {(interviewDetail) => (
            <InterviewPageContent interviewDetail={interviewDetail} highlightedDocumentId={documentId} />
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type InterviewSearchParams = {
  documentId?: string;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/hr-interviews/interviews/$interviewId/")({
  component: InterviewPage,
  validateSearch: (search: Partial<InterviewSearchParams>): InterviewSearchParams => ({
    documentId: search.documentId,
  }),
});
