import { useContext } from "react";

import { ChoiceSubGroup, NestedChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { DataSourceContext } from "../../components/organisms/common/DataSourceContext";

export const useGetCategoryContent = (
  filterParams: { subCategoryIds: string[] } | undefined,
  navigate: (params: { search: (prev: { subCategoryIds: string[] }) => { subCategoryIds: string[] } }) => Promise<void>,
): NestedChoiceGroupProps => {
  const { categories, subCategories } = useContext(DataSourceContext);
  const filterCategoryData: NestedChoiceGroupProps = {
    groupName: "カテゴリ",
    uiType: "nested",
    choiceSubGroups: [],
  };
  categories.forEach((category) => {
    const filterSubGroup: ChoiceSubGroup = {
      subGroupName: category.displayName,
      choiceOptions: [],
    };
    subCategories.findByCategoryId(category.categoryId).forEach((subCategory) => {
      filterSubGroup.choiceOptions.push({
        name: subCategory.displayName,
        toggleOption: async () =>
          await navigate({
            search: (prev) => {
              const subCategoryIdSet = new Set(prev.subCategoryIds);
              if (subCategoryIdSet.has(subCategory.subCategoryId)) {
                subCategoryIdSet.delete(subCategory.subCategoryId);
              } else {
                subCategoryIdSet.add(subCategory.subCategoryId);
              }
              return { ...prev, subCategoryIds: Array.from(subCategoryIdSet) };
            },
          }),
        isChecked: filterParams ? filterParams.subCategoryIds.includes(subCategory.subCategoryId) : false,
      });
    });
    if (filterSubGroup.choiceOptions.length) {
      filterCategoryData.choiceSubGroups.push(filterSubGroup);
    }
  });
  return filterCategoryData;
};
