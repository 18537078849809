export const getBeginningOfMonth = (date: Date): Date => {
    const result = new Date(date);
    result.setDate(1);
    result.setHours(0, 0, 0, 0);
    return result;
};

export const getEndOfOneMonthAgo = (date: Date) => {
    const result = getBeginningOfMonth(date);
    result.setMilliseconds(result.getMilliseconds() - 1);
    return result;
};

export const getEndOfLastMonth = (): Date => {
    return getEndOfOneMonthAgo(new Date());
};

export const addMonth = (date: Date, monthDelta: number): Date => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + monthDelta);
    return result;
};

export const isInvalidDate = (date: Date): boolean => {
    return Number.isNaN(date.getTime());
};
