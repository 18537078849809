import React from "react";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { HRPentestLogo } from "../../../../../atoms/icon/HRPentestLogo";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

export const LoginLogoTitle: React.FC = () => {
  const theme = useAmeTheme();
  return (
    <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center", paddingBottom: "32px" }}>
      <AmeBox sx={{ width: "220px" }}>
        <HRPentestLogo htmlColor={theme.brand.primary[100]} />
      </AmeBox>
    </AmeBox>
  );
};
