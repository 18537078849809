import React, { useCallback } from "react";

import { useMediaQuery } from "@mui/material";

import { customizedMuiTheme, useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { RadioButton } from "../../../atoms/button/RadioButton";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { SurveyQuestion, SurveyQuestionAnswer } from "./SurveyTemplateBody";

type Props = {
  surveyQuestion: SurveyQuestion;
  answer: SurveyQuestionAnswer | undefined;
  onChange: (answer: SurveyQuestionAnswer) => void;
};
export const SurveyTemplateQuestionItem: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const isPc = useMediaQuery(customizedMuiTheme.breakpoints.up("md"));
  const handleChange = useCallback(
    (value: string) => {
      props.onChange({
        questionId: props.surveyQuestion.questionId,
        value,
      });
    },
    [props],
  );
  return (
    <AmeBox
      sx={{
        padding: "24px",
        border: `1px solid ${theme.common.border.gray40}`,
        borderRadius: "4px",
      }}
    >
      <AmeTypography fontSize="body2">{props.surveyQuestion.questionText}</AmeTypography>
      <Spacer height="8px" />
      {!isPc && (
        <>
          <AmeBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <AmeTypography fontSize="caption">まったくそう思わない</AmeTypography>
            <AmeTypography fontSize="caption">とてもそう思う</AmeTypography>
          </AmeBox>
          <Spacer height="6px" />
        </>
      )}
      <AmeBox
        component="fieldset"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "none" }}
      >
        {isPc && <AmeTypography fontSize="caption">まったくそう思わない</AmeTypography>}
        {
          // 選択肢の 1 〜 5 を生成
          [...Array(5)]
            .map((_, i) => String(i + 1))
            .map((value) => (
              <RadioButton
                key={value}
                id={`${props.surveyQuestion.questionId}-${value}`}
                name={props.surveyQuestion.questionId}
                value={value}
                onChange={handleChange}
                checked={value === props.answer?.value}
              />
            ))
        }
        {isPc && <AmeTypography fontSize="caption">とてもそう思う</AmeTypography>}
      </AmeBox>
    </AmeBox>
  );
};
