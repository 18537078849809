/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSurveyStatusRequestBody
 */
export interface UpdateSurveyStatusRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyStatusRequestBody
     */
    status: UpdateSurveyStatusRequestBodyStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateSurveyStatusRequestBodyStatusEnum {
    RUNNING = 'running',
    PAUSED = 'paused',
    COMPLETED = 'completed'
}

export function UpdateSurveyStatusRequestBodyFromJSON(json: any): UpdateSurveyStatusRequestBody {
    return UpdateSurveyStatusRequestBodyFromJSONTyped(json, false);
}

export function UpdateSurveyStatusRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSurveyStatusRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function UpdateSurveyStatusRequestBodyToJSON(value?: UpdateSurveyStatusRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}


