import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Clockwise: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99881 3.17068C6.73806 3.17117 5.51139 3.57997 4.50255 4.33585C3.49371 5.09173 2.75699 6.15401 2.40274 7.36357C2.04848 8.57314 2.09577 9.86488 2.53751 11.0453C2.97925 12.2258 3.79167 13.2314 4.85308 13.9115C5.91449 14.5917 7.16776 14.9097 8.42518 14.8181C9.68259 14.7265 10.8765 14.2301 11.828 13.4032C12.7795 12.5764 13.4374 11.4636 13.7033 10.2316C13.9691 8.9996 13.8285 7.71466 13.3025 6.56924C13.2452 6.42998 13.2441 6.27398 13.2993 6.13389C13.3544 5.9938 13.4617 5.88048 13.5986 5.81764C13.7354 5.75481 13.8913 5.74733 14.0336 5.79677C14.1758 5.84621 14.2934 5.94874 14.3618 6.0829C14.993 7.45747 15.1616 8.99947 14.8426 10.4779C14.5235 11.9564 13.7338 13.2917 12.5919 14.2838C11.4499 15.276 10.0172 15.8716 8.50822 15.9814C6.99926 16.0912 5.49533 15.7093 4.22169 14.8929C2.94805 14.0766 1.97328 12.8697 1.44338 11.453C0.913485 10.0363 0.856986 8.48618 1.28235 7.03472C1.70771 5.58326 2.59204 4.30864 3.80287 3.40178C5.0137 2.49493 6.48585 2.00465 7.99881 2.00439V3.17068Z"
        fill="currentColor"
      />
      <path
        d="M7.9994 4.8773V0.291462C7.99942 0.23606 8.01523 0.18181 8.04498 0.135065C8.07473 0.0883199 8.11719 0.0510152 8.16738 0.0275193C8.21757 0.00402326 8.27341 -0.00469148 8.32838 0.00239547C8.38334 0.00948242 8.43515 0.0320777 8.47774 0.0675355L11.2311 2.36046C11.2639 2.38782 11.2903 2.42206 11.3084 2.46076C11.3265 2.49945 11.3359 2.54166 11.3359 2.58438C11.3359 2.62711 11.3265 2.66931 11.3084 2.70801C11.2903 2.7467 11.2639 2.78094 11.2311 2.80831L8.47774 5.10123C8.43515 5.13669 8.38334 5.15928 8.32838 5.16637C8.27341 5.17346 8.21757 5.16474 8.16738 5.14124C8.11719 5.11775 8.07473 5.08044 8.04498 5.0337C8.01523 4.98695 7.99942 4.9327 7.9994 4.8773Z"
        fill="currentColor"
      />
      <path
        d="M7.78946 11.084C7.08746 11.084 6.61946 10.808 6.28946 10.478L6.69746 9.914C6.94946 10.16 7.26146 10.364 7.67546 10.364C8.13746 10.364 8.46146 10.07 8.46146 9.548C8.46146 9.032 8.16146 8.744 7.71146 8.744C7.44746 8.744 7.30346 8.81 7.04546 8.978L6.64346 8.714L6.76346 6.554H9.13946V7.298H7.52546L7.44146 8.24C7.62146 8.156 7.77146 8.114 7.97546 8.114C8.71946 8.114 9.34346 8.558 9.34346 9.524C9.34346 10.514 8.61146 11.084 7.78946 11.084Z"
        fill="currentColor"
      />
    </SvgWrapper>
  );
};
