import {
  HrInterviewTranscriptionParagraphResponse,
  HrInterviewTranscriptionResponse,
  HrInterviewTranscriptionStatus,
} from "../../store/autogenApi";

export class HrInterviewTranscription {
  private constructor(
    readonly hrInterviewTranscriptionId: string,
    readonly hrInterviewFileId: string,
    readonly status: HrInterviewTranscriptionStatus,
    readonly paragraphs: HrInterviewTranscriptionParagraph[],
    readonly summary: string | undefined,
    readonly createdAt: Date,
    readonly updatedAt: Date,
  ) {}

  public get isTranscribing(): boolean {
    return (
      this.status === "AI_TRANSCRIBING" ||
      this.status === "BEAUTIFYING" ||
      this.status === "OVERALL_SUMMARIZING" ||
      this.status === "TOPIC_SUMMARIZING"
    );
  }

  public static fromResponse(response: HrInterviewTranscriptionResponse): HrInterviewTranscription {
    return new HrInterviewTranscription(
      response.hrInterviewTranscriptionId,
      response.hrInterviewFileId,
      response.status,
      response.paragraphs.map(HrInterviewTranscriptionParagraph.fromModel),
      response.summary,
      new Date(response.createdAt),
      new Date(response.updatedAt),
    );
  }
}

export class HrInterviewTranscriptionParagraph {
  private constructor(
    readonly hrInterviewTranscriptionParagraphId: string,
    readonly hrInterviewTranscriptionId: string,
    readonly speakerIndex: number,
    readonly startTime: number,
    readonly endTime: number,
    readonly content: string,
    readonly createdAt: Date,
    readonly updatedAt: Date,
  ) {}

  public static fromModel(response: HrInterviewTranscriptionParagraphResponse): HrInterviewTranscriptionParagraph {
    return new HrInterviewTranscriptionParagraph(
      response.hrInterviewTranscriptionParagraphId,
      response.hrInterviewTranscriptionId,
      response.speakerIndex,
      response.startTime,
      response.endTime,
      response.content,
      new Date(response.createdAt),
      new Date(response.updatedAt),
    );
  }
}
