import React from "react";

import { PopoverProps } from "@mui/material";

import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmePopover } from "../../muiWrapper/AmePopover";

type Props = {
  children: React.ReactNode;
  icon: React.ReactNode;
} & Pick<PopoverProps, "sx" | "anchorOrigin" | "transformOrigin">;

export const IconPopover: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <AmeBox>
      <AmeBox onMouseEnter={handlePopoverOpen} sx={{ display: "flex", alignItems: "center" }}>
        {props.icon}
      </AmeBox>
      <AmePopover
        sx={props.sx}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={
          props.anchorOrigin ?? {
            vertical: "top",
            horizontal: "center",
          }
        }
        transformOrigin={
          props.transformOrigin ?? {
            vertical: "bottom",
            horizontal: "center",
          }
        }
        onClose={handlePopoverClose}
      >
        <AmeBox onMouseEnter={() => setAnchorEl(anchorEl)} onMouseLeave={handlePopoverClose}>
          {props.children}
        </AmeBox>
      </AmePopover>
    </AmeBox>
  );
};
