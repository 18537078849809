import React from "react";

import { Modal } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  open: boolean;
  children: React.ReactNode;
  width?: "normal" | "wide";
  onClose: () => void;
}

export const AmeModal: React.FC<Props> = ({ width = "normal", ...props }) => {
  const theme = useAmeTheme();
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <AmeBox
        sx={{
          maxWidth: width === "normal" ? "750px" : "95vw",
          width: "100%",
          maxHeight: "95vh",
          overflowY: "scroll",
          "&::-webkit-scrollbar-track": {
            backgroundColor: theme.common.background.gray40,
          },
        }}
      >
        {props.children}
      </AmeBox>
    </Modal>
  );
};
