import React from "react";

import { FieldError, UseFormRegister } from "react-hook-form";

import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeFormset } from "../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { SimplePaper } from "../../../../atoms/paper/SimplePaper";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import {
  SurveyQuestion,
  SurveyQuestionAnswer,
  SurveyQuestionAnswers,
  SurveyTemplateBody,
} from "../../../common/SurveyTemplate/SurveyTemplateBody";
import { SurveyTemplateHeader } from "../../../common/SurveyTemplate/SurveyTemplateHeader";
import { SurveyTemplateQuestionList } from "../../../common/SurveyTemplate/SurveyTemplateQuestionList";

interface Props {
  answers: SurveyQuestionAnswers;
  title: string;
  customerCreatedId: string;
  questions: SurveyQuestion[];
  description: string;
  error: FieldError | undefined;
  register: UseFormRegister<{ customerCreatedId: string }>;
  onChange: (answer: SurveyQuestionAnswer) => void;
  onSubmit: () => void;
}

export const SurveyQuestionnaireForQr: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const submittable = props.answers.size === props.questions.length && props.customerCreatedId !== "";
  return (
    <SimplePaper noPadding>
      <AmeBox
        sx={{ position: "sticky", top: 0, zIndex: theme.zIndex.header, backgroundColor: theme.common.background.white }}
      >
        <SurveyTemplateHeader total={props.questions.length} answered={props.answers.size} title={props.title} />
      </AmeBox>
      <SurveyTemplateBody description={props.description} submittable={submittable} onSubmit={props.onSubmit}>
        <AmeBox>
          <AmeFormset label="従業員番号" errorMessage={props.error?.message} required>
            <AmeInput
              {...props.register("customerCreatedId", { required: "従業員番号は必須です。" })}
              error={props.error?.message !== undefined}
            />
          </AmeFormset>
        </AmeBox>
        <SurveyTemplateQuestionList
          surveyQuestions={props.questions}
          answers={props.answers}
          onChange={props.onChange}
        />
      </SurveyTemplateBody>
    </SimplePaper>
  );
};
