import React, { MouseEventHandler } from "react";

import { IconButton as MuiIconButton } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

interface Props {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: "primary" | "basic";
}

// 丸形のアイコンが存在しないので適当に切り出し
export const AmeIconButton: React.FC<Props> = ({ children, onClick, color = "basic" }) => {
  const theme = useAmeTheme();
  return (
    <MuiIconButton
      onClick={onClick}
      sx={{
        height: "48px",
        width: "48px",
        color: (() => {
          switch (color) {
            case "primary":
              return theme.common.text.white;
            case "basic":
              return theme.common.text.black;
          }
        })(),
        backgroundColor: (() => {
          switch (color) {
            case "primary":
              return theme.brand.primary["100"];
            case "basic":
              return "none";
          }
        })(),
      }}
    >
      {children}
    </MuiIconButton>
  );
};
