import { HrInterviewFileResponse, HrInterviewTranscriptionStatus } from "../../store/autogenApi";
import { UserRef } from "../User";
import { UserGroupRef } from "../UserGroup";

import { HrInterviewRef } from "./HrInterview";

export class HrInterviewFile {
  private constructor(
    readonly hrInterviewFileId: string,
    readonly interviewerId: string | undefined,
    readonly userGroup: UserGroupRef | undefined,
    readonly originalFileName: string,
    readonly duration: number,
    readonly createdAt: Date,
    readonly transcriptionStatus: HrInterviewTranscriptionStatus | undefined,
    readonly uploadUser: UserRef | undefined,
    readonly hrInterview: HrInterviewRef | undefined,
  ) {}

  public static fromResponse(response: HrInterviewFileResponse): HrInterviewFile {
    return new HrInterviewFile(
      response.hrInterviewFileId,
      response.interviewerId,
      response.userGroup ? UserGroupRef.fromResponse(response.userGroup) : undefined,
      response.originalFileName,
      response.duration,
      new Date(response.createdAt),
      response.transcriptionStatus,
      response.uploadUser ? UserRef.fromResponse(response.uploadUser) : undefined,
      response.hrInterview ? HrInterviewRef.fromResponse(response.hrInterview) : undefined,
    );
  }

  get linkStatusText(): string {
    if (!this.hrInterview?.employee) {
      return "選択なし";
    }
    return `${this.hrInterview.interviewType.displayName}_${this.hrInterview.employee.customerCreatedId}`;
  }

  get fileTranscriptionStatus(): string {
    if (!this.transcriptionStatus) {
      return "エラー（文字起こし情報なし）";
    }
    switch (this.transcriptionStatus) {
      case "AI_TRANSCRIBING":
      case "BEAUTIFYING":
      case "OVERALL_SUMMARIZING":
      case "TOPIC_SUMMARIZING":
        return "文字起し中";
      case "COMPLETED":
        return "文字起し完了";
      case "FAILED":
        return "エラー";
    }
  }
}
