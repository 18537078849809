import { useCallback, useEffect, useState } from "react";

import { Employee } from "../../../../../models/Employee";

export type InterviewFileTmpData = {
  file: File;
  employee: Employee | null;
  tmpFilename?: string;
  percentage: number;
  status: "pending" | "uploaded" | "uploading" | "error";
  comment: string;
};

export const useInterviewFileTmpDataList = (
  files: File[],
  uploadInterviewFile: (
    file: File,
    updatePercentage: (percentage: number) => void,
  ) => Promise<{ tmpFilename: string; suggest?: Employee }>,
) => {
  const [fileTmpDataList, setFileTmpDataList] = useState<InterviewFileTmpData[]>([]);

  const setInterviewFileTmpData = useCallback((index: number, updateParams: Partial<InterviewFileTmpData>) => {
    setFileTmpDataList((currentFile) => {
      const newFile = [...currentFile];
      newFile[index] = { ...newFile[index], ...updateParams };
      return newFile;
    });
  }, []);

  useEffect(() => {
    setFileTmpDataList(
      files.map((f) => ({
        file: f,
        employee: null,
        status: "pending",
        percentage: 0,
        comment: "",
        tmpFilename: undefined,
      })),
    );
    (async () => {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setInterviewFileTmpData(i, { status: "uploading" });
        try {
          const { tmpFilename, suggest } = await uploadInterviewFile(file, (percentage) =>
            setInterviewFileTmpData(i, { percentage }),
          );
          setInterviewFileTmpData(i, { status: "uploaded", tmpFilename: tmpFilename, employee: suggest });
        } catch {
          setInterviewFileTmpData(i, { status: "error" });
        }
      }
    })();
  }, [files, setInterviewFileTmpData, uploadInterviewFile]);

  return { fileTmpDataList, setInterviewFileTmpData };
};

export const getUploadStatus = (interviewTmpFile: InterviewFileTmpData): string => {
  switch (interviewTmpFile.status) {
    case "pending":
      return "待機中";
    case "uploading":
      return `アップロード中(${interviewTmpFile.percentage.toFixed(1)}%)`;
    case "uploaded":
      return "アップロード完了";
    case "error":
      return "アップロードエラー";
  }
};
