/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserGroupResponse,
    UserGroupResponseFromJSON,
    UserGroupResponseFromJSONTyped,
    UserGroupResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserGroupsResponse
 */
export interface UserGroupsResponse {
    /**
     * 
     * @type {number}
     * @memberof UserGroupsResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<UserGroupResponse>}
     * @memberof UserGroupsResponse
     */
    userGroups: Array<UserGroupResponse>;
}

export function UserGroupsResponseFromJSON(json: any): UserGroupsResponse {
    return UserGroupsResponseFromJSONTyped(json, false);
}

export function UserGroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'userGroups': ((json['userGroups'] as Array<any>).map(UserGroupResponseFromJSON)),
    };
}

export function UserGroupsResponseToJSON(value?: UserGroupsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'userGroups': ((value.userGroups as Array<any>).map(UserGroupResponseToJSON)),
    };
}


