import { useCallback } from "react";

import { defaultApi } from "../store/defaultApi";
import { useGetInterviewAudioFileUrlLazyQuery } from "../store/hooks/interview-files";

import { useHrPentestSnackbar } from "./useHrPentestSnackbar";

export const useDownloadInterviewFile = () => {
  const [getPresignedUrl] = useGetInterviewAudioFileUrlLazyQuery();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  return useCallback(
    async (interviewFileId: string) => {
      const res = await getPresignedUrl({ interviewFileId: interviewFileId });
      if (res.isSuccess) {
        const link = document.createElement("a");
        link.href = res.data.presignedUrl;
        link.click();
        link.remove();
      } else {
        enqueueErrorSnackbar({ title: "ダウンロードリンクの取得に失敗しました。" });
      }
    },
    [enqueueErrorSnackbar, getPresignedUrl],
  );
};

export const useDownloadHrInterviewFile = () => {
  const [getPresignedUrl] = defaultApi.endpoints.getInterviewFileDownloadUrl.useLazyQuery();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  return useCallback(
    async (interviewFileId: string) => {
      const res = await getPresignedUrl({ hrInterviewFileId: interviewFileId });
      if (res.isSuccess) {
        const link = document.createElement("a");
        link.href = res.data;
        link.click();
        link.remove();
      } else {
        enqueueErrorSnackbar({ title: "ダウンロードリンクの取得に失敗しました。" });
      }
    },
    [enqueueErrorSnackbar, getPresignedUrl],
  );
};
