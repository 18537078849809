import React from "react";

import { StepCard } from "../../atoms/cards/StepCard";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  steps: {
    children: React.ReactNode;
  }[];
}

export const StepCards: React.FC<Props> = (props) => {
  return (
    <>
      <AmeBox
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
          width: "1296px",
          columnGap: "24px",
        }}
      >
        {props.steps.map((step, i) => (
          <StepCard key={i} title={`STEP${i + 1}`}>
            {step.children}
          </StepCard>
        ))}
      </AmeBox>
    </>
  );
};
