import React, { useCallback, useContext, useState } from "react";

import { requireNonNull } from "@amecloud/common-utils/src/utils/Assertions";
import { promiseRetry } from "@amecloud/common-utils/src/utils/PromiseUtils";
import { createFileRoute } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { useSp } from "../../../../../hooks/useSp";
import { Employee } from "../../../../../models/Employee";
import type { CreateInterviewFilesRequestBody } from "../../../../../store/autogenApi";
import { useCompleteMultipartUpload, useCreateInterviewFiles } from "../../../../../store/hooks/interview-files";
import { useCreateInterviewAudioFileUploadUrlsLazyQuery } from "../../../../../store/hooks/interview-files";
import { useGetGroupListQuery } from "../../../../../store/hooks/userGroups";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { MyselfContext } from "../../../../organisms/common/MyselfContext";
import { InterviewFileRegisterContent } from "../../../../organisms/interview-files/register/InterviewFileRegisterContent";
import { SpInterviewFileRegisterContent } from "../../../../organisms/interview-files/register/SpInterviewFileRegisterContent";
import { InterviewFileTmpData } from "../../../../organisms/interview-files/register/common/useInterviewFileTmpDataList";

/** 10MB */
const DATA_SIZE_PER_CHUNK = 10 * 1024 * 1024;

/** 2GB */
const FILE_SIZE_LIMIT = 2 * 1024 * 1024 * 1024;

const getAudioDuration = (file: File): Promise<number> => {
  return new Promise((resolve) => {
    const audio = new Audio(URL.createObjectURL(file));
    audio.onloadedmetadata = () => {
      resolve(audio.duration);
      URL.revokeObjectURL(audio.src);
    };
  });
};

export const InterviewFileRegister: React.FC = () => {
  const { period } = useContext(DataSourceContext);
  const { myUserData } = useContext(MyselfContext);
  const userGroups = useGetGroupListQuery(10000, 0);
  const navigate = useNavigate();
  const [interviewFiles, setInterviewFiles] = useState<File[]>([]);
  const getPresignedUrl = useCreateInterviewAudioFileUploadUrlsLazyQuery();
  const completeMultipartUpload = useCompleteMultipartUpload();
  const createInterviewFilesAndLinkEmployees = useCreateInterviewFiles();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const linkInterviewFiles = useCallback(
    async (interviewFileMaps: InterviewFileTmpData[]) => {
      const body: CreateInterviewFilesRequestBody[] = [];
      for (const interviewFileMap of interviewFileMaps) {
        const duration = await getAudioDuration(interviewFileMap.file);
        body.push({
          tmpFilename: requireNonNull(interviewFileMap.tmpFilename),
          originalFilename: interviewFileMap.file.name,
          employeeTenureId: interviewFileMap.employee?.employeeTenureId,
          userGroupId: myUserData.isAdmin ? undefined : userGroups.data?.userGroups[0]?.userGroupId,
          duration: duration,
        });
      }
      const res = await createInterviewFilesAndLinkEmployees({ body: body });
      if (res.isSuccess) {
        void navigate({ to: "/interview-files", search: { page: 1 } });
      }
    },
    [userGroups, createInterviewFilesAndLinkEmployees, navigate, myUserData.isAdmin],
  );

  const uploadInterviewFile = useCallback(
    async (
      file: File,
      updatePercentage: (percentage: number) => void,
    ): Promise<{ tmpFilename: string; suggest?: Employee }> => {
      if (file.size > FILE_SIZE_LIMIT) {
        enqueueErrorSnackbar({
          title: "ファイルサイズが2GB以上です。",
          message: "ファイルのサイズを確認してください。",
        });
        throw new Error("音声データのアップロードに失敗しました。");
      }
      const splitFilename = file.name.split(".");
      const basename = splitFilename.slice(0, splitFilename.length - 1).join(".");
      const extension = splitFilename.length > 1 ? splitFilename[splitFilename.length - 1] : undefined;
      const chunkLength = Math.ceil(file.size / DATA_SIZE_PER_CHUNK);

      const res = await getPresignedUrl({
        createInterviewAudioFileUploadUrlsRequestBody: {
          fileBasename: basename,
          fileExtension: extension,
          partSize: chunkLength,
          fileSize: file.size,
        },
      });

      if (res.isSuccess) {
        if (chunkLength !== res.data.partUploadUrls.length) {
          enqueueErrorSnackbar({
            title: "レスポンスデータが不正です。",
            message: "chunkLengthとpartUploadUrlsのの長さが不一致です。",
          });
          throw new Error("音声データのアップロードに失敗しました。");
        }
        for (const i of Array.from(Array(chunkLength)).keys()) {
          try {
            await promiseRetry(async () => {
              const response = await fetch(res.data.partUploadUrls[i], {
                body: file.slice(i * DATA_SIZE_PER_CHUNK, (i + 1) * DATA_SIZE_PER_CHUNK),
                method: "put",
              });
              if (!response.ok) {
                throw new Error(`Failed to upload part ${i + 1} of ${chunkLength}`);
              }
            });
            updatePercentage && updatePercentage(((i + 1) / chunkLength) * 100);
          } catch {
            enqueueErrorSnackbar({
              title: "音声データのアップロードに失敗しました。",
              message: "ブラウザをリロードして再度お試しください。",
            });
          }
        }
        await completeMultipartUpload({
          uploadId: res.data.partUploadId,
          completeInterviewAudioFileUploadRequestBody: { tmpFilename: res.data.tmpFilename },
        });
        return {
          suggest: res.data.suggest && Employee.fromResponse(res.data.suggest),
          tmpFilename: res.data.tmpFilename,
        };
      }
      enqueueErrorSnackbar({ title: "音声データのアップロードに失敗しました。" });
      throw new Error("音声データのアップロードに失敗しました。");
    },
    [completeMultipartUpload, enqueueErrorSnackbar, getPresignedUrl],
  );

  const sp = useSp();
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "音声データ一覧", to: "/interview-files" }]}
        currentTitle="音声データ登録"
      />
      <PageLayoutBody type={"wide"}>
        <PaperWrapper>
          <PaperHeader>音声データ登録</PaperHeader>
          <PaperBody>
            {sp ? (
              <SpInterviewFileRegisterContent
                setInterviewFiles={setInterviewFiles}
                interviewFiles={interviewFiles}
                periodId={period.periodId}
                uploadInterviewFile={uploadInterviewFile}
                onSubmitInterviewFileMaps={linkInterviewFiles}
              />
            ) : (
              <InterviewFileRegisterContent
                setInterviewFiles={setInterviewFiles}
                interviewFiles={interviewFiles}
                periodId={period.periodId}
                uploadInterviewFile={uploadInterviewFile}
                onSubmitInterviewFileMaps={linkInterviewFiles}
              />
            )}
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/interview-files/register")({
  component: InterviewFileRegister,
});
