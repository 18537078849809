import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const CounterClockwise: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00119 3.17068C9.26194 3.17117 10.4886 3.57997 11.4974 4.33585C12.5063 5.09173 13.243 6.15401 13.5973 7.36357C13.9515 8.57314 13.9042 9.86488 13.4625 11.0453C13.0207 12.2258 12.2083 13.2314 11.1469 13.9115C10.0855 14.5917 8.83224 14.9097 7.57482 14.8181C6.31741 14.7265 5.12353 14.2301 4.17201 13.4032C3.2205 12.5764 2.56256 11.4636 2.29674 10.2316C2.03093 8.9996 2.17154 7.71466 2.69753 6.56924C2.75478 6.42998 2.75593 6.27398 2.70074 6.13389C2.64555 5.9938 2.5383 5.88048 2.40143 5.81764C2.26456 5.75481 2.10869 5.74733 1.96643 5.79677C1.82417 5.84621 1.70655 5.94874 1.6382 6.0829C1.00704 7.45747 0.83837 8.99947 1.15745 10.4779C1.47653 11.9564 2.26618 13.2917 3.40812 14.2838C4.55006 15.276 5.98281 15.8716 7.49178 15.9814C9.00074 16.0912 10.5047 15.7093 11.7783 14.8929C13.0519 14.0766 14.0267 12.8697 14.5566 11.453C15.0865 10.0363 15.143 8.48618 14.7176 7.03472C14.2923 5.58326 13.408 4.30864 12.1971 3.40178C10.9863 2.49493 9.51415 2.00465 8.00119 2.00439V3.17068Z"
        fill="currentColor"
      />
      <path
        d="M8.0006 4.8773V0.291462C8.00058 0.23606 7.98477 0.18181 7.95502 0.135065C7.92527 0.0883199 7.88281 0.0510152 7.83262 0.0275193C7.78243 0.00402326 7.72659 -0.00469148 7.67162 0.00239547C7.61666 0.00948242 7.56485 0.0320777 7.52226 0.0675355L4.76893 2.36046C4.73611 2.38782 4.7097 2.42206 4.69158 2.46076C4.67346 2.49945 4.66406 2.54166 4.66406 2.58438C4.66406 2.62711 4.67346 2.66931 4.69158 2.70801C4.7097 2.7467 4.73611 2.78094 4.76893 2.80831L7.52226 5.10123C7.56485 5.13669 7.61666 5.15928 7.67162 5.16637C7.72659 5.17346 7.78243 5.16474 7.83262 5.14124C7.88281 5.11775 7.92527 5.08044 7.95502 5.0337C7.98477 4.98695 8.00058 4.9327 8.0006 4.8773Z"
        fill="currentColor"
      />
      <path
        d="M7.78946 11.084C7.08746 11.084 6.61946 10.808 6.28946 10.478L6.69746 9.914C6.94946 10.16 7.26146 10.364 7.67546 10.364C8.13746 10.364 8.46146 10.07 8.46146 9.548C8.46146 9.032 8.16146 8.744 7.71146 8.744C7.44746 8.744 7.30346 8.81 7.04546 8.978L6.64346 8.714L6.76346 6.554H9.13946V7.298H7.52546L7.44146 8.24C7.62146 8.156 7.77146 8.114 7.97546 8.114C8.71946 8.114 9.34346 8.558 9.34346 9.524C9.34346 10.514 8.61146 11.084 7.78946 11.084Z"
        fill="currentColor"
      />
    </SvgWrapper>
  );
};
