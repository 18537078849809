import React, { useMemo } from "react";

import { useIsLinkedChoiceGroup } from "../../../../../hooks/filterHooks/useIsLinkedChoiceGroup";
import { useUploaderChoiceGroup } from "../../../../../hooks/filterHooks/useUploaderChoiceGroup";
import { useSelectedFilter } from "../../../../../hooks/useSelectedFilter";
import { User } from "../../../../../models/User";
import { HrInterviewFile } from "../../../../../models/hrInterview/HrInterviewFile";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import { ChoiceGroupProps } from "../../../../molecules/filter/ChoiceGroup/type";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { ReportFilterChipList } from "../../../v2/v2Component/ReportFilterChipList";
import { DisplayedInterviewsCount } from "../../../v2/v2Component/ReportHeader/DisplayedInterviewsCount";

import { InterviewFileListFilter } from "./InterviewFileListFilter";
import { InterviewFileTable } from "./InterviewFileTable";

export interface InterviewReportQuery {
  page: number;
  from?: string;
  to?: string;
  isLinked: boolean[];
  uploaderIds: string[];
}

interface Props {
  interviewFiles: HrInterviewFile[];
  totalCount: number;
  users: User[];
  searchParams: InterviewReportQuery;
  navigate: (opt: { search: (prev: InterviewReportQuery) => InterviewReportQuery }) => Promise<void>;
}

export const InterviewFileListContent: React.FC<Props> = ({
  searchParams,
  interviewFiles,
  users,
  navigate,
  totalCount,
}) => {
  const choiceGroups = useInterviewFilesChoiceGroups(navigate, searchParams, users);
  const selectedFilters = useSelectedFilter(choiceGroups);
  return (
    <AmeBox sx={{ display: "flex", gap: "24px" }}>
      <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
        <InterviewFileListFilter
          choiceGroups={choiceGroups}
          from={searchParams.from}
          to={searchParams.to}
          setDate={async (params) => {
            await navigate({
              search: (prev) => {
                const nextParams = { ...prev };
                if (params.from) {
                  nextParams.from = formatDateToYYYYMMDD(params.from);
                }
                if (params.to) {
                  nextParams.to = formatDateToYYYYMMDD(params.to);
                }
                return nextParams;
              },
            });
          }}
        />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, flexBasis: 0 }}>
        <AmeBox>
          <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "56px" }}>
            <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
              <DisplayedInterviewsCount count={totalCount} />
            </AmeBox>
            <AmeBox sx={{ flexGrow: 1, flexShrink: 1, overflowX: "scroll", margin: "0 16px", paddingTop: "8px" }}>
              <ReportFilterChipList selectedFilters={selectedFilters} />
            </AmeBox>
          </AmeBox>
        </AmeBox>
        <AmeBox>
          <InterviewFileTable interviewFiles={interviewFiles} />
        </AmeBox>
      </AmeBox>
    </AmeBox>
  );
};

const useInterviewFilesChoiceGroups = (
  navigate: (arg: { search: (prev: InterviewReportQuery) => InterviewReportQuery }) => Promise<void>,
  filterParams: InterviewReportQuery,
  uploaders: User[],
): ChoiceGroupProps[] => {
  const interviewersChoiceGroup = useUploaderChoiceGroup(uploaders, {
    navigate,
    filterParams,
  });
  const isLinedChoiceGroup = useIsLinkedChoiceGroup({
    navigate,
    filterParams,
  });
  return useMemo(() => [isLinedChoiceGroup, interviewersChoiceGroup], [isLinedChoiceGroup, interviewersChoiceGroup]);
};
