import { SubCategoryList, SubCategoryModel } from "../../models/SubCategory";
import { SubCategoryResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useSubCategoriesByCategoryIdQuery = (categoryId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getSubCategoryByCategoryId.useQuery(
    { categoryId: categoryId },
    { pollingInterval: options?.pollingInterval },
  );
  return useQueryResult({
    result,
    fromResponse: (subCategories: SubCategoryResponse[]) => subCategories.map(SubCategoryModel.fromResponse),
    onError: options?.onError,
  });
};

export const useGetSubCategoryBySubCategoryIdQuery = (subCategoryId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getSubCategoryBySubCategoryId.useQuery({ subCategoryId });
  return useQueryResult({
    result,
    fromResponse: SubCategoryModel.fromResponse,
    onError: options?.onError,
  });
};

export const useSubCategories = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getAllSubCategories.useQuery();
  return useQueryResult({
    result,
    fromResponse: SubCategoryList.fromResponse,
    onError: options?.onError,
  });
};
export const useCreateSubCategoryMutation = (options?: UseMutationOptions) => {
  const [createSubCategory] = defaultApi.endpoints.createSubCategory.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createSubCategory,
    onError: options?.onError,
  });
};

export const useUpdateSubCategoryMutation = (options?: UseMutationOptions) => {
  const [updateSubCategory] = defaultApi.endpoints.updateSubCategory.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateSubCategory,
    onError: options?.onError,
  });
};

export const useDeleteSubCategoryMutation = (options?: UseMutationOptions) => {
  const [deleteSubCategory] = defaultApi.endpoints.deleteSubCategory.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteSubCategory,
    onError: options?.onError,
  });
};
