import React, { useCallback, useContext } from "react";

import { Add } from "@mui/icons-material";
import { createFileRoute, Link } from "@tanstack/react-router";

import { useSp } from "../../../../../hooks/useSp";
import { useGetInterviewFilesQuery, useUpdateInterviewFile } from "../../../../../store/hooks/interview-files";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeIconButton } from "../../../../atoms/button/AmeIconButton";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmePagination } from "../../../../atoms/pagination/AmePagination";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { InterviewFileTable } from "../../../../organisms/interview-files/index/InterviewFileTable";

export const INTERVIEW_FILES_PER_PAGE = 10;

export const InterviewFiles: React.FC = () => {
  const { period } = useContext(DataSourceContext);
  const sp = useSp();
  const { page } = Route.useSearch();
  const navigate = Route.useNavigate();
  const onChangePage = useCallback(
    async (pageNumber: number) => {
      await navigate({
        search: (prev) => ({ ...prev, page: pageNumber }),
      });
    },
    [navigate],
  );
  const offset = (page - 1) * INTERVIEW_FILES_PER_PAGE;
  const interviewFileQueryState = useGetInterviewFilesQuery(INTERVIEW_FILES_PER_PAGE, offset, {
    pollingInterval: 1000 * 30,
  });
  const updateInterviewFileRaw = useUpdateInterviewFile();
  const updateInterviewFile = useCallback(
    async (interviewFileId: string, employeeTenureId: string | null) => {
      await updateInterviewFileRaw({
        interviewRecordingId: interviewFileId,
        updateInterviewFileRequestBody: {
          employeeTenureId,
        },
      });
    },
    [updateInterviewFileRaw],
  );

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader
        title="音声データ一覧"
        endElement={<AmeButton to={"/interview-files/register"}>音声データ登録</AmeButton>}
      />
      {sp ? (
        <AmeBox sx={{ position: "fixed", bottom: "24px", right: "24px" }}>
          <Link to={"/interview-files/register"}>
            <AmeIconButton color={"primary"}>
              <Add color={"inherit"} />
            </AmeIconButton>
          </Link>
        </AmeBox>
      ) : null}
      <PageLayoutBody type="wide">
        <WaitForSuccess queryState={interviewFileQueryState}>
          {(interviewList) => (
            <React.Fragment>
              <InterviewFileTable
                interviewFiles={interviewList.interviewFiles}
                updateInterviewFile={updateInterviewFile}
                periodId={period.periodId}
              />
              <Spacer height="24px" />
              <AmeBox sx={{ display: "flex", justifyContent: "center" }}>
                <AmePagination
                  count={Math.ceil(interviewList.totalCount / INTERVIEW_FILES_PER_PAGE)}
                  onChangePage={onChangePage}
                  page={page}
                />
              </AmeBox>
            </React.Fragment>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type QueryParams = {
  page: number;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/interview-files/")({
  component: InterviewFiles,
  validateSearch: (search: Partial<QueryParams>) => ({ page: search.page ?? 1 }),
});
