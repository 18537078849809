import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { SubCategoryRuleViewer } from "../../../../../../../organisms/settings/rules/SubCateogoryRuleViewer";

const RuleSettingsBySubCategory: React.FC = () => {
  const { categoryId } = Route.useParams();
  return <SubCategoryRuleViewer categoryId={categoryId} />;
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-admin/settings/rules/sub-categories/$categoryId/",
)({
  component: RuleSettingsBySubCategory,
});
