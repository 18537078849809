/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackRating,
    InterviewFeedbackRatingFromJSON,
    InterviewFeedbackRatingFromJSONTyped,
    InterviewFeedbackRatingToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFeedbackQuestionAnswer
 */
export interface InterviewFeedbackQuestionAnswer {
    /**
     * 
     * @type {InterviewFeedbackRating}
     * @memberof InterviewFeedbackQuestionAnswer
     */
    rating: InterviewFeedbackRating;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackQuestionAnswer
     */
    answer: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackQuestionAnswer
     */
    interviewFeedbackAnswerId: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackQuestionAnswer
     */
    question: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewFeedbackQuestionAnswer
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackQuestionAnswer
     */
    interviewFeedbackQuestionId: string;
}

export function InterviewFeedbackQuestionAnswerFromJSON(json: any): InterviewFeedbackQuestionAnswer {
    return InterviewFeedbackQuestionAnswerFromJSONTyped(json, false);
}

export function InterviewFeedbackQuestionAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFeedbackQuestionAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rating': InterviewFeedbackRatingFromJSON(json['rating']),
        'answer': json['answer'],
        'interviewFeedbackAnswerId': json['interviewFeedbackAnswerId'],
        'question': json['question'],
        'priority': json['priority'],
        'interviewFeedbackQuestionId': json['interviewFeedbackQuestionId'],
    };
}

export function InterviewFeedbackQuestionAnswerToJSON(value?: InterviewFeedbackQuestionAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': InterviewFeedbackRatingToJSON(value.rating),
        'answer': value.answer,
        'interviewFeedbackAnswerId': value.interviewFeedbackAnswerId,
        'question': value.question,
        'priority': value.priority,
        'interviewFeedbackQuestionId': value.interviewFeedbackQuestionId,
    };
}


