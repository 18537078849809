import React from "react";

import { SimplePaper } from "../../../atoms/paper/SimplePaper";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

export const StartingAnalysis: React.FC = () => {
  // const theme = useAmeTheme();
  // const turnoverSummariesQuery = useTurnoverSummariesBySegmentQuery(
  //   props.period.periodId,
  //   SegmentKey.MONTHS_OF_SERVICE,
  // );
  return (
    <>
      <AmeBox sx={{ paddingBottom: "24px" }}>
        <AmeTypography component="h4">分析をはじめましょう</AmeTypography>
      </AmeBox>
      <SimplePaper>
        <div>
          <a href={"/employee-report"} style={{ color: "rgb(0, 172, 193)" }}>
            従業員レポート
          </a>
          にて離職の推移を確認できます。
        </div>
        <div>
          <a href={"/interview-report"} style={{ color: "rgb(0, 172, 193)" }}>
            インタビューレポート
          </a>
          にて退職理由を確認できます。
        </div>
        <div>
          <a href={"/survey-report"} style={{ color: "rgb(0, 172, 193)" }}>
            アンケートレポート
          </a>
          にてアンケートを確認できます。
        </div>
      </SimplePaper>
    </>
  );
};
