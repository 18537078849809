import React, { useMemo } from "react";

import { Column } from "react-table";

import { useParallelController } from "../../../../../hooks/useParallelController";
import { ComputationTaskStatus } from "../../../../../store/hooks/computationTasks";
import { RuleModel, useGetRulesQueryByCategoryId } from "../../../../../store/hooks/rules";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { TaskStatusIcon } from "../../../../molecules/TaskStatusIcon";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { RuleStatsCell } from "../RuleStatsCell";
import { RuleDeleteButton } from "../RuleTable/RuleDeleteButton";
import { RuleTable } from "../RuleTable/RuleTable";

import { SubCategoryCell } from "./SubCategoryCell";

interface Props {
  defaultSubCategoryId?: string;
  categoryId: string;
}

export const CategoryRuleTable: React.FC<Props> = ({ categoryId, defaultSubCategoryId }) => {
  const { isSuccess, data: rules } = useGetRulesQueryByCategoryId(categoryId, { pollingInterval: 1000 * 15 });
  return isSuccess ? (
    <MemoizedRuleTableWrapper
      data={rules.sort((a, b) => a.ruleId.localeCompare(b.ruleId))}
      subCategoryId={defaultSubCategoryId}
      categoryId={categoryId}
    />
  ) : null;
};

const MemoizedRuleTableWrapper = React.memo<{ data: RuleModel[]; subCategoryId?: string; categoryId: string }>(
  function RuleTableWrapper(props) {
    const fetchController = useParallelController();
    const columns = useMemo(
      () => generateColumns(props.categoryId, fetchController),
      [fetchController, props.categoryId],
    );
    return (
      <RuleTable
        data={props.data}
        columns={columns}
        subCategoryId={props.subCategoryId}
        categoryId={props.categoryId}
        categoryType="category"
      />
    );
  },
);

const generateColumns: (
  categoryId: string,
  fetchController: (task: () => Promise<void>) => Promise<void>,
) => Column<RuleModel>[] = (categoryId, fetchController) => [
  {
    Header: "ルール",
    accessor: "esQuery",
    width: "unset",
    Cell: (data) => {
      const esQuery = data.row.original.esQuery;
      const computationTaskStatus = data.row.original.computationTaskStatus;
      return (
        <AmeBox sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <AmeTypography omit>{esQuery}</AmeTypography>
          <TaskStatusIcon
            taskStatus={computationTaskStatus}
            DefaultIcon={(props) => <TaskStatusIcon size={props.size} taskStatus={ComputationTaskStatus.SUCCESSFUL} />}
          />
        </AmeBox>
      );
    },
  },
  {
    Header: "サブカテゴリー",
    accessor: "subCategoryId",
    width: 291,
    Cell: (data) => {
      return <SubCategoryCell subCategoryId={data.value} categoryId={categoryId} ruleId={data.row.original.ruleId} />;
    },
  },
  {
    Header: "Precision",
    id: "precision",
    accessor: "ruleId",
    disableSortBy: true,
    Cell: (data) =>
      data.row.original.computationTaskStatus === ComputationTaskStatus.SUCCESSFUL ? (
        <RuleStatsCell data={data} fetchController={fetchController}>
          {(stats) => <>{stats.precision.toFixed(2)}</>}
        </RuleStatsCell>
      ) : (
        <>-</>
      ),
    width: 120,
  },
  {
    Header: "ヒット件数",
    id: "hitCount",
    accessor: "ruleId",
    disableSortBy: true,
    Cell: (data) =>
      data.row.original.computationTaskStatus === ComputationTaskStatus.SUCCESSFUL ? (
        <RuleStatsCell data={data} fetchController={fetchController}>
          {(stats) => <>{`${stats.hitCount}件`}</>}
        </RuleStatsCell>
      ) : (
        <>-</>
      ),
    width: 120,
  },
  {
    accessor: "ruleId",
    width: 56,
    id: "delete-button",
    Cell: (data) => <RuleDeleteButton ruleId={data.row.original.ruleId} />,
  },
];
