import React, { useCallback, useContext, useState } from "react";

import { SelectChangeEvent } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";

import { useGetSegmentContent } from "../../../../../hooks/filterHooks/useSegmentChoiceGroup";
import { useSelectedFilter } from "../../../../../hooks/useSelectedFilter";
import { InterviewKpiReportFilters, useGetInterviewKpiReport } from "../../../../../store/hooks/interviews";
import { Period } from "../../../../../store/hooks/periods";
import { formatNumber } from "../../../../../utils/formatNumber";
import { ColumnsWithRatioChart } from "../../../../atoms/charts/ColumnsWithRatioChart";
import { LineChart } from "../../../../atoms/charts/LineChart";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { SimplePaper } from "../../../../atoms/paper/SimplePaper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { V2ContentWrapper } from "../../../../atoms/wrapper/V2ContentWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { ChoiceGroupProps } from "../../../../molecules/filter/ChoiceGroup/type";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { InterviewerFilter } from "../../../../organisms/v2/InterviewerFilter";
import { ReportHeader } from "../../../../organisms/v2/v2Component/ReportHeader";

export const InterviewKpiReport: React.FC = () => {
  const { periods } = useContext(DataSourceContext);

  const search = Route.useSearch();
  const { periodId } = search;
  const navigate = Route.useNavigate();
  const setPeriod = useCallback(
    async (periodId: number) => {
      await navigate({
        search: (prev) => ({
          ...prev,
          periodId: periodId,
        }),
      });
    },
    [navigate],
  );

  const basePeriod = periods.find((p) => p.periodId === periodId);
  const filterGroups = [useGetSegmentContent(search, navigate)];

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"インタビューKPIレポート"} />
      <PageLayoutBody type={"wide"}>
        <AmeBox sx={{ display: "flex", gap: "24px" }}>
          <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
            <InterviewerFilter
              currentPeriodId={basePeriod?.periodId}
              periods={periods}
              setPeriod={setPeriod}
              choiceGroups={filterGroups}
            />
          </AmeBox>
          <AmeBox sx={{ flexGrow: 1, minWidth: 0 }}>
            {basePeriod ? (
              <InterviewKpiReportContent basePeriod={basePeriod} segmentFilters={search} filterGroups={filterGroups} />
            ) : (
              <>期間を選択してください</>
            )}
          </AmeBox>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const InterviewKpiReportContent: React.FC<{
  basePeriod: Period;
  segmentFilters: InterviewKpiReportFilters;
  filterGroups: ChoiceGroupProps[];
}> = ({ basePeriod, segmentFilters, filterGroups }) => {
  const {
    setting: {
      globalSetting: { maxMinutesInKpiReport, maxScaleValueInKpiReport, maxPercentageInKpiReport },
    },
  } = useContext(DataSourceContext);
  const [selectedSegment, setSelectedSegment] = useState("NONE");
  const onChangeAxis = useCallback(
    (e: SelectChangeEvent) => {
      setSelectedSegment(e.target.value);
    },
    [setSelectedSegment],
  );
  const reportDataQueryState = useGetInterviewKpiReport(basePeriod.periodId, segmentFilters);
  const selectedFilters = useSelectedFilter(filterGroups);

  return (
    <>
      <ReportHeader
        selectedFilters={selectedFilters}
        axisDisplayNames={{ NONE: "軸なし" }}
        selectedAxis={selectedSegment}
        onChangeAxis={onChangeAxis}
      />
      <SimplePaper>
        <V2ContentWrapper title="データ取得率">
          <WaitForSuccess queryState={reportDataQueryState}>
            {({ data }) => {
              return (
                <ColumnsWithRatioChart
                  seriesAll={data.map(({ turnoverCount }) => turnoverCount)}
                  seriesSome={data.map(({ interviewCount }) => interviewCount)}
                  seriesLabels={data.map(({ axis }) => axis)}
                  names={["退職者数", "インタビュー数", "インタビュー取得率"]}
                  columnMax={maxScaleValueInKpiReport}
                  ratioMax={maxPercentageInKpiReport !== undefined ? maxPercentageInKpiReport / 100 : 1}
                  height={300}
                />
              );
            }}
          </WaitForSuccess>
        </V2ContentWrapper>
      </SimplePaper>
      <Spacer height={"24px"} />
      <SimplePaper>
        <V2ContentWrapper title="録音時間長さ">
          <WaitForSuccess queryState={reportDataQueryState}>
            {({ data }) => {
              return (
                <LineChart
                  series={data.map(({ avgDuration }) => avgDuration / 60)}
                  seriesLabels={data.map(({ axis }) => axis)}
                  height={300}
                  formatter={(value) => `${formatNumber(value)}分`}
                  max={maxMinutesInKpiReport || 60}
                />
              );
            }}
          </WaitForSuccess>
        </V2ContentWrapper>
      </SimplePaper>
    </>
  );
};

type InterviewKpiReportSearch = {
  periodId?: number;
} & InterviewKpiReportFilters;

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/interview-kpi-report/")({
  validateSearch: (search: Partial<InterviewKpiReportSearch>): InterviewKpiReportSearch => ({
    periodId: search.periodId,
    SEX: search.SEX || [],
    DEPARTMENT: search.DEPARTMENT || [],
    FREE1: search.FREE1 || [],
    FREE2: search.FREE2 || [],
    AGE: search.AGE || [],
    MONTHS_OF_SERVICE: search.MONTHS_OF_SERVICE || [],
  }),
  component: InterviewKpiReport,
});
