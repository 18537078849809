import React, { useState } from "react";

import { SubCategoryModel } from "../../../../../models/SubCategory";
import { CategoryModel } from "../../../../../store/hooks/categories";
import { AmePagination } from "../../../../atoms/pagination/AmePagination";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DEFAULT_ROWS_PER_PAGE, RuleListHeader } from "../RuleListHeader";
import { RuleRegisterProvider } from "../RuleRegisterContext";

interface Props<Model extends CategoryModel | SubCategoryModel> {
  categoryId?: string;
  allItems: Model[];
  children: (currentPageItems: Model[]) => React.ReactNode;
}

export const RulePaginationContainer = <Model extends CategoryModel | SubCategoryModel>(props: Props<Model>) => {
  const [page, setPage] = useState<number>(0);
  const currentPageItems = props.allItems.slice(page * DEFAULT_ROWS_PER_PAGE, (page + 1) * DEFAULT_ROWS_PER_PAGE);
  return (
    <React.Fragment>
      <RuleListHeader categoryId={props.categoryId} itemsLength={props.allItems.length} page={page} setPage={setPage} />
      <RuleRegisterProvider>{props.children(currentPageItems)}</RuleRegisterProvider>
      <AmeBox sx={{ display: "flex", justifyContent: "center" }}>
        <AmePagination
          onChangePage={(page) => {
            setPage(page - 1);
          }}
          count={Math.ceil(props.allItems.length / DEFAULT_ROWS_PER_PAGE)}
        />
      </AmeBox>
    </React.Fragment>
  );
};
