import React from "react";

import { Control, UseFormRegister, useFieldArray, useFormState } from "react-hook-form";

import { SubCategoryModel } from "../../../../../models/SubCategory";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";
import { EditableCell } from "../../../../atoms/table/EditableCell";

import { QuestionAddingRow } from "./QuestionAddingRow";
import { QuestionSettingFormType } from "./SurveyQuestionSettingContent";
import { QuestionSettingOperationsCell } from "./cells/QuestionSettingOperationsCell";
import { SubCategorySelectCell } from "./cells/SubCategorySelectCell";

interface Props {
  register: UseFormRegister<QuestionSettingFormType>;
  control: Control<QuestionSettingFormType, never>;
  subCategories: SubCategoryModel[];
}

export const QuestionSettingTable: React.FC<Props> = (props) => {
  const { fields, append, swap, remove } = useFieldArray({
    control: props.control,
    name: "questions",
  });
  const formState = useFormState({ control: props.control, name: "questions" });

  return (
    <React.Fragment>
      <AmeTable useCustomCellWidth>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell width="64px">順番</AmeTableHeadCell>
            <AmeTableHeadCell>設問</AmeTableHeadCell>
            <AmeTableHeadCell width="320px">紐付き先カテゴリ</AmeTableHeadCell>
            <AmeTableHeadCell width="196px">操作</AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {fields.map((field, index) => {
            return (
              <AmeTableRow key={field.id} isDense>
                <AmeTableBodyCell>{index + 1}</AmeTableBodyCell>
                <AmeTableBodyCell noPadding>
                  <EditableCell
                    {...props.register(`questions.${index}.questionText`, { required: "必須項目です。" })}
                    error={Boolean(formState.errors.questions?.[index]?.questionText)}
                  />
                </AmeTableBodyCell>
                <AmeTableBodyCell noPadding>
                  <SubCategorySelectCell
                    index={index}
                    control={props.control}
                    subCategoryId={field.subCategoryId}
                    subCategories={props.subCategories}
                  />
                </AmeTableBodyCell>
                <AmeTableBodyCell>
                  <QuestionSettingOperationsCell
                    index={index}
                    rowLastIndex={fields.length - 1}
                    swap={swap}
                    remove={remove}
                  />
                </AmeTableBodyCell>
              </AmeTableRow>
            );
          })}
        </AmeTableBody>
      </AmeTable>
      <QuestionAddingRow append={append} />
    </React.Fragment>
  );
};
