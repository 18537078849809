import React, { useCallback, useEffect, useState } from "react";

import { UserGroup } from "../../../../../../../models/UserGroup";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { AmeInput } from "../../../../../../atoms/forms/input/AmeInput";
import { Search } from "../../../../../../atoms/icon/Search";
import { ChoosableList } from "../../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../../atoms/list/ChoosableListItem";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { SPDrawer } from "../../../../../../molecules/drawer/SPDrawer";
import { AmeDropboxButton } from "../../../../../../molecules/dropbox/AmeDropboxButton";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface Props {
  value?: UserGroup;
  title: string;
  placeholder?: string;
  getSuggests: (search: string) => Promise<UserGroup[]>;
  onSubmit: (suggest: UserGroup | undefined) => void;
}

export const SpBeloingingUserGroupSearchDropDown: React.FC<Props> = ({
  value,
  title,
  placeholder,
  onSubmit,
  getSuggests,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<UserGroup | undefined>(value);
  const [suggests, setSuggests] = useState<UserGroup[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  const onClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    (async () => {
      if (searchInput === "") {
        setSuggests([]);
        return;
      }
      const suggestsResult = await getSuggests(searchInput);
      setSuggests(suggestsResult);
    })();
  }, [getSuggests, searchInput]);

  const onClickSubmitButton = useCallback(
    (data: UserGroup | undefined) => {
      onSubmit(data);
      onClose();
    },
    [onClose, onSubmit],
  );

  return (
    <>
      <AmeDropboxButton onClick={onClick} displaySelected={selected?.displayName} />
      <SPDrawer open={isOpen} title={title} onClose={onClose}>
        <AmeInput
          placeholder={placeholder}
          fullWidth
          endIcon={<Search size={16} />}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Spacer height={"24px"} />
        <ChoosableList selectedValue={selected} onSelect={setSelected}>
          <ChoosableListItem>選択なし</ChoosableListItem>
          {suggests.map((suggest) => {
            return (
              <ChoosableListItem key={suggest.userGroupId} value={suggest.userGroupId}>
                {suggest.displayName}
              </ChoosableListItem>
            );
          })}
        </ChoosableList>
        {searchInput === "" ? (
          <>
            <Spacer height="16px" />
            <AmeTypography fontSize="body2">
              検索ボックスに従業員番号を入力してください。予測される従業員番号が表示されます。
            </AmeTypography>
          </>
        ) : null}
        <Spacer height={"24px"} />
        <AmeBox sx={{ position: "sticky", bottom: 0 }}>
          <AmeButton
            onClick={() => {
              onClickSubmitButton(value);
            }}
            fullWidth
          >
            確定
          </AmeButton>
        </AmeBox>
      </SPDrawer>
    </>
  );
};
