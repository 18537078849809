/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HrInterviewTranscriptionParagraphResponse,
    HrInterviewTranscriptionParagraphResponseFromJSON,
    HrInterviewTranscriptionParagraphResponseFromJSONTyped,
    HrInterviewTranscriptionParagraphResponseToJSON,
    HrInterviewTranscriptionStatus,
    HrInterviewTranscriptionStatusFromJSON,
    HrInterviewTranscriptionStatusFromJSONTyped,
    HrInterviewTranscriptionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewTranscriptionResponse
 */
export interface HrInterviewTranscriptionResponse {
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewTranscriptionResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewTranscriptionResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewTranscriptionResponse
     */
    summary?: string;
    /**
     * 
     * @type {Array<HrInterviewTranscriptionParagraphResponse>}
     * @memberof HrInterviewTranscriptionResponse
     */
    paragraphs: Array<HrInterviewTranscriptionParagraphResponse>;
    /**
     * 
     * @type {HrInterviewTranscriptionStatus}
     * @memberof HrInterviewTranscriptionResponse
     */
    status: HrInterviewTranscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewTranscriptionResponse
     */
    hrInterviewFileId: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewTranscriptionResponse
     */
    hrInterviewTranscriptionId: string;
}

export function HrInterviewTranscriptionResponseFromJSON(json: any): HrInterviewTranscriptionResponse {
    return HrInterviewTranscriptionResponseFromJSONTyped(json, false);
}

export function HrInterviewTranscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewTranscriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'paragraphs': ((json['paragraphs'] as Array<any>).map(HrInterviewTranscriptionParagraphResponseFromJSON)),
        'status': HrInterviewTranscriptionStatusFromJSON(json['status']),
        'hrInterviewFileId': json['hrInterviewFileId'],
        'hrInterviewTranscriptionId': json['hrInterviewTranscriptionId'],
    };
}

export function HrInterviewTranscriptionResponseToJSON(value?: HrInterviewTranscriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'summary': value.summary,
        'paragraphs': ((value.paragraphs as Array<any>).map(HrInterviewTranscriptionParagraphResponseToJSON)),
        'status': HrInterviewTranscriptionStatusToJSON(value.status),
        'hrInterviewFileId': value.hrInterviewFileId,
        'hrInterviewTranscriptionId': value.hrInterviewTranscriptionId,
    };
}


