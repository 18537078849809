/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TranscriptionSentence,
    TranscriptionSentenceFromJSON,
    TranscriptionSentenceFromJSONTyped,
    TranscriptionSentenceToJSON,
} from './';

/**
 * 
 * @export
 * @interface TranscriptionResult
 */
export interface TranscriptionResult {
    /**
     * 
     * @type {number}
     * @memberof TranscriptionResult
     */
    speakers: number;
    /**
     * 
     * @type {Array<TranscriptionSentence>}
     * @memberof TranscriptionResult
     */
    sentences: Array<TranscriptionSentence>;
}

export function TranscriptionResultFromJSON(json: any): TranscriptionResult {
    return TranscriptionResultFromJSONTyped(json, false);
}

export function TranscriptionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptionResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'speakers': json['speakers'],
        'sentences': ((json['sentences'] as Array<any>).map(TranscriptionSentenceFromJSON)),
    };
}

export function TranscriptionResultToJSON(value?: TranscriptionResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'speakers': value.speakers,
        'sentences': ((value.sentences as Array<any>).map(TranscriptionSentenceToJSON)),
    };
}


