import React, { useCallback, useMemo, useState } from "react";

import { ComputationTaskStatus } from "../../../../../store/hooks/computationTasks";
import { filterRulesBySubCategoryId, useGetRulesQueryByCategoryId } from "../../../../../store/hooks/rules";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { CategoryMenu } from "../CategoryList/CategoryMenu";
import { EditableCategoryCell } from "../common/EditableCategoryCell";

import { SubCategoryRuleTable } from "./SubCategoryRuleTable";

interface Props {
  categoryId: string;
  subCategoryId: string;
  displayName: string;
  computationTaskStatus: ComputationTaskStatus | null;
  updateSubCategory: (args: { subCategoryId: string; displayName: string }) => void;
  deleteSubCategory: (args: { subCategoryId: string }) => void;
}

export const SubCategoryInfo: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: categoryRules } = useGetRulesQueryByCategoryId(props.categoryId);
  const deletable = useMemo(
    () => (categoryRules ? filterRulesBySubCategoryId(categoryRules, props.subCategoryId).length === 0 : false),
    [props, categoryRules],
  );

  const onDelete = useCallback(() => {
    props.deleteSubCategory({ subCategoryId: props.subCategoryId });
  }, [props]);

  return (
    <AmeBox sx={{ overflow: "hidden", marginBottom: "8px" }}>
      <AmeBox sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <AmeBox sx={{ flexGrow: 1, flexShrink: 1, overflow: "hidden" }}>
          <EditableCategoryCell
            id={props.subCategoryId}
            displayName={props.displayName}
            update={({ id, displayName }) => props.updateSubCategory({ subCategoryId: id, displayName })}
            computationTaskStatus={props.computationTaskStatus}
          />
        </AmeBox>
        <AmeBox sx={{ flexShrink: 0 }}>
          <CategoryMenu
            categoryId={props.categoryId}
            subCategoryId={props.subCategoryId}
            displayName={props.displayName}
            onDelete={onDelete}
            deletable={deletable}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        </AmeBox>
      </AmeBox>
      {isOpen ? <SubCategoryRuleTable subCategoryId={props.subCategoryId} categoryId={props.categoryId} /> : null}
    </AmeBox>
  );
};
