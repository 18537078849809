import React, { useCallback } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { InputSegmentKey } from "../../../../../../models/constants/values";
import { useGetSegmentDefinition } from "../../../../../../store/hooks/segmentDefinitions";
import { useGetSetting, useRegisterSegmentName } from "../../../../../../store/hooks/settings";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { SettingsSegmentsTable } from "../../../../../organisms/settings/segments/SettingsSegmentTable";

const SettingsSegments: React.FC = () => {
  const settingQuery = useGetSetting();
  const register = useRegisterSegmentName();
  const registerSegmentName = useCallback(
    async (segmentKey: keyof typeof InputSegmentKey, displayName: string) => {
      await register({ segmentKey: segmentKey, registerSegmentNameRequestBody: { name: displayName } });
    },
    [register],
  );
  // FIXME: 全取得APIを作る
  const sexSegmentDefinition = useGetSegmentDefinition(InputSegmentKey.SEX);
  const departmentSegmentDefinition = useGetSegmentDefinition(InputSegmentKey.DEPARTMENT);
  const free1SegmentDefinition = useGetSegmentDefinition(InputSegmentKey.FREE1);
  const free2SegmentDefinition = useGetSegmentDefinition(InputSegmentKey.FREE2);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[{ title: "設定", to: "/settings" }]} currentTitle="属性定義設定" />
      <PageLayoutBody type="wide">
        <AmeBox sx={{ paddingBottom: "24px" }}>
          <AmeTypography component="h6">属性定義一覧</AmeTypography>
        </AmeBox>
        {/* TODO: 全取得するAPIつくってこのクソネスト解消する */}
        <WaitForSuccess queryState={sexSegmentDefinition}>
          {(sex) => (
            <WaitForSuccess queryState={departmentSegmentDefinition}>
              {(department) => (
                <WaitForSuccess queryState={free1SegmentDefinition}>
                  {(free1) => (
                    <WaitForSuccess queryState={free2SegmentDefinition}>
                      {(free2) => (
                        <WaitForSuccess queryState={settingQuery}>
                          {(setting) => (
                            <SettingsSegmentsTable
                              registerSegmentName={registerSegmentName}
                              definitionDisplayNames={setting.segmentNames}
                              sexSegments={sex}
                              departmentSegments={department}
                              free1Segments={free1}
                              free2Segments={free2}
                            />
                          )}
                        </WaitForSuccess>
                      )}
                    </WaitForSuccess>
                  )}
                </WaitForSuccess>
              )}
            </WaitForSuccess>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/segments/")({
  component: SettingsSegments,
});
