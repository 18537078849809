import React, { useCallback, useEffect, useState } from "react";

import { requireNonNull } from "@amecloud/common-utils/src/utils/Assertions";
import { promiseRetry } from "@amecloud/common-utils/src/utils/PromiseUtils";
import { createFileRoute } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../../hooks/useHrPentestSnackbar";
import { UserGroupRef } from "../../../../../../models/UserGroup";
import {
  useCompleteHrInterviewFileUpload,
  useCreateHrInterviewFile,
  useGetHrInterviewFileUploaderUrl,
} from "../../../../../../store/hooks/hrInterviewFiles";
import { useGetMyUserGroupQuery } from "../../../../../../store/hooks/userGroups";
import { getAudioDuration } from "../../../../../../utils/AudioUtils";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { FileProperties } from "../../../../../organisms/hr-interviews/InterviewFileNameMapper";
import { CreateInterviewFileContent } from "../../../../../organisms/hr-interviews/interview-files/create";

/** 2GB */
const FILE_SIZE_LIMIT = 2 * 1024 * 1024 * 1024;

const InterviewFileCreatePage: React.FC = () => {
  const getPresignedUrl = useGetHrInterviewFileUploaderUrl();
  const completeMultipartUpload = useCompleteHrInterviewFileUpload();
  const createHrInterviewFileRaw = useCreateHrInterviewFile();
  const userGroupListQueryState = useGetMyUserGroupQuery();

  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const [interviewFiles, setInterviewFiles] = useState<File[]>([]);
  const [registerFileProperties, setRegisterFileProperties] = useState<FileProperties[]>([]);

  const setRegisterFileProperty = (index: number, updateParams: Partial<FileProperties>) => {
    setRegisterFileProperties((currentFile) => {
      const newFile = [...currentFile];
      newFile[index] = { ...newFile[index], ...updateParams };
      return newFile;
    });
  };

  const removeFile = useCallback(
    (index: number) => {
      const tempRegisterFiles = [...registerFileProperties];
      tempRegisterFiles.splice(index, 1);
      setRegisterFileProperties(tempRegisterFiles);
    },
    [registerFileProperties],
  );

  const uploadInterviewFile = useCallback(
    async (file: File, updatePercentage: (percentage: number) => void): Promise<string> => {
      if (file.size > FILE_SIZE_LIMIT) {
        enqueueErrorSnackbar({
          title: "ファイルサイズが2GB以上です。",
          message: "ファイルのサイズを確認してください。",
        });
        throw new Error("音声データのアップロードに失敗しました。");
      }

      const res = await getPresignedUrl({
        getHrInterviewFileUploaderUrlBody: {
          fileSize: file.size,
        },
      });

      if (!res.isSuccess) {
        throw new Error("音声データのアップロードに失敗しました。");
      }
      for (const i of Array.from(Array(res.data.partUploadUrls.length)).keys()) {
        try {
          await promiseRetry(async () => {
            const response = await fetch(res.data.partUploadUrls[i], {
              body: file.slice(i * res.data.chunkSize, (i + 1) * res.data.chunkSize),
              method: "put",
            });
            if (!response.ok) {
              throw new Error(`Failed to upload part ${i + 1} of ${res.data.partUploadUrls.length}`);
            }
            updatePercentage(((i + 1) / res.data.partUploadUrls.length) * 100);
          });
        } catch {
          enqueueErrorSnackbar({
            title: "音声データのアップロードに失敗しました。",
            message: "ブラウザをリロードして再度お試しください。",
          });
        }
      }
      await completeMultipartUpload({
        uploadId: res.data.multipartUploadId,
        completeHrInterviewFileUploadBody: { filename: res.data.filename },
      });
      return res.data.filename;
    },
    [completeMultipartUpload, enqueueErrorSnackbar, getPresignedUrl],
  );

  useEffect(() => {
    setRegisterFileProperties(
      interviewFiles.map((registerFile) => ({
        file: registerFile,
        fileName: registerFile.name,
        status: "pending",
        percentage: 0,
        duration: 0,
      })),
    );

    (async () => {
      for (let i = 0; i < interviewFiles.length; i++) {
        const file = interviewFiles[i];
        setRegisterFileProperty(i, { status: "uploading" });
        try {
          const duration = await getAudioDuration(file);
          setRegisterFileProperty(i, { duration });
        } catch {
          enqueueErrorSnackbar({
            title: "音声データの読み込みに失敗しました。",
            message: "ファイル形式の確認をお願いします。ファイル名: " + file.name,
          });
          setRegisterFileProperty(i, { status: "error" });
          continue;
        }
        try {
          const s3FileName = await uploadInterviewFile(file, (percentage) =>
            setRegisterFileProperty(i, { percentage }),
          );
          setRegisterFileProperty(i, { status: "uploaded", s3FileName: s3FileName });
        } catch {
          setRegisterFileProperty(i, { status: "error" });
        }
      }
    })();
  }, [enqueueErrorSnackbar, interviewFiles, uploadInterviewFile]);

  const registerInterviewFiles = useCallback(
    async (fileProperties: FileProperties[], userGroup?: UserGroupRef) => {
      await createHrInterviewFileRaw({
        createHrInterviewFileRequestBody: {
          files: fileProperties.map((fileProperty) => ({
            s3FileName: requireNonNull(
              fileProperty.s3FileName,
              "s3FileNameがありません。fileName: " + fileProperty.fileName,
            ),
            originalFileName: fileProperty.fileName,
            duration: fileProperty.duration,
            userGroupId: userGroup?.userGroupId,
          })),
        },
      });
    },
    [createHrInterviewFileRaw],
  );

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "在職者音声一覧", to: "/hr-interviews/interview-files" }]}
        currentTitle={"音声データ登録"}
      />
      <PageLayoutBody>
        <WaitForSuccess queryState={userGroupListQueryState}>
          {(userGroupList) => (
            <CreateInterviewFileContent
              removeFile={removeFile}
              setFiles={setInterviewFiles}
              interviewFiles={interviewFiles}
              registerInterviewFile={registerInterviewFiles}
              registerFileProperties={registerFileProperties}
              defaultUserGroup={userGroupList.userGroups[0]}
            />
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
export const Route = createFileRoute("/_authenticated/_authorized-for-all/hr-interviews/interview-files/create")({
  component: InterviewFileCreatePage,
});
