/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChangeSetChangeType,
    ChangeSetChangeTypeFromJSON,
    ChangeSetChangeTypeFromJSONTyped,
    ChangeSetChangeTypeToJSON,
    ChangeSetErrorType,
    ChangeSetErrorTypeFromJSON,
    ChangeSetErrorTypeFromJSONTyped,
    ChangeSetErrorTypeToJSON,
    ChangeSetItemAttributeResponse,
    ChangeSetItemAttributeResponseFromJSON,
    ChangeSetItemAttributeResponseFromJSONTyped,
    ChangeSetItemAttributeResponseToJSON,
    EmployeeChangeSetItem2ResponseInfoChangeRequests,
    EmployeeChangeSetItem2ResponseInfoChangeRequestsFromJSON,
    EmployeeChangeSetItem2ResponseInfoChangeRequestsFromJSONTyped,
    EmployeeChangeSetItem2ResponseInfoChangeRequestsToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeChangeSetItem2Response
 */
export interface EmployeeChangeSetItem2Response {
    /**
     * 
     * @type {string}
     * @memberof EmployeeChangeSetItem2Response
     */
    customerCreatedId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeChangeSetItem2Response
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeChangeSetItem2Response
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeChangeSetItem2Response
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeChangeSetItem2Response
     */
    hireDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeChangeSetItem2Response
     */
    retireDate?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeChangeSetItem2Response
     */
    rowNumber: number;
    /**
     * 
     * @type {Array<ChangeSetItemAttributeResponse>}
     * @memberof EmployeeChangeSetItem2Response
     */
    attributes: Array<ChangeSetItemAttributeResponse>;
    /**
     * 
     * @type {EmployeeChangeSetItem2ResponseInfoChangeRequests}
     * @memberof EmployeeChangeSetItem2Response
     */
    infoChangeRequests: EmployeeChangeSetItem2ResponseInfoChangeRequests;
    /**
     * 
     * @type {ChangeSetChangeType}
     * @memberof EmployeeChangeSetItem2Response
     */
    changeType: ChangeSetChangeType;
    /**
     * 
     * @type {ChangeSetErrorType}
     * @memberof EmployeeChangeSetItem2Response
     */
    errorMessage?: ChangeSetErrorType;
}

export function EmployeeChangeSetItem2ResponseFromJSON(json: any): EmployeeChangeSetItem2Response {
    return EmployeeChangeSetItem2ResponseFromJSONTyped(json, false);
}

export function EmployeeChangeSetItem2ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeChangeSetItem2Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerCreatedId': json['customerCreatedId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'hireDate': !exists(json, 'hireDate') ? undefined : json['hireDate'],
        'retireDate': !exists(json, 'retireDate') ? undefined : json['retireDate'],
        'rowNumber': json['rowNumber'],
        'attributes': ((json['attributes'] as Array<any>).map(ChangeSetItemAttributeResponseFromJSON)),
        'infoChangeRequests': EmployeeChangeSetItem2ResponseInfoChangeRequestsFromJSON(json['infoChangeRequests']),
        'changeType': ChangeSetChangeTypeFromJSON(json['changeType']),
        'errorMessage': !exists(json, 'errorMessage') ? undefined : ChangeSetErrorTypeFromJSON(json['errorMessage']),
    };
}

export function EmployeeChangeSetItem2ResponseToJSON(value?: EmployeeChangeSetItem2Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerCreatedId': value.customerCreatedId,
        'name': value.name,
        'email': value.email,
        'dateOfBirth': value.dateOfBirth,
        'hireDate': value.hireDate,
        'retireDate': value.retireDate,
        'rowNumber': value.rowNumber,
        'attributes': ((value.attributes as Array<any>).map(ChangeSetItemAttributeResponseToJSON)),
        'infoChangeRequests': EmployeeChangeSetItem2ResponseInfoChangeRequestsToJSON(value.infoChangeRequests),
        'changeType': ChangeSetChangeTypeToJSON(value.changeType),
        'errorMessage': ChangeSetErrorTypeToJSON(value.errorMessage),
    };
}


