import React from "react";

import { Period } from "../../../store/hooks/periods";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { ChoiceGroup } from "../../molecules/filter/ChoiceGroup";
import { ChoiceGroupProps } from "../../molecules/filter/ChoiceGroup/type";
import { FilterSelect } from "../../molecules/filter/FilterSelect";
import { FilterWrapper } from "../../molecules/filter/FilterWrapper";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  currentPeriodId?: number;
  periods: Period[];
  setPeriod: (periodId: number) => void;
  choiceGroups: ChoiceGroupProps[];
};

export const InterviewerFilter: React.FC<Props> = ({ periods, choiceGroups, setPeriod, currentPeriodId }) => {
  const theme = useAmeTheme();
  return (
    <>
      <FilterWrapper>
        <AmeBox sx={{ borderBottom: `1px solid ${theme.common.border.gray40}` }}>
          <AmeBox sx={{ padding: "16px", flexShrink: 1 }}>
            <AmeTypography component="h6">{"対象期間"}</AmeTypography>
            <AmeTypography fontSize="body2">{"会計期間"}</AmeTypography>
            <FilterSelect
              options={periods.map((period) => ({
                queryValue: period.periodId.toString(),
                name: period.getDisplayName(),
              }))}
              selectedOption={currentPeriodId?.toString() ?? null}
              setFilterParams={(_, optionKey) => {
                setPeriod(parseInt(optionKey));
              }}
              filterParamKey="periodId"
            />
          </AmeBox>
        </AmeBox>
        {choiceGroups.map((filterGroup, index) => {
          return <ChoiceGroup key={index} {...filterGroup} />;
        })}
      </FilterWrapper>
    </>
  );
};
