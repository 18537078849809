import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const CheckV2Icon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 12 10">
      <path
        d="M10.1347 0.31882C10.3421 0.112066 10.618 -0.0022356 10.9043 3.31386e-05C11.1906 0.00230188 11.4649 0.120964 11.6692 0.33098C11.8736 0.540997 11.992 0.825941 11.9996 1.12569C12.0072 1.42543 11.9033 1.71653 11.7098 1.93756L5.83628 9.63046C5.73529 9.74438 5.61339 9.83581 5.47788 9.89927C5.34238 9.96273 5.19604 9.99692 5.04763 9.9998C4.89922 10.0027 4.75177 9.97418 4.61412 9.91602C4.47646 9.85786 4.35141 9.77122 4.24646 9.66129L0.351393 5.58205C0.242921 5.4762 0.15592 5.34855 0.095577 5.20672C0.0352345 5.06489 0.00278735 4.91178 0.000171818 4.75653C-0.00244371 4.60128 0.024826 4.44707 0.0803535 4.30309C0.135881 4.15912 0.218529 4.02834 0.323367 3.91854C0.428205 3.80875 0.553086 3.72219 0.690558 3.66404C0.828031 3.60588 0.97528 3.57732 1.12352 3.58006C1.27176 3.5828 1.41796 3.61678 1.55338 3.67998C1.68881 3.74318 1.8107 3.83429 1.91177 3.94789L4.99426 7.17459L10.1067 0.352737C10.1159 0.340868 10.1243 0.329544 10.1347 0.31882Z"
        fill="currentColor"
      />
    </SvgWrapper>
  );
};
