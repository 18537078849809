/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HrInterviewTranscriptionStatus {
    FAILED = 'FAILED',
    AI_TRANSCRIBING = 'AI_TRANSCRIBING',
    BEAUTIFYING = 'BEAUTIFYING',
    OVERALL_SUMMARIZING = 'OVERALL_SUMMARIZING',
    TOPIC_SUMMARIZING = 'TOPIC_SUMMARIZING',
    COMPLETED = 'COMPLETED'
}

export function HrInterviewTranscriptionStatusFromJSON(json: any): HrInterviewTranscriptionStatus {
    return HrInterviewTranscriptionStatusFromJSONTyped(json, false);
}

export function HrInterviewTranscriptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewTranscriptionStatus {
    return json as HrInterviewTranscriptionStatus;
}

export function HrInterviewTranscriptionStatusToJSON(value?: HrInterviewTranscriptionStatus | null): any {
    return value as any;
}

