import React from "react";

import { EMAIL_REG } from "@amecloud/common-utils/src/utils/Validation";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormRegister } from "react-hook-form/dist/types/form";

import { AmeFormset } from "../../../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../../../atoms/forms/input/AmeInput";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";

import { UserEditFormType } from "./UserEditor";

interface Props {
  register: UseFormRegister<UserEditFormType>;
  errors: FieldErrors<UserEditFormType>;
}

export const UserFormTemplate: React.FC<Props> = ({ errors, register }) => {
  return (
    <>
      <AmeFormset label={"姓"} required errorMessage={errors.surname?.message}>
        <AmeInput
          fullWidth
          {...register("surname", {
            required: "必須です。",
            maxLength: { value: 100, message: "100文字までにしてください。" },
          })}
          placeholder={"姓"}
        />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormset label={"名"} required errorMessage={errors.givenName?.message}>
        <AmeInput
          fullWidth
          {...register("givenName", {
            required: "必須です。",
            maxLength: { value: 100, message: "100文字までにしてください。" },
          })}
          placeholder={"名"}
        />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormset label={"eメールアドレス"} required errorMessage={errors.email?.message}>
        <AmeInput
          fullWidth
          {...register("email", {
            required: "必須です。",
            maxLength: { value: 100, message: "100文字までにしてください。" },
            pattern: { value: EMAIL_REG, message: "メールアドレスの形式が正しくありません。" },
          })}
          placeholder={"xxx@xxx.jp"}
        />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormset required label={"携帯電話番号（ハイフン除く）"} errorMessage={errors.phoneNumber?.message}>
        <AmeInput
          fullWidth
          {...register("phoneNumber", {
            required: "必須です。",
            maxLength: { value: 11, message: "11桁で入力してください。" },
            minLength: { value: 11, message: "11桁で入力してください。" },
            pattern: { value: /^(070|080|090)\d{8}$/, message: "形式を確認してください。" },
          })}
          placeholder={"09099991234"}
        />
      </AmeFormset>
    </>
  );
};
