import React, { useCallback } from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { useGetInterviewTypes } from "../../../../../../store/hooks/hr-interview-setting";
import { useUpdateHrInterview } from "../../../../../../store/hooks/hrInterview";
import { useGetGroupListQuery } from "../../../../../../store/hooks/userGroups";
import { formatDateToYYYYMMDD } from "../../../../../../utils/formatter";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeFormFollowingButtonsLayout } from "../../../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { CreateHrInterviewPageContent } from "../../create";
import { InterviewCreationFrom } from "../../create/InterviewCreationForm";

export const EditInterviewContent: React.FC<{ interviewDetail: HrInterviewDetail }> = ({ interviewDetail }) => {
  const { register, control, handleSubmit, formState, setValue } = useForm<InterviewCreationFrom>({
    mode: "onSubmit",
    defaultValues: {
      employeeTenure: {
        employeeTenureId: interviewDetail.employee?.employeeTenureId,
        displayName: interviewDetail.employee?.getEmployeeDisplayName(),
      },
      interviewTypeId: interviewDetail.interviewType.interviewTypeId,
      interviewDate: interviewDetail.interviewDate ? new Date(interviewDetail.interviewDate) : undefined,
      interviewFormatId: interviewDetail.interviewFormat.interviewFormatId,
      groupId: interviewDetail.userGroup?.userGroupId,
    },
  });

  const navigate = useNavigate();
  const interviewTypesQuery = useGetInterviewTypes();
  const userGroupListQueryState = useGetGroupListQuery();
  const updateHrInterview = useUpdateHrInterview();
  const clearFormatId = useCallback(() => {
    setValue("interviewFormatId", undefined as unknown as string);
  }, [setValue]);
  return (
    <>
      <PageLayoutBody>
        <PaperWrapper>
          <PaperHeader>面談概要</PaperHeader>
          <PaperBody>
            <WaitForSuccess queryState={userGroupListQueryState}>
              {({ userGroups }) => (
                <WaitForSuccess queryState={interviewTypesQuery}>
                  {(types) => (
                    <CreateHrInterviewPageContent
                      isDraft={interviewDetail.analysisStatus === undefined}
                      interviewTypes={types}
                      userGroups={userGroups}
                      register={register}
                      control={control}
                      formState={formState}
                      clearFormatId={clearFormatId}
                    />
                  )}
                </WaitForSuccess>
              )}
            </WaitForSuccess>
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
      <AmeFormFollowingButtonsLayout
        onPrimary={handleSubmit(async (data) => {
          const res = await updateHrInterview({
            hrInterviewId: interviewDetail.hrInterviewId,
            updateHrInterviewRequestBody: {
              employeeTenureId: data.employeeTenure?.employeeTenureId,
              userGroupId: data.groupId || null,
              interviewFormatId:
                formState.defaultValues?.interviewFormatId !== data.interviewFormatId
                  ? data.interviewFormatId
                  : undefined,
              interviewDate: data.interviewDate ? formatDateToYYYYMMDD(data.interviewDate) : undefined,
            },
          });
          if (res.isSuccess) {
            await navigate({
              to: "/hr-interviews/interviews/$interviewId",
              params: { interviewId: res.data.hrInterviewId },
            });
          }
        })}
        onSecondary={async () => {
          await navigate({
            to: "/hr-interviews/interviews",
            search: {} as never,
          });
        }}
        secondaryText={"キャンセル"}
      />
    </>
  );
};
