import React from "react";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { Caution } from "../../../../../atoms/icon/Caution";
import { EditIcon } from "../../../../../atoms/icon/Edit";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../../../../muiWrapper/AmeIconButton";
import { getUploadStatus, InterviewFileTmpData } from "../../common/useInterviewFileTmpDataList";

interface Props {
  interviewFileTmpDataList: InterviewFileTmpData[];
  changePage: (page: number) => void;
  onSubmitInterviewFileMaps: (interviewFileMaps: InterviewFileTmpData[]) => Promise<void>;
  cancelWizard: () => void;
}

export const FileMappingConfirmation: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  return (
    <>
      <AmeBox sx={{ "& > * + *": { borderTop: `1px solid ${theme.common.border.black}` } }}>
        {props.interviewFileTmpDataList.map((interviewFile, i) => (
          <AmeBox
            key={interviewFile.file.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
              padding: "8px 16px",
            }}
          >
            <AmeBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                alignItems: "start",
              }}
            >
              <AmeBox sx={{ flexShrink: 1 }}>
                <AmeTypography fontSize={"body2"} omit>
                  <AmeBox sx={{ fontWeight: "bold" }} component={"span"}>
                    {interviewFile.file.name}
                  </AmeBox>
                </AmeTypography>
              </AmeBox>
              <AmeBox sx={{ flexShrink: 1 }}>
                <AmeTypography omit fontSize={"body2"}>
                  {getUploadStatus(interviewFile)}
                </AmeTypography>
              </AmeBox>
            </AmeBox>
            <AmeBox
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0,
                alignItems: "center",
              }}
            >
              <AmeTypography omit fontSize={"caption"}>
                {interviewFile.employee ? (
                  interviewFile.employee.getEmployeeDisplayName()
                ) : (
                  <AmeBox sx={{ display: "flex", alignItems: "center" }}>
                    <Caution size={16} color={"primary"} />
                    選択なし
                  </AmeBox>
                )}
              </AmeTypography>
              <AmeIconButton onClick={() => props.changePage(i)}>
                <EditIcon color={"black"} />
              </AmeIconButton>
            </AmeBox>
          </AmeBox>
        ))}
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1 }} />
      <AmeBox>
        <AmeButton
          onClick={() => props.onSubmitInterviewFileMaps(props.interviewFileTmpDataList)}
          fullWidth
          color={"primary"}
          variant={"contained"}
        >
          一括登録
        </AmeButton>
      </AmeBox>
      <Spacer height="12px" />
      <AmeBox>
        <AmeButton
          onClick={() => {
            props.cancelWizard();
          }}
          fullWidth
          color={"basic"}
          variant={"outlined"}
        >
          キャンセル
        </AmeButton>
      </AmeBox>
    </>
  );
};
