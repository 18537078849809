import React from "react";

import { CSS_SP_MEDIA_QUERY } from "../../../../../../utils/mediaQuery";
import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

export const LoginInputWrapper: React.FC<Props> = ({ children }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox sx={{ paddingBottom: "8px", [CSS_SP_MEDIA_QUERY]: { background: theme.common.background.white } }}>
      {children}
    </AmeBox>
  );
};
