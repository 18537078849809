import React from "react";

import { TagCheckboxesFormType } from "..";
import { Sentence } from "../../../../../../../store/hooks/interviewReport";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { RetirementVoiceListRow } from "./RetirementVoiceListRow";

interface Props {
  tagsAreChecked: TagCheckboxesFormType;
  sentences: Sentence[];
}

export const RetirementVoiceList: React.FC<Props> = ({ sentences, tagsAreChecked }) => {
  return (
    <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {sentences.map((sentence) => (
        <RetirementVoiceListRow key={sentence.documentId} sentence={sentence} tagsAreChecked={tagsAreChecked} />
      ))}
    </AmeBox>
  );
};
