import React from "react";

import { useFieldArray } from "react-hook-form";
import { Control, UseFormRegister } from "react-hook-form/dist/types/form";

import { AmeFormset } from "../../../../../../atoms/forms/AmeFormset";
import { AmeTextarea } from "../../../../../../atoms/forms/textarea/AmeTextarea";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { InterviewSheetFormType } from "./type";

interface Props {
  register: UseFormRegister<InterviewSheetFormType>;
  control: Control<InterviewSheetFormType>;
  setFocusedInput: (element: HTMLTextAreaElement | HTMLInputElement | null) => void;
}

export const InterviewSheet: React.FC<Props> = (props) => {
  const { fields } = useFieldArray({
    control: props.control,
    name: "contents",
  });
  return (
    <>
      {fields.map((f, i) => (
        <AmeBox key={i} sx={{ "& + &": { marginTop: "16px" } }}>
          <AmeFormset label={f.question}>
            <AmeTextarea
              onFocus={(e) => {
                props.setFocusedInput(e.currentTarget);
              }}
              fullWidth
              {...props.register(`contents.${i}.answer`)}
            />
          </AmeFormset>
        </AmeBox>
      ))}
    </>
  );
};
