/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NominalTypingEmployeeAttributeDefinitionStringAllOf,
    NominalTypingEmployeeAttributeDefinitionStringAllOfFromJSON,
    NominalTypingEmployeeAttributeDefinitionStringAllOfFromJSONTyped,
    NominalTypingEmployeeAttributeDefinitionStringAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface NominalTypingEmployeeAttributeDefinitionString
 */
export interface NominalTypingEmployeeAttributeDefinitionString {
    /**
     * 
     * @type {string}
     * @memberof NominalTypingEmployeeAttributeDefinitionString
     */
    type: NominalTypingEmployeeAttributeDefinitionStringTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum NominalTypingEmployeeAttributeDefinitionStringTypeEnum {
    EMPLOYEE_ATTRIBUTE_DEFINITION = 'EmployeeAttributeDefinition'
}

export function NominalTypingEmployeeAttributeDefinitionStringFromJSON(json: any): NominalTypingEmployeeAttributeDefinitionString {
    return NominalTypingEmployeeAttributeDefinitionStringFromJSONTyped(json, false);
}

export function NominalTypingEmployeeAttributeDefinitionStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): NominalTypingEmployeeAttributeDefinitionString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['_type'],
    };
}

export function NominalTypingEmployeeAttributeDefinitionStringToJSON(value?: NominalTypingEmployeeAttributeDefinitionString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_type': value.type,
    };
}


