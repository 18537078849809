import { UserGroup, UserGroupList } from "../../models/UserGroup";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useCreateGroupMutation = (options?: UseMutationOptions) => {
  const [createGroup] = defaultApi.endpoints.createUserGroup.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createGroup,
    onError: options?.onError,
  });
};

export const useGetGroupListQuery = (count: number = 10000, offset: number = 0, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getUserGroups.useQuery(
    { count, offset },
    {
      pollingInterval: options?.pollingInterval,
      skip: options?.skip,
    },
  );

  return useQueryResult({
    result,
    fromResponse: UserGroupList.fromResponse,
    onError: options?.onError,
  });
};

export const useGetGroupQuery = (userGroupId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getUserGroup.useQuery(
    { userGroupId },
    {
      pollingInterval: options?.pollingInterval,
      skip: options?.skip,
    },
  );

  return useQueryResult({
    result,
    fromResponse: UserGroup.fromResponse,
    onError: options?.onError,
  });
};

export const useDeleteGroupMutation = (options?: UseMutationOptions) => {
  const [deleteGroup] = defaultApi.endpoints.deleteUserGroup.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteGroup,
    onError: options?.onError,
  });
};

export const useUpdateGroupMutation = (options?: UseMutationOptions) => {
  const [updateGroup] = defaultApi.endpoints.updateUserGroup.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateGroup,
    onError: options?.onError,
  });
};

export const useSetUsersToUserGroupMutation = (options?: UseMutationOptions) => {
  const [setUsersToUserGroup] = defaultApi.endpoints.setUsersToUserGroup.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: setUsersToUserGroup,
    onError: options?.onError,
  });
};

export const useGetUserGroupsOfUser = (userId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getUserGroupsOfUser.useQuery(
    { userId: userId },
    {
      pollingInterval: options?.pollingInterval,
      skip: options?.skip,
    },
  );
  return useQueryResult({
    result,
    fromResponse: UserGroupList.fromResponse,
    onError: options?.onError,
  });
};

// TODO: APIから治す
export const useGetMyUserGroupQuery = (options?: UseQueryOptions) => {
  const myselfResult = defaultApi.endpoints.getMyself.useQuery(undefined, {
    pollingInterval: options?.pollingInterval,
    skip: options?.skip,
  });
  const result = defaultApi.endpoints.getUserGroupsOfUser.useQuery(
    { userId: myselfResult.data?.userId as string },
    {
      pollingInterval: options?.pollingInterval,
      skip: !myselfResult.isSuccess,
    },
  );

  return useQueryResult({
    result,
    fromResponse: UserGroupList.fromResponse,
    onError: options?.onError,
  });
};
