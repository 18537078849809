import React from "react";

import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

export const LoginSubTitleWrapper: React.FC<Props> = ({ children }) => {
  return (
    <AmeBox sx={{ width: "100%", paddingBottom: "16px" }}>
      <AmeTypography component="h2" textAlign={"center"}>
        {children}
      </AmeTypography>
    </AmeBox>
  );
};
