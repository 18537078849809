/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateInterviewFeedbackSettingsAnswer,
    UpdateInterviewFeedbackSettingsAnswerFromJSON,
    UpdateInterviewFeedbackSettingsAnswerFromJSONTyped,
    UpdateInterviewFeedbackSettingsAnswerToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateInterviewFeedbackSettingsQuestion
 */
export interface UpdateInterviewFeedbackSettingsQuestion {
    /**
     * 
     * @type {number}
     * @memberof UpdateInterviewFeedbackSettingsQuestion
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateInterviewFeedbackSettingsQuestion
     */
    question: string;
    /**
     * 
     * @type {Array<UpdateInterviewFeedbackSettingsAnswer>}
     * @memberof UpdateInterviewFeedbackSettingsQuestion
     */
    answerChoices: Array<UpdateInterviewFeedbackSettingsAnswer>;
}

export function UpdateInterviewFeedbackSettingsQuestionFromJSON(json: any): UpdateInterviewFeedbackSettingsQuestion {
    return UpdateInterviewFeedbackSettingsQuestionFromJSONTyped(json, false);
}

export function UpdateInterviewFeedbackSettingsQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInterviewFeedbackSettingsQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priority': json['priority'],
        'question': json['question'],
        'answerChoices': ((json['answerChoices'] as Array<any>).map(UpdateInterviewFeedbackSettingsAnswerFromJSON)),
    };
}

export function UpdateInterviewFeedbackSettingsQuestionToJSON(value?: UpdateInterviewFeedbackSettingsQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priority': value.priority,
        'question': value.question,
        'answerChoices': ((value.answerChoices as Array<any>).map(UpdateInterviewFeedbackSettingsAnswerToJSON)),
    };
}


