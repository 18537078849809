import React from "react";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

type Props = {
  fieldName: string;
  children: React.ReactNode;
};

export const UserDetailField: React.FC<Props> = ({ fieldName, children }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox sx={{ borderBottom: `1px solid ${theme.common.border.gray40}`, padding: "8px 0px 8px 0px" }}>
      <AmeTypography fontSize="caption">{fieldName}</AmeTypography>
      {children}
    </AmeBox>
  );
};
