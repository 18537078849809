/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateHrInterviewFileRequestBodyFiles
 */
export interface CreateHrInterviewFileRequestBodyFiles {
    /**
     * 
     * @type {number}
     * @memberof CreateHrInterviewFileRequestBodyFiles
     */
    duration: number;
    /**
     * 
     * @type {string}
     * @memberof CreateHrInterviewFileRequestBodyFiles
     */
    interviewerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHrInterviewFileRequestBodyFiles
     */
    userGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHrInterviewFileRequestBodyFiles
     */
    originalFileName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHrInterviewFileRequestBodyFiles
     */
    s3FileName: string;
}

export function CreateHrInterviewFileRequestBodyFilesFromJSON(json: any): CreateHrInterviewFileRequestBodyFiles {
    return CreateHrInterviewFileRequestBodyFilesFromJSONTyped(json, false);
}

export function CreateHrInterviewFileRequestBodyFilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateHrInterviewFileRequestBodyFiles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': json['duration'],
        'interviewerId': !exists(json, 'interviewerId') ? undefined : json['interviewerId'],
        'userGroupId': !exists(json, 'userGroupId') ? undefined : json['userGroupId'],
        'originalFileName': json['originalFileName'],
        's3FileName': json['s3FileName'],
    };
}

export function CreateHrInterviewFileRequestBodyFilesToJSON(value?: CreateHrInterviewFileRequestBodyFiles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': value.duration,
        'interviewerId': value.interviewerId,
        'userGroupId': value.userGroupId,
        'originalFileName': value.originalFileName,
        's3FileName': value.s3FileName,
    };
}


