import React, { useCallback } from "react";

import { SelectChangeEvent } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";

import { SURVEY_SENDING_METHODS, SurveySendingMethod } from "../../../../models/constants/values";
import { ChoosableList } from "../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../atoms/list/ChoosableListItem";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeDropbox_old } from "../../../molecules/dropbox/AmeDropbox";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { SURVEY_SENDING_METHOD_TEXT_AND_ICON } from "../common/constants";

interface Props {
  sendingMethod: SurveySendingMethod;
}

export const SurveySendingMethodSelector: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const onChange = useCallback(
    async (event: SelectChangeEvent) => {
      await navigate({ search: { method: event.target.value as SurveySendingMethod } });
    },
    [navigate],
  );

  return (
    <AmeDropbox_old
      onChange={onChange}
      value={props.sendingMethod}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      displaySelected={
        <AmeBox component="div" sx={{ display: "flex", alignItems: "center" }}>
          {SURVEY_SENDING_METHOD_TEXT_AND_ICON[props.sendingMethod].icon}
          <Spacer width="8px" />
          {SURVEY_SENDING_METHOD_TEXT_AND_ICON[props.sendingMethod].text}
        </AmeBox>
      }
    >
      <ChoosableList>
        {SURVEY_SENDING_METHODS.map((method) => (
          <ChoosableListItem key={method} value={method}>
            <AmeBox component="div" sx={{ display: "flex", alignItems: "center" }}>
              {SURVEY_SENDING_METHOD_TEXT_AND_ICON[method].icon}
              <Spacer width="8px" />
              {SURVEY_SENDING_METHOD_TEXT_AND_ICON[method].text}
            </AmeBox>
          </ChoosableListItem>
        ))}
      </ChoosableList>
    </AmeDropbox_old>
  );
};
