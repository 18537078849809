import React, { useContext } from "react";

import { Link } from "@tanstack/react-router";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { ArrowRight } from "../../../atoms/icon/ArrowRight";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../DataSourceContext";

export const InterviewerNavigator: React.FC = () => {
  const theme = useAmeTheme();
  const {
    setting: {
      globalSetting: { activateHrInterview },
    },
  } = useContext(DataSourceContext);
  return (
    <AmeBox sx={{ display: "flex", flexDirection: "column" }}>
      <Link to="/interview-files" search={{ page: 1 }} style={{ textDecoration: "none", color: "inherit" }}>
        <AmeBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 10px 16px",
            borderBottom: `1px solid ${theme.common.border.gray40}`,
          }}
        >
          <AmeTypography fontSize="body1">退職者音声一覧</AmeTypography>
          <ArrowRight color="black" size={16} />
        </AmeBox>
      </Link>
      {activateHrInterview ? (
        <Link
          to="/hr-interviews/interview-files"
          search={{ page: 1 } as never}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <AmeBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 10px 16px",
              borderBottom: `1px solid ${theme.common.border.gray40}`,
            }}
          >
            <AmeTypography fontSize="body1">在職者音声一覧</AmeTypography>
            <ArrowRight color="black" size={16} />
          </AmeBox>
        </Link>
      ) : null}
    </AmeBox>
  );
};
