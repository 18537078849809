import React, { PropsWithChildren } from "react";

import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  title: string;
};

export const AmeAccordion: React.FC<PropsWithChildren<Props>> = (props) => {
  const theme = useAmeTheme();

  return (
    <Accordion
      sx={{
        backgroundColor: theme.common.background.gray20,
        border: 0,
        boxShadow: "none",
        "&::before": {
          display: "none",
        },
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          minHeight: "unset",
          height: "32px",
          margin: "0px",
          border: 0,
        }}
      >
        <AmeBox
          component="p"
          sx={{
            fontFamily: "'Noto Sans CJK JP', sans-serif",
            color: theme.common.text.black,
            fontSize: theme.typography.body2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            lineHeight: "unset",
          }}
        >
          {props.title}
        </AmeBox>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          border: 0,
          padding: "unset",
          boxShadow: "none",
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};
