import React from "react";

import { ArrowForwardIos } from "@mui/icons-material";
import { LinkProps } from "@tanstack/react-router";

import { AmeButton } from "../../../atoms/button/AmeButton";

interface Props extends LinkProps {
  children: string;
  // グラフ目盛り設定用の一時的なProps。グラフ目盛り設定ページが出来たら消す。
  disabled?: boolean;
}
export const SettingLinkButton: React.FC<Props> = ({ children, to, disabled, params }) => {
  return (
    <AmeButton
      to={to}
      params={params}
      variant="outlined"
      endIcon={
        <ArrowForwardIos
          sx={{
            "&.MuiSvgIcon-root": {
              fontSize: "16px",
            },
          }}
        />
      }
      disabled={disabled}
    >
      {children}
    </AmeButton>
  );
};
