/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSurveyRequestBody
 */
export interface UpdateSurveyRequestBody {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSurveyRequestBody
     */
    useQrcode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSurveyRequestBody
     */
    sendEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyRequestBody
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyRequestBody
     */
    surveyTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyRequestBody
     */
    surveyDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyRequestBody
     */
    emailSubject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSurveyRequestBody
     */
    emailBody?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UpdateSurveyRequestBody
     */
    dueDateTime?: Date;
}

export function UpdateSurveyRequestBodyFromJSON(json: any): UpdateSurveyRequestBody {
    return UpdateSurveyRequestBodyFromJSONTyped(json, false);
}

export function UpdateSurveyRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSurveyRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useQrcode': !exists(json, 'useQrcode') ? undefined : json['useQrcode'],
        'sendEmail': !exists(json, 'sendEmail') ? undefined : json['sendEmail'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'surveyTitle': !exists(json, 'surveyTitle') ? undefined : json['surveyTitle'],
        'surveyDescription': !exists(json, 'surveyDescription') ? undefined : json['surveyDescription'],
        'emailSubject': !exists(json, 'emailSubject') ? undefined : json['emailSubject'],
        'emailBody': !exists(json, 'emailBody') ? undefined : json['emailBody'],
        'dueDateTime': !exists(json, 'dueDateTime') ? undefined : (new Date(json['dueDateTime'])),
    };
}

export function UpdateSurveyRequestBodyToJSON(value?: UpdateSurveyRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'useQrcode': value.useQrcode,
        'sendEmail': value.sendEmail,
        'displayName': value.displayName,
        'surveyTitle': value.surveyTitle,
        'surveyDescription': value.surveyDescription,
        'emailSubject': value.emailSubject,
        'emailBody': value.emailBody,
        'dueDateTime': value.dueDateTime === undefined ? undefined : (value.dueDateTime.toISOString()),
    };
}


