import React from "react";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { useSummarizeHrInterview } from "../../../../../../store/hooks/hrInterview";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { CounterClockwise } from "../../../../../atoms/icon/CounterClockwise";
import { Warning } from "../../../../../atoms/icon/Warning";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { IconText } from "../../../../../molecules/common/IconText";

import { InterviewUpdateButton } from "./InterviewUpdateButton";

interface Props {
  interviewDetail: HrInterviewDetail;
}

export const InterviewSummary: React.FC<Props> = ({ interviewDetail }) => {
  const summarize = useSummarizeHrInterview();

  return (
    <PaperWrapper>
      <PaperHeader
        endElement={
          interviewDetail.transcription?.isTranscribing ? (
            <InterviewUpdateButton interviewDetail={interviewDetail} />
          ) : (
            <AmeButton
              onClick={async () => {
                await summarize({
                  hrInterviewId: interviewDetail.hrInterviewId,
                  fromTranscription: false,
                });
              }}
              variant="outlined"
              color="basic"
              size="small"
              startIcon={<CounterClockwise color="black" />}
            >
              議事録からサマリーする
            </AmeButton>
          )
        }
      >
        サマリー
      </PaperHeader>
      <PaperBody>
        {interviewDetail.summary ? (
          <AmeTypography>{interviewDetail.summary}</AmeTypography>
        ) : (
          <IconText icon={<Warning color={"primary"} size={30} />}>
            音声データまたは議事録からサマリーを生成できます。
          </IconText>
        )}
      </PaperBody>
    </PaperWrapper>
  );
};
