import React, { useCallback, useContext, useState } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { UserGroupRef } from "../../../../../../../models/UserGroup";
import { useGetHrInterviewFile, useUpdateInterviewFile } from "../../../../../../../store/hooks/hrInterviewFiles";
import { useGetGroupListQuery } from "../../../../../../../store/hooks/userGroups";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeFormButtonsLayout } from "../../../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { MyselfContext } from "../../../../../../organisms/common/MyselfContext";
import { EditInterviewFileContent } from "../../../../../../organisms/hr-interviews/interview-files/$interviewFileId/edit";

const InterviewFileEditPage: React.FC = () => {
  const { interviewFileId } = Route.useParams();
  const getInterviewFileQuery = useGetHrInterviewFile(interviewFileId);

  const [selectedGroup, setSelectedGroup] = useState<UserGroupRef | undefined>();
  const userGroupListQueryState = useGetGroupListQuery();
  const updateInterviewFile = useUpdateInterviewFile();

  const navigate = useNavigate();

  const { myUserData } = useContext(MyselfContext);

  const error = !myUserData.isAdmin && !selectedGroup;

  const onSubmit = useCallback(async () => {
    const res = await updateInterviewFile({
      hrInterviewFileId: interviewFileId,
      updateHrInterviewFileRequestBody: {
        userGroupId: selectedGroup?.userGroupId ?? null,
      },
    });
    if (res.isSuccess) {
      await navigate({ to: `/hr-interviews/interview-files/$interviewFileId`, params: { interviewFileId } });
    }
  }, [selectedGroup, updateInterviewFile, interviewFileId, navigate]);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "在職者音声一覧", to: "/hr-interviews/interview-files" }]}
        currentTitle={"音声データ編集"}
      />
      <PageLayoutBody>
        <PaperWrapper>
          <PaperHeader>音声データ詳細</PaperHeader>
          <PaperBody>
            <WaitForSuccess queryState={getInterviewFileQuery}>
              {(interviewFile) => (
                <WaitForSuccess queryState={userGroupListQueryState}>
                  {(userGroupList) => (
                    <EditInterviewFileContent
                      interviewFile={interviewFile}
                      userGroups={userGroupList.userGroups}
                      selectedGroup={selectedGroup}
                      setGroup={setSelectedGroup}
                      error={error}
                    />
                  )}
                </WaitForSuccess>
              )}
            </WaitForSuccess>
          </PaperBody>
        </PaperWrapper>
        <Spacer height={"24px"} />
        <AmeFormButtonsLayout
          onPrimary={onSubmit}
          primaryDisabled={error}
          onSecondary={async () => {
            await navigate({
              to: `/hr-interviews/interview-files/$interviewFileId`,
              params: { interviewFileId },
            });
          }}
          secondaryText={"キャンセル"}
        />
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-all/hr-interviews/interview-files/$interviewFileId/edit",
)({
  component: InterviewFileEditPage,
});
