import React, { MouseEventHandler, useMemo } from "react";

import { Chip } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { Close } from "../icon/Close";

type Variant = "secondary" | "warning";

interface Props {
  label: string;
  selected?: boolean;
  variant?: Variant;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onDelete?: () => void;
  fullWidth?: boolean;
}

export const AmeChip: React.FC<Props> = ({
  label,
  selected,
  onClick,
  onDelete,
  disabled,
  fullWidth,
  variant = "secondary",
}) => {
  return (
    <Chip
      component={onClick ? "button" : "div"}
      label={label}
      variant="outlined"
      sx={useChipStyle(variant, selected, disabled, fullWidth)}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<Close />}
      disabled={disabled}
    />
  );
};

const useChipStyle = (
  variant: Variant,
  selected: boolean | undefined,
  disabled: boolean | undefined,
  fullWidth: boolean | undefined,
): SystemStyleObject => {
  const theme = useAmeTheme();
  return useMemo(() => {
    const style: SystemStyleObject = {
      ...theme.typography.body2,
      fontWeight: theme.typography.button.fontWeight,
      width: fullWidth ? "100%" : undefined,
    };
    if (disabled) {
      return {
        "&.Mui-disabled": {
          ...style,
          border: `1px solid ${theme.common.border.gray40}`,
          backgroundColor: theme.common.background.gray40,
          color: theme.common.text.gray20,
          opacity: 1,
        },
        "& .MuiChip-deleteIcon": {
          color: theme.common.text.gray20,
        },
      };
    }
    if (variant === "secondary") {
      if (selected) {
        return {
          ...style,
          color: theme.brand.secondary[100],
          border: `1px solid ${theme.brand.secondary[100]}`,
          backgroundColor: theme.brand.secondary[20],
          "&.MuiChip-clickable:hover": {
            backgroundColor: theme.brand.secondary[20],
          },
          "& .MuiChip-deleteIcon": {
            color: theme.brand.secondary[100],
            "&:hover": {
              color: theme.brand.secondary[100],
            },
          },
        };
      }
      return {
        ...style,
        border: `1px solid ${theme.brand.secondary[100]}`,
        color: theme.brand.secondary[100],
        "&.MuiChip-clickable:hover": {
          backgroundColor: theme.brand.secondary[20],
        },
        "& .MuiChip-deleteIcon": {
          color: theme.brand.secondary[100],
          "&:hover": {
            color: theme.brand.secondary[100],
          },
        },
      };
    }
    if (selected) {
      return {
        ...style,
        color: theme.common.text.warning,
        border: `1px solid ${theme.common.border.warning}`,
        backgroundColor: theme.common.background.warning40,
        "&.MuiChip-clickable:hover": {
          backgroundColor: theme.common.background.warning40,
        },
        "& .MuiChip-deleteIcon": {
          color: theme.common.text.warning,
          "&:hover": {
            color: theme.common.text.warning,
          },
        },
      };
    }
    return {
      ...style,
      border: `1px solid ${theme.common.border.warning}`,
      color: theme.common.text.warning,
      "&.MuiChip-clickable:hover": {
        backgroundColor: theme.common.background.warning40,
      },
      "& .MuiChip-deleteIcon": {
        color: theme.common.text.warning,
        "&:hover": {
          color: theme.common.background.warning40,
        },
      },
    };
  }, [selected, theme, variant, disabled, fullWidth]);
};
