/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSegmentDefinitionRequestBody
 */
export interface CreateSegmentDefinitionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateSegmentDefinitionRequestBody
     */
    mappedTo?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSegmentDefinitionRequestBody
     */
    segmentValue: string;
}

export function CreateSegmentDefinitionRequestBodyFromJSON(json: any): CreateSegmentDefinitionRequestBody {
    return CreateSegmentDefinitionRequestBodyFromJSONTyped(json, false);
}

export function CreateSegmentDefinitionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSegmentDefinitionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mappedTo': !exists(json, 'mappedTo') ? undefined : json['mappedTo'],
        'segmentValue': json['segmentValue'],
    };
}

export function CreateSegmentDefinitionRequestBodyToJSON(value?: CreateSegmentDefinitionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mappedTo': value.mappedTo,
        'segmentValue': value.segmentValue,
    };
}


