import React from "react";

import { Paper } from "@mui/material";

import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

export const CheckingRetirementRate: React.FC = () => {
  return (
    <>
      <AmeBox sx={{ paddingBottom: "24px" }}>
        <AmeTypography component="h4">離職率を確認しましょう</AmeTypography>
      </AmeBox>
      <Paper
        sx={{
          padding: "24px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "48px",
        }}
      >
        <a href={"/employee-report"} style={{ color: "rgb(0, 172, 193)" }}>
          従業員レポート
        </a>
        にて離職率を確認できます。
      </Paper>
    </>
  );
};
