import React from "react";

import { ArrowBackIosNew } from "@mui/icons-material";
import { UseFieldArrayRemove, UseFieldArraySwap } from "react-hook-form";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { AmeIconButton } from "../../../../../muiWrapper/AmeIconButton";

interface Props {
  index: number;
  swap: UseFieldArraySwap;
  remove: UseFieldArrayRemove;
  rowLastIndex: number;
}

export const QuestionSettingOperationsCell: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  return (
    <React.Fragment>
      <AmeIconButton
        onClick={() => props.swap(props.index, props.index - 1)}
        disabled={props.index == 0}
        sx={{ border: `1px solid ${theme.common.border.gray40}`, marginRight: "8px" }}
      >
        <ArrowBackIosNew sx={{ transform: "rotate(90deg)", fontSize: "14px" }} />
      </AmeIconButton>
      <AmeIconButton
        onClick={() => props.swap(props.index, props.index + 1)}
        disabled={props.index === props.rowLastIndex}
        sx={{ border: `1px solid ${theme.common.border.gray40}`, marginRight: "8px" }}
      >
        <ArrowBackIosNew sx={{ transform: "rotate(-90deg)", fontSize: "14px" }} />
      </AmeIconButton>
      <AmeButton size="small" variant="outlined" onClick={() => props.remove(props.index)}>
        削除
      </AmeButton>
    </React.Fragment>
  );
};
