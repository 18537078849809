import React from "react";

import { FeedbackDetail } from "../../../../../../../store/hooks/interviewer";
import { AmeTable, AmeTableBody, AmeTableHead, AmeTableRow } from "../../../../../../atoms/table/AmeTable";
import { NicoChanFace } from "../../../../../../molecules/InterviewFeedback/NicoChanFace";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { InterviewerQualityDetailTableBodyCell } from "./InterviewerQualityDetailTableBodyCell";
import { InterviewerQualityDetailTableHeadCell } from "./InterviewerQualityDetailTableHeadCell";

interface Props {
  feedbackDetails: FeedbackDetail[];
}

export const InterviewerQualityDetailTable: React.FC<Props> = ({ feedbackDetails }) => {
  return (
    <AmeTable>
      <AmeTableHead>
        <AmeTableRow>
          <InterviewerQualityDetailTableHeadCell>設問</InterviewerQualityDetailTableHeadCell>
          <InterviewerQualityDetailTableHeadCell>品質</InterviewerQualityDetailTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {feedbackDetails.map((interviewerQuality) => (
          <AmeTableRow key={interviewerQuality.question}>
            <InterviewerQualityDetailTableBodyCell>{interviewerQuality.question}</InterviewerQualityDetailTableBodyCell>
            <InterviewerQualityDetailTableBodyCell>
              <AmeBox sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <NicoChanFace rating={interviewerQuality.rating} />
                {interviewerQuality.answer}
              </AmeBox>
            </InterviewerQualityDetailTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
