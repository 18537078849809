/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewReportScore
 */
export interface InterviewReportScore {
    /**
     * 
     * @type {number}
     * @memberof InterviewReportScore
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof InterviewReportScore
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewReportScore
     */
    subCategoryId: string;
}

export function InterviewReportScoreFromJSON(json: any): InterviewReportScore {
    return InterviewReportScoreFromJSONTyped(json, false);
}

export function InterviewReportScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewReportScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'displayName': json['displayName'],
        'subCategoryId': json['subCategoryId'],
    };
}

export function InterviewReportScoreToJSON(value?: InterviewReportScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'displayName': value.displayName,
        'subCategoryId': value.subCategoryId,
    };
}


