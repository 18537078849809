import React, { useCallback, useMemo, useState } from "react";

import { Employee } from "../../../../../models/Employee";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeFormButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { EmployeeSelector } from "../../../common/EmployeeSelector";
import { InterviewFilesRegisterationCautionDialog } from "../InterviewFilesRegisterationCautionDialog";
import {
  getUploadStatus,
  InterviewFileTmpData,
  useInterviewFileTmpDataList,
} from "../common/useInterviewFileTmpDataList";

interface Props {
  files: File[];
  uploadInterviewFile: (
    file: File,
    updatePercentage: (percentage: number) => void,
  ) => Promise<{ tmpFilename: string; suggest?: Employee }>;
  onSubmitInterviewFileMaps: (interviewFileMaps: InterviewFileTmpData[]) => Promise<void>;
}

export const InterviewFileEmployeeMapper: React.FC<Props> = ({
  files,
  uploadInterviewFile,
  onSubmitInterviewFileMaps,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { fileTmpDataList, setInterviewFileTmpData } = useInterviewFileTmpDataList(files, uploadInterviewFile);

  const allFilesAreLinkedToEmployee = useMemo(
    () => fileTmpDataList.every((file) => file.employee !== undefined),
    [fileTmpDataList],
  );

  const onSubmit = useCallback(
    async (interviewFileMaps: InterviewFileTmpData[]) => {
      const requests = interviewFileMaps.filter((v): v is Required<InterviewFileTmpData> => Boolean(v.tmpFilename));
      await onSubmitInterviewFileMaps(requests);
    },
    [onSubmitInterviewFileMaps],
  );

  const onCloseDialog = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return (
    <>
      <AmeTypography component="h6">音声データと従業員情報のマッピングをしてください</AmeTypography>
      <Spacer height="24px" />
      <AmeTable>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell>タイトル</AmeTableHeadCell>
            <AmeTableHeadCell>紐付き先従業員番号</AmeTableHeadCell>
            <AmeTableHeadCell>ステータス</AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {fileTmpDataList.map((interviewFile, i) => (
            <AmeTableRow key={i}>
              <AmeTableBodyCell>{interviewFile.file.name}</AmeTableBodyCell>
              <AmeTableBodyCell noPadding>
                <EmployeeSelector
                  disabled={!interviewFile.tmpFilename}
                  value={interviewFile.employee}
                  onSelectEmployee={(e) => {
                    setInterviewFileTmpData(i, { employee: e });
                  }}
                  forTable
                />
              </AmeTableBodyCell>
              <AmeTableBodyCell>{getUploadStatus(interviewFile)}</AmeTableBodyCell>
            </AmeTableRow>
          ))}
        </AmeTableBody>
      </AmeTable>
      <Spacer height="24px" />
      {allFilesAreLinkedToEmployee ? null : (
        <InterviewFilesRegisterationCautionDialog
          isOpen={isOpen}
          onSubmit={() => onSubmit(fileTmpDataList)}
          onClose={onCloseDialog}
        />
      )}
      <AmeFormButtonsLayout
        primaryDisabled={!fileTmpDataList.every((f) => ["error", "uploaded"].includes(f.status))}
        onPrimary={allFilesAreLinkedToEmployee ? () => onSubmit(fileTmpDataList) : () => setIsOpen(true)}
        primaryText="一括登録する"
      />
    </>
  );
};
