import React, { useMemo } from "react";

import { useFollowupChoiceGroup } from "../../../../../hooks/filterHooks/useFollowupChoiceGroup";
import { useInterviewFBChoiceGroup } from "../../../../../hooks/filterHooks/useInterviewFBChoiceGroup";
import { useInterviewFileStatusChoiceGroup } from "../../../../../hooks/filterHooks/useInterviewFileStatusChoiceGroup";
import { useInterviewStatusChoiceGroup } from "../../../../../hooks/filterHooks/useInterviewStatusChoiceGroup";
import { useInterviewTypeChoiceGroup } from "../../../../../hooks/filterHooks/useInterviewTypeChoiceGroup";
import { useInterviewerChoiceGroup } from "../../../../../hooks/filterHooks/useInterviewerChoiceGroup";
import { useGetSegmentContent } from "../../../../../hooks/filterHooks/useSegmentChoiceGroup";
import { useSelectedFilter } from "../../../../../hooks/useSelectedFilter";
import { HrInterviewType } from "../../../../../models/HrInterviewSetting";
import { User } from "../../../../../models/User";
import { FeedbackStatus } from "../../../../../models/constants/values";
import { HrInterview } from "../../../../../models/hrInterview/HrInterview";
import { PaginatedList } from "../../../../../models/utils/PaginatedList";
import { QueryState } from "../../../../../store/hooks/utils/parseResult";
import { InterviewStatus, InterviewTranscriptionStatus } from "../../../../../utils/constants";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import { WaitForData } from "../../../../molecules/Loading/WaitForData";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { ChoiceGroupProps } from "../../../../molecules/filter/ChoiceGroup/type";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { HrInterviewFilter } from "./HrInterviewFilter";
import { HrInterviewListHeader } from "./HrInterviewListHeader";
import { HrInterviewTable } from "./HrInterviewTable";

export type HrInterviewQuery = {
  interviewTypeIds: string[];
  interviewStatuses: InterviewStatus[];
  interviewFileStatuses: InterviewTranscriptionStatus[];
  interviewFeedbackStatuses: FeedbackStatus[];
  interviewerIds: string[];
  followupRequired: boolean[];
  SEX: string[];
  DEPARTMENT: string[];
  FREE1: string[];
  FREE2: string[];
  AGE: string[];
  MONTHS_OF_SERVICE: string[];
  from?: string;
  to?: string;
};

interface Props {
  searchParams: HrInterviewQuery;
  navigate: (params: { search: (prev: HrInterviewQuery) => HrInterviewQuery }) => Promise<void>;
  interviewTypes: HrInterviewType[];
  interviewers: User[];
  interviewListQueryState: QueryState<PaginatedList<HrInterview>>;
}

export const HrInterviewListContent: React.FC<Props> = ({
  searchParams,
  navigate,
  interviewers,
  interviewTypes,
  interviewListQueryState,
}) => {
  const choiceGroups = useHrInterviewChoiceGroups(navigate, searchParams, interviewTypes, interviewers);
  const selectedFilter = useSelectedFilter(choiceGroups);

  return (
    <AmeBox sx={{ display: "flex", gap: "24px" }}>
      <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
        <HrInterviewFilter
          choiceGroups={choiceGroups}
          from={searchParams.from}
          to={searchParams.to}
          setDate={async (params) => {
            await navigate({
              search: (prev) => {
                const nextParams = { ...prev };
                if (params.from) {
                  nextParams.from = formatDateToYYYYMMDD(params.from);
                }
                if (params.to) {
                  nextParams.to = formatDateToYYYYMMDD(params.to);
                }
                return nextParams;
              },
            });
          }}
        />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, minWidth: 0 }}>
        <WaitForData queryState={interviewListQueryState}>
          {({ totalCount }) => <HrInterviewListHeader count={totalCount} selectedFilters={selectedFilter} />}
        </WaitForData>
        <AmeBox>
          <WaitForSuccess queryState={interviewListQueryState}>
            {({ items }) => <HrInterviewTable interviews={items} />}
          </WaitForSuccess>
        </AmeBox>
      </AmeBox>
    </AmeBox>
  );
};

const useHrInterviewChoiceGroups = (
  navigate: (arg: { search: (prev: HrInterviewQuery) => HrInterviewQuery }) => Promise<void>,
  searchParams: HrInterviewQuery,
  interviewTypes: HrInterviewType[],
  interviewers: User[],
): ChoiceGroupProps[] => {
  const interviewTypeChoiceGroup = useInterviewTypeChoiceGroup(interviewTypes, {
    filterParams: searchParams,
    navigate,
  });
  const interviewStatusChoiceGroup = useInterviewStatusChoiceGroup({ filterParams: searchParams, navigate });
  const interviewFileStatusChoiceGroup = useInterviewFileStatusChoiceGroup({ filterParams: searchParams, navigate });
  const fbStatusChoiceGroup = useInterviewFBChoiceGroup({ filterParams: searchParams, navigate });
  const interviewerChoiceGroup = useInterviewerChoiceGroup(interviewers, { filterParams: searchParams, navigate });
  const followupChoiceGroup = useFollowupChoiceGroup({ filterParams: searchParams, navigate });
  const segmentFilter = useGetSegmentContent(searchParams, navigate);
  return useMemo(
    () => [
      interviewTypeChoiceGroup,
      interviewStatusChoiceGroup,
      interviewFileStatusChoiceGroup,
      fbStatusChoiceGroup,
      interviewerChoiceGroup,
      followupChoiceGroup,
      segmentFilter,
    ],
    [
      fbStatusChoiceGroup,
      followupChoiceGroup,
      interviewFileStatusChoiceGroup,
      interviewStatusChoiceGroup,
      interviewTypeChoiceGroup,
      interviewerChoiceGroup,
      segmentFilter,
    ],
  );
};
