import React, { useCallback, useState } from "react";

import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  summary: string | undefined;
  summarizeInterview: () => Promise<void>;
}

export const Summary: React.FC<Props> = ({ summary, summarizeInterview }) => {
  const [clicked, setClicked] = useState(false);
  const onClick = useCallback(async () => {
    await summarizeInterview();
    setClicked(true);
  }, [summarizeInterview]);

  return (
    <>
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader items={[]} currentTitle="サマリー" />
        <PageLayoutBody type="wide">
          <AmeBox sx={{ height: "100%", overflowY: "scroll" }}>
            <AmeBox
              sx={{
                display: summary === undefined ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <AmeButton disabled={clicked} onClick={onClick}>
                AIサマリー生成
              </AmeButton>
            </AmeBox>
            {summary === undefined ? null : <AmeTypography>{summary}</AmeTypography>}
          </AmeBox>
        </PageLayoutBody>
      </PageLayoutWrapper>
    </>
  );
};
