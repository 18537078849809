import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { Spacer } from "../spacers/Spacer";
import { AmeTypography } from "../typography/AmeTypography";

interface Props {
  title: string;
  children: React.ReactNode;
  extracontent?: React.ReactNode;
}

export const V2ContentWrapper: React.FC<Props> = ({ title, children, extracontent }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox sx={{ position: "relative" }}>
      <AmeBox
        sx={{
          borderBottom: `1px solid ${theme.common.border.gray40}`,
          paddingBottom: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <AmeTypography component="h5">{title}</AmeTypography>
        {extracontent}
      </AmeBox>
      <Spacer height="24px" />
      {children}
    </AmeBox>
  );
};
