/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeInfoChangeRequest,
    EmployeeInfoChangeRequestFromJSON,
    EmployeeInfoChangeRequestFromJSONTyped,
    EmployeeInfoChangeRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeChangeSetItem2ResponseInfoChangeRequests
 */
export interface EmployeeChangeSetItem2ResponseInfoChangeRequests {
    /**
     * 
     * @type {EmployeeInfoChangeRequest}
     * @memberof EmployeeChangeSetItem2ResponseInfoChangeRequests
     */
    retireDate: EmployeeInfoChangeRequest;
    /**
     * 
     * @type {EmployeeInfoChangeRequest}
     * @memberof EmployeeChangeSetItem2ResponseInfoChangeRequests
     */
    hireDate: EmployeeInfoChangeRequest;
    /**
     * 
     * @type {EmployeeInfoChangeRequest}
     * @memberof EmployeeChangeSetItem2ResponseInfoChangeRequests
     */
    dateOfBirth: EmployeeInfoChangeRequest;
    /**
     * 
     * @type {EmployeeInfoChangeRequest}
     * @memberof EmployeeChangeSetItem2ResponseInfoChangeRequests
     */
    email: EmployeeInfoChangeRequest;
    /**
     * 
     * @type {EmployeeInfoChangeRequest}
     * @memberof EmployeeChangeSetItem2ResponseInfoChangeRequests
     */
    name: EmployeeInfoChangeRequest;
}

export function EmployeeChangeSetItem2ResponseInfoChangeRequestsFromJSON(json: any): EmployeeChangeSetItem2ResponseInfoChangeRequests {
    return EmployeeChangeSetItem2ResponseInfoChangeRequestsFromJSONTyped(json, false);
}

export function EmployeeChangeSetItem2ResponseInfoChangeRequestsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeChangeSetItem2ResponseInfoChangeRequests {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retireDate': EmployeeInfoChangeRequestFromJSON(json['retireDate']),
        'hireDate': EmployeeInfoChangeRequestFromJSON(json['hireDate']),
        'dateOfBirth': EmployeeInfoChangeRequestFromJSON(json['dateOfBirth']),
        'email': EmployeeInfoChangeRequestFromJSON(json['email']),
        'name': EmployeeInfoChangeRequestFromJSON(json['name']),
    };
}

export function EmployeeChangeSetItem2ResponseInfoChangeRequestsToJSON(value?: EmployeeChangeSetItem2ResponseInfoChangeRequests | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retireDate': EmployeeInfoChangeRequestToJSON(value.retireDate),
        'hireDate': EmployeeInfoChangeRequestToJSON(value.hireDate),
        'dateOfBirth': EmployeeInfoChangeRequestToJSON(value.dateOfBirth),
        'email': EmployeeInfoChangeRequestToJSON(value.email),
        'name': EmployeeInfoChangeRequestToJSON(value.name),
    };
}


