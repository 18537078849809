import React from "react";

import { useAmeSound } from "../../../../../hooks/useAmeSound";
import { TranscriptionSentence } from "../../../../../models/Transcription";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { TranscriptionContainer } from "./TranscriptionContainer";
import { TranscriptionRowBorder } from "./TranscriptionRowBorder";
import { TranscriptionRowContainer } from "./TranscriptionRowContainer";

interface Props {
  sound: Howl;
  transcriptions: TranscriptionSentence[];
  speakerMap: Map<string, number>;
}

export const TranscriptionAudioPlayer: React.FC<Props> = ({ sound, transcriptions, speakerMap }) => {
  const ameSound = useAmeSound(sound);

  return (
    <TranscriptionContainer sound={ameSound}>
      <>
        {transcriptions.map((t, i) => (
          <React.Fragment key={i}>
            <TranscriptionRowBorder index={i} lastIndex={transcriptions.length} />
            <TranscriptionRowContainer
              index={i}
              isTarget={t.enabled}
              play={ameSound.togglePlay}
              second={t.startTimeSec}
              speakerLabel={speakerMap.get(t.speakerLabel)?.toString() || "0"}
              isPlaying={ameSound.isPlaying}
            >
              <AmeBox>{t.text}</AmeBox>
            </TranscriptionRowContainer>
          </React.Fragment>
        ))}
        <TranscriptionRowBorder index={transcriptions.length} lastIndex={transcriptions.length} />
      </>
    </TranscriptionContainer>
  );
};
