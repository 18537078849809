/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateInterviewFilesRequestBody
 */
export interface CreateInterviewFilesRequestBody {
    /**
     * 
     * @type {number}
     * @memberof CreateInterviewFilesRequestBody
     */
    duration: number;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewFilesRequestBody
     */
    userGroupId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewFilesRequestBody
     */
    tmpFilename: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewFilesRequestBody
     */
    originalFilename: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInterviewFilesRequestBody
     */
    employeeTenureId?: string;
}

export function CreateInterviewFilesRequestBodyFromJSON(json: any): CreateInterviewFilesRequestBody {
    return CreateInterviewFilesRequestBodyFromJSONTyped(json, false);
}

export function CreateInterviewFilesRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInterviewFilesRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': json['duration'],
        'userGroupId': !exists(json, 'userGroupId') ? undefined : json['userGroupId'],
        'tmpFilename': json['tmpFilename'],
        'originalFilename': json['originalFilename'],
        'employeeTenureId': !exists(json, 'employeeTenureId') ? undefined : json['employeeTenureId'],
    };
}

export function CreateInterviewFilesRequestBodyToJSON(value?: CreateInterviewFilesRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': value.duration,
        'userGroupId': value.userGroupId,
        'tmpFilename': value.tmpFilename,
        'originalFilename': value.originalFilename,
        'employeeTenureId': value.employeeTenureId,
    };
}


