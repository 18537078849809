import React, { ChangeEventHandler } from "react";

import { SxProps, Theme } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { AmeInput } from "./AmeInput";

interface DateInputProps {
  defaultValue?: string;
  value?: string;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export interface AmeDateSpanInputProps {
  label?: string;
  sx?: SxProps<Theme>;
  startProps: DateInputProps;
  endProps: DateInputProps;
}

export const AmeDateSpanInput: React.FC<AmeDateSpanInputProps> = (props) => {
  return (
    <FormControl fullWidth variant="standard">
      {props.label && <AmeTypography fontSize="body2">{props.label}</AmeTypography>}
      <AmeBox
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "8px",
          paddingTop: "8px",
        }}
      >
        <AmeInput type="date" {...props.startProps} />
        〜
        <AmeInput type="date" {...props.endProps} />
      </AmeBox>
    </FormControl>
  );
};
