import React from "react";

import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  label: string;
  children: React.ReactNode;
}

export const InterviewFileMetadataCell: React.FC<Props> = ({ label, children }) => {
  return (
    <AmeBox sx={{ width: "20%" }}>
      <AmeTypography fontSize="caption">{label}</AmeTypography>
      <Spacer height="4px" />
      {children}
    </AmeBox>
  );
};
