import React, { useContext } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../atoms/paper/PaperWrapper";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../../../organisms/common/DataSourceContext";
import { EmailSettingsForm } from "../../../../../../organisms/settings/surveys/emails/EmailSettingsForm";

const EmailSettings: React.FC = () => {
  const {
    setting: {
      globalSetting: { emailSenderName, emailSignature },
    },
  } = useContext(DataSourceContext);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader currentTitle={"アンケートメール設定"} items={[{ title: "設定", to: "/settings" }]} />
      <PageLayoutBody>
        <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <PaperWrapper>
            <PaperHeader>
              <AmeTypography fontSize="body1">アンケートメール設定</AmeTypography>
            </PaperHeader>
            <PaperBody>
              <EmailSettingsForm emailSenderName={emailSenderName} emailSignature={emailSignature} />
            </PaperBody>
          </PaperWrapper>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/surveys/emails/")({
  component: EmailSettings,
});
