import React, { useCallback } from "react";

import { FieldErrors } from "react-hook-form";

import { useSp } from "../../../../../../../hooks/useSp";
import { UserGroup } from "../../../../../../../models/UserGroup";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { AmeFormset } from "../../../../../../atoms/forms/AmeFormset";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { LaptopBelongingUserGroupSearchDropDown } from "./LaptopBelongingUserGroupSearchDropDown";
import { SpBeloingingUserGroupSearchDropDown } from "./SpBeloingingUserGroupSearchDropDown";
import { UserEditFormType } from "./UserEditor";

interface Props {
  errors: FieldErrors<UserEditFormType>;
  userGroups: UserGroup[];
  currentUserGroup: UserGroup | undefined;
  onGroupChange: (group: UserGroup | undefined, indexOfBelongingUserGroup?: number) => void;
  indexOfcurrentUserGroup: number;
  onDeleteClick: () => void;
}
export const BelongingUserGroupSearchDropDown: React.FC<Props> = ({
  errors,
  userGroups,
  currentUserGroup,
  onGroupChange,
  indexOfcurrentUserGroup,
  onDeleteClick,
}) => {
  const sp = useSp();
  const onSelect = useCallback(
    (data?: UserGroup) => {
      if (indexOfcurrentUserGroup >= 0) {
        onGroupChange(data, indexOfcurrentUserGroup);
      } else {
        onGroupChange(data);
      }
    },
    [indexOfcurrentUserGroup, onGroupChange],
  );

  const getSuggests = useCallback(
    async (search: string) => {
      return userGroups.filter((userGroup) => userGroup.displayName.includes(search));
    },
    [userGroups],
  );

  return (
    <AmeFormset label={""} errorMessage={errors?.userGroupIds?.message}>
      <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <AmeBox sx={{ marginRight: "16px", flexGrow: 1 }}>
          {sp ? (
            <SpBeloingingUserGroupSearchDropDown
              value={currentUserGroup}
              title={"グループの選択"}
              placeholder={"グループ名"}
              getSuggests={getSuggests}
              onSubmit={onSelect}
            />
          ) : (
            <LaptopBelongingUserGroupSearchDropDown
              value={currentUserGroup}
              placeholder={"グループ名"}
              getSuggests={getSuggests}
              onSubmit={onSelect}
            />
          )}
        </AmeBox>
        <AmeBox sx={{ visibility: currentUserGroup ? "visible" : "hidden" }}>
          <AmeButton onClick={onDeleteClick} variant="outlined" size="small">
            削除
          </AmeButton>
        </AmeBox>
      </AmeBox>
    </AmeFormset>
  );
};
