import React, { useCallback, useEffect, useRef, useState } from "react";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { AmeTypography } from "../../typography/AmeTypography";

type DropBoxSize = "small" | "medium" | "large" | "full";

interface Props {
  selectedLabel?: string;
  children: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  size?: DropBoxSize;
}

const getWidth = (size: DropBoxSize) => {
  switch (size) {
    case "small":
      return "160px";
    case "medium":
      return "240px";
    case "large":
      return "320px";
    case "full":
      return "100%";
  }
};

export const AmeDropbox: React.FC<Props> = ({ selectedLabel, children, error, disabled, size = "small" }) => {
  const theme = useAmeTheme();

  const [isOpen, setIsOpen] = useState(false);
  const dropboxRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleClose = useCallback((event: MouseEvent) => {
    if (dropboxRef.current && !dropboxRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClose);
    }
    return () => {
      if (isOpen) {
        document.removeEventListener("mousedown", handleClose);
      }
    };
  }, [handleClose, isOpen]);

  return (
    <>
      <div style={{ width: getWidth(size) }} ref={dropboxRef}>
        <AmeBox sx={{ width: getWidth(size), position: "relative" }}>
          <AmeBox
            sx={{
              width: "100%",
              padding: "8.5px 14px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "8px",
              outline: isOpen
                ? `2px solid ${theme.brand.secondary[100]}`
                : error
                  ? `2px solid ${theme.common.border.error}`
                  : `1px solid ${theme.common.border.gray40}`,
              outlineOffset: "-2px",
              background: disabled ? theme.common.background.gray20 : theme.common.background.white,
              borderRadius: "8px",
              "&:hover": { background: disabled ? undefined : theme.brand.secondary[20] },
            }}
            onClick={handleClick}
          >
            <AmeTypography>{selectedLabel ?? "選択なし"}</AmeTypography>
            {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </AmeBox>
          <AmeBox
            sx={{
              width: "100%",
              "& > *": { borderBottom: `1px solid ${theme.common.border.gray40}` },
              "& > *:hover": { background: theme.brand.secondary[20] },
              "& > *:last-child": { borderBottom: "none" },
              display: isOpen ? "" : "none",
              position: "absolute",
              borderRadius: "8px",
              background: "white",
              boxShadow: "0px 8px 13px -6px rgba(0, 0, 0, 0.3)",
              left: 0,
              right: 0,
              zIndex: 50000,
            }}
            onClick={handleClick}
          >
            {children}
          </AmeBox>
        </AmeBox>
      </div>
    </>
  );
};
