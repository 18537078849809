import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const CheckboxEmpty: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16" size={16}>
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="#DDDDDD" />
    </SvgWrapper>
  );
};
