import React, { ReactNode } from "react";

import { Dialog } from "@mui/material";

import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const AmeDialog: React.FC<Props> = (props) => {
  return (
    <>
      {props.open ? (
        <Dialog
          open={props.open}
          onClose={props.onClose}
          sx={{
            "& .MuiPaper-root": {
              width: "750px",
              maxWidth: "unset",
            },
          }}
        >
          <AmeBox>{props.children}</AmeBox>
        </Dialog>
      ) : null}
    </>
  );
};
