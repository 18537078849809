import React, { useEffect, useState } from "react";

import { AmeSound } from "../../../../../../../hooks/useAmeSound";
import { generateTimeDisplay } from "../../../../../../../hooks/useTimer";
import { AmeDropbox } from "../../../../../../atoms/forms/input/AmeDropbox";
import { Clockwise } from "../../../../../../atoms/icon/Clockwise";
import { CounterClockwise } from "../../../../../../atoms/icon/CounterClockwise";
import { Pause } from "../../../../../../atoms/icon/Pause";
import { Play } from "../../../../../../atoms/icon/Play";
import { AmeCommonListItem } from "../../../../../../atoms/list/AmeCommonListItem";
import { AmeSlider } from "../../../../../../atoms/slider";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../../../../../muiWrapper/AmeIconButton";

interface Props {
  ameSound: AmeSound;
}

export const FilePlayer: React.FC<Props> = ({ ameSound }) => {
  const [currentTime, setCurrentTime] = useState(() => ameSound.sound.seek());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(ameSound.sound.seek());
    }, 100);
    return () => clearInterval(interval);
  }, [ameSound.sound, setCurrentTime]);

  return (
    <AmeBox sx={{ display: "flex", flexDirection: "column", justifyContent: "stretch" }}>
      <AmeSlider
        min={0}
        max={ameSound.sound.duration()}
        value={currentTime}
        onChange={(value) => ameSound.sound.seek(value)}
        valueLabelDisplay={"auto"}
        valueLabelFormat={(value) => generateTimeDisplay(value)}
      />
      <AmeBox sx={{ display: "flex", justifyContent: "space-between" }}>
        <AmeTypography component={"p"} fontSize={"body2"}>
          {generateTimeDisplay(currentTime)}
        </AmeTypography>
        <AmeTypography component={"p"} fontSize={"body2"}>
          {generateTimeDisplay(ameSound.sound.duration())}
        </AmeTypography>
      </AmeBox>
      <AmeBox sx={{ display: "flex", justifyContent: "center" }}>
        <AmeBox sx={{ display: "flex", gap: "4px" }}>
          <AmeIconButton onClick={ameSound.backward}>
            <CounterClockwise size={24} color={"black"} />
          </AmeIconButton>
          <AmeIconButton onClick={() => ameSound.togglePlay()}>
            {ameSound.isPlaying ? <Pause size={24} color={"black"} /> : <Play size={24} color={"black"} />}
          </AmeIconButton>
          <AmeIconButton onClick={ameSound.forward}>
            <Clockwise size={24} color={"black"} />
          </AmeIconButton>
        </AmeBox>
        <Spacer width="16px" />
        <AmeDropbox selectedLabel={`${ameSound.rate}倍速`}>
          <AmeCommonListItem onClick={() => ameSound.changeRate(0.5)}>0.5倍速</AmeCommonListItem>
          <AmeCommonListItem onClick={() => ameSound.changeRate(0.75)}>0.75倍速</AmeCommonListItem>
          <AmeCommonListItem onClick={() => ameSound.changeRate(1.0)}>1倍速</AmeCommonListItem>
          <AmeCommonListItem onClick={() => ameSound.changeRate(1.25)}>1.25倍速</AmeCommonListItem>
          <AmeCommonListItem onClick={() => ameSound.changeRate(1.5)}>1.5倍速</AmeCommonListItem>
          <AmeCommonListItem onClick={() => ameSound.changeRate(2.0)}>2.0倍速</AmeCommonListItem>
        </AmeDropbox>
      </AmeBox>
    </AmeBox>
  );
};
