import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { SettingItemsContainer } from "../../../../../organisms/settings/common/SettingItemsContainer";
import { SettingLinkButton } from "../../../../../organisms/settings/common/SettingLinkButton";

const RuleSetting: React.FC = () => {
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader currentTitle={"ルール設定"} items={[{ to: "/settings", title: "設定" }]} />
      <PageLayoutBody>
        <SettingItemsContainer title="種別" justifyContent="left">
          <SettingLinkButton to="/settings/rules/categories">カテゴリー</SettingLinkButton>
          <SettingLinkButton to="/settings/rules/sub-categories">サブカテゴリー</SettingLinkButton>
        </SettingItemsContainer>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/rules/")({
  component: RuleSetting,
});
