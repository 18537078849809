import React, { useContext } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { LogoutButton } from "../../../../organisms/settings/common/LogoutButton";
import { SettingItem } from "../../../../organisms/settings/common/SettingItem";
import { SettingItemsContainer } from "../../../../organisms/settings/common/SettingItemsContainer";

const Settings: React.FC = () => {
  const {
    setting: {
      globalSetting: { activateHrInterview },
    },
  } = useContext(DataSourceContext);
  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title="設定" />
      <PageLayoutBody type="wide">
        <SettingItemsContainer title="基本設定" justifyContent={"left"}>
          <SettingItem
            settingTitle="グラフ区切り設定"
            to="/settings/graphs/segments"
            description="勤続年数や年齢などの属性におけるグラフ表示の区切りを設定できます"
          />
          <SettingItem
            settingTitle="従業員属性定義の設定"
            to="/settings/segments"
            description="個社毎に異なる属性データの定義を設定できます"
          />
          <SettingItem
            settingTitle="アンケートメール設定"
            to="/settings/surveys/emails"
            description="従業員向けアンケートのメール送信に利用する送信者名、および署名を設定できます"
          />
          <SettingItem
            settingTitle="グラフ目盛り設定"
            to="/settings/graphs/scales"
            description="システムで表示する、グラフの縦軸の最大値を設定できます。"
          />
        </SettingItemsContainer>
        {activateHrInterview && (
          <SettingItemsContainer title="在職者面談設定" justifyContent={"left"}>
            <SettingItem
              settingTitle="面談種類種類の設定"
              to="/settings/hr-interview-types"
              description="面談の種類やフォーマットを設定できます。"
            />
            <SettingItem
              settingTitle="面談後アンケート設定"
              to="/settings/hr-interview-feedbacks"
              description="面談後アンケート・アンケート通知メールの設定を行います。"
            />
          </SettingItemsContainer>
        )}
        <SettingItemsContainer title="メンバー設定" justifyContent={"left"}>
          <SettingItem
            settingTitle="グループ管理"
            to="/settings/members/groups"
            description="登録されたメンバーのグループを管理できます"
          />
        </SettingItemsContainer>
        <SettingItemsContainer title="ユーザー設定" justifyContent={"left"}>
          <SettingItem
            settingTitle="パスワード設定"
            to="/settings/passwords"
            description="ログインに利用するパスワードを変更できます"
          />
        </SettingItemsContainer>
        <LogoutButton />
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/")({
  component: Settings,
});
