import React, { useContext } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetSurvey } from "../../../../../../store/hooks/surveys";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../../organisms/common/DataSourceContext";
import { SurveyDetails } from "../../../../../organisms/surveys/$surveyId/SurveyDetails";
import { SurveyDetailsHeader } from "../../../../../organisms/surveys/$surveyId/headers";

const SurveyDetailPage: React.FC = () => {
  const { surveyId } = Route.useParams();
  return <SurveyDetailContent surveyId={surveyId} />;
};

interface Props {
  surveyId: string;
}

const SurveyDetailContent: React.FC<Props> = (props) => {
  const {
    setting: {
      globalSetting: { emailSignature },
    },
  } = useContext(DataSourceContext);
  const surveyQuery = useGetSurvey(props.surveyId);

  return (
    <WaitForSuccess queryState={surveyQuery}>
      {(survey) => (
        <PageLayoutWrapper>
          <BreadcrumbPageLayoutHeader
            items={[{ title: "アンケート一覧", to: "/surveys" }]}
            currentTitle="アンケート詳細"
          />
          <PageLayoutBody type="wide">
            <AmeBox sx={{ display: "flex", justifyContent: "center" }}>
              <AmeBox sx={{ width: "100%", maxWidth: "750px" }}>
                <SurveyDetailsHeader survey={survey} emailSignature={emailSignature} />
                <Spacer height="24px" />
                <SurveyDetails survey={survey} />
              </AmeBox>
            </AmeBox>
          </PageLayoutBody>
        </PageLayoutWrapper>
      )}
    </WaitForSuccess>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/surveys/$surveyId/")({
  component: SurveyDetailPage,
});
