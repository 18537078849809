/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HrInterviewRefResponse,
    HrInterviewRefResponseFromJSON,
    HrInterviewRefResponseFromJSONTyped,
    HrInterviewRefResponseToJSON,
    HrInterviewTranscriptionStatus,
    HrInterviewTranscriptionStatusFromJSON,
    HrInterviewTranscriptionStatusFromJSONTyped,
    HrInterviewTranscriptionStatusToJSON,
    UserGroupRefResponse,
    UserGroupRefResponseFromJSON,
    UserGroupRefResponseFromJSONTyped,
    UserGroupRefResponseToJSON,
    UserRefResponse,
    UserRefResponseFromJSON,
    UserRefResponseFromJSONTyped,
    UserRefResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewFileResponse
 */
export interface HrInterviewFileResponse {
    /**
     * 
     * @type {HrInterviewRefResponse}
     * @memberof HrInterviewFileResponse
     */
    hrInterview?: HrInterviewRefResponse;
    /**
     * 
     * @type {HrInterviewTranscriptionStatus}
     * @memberof HrInterviewFileResponse
     */
    transcriptionStatus?: HrInterviewTranscriptionStatus;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewFileResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof HrInterviewFileResponse
     */
    duration: number;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFileResponse
     */
    interviewerId?: string;
    /**
     * 
     * @type {UserRefResponse}
     * @memberof HrInterviewFileResponse
     */
    uploadUser?: UserRefResponse;
    /**
     * 
     * @type {UserGroupRefResponse}
     * @memberof HrInterviewFileResponse
     */
    userGroup?: UserGroupRefResponse;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFileResponse
     */
    originalFileName: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFileResponse
     */
    hrInterviewFileId: string;
}

export function HrInterviewFileResponseFromJSON(json: any): HrInterviewFileResponse {
    return HrInterviewFileResponseFromJSONTyped(json, false);
}

export function HrInterviewFileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewFileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hrInterview': !exists(json, 'hrInterview') ? undefined : HrInterviewRefResponseFromJSON(json['hrInterview']),
        'transcriptionStatus': !exists(json, 'transcriptionStatus') ? undefined : HrInterviewTranscriptionStatusFromJSON(json['transcriptionStatus']),
        'createdAt': (new Date(json['createdAt'])),
        'duration': json['duration'],
        'interviewerId': !exists(json, 'interviewerId') ? undefined : json['interviewerId'],
        'uploadUser': !exists(json, 'uploadUser') ? undefined : UserRefResponseFromJSON(json['uploadUser']),
        'userGroup': !exists(json, 'userGroup') ? undefined : UserGroupRefResponseFromJSON(json['userGroup']),
        'originalFileName': json['originalFileName'],
        'hrInterviewFileId': json['hrInterviewFileId'],
    };
}

export function HrInterviewFileResponseToJSON(value?: HrInterviewFileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hrInterview': HrInterviewRefResponseToJSON(value.hrInterview),
        'transcriptionStatus': HrInterviewTranscriptionStatusToJSON(value.transcriptionStatus),
        'createdAt': (value.createdAt.toISOString()),
        'duration': value.duration,
        'interviewerId': value.interviewerId,
        'uploadUser': UserRefResponseToJSON(value.uploadUser),
        'userGroup': UserGroupRefResponseToJSON(value.userGroup),
        'originalFileName': value.originalFileName,
        'hrInterviewFileId': value.hrInterviewFileId,
    };
}


