import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetInterviewTypes } from "../../../../../store/hooks/hr-interview-setting";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { HrInterviewReportContent } from "../../../../organisms/hr-interviews/interview-report";
import { HrInterviewReportQuery } from "../../../../organisms/hr-interviews/interview-report/HrInterviewReportQuery";

export const HrInterviewReport: React.FC = () => {
  const searchParams = Route.useSearch();
  const navigate = Route.useNavigate();

  const interviewTypesQuery = useGetInterviewTypes(true);
  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"在職者インタビューレポート"} />
      <PageLayoutBody type={"wide"}>
        <WaitForSuccess queryState={interviewTypesQuery}>
          {(interviewTypes) => (
            <HrInterviewReportContent searchParams={searchParams} navigate={navigate} interviewTypes={interviewTypes} />
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hr-interviews/interview-report")({
  component: HrInterviewReport,
  validateSearch: (searchParams: Partial<HrInterviewReportQuery>): HrInterviewReportQuery => {
    return {
      axis: searchParams.axis || "NONE",
      interviewTypeIds: searchParams.interviewTypeIds ?? [],
      followupRequired: searchParams.followupRequired ?? [],
      interviewDateFrom: searchParams.interviewDateFrom,
      interviewDateTo: searchParams.interviewDateTo,
      SEX: searchParams.SEX ?? [],
      DEPARTMENT: searchParams.DEPARTMENT ?? [],
      FREE1: searchParams.FREE1 ?? [],
      FREE2: searchParams.FREE2 ?? [],
      AGE: searchParams.AGE ?? [],
      MONTHS_OF_SERVICE: searchParams.MONTHS_OF_SERVICE ?? [],
      subCategoryIds: searchParams.subCategoryIds ?? [],
    };
  },
});
