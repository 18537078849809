import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";

import { UseChoiceGroupParams } from "./type";

export const useIsLinkedChoiceGroup = ({
  filterParams,
  navigate,
}: UseChoiceGroupParams<{ isLinked: boolean[] }>): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    const followUpRequiredChoices = [
      { value: false, label: "面談紐付け前" },
      { value: true, label: "面談紐付け済" },
    ];
    return {
      groupName: "音声ステータス",
      uiType: "flat",
      choiceOptions: followUpRequiredChoices.map((choice) => ({
        name: choice.label,
        toggleOption: async () =>
          await navigate({
            search: (prev: { isLinked: boolean[] }) => {
              const isLinkedSet = new Set(prev.isLinked);
              if (!isLinkedSet.delete(choice.value!)) {
                isLinkedSet.add(choice.value!);
              }
              return { ...prev, isLinked: Array.from(isLinkedSet) };
            },
          }),
        isChecked: filterParams.isLinked.includes(choice.value),
      })),
    };
  }, [filterParams, navigate]);
};
