declare class Id<T> {
    private _IDENTITY: T;
}
// 暗黙の型変換を防ぐためのハック
export type SerializedJson<T> = string & Id<T>;

export function serializeJson<T>(request: T): SerializedJson<T> {
    return JSON.stringify(request) as SerializedJson<T>;
}

export function deserializeJson<T>(jsonString: SerializedJson<T>): T {
    return JSON.parse(jsonString) as T;
}

export function removeEmptyFields(obj: Record<string, string | number | Date>) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}
