/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateInterviewFormatRequestBodyContents
 */
export interface UpdateInterviewFormatRequestBodyContents {
    /**
     * 
     * @type {number}
     * @memberof UpdateInterviewFormatRequestBodyContents
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateInterviewFormatRequestBodyContents
     */
    formatContent: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInterviewFormatRequestBodyContents
     */
    interviewFormatContentId?: string;
}

export function UpdateInterviewFormatRequestBodyContentsFromJSON(json: any): UpdateInterviewFormatRequestBodyContents {
    return UpdateInterviewFormatRequestBodyContentsFromJSONTyped(json, false);
}

export function UpdateInterviewFormatRequestBodyContentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInterviewFormatRequestBodyContents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': json['order'],
        'formatContent': json['formatContent'],
        'interviewFormatContentId': !exists(json, 'interviewFormatContentId') ? undefined : json['interviewFormatContentId'],
    };
}

export function UpdateInterviewFormatRequestBodyContentsToJSON(value?: UpdateInterviewFormatRequestBodyContents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': value.order,
        'formatContent': value.formatContent,
        'interviewFormatContentId': value.interviewFormatContentId,
    };
}


