import React, { useCallback, useState } from "react";

import { Employee } from "../../../models/Employee";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeButton } from "../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../atoms/spacers/Spacer";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { EmployeeSelector } from "../common/EmployeeSelector";

interface Props {
  title: string;
  onSubmit: (employee: Employee | null) => Promise<void>;
  onClose: () => void;
}

export const EmployeeSelectModalContent: React.FC<Props> = (props) => {
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const onChange = useCallback((e: Employee | null) => {
    setSelectedEmployee(e);
  }, []);
  const onSubmit = useCallback(async () => {
    await props.onSubmit(selectedEmployee);
    props.onClose();
  }, [props, selectedEmployee]);
  const theme = useAmeTheme();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[]} currentTitle={props.title} />
      <PageLayoutBody type="wide">
        <AmeTypography component={"p"}>従業員番号</AmeTypography>
        <Spacer height={"8px"} />
        <AmeBox sx={{ borderRadius: "8px", height: "54px", border: `1px solid ${theme.common.border.gray40}` }}>
          <EmployeeSelector value={selectedEmployee} onSelectEmployee={onChange} />
        </AmeBox>
        <Spacer height={"24px"} />
        <AmeBox>
          <AmeButton disabled={!selectedEmployee} onClick={onSubmit}>
            登録
          </AmeButton>
          <AmeBox component={"span"} sx={{ marginLeft: "16px" }}>
            <AmeButton color={"basic"} variant={"outlined"} onClick={props.onClose}>
              キャンセル
            </AmeButton>
          </AmeBox>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
