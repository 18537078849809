import React from "react";

import type { UseFormRegister, FormState, Control } from "react-hook-form";

import type { UserGroup } from "../../../../../../../models/UserGroup";
import { PaperBody } from "../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";

import { AuthInfoForm } from "./AuthInfoForm";
import { UserFormTemplate } from "./UserFormTemplate";

export interface UserEditFormType {
  surname: string;
  givenName: string;
  isAdmin: string;
  email: string;
  phoneNumber: string;
  userGroupIds: { userGroupId: string }[];
}

interface Props {
  register: UseFormRegister<UserEditFormType>;
  formState: FormState<UserEditFormType>;
  control: Control<UserEditFormType>;
  userGroups: UserGroup[];
}

export const UserEditor: React.FC<Props> = ({ register, formState, control, userGroups }) => {
  return (
    <>
      <PaperWrapper>
        <PaperHeader>
          <AmeTypography fontSize="body1">ユーザー詳細</AmeTypography>
        </PaperHeader>
        <PaperBody>
          <UserFormTemplate register={register} errors={formState.errors} />
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperHeader>
          <AmeTypography fontSize="body1">権限</AmeTypography>
        </PaperHeader>
        <PaperBody>
          <AuthInfoForm control={control} errors={formState.errors} userGroups={userGroups} register={register} />
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
    </>
  );
};
