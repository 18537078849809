import React, { useContext } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../../../organisms/common/DataSourceContext";
import { GraphScaleSettingTable } from "../../../../../../organisms/settings/graphs/scales/GraphScaleSettingTable";

export const SettingsGraphsScales: React.FC = () => {
  const {
    setting: { globalSetting },
  } = useContext(DataSourceContext);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader currentTitle={"グラフ目盛り設定"} items={[{ title: "設定", to: "/settings" }]} />
      <PageLayoutBody type={"wide"}>
        <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <AmeTypography component="h6">グラフ目盛り設定</AmeTypography>
          <AmeButton size={"large"} variant="outlined" to={`/settings/graphs/scales/detail`}>
            編集
          </AmeButton>
        </AmeBox>
        <Spacer height="24px" />
        <GraphScaleSettingTable globalSetting={globalSetting} />
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/graphs/scales/")({
  component: SettingsGraphsScales,
});
