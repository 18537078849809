import React, { useCallback } from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { useUpdateEmailSettings } from "../../../../../store/hooks/settings";
import { AmeFormset } from "../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { AmeTextarea } from "../../../../atoms/forms/textarea/AmeTextarea";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeFormButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";

interface Props {
  emailSenderName: string;
  emailSignature: string | undefined;
}

type EmailSettingsFormType = {
  emailSenderName: string;
  emailSignature: string | undefined;
};

export const EmailSettingsForm: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useHrPentestSnackbar();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmailSettingsFormType>({
    criteriaMode: "all",
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: {
      emailSenderName: props.emailSenderName,
      emailSignature: props.emailSignature,
    },
  });
  const onError = useCallback(() => {
    enqueueErrorSnackbar({ title: "アンケートメール設定の更新に失敗しました。担当者にお問い合わせください。" });
  }, [enqueueErrorSnackbar]);

  const updateEmailSettings = useUpdateEmailSettings({ onError });

  const onSubmit = useCallback(
    async (data: EmailSettingsFormType) => {
      await updateEmailSettings({
        updateEmailSettingsBody: {
          emailSenderName: data.emailSenderName,
          emailSignature: data.emailSignature,
        },
      });
      void navigate({ to: "/settings" });
      enqueueSuccessSnackbar({ title: "アンケートメール設定を更新しました。" });
    },
    [updateEmailSettings, enqueueSuccessSnackbar, navigate],
  );

  return (
    <>
      <AmeFormset label="アンケート送信者名" errorMessage={errors.emailSenderName?.message} required>
        <AmeInput {...register("emailSenderName", { required: "空の送信者名は設定できません。" })} fullWidth />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormset label="アンケートメール署名">
        <AmeTextarea {...register("emailSignature")} placeholder={"〇〇株式会社△△部☓☓"} fullWidth />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormButtonsLayout onPrimary={handleSubmit(onSubmit)} primaryText="変更" />
    </>
  );
};
