import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const ArrowRight: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 17 17">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14592 2.14689C5.19236 2.10033 5.24754 2.06339 5.30828 2.03818C5.36903 2.01297 5.43415 2 5.49992 2C5.56568 2 5.63081 2.01297 5.69155 2.03818C5.7523 2.06339 5.80747 2.10033 5.85392 2.14689L11.8539 8.14689C11.9005 8.19334 11.9374 8.24852 11.9626 8.30926C11.9878 8.37001 12.0008 8.43513 12.0008 8.50089C12.0008 8.56666 11.9878 8.63178 11.9626 8.69253C11.9374 8.75327 11.9005 8.80845 11.8539 8.85489L5.85392 14.8549C5.76003 14.9488 5.63269 15.0015 5.49992 15.0015C5.36714 15.0015 5.2398 14.9488 5.14592 14.8549C5.05203 14.761 4.99929 14.6337 4.99929 14.5009C4.99929 14.3681 5.05203 14.2408 5.14592 14.1469L10.7929 8.50089L5.14592 2.85489C5.09935 2.80845 5.06241 2.75327 5.0372 2.69253C5.012 2.63178 4.99902 2.56666 4.99902 2.50089C4.99902 2.43513 5.012 2.37001 5.0372 2.30926C5.06241 2.24852 5.09935 2.19334 5.14592 2.14689Z"
        fill="currentColor"
      />
    </SvgWrapper>
  );
};
