import React from "react";

import { Link } from "@tanstack/react-router";

import { InterviewFile } from "../../../models/InterviewFile";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../atoms/table/AmeTable";
import { AmeTypography } from "../../atoms/typography/AmeTypography";

interface Props {
  interviewFiles: InterviewFile[];
}

export const InterviewFileTable: React.FC<Props> = (props) => {
  return (
    <AmeTable>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell>タイトル</AmeTableHeadCell>
          <AmeTableHeadCell>紐付き先従業員番号</AmeTableHeadCell>
          <AmeTableHeadCell>ステータス</AmeTableHeadCell>
          <AmeTableHeadCell></AmeTableHeadCell>
          <AmeTableHeadCell></AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {props.interviewFiles.map((file, i) => (
          <AmeTableRow key={i}>
            <AmeTableBodyCell>
              <Link
                to={"/interview-files/$interviewRecordingId"}
                params={{ interviewRecordingId: file.interviewRecordingId }}
              >
                <AmeTypography component={"p"} fontSize={"body2"} color={"secondary"}>
                  {file.originalFileName}
                </AmeTypography>
              </Link>
            </AmeTableBodyCell>
            <AmeTableBodyCell>{file.employee?.customerCreatedId}</AmeTableBodyCell>
            <AmeTableBodyCell>{file.getInternalStatusText()}</AmeTableBodyCell>
            <AmeTableBodyCell>
              <Link
                to={"/interview-files/$interviewRecordingId/transcription"}
                params={{ interviewRecordingId: file.interviewRecordingId }}
              >
                <AmeTypography component={"p"} fontSize={"body2"} color={"secondary"}>
                  文字起こし
                </AmeTypography>
              </Link>
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              <Link
                to={"/interview-files/$interviewRecordingId/transcription"}
                params={{ interviewRecordingId: file.interviewRecordingId }}
                search={{ check: true }}
              >
                <AmeTypography component={"p"} fontSize={"body2"} color={"secondary"}>
                  チェック
                </AmeTypography>
              </Link>
            </AmeTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
