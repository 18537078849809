import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Hamburger: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 19 15" size={16}>
      <line y1="0.5" x2="19" y2="0.5" stroke="currentColor" />
      <line y1="7.5" x2="19" y2="7.5" stroke="currentColor" />
      <line y1="14.5" x2="19" y2="14.5" stroke="currentColor" />
    </SvgWrapper>
  );
};
