import { HrInterviewFile } from "../../models/hrInterview/HrInterviewFile";
import { PaginatedList } from "../../models/utils/PaginatedList";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useGetHrInterviewFiles = (
  count: number,
  offset: number,
  filterParams: {
    from: string | undefined;
    to: string | undefined;
    uploaderUserIds: string[];
    linkWithInterview: boolean[];
  },
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.findHrInterviewFiles.useQuery(
    {
      count,
      offset,
      createdAtStart: filterParams.from,
      createdAtEnd: filterParams.to,
      uploadUserIds: filterParams.uploaderUserIds,
      linkWithInterview: filterParams.linkWithInterview,
    },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (res): PaginatedList<HrInterviewFile> => ({
      totalCount: res.totalCount,
      items: res.files.map(HrInterviewFile.fromResponse),
    }),
    onError: options?.onError,
  });
};

export const useGetHrInterviewFile = (hrInterviewFileId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getHrInterviewFile.useQuery(
    { hrInterviewFileId },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: HrInterviewFile.fromResponse,
    onError: options?.onError,
  });
};

export const useDeleteInterviewFile = (options?: UseMutationOptions) => {
  const [deleteHrInterviewFile] = defaultApi.endpoints.deleteHrInterviewFile.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteHrInterviewFile,
    onError: options?.onError,
  });
};

export const useUpdateInterviewFile = (options?: UseMutationOptions) => {
  const [updateHrInterviewFile] = defaultApi.endpoints.updateHrInterviewFile.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateHrInterviewFile,
    onError: options?.onError,
  });
};
export const useGetHrInterviewFileUploaderUrl = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.getHrInterviewFileUploaderUrl.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};
export const useCompleteHrInterviewFileUpload = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.completeHrInterviewFileUpload.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};
export const useCreateHrInterviewFile = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.createHrInterviewFile.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};
