/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComputationTaskResponse,
    ComputationTaskResponseFromJSON,
    ComputationTaskResponseFromJSONTyped,
    ComputationTaskResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubCategoryResponseMeta
 */
export interface SubCategoryResponseMeta {
    /**
     * 
     * @type {ComputationTaskResponse}
     * @memberof SubCategoryResponseMeta
     */
    latestComputationTask?: ComputationTaskResponse;
}

export function SubCategoryResponseMetaFromJSON(json: any): SubCategoryResponseMeta {
    return SubCategoryResponseMetaFromJSONTyped(json, false);
}

export function SubCategoryResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubCategoryResponseMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latestComputationTask': !exists(json, 'latestComputationTask') ? undefined : ComputationTaskResponseFromJSON(json['latestComputationTask']),
    };
}

export function SubCategoryResponseMetaToJSON(value?: SubCategoryResponseMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latestComputationTask': ComputationTaskResponseToJSON(value.latestComputationTask),
    };
}


