import { CalculatedSegmentKey, CalculatedSegmentSettingResponse } from "../store/autogenApi";

export class CalculatedSegmentDefinition {
  constructor(
    readonly segmentKey: CalculatedSegmentKey,
    readonly selectedCalculationStrategy: string,
  ) {}

  public static fromResponse(response: CalculatedSegmentSettingResponse): CalculatedSegmentDefinition {
    return new CalculatedSegmentDefinition(response.segmentKey, response.selectedCalculationStrategy);
  }
}
