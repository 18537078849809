import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";

export const useGetFeedbackSurvey = () => {
  const result = defaultApi.endpoints.getHrInterviewFeedbackQuestionList.useQuery();
  return useQueryResult({
    result,
    fromResponse: (r) => r,
  });
};

export const useUpdateFeedbackQuestion = () => {
  const [updateFeedbackSurvey] = defaultApi.endpoints.updateHrInterviewFeedbackQuestions.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateFeedbackSurvey,
  });
};

export const useDeleteFeedbackQuestion = () => {
  const [deleteFeedbackSurvey] = defaultApi.endpoints.deleteHrInterviewFeedbackQuestion.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteFeedbackSurvey,
  });
};
