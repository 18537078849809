import React from "react";

import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  icon: React.ReactNode;
  text: string;
}

export const SurveyTableBodyCellWithPrefixIconTemplate: React.FC<Props> = ({ icon, text }) => {
  return (
    <AmeBox sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
      {icon}
      <Spacer width="8px" />
      <AmeTypography>{text}</AmeTypography>
    </AmeBox>
  );
};
