import React, { useCallback } from "react";

import { generateSurveyEmailBody } from "@amecloud/common-utils/src/utils/EmailUtils";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SurveyModel } from "../../../../../models/Survey";
import { useSendRemindSurveySheet } from "../../../../../store/hooks/surveys";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmeModal } from "../../../../atoms/modal/AmeModal";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  emailSignature?: string;
  survey: SurveyModel;
  isOpen: boolean;
  onClose: () => void;
}

export const RemindSurveyModal: React.FC<Props> = (props) => {
  const sendRemindSurveySheet = useSendRemindSurveySheet();
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();

  const onSubmit = useCallback(async () => {
    const result = await sendRemindSurveySheet({
      surveyId: props.survey.surveyId,
    });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "リマインドメールを送信しました。" });
      props.onClose();
    } else {
      enqueueErrorSnackbar({ title: "リマインドメールの送信に失敗しました。" });
    }
  }, [sendRemindSurveySheet, enqueueSuccessSnackbar, enqueueErrorSnackbar, props]);

  if (props.survey.emailSubject === null || props.survey.emailBody === null) {
    enqueueErrorSnackbar({ title: "メールの件名と本文がありません。" });
    return null;
  }

  return (
    <AmeModal open={props.isOpen} onClose={props.onClose}>
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader items={[]} currentTitle="未回答者へリマインド" />
        <PageLayoutBody type="wide">
          <AmeBox
            sx={{
              "> *": { borderBottom: "1px solid #cccccc", padding: "16px 0" },
              "> *:first-child": { paddingTop: "0", paddingBottom: "24px" },
            }}
          >
            <AmeTypography component="h5">
              未回答者へリマインドメール送信します
              <br />
              以下の内容でよろしければ、メールを送信ボタンを押してください。
            </AmeTypography>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>件名</AmeTypography>
              </AmeBox>
              <AmeTypography>{"【リマインド】" + props.survey.emailSubject}</AmeTypography>
            </AmeBox>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>本文</AmeTypography>
              </AmeBox>
              <AmeTypography>
                {generateSurveyEmailBody(props.survey.emailBody ?? "", "{アンケートURL}", props.emailSignature)}
              </AmeTypography>
            </AmeBox>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>未回答者数</AmeTypography>
              </AmeBox>
              <AmeTypography>{props.survey.unansweredEmailSheetsCount}人</AmeTypography>
            </AmeBox>
          </AmeBox>
          <Spacer height="24px" />
          <AmeBox sx={{ display: "flex", justifyContent: "right", gap: "16px" }}>
            <AmeButton variant="outlined" onClick={props.onClose}>
              戻る
            </AmeButton>
            <AmeButton onClick={onSubmit} disabled={props.survey.unansweredEmailSheetsCount === 0}>
              メールを送信
            </AmeButton>
          </AmeBox>
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeModal>
  );
};
