import React, { useState } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { DefinitionList } from "../../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import {
  EmployeeTenureTable,
  TenureEntry,
} from "../../../../../organisms/employee/employee-details/EmployeeTenureTable";

const defaultTenures = [
  {
    startDate: new Date(2024, 11, 14),
    endDate: new Date(2024, 12, 31),
    tenureDetails: {
      effectiveFrom: "2024年1月15日",
      department: "プロジェクト",
      free1: "非常勤",
      free2: "非常勤嘱託参与",
      gender: "男性",
    },
  },
  {
    startDate: new Date(2025, 1, 1),
    tenureDetails: {
      effectiveFrom: "2025年1月1日",
      department: "営業部",
      free1: "正社員",
      free2: "マネージャー",
      gender: "男性",
    },
  },
];

export const useGetTenures = () => {
  const [tenures, setTenures] = useState<TenureEntry[]>(defaultTenures);
  return { tenures, setTenures };
};

const EmployeeDetails: React.FC = () => {
  const { tenures } = useGetTenures();
  const navigate = useNavigate();
  const { employeeId } = Route.useParams();
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[{ title: "従業員一覧", to: "/employees" }]} currentTitle="従業員詳細" />
      <PageLayoutHeader
        title="田中太郎"
        endElement={
          <AmeButton variant="outlined" color="basic">
            削除
          </AmeButton>
        }
      />
      <PageLayoutBody type="wide">
        <PaperWrapper>
          <PaperHeader>従業員情報</PaperHeader>
          <PaperBody>
            <DefinitionList>
              <DefinitionListItem label={"従業員番号"} value={"1111"} />
              <DefinitionListItem label={"名前"} value={"田中太郎"} />
              <DefinitionListItem label={"メールアドレス"} value={"taro.tanaka@example.com"} />
              <DefinitionListItem label={"年齢"} value={"20"} />
            </DefinitionList>
          </PaperBody>
        </PaperWrapper>

        <Spacer height="24px" />

        <PaperWrapper>
          <PaperHeader>属性履歴</PaperHeader>
          <PaperBody>
            <EmployeeTenureTable
              tenures={tenures}
              onDelete={() => {
                /* 削除処理 */
              }}
              onEdit={async (index: number) => {
                await navigate({ to: "/employees/" + employeeId + "/tenure-edit", search: { index: index } });
              }}
              onAddAttribute={() => {
                /* 属性追加処理 */
              }}
            />
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/employees/$employeeId/")({
  component: EmployeeDetails,
});
