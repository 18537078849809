import React, { useCallback, useState } from "react";

import { useSp } from "../../../hooks/useSp";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeButton } from "../../atoms/button/AmeButton";
import { AmeCheckbox } from "../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../atoms/spacers/Spacer";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  onClose: () => void;
  onDelete: () => Promise<void>;
}

export const AudioDeleteDialogContent: React.FC<Props> = ({ onClose, onDelete }) => {
  const sp = useSp();
  const theme = useAmeTheme();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onSubmit = useCallback(async () => {
    await onDelete();
  }, [onDelete]);

  const onChange = useCallback(() => {
    setIsChecked(!isChecked);
  }, [setIsChecked, isChecked]);

  return (
    <>
      <AmeBox
        sx={{
          padding: "0 24px",
          display: "flex",
          alignItems: "center",
          borderBottom: `1px solid ${theme.common.border.gray40}`,
          height: "56px",
          backgroundColor: theme.common.background.warning20,
          justifyContent: sp ? "center" : "start",
        }}
      >
        <AmeTypography color="warning" component="h5">
          本当に削除しますか？
        </AmeTypography>
      </AmeBox>
      <AmeBox sx={{ padding: "24px" }}>
        <AmeTypography>削除すると、元に戻すことができません。</AmeTypography>
        <AmeTypography>問題ない場合は削除ボタンを押してください。</AmeTypography>
        <Spacer height="24px" />
        <AmeBox sx={{ display: "flex", justifyContent: sp ? "center" : "start" }}>
          <AmeBox onClick={onChange} sx={{ display: "inline-flex" }}>
            <AmeCheckbox checked={isChecked} />
            <Spacer width="8px" />
            <AmeTypography>注意事項を確認しました</AmeTypography>
          </AmeBox>
        </AmeBox>
      </AmeBox>
      <AmeBox
        sx={{
          display: "flex",
          flexDirection: sp ? "column-reverse" : "row",
          justifyContent: sp ? "center" : "end",
          gap: "16px",
          padding: "0 24px 24px 24px",
        }}
      >
        <AmeButton variant="outlined" onClick={onClose}>
          キャンセル
        </AmeButton>
        <AmeButton variant="contained" onClick={onSubmit} color={"primary"} disabled={!isChecked}>
          削除
        </AmeButton>
      </AmeBox>
    </>
  );
};
