import React, { useCallback } from "react";

import { useNavigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { useSp } from "../../../../../hooks/useSp";
import { UserGroupRef } from "../../../../../models/UserGroup";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeFormFollowingButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { AudioFilesImporterTemp } from "../../../interview-files/register/common/AudioFilesImporter";
import { FileProperties, InterviewFileNameMapper } from "../../InterviewFileNameMapper";

const CreateContent: React.FC<{
  interviewFiles: File[];
  registerFileProperties: FileProperties[];
  setInterviewFiles: (files: File[]) => void;
  removeFile: (index: number) => void;
}> = ({ interviewFiles, registerFileProperties, setInterviewFiles, removeFile }) => {
  return (
    <>
      <PaperWrapper>
        <PaperHeader>音声データ登録</PaperHeader>
        <PaperBody>
          <AmeTypography component="h6">音声データのファイルを選択してください</AmeTypography>
          <Spacer height="24px" />
          {/* 一時的にsp版デザインを使用するためのコンポーネント AudioFilesImporterでまとめるのが望ましい*/}
          <AudioFilesImporterTemp onImported={setInterviewFiles} />
        </PaperBody>
      </PaperWrapper>
      {registerFileProperties.length > 0 && (
        <>
          <Spacer height={"24px"} />
          <PaperWrapper>
            <PaperHeader>登録音声データ</PaperHeader>
            <PaperBody>
              {interviewFiles.length > 0 && (
                <InterviewFileNameMapper interviewFileProperties={registerFileProperties} removeFile={removeFile} />
              )}
            </PaperBody>
          </PaperWrapper>
        </>
      )}
    </>
  );
};
export const CreateInterviewFileContent: React.FC<{
  removeFile: (index: number) => void;
  setFiles: (files: File[]) => void;
  interviewFiles: File[];
  defaultUserGroup?: UserGroupRef;
  registerInterviewFile: (fileProperties: FileProperties[], userGroup?: UserGroupRef) => Promise<void>;
  registerFileProperties: FileProperties[];
}> = ({ removeFile, setFiles, interviewFiles, registerFileProperties, registerInterviewFile, defaultUserGroup }) => {
  const sp = useSp();
  const navigate = useNavigate();
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();

  const onSubmit = useCallback(async () => {
    await registerInterviewFile(registerFileProperties, defaultUserGroup);
    enqueueSuccessSnackbar({
      title: "音声データを保存しました。",
      message: sp ? "登録した音声から面談記録を作成する場合はPCから操作してください。" : undefined,
    });
    await navigate({ to: "/hr-interviews/interview-files", search: {} as never });
  }, [registerFileProperties, registerInterviewFile, enqueueSuccessSnackbar, navigate, sp, defaultUserGroup]);

  return (
    <>
      <CreateContent
        interviewFiles={interviewFiles}
        registerFileProperties={registerFileProperties}
        setInterviewFiles={setFiles}
        removeFile={removeFile}
      />
      {sp ? (
        <AmeBox sx={{ padding: "24px" }}>
          <AmeButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={
              registerFileProperties.length === 0 || !registerFileProperties.every((file) => file.status === "uploaded")
            }
          >
            保存
          </AmeButton>
          <Spacer height="10px" />
          <AmeButton
            fullWidth
            variant="outlined"
            onClick={async () => {
              await navigate({ to: "/hr-interviews/interview-files", search: {} as never });
            }}
          >
            キャンセル
          </AmeButton>
        </AmeBox>
      ) : (
        <AmeFormFollowingButtonsLayout
          onPrimary={onSubmit}
          onSecondary={async () => {
            await navigate({ to: "/hr-interviews/interview-files", search: {} as never });
          }}
          primaryDisabled={
            registerFileProperties.length === 0 || !registerFileProperties.every((file) => file.status === "uploaded")
          }
          secondaryText={"キャンセル"}
        />
      )}
    </>
  );
};
