import { useContext, useMemo } from "react";

import {
  AGE_CONDITIONS_OPTIONS,
  MONTHS_OF_SERVICE_CONDITIONS_OPTIONS,
} from "@amecloud/common-utils/src/constants/calculatedSegmentDefinition";

import { ChoiceSubGroup, NestedChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { DataSourceContext } from "../../components/organisms/common/DataSourceContext";
import { CalculatedSegmentKey, InputSegmentKey, SegmentKey } from "../../models/constants/values";
import { useGetCalculatedSegmentConditionSetting } from "../../store/hooks/segmentDefinitions";

export const useGetSegmentContent = (
  filterParams: { [key in keyof typeof SegmentKey]: string[] },
  navigate: (params: {
    search: (prev: { [key in keyof typeof SegmentKey]: string[] }) => { [key in keyof typeof SegmentKey]: string[] };
  }) => Promise<void>,
): NestedChoiceGroupProps => {
  const segmentList = useSegmentList();
  const {
    setting: { segmentNames },
  } = useContext(DataSourceContext);

  return useMemo<NestedChoiceGroupProps>(() => {
    const choiceSubGroupList: ChoiceSubGroup[] = [];
    for (const { segmentKey, segmentValues } of segmentList) {
      const choiceSubGroup: ChoiceSubGroup = {
        subGroupName: segmentNames[segmentKey],
        choiceOptions: [],
      };
      for (const segmentValue of segmentValues) {
        choiceSubGroup.choiceOptions.push({
          name: segmentValue,
          isChecked: filterParams[segmentKey] ? filterParams[segmentKey].includes(segmentValue) : false,
          toggleOption: () =>
            navigate({
              search: (prev) => {
                const segmentValueSet = new Set(prev[segmentKey]);
                if (segmentValueSet.has(segmentValue)) {
                  segmentValueSet.delete(segmentValue);
                } else {
                  segmentValueSet.add(segmentValue);
                }
                return { ...prev, [segmentKey]: Array.from(segmentValueSet) };
              },
            }),
        });
      }
      if (choiceSubGroup.choiceOptions.length) {
        choiceSubGroupList.push(choiceSubGroup);
      }
    }

    return {
      groupName: "セグメント",
      uiType: "nested",
      choiceSubGroups: choiceSubGroupList,
    };
  }, [filterParams, navigate, segmentList, segmentNames]);
};

const useSegmentList = () => {
  const { setting } = useContext(DataSourceContext);
  const ageCalculatedConditionQuery = useGetCalculatedSegmentConditionSetting(CalculatedSegmentKey.AGE);
  const monthsOfServiceCalculatedConditionQuery = useGetCalculatedSegmentConditionSetting(
    CalculatedSegmentKey.MONTHS_OF_SERVICE,
  );

  return useMemo<{ segmentKey: keyof typeof SegmentKey; segmentValues: string[] }[]>(() => {
    const result: { [key in keyof typeof InputSegmentKey]: Set<string> } = {
      [InputSegmentKey.SEX]: new Set<string>(),
      [InputSegmentKey.DEPARTMENT]: new Set<string>(),
      [InputSegmentKey.FREE1]: new Set<string>(),
      [InputSegmentKey.FREE2]: new Set<string>(),
    };
    for (const definition of setting.inputSegmentDefinitionMap.definitionMap.values()) {
      result[definition.segmentKey].add(definition.mappedTo ?? definition.segmentValue);
    }
    return [
      { segmentKey: InputSegmentKey.SEX, segmentValues: Array.from(result[InputSegmentKey.SEX]) },
      { segmentKey: InputSegmentKey.DEPARTMENT, segmentValues: Array.from(result[InputSegmentKey.DEPARTMENT]) },
      { segmentKey: InputSegmentKey.FREE1, segmentValues: Array.from(result[InputSegmentKey.FREE1]) },
      { segmentKey: InputSegmentKey.FREE2, segmentValues: Array.from(result[InputSegmentKey.FREE2]) },
      {
        segmentKey: SegmentKey.AGE,
        segmentValues: ageCalculatedConditionQuery.data
          ? AGE_CONDITIONS_OPTIONS[ageCalculatedConditionQuery.data.selectedCalculationStrategy].conditions.map(
              ({ mappedTo }) => mappedTo,
            )
          : [],
      },
      {
        segmentKey: SegmentKey.MONTHS_OF_SERVICE,
        segmentValues: monthsOfServiceCalculatedConditionQuery.data
          ? MONTHS_OF_SERVICE_CONDITIONS_OPTIONS[
              monthsOfServiceCalculatedConditionQuery.data.selectedCalculationStrategy
            ].conditions.map(({ mappedTo }) => mappedTo)
          : [],
      },
    ];
  }, [setting, ageCalculatedConditionQuery.data, monthsOfServiceCalculatedConditionQuery.data]);
};
