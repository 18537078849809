/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewDocumentResponseEmployee,
    InterviewDocumentResponseEmployeeFromJSON,
    InterviewDocumentResponseEmployeeFromJSONTyped,
    InterviewDocumentResponseEmployeeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewDocumentResponse
 */
export interface InterviewDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponse
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponse
     */
    sentence: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewDocumentResponse
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewDocumentResponse
     */
    length: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InterviewDocumentResponse
     */
    categoryIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InterviewDocumentResponse
     */
    subCategoryIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponse
     */
    exEmployeeInterviewId: string;
    /**
     * 
     * @type {InterviewDocumentResponseEmployee}
     * @memberof InterviewDocumentResponse
     */
    employee: InterviewDocumentResponseEmployee;
}

export function InterviewDocumentResponseFromJSON(json: any): InterviewDocumentResponse {
    return InterviewDocumentResponseFromJSONTyped(json, false);
}

export function InterviewDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewDocumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': json['documentId'],
        'sentence': json['sentence'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'length': json['length'],
        'categoryIds': json['categoryIds'],
        'subCategoryIds': json['subCategoryIds'],
        'exEmployeeInterviewId': json['exEmployeeInterviewId'],
        'employee': InterviewDocumentResponseEmployeeFromJSON(json['employee']),
    };
}

export function InterviewDocumentResponseToJSON(value?: InterviewDocumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'sentence': value.sentence,
        'offset': value.offset,
        'length': value.length,
        'categoryIds': value.categoryIds,
        'subCategoryIds': value.subCategoryIds,
        'exEmployeeInterviewId': value.exEmployeeInterviewId,
        'employee': InterviewDocumentResponseEmployeeToJSON(value.employee),
    };
}


