import React from "react";
import { useCallback } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { endOfDay, formatISO } from "date-fns";

import { useHrPentestSnackbar } from "../../../../../../hooks/useHrPentestSnackbar";
import { SurveySendingMethod } from "../../../../../../models/constants/values";
import { useGetSurvey, useUpdateSurvey } from "../../../../../../store/hooks/surveys";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { SurveyForm } from "../../../../../organisms/surveys/create/SurveyForm";

const SurveyEditPage: React.FC = () => {
  const { surveyId } = Route.useParams();
  return <SurveyEditPageContent surveyId={surveyId} />;
};

interface Props {
  surveyId: string;
}

export const SurveyEditPageContent: React.FC<Props> = (props) => {
  const surveyQuery = useGetSurvey(props.surveyId);
  const updateSurvey = useUpdateSurvey();
  const navigate = useNavigate();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onClickCancel = useCallback(async () => {
    await navigate({ to: "/surveys/" + props.surveyId });
  }, [navigate, props.surveyId]);

  const handleSubmit = useCallback(
    async (
      method: SurveySendingMethod,
      title: string,
      description: string,
      emailSubject: string,
      emailBody: string,
      dueDateTime: Date,
    ) => {
      const useQrCode = method === "both" || method === "qr-code";
      const sendEmail = method === "both" || method === "email";
      const result = await updateSurvey({
        surveyId: props.surveyId,
        updateSurveyRequestBody: {
          sendEmail,
          useQrcode: useQrCode,
          displayName: title,
          surveyTitle: title,
          surveyDescription: description,
          emailSubject: sendEmail ? emailSubject : null,
          emailBody: sendEmail ? emailBody : null,
          dueDateTime: formatISO(endOfDay(dueDateTime)),
        },
      });
      if (result.isSuccess) {
        await navigate({ to: `/surveys/${props.surveyId}` });
        return;
      }
      enqueueErrorSnackbar({ title: "アンケートの更新に失敗しました。" });
    },
    [props.surveyId, updateSurvey, navigate, enqueueErrorSnackbar],
  );
  return (
    <WaitForSuccess queryState={surveyQuery}>
      {(survey) => (
        <PageLayoutWrapper>
          <BreadcrumbPageLayoutHeader
            items={[
              {
                title: "アンケート一覧",
                to: "/surveys",
              },
              {
                title: `${survey.displayName}`,
                to: `/surveys/$surveyId`,
                params: { surveyId: survey.surveyId },
              },
            ]}
            currentTitle="アンケート編集"
          />

          <SurveyForm
            method={survey.sendEmail ? (survey.useQrcode ? "both" : "email") : "qr-code"}
            survey={survey}
            questions={survey.questions}
            onClickCancel={onClickCancel}
            handleSubmit={handleSubmit}
          />
        </PageLayoutWrapper>
      )}
    </WaitForSuccess>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/surveys/$surveyId/edit")({
  component: SurveyEditPage,
});
