import React, { useEffect } from "react";

import { UserGroup, UserGroupRef } from "../../../../../../models/UserGroup";
import { HrInterviewFile } from "../../../../../../models/hrInterview/HrInterviewFile";
import { AmeFormset } from "../../../../../atoms/forms/AmeFormset";
import { AmeDropbox } from "../../../../../atoms/forms/input/AmeDropbox";
import { AmeCommonListItem } from "../../../../../atoms/list/AmeCommonListItem";
import { AmeCommonListMainContent } from "../../../../../atoms/list/AmeCommonListMainContent";

interface Props {
  userGroups: UserGroup[];
  selectedGroup: UserGroupRef | undefined;
  setGroup: (group: UserGroupRef | undefined) => void;
  interviewFile: HrInterviewFile;
  error: boolean;
}

export const EditInterviewFileContent: React.FC<Props> = ({
  selectedGroup,
  setGroup,
  interviewFile,
  userGroups,
  error,
}) => {
  useEffect(() => {
    setGroup(interviewFile.userGroup);
  }, [interviewFile.userGroup, setGroup, userGroups]);
  return (
    <AmeFormset label={"グループ"} errorMessage={error ? "必須です。" : undefined}>
      <AmeDropbox selectedLabel={selectedGroup?.displayName} error={false} disabled={false} size="full">
        <AmeCommonListItem onClick={() => setGroup(undefined)}>
          <AmeCommonListMainContent>削除</AmeCommonListMainContent>
        </AmeCommonListItem>
        {userGroups.map((userGroup) => {
          return (
            <AmeCommonListItem onClick={() => setGroup(userGroup)} key={userGroup.userGroupId}>
              <AmeCommonListMainContent>{userGroup.displayName}</AmeCommonListMainContent>
            </AmeCommonListItem>
          );
        })}
      </AmeDropbox>
    </AmeFormset>
  );
};
