/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewQualityReportResponse,
    InterviewQualityReportResponseFromJSON,
    InterviewQualityReportResponseFromJSONTyped,
    InterviewQualityReportResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewQualityReportListResponse
 */
export interface InterviewQualityReportListResponse {
    /**
     * 
     * @type {Array<InterviewQualityReportResponse>}
     * @memberof InterviewQualityReportListResponse
     */
    qualityReports: Array<InterviewQualityReportResponse>;
}

export function InterviewQualityReportListResponseFromJSON(json: any): InterviewQualityReportListResponse {
    return InterviewQualityReportListResponseFromJSONTyped(json, false);
}

export function InterviewQualityReportListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewQualityReportListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'qualityReports': ((json['qualityReports'] as Array<any>).map(InterviewQualityReportResponseFromJSON)),
    };
}

export function InterviewQualityReportListResponseToJSON(value?: InterviewQualityReportListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'qualityReports': ((value.qualityReports as Array<any>).map(InterviewQualityReportResponseToJSON)),
    };
}


