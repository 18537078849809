import React, { useCallback, useMemo } from "react";

import { Box } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { AmeTheme, useAmeTheme } from "../../../utils/styles/AmeTheme";

export type ChoosableListItemSize = "small" | "medium";

interface Props<Value> {
  size?: ChoosableListItemSize;
  disabled?: boolean;
  onSelect?: (value: Value | undefined | null) => void;
  children: React.ReactNode;
  selected?: boolean;
  /** 未選択はundefinedを指定. AmeDropdownのvalueと型を合わせてね (そこまでは型推論が効かないため) */
  value?: Value | null;
  selectedValue?: Value | null;
  forSelect?: boolean;
}

export const ChoosableListItem = <Value,>({
  size,
  onSelect,
  value,
  children,
  disabled,
  selectedValue,
  forSelect,
}: Props<Value>) => {
  const theme = useAmeTheme();
  const selected = useMemo(() => forSelect && selectedValue === value, [selectedValue, value, forSelect]);
  const styles = useListItemStyle(theme, size, selected, disabled);

  const onClick = useCallback(() => {
    if (onSelect && !disabled) onSelect(value);
  }, [onSelect, value, disabled]);

  return (
    <Box sx={styles} onClick={onClick}>
      {children}
    </Box>
  );
};

const useListItemStyle = (
  theme: AmeTheme,
  size: ChoosableListItemSize | undefined,
  selected: boolean | undefined,
  disabled: boolean | undefined,
) => {
  return useMemo(() => {
    const baseStyle: SystemStyleObject = {
      display: "flex",
      height: size === "small" ? "36px" : "48px",
      padding: "0 16px",
      alignItems: "center",
      color: theme.common.text.black,
      ":hover": {
        backgroundColor: theme.common.background.gray40,
        color: theme.common.text.black,
        cursor: "pointer",
      },
    };
    if (disabled) {
      return {
        ...baseStyle,
        color: theme.common.text.gray20,
        backgroundColor: theme.common.background.gray40,
        ":hover": undefined,
      };
    }
    if (selected) {
      return {
        ...baseStyle,
        color: theme.common.text.black,
        backgroundColor: theme.brand.secondary["20"],
        ":hover": undefined,
      };
    }
    return baseStyle;
  }, [size, selected, disabled, theme]);
};
