/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateGraphScaleSettingsBody
 */
export interface UpdateGraphScaleSettingsBody {
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxMinutesInKpiReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxPercentageInKpiReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScaleValueInKpiReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScalePercentageInSurveyReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScaleValueInSurveyReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScalePercentageInEmployeeReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScaleCurrentEmployeeValueInEmployeeReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScaleValueInEmployeeReport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScalePercentageOfTurnoverRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGraphScaleSettingsBody
     */
    maxScaleValueOfTurnoverCount?: number | null;
}

export function UpdateGraphScaleSettingsBodyFromJSON(json: any): UpdateGraphScaleSettingsBody {
    return UpdateGraphScaleSettingsBodyFromJSONTyped(json, false);
}

export function UpdateGraphScaleSettingsBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateGraphScaleSettingsBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxMinutesInKpiReport': !exists(json, 'maxMinutesInKpiReport') ? undefined : json['maxMinutesInKpiReport'],
        'maxPercentageInKpiReport': !exists(json, 'maxPercentageInKpiReport') ? undefined : json['maxPercentageInKpiReport'],
        'maxScaleValueInKpiReport': !exists(json, 'maxScaleValueInKpiReport') ? undefined : json['maxScaleValueInKpiReport'],
        'maxScalePercentageInSurveyReport': !exists(json, 'maxScalePercentageInSurveyReport') ? undefined : json['maxScalePercentageInSurveyReport'],
        'maxScaleValueInSurveyReport': !exists(json, 'maxScaleValueInSurveyReport') ? undefined : json['maxScaleValueInSurveyReport'],
        'maxScalePercentageInEmployeeReport': !exists(json, 'maxScalePercentageInEmployeeReport') ? undefined : json['maxScalePercentageInEmployeeReport'],
        'maxScaleCurrentEmployeeValueInEmployeeReport': !exists(json, 'maxScaleCurrentEmployeeValueInEmployeeReport') ? undefined : json['maxScaleCurrentEmployeeValueInEmployeeReport'],
        'maxScaleValueInEmployeeReport': !exists(json, 'maxScaleValueInEmployeeReport') ? undefined : json['maxScaleValueInEmployeeReport'],
        'maxScalePercentageOfTurnoverRate': !exists(json, 'maxScalePercentageOfTurnoverRate') ? undefined : json['maxScalePercentageOfTurnoverRate'],
        'maxScaleValueOfTurnoverCount': !exists(json, 'maxScaleValueOfTurnoverCount') ? undefined : json['maxScaleValueOfTurnoverCount'],
    };
}

export function UpdateGraphScaleSettingsBodyToJSON(value?: UpdateGraphScaleSettingsBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maxMinutesInKpiReport': value.maxMinutesInKpiReport,
        'maxPercentageInKpiReport': value.maxPercentageInKpiReport,
        'maxScaleValueInKpiReport': value.maxScaleValueInKpiReport,
        'maxScalePercentageInSurveyReport': value.maxScalePercentageInSurveyReport,
        'maxScaleValueInSurveyReport': value.maxScaleValueInSurveyReport,
        'maxScalePercentageInEmployeeReport': value.maxScalePercentageInEmployeeReport,
        'maxScaleCurrentEmployeeValueInEmployeeReport': value.maxScaleCurrentEmployeeValueInEmployeeReport,
        'maxScaleValueInEmployeeReport': value.maxScaleValueInEmployeeReport,
        'maxScalePercentageOfTurnoverRate': value.maxScalePercentageOfTurnoverRate,
        'maxScaleValueOfTurnoverCount': value.maxScaleValueOfTurnoverCount,
    };
}


