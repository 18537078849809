/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackQuestionAnswer,
    InterviewFeedbackQuestionAnswerFromJSON,
    InterviewFeedbackQuestionAnswerFromJSONTyped,
    InterviewFeedbackQuestionAnswerToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFeedbackResponse
 */
export interface InterviewFeedbackResponse {
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackResponse
     */
    comment: string;
    /**
     * 
     * @type {Array<InterviewFeedbackQuestionAnswer>}
     * @memberof InterviewFeedbackResponse
     */
    questions: Array<InterviewFeedbackQuestionAnswer>;
    /**
     * 
     * @type {number}
     * @memberof InterviewFeedbackResponse
     */
    qualityScore: number;
    /**
     * 
     * @type {Date}
     * @memberof InterviewFeedbackResponse
     */
    interviewDate: Date;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackResponse
     */
    originalFileName: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackResponse
     */
    interviewRecordingId: string;
}

export function InterviewFeedbackResponseFromJSON(json: any): InterviewFeedbackResponse {
    return InterviewFeedbackResponseFromJSONTyped(json, false);
}

export function InterviewFeedbackResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFeedbackResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
        'questions': ((json['questions'] as Array<any>).map(InterviewFeedbackQuestionAnswerFromJSON)),
        'qualityScore': json['qualityScore'],
        'interviewDate': (new Date(json['interviewDate'])),
        'originalFileName': json['originalFileName'],
        'interviewRecordingId': json['interviewRecordingId'],
    };
}

export function InterviewFeedbackResponseToJSON(value?: InterviewFeedbackResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'questions': ((value.questions as Array<any>).map(InterviewFeedbackQuestionAnswerToJSON)),
        'qualityScore': value.qualityScore,
        'interviewDate': (value.interviewDate.toISOString()),
        'originalFileName': value.originalFileName,
        'interviewRecordingId': value.interviewRecordingId,
    };
}


