import React from "react";

import { QueryState } from "../../../store/hooks/utils/parseResult";
import { AmeCircularProgress } from "../../muiWrapper/AmeCircularProgress";

type Props<T> = {
  queryState: QueryState<T>;
  children: (data: T | undefined) => React.ReactElement | null;
};

export const WaitForFetch = <T,>(props: Props<T>) => {
  if (!props.queryState.isFetching) {
    return props.children(props.queryState.data);
  } else {
    return <AmeCircularProgress />;
  }
};
