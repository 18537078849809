import React, { useCallback, useMemo } from "react";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

type HighlightType = "none" | "reason" | "voice";

type Highlight = {
  offset: number;
  length: number;
  type: HighlightType;
};

interface Props {
  retirementRecordInterview: {
    interviewContent: string;
    highlights: { offset: number; length: number; subCategoryIds: string[] }[];
  };
  selectedRetirementReasonId: string | undefined;
  highlightVoice?: string;
}

export const InterviewContent: React.FC<Props> = ({
  retirementRecordInterview,
  selectedRetirementReasonId,
  highlightVoice,
}) => {
  const theme = useAmeTheme();
  const decodedVoice = useMemo(() => {
    if (highlightVoice === undefined) {
      return undefined;
    }
    return decodeURI(highlightVoice);
  }, [highlightVoice]);

  const voiceHighlight = useMemo((): Highlight | undefined => {
    if (decodedVoice === undefined) {
      return undefined;
    }
    const regExp = new RegExp(decodedVoice);
    const match = regExp.exec(retirementRecordInterview.interviewContent);
    if (match === null) {
      return undefined;
    }
    return {
      offset: match.index,
      length: decodedVoice.length,
      type: "voice",
    };
  }, [decodedVoice, retirementRecordInterview.interviewContent]);

  const targetHighlights = useMemo((): Highlight[] => {
    if (selectedRetirementReasonId === undefined) {
      return [];
    }
    return retirementRecordInterview.highlights
      .filter((highlight) => highlight.subCategoryIds.includes(selectedRetirementReasonId))
      .map((highlight) => ({ offset: highlight.offset, length: highlight.length, type: "reason" }));
  }, [selectedRetirementReasonId, retirementRecordInterview]);

  const interviewContentList = useGetInterviewContentListHighlightOrNot(
    retirementRecordInterview.interviewContent,
    targetHighlights,
    voiceHighlight,
  );

  const scrollToHighlight = useCallback(
    (highlightType: HighlightType) => {
      const scrolledId: string | undefined = interviewContentList.find(
        (interview) => interview.highlightType === highlightType,
      )?.content;
      if (scrolledId) {
        const scrolledPosition = document.getElementById(scrolledId);
        scrolledPosition?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    [interviewContentList],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => scrollToHighlight("voice"), []); // voiceのハイライトへの遷移はマウント時のみ実行
  React.useEffect(() => scrollToHighlight("reason"), [interviewContentList, scrollToHighlight]);
  return (
    <PaperWrapper>
      <PaperHeader>インタビュー内容</PaperHeader>
      <PaperBody>
        <AmeTypography>
          {interviewContentList.map((content) =>
            content.highlightType === "none" ? (
              content.content
            ) : (
              <span id={content.content} key={content.content}>
                <AmeBox
                  key={content.content}
                  component="span"
                  sx={{
                    backgroundColor:
                      content.highlightType === "reason"
                        ? theme.common.background.warning40
                        : theme.brand.secondary[20],
                  }}
                >
                  {content.content}
                </AmeBox>
              </span>
            ),
          )}
        </AmeTypography>
      </PaperBody>
    </PaperWrapper>
  );
};

const useGetInterviewContentListHighlightOrNot = (
  interviewContent: string,
  reasonHighlights: Highlight[],
  voiceHighlight: Highlight | undefined,
) => {
  return useMemo(() => {
    const highlights = concatReasonHighlightsWithVoiceHighlight(reasonHighlights, voiceHighlight);
    const sortedHighlights = highlights.sort((highlight1, highlight2) => highlight1.offset - highlight2.offset);
    const interviewContentList: {
      content: string;
      highlightType: HighlightType;
    }[] = [];
    let currentPosition = 0;
    for (const highlight of sortedHighlights) {
      const preHightlightContent = interviewContent.slice(currentPosition, highlight.offset);
      interviewContentList.push({ content: preHightlightContent, highlightType: "none" });
      const highlightInterviewContent = interviewContent.slice(highlight.offset, highlight.offset + highlight.length);
      interviewContentList.push({ content: highlightInterviewContent, highlightType: highlight.type });
      currentPosition = highlight.offset + highlight.length;
    }
    if (currentPosition < interviewContent.length) {
      interviewContentList.push({ content: interviewContent.slice(currentPosition), highlightType: "none" });
    }
    return interviewContentList;
  }, [interviewContent, reasonHighlights, voiceHighlight]);
};

const NOT_FOUND_INDEX = -1;

const concatReasonHighlightsWithVoiceHighlight = (
  reasonHighlights: Highlight[],
  voiceHighlight: Highlight | undefined,
): Highlight[] => {
  if (voiceHighlight === undefined) {
    return reasonHighlights;
  }
  const indexOfSameOffsetWithVoiceHighlight = reasonHighlights.findIndex(
    (highlight) => highlight.offset === voiceHighlight.offset,
  );
  if (indexOfSameOffsetWithVoiceHighlight === NOT_FOUND_INDEX) {
    return reasonHighlights.concat(voiceHighlight);
  }
  reasonHighlights[indexOfSameOffsetWithVoiceHighlight] = voiceHighlight;
  return reasonHighlights;
};
