import { CalculatedSegmentDefinition } from "../../models/CalculatedSegmentDefinition";
import { InputSegmentDefinitionModel } from "../../models/Setting";
import { CalculatedSegmentKey, InputSegmentKey } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";

export const useGetSegmentDefinition = (segmentKey: InputSegmentKey) => {
  const result = defaultApi.endpoints.getSegmentDefinitions.useQuery({ segmentKey: segmentKey });
  return useQueryResult({
    result,
    fromResponse: (response) => response.map(InputSegmentDefinitionModel.fromResponse),
  });
};

export const useCreateSegmentDefinition = () => {
  const [createSegmentDefinition] = defaultApi.endpoints.createSegmentDefinition.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createSegmentDefinition,
  });
};

export const useUpdateSegmentDefinition = () => {
  const [updateSegmentDefinition] = defaultApi.endpoints.updateSegmentDefinition.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateSegmentDefinition,
  });
};

export const useGetCalculatedSegmentConditionSetting = (segmentKey: CalculatedSegmentKey) => {
  const result = defaultApi.endpoints.getCalculatedSegmentConditionSetting.useQuery({ segmentKey: segmentKey });
  return useQueryResult({
    result,
    fromResponse: CalculatedSegmentDefinition.fromResponse,
  });
};

export const useRegisterCalculatedSegmentCondition = () => {
  const [registerCalculatedSegmentCondition] = defaultApi.endpoints.registerCalculatedSegmentCondition.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: registerCalculatedSegmentCondition,
  });
};
