import React from "react";

import { HrInterviewQualityReport } from "../../../../models/hrInterview/HrInterviewQualityReport";
import { PaperBody } from "../../../atoms/paper/PaperBody";
import { PaperWrapper } from "../../../atoms/paper/PaperWrapper";
import { V2ContentWrapper } from "../../../atoms/wrapper/V2ContentWrapper";

import { ScoreQualityChart } from "./charts/ScoreQualityChart";

interface Props {
  hrInterviewQualityReport: HrInterviewQualityReport;
}

export const QualityScoreReport: React.FC<Props> = ({ hrInterviewQualityReport }) => {
  const { evaluationTypeSeries, evaluationTypeNames, seriesLabels } =
    hrInterviewQualityReport.generateScoreQualitySeriesData();
  return (
    <PaperWrapper>
      <PaperBody>
        <V2ContentWrapper title="面談スコア品質">
          <ScoreQualityChart
            evaluationTypeSeries={evaluationTypeSeries}
            evaluationTypeNames={evaluationTypeNames}
            seriesLabels={seriesLabels}
          />
        </V2ContentWrapper>
      </PaperBody>
    </PaperWrapper>
  );
};
