/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegisterInterviewFormatContentsRequestBodyFormatContents,
    RegisterInterviewFormatContentsRequestBodyFormatContentsFromJSON,
    RegisterInterviewFormatContentsRequestBodyFormatContentsFromJSONTyped,
    RegisterInterviewFormatContentsRequestBodyFormatContentsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegisterInterviewFormatContentsRequestBody
 */
export interface RegisterInterviewFormatContentsRequestBody {
    /**
     * 
     * @type {Array<RegisterInterviewFormatContentsRequestBodyFormatContents>}
     * @memberof RegisterInterviewFormatContentsRequestBody
     */
    formatContents: Array<RegisterInterviewFormatContentsRequestBodyFormatContents>;
}

export function RegisterInterviewFormatContentsRequestBodyFromJSON(json: any): RegisterInterviewFormatContentsRequestBody {
    return RegisterInterviewFormatContentsRequestBodyFromJSONTyped(json, false);
}

export function RegisterInterviewFormatContentsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterInterviewFormatContentsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formatContents': ((json['formatContents'] as Array<any>).map(RegisterInterviewFormatContentsRequestBodyFormatContentsFromJSON)),
    };
}

export function RegisterInterviewFormatContentsRequestBodyToJSON(value?: RegisterInterviewFormatContentsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formatContents': ((value.formatContents as Array<any>).map(RegisterInterviewFormatContentsRequestBodyFormatContentsToJSON)),
    };
}


