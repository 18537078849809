import React from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import type { UserDetail } from "../../../../../../../models/User";
import type { UserGroup } from "../../../../../../../models/UserGroup";
import { useUpdateUserMutation } from "../../../../../../../store/hooks/users";
import { AmeFormButtonsLayout } from "../../../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";

import { UserEditor } from "./UserEditor";
import { UserEditFormType } from "./UserEditor";

type Props = {
  user: UserDetail;
  userGroups: UserGroup[];
  userId: string;
};

export const UserEditForm: React.FC<Props> = (props) => {
  const { register, control, formState, handleSubmit } = useForm<UserEditFormType>({
    mode: "onChange",
    defaultValues: {
      surname: props.user.surname,
      givenName: props.user.givenName,
      isAdmin: props.user.isAdmin == true ? "true" : "false",
      email: props.user.email,
      phoneNumber: props.user.phoneNumber?.replace(/-/g, ""),
      userGroupIds: props.user.userGroups.map((userBelonging) => {
        return { userGroupId: userBelonging.userGroupId };
      }),
    },
  });
  const updateUser = useUpdateUserMutation();
  const navigate = useNavigate();
  const onCancel = () => {
    void navigate({ to: "/hidden-pages/members/users/$userId", params: { userId: props.userId } });
  };
  return (
    <>
      <UserEditor register={register} formState={formState} control={control} userGroups={props.userGroups} />
      <AmeFormButtonsLayout
        onPrimary={handleSubmit(async (data) => {
          await updateUser({
            userId: props.user.userId!,
            updateUserRequestBody: {
              surname: data.surname,
              givenName: data.givenName,
              isAdmin: data.isAdmin == "true" ? true : false,
              email: data.email,
              phoneNumber: data.phoneNumber,
              userGroupIds: data.userGroupIds.map((userGroup) => {
                return userGroup.userGroupId;
              }),
            },
          });
          void navigate({ to: "/hidden-pages/members/users/$userId", params: { userId: props.userId } });
        })}
        onSecondary={onCancel}
      />
    </>
  );
};
