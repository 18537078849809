import React, { StrictMode } from "react";

import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { HrPentestSnackbarProvider } from "./components/organisms/common/HrPentestSnackbar";
import { store } from "./ducks";
import { routeTree } from "./routeTree.gen";
import { customizedMuiTheme } from "./utils/styles/AmeTheme";
import "./index.scss";

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.querySelector("#app")!);
root.render(
  <StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customizedMuiTheme}>
          <HrPentestSnackbarProvider>
            <RouterProvider router={router} />
          </HrPentestSnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </StrictMode>,
);
