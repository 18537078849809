import React, { createContext, useCallback, useState } from "react";

type RuleQuery = { field: string; value: string; not: boolean };

type RuleBuilderContextType = {
  currentRule: string;
  setCurrentRule: (value: string) => void;
  addRule: (props: RuleQuery) => void;
};

export const RuleBuilderContext = createContext<RuleBuilderContextType>({
  currentRule: "",
  setCurrentRule: () => undefined,
  addRule: () => undefined,
});

interface Props {
  children: React.ReactNode;
}

export const RuleBuilderContextProvider: React.FC<Props> = ({ children }) => {
  const [currentRule, setCurrentRule] = useState<string>("");
  const generateQuery = useCallback(({ field, value, not }: RuleQuery): string => {
    let query = "";
    if (not) {
      query = query + "NOT ";
    }
    query = query + `${field}:"${value}"`;
    return query;
  }, []);

  const addRule = useCallback(
    (props: RuleQuery) => {
      setCurrentRule((rule) => {
        if (!rule || !rule.match(/\S/g)) {
          return generateQuery(props);
        }
        return rule + " AND " + generateQuery(props);
      });
    },
    [setCurrentRule, generateQuery],
  );

  return (
    <RuleBuilderContext.Provider value={{ currentRule, setCurrentRule, addRule }}>
      {children}
    </RuleBuilderContext.Provider>
  );
};
