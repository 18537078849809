/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnalysisScore,
    AnalysisScoreFromJSON,
    AnalysisScoreFromJSONTyped,
    AnalysisScoreToJSON,
    HrInterviewReportResponseDocuments,
    HrInterviewReportResponseDocumentsFromJSON,
    HrInterviewReportResponseDocumentsFromJSONTyped,
    HrInterviewReportResponseDocumentsToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewReportResponse
 */
export interface HrInterviewReportResponse {
    /**
     * 
     * @type {number}
     * @memberof HrInterviewReportResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<HrInterviewReportResponseDocuments>}
     * @memberof HrInterviewReportResponse
     */
    documents: Array<HrInterviewReportResponseDocuments>;
    /**
     * 
     * @type {Array<AnalysisScore>}
     * @memberof HrInterviewReportResponse
     */
    analysisScores: Array<AnalysisScore>;
}

export function HrInterviewReportResponseFromJSON(json: any): HrInterviewReportResponse {
    return HrInterviewReportResponseFromJSONTyped(json, false);
}

export function HrInterviewReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'documents': ((json['documents'] as Array<any>).map(HrInterviewReportResponseDocumentsFromJSON)),
        'analysisScores': ((json['analysisScores'] as Array<any>).map(AnalysisScoreFromJSON)),
    };
}

export function HrInterviewReportResponseToJSON(value?: HrInterviewReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'documents': ((value.documents as Array<any>).map(HrInterviewReportResponseDocumentsToJSON)),
        'analysisScores': ((value.analysisScores as Array<any>).map(AnalysisScoreToJSON)),
    };
}


