import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { FeedbackStatus } from "../../models/constants/values";

import { UseChoiceGroupParams } from "./type";

export const useInterviewFBChoiceGroup = ({
  filterParams,
  navigate: navigate,
}: UseChoiceGroupParams<{
  interviewFeedbackStatuses: FeedbackStatus[];
}>): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    const followUpRequiredChoices: { value: FeedbackStatus; label: string }[] = [
      { value: "UNSENT", label: "未送信" },
      { value: "UNANSWERED", label: "未回答" },
      { value: "ANSWERED", label: "回答済" },
    ];
    return {
      groupName: "アンケートステータス",
      uiType: "flat",
      choiceOptions: followUpRequiredChoices.map((choice) => ({
        name: choice.label,
        toggleOption: async () => {
          await navigate({
            search: (prev: { interviewFeedbackStatuses: FeedbackStatus[] }) => {
              const feedbackSet = new Set(prev.interviewFeedbackStatuses);
              if (!feedbackSet.delete(choice.value!)) {
                feedbackSet.add(choice.value!);
              }
              return { ...prev, interviewFeedbackStatuses: Array.from(feedbackSet) };
            },
          });
        },
        isChecked: filterParams.interviewFeedbackStatuses.includes(choice.value),
      })),
    };
  }, [filterParams, navigate]);
};
