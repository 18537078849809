import React, { useCallback } from "react";

import { requireNonNull } from "@amecloud/common-utils/src/utils/Assertions";
import { useNavigate } from "@tanstack/react-router";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";

import { VALID_PASSWORD_REG } from "../../../../config/PasswordPolicy";
import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { AmeFormButtonsLayout } from "../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { PasswordInput } from "../../../molecules/PasswordInput";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { useCognitoUser } from "../../common/Authenticator";

const PASSWORD_FORM_KEY = {
  CURRENT_PASSWORD: "currentPassword",
  NEW_PASSWORD: "newPassword",
  REENTERED_NEW_PASSWORD: "reenteredNewPassword",
} as const;

type PasswordFormKey = (typeof PASSWORD_FORM_KEY)[keyof typeof PASSWORD_FORM_KEY];

type PasswordFormType = {
  [key in PasswordFormKey]: string;
};
const MINIMUM_PASSWORD_LENGTH = 8;
export const PasswordSettingForm: React.FC = () => {
  const navigate = useNavigate();
  const cognitoUser = useCognitoUser();
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm<PasswordFormType>({
    criteriaMode: "all",
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      reenteredNewPassword: "",
    },
  });
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();

  const onSubmit = useCallback(
    async (data: PasswordFormType) => {
      try {
        await Auth.changePassword(requireNonNull(cognitoUser), data.currentPassword, data.newPassword);
        await navigate({ to: "/settings" });
      } catch (err) {
        // TODO: エラーの表示の仕方はあとで考える（デザインもらってからかな？）
        if (err.code === "InvalidPasswordException") {
          enqueueErrorSnackbar({
            title: "パスワードの形式が無効です。",
            message: "パスワードは半角小英字、大英字、数字、記号をそれぞれ少なくとも１つ以上含む必要があります。",
          });
          return;
        }
        if (err.code === "NotAuthorizedException") {
          enqueueErrorSnackbar({ title: "現在のパスワードが違います。" });
          return;
        }
        if (err.code === "LimitExceededException") {
          enqueueErrorSnackbar({
            title: "パスワード変更要求リクエスト数が上限に達しました。",
            message: "少々時間を開けてから、再度お試しください。",
          });
          return;
        }
        enqueueErrorSnackbar();
        // eslint-disable-next-line no-console
        console.error(err);
        return;
      }
      enqueueSuccessSnackbar({ title: "パスワードを変更しました。" });
    },
    [navigate, enqueueErrorSnackbar, enqueueSuccessSnackbar, cognitoUser],
  );

  return (
    <>
      <AmeBox sx={{ paddingBottom: "24px" }}>
        <Controller
          name={PASSWORD_FORM_KEY.CURRENT_PASSWORD}
          control={control}
          rules={{
            required: "現在のパスワードが入力されていません。",
          }}
          render={({ field }) => (
            <PasswordInput label="現在のパスワード" field={field} errors={errors.currentPassword?.types} />
          )}
        />
      </AmeBox>
      <AmeBox sx={{ paddingBottom: "24px" }}>
        <Controller
          name={PASSWORD_FORM_KEY.NEW_PASSWORD}
          control={control}
          rules={{
            required: "新しいパスワードが入力されていません。",
            minLength: {
              value: MINIMUM_PASSWORD_LENGTH,
              message: "新しいパスワードは８文字以上である必要があります。",
            },
            pattern: {
              value: VALID_PASSWORD_REG,
              message:
                "新しいパスワードは大文字英字、小文字英字、数字、記号の4種類をそれぞれ１つ以上使う必要があります。",
            },
          }}
          render={({ field }) => (
            <PasswordInput label={"新しいパスワード"} field={field} errors={errors.newPassword?.types} />
          )}
        />
      </AmeBox>
      <AmeBox sx={{ paddingBottom: "24px" }}>
        <Controller
          name={PASSWORD_FORM_KEY.REENTERED_NEW_PASSWORD}
          control={control}
          rules={{
            required: "再入力用のパスワードが入力されていません。",
            validate: (input) => getValues("newPassword") === input || "パスワードが一致しません。",
          }}
          render={({ field }) => (
            <PasswordInput
              label={"新しいパスワードの再入力"}
              field={field}
              errors={errors.reenteredNewPassword?.types}
            />
          )}
        />
      </AmeBox>
      <AmeFormButtonsLayout onPrimary={handleSubmit(onSubmit)} primaryText="変更" />
    </>
  );
};
