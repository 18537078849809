import React from "react";

import { SurveyQuestion, SurveyQuestionAnswer, SurveyQuestionAnswers } from "./SurveyTemplateBody";
import { SurveyTemplateQuestionItem } from "./SurveyTemplateQuestionItem";

interface Props {
  surveyQuestions: SurveyQuestion[];
  answers: SurveyQuestionAnswers;
  onChange: (answer: SurveyQuestionAnswer) => void;
}

export const SurveyTemplateQuestionList: React.FC<Props> = (props) => {
  return (
    <>
      {props.surveyQuestions.map((question) => {
        const answer = props.answers.get(question.questionId);
        return (
          <SurveyTemplateQuestionItem
            key={question.questionId}
            surveyQuestion={question}
            answer={answer}
            onChange={props.onChange}
          />
        );
      })}
    </>
  );
};
