import React, { useEffect } from "react";

import { DropzoneInputProps, DropzoneRootProps, ErrorCode, FileRejection } from "react-dropzone";

import { useHrPentestSnackbar } from "../../../hooks/useHrPentestSnackbar";
import { AmeButton } from "../../atoms/button/AmeButton";
import { Spacer } from "../../atoms/spacers/Spacer";

interface Props {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  fileRejection?: FileRejection;
  buttonText?: string;
}

export const SpFileDropZone: React.FC<Props> = (props) => {
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  useEffect(() => {
    if (props.fileRejection !== undefined) {
      props.fileRejection.errors.forEach((error) => {
        if (error.code === ErrorCode.FileInvalidType) {
          enqueueErrorSnackbar({
            title: "ファイルの拡張子が不正です。",
            message: "ファイルの拡張子を確認してください。",
          });
        }
        if (error.code === ErrorCode.FileTooLarge) {
          enqueueErrorSnackbar({
            title: "ファイルのサイズが大きすぎます。",
            message: "ファイルのサイズを確認してください。",
          });
        }
      });
    }
  }, [props.fileRejection, enqueueErrorSnackbar]);

  return (
    <div {...props.getRootProps()}>
      <input {...props.getInputProps()} />
      <Spacer height="8px" />
      <AmeButton variant="contained" color={"primary"} fullWidth>
        {props.buttonText ?? "ファイルを選択"}
      </AmeButton>
    </div>
  );
};
