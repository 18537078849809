import React, { useCallback, useMemo } from "react";

import { isEqual } from "lodash";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../../../hooks/useHrPentestSnackbar";
import { HrInterviewQuestion } from "../../../../../../../models/HrInterviewSetting";
import { useRegisterInterviewFormatContents } from "../../../../../../../store/hooks/hr-interview-setting";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { HrInterviewQuestionForm, HrInterviewQuestionsSettingTable } from "./HrInterviewQuestionsSettingTable";

interface Props {
  defaultInterviewQuestions: HrInterviewQuestion[];
  title: string;
  hrInterviewFormatId: string;
}

export const HrInterviewQuestionsSettingContent: React.FC<Props> = (props) => {
  const { register, control, handleSubmit, watch } = useForm<HrInterviewQuestionForm>({
    defaultValues: {
      hrInterviewQuestions: props.defaultInterviewQuestions,
    },
  });
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const registerInterviewFormatContents = useRegisterInterviewFormatContents();

  const watchedValues = watch();

  const isFormChanged = useMemo(() => {
    return !isEqual(watchedValues.hrInterviewQuestions, props.defaultInterviewQuestions);
  }, [watchedValues.hrInterviewQuestions, props.defaultInterviewQuestions]);

  const onSubmit = useCallback(
    async (data: HrInterviewQuestionForm) => {
      try {
        await registerInterviewFormatContents({
          interviewFormatId: props.hrInterviewFormatId,
          registerInterviewFormatContentsRequestBody: {
            formatContents: data.hrInterviewQuestions.map((question, index) => ({
              order: index + 1,
              formatContent: question.hrInterviewQuestionText,
              interviewFormatContentId: question.hrInterviewQuestionId,
            })),
          },
        });
        enqueueSuccessSnackbar({ title: "保存に成功しました。" });
      } catch (error) {
        enqueueErrorSnackbar({ title: "保存に失敗しました。" });
      }
    },
    [registerInterviewFormatContents, enqueueSuccessSnackbar, enqueueErrorSnackbar, props.hrInterviewFormatId],
  );

  const onSubmitError = useCallback(() => {
    enqueueErrorSnackbar({ title: "赤枠のセルに値が入っていません。" });
  }, [enqueueErrorSnackbar]);

  return (
    <AmeBox component="form" onSubmit={handleSubmit(onSubmit, onSubmitError)}>
      <PageLayoutWrapper>
        <PageLayoutHeader
          title={props.title}
          endElement={
            <AmeBox component="span" sx={{ display: "inline-flex", gap: "8px", justifyContent: "space-between" }}>
              <AmeButton type="submit" disabled={!isFormChanged}>
                保存
              </AmeButton>
            </AmeBox>
          }
        />
        <PageLayoutBody type="wide">
          <HrInterviewQuestionsSettingTable register={register} control={control} />
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeBox>
  );
};
