import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetGroupListQuery } from "../../../../../../../../../store/hooks/userGroups";
import { useGetUserQuery } from "../../../../../../../../../store/hooks/users";
import { BreadcrumbPageLayoutHeader } from "../../../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../../../molecules/Loading/WaitForSuccess";
import { UserEditForm } from "../../../../../../../../organisms/settings/members/users/userId/edit/UserEditForm";

export const UserEditPage = () => {
  const userGroupListQueryState = useGetGroupListQuery();
  const { userId } = Route.useParams();
  const userQueryState = useGetUserQuery(userId!);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          {
            title: "設定",
            to: "/settings",
          },
          {
            title: "ユーザー管理",
            to: "/hidden-pages/members/users",
          },
        ]}
        currentTitle="ユーザー詳細"
      />
      <PageLayoutBody>
        <WaitForSuccess queryState={userGroupListQueryState}>
          {({ userGroups }) => (
            <WaitForSuccess queryState={userQueryState}>
              {(user) => <UserEditForm user={user} userGroups={userGroups} userId={userId!} />}
            </WaitForSuccess>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/members/users/$userId/edit/")({
  component: UserEditPage,
});
