import React, { useCallback } from "react";

import { Box } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

type RadioButtonProps = {
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  checked: boolean;
};
export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const theme = useAmeTheme();
  const handleChange = useCallback(() => props.onChange(props.value), [props]);
  return (
    <AmeBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <label htmlFor={props.id}>
        <AmeTypography fontSize="body2">{props.value}</AmeTypography>
      </label>
      <AmeBox
        onClick={handleChange}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          cursor: "pointer",
          ":hover": {
            bgcolor: theme.brand.secondary[20],
          },
        }}
      >
        <Box
          component="input"
          type="radio"
          id={props.id}
          name={props.name}
          value={props.value}
          onChange={handleChange}
          checked={props.checked}
          sx={{
            appearance: "none",
            width: "24px",
            height: "24px",
            border: `1px solid ${theme.brand.secondary[110]}`,
            borderRadius: "50%",
            cursor: "pointer",
            ":hover": {
              bgcolor: theme.brand.secondary[20],
            },
            ":checked": {
              borderColor: theme.brand.secondary[100],
              bgcolor: theme.brand.secondary[100],
            },
          }}
        />
      </AmeBox>
    </AmeBox>
  );
};
