import React, { useState } from "react";

import { Box } from "@mui/material";

// import { ForgetPassword } from "./ForgetPassword";
import { useCognitoUser } from "..";
import { CSS_SP_MEDIA_QUERY } from "../../../../../utils/mediaQuery";

import { ForceChangePassword } from "./ForceChangePassword";
import { ForgetPassword } from "./ForgetPassword";
import { MfaVerification } from "./MfaVerification";
import { SignIn } from "./SignIn";

export type UserStateBeforeSignIn = "sign-in" | "forget-password";

export const LoginManager: React.VFC = () => {
  const cognitoUser = useCognitoUser();
  const [userStateBeforeSignIn, setUserStateBeforeSignIn] = useState<UserStateBeforeSignIn>("sign-in");
  // MFAの認証コードの再発行のために使う。
  // パスワードの再設定後のMFA認証コードの再発行については、最初からやり直す必要があるため対応しない。
  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        [CSS_SP_MEDIA_QUERY]: { width: "100%", padding: "24px" },
      }}
    >
      {cognitoUser?.challengeName === "NEW_PASSWORD_REQUIRED" ? (
        <ForceChangePassword setPassword={setPassword} />
      ) : cognitoUser?.challengeName === "SMS_MFA" ? (
        <MfaVerification email={email} password={password} />
      ) : userStateBeforeSignIn === "sign-in" ? (
        <SignIn setUserStateBeforeSignIn={setUserStateBeforeSignIn} setEmail={setEmail} setPassword={setPassword} />
      ) : (
        <ForgetPassword setUserStateBeforeSignIn={setUserStateBeforeSignIn} />
      )}
    </Box>
  );
};
