import React, { useCallback, useMemo } from "react";

import { Column } from "react-table";

import { RuleModel, useCreateRule } from "../../../../../store/hooks/rules";
import { AmeTableBodyCell, AmeTableRow } from "../../../../atoms/table/AmeTable";

import { CreateRuleCell } from "./CreateRuleCell";
import { DataTable } from "./DataTable";

interface Props {
  data: RuleModel[];
  columns: Column<RuleModel>[];
  subCategoryId?: string;
  categoryId: string;
  categoryType: "category" | "subCategory";
}

export const RuleTable: React.FC<Props> = ({ data, columns, subCategoryId, categoryId, categoryType }) => {
  const createRule = useCreateRule();
  const rules = useMemo(() => (data ? data.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()) : []), [data]);
  const onCreateRule = useCallback(
    async (esQuery: string, setCurrentValue: (value: string) => void) => {
      if (esQuery === "") {
        return;
      }
      const { isSuccess } = await createRule({
        categoryId,
        createRuleRequestBodyString: { esQuery, subCategoryId },
      });
      if (isSuccess) {
        setCurrentValue("");
      }
    },

    [createRule, categoryId, subCategoryId],
  );
  return (
    <DataTable
      data={rules}
      columns={columns}
      addtionalRow={
        <AmeTableRow>
          <AmeTableBodyCell noPadding>
            <CreateRuleCell placeholder="ルールを入力" onCreateRule={onCreateRule} />
          </AmeTableBodyCell>
          {categoryType === "category" ? (
            <React.Fragment>
              <AmeTableBodyCell>-</AmeTableBodyCell>
              <AmeTableBodyCell>-</AmeTableBodyCell>
              <AmeTableBodyCell>-</AmeTableBodyCell>
              <AmeTableBodyCell>-</AmeTableBodyCell>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <AmeTableBodyCell>-</AmeTableBodyCell>
              <AmeTableBodyCell>-</AmeTableBodyCell>
              <AmeTableBodyCell>-</AmeTableBodyCell>
            </React.Fragment>
          )}
        </AmeTableRow>
      }
    />
  );
};
