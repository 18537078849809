import React, { useCallback, useState } from "react";

import { Link } from "@tanstack/react-router";

import { AmeCheckbox } from "../../../../../../atoms/checkbox/AmeCheckbox";
import { AmeFormset } from "../../../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../../../atoms/forms/input/AmeInput";
import { ChoosableList } from "../../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../../atoms/list/ChoosableListItem";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeDialog } from "../../../../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { AmeMenu } from "../../../../../../muiWrapper/AmeMenu";

interface Props {
  editingGroup: EditingGroup;
  editGroupName: (groupId: string, editGroupName: string) => Promise<void>;
  deleteGroup: (groupId: string) => Promise<void>;
  onClose: () => void;
}

export interface EditingGroup {
  element: Element;
  groupId: string;
  groupName: string;
}

export const GroupEditMenu: React.FC<Props> = (props) => {
  const [editType, setEditType] = useState<"editName" | "delete" | undefined>(undefined);
  const onCloseEditDialog = useCallback(() => {
    setEditType(undefined);
  }, [setEditType]);

  const onSubmitGroupNameEdit = useCallback(
    async (groupName: string) => {
      await props.editGroupName(props.editingGroup.groupId, groupName);
      props.onClose();
    },
    [props],
  );
  const onDeleteGroup = useCallback(async () => {
    await props.deleteGroup(props.editingGroup.groupId);
    props.onClose();
  }, [props]);

  return (
    <>
      <AmeMenu anchorEl={props.editingGroup.element} open={true} onClose={props.onClose}>
        <ChoosableList>
          <ChoosableListItem
            onSelect={() => {
              setEditType("editName");
            }}
          >
            グループ名の編集
          </ChoosableListItem>
          <Link to={"/settings/members/groups/$groupId"} params={{ groupId: props.editingGroup.groupId }}>
            <ChoosableListItem>メンバーの編集</ChoosableListItem>
          </Link>
          <ChoosableListItem
            onSelect={() => {
              setEditType("delete");
            }}
          >
            削除
          </ChoosableListItem>
        </ChoosableList>
      </AmeMenu>
      <GroupEditDialog
        currentGroupName={props.editingGroup.groupName}
        onClose={onCloseEditDialog}
        onSubmit={onSubmitGroupNameEdit}
        open={editType === "editName"}
      />
      <GroupDeleteDialog onClose={onCloseEditDialog} onSubmit={onDeleteGroup} open={editType === "delete"} />
    </>
  );
};

interface GroupEditDialogProps {
  currentGroupName: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (groupName: string) => Promise<void>;
}

const GroupEditDialog: React.FC<GroupEditDialogProps> = (props) => {
  const [groupName, onChangeGroupName] = useState(props.currentGroupName);
  return (
    <AmeDialog open={props.open} onClose={props.onClose}>
      <AmeDialogLayout onClose={props.onClose} onSubmit={() => props.onSubmit(groupName)} title={"グループ名を編集"}>
        <AmeFormset label={"グループ名"} required>
          <AmeInput fullWidth value={groupName} onChange={(e) => onChangeGroupName(e.target.value)} />
        </AmeFormset>
      </AmeDialogLayout>
    </AmeDialog>
  );
};

interface GroupDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const GroupDeleteDialog: React.FC<GroupDeleteDialogProps> = (props) => {
  const [confirmed, onChangeConfirmed] = useState(false);
  return (
    <AmeDialog open={props.open} onClose={props.onClose}>
      <AmeDialogLayout
        onClose={props.onClose}
        onSubmit={props.onSubmit}
        title={"本当に削除しますか？"}
        variant={"caution"}
        submitButtonDisabled={!confirmed}
      >
        <AmeBox sx={{ lineHeight: "1.4" }}>
          削除すると、元に戻すことができません。
          <br />
          問題ない場合は確定ボタンを押してください。
        </AmeBox>
        <Spacer height={"24px"} />
        <AmeBox sx={{ display: "flex", alignItems: "center" }}>
          <AmeCheckbox
            size={"small"}
            id={"confirmed"}
            checked={confirmed}
            onChange={(e) => onChangeConfirmed(e.target.checked)}
          />
          <Spacer width={"4px"} />
          <label htmlFor={"confirmed"}>注意事項を確認しました。</label>
        </AmeBox>
      </AmeDialogLayout>
    </AmeDialog>
  );
};
