import React, { useContext } from "react";

import { useSearchInterviewDocumentsByRawRule } from "../../../../../../store/hooks/documents";
import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { RuleBuilderContext } from "../RuleBuilderContext";

export const MatchedDocs: React.FC = () => {
  const theme = useAmeTheme();
  const { currentRule } = useContext(RuleBuilderContext);

  const {
    isSuccess,
    isFetching,
    data: matchedDocs,
  } = useSearchInterviewDocumentsByRawRule({ count: 10, esQuery: currentRule, offset: 0 });

  if (isSuccess && !isFetching && matchedDocs.length) {
    return (
      <AmeBox
        sx={{
          backgroundColor: theme.common.background.gray40,
          width: "100%",
          overflow: "scroll",
          whiteSpace: "nowrap",
          marginBottom: "16px",
        }}
      >
        {matchedDocs.map((doc) => (
          <AmeTypography key={doc.documentId} component="div">
            {doc.content}
          </AmeTypography>
        ))}
      </AmeBox>
    );
  }
  return null;
};
