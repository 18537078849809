/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveySheetStatus,
    SurveySheetStatusFromJSON,
    SurveySheetStatusFromJSONTyped,
    SurveySheetStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface SurveyAnswerResponse
 */
export interface SurveyAnswerResponse {
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswerResponse
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswerResponse
     */
    customerCreatedId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswerResponse
     */
    surveyId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAnswerResponse
     */
    surveySheetId: string;
    /**
     * 
     * @type {SurveySheetStatus}
     * @memberof SurveyAnswerResponse
     */
    status: SurveySheetStatus;
}

export function SurveyAnswerResponseFromJSON(json: any): SurveyAnswerResponse {
    return SurveyAnswerResponseFromJSONTyped(json, false);
}

export function SurveyAnswerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyAnswerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'customerCreatedId': json['customerCreatedId'],
        'surveyId': json['surveyId'],
        'surveySheetId': json['surveySheetId'],
        'status': SurveySheetStatusFromJSON(json['status']),
    };
}

export function SurveyAnswerResponseToJSON(value?: SurveyAnswerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'customerCreatedId': value.customerCreatedId,
        'surveyId': value.surveyId,
        'surveySheetId': value.surveySheetId,
        'status': SurveySheetStatusToJSON(value.status),
    };
}


