import React from "react";

import { Information } from "../../atoms/icon/Information";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { DevelopWrapper } from "../../atoms/wrapper/DevelopWrapper";
import { IconPopover } from "../../molecules/IconPopover";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface InfoExplainProps {
  predate: string; // 前期の計算日
  curdate: string; // 今期の計算日
}

export const InfoExplain: React.FC<InfoExplainProps> = ({ predate, curdate }) => {
  return (
    <DevelopWrapper activationLevels={["local"]}>
      <AmeBox sx={{ marginLeft: "8px" }}>
        <IconPopover icon={<Information />} transformOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <AmeBox sx={{ width: "215px", height: "160px" }}>
            <AmeTypography fontSize="caption" color="basic">
              <AmeBox>
                <AmeBox
                  sx={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#F8F9FA",
                    paddingLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  前期
                </AmeBox>
                <AmeBox
                  sx={{ display: "flex", width: "100%", height: "40px", paddingLeft: "20px", alignItems: "center" }}
                >
                  <AmeBox>計算日</AmeBox>
                  <AmeBox sx={{ paddingLeft: "8px" }}>{predate}時点</AmeBox>
                </AmeBox>
                <AmeBox
                  sx={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#F8F9FA",
                    paddingLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  今期
                </AmeBox>
                <AmeBox
                  sx={{ display: "flex", width: "100%", height: "40px", paddingLeft: "20px", alignItems: "center" }}
                >
                  <AmeBox>計算日</AmeBox>
                  <AmeBox sx={{ paddingLeft: "8px" }}>{curdate}時点</AmeBox>
                </AmeBox>
              </AmeBox>
            </AmeTypography>
          </AmeBox>
        </IconPopover>
      </AmeBox>
    </DevelopWrapper>
  );
};
