import React, { createContext, useState } from "react";

import { RuleRegister } from "../RuleRegister";

export type RuleBuildTarget = { categoryId: string; subCategoryId?: string; displayName: string };
interface Props {
  children: React.ReactNode;
}

type RuleRegisterContextType = {
  setRuleBuildTarget: (params: RuleBuildTarget | undefined) => void;
};

export const RuleRegisterContext = createContext<RuleRegisterContextType>({
  setRuleBuildTarget: (_: RuleBuildTarget) => undefined,
});

export const RuleRegisterProvider: React.FC<Props> = ({ children }) => {
  const [ruleBuildTarget, setRuleBuildTarget] = useState<RuleBuildTarget | undefined>();

  return (
    <RuleRegisterContext.Provider value={{ setRuleBuildTarget }}>
      {children}
      {ruleBuildTarget !== undefined && (
        <RuleRegister
          setRuleBuildTarget={setRuleBuildTarget}
          categoryId={ruleBuildTarget.categoryId}
          subCategoryId={ruleBuildTarget.subCategoryId}
          displayName={ruleBuildTarget.displayName}
        />
      )}
    </RuleRegisterContext.Provider>
  );
};
