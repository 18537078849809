import React, { useCallback } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { useGetGroupQuery, useSetUsersToUserGroupMutation } from "../../../../../../../store/hooks/userGroups";
import { useGetUserListQuery } from "../../../../../../../store/hooks/users";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { MemberTransferList } from "../../../../../../organisms/settings/members/groups/groupId/MemberTransferList";

export const GroupPage: React.FC = () => {
  const { groupId: rawGroupId } = Route.useParams();
  const groupId = rawGroupId!;
  const getGroupQueryState = useGetGroupQuery(groupId);

  const navigate = useNavigate();
  const transitToGroupSetting = useCallback(async () => {
    await navigate({ to: "/settings/members/groups", search: { page: 1 } });
  }, [navigate]);

  const setUsersToUserGroupRaw = useSetUsersToUserGroupMutation();
  const updateGroupMembers = useCallback(
    async (userIds: string[]) => {
      await setUsersToUserGroupRaw({ userGroupId: groupId, userGroupMembersRequest: { userIds } });
      await transitToGroupSetting();
    },
    [setUsersToUserGroupRaw, groupId, transitToGroupSetting],
  );

  const userListMutation = useGetUserListQuery();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          {
            title: "設定",
            to: "/settings",
          },
          {
            title: "グループ管理",
            to: "/settings/members/groups",
          },
        ]}
        currentTitle="メンバー編集"
      />
      <PageLayoutBody type="wide">
        <WaitForSuccess queryState={userListMutation}>
          {({ users }) => (
            <WaitForSuccess queryState={getGroupQueryState}>
              {(group) => (
                <>
                  <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <AmeTypography component={"h4"}>「{group.displayName}」のメンバー編集</AmeTypography>
                  </AmeBox>
                  <Spacer height={"24px"} />
                  <MemberTransferList
                    groupName={group.displayName}
                    users={users}
                    selectedUserIds={group.users.map((user) => user.userId!)}
                    transitToGroupSetting={transitToGroupSetting}
                    updateGroupMembers={updateGroupMembers}
                  />
                </>
              )}
            </WaitForSuccess>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/members/groups/$groupId")({
  component: GroupPage,
});
