import { SurveyQuestionResponse, SurveyStatus, SurveyResponse } from "../store/autogenApi";

import { SurveySendingMethod } from "./constants/values";

export class SurveyModel {
  constructor(
    readonly surveyId: string,
    readonly useQrcode: boolean,
    readonly sendEmail: boolean,
    readonly displayName: string,
    readonly surveyTitle: string,
    readonly surveyDescription: string,
    readonly targetEmployeeCount: number,
    readonly collectedCount: number,
    readonly emailSheetsCount: number,
    readonly unansweredEmailSheetsCount: number,
    readonly dueDateTime: Date,
    readonly status: SurveyStatus,
    readonly questions: SurveyQuestionResponse[],
    readonly startedAt: Date | undefined,
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly emailSubject: string | undefined,
    readonly emailBody: string | undefined,
  ) {}

  public static fromResponse(response: SurveyResponse): SurveyModel {
    return new SurveyModel(
      response.surveyId,
      response.useQrcode,
      response.sendEmail,
      response.displayName,
      response.surveyTitle,
      response.surveyDescription,
      response.targetEmployeeCount,
      response.collectedCount,
      response.emailSheetsCount,
      response.unansweredEmailSheetsCount,
      new Date(response.dueDateTime),
      response.status,
      response.questions,
      response.startedAt !== undefined ? new Date(response.startedAt) : undefined,
      new Date(response.createdAt),
      new Date(response.updatedAt),
      response.emailSubject,
      response.emailBody,
    );
  }

  public static getSurveySendingMethod(useQrCode: boolean, sendEmail: boolean): SurveySendingMethod {
    if (useQrCode && sendEmail) {
      return "both";
    }
    if (useQrCode) {
      return "qr-code";
    }
    if (sendEmail) {
      return "email";
    }
    throw new Error("不明な送付方法です。");
  }
}
