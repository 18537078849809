import React, { useCallback, useContext, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetInterviewDetail, useSummarizeInterview, useUnfixInterview } from "../../../../../store/hooks/interviews";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { HorizontalStackedBarChart } from "../../../../atoms/charts/HorizontalStackedBarChart";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { V2ContentWrapper } from "../../../../atoms/wrapper/V2ContentWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { EmployeeAttributes } from "../../../../organisms/v2/interviews/interviewId/EmployeeAttributes";
import { InterviewContent } from "../../../../organisms/v2/interviews/interviewId/InterviewContent";
import { InterviewFileMetadata } from "../../../../organisms/v2/interviews/interviewId/InterviewFileMetadata";
import { RetirementReasons } from "../../../../organisms/v2/interviews/interviewId/RetirementReasons";
import { Summary } from "../../../../organisms/v2/interviews/interviewId/Summary";

export const ExEmployeeInterviewPage: React.FC = () => {
  const { interviewId } = Route.useParams();
  const { voice } = Route.useSearch();
  const { subCategories, setting } = useContext(DataSourceContext);
  const interviewDetailQueryState = useGetInterviewDetail(interviewId);
  const summarizeInterviewRaw = useSummarizeInterview();
  const summarizeInterview = useCallback(async () => {
    await summarizeInterviewRaw({ exEmployeeInterviewId: interviewId });
  }, [summarizeInterviewRaw, interviewId]);
  const [selectedRetirementReasonId, setSelectedRetirementReasonId] = useState<string | undefined>(undefined);

  const onChangeSelectedRetirementReason = useCallback(
    (retirementReasonId: string) => {
      if (selectedRetirementReasonId !== retirementReasonId) {
        setSelectedRetirementReasonId(retirementReasonId);
        return;
      }
      setSelectedRetirementReasonId(undefined);
    },
    [setSelectedRetirementReasonId, selectedRetirementReasonId],
  );
  const selectedSubCategory = selectedRetirementReasonId
    ? subCategories.findBySubCategoryId(selectedRetirementReasonId)
    : undefined;

  const unfixInterview = useUnfixInterview();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "音声データ一覧", to: "/interview-files" }]}
        currentTitle={"インタビュー詳細"}
      />
      <PageLayoutBody type={"wide"}>
        <div style={{ display: "none" }}>
          <AmeButton onClick={() => unfixInterview({ exEmployeeInterviewId: interviewId })}>確定戻し</AmeButton>
        </div>
        <WaitForSuccess queryState={interviewDetailQueryState}>
          {(interviewDetail) => (
            <V2ContentWrapper title="インタビュー詳細">
              <AmeBox sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
                <AmeBox sx={{ display: "flex", gap: "24px" }}>
                  <AmeBox sx={{ maxWidth: "50%", flexGrow: 1, flexBasis: 0 }}>
                    <EmployeeAttributes attribute={interviewDetail.retiredEmployee} setting={setting} />
                  </AmeBox>
                  <AmeBox sx={{ maxWidth: "50%", flexGrow: 1, flexBasis: 0, position: "relative" }}>
                    <AmeBox sx={{ position: "absolute", height: "100%", width: "100%" }}>
                      <Summary
                        summary={interviewDetail.summary?.summaryContent}
                        summarizeInterview={summarizeInterview}
                      />
                    </AmeBox>
                  </AmeBox>
                </AmeBox>
                <AmeBox>
                  <PaperWrapper>
                    <PaperHeader>AI解析結果</PaperHeader>
                    <PaperBody>
                      <HorizontalStackedBarChart
                        series={[
                          {
                            name: "",
                            data: interviewDetail.retirementReasons.values.map((value) => ({
                              value: value.score,
                              name:
                                subCategories.subCategories.find((subCategory) => {
                                  return subCategory.subCategoryId === value.subCategoryId;
                                })?.displayName ?? "",
                            })),
                          },
                        ]}
                        sortByfirstSeries
                        highlightColumnNames={selectedSubCategory ? [selectedSubCategory.displayName] : undefined}
                      />
                    </PaperBody>
                  </PaperWrapper>
                </AmeBox>
                <AmeBox>
                  <RetirementReasons
                    subCategories={subCategories.subCategories}
                    retirementReasons={interviewDetail.retirementReasons.values}
                    selectedRetirementReasonId={selectedRetirementReasonId}
                    onChangeSelectedRetirementReason={onChangeSelectedRetirementReason}
                  />
                </AmeBox>
                <AmeBox>
                  <WaitForSuccess queryState={interviewDetailQueryState}>
                    {(interviewDetail) => (
                      <InterviewContent
                        highlightVoice={voice}
                        retirementRecordInterview={interviewDetail.interview!}
                        selectedRetirementReasonId={selectedRetirementReasonId}
                      />
                    )}
                  </WaitForSuccess>
                </AmeBox>
                {interviewDetail.interviewFile ? (
                  <AmeBox>
                    <InterviewFileMetadata interviewFileMetadata={interviewDetail.interviewFile} />
                  </AmeBox>
                ) : null}
              </AmeBox>
            </V2ContentWrapper>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type QueryParams = {
  voice?: string;
};
export const Route = createFileRoute("/_authenticated/_authorized-for-all/interviews/$interviewId")({
  component: ExEmployeeInterviewPage,
  validateSearch: (search: Partial<QueryParams>): QueryParams => search,
});
