import React, { useMemo } from "react";

import { Column } from "react-table";

import { useParallelController } from "../../../../../hooks/useParallelController";
import { ComputationTaskStatus } from "../../../../../store/hooks/computationTasks";
import { RuleModel, filterRulesBySubCategoryId, useGetRulesQueryByCategoryId } from "../../../../../store/hooks/rules";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { TaskStatusIcon } from "../../../../molecules/TaskStatusIcon";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { RuleStatsCell } from "../RuleStatsCell";
import { RuleDeleteButton } from "../RuleTable/RuleDeleteButton";
import { RuleTable } from "../RuleTable/RuleTable";

interface Props {
  subCategoryId: string;
  categoryId: string;
}

export const SubCategoryRuleTable: React.FC<Props> = ({ subCategoryId, categoryId }) => {
  const { isSuccess, data } = useGetRulesQueryByCategoryId(categoryId, { pollingInterval: 1000 * 15 });

  return isSuccess ? (
    <MemoizedRuleTableWrapper
      data={filterRulesBySubCategoryId(data, subCategoryId)}
      subCategoryId={subCategoryId}
      categoryId={categoryId}
    />
  ) : null;
};

const MemoizedRuleTableWrapper = React.memo<{ data: RuleModel[]; subCategoryId: string; categoryId: string }>(
  function RuleTableWrapper(props) {
    const fetchController = useParallelController();
    const columns = useMemo(() => generateColumns(fetchController), [fetchController]);
    return (
      <RuleTable
        data={props.data}
        columns={columns}
        subCategoryId={props.subCategoryId}
        categoryId={props.categoryId}
        categoryType="subCategory"
      />
    );
  },
);

const generateColumns = (fetchController: (task: () => Promise<void>) => Promise<void>): Column<RuleModel>[] => {
  return [
    {
      Header: "ルール",
      accessor: "esQuery",
      width: "unset",
      Cell: (data) => {
        const esQuery = data.row.original.esQuery;
        const computationTaskStatus = data.row.original.computationTaskStatus;
        return (
          <AmeBox sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <AmeTypography omit>{esQuery}</AmeTypography>
            <TaskStatusIcon
              taskStatus={computationTaskStatus}
              DefaultIcon={(props) => (
                <TaskStatusIcon size={props.size} taskStatus={ComputationTaskStatus.SUCCESSFUL} />
              )}
            />
          </AmeBox>
        );
      },
    },
    {
      Header: "Precision",
      id: "precision",
      accessor: "ruleId",
      disableSortBy: true,
      width: 93,
      Cell: (data) => {
        return data.row.original.computationTaskStatus === ComputationTaskStatus.SUCCESSFUL ? (
          <RuleStatsCell fetchController={fetchController} data={data}>
            {(stats) => <>{stats.precision.toFixed(2)}</>}
          </RuleStatsCell>
        ) : null;
      },
    },
    {
      Header: "ヒット件数",
      id: "hitCount",
      accessor: "ruleId",
      disableSortBy: true,
      width: 85,
      Cell: (data) => {
        return data.row.original.computationTaskStatus === ComputationTaskStatus.SUCCESSFUL ? (
          <RuleStatsCell fetchController={fetchController} data={data}>
            {(stats) => <>{`${stats.hitCount}件`}</>}
          </RuleStatsCell>
        ) : null;
      },
    },
    {
      accessor: "ruleId",
      width: 56,
      Cell: (data) => <RuleDeleteButton ruleId={data.row.original.ruleId} />,
    },
  ];
};
