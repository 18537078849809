import React from "react";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { DefinitionList } from "../../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";

interface Props {
  interviewDetail: HrInterviewDetail;
}

export const InterviewFollowUp: React.FC<Props> = ({ interviewDetail }) => {
  return (
    <PaperWrapper>
      <PaperHeader
        endElement={
          <AmeButton
            variant="outlined"
            color="basic"
            size="small"
            to={"/hr-interviews/interviews/$interviewId/followup"}
            params={{ interviewId: interviewDetail.hrInterviewId }}
          >
            編集
          </AmeButton>
        }
      >
        フォローアップ要否
      </PaperHeader>
      <PaperBody>
        <DefinitionList>
          <DefinitionListItem label="フォローアップ要否" value={interviewDetail.followUpStatus} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="内容" value={interviewDetail.followUpMemo} />
        </DefinitionList>
      </PaperBody>
    </PaperWrapper>
  );
};
