import React, { useCallback, useState, KeyboardEvent, ChangeEvent } from "react";

import { AmeInput } from "../../../../atoms/forms/input/AmeInput";

interface Props {
  placeholder: string;
  onCreateRule: (esQuery: string, setCurrentValue: (value: string) => void) => void;
}

export const CreateRuleCell: React.FC<Props> = ({ placeholder, onCreateRule }) => {
  const [query, setQuery] = useState<string>("");

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  }, []);

  const onBlur = useCallback(() => {
    onCreateRule(query, setQuery);
  }, [onCreateRule, query, setQuery]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    },
    [setQuery],
  );

  return (
    <AmeInput
      placeholder={placeholder}
      value={query}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      variant="auto-complete"
      fullWidth
    />
  );
};
