/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Construct a type with a set of properties K of type T
 * @export
 * @interface RecordSegmentKeyString
 */
export interface RecordSegmentKeyString {
    /**
     * 
     * @type {string}
     * @memberof RecordSegmentKeyString
     */
    sEX?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSegmentKeyString
     */
    dEPARTMENT?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSegmentKeyString
     */
    fREE1?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSegmentKeyString
     */
    fREE2?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSegmentKeyString
     */
    mONTHSOFSERVICE?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSegmentKeyString
     */
    aGE?: string;
}

export function RecordSegmentKeyStringFromJSON(json: any): RecordSegmentKeyString {
    return RecordSegmentKeyStringFromJSONTyped(json, false);
}

export function RecordSegmentKeyStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSegmentKeyString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sEX': !exists(json, 'SEX') ? undefined : json['SEX'],
        'dEPARTMENT': !exists(json, 'DEPARTMENT') ? undefined : json['DEPARTMENT'],
        'fREE1': !exists(json, 'FREE1') ? undefined : json['FREE1'],
        'fREE2': !exists(json, 'FREE2') ? undefined : json['FREE2'],
        'mONTHSOFSERVICE': !exists(json, 'MONTHS_OF_SERVICE') ? undefined : json['MONTHS_OF_SERVICE'],
        'aGE': !exists(json, 'AGE') ? undefined : json['AGE'],
    };
}

export function RecordSegmentKeyStringToJSON(value?: RecordSegmentKeyString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SEX': value.sEX,
        'DEPARTMENT': value.dEPARTMENT,
        'FREE1': value.fREE1,
        'FREE2': value.fREE2,
        'MONTHS_OF_SERVICE': value.mONTHSOFSERVICE,
        'AGE': value.aGE,
    };
}


