import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";

import { getAuthorization } from "../utils/api/ApiClientFactory";
const API_URL = "//" + (window.location.hostname === "localhost" ? "localhost:3333" : "api." + window.location.host);

const PUBLIC_ENDPOINTS = [
  "getSurveySheet",
  "submitSurveyAnswer",
  "getSurveySheetBySurveyId",
  "submitSurveyAnswerWithId",
  "customerCreatedIdExists",
  "getSharedSurveySheet",
  "createHrInterviewFeedbackAnswers",
  "getHrInterviewFeedbackQuestionList",
];

// 自動生成のAPIクライアント？が読み込むやつ。
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    paramsSerializer(params) {
      return qs.stringify(params);
    },
    prepareHeaders: async (headers, { endpoint }) => {
      // publicなエンドポイントに対してはヘッダーは書き換えずに返す。
      if (PUBLIC_ENDPOINTS.includes(endpoint)) {
        return headers;
      }

      const authorization = await getAuthorization();
      if (authorization) {
        headers.append("authorization", authorization);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
