import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  HrPentestSnackbarMessage,
  HrPentestSnackbarMessageBody,
} from "../components/organisms/common/HrPentestSnackbar";

type SnackbarState = {
  message: HrPentestSnackbarMessage | null;
  version: number;
};

const initialState: SnackbarState = {
  message: null,
  version: 0,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: initialState,
  reducers: {
    enqueueSuccessSnackbar: (state, action: PayloadAction<HrPentestSnackbarMessageBody>) => {
      state.version++;
      state.message = {
        type: "success",
        ...action.payload,
      };
    },
    enqueueErrorSnackbar: (state, action: PayloadAction<HrPentestSnackbarMessageBody>) => {
      state.version++;
      state.message = {
        type: "error",
        ...action.payload,
      };
    },
  },
});

export const snackbarActions = snackbarSlice.actions;
