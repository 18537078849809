/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFormatContentResponse,
    InterviewFormatContentResponseFromJSON,
    InterviewFormatContentResponseFromJSONTyped,
    InterviewFormatContentResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFormatResponse
 */
export interface InterviewFormatResponse {
    /**
     * 
     * @type {Array<InterviewFormatContentResponse>}
     * @memberof InterviewFormatResponse
     */
    contents: Array<InterviewFormatContentResponse>;
    /**
     * 
     * @type {string}
     * @memberof InterviewFormatResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewFormatResponse
     */
    interviewFormatId: string;
}

export function InterviewFormatResponseFromJSON(json: any): InterviewFormatResponse {
    return InterviewFormatResponseFromJSONTyped(json, false);
}

export function InterviewFormatResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFormatResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contents': ((json['contents'] as Array<any>).map(InterviewFormatContentResponseFromJSON)),
        'displayName': json['displayName'],
        'interviewFormatId': json['interviewFormatId'],
    };
}

export function InterviewFormatResponseToJSON(value?: InterviewFormatResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contents': ((value.contents as Array<any>).map(InterviewFormatContentResponseToJSON)),
        'displayName': value.displayName,
        'interviewFormatId': value.interviewFormatId,
    };
}


