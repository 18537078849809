import React, { useCallback, useMemo } from "react";

import { isEqual } from "lodash";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../../hooks/useHrPentestSnackbar";
import { HrInterviewFormat } from "../../../../../../models/HrInterviewSetting";
import { useRegisterInterviewFormats } from "../../../../../../store/hooks/hr-interview-setting";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { HrInterviewFormatsSettingTable, InterviewFormatsForm } from "./HrInterviewFormatsSettingTable";

interface Props {
  defaultInterviewFormats: HrInterviewFormat[];
  title: string;
  hrInterviewTypeId: string;
}

export const HrInterviewFormatsSettingContent: React.FC<Props> = (props) => {
  const { register, control, handleSubmit, watch } = useForm<InterviewFormatsForm>({
    defaultValues: {
      hrInterviewFormats: props.defaultInterviewFormats,
    },
  });
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const registerInterviewFormat = useRegisterInterviewFormats();

  const watchedValues = watch();

  const isFormChanged = useMemo(() => {
    return !isEqual(watchedValues.hrInterviewFormats, props.defaultInterviewFormats);
  }, [watchedValues.hrInterviewFormats, props.defaultInterviewFormats]);

  const onSubmit = useCallback(
    async (data: InterviewFormatsForm) => {
      try {
        await registerInterviewFormat({
          interviewTypeId: props.hrInterviewTypeId,
          registerInterviewFormatsRequestBody: {
            formats: data.hrInterviewFormats.map((format, index) => ({
              order: index + 1,
              displayName: format.hrInterviewFormatText,
              interviewFormatId: format.hrInterviewFormatId,
            })),
          },
        });

        enqueueSuccessSnackbar({ title: "保存に成功しました。" });
      } catch (error) {
        enqueueErrorSnackbar({ title: "保存に失敗しました。" });
      }
    },
    [registerInterviewFormat, enqueueSuccessSnackbar, enqueueErrorSnackbar, props.hrInterviewTypeId],
  );

  const onSubmitError = useCallback(() => {
    enqueueErrorSnackbar({ title: "赤枠のセルに値が入っていません。" });
  }, [enqueueErrorSnackbar]);

  return (
    <AmeBox component="form" onSubmit={handleSubmit(onSubmit, onSubmitError)}>
      <PageLayoutWrapper>
        <PageLayoutHeader
          title={props.title}
          endElement={
            <AmeBox component="span" sx={{ display: "inline-flex", gap: "8px", justifyContent: "space-between" }}>
              <AmeButton type="submit" disabled={!isFormChanged}>
                保存
              </AmeButton>
            </AmeBox>
          }
        />
        <PageLayoutBody type="wide">
          <HrInterviewFormatsSettingTable
            register={register}
            control={control}
            hrInterviewTypeId={props.hrInterviewTypeId}
          />
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeBox>
  );
};
