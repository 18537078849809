/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LabeledDocumentResponse,
    LabeledDocumentResponseFromJSON,
    LabeledDocumentResponseFromJSONTyped,
    LabeledDocumentResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface LabeledDocumentListResponse
 */
export interface LabeledDocumentListResponse {
    /**
     * 
     * @type {Array<LabeledDocumentResponse>}
     * @memberof LabeledDocumentListResponse
     */
    documents: Array<LabeledDocumentResponse>;
    /**
     * 
     * @type {number}
     * @memberof LabeledDocumentListResponse
     */
    totalCount: number;
}

export function LabeledDocumentListResponseFromJSON(json: any): LabeledDocumentListResponse {
    return LabeledDocumentListResponseFromJSONTyped(json, false);
}

export function LabeledDocumentListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabeledDocumentListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(LabeledDocumentResponseFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function LabeledDocumentListResponseToJSON(value?: LabeledDocumentListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documents': ((value.documents as Array<any>).map(LabeledDocumentResponseToJSON)),
        'totalCount': value.totalCount,
    };
}


