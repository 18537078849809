import React from "react";

import { UseFormWatch, Control } from "react-hook-form";

import { InterviewFeedbackQuestion } from "../../../../../store/hooks/interviewer";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { AmeTable, AmeTableBody, AmeTableHead, AmeTableHeadCell, AmeTableRow } from "../../../../atoms/table/AmeTable";

import { InterviewerFeedbackTableRow } from "./InterviewerFeedbackTableRow";

export type InterviewerFormType = {
  interviewContent: string;
  qualities: { interviewFeedbackAnswerId: string; interviewFeedbackQuestionId: string }[];
  comment: string;
};

interface Props {
  watch: UseFormWatch<InterviewerFormType>;
  control: Control<InterviewerFormType>;
  interviewFeedbackQuestions: InterviewFeedbackQuestion[];
}

export const InterviewerFeedbackTable: React.FC<Props> = ({ watch, control, interviewFeedbackQuestions }) => {
  return (
    <PaperWrapper>
      <AmeTable useCustomCellWidth>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell>設問</AmeTableHeadCell>
            <AmeTableHeadCell>品質</AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {interviewFeedbackQuestions.map((question, i) => (
            <InterviewerFeedbackTableRow
              key={question.interviewFeedbackQuestionId}
              question={question}
              index={i}
              currentValue={watch(`qualities.${i}.interviewFeedbackAnswerId`)}
              control={control}
            />
          ))}
        </AmeTableBody>
      </AmeTable>
    </PaperWrapper>
  );
};
