import React, { useCallback } from "react";

import { AmeFormset } from "../../../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../../../atoms/forms/input/AmeInput";
import { AmeDialog } from "../../../../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../../../../molecules/dialog/AmeDialogLayout";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (groupName: string) => Promise<void>;
}

export const GroupCreationDialog: React.FC<Props> = ({ open, onSubmit: onSubmitRaw, onClose }) => {
  const [groupName, setGroupName] = React.useState("");
  const onSubmit = useCallback(async () => {
    await onSubmitRaw(groupName);
    onClose();
  }, [groupName, onClose, onSubmitRaw]);
  return (
    <AmeDialog open={open} onClose={onClose}>
      <AmeDialogLayout
        onClose={onClose}
        onSubmit={onSubmit}
        title={"グループを新規登録"}
        submitButtonDisabled={groupName === ""}
      >
        <AmeFormset label={"グループ名"} required>
          <AmeInput
            fullWidth
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
          />
        </AmeFormset>
      </AmeDialogLayout>
    </AmeDialog>
  );
};
