import React, { useContext } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../../../organisms/common/DataSourceContext";
import { GraphScaleForm } from "../../../../../../organisms/settings/graphs/scales/GraphScaleForm";

const SettingsGraphsScalesDetail: React.FC = () => {
  const {
    setting: { globalSetting },
  } = useContext(DataSourceContext);
  const navigate = Route.useNavigate();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        currentTitle={`グラフ目盛り設定詳細`}
        items={[
          { title: "設定", to: "/settings" },
          { title: "グラフ目盛り設定", to: "/settings/graphs/scales" },
        ]}
      />
      <PageLayoutBody>
        <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <AmeTypography component="h6">グラフ目盛り設定詳細</AmeTypography>
        </AmeBox>
        <Spacer height="24px" />
        <GraphScaleForm
          globalSetting={globalSetting}
          navigateToList={async () => {
            await navigate({ to: "/settings/graphs/scales" });
          }}
        />
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/graphs/scales/detail")({
  component: SettingsGraphsScalesDetail,
});
