import React, { useCallback, useMemo, useState } from "react";

import { ComputationTaskStatus } from "../../../../../store/hooks/computationTasks";
import { useGetRulesQueryByCategoryId } from "../../../../../store/hooks/rules";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { EditableCategoryCell } from "../common/EditableCategoryCell";

import { CategoryMenu } from "./CategoryMenu";
import { CategoryRuleTable } from "./CategoryRuleTable";

interface Props {
  categoryId: string;
  displayName: string;
  computationTaskStatus: ComputationTaskStatus | null;
  defaultSubCategoryId?: string;
  updateCategory: (args: { categoryId: string; displayName: string }) => void;
  deleteCategory: (args: { categoryId: string }) => void;
}

export const CategoryInfo: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: rules } = useGetRulesQueryByCategoryId(props.categoryId);

  const onDelete = useCallback(() => {
    props.deleteCategory({ categoryId: props.categoryId });
  }, [props]);

  const deletable = useMemo(() => {
    return rules?.length === 0;
  }, [rules]);

  return (
    <AmeBox sx={{ overflow: "hidden", marginBottom: "8px" }}>
      <AmeBox sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <AmeBox sx={{ flexGrow: 1, flexShrink: 1, overlow: "hidden" }}>
          <EditableCategoryCell
            id={props.categoryId}
            displayName={props.displayName}
            update={({ id, displayName }) => props.updateCategory({ categoryId: id, displayName })}
            computationTaskStatus={props.computationTaskStatus}
          />
        </AmeBox>
        <AmeBox sx={{ flexShrink: 0 }}>
          <CategoryMenu
            categoryId={props.categoryId}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            displayName={props.displayName}
            onDelete={onDelete}
            deletable={deletable}
          />
        </AmeBox>
      </AmeBox>
      {isOpen ? (
        <AmeBox sx={{ margin: "8px 0" }}>
          <CategoryRuleTable categoryId={props.categoryId} defaultSubCategoryId={props.defaultSubCategoryId} />
        </AmeBox>
      ) : null}
    </AmeBox>
  );
};
