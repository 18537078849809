import React from "react";

import { SvgIconProps, SvgWrapper } from "./index";

export const Reload: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper viewBox={"0 0 16 17"} {...props}>
      <g id="Icon">
        <path
          id="Vector"
          d="M7.99967 3.16927C6.1673 3.16927 4.54973 4.09344 3.58929 5.5026H5.33301V6.83594H1.33301V2.83594H2.66634V4.50218C3.88221 2.88371 5.8181 1.83594 7.99967 1.83594C11.6815 1.83594 14.6663 4.8207 14.6663 8.5026H13.333C13.333 5.55708 10.9452 3.16927 7.99967 3.16927ZM2.66634 8.5026C2.66634 11.4481 5.05415 13.8359 7.99967 13.8359C9.83207 13.8359 11.4496 12.9117 12.4101 11.5026H10.6663V10.1693H14.6663V14.1693H13.333V12.503C12.1171 14.1215 10.1813 15.1693 7.99967 15.1693C4.31777 15.1693 1.33301 12.1845 1.33301 8.5026H2.66634Z"
          fill="currentColor"
        />
      </g>
    </SvgWrapper>
  );
};
