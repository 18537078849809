import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { HrInterviewType } from "../../models/HrInterviewSetting";

import { UseChoiceGroupParams } from "./type";

export const useInterviewTypeChoiceGroup = (
  interviewTypes: HrInterviewType[],
  { filterParams, navigate }: UseChoiceGroupParams<{ interviewTypeIds: string[] }>,
): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    return {
      groupName: "面談種類",
      uiType: "flat",
      choiceOptions: interviewTypes.map((interviewType) => ({
        name: interviewType.hrInterviewTypeText,
        toggleOption: async () =>
          await navigate({
            search: (prev: { interviewTypeIds: string[] }) => {
              const interviewTypeset = new Set(prev.interviewTypeIds);
              if (!interviewTypeset.delete(interviewType.hrInterviewTypeId!)) {
                interviewTypeset.add(interviewType.hrInterviewTypeId!);
              }
              return { ...prev, interviewTypeIds: Array.from(interviewTypeset) };
            },
          }),
        isChecked: filterParams.interviewTypeIds.includes(interviewType.hrInterviewTypeId),
      })),
    };
  }, [filterParams, interviewTypes, navigate]);
};
