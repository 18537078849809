/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeAttributeResponse,
    EmployeeAttributeResponseFromJSON,
    EmployeeAttributeResponseFromJSONTyped,
    EmployeeAttributeResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeResponse
 */
export interface EmployeeResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    employeeTenureId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    customerCreatedId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    at: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    hireDate: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeResponse
     */
    retireDate?: string;
    /**
     * 
     * @type {Array<EmployeeAttributeResponse>}
     * @memberof EmployeeResponse
     */
    attributes: Array<EmployeeAttributeResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeResponse
     */
    hasInterviewAssign: boolean;
}

export function EmployeeResponseFromJSON(json: any): EmployeeResponse {
    return EmployeeResponseFromJSONTyped(json, false);
}

export function EmployeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'employeeTenureId': json['employeeTenureId'],
        'customerCreatedId': json['customerCreatedId'],
        'at': json['at'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'hireDate': json['hireDate'],
        'retireDate': !exists(json, 'retireDate') ? undefined : json['retireDate'],
        'attributes': ((json['attributes'] as Array<any>).map(EmployeeAttributeResponseFromJSON)),
        'hasInterviewAssign': json['hasInterviewAssign'],
    };
}

export function EmployeeResponseToJSON(value?: EmployeeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'employeeTenureId': value.employeeTenureId,
        'customerCreatedId': value.customerCreatedId,
        'at': value.at,
        'name': value.name,
        'email': value.email,
        'dateOfBirth': value.dateOfBirth,
        'hireDate': value.hireDate,
        'retireDate': value.retireDate,
        'attributes': ((value.attributes as Array<any>).map(EmployeeAttributeResponseToJSON)),
        'hasInterviewAssign': value.hasInterviewAssign,
    };
}


