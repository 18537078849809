import React, { useCallback, useContext } from "react";

import { ArrowForwardIos } from "@mui/icons-material";
import { Auth } from "aws-amplify";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { CurrentCognitoUserContext } from "../../common/Authenticator";

export const LogoutButton: React.FC = () => {
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const { setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);

  const onClick = useCallback(async () => {
    try {
      await Auth.signOut();
      setCurrentCognitoUser(undefined);
    } catch (e) {
      enqueueErrorSnackbar();
    }
  }, [setCurrentCognitoUser, enqueueErrorSnackbar]);

  return (
    <AmeButton
      onClick={onClick}
      variant="outlined"
      endIcon={
        <ArrowForwardIos
          sx={{
            "&.MuiSvgIcon-root": {
              fontSize: "16px",
            },
          }}
        />
      }
    >
      ログアウト
    </AmeButton>
  );
};
