import React, { createContext, useContext, useMemo } from "react";

import { PureAbility, defineAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";

import { MyselfContext } from "../MyselfContext";

export const ACTION = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
  manage: "manage",
} as const satisfies Record<string, string>;
type Actions = keyof typeof ACTION;

export const SUBJECT = {
  all: "all",
  interview: "interview",
  interviewFile: "interviewFile",
  setting: "setting",
  hiddenItem: "hiddenItem",
} as const satisfies Record<string, string>;
type Subjects = keyof typeof SUBJECT;
export type AmeAbility = PureAbility<[Actions, Subjects], never>;
export const AbilityContext = createContext<AmeAbility>({} as never);

export const Can = createContextualCan(AbilityContext.Consumer);

export const Authorizer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { myUserData } = useContext(MyselfContext);
  const ability = useMemo(() => {
    return defineAbility<AmeAbility>((can) => {
      if (myUserData.isAdmin) {
        can(ACTION.manage, SUBJECT.all);
      } else {
        can(ACTION.read, SUBJECT.interview);
        can(ACTION.manage, SUBJECT.interviewFile);
      }
    });
  }, [myUserData]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};
