export const createEnum = <T extends string>(...values: T[]): { [key in T]: key } => {
    if (new Set(values).size !== values.length) {
        throw new Error("Duplicate value in enum");
    }
    return values.reduce(
        (acc, value) => {
            acc[value] = value;
            return acc;
        },
        {} as { [key in T]: key },
    );
};
