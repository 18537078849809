/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ユーザーが直接指定可能なattributeのこと
 * @export
 * @enum {string}
 */
export enum InputSegmentKey {
    SEX = 'SEX',
    DEPARTMENT = 'DEPARTMENT',
    FREE1 = 'FREE1',
    FREE2 = 'FREE2'
}

export function InputSegmentKeyFromJSON(json: any): InputSegmentKey {
    return InputSegmentKeyFromJSONTyped(json, false);
}

export function InputSegmentKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputSegmentKey {
    return json as InputSegmentKey;
}

export function InputSegmentKeyToJSON(value?: InputSegmentKey | null): any {
    return value as any;
}

