import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConfig: build.query<GetConfigApiResponse, GetConfigApiArg>({
      query: () => ({ url: `/api/config` }),
    }),
    healthcheck: build.query<HealthcheckApiResponse, HealthcheckApiArg>({
      query: () => ({ url: `/api/healthcheck` }),
    }),
    getVersion: build.query<GetVersionApiResponse, GetVersionApiArg>({
      query: () => ({ url: `/api/healthcheck/version` }),
    }),
    rebuildAnalysisFromScratch: build.query<RebuildAnalysisFromScratchApiResponse, RebuildAnalysisFromScratchApiArg>({
      query: (queryArg) => ({ url: `/api/rebuildAnalysisFromScratch`, params: { password: queryArg.password } }),
    }),
    getComputationTask: build.query<GetComputationTaskApiResponse, GetComputationTaskApiArg>({
      query: (queryArg) => ({ url: `/api/computation-tasks/${queryArg.computationTaskId}` }),
    }),
    getLatestComputationTaskByDocumentId: build.query<
      GetLatestComputationTaskByDocumentIdApiResponse,
      GetLatestComputationTaskByDocumentIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/documents/${queryArg.documentId}/computation-tasks/latest` }),
    }),
    getNotes: build.query<GetNotesApiResponse, GetNotesApiArg>({
      query: (queryArg) => ({ url: `/api/notes`, params: { segmentKey: queryArg.segmentKey } }),
    }),
    createNote: build.mutation<CreateNoteApiResponse, CreateNoteApiArg>({
      query: (queryArg) => ({ url: `/api/notes`, method: "POST", body: queryArg.createNoteRequestBody }),
    }),
    getNote: build.query<GetNoteApiResponse, GetNoteApiArg>({
      query: (queryArg) => ({ url: `/api/notes/${queryArg.noteId}` }),
    }),
    updateNote: build.mutation<UpdateNoteApiResponse, UpdateNoteApiArg>({
      query: (queryArg) => ({
        url: `/api/notes/${queryArg.noteId}`,
        method: "PUT",
        body: queryArg.updateNoteRequestBody,
      }),
    }),
    deleteNote: build.mutation<DeleteNoteApiResponse, DeleteNoteApiArg>({
      query: (queryArg) => ({ url: `/api/notes/${queryArg.noteId}`, method: "DELETE" }),
    }),
    getEmployeeList: build.query<GetEmployeeListApiResponse, GetEmployeeListApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employees`,
        params: {
          at: queryArg.at,
          count: queryArg.count,
          offset: queryArg.offset,
          customerCreatedId: queryArg.customerCreatedId,
        },
      }),
    }),
    registerEmployees: build.mutation<RegisterEmployeesApiResponse, RegisterEmployeesApiArg>({
      query: (queryArg) => ({ url: `/api/v5/employee-master/employees`, method: "POST", body: queryArg.body }),
    }),
    getEmployee: build.query<GetEmployeeApiResponse, GetEmployeeApiArg>({
      query: (queryArg) => ({ url: `/api/v5/employee-master/employees/${queryArg.employeeId}` }),
    }),
    getEmployeeTenure: build.query<GetEmployeeTenureApiResponse, GetEmployeeTenureApiArg>({
      query: (queryArg) => ({ url: `/api/v5/employee-master/employee-tenures/${queryArg.employeeTenureId}` }),
    }),
    updateEmployeeTenure: build.mutation<UpdateEmployeeTenureApiResponse, UpdateEmployeeTenureApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-tenures/${queryArg.employeeTenureId}`,
        method: "PUT",
        body: queryArg.updateEmployeeTenureRequestBody,
      }),
    }),
    deleteTenure: build.mutation<DeleteTenureApiResponse, DeleteTenureApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-tenures/${queryArg.employeeTenureId}`,
        method: "DELETE",
      }),
    }),
    getEmployeeAttributes: build.query<GetEmployeeAttributesApiResponse, GetEmployeeAttributesApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-tenures/${queryArg.employeeTenureId}/attributes`,
        params: { at: queryArg.at },
      }),
    }),
    upsertEmployeeAttributes: build.mutation<UpsertEmployeeAttributesApiResponse, UpsertEmployeeAttributesApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-tenures/${queryArg.employeeTenureId}/attributes`,
        method: "PUT",
        body: queryArg.addEmployeeAttributesBody,
      }),
    }),
    deleteEmployeeAttributes: build.mutation<DeleteEmployeeAttributesApiResponse, DeleteEmployeeAttributesApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-tenures/${queryArg.employeeTenureId}/attributes`,
        method: "DELETE",
        params: { at: queryArg.at },
      }),
    }),
    customerCreatedIdExists: build.query<CustomerCreatedIdExistsApiResponse, CustomerCreatedIdExistsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employees/customer-created-id/exists/${queryArg.customerCreatedId}`,
      }),
    }),
    addEmployeeChangeSet: build.mutation<AddEmployeeChangeSetApiResponse, AddEmployeeChangeSetApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-change-set`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getEmployeeChangeSetSummary: build.query<GetEmployeeChangeSetSummaryApiResponse, GetEmployeeChangeSetSummaryApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v5/employee-master/employee-change-set/${queryArg.changeSetId}`,
          params: { count: queryArg.count, offset: queryArg.offset },
        }),
      },
    ),
    applyEmployeeChangeSet: build.mutation<ApplyEmployeeChangeSetApiResponse, ApplyEmployeeChangeSetApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-change-set/${queryArg.changeSetId}`,
        method: "POST",
      }),
    }),
    addEmployeeAttributeDefinition: build.mutation<
      AddEmployeeAttributeDefinitionApiResponse,
      AddEmployeeAttributeDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/attributes`,
        method: "POST",
        body: queryArg.addEmployeeDefinitionRequestBody,
      }),
    }),
    getEmployeeReportV3: build.query<GetEmployeeReportV3ApiResponse, GetEmployeeReportV3ApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/employee-master/employee-reports/periods/${queryArg.periodId}`,
        params: {
          axis: queryArg.axis,
          from: queryArg["from"],
          to: queryArg.to,
          sexes: queryArg.sexes,
          departments: queryArg.departments,
          free1s: queryArg.free1S,
          free2s: queryArg.free2S,
          monthsOfServices: queryArg.monthsOfServices,
          ages: queryArg.ages,
        },
      }),
    }),
    downloadEmployeeCsv: build.query<DownloadEmployeeCsvApiResponse, DownloadEmployeeCsvApiArg>({
      query: (queryArg) => ({ url: `/api/v5/employee-master/download-employees`, params: { at: queryArg.at } }),
    }),
    createHrInterviewFile: build.mutation<CreateHrInterviewFileApiResponse, CreateHrInterviewFileApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-files`,
        method: "POST",
        body: queryArg.createHrInterviewFileRequestBody,
      }),
    }),
    findHrInterviewFiles: build.query<FindHrInterviewFilesApiResponse, FindHrInterviewFilesApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-files`,
        params: {
          count: queryArg.count,
          offset: queryArg.offset,
          linkWithInterview: queryArg.linkWithInterview,
          uploadUserIds: queryArg.uploadUserIds,
          createdAtStart: queryArg.createdAtStart,
          createdAtEnd: queryArg.createdAtEnd,
        },
      }),
    }),
    getHrInterviewFile: build.query<GetHrInterviewFileApiResponse, GetHrInterviewFileApiArg>({
      query: (queryArg) => ({ url: `/api/v5/hr-interview/interview-files/${queryArg.hrInterviewFileId}` }),
    }),
    updateHrInterviewFile: build.mutation<UpdateHrInterviewFileApiResponse, UpdateHrInterviewFileApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-files/${queryArg.hrInterviewFileId}`,
        method: "PUT",
        body: queryArg.updateHrInterviewFileRequestBody,
      }),
    }),
    deleteHrInterviewFile: build.mutation<DeleteHrInterviewFileApiResponse, DeleteHrInterviewFileApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-files/${queryArg.hrInterviewFileId}`,
        method: "DELETE",
      }),
    }),
    getInterviewFileDownloadUrl: build.query<GetInterviewFileDownloadUrlApiResponse, GetInterviewFileDownloadUrlApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v5/hr-interview/interview-files/${queryArg.hrInterviewFileId}/download-url`,
        }),
      },
    ),
    getHrInterviewFileUploaderUrl: build.mutation<
      GetHrInterviewFileUploaderUrlApiResponse,
      GetHrInterviewFileUploaderUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-file-uploader-urls`,
        method: "PUT",
        body: queryArg.getHrInterviewFileUploaderUrlBody,
      }),
    }),
    completeHrInterviewFileUpload: build.mutation<
      CompleteHrInterviewFileUploadApiResponse,
      CompleteHrInterviewFileUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-file-uploader-urls/${queryArg.uploadId}/complete`,
        method: "POST",
        body: queryArg.completeHrInterviewFileUploadBody,
      }),
    }),
    getHrInterviewQualityReport: build.query<GetHrInterviewQualityReportApiResponse, GetHrInterviewQualityReportApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v5/hr-interview/quality-report`,
          params: {
            axis: queryArg.axis,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            followUpIsRequired: queryArg.followUpIsRequired,
            interviewTypeIds: queryArg.interviewTypeIds,
            interviewerIds: queryArg.interviewerIds,
            evaluationItems: queryArg.evaluationItems,
          },
        }),
      },
    ),
    createInterviewType: build.mutation<CreateInterviewTypeApiResponse, CreateInterviewTypeApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-types`,
        method: "POST",
        body: queryArg.createInterviewTypeRequestBody,
      }),
    }),
    registerInterviewType: build.mutation<RegisterInterviewTypeApiResponse, RegisterInterviewTypeApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-types`,
        method: "PUT",
        body: queryArg.registerInterviewTypesRequestBody,
      }),
    }),
    getInterviewTypes: build.query<GetInterviewTypesApiResponse, GetInterviewTypesApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-types`,
        params: { includeDeleted: queryArg.includeDeleted },
      }),
    }),
    getInterviewType: build.query<GetInterviewTypeApiResponse, GetInterviewTypeApiArg>({
      query: (queryArg) => ({ url: `/api/v5/hr-interview/interview-types/${queryArg.interviewTypeId}` }),
    }),
    getInterviewFormatByTypeId: build.query<GetInterviewFormatByTypeIdApiResponse, GetInterviewFormatByTypeIdApiArg>({
      query: (queryArg) => ({ url: `/api/v5/hr-interview/interview-types/${queryArg.interviewTypeId}/formats` }),
    }),
    createInterviewFormat: build.mutation<CreateInterviewFormatApiResponse, CreateInterviewFormatApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-types/${queryArg.interviewTypeId}/formats`,
        method: "POST",
        body: queryArg.createInterviewFormatRequestBody,
      }),
    }),
    registerInterviewFormats: build.mutation<RegisterInterviewFormatsApiResponse, RegisterInterviewFormatsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-types/${queryArg.interviewTypeId}/formats`,
        method: "PUT",
        body: queryArg.registerInterviewFormatsRequestBody,
      }),
    }),
    updateInterviewFormat: build.mutation<UpdateInterviewFormatApiResponse, UpdateInterviewFormatApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-formats/${queryArg.interviewFormatId}`,
        method: "PUT",
        body: queryArg.updateInterviewFormatRequestBody,
      }),
    }),
    getInterviewFormatContents: build.query<GetInterviewFormatContentsApiResponse, GetInterviewFormatContentsApiArg>({
      query: (queryArg) => ({ url: `/api/v5/hr-interview/interview-formats/${queryArg.interviewFormatId}/contents` }),
    }),
    registerInterviewFormatContents: build.mutation<
      RegisterInterviewFormatContentsApiResponse,
      RegisterInterviewFormatContentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-formats/${queryArg.interviewFormatId}/contents`,
        method: "PUT",
        body: queryArg.registerInterviewFormatContentsRequestBody,
      }),
    }),
    getHrInterviewFeedbackQuestionList: build.query<
      GetHrInterviewFeedbackQuestionListApiResponse,
      GetHrInterviewFeedbackQuestionListApiArg
    >({
      query: () => ({ url: `/api/v5/hr-interview/feedbacks/questions` }),
    }),
    updateHrInterviewFeedbackQuestions: build.mutation<
      UpdateHrInterviewFeedbackQuestionsApiResponse,
      UpdateHrInterviewFeedbackQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/feedbacks/questions`,
        method: "PUT",
        body: queryArg.updateHrInterviewFeedbackQuestionsRequestBody,
      }),
    }),
    deleteHrInterviewFeedbackQuestion: build.mutation<
      DeleteHrInterviewFeedbackQuestionApiResponse,
      DeleteHrInterviewFeedbackQuestionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/feedbacks/questions/${queryArg.hrInterviewFeedbackQuestionId}`,
        method: "DELETE",
      }),
    }),
    createHrInterviewFeedbackAnswers: build.mutation<
      CreateHrInterviewFeedbackAnswersApiResponse,
      CreateHrInterviewFeedbackAnswersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/feedbacks/answers`,
        method: "POST",
        body: queryArg.createHrInterviewFeedbackAnswersRequestBody,
      }),
    }),
    getHrInterviews: build.query<GetHrInterviewsApiResponse, GetHrInterviewsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interviews`,
        params: {
          count: queryArg.count,
          offset: queryArg.offset,
          interviewTypeIds: queryArg.interviewTypeIds,
          interviewStatuses: queryArg.interviewStatuses,
          transcriptionStatuses: queryArg.transcriptionStatuses,
          interviewerIds: queryArg.interviewerIds,
          followUpIsRequired: queryArg.followUpIsRequired,
          segmentFilters: queryArg.segmentFilters,
          interviewDateStart: queryArg.interviewDateStart,
          interviewDateEnd: queryArg.interviewDateEnd,
          feedbackStatuses: queryArg.feedbackStatuses,
        },
      }),
    }),
    createHrInterview: build.mutation<CreateHrInterviewApiResponse, CreateHrInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interviews`,
        method: "POST",
        body: queryArg.createHrInterviewRequestBody,
      }),
    }),
    getHrInterview: build.query<GetHrInterviewApiResponse, GetHrInterviewApiArg>({
      query: (queryArg) => ({ url: `/api/v5/hr-interview/interviews/${queryArg.hrInterviewId}` }),
    }),
    updateHrInterview: build.mutation<UpdateHrInterviewApiResponse, UpdateHrInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interviews/${queryArg.hrInterviewId}`,
        method: "PUT",
        body: queryArg.updateHrInterviewRequestBody,
      }),
    }),
    deleteHrInterview: build.mutation<DeleteHrInterviewApiResponse, DeleteHrInterviewApiArg>({
      query: (queryArg) => ({ url: `/api/v5/hr-interview/interviews/${queryArg.hrInterviewId}`, method: "DELETE" }),
    }),
    updateFileOfHrInterview: build.mutation<UpdateFileOfHrInterviewApiResponse, UpdateFileOfHrInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interviews/${queryArg.hrInterviewId}/interview-files`,
        method: "PUT",
        body: queryArg.updateFileOfHrInterviewRequestBody,
      }),
    }),
    analyzeHrInterview: build.mutation<AnalyzeHrInterviewApiResponse, AnalyzeHrInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interviews/${queryArg.hrInterviewId}/analyze`,
        method: "POST",
      }),
    }),
    summarizeHrInterview: build.mutation<SummarizeHrInterviewApiResponse, SummarizeHrInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interviews/${queryArg.hrInterviewId}/summarize`,
        method: "PUT",
        params: { fromTranscription: queryArg.fromTranscription },
      }),
    }),
    sendHrInterviewFeedback: build.mutation<SendHrInterviewFeedbackApiResponse, SendHrInterviewFeedbackApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interviews/${queryArg.hrInterviewId}/send-feedback`,
        method: "PUT",
        body: queryArg.sendHrInterviewFeedbackRequestBody,
      }),
    }),
    getHrInterviewReport: build.query<GetHrInterviewReportApiResponse, GetHrInterviewReportApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/hr-interview/interview-report`,
        params: {
          axis: queryArg.axis,
          interviewTypeIds: queryArg.interviewTypeIds,
          followUpIsRequired: queryArg.followUpIsRequired,
          interviewDateFrom: queryArg.interviewDateFrom,
          interviewDateTo: queryArg.interviewDateTo,
          subCategoryIds: queryArg.subCategoryIds,
          segmentFilters: queryArg.segmentFilters,
        },
      }),
    }),
    createInterviewFiles: build.mutation<CreateInterviewFilesApiResponse, CreateInterviewFilesApiArg>({
      query: (queryArg) => ({ url: `/api/v5/interview/interview-files`, method: "POST", body: queryArg.body }),
    }),
    getInterviewFiles: build.query<GetInterviewFilesApiResponse, GetInterviewFilesApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-files`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    rebuildDurationOfInterviewFiles: build.mutation<
      RebuildDurationOfInterviewFilesApiResponse,
      RebuildDurationOfInterviewFilesApiArg
    >({
      query: () => ({ url: `/api/v5/interview/interview-files`, method: "PUT" }),
    }),
    getInterviewFile: build.query<GetInterviewFileApiResponse, GetInterviewFileApiArg>({
      query: (queryArg) => ({ url: `/api/v5/interview/interview-files/${queryArg.interviewRecordingId}` }),
    }),
    updateInterviewFile: build.mutation<UpdateInterviewFileApiResponse, UpdateInterviewFileApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-files/${queryArg.interviewRecordingId}`,
        method: "PUT",
        body: queryArg.updateInterviewFileRequestBody,
      }),
    }),
    deleteInterviewFile: build.mutation<DeleteInterviewFileApiResponse, DeleteInterviewFileApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-files/${queryArg.interviewRecordingId}`,
        method: "DELETE",
      }),
    }),
    createInterviewAudioFileUploadUrls: build.mutation<
      CreateInterviewAudioFileUploadUrlsApiResponse,
      CreateInterviewAudioFileUploadUrlsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/interview/presigned-urls/interview-audio-files`,
        method: "POST",
        body: queryArg.createInterviewAudioFileUploadUrlsRequestBody,
      }),
    }),
    completeInterviewFileMultipartUpload: build.mutation<
      CompleteInterviewFileMultipartUploadApiResponse,
      CompleteInterviewFileMultipartUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/interview/presigned-urls/interview-audio-files/${queryArg.uploadId}`,
        method: "PUT",
        body: queryArg.completeInterviewAudioFileUploadRequestBody,
      }),
    }),
    getInterviewAudioFileUrl: build.query<GetInterviewAudioFileUrlApiResponse, GetInterviewAudioFileUrlApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/presigned-urls/interview-audio-files/${queryArg.interviewFileId}`,
      }),
    }),
    startTranscription: build.mutation<StartTranscriptionApiResponse, StartTranscriptionApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-file-transcriptions/${queryArg.interviewRecordingId}/transcription`,
        method: "POST",
      }),
    }),
    getTranscription: build.query<GetTranscriptionApiResponse, GetTranscriptionApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-file-transcriptions/${queryArg.interviewRecordingId}/transcription`,
      }),
    }),
    updateTranscription: build.mutation<UpdateTranscriptionApiResponse, UpdateTranscriptionApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-file-transcriptions/${queryArg.interviewRecordingId}/transcription`,
        method: "PUT",
        body: queryArg.updateTranscriptionRequestBody,
      }),
    }),
    submitTranscription: build.mutation<SubmitTranscriptionApiResponse, SubmitTranscriptionApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-file-transcriptions/${queryArg.interviewRecordingId}/transcription/submit`,
        method: "PUT",
      }),
    }),
    createExEmployeeInterview: build.mutation<CreateExEmployeeInterviewApiResponse, CreateExEmployeeInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/periods/${queryArg.periodId}/employees/${queryArg.employeeId}/interviews`,
        method: "POST",
        body: queryArg.createExEmployeeInterviewRequestBody,
      }),
    }),
    createExEmployeeInterviewByEmployeeTenureId: build.mutation<
      CreateExEmployeeInterviewByEmployeeTenureIdApiResponse,
      CreateExEmployeeInterviewByEmployeeTenureIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/interview/employee-tenures/${queryArg.employeeTenureId}/ex-employee-interviews`,
        method: "POST",
        body: queryArg.createExEmployeeInterviewRequestBody,
      }),
    }),
    getExEmployeeInterviews: build.query<GetExEmployeeInterviewsApiResponse, GetExEmployeeInterviewsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    getExEmployeeInterview: build.query<GetExEmployeeInterviewApiResponse, GetExEmployeeInterviewApiArg>({
      query: (queryArg) => ({ url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}` }),
    }),
    editExEmployeeInterview: build.mutation<EditExEmployeeInterviewApiResponse, EditExEmployeeInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}`,
        method: "PUT",
        body: queryArg.editExEmployeeInterviewRequestBody,
      }),
    }),
    deleteExEmployeeInterview: build.mutation<DeleteExEmployeeInterviewApiResponse, DeleteExEmployeeInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}`,
        method: "DELETE",
      }),
    }),
    analyzeExEmployeeInterview: build.mutation<AnalyzeExEmployeeInterviewApiResponse, AnalyzeExEmployeeInterviewApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}/analysis`,
          method: "POST",
        }),
      },
    ),
    unfixExEmployeeInterview: build.mutation<UnfixExEmployeeInterviewApiResponse, UnfixExEmployeeInterviewApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}/analysis`,
        method: "DELETE",
      }),
    }),
    getLatestComputationTaskByExEmployeeInterviewId: build.query<
      GetLatestComputationTaskByExEmployeeInterviewIdApiResponse,
      GetLatestComputationTaskByExEmployeeInterviewIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}/computation-task/latest`,
      }),
    }),
    summarizeExEmployeeInterview: build.mutation<
      SummarizeExEmployeeInterviewApiResponse,
      SummarizeExEmployeeInterviewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}/summary`,
        method: "POST",
      }),
    }),
    submitInterviewFeedback: build.mutation<SubmitInterviewFeedbackApiResponse, SubmitInterviewFeedbackApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-files/${queryArg.interviewFileId}/interview-feedback`,
        method: "PUT",
        body: queryArg.submitInterviewFeedbackRequest,
      }),
    }),
    getInterviewFeedbacks: build.query<GetInterviewFeedbacksApiResponse, GetInterviewFeedbacksApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/users/${queryArg.userId}/interview-feedbacks`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    getInterviewQualityReportList: build.query<
      GetInterviewQualityReportListApiResponse,
      GetInterviewQualityReportListApiArg
    >({
      query: () => ({ url: `/api/v5/interview/interview-quality-reports` }),
    }),
    getInterviewQualityReport: build.query<GetInterviewQualityReportApiResponse, GetInterviewQualityReportApiArg>({
      query: (queryArg) => ({ url: `/api/v5/interview/interview-quality-reports/${queryArg.userId}` }),
    }),
    getInterviewReport: build.query<GetInterviewReportApiResponse, GetInterviewReportApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interviews/scores`,
        params: {
          axis: queryArg.axis,
          from: queryArg["from"],
          to: queryArg.to,
          sexes: queryArg.sexes,
          departments: queryArg.departments,
          free1s: queryArg.free1S,
          free2s: queryArg.free2S,
          monthsOfServices: queryArg.monthsOfServices,
          ages: queryArg.ages,
          subCategoryIds: queryArg.subCategoryIds,
        },
      }),
    }),
    getInterviewKpiReport: build.query<GetInterviewKpiReportApiResponse, GetInterviewKpiReportApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/interview-kpi-reports/${queryArg.periodId}`,
        params: {
          sexes: queryArg.sexes,
          departments: queryArg.departments,
          free1s: queryArg.free1S,
          free2s: queryArg.free2S,
          monthsOfServices: queryArg.monthsOfServices,
          ages: queryArg.ages,
        },
      }),
    }),
    getDocumentsByExEmployeeInterviewId: build.query<
      GetDocumentsByExEmployeeInterviewIdApiResponse,
      GetDocumentsByExEmployeeInterviewIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews/${queryArg.exEmployeeInterviewId}/documents`,
      }),
    }),
    getExEmployeeReviews: build.query<GetExEmployeeReviewsApiResponse, GetExEmployeeReviewsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/interview/ex-employee-interviews/summaries/reviews`,
        params: {
          count: queryArg.count,
          offset: queryArg.offset,
          categoryId: queryArg.categoryId,
          subCategoryIds: queryArg.subCategoryIds,
          segmentKey: queryArg.segmentKey,
          segmentValue: queryArg.segmentValue,
          endCreatedAt: queryArg.endCreatedAt,
        },
      }),
    }),
    searchForInterviewDocuments: build.query<SearchForInterviewDocumentsApiResponse, SearchForInterviewDocumentsApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v5/interview/interview-documents`,
          params: {
            count: queryArg.count,
            offset: queryArg.offset,
            subCategoryId: queryArg.subCategoryId,
            sex: queryArg.sex,
            department: queryArg.department,
            free1: queryArg.free1,
            free2: queryArg.free2,
            age: queryArg.age,
            monthsOfService: queryArg.monthsOfService,
            retireDateBegin: queryArg.retireDateBegin,
            retireDateEnd: queryArg.retireDateEnd,
          },
        }),
      },
    ),
    getSurveyReport: build.query<GetSurveyReportApiResponse, GetSurveyReportApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/reports/survey-reports/${queryArg.surveyId}`,
        params: {
          axis: queryArg.axis,
          sexes: queryArg.sexes,
          departments: queryArg.departments,
          free1s: queryArg.free1S,
          free2s: queryArg.free2S,
          monthsOfServices: queryArg.monthsOfServices,
          ages: queryArg.ages,
          subCategoryIds: queryArg.subCategoryIds,
        },
      }),
    }),
    registerSurveyQuestions: build.mutation<RegisterSurveyQuestionsApiResponse, RegisterSurveyQuestionsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/survey-questions`,
        method: "PUT",
        body: queryArg.registerSurveyQuestionRequestBody,
      }),
    }),
    getSurveyQuestions: build.query<GetSurveyQuestionsApiResponse, GetSurveyQuestionsApiArg>({
      query: () => ({ url: `/api/v5/settings/survey-questions` }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/users`, method: "POST", body: queryArg.createUserRequestBody }),
    }),
    getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
      query: () => ({ url: `/api/v5/settings/users` }),
    }),
    syncCognitoUsers: build.mutation<SyncCognitoUsersApiResponse, SyncCognitoUsersApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/users/sync`,
        method: "POST",
        body: queryArg.syncCognitoUsersRequestBody,
      }),
    }),
    getMyself: build.query<GetMyselfApiResponse, GetMyselfApiArg>({
      query: () => ({ url: `/api/v5/settings/users/me` }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/users/${queryArg.userId}` }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/users/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.updateUserRequestBody,
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/users/${queryArg.userId}`, method: "DELETE" }),
    }),
    resendInvitation: build.mutation<ResendInvitationApiResponse, ResendInvitationApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/users/resend-invitation`,
        method: "POST",
        body: queryArg.resendInvitationRequestBody,
      }),
    }),
    createUserGroup: build.mutation<CreateUserGroupApiResponse, CreateUserGroupApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/user-groups`,
        method: "POST",
        body: queryArg.createUserGroupRequest,
      }),
    }),
    getUserGroups: build.query<GetUserGroupsApiResponse, GetUserGroupsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/user-groups`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    getUserGroup: build.query<GetUserGroupApiResponse, GetUserGroupApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/user-groups/${queryArg.userGroupId}`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    updateUserGroup: build.mutation<UpdateUserGroupApiResponse, UpdateUserGroupApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/user-groups/${queryArg.userGroupId}`,
        method: "PUT",
        body: queryArg.partialCreateUserGroupRequest,
      }),
    }),
    deleteUserGroup: build.mutation<DeleteUserGroupApiResponse, DeleteUserGroupApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/user-groups/${queryArg.userGroupId}`, method: "DELETE" }),
    }),
    getUserGroupsOfUser: build.query<GetUserGroupsOfUserApiResponse, GetUserGroupsOfUserApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/users/${queryArg.userId}/groups` }),
    }),
    setUsersToUserGroup: build.mutation<SetUsersToUserGroupApiResponse, SetUsersToUserGroupApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/user-groups/${queryArg.userGroupId}/users`,
        method: "PUT",
        body: queryArg.userGroupMembersRequest,
      }),
    }),
    getInterviewFeedbackSettings: build.query<
      GetInterviewFeedbackSettingsApiResponse,
      GetInterviewFeedbackSettingsApiArg
    >({
      query: () => ({ url: `/api/v5/settings/interview-feedback` }),
    }),
    updateInterviewFeedbackSettings: build.mutation<
      UpdateInterviewFeedbackSettingsApiResponse,
      UpdateInterviewFeedbackSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/settings/interview-feedback`,
        method: "PUT",
        body: queryArg.updateInterviewFeedbackSettingsRequest,
      }),
    }),
    createCategory: build.mutation<CreateCategoryApiResponse, CreateCategoryApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/categories`,
        method: "POST",
        body: queryArg.createCategoryRequestBody,
      }),
    }),
    getAllCategories: build.query<GetAllCategoriesApiResponse, GetAllCategoriesApiArg>({
      query: () => ({ url: `/api/v5/settings/categories` }),
    }),
    getCategory: build.query<GetCategoryApiResponse, GetCategoryApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/categories/${queryArg.categoryId}` }),
    }),
    updateCategory: build.mutation<UpdateCategoryApiResponse, UpdateCategoryApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/categories/${queryArg.categoryId}`,
        method: "PUT",
        body: queryArg.updateCategoryRequestBody,
      }),
    }),
    deleteCategory: build.mutation<DeleteCategoryApiResponse, DeleteCategoryApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/categories/${queryArg.categoryId}`, method: "DELETE" }),
    }),
    createSubCategory: build.mutation<CreateSubCategoryApiResponse, CreateSubCategoryApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/categories/${queryArg.categoryId}/sub-categories`,
        method: "POST",
        body: queryArg.createSubCategoryRequestBody,
      }),
    }),
    getSubCategoryByCategoryId: build.query<GetSubCategoryByCategoryIdApiResponse, GetSubCategoryByCategoryIdApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/categories/${queryArg.categoryId}/sub-categories` }),
    }),
    getAllSubCategories: build.query<GetAllSubCategoriesApiResponse, GetAllSubCategoriesApiArg>({
      query: () => ({ url: `/api/v5/settings/sub-categories` }),
    }),
    getSubCategoryBySubCategoryId: build.query<
      GetSubCategoryBySubCategoryIdApiResponse,
      GetSubCategoryBySubCategoryIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v5/settings/sub-categories/${queryArg.subCategoryId}` }),
    }),
    updateSubCategory: build.mutation<UpdateSubCategoryApiResponse, UpdateSubCategoryApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/sub-categories/${queryArg.subCategoryId}`,
        method: "PUT",
        body: queryArg.updateSubCategoryRequestBody,
      }),
    }),
    deleteSubCategory: build.mutation<DeleteSubCategoryApiResponse, DeleteSubCategoryApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/sub-categories/${queryArg.subCategoryId}`, method: "DELETE" }),
    }),
    addSubcategoriesToDocument: build.mutation<AddSubcategoriesToDocumentApiResponse, AddSubcategoriesToDocumentApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v5/settings/documents/${queryArg.documentId}`,
          method: "PUT",
          body: queryArg.addSubCategoriesRequestBody,
        }),
      },
    ),
    createRule: build.mutation<CreateRuleApiResponse, CreateRuleApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/categories/${queryArg.categoryId}/rules`,
        method: "POST",
        body: queryArg.createRuleRequestBodyString,
      }),
    }),
    getRulesByCategoryId: build.query<GetRulesByCategoryIdApiResponse, GetRulesByCategoryIdApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/categories/${queryArg.categoryId}/rules` }),
    }),
    dumpRules: build.query<DumpRulesApiResponse, DumpRulesApiArg>({
      query: () => ({ url: `/api/v5/settings/rules` }),
    }),
    updateRule: build.mutation<UpdateRuleApiResponse, UpdateRuleApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/rules/${queryArg.ruleId}`,
        method: "PUT",
        body: queryArg.genericUpdateRuleRequestBodyString,
      }),
    }),
    deleteRule: build.mutation<DeleteRuleApiResponse, DeleteRuleApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/rules/${queryArg.ruleId}`, method: "DELETE" }),
    }),
    getLatestComputationTaskByRuleId: build.query<
      GetLatestComputationTaskByRuleIdApiResponse,
      GetLatestComputationTaskByRuleIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v5/settings/rules/${queryArg.ruleId}/computation-task/latest` }),
    }),
    getRuleSuggestionsByCategoryId: build.query<
      GetRuleSuggestionsByCategoryIdApiResponse,
      GetRuleSuggestionsByCategoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/settings/categories/${queryArg.categoryId}/rule-suggestions`,
        params: {
          algorithm: queryArg.algorithm,
          query: queryArg.query,
          size: queryArg.size,
          significantTerms: queryArg.significantTerms,
          filterNonEffectiveRules: queryArg.filterNonEffectiveRules,
        },
      }),
    }),
    getRuleSuggestionsBySubCategoryId: build.query<
      GetRuleSuggestionsBySubCategoryIdApiResponse,
      GetRuleSuggestionsBySubCategoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/settings/sub-categories/${queryArg.subCategoryId}/rule-suggestions`,
        params: {
          algorithm: queryArg.algorithm,
          query: queryArg.query,
          size: queryArg.size,
          significantTerms: queryArg.significantTerms,
          filterNonEffectiveRules: queryArg.filterNonEffectiveRules,
        },
      }),
    }),
    searchInterviewDocumentsByRawRule: build.query<
      SearchInterviewDocumentsByRawRuleApiResponse,
      SearchInterviewDocumentsByRawRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/settings/retirements/interviews/documents/search-by-rule`,
        params: { esQuery: queryArg.esQuery, count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    startLabelingSession: build.mutation<StartLabelingSessionApiResponse, StartLabelingSessionApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/labels/start`,
        method: "POST",
        body: queryArg.genericStartLabelingSessionRequestBodyString,
      }),
    }),
    getLabelingResult: build.query<GetLabelingResultApiResponse, GetLabelingResultApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/labels/results`,
        params: { subCategoryId: queryArg.subCategoryId, count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    setLabel: build.mutation<SetLabelApiResponse, SetLabelApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/labels/${queryArg.labelId}`,
        method: "POST",
        body: queryArg.setLabelRequestBody,
      }),
    }),
    getLabelDocument: build.query<GetLabelDocumentApiResponse, GetLabelDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/labels/sessions/${queryArg.sessionId}`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    getStatsByRule: build.query<GetStatsByRuleApiResponse, GetStatsByRuleApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/rules/${queryArg.ruleId}/stats` }),
    }),
    getLabelStatsBySubCategoryId: build.query<
      GetLabelStatsBySubCategoryIdApiResponse,
      GetLabelStatsBySubCategoryIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v5/settings/sub-categories/${queryArg.subCategoryId}/label-stats` }),
    }),
    getSetting: build.query<GetSettingApiResponse, GetSettingApiArg>({
      query: () => ({ url: `/api/v5/settings/settings` }),
    }),
    updateEmailSettings: build.mutation<UpdateEmailSettingsApiResponse, UpdateEmailSettingsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/settings/emails`,
        method: "PUT",
        body: queryArg.updateEmailSettingsBody,
      }),
    }),
    updateGraphScaleSettings: build.mutation<UpdateGraphScaleSettingsApiResponse, UpdateGraphScaleSettingsApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/settings/graph-scales`,
        method: "PUT",
        body: queryArg.updateGraphScaleSettingsBody,
      }),
    }),
    registerSegmentName: build.mutation<RegisterSegmentNameApiResponse, RegisterSegmentNameApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/settings/segments/${queryArg.segmentKey}/name`,
        method: "PUT",
        body: queryArg.registerSegmentNameRequestBody,
      }),
    }),
    getSegmentDefinitions: build.query<GetSegmentDefinitionsApiResponse, GetSegmentDefinitionsApiArg>({
      query: (queryArg) => ({ url: `/api/v5/settings/settings/segments/${queryArg.segmentKey}/definitions` }),
    }),
    createSegmentDefinition: build.mutation<CreateSegmentDefinitionApiResponse, CreateSegmentDefinitionApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/settings/segments/${queryArg.segmentKey}/definitions`,
        method: "POST",
        body: queryArg.createSegmentDefinitionRequestBody,
      }),
    }),
    updateSegmentDefinition: build.mutation<UpdateSegmentDefinitionApiResponse, UpdateSegmentDefinitionApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/settings/segments/${queryArg.segmentKey}/definitions/${queryArg.inputSegmentDefinitionId}`,
        method: "PUT",
        body: queryArg.editSegmentDefinitionRequestBody,
      }),
    }),
    getCalculatedSegmentConditionSetting: build.query<
      GetCalculatedSegmentConditionSettingApiResponse,
      GetCalculatedSegmentConditionSettingApiArg
    >({
      query: (queryArg) => ({ url: `/api/v5/settings/settings/segments/${queryArg.segmentKey}/conditions` }),
    }),
    registerCalculatedSegmentCondition: build.mutation<
      RegisterCalculatedSegmentConditionApiResponse,
      RegisterCalculatedSegmentConditionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/settings/settings/segments/${queryArg.segmentKey}/conditions`,
        method: "PUT",
        body: queryArg.registerCalculatedSegmentSettingRequestBody,
      }),
    }),
    restoreInterviewAttribute: build.mutation<RestoreInterviewAttributeApiResponse, RestoreInterviewAttributeApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/periods/${queryArg.periodId}/restore-interview-attribute`,
        method: "POST",
        params: { target: queryArg.target },
      }),
    }),
    getPeriods: build.query<GetPeriodsApiResponse, GetPeriodsApiArg>({
      query: () => ({ url: `/api/v5/settings/periods` }),
    }),
    createPeriod: build.mutation<CreatePeriodApiResponse, CreatePeriodApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/settings/periods`,
        method: "POST",
        body: queryArg.createPeriodRequestBody,
      }),
    }),
    getLatestPeriod: build.query<GetLatestPeriodApiResponse, GetLatestPeriodApiArg>({
      query: () => ({ url: `/api/v5/settings/periods/latest-period` }),
    }),
    carryOverEmployeesIntoNextPeriod: build.mutation<
      CarryOverEmployeesIntoNextPeriodApiResponse,
      CarryOverEmployeesIntoNextPeriodApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v5/settings/periods/latest-period/carry-over`,
        method: "POST",
        body: queryArg.carryOverEmployeesToNextPeriodRequestBody,
      }),
    }),
    createSurvey: build.mutation<CreateSurveyApiResponse, CreateSurveyApiArg>({
      query: (queryArg) => ({ url: `/api/v5/survey/surveys`, method: "POST", body: queryArg.createSurveyRequestBody }),
    }),
    getSurveys: build.query<GetSurveysApiResponse, GetSurveysApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/survey/surveys`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    getSurvey: build.query<GetSurveyApiResponse, GetSurveyApiArg>({
      query: (queryArg) => ({ url: `/api/v5/survey/surveys/${queryArg.surveyId}` }),
    }),
    updateSurvey: build.mutation<UpdateSurveyApiResponse, UpdateSurveyApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/survey/surveys/${queryArg.surveyId}`,
        method: "PUT",
        body: queryArg.updateSurveyRequestBody,
      }),
    }),
    deleteSurvey: build.mutation<DeleteSurveyApiResponse, DeleteSurveyApiArg>({
      query: (queryArg) => ({ url: `/api/v5/survey/surveys/${queryArg.surveyId}`, method: "DELETE" }),
    }),
    getLatestSurvey: build.query<GetLatestSurveyApiResponse, GetLatestSurveyApiArg>({
      query: () => ({ url: `/api/v5/survey/latest-survey` }),
    }),
    startSurvey: build.mutation<StartSurveyApiResponse, StartSurveyApiArg>({
      query: (queryArg) => ({ url: `/api/v5/survey/surveys/${queryArg.surveyId}/start`, method: "PUT" }),
    }),
    updateSurveyStatus: build.mutation<UpdateSurveyStatusApiResponse, UpdateSurveyStatusApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/survey/surveys/${queryArg.surveyId}/status`,
        method: "PUT",
        body: queryArg.updateSurveyStatusRequestBody,
      }),
    }),
    getLatestComputationTaskBySurveyId: build.query<
      GetLatestComputationTaskBySurveyIdApiResponse,
      GetLatestComputationTaskBySurveyIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v5/survey/surveys/${queryArg.surveyId}/computation-tasks/latest` }),
    }),
    sendSampleSurveySheet: build.mutation<SendSampleSurveySheetApiResponse, SendSampleSurveySheetApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/survey/surveys/${queryArg.surveyId}/sample`,
        method: "POST",
        body: queryArg.sendSampleSurveySheetRequestBody,
      }),
    }),
    sendRemindSurveySheet: build.mutation<SendRemindSurveySheetApiResponse, SendRemindSurveySheetApiArg>({
      query: (queryArg) => ({ url: `/api/v5/survey/surveys/${queryArg.surveyId}/remind`, method: "POST" }),
    }),
    getSurveyAnswers: build.query<GetSurveyAnswersApiResponse, GetSurveyAnswersApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/survey/surveys/${queryArg.surveyId}/answers`,
        params: { count: queryArg.count, offset: queryArg.offset },
      }),
    }),
    getSurveySheet: build.query<GetSurveySheetApiResponse, GetSurveySheetApiArg>({
      query: (queryArg) => ({ url: `/api/v5/survey/survey-sheets/${queryArg.surveySheetId}` }),
    }),
    submitSurveyAnswer: build.mutation<SubmitSurveyAnswerApiResponse, SubmitSurveyAnswerApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/survey/survey-sheets/${queryArg.surveySheetId}`,
        method: "POST",
        body: queryArg.submitSurveyAnswerRequestBody,
      }),
    }),
    getSharedSurveySheet: build.query<GetSharedSurveySheetApiResponse, GetSharedSurveySheetApiArg>({
      query: (queryArg) => ({ url: `/api/v5/survey/surveys/${queryArg.surveyId}/survey-sheets` }),
    }),
    submitSurveyAnswerWithId: build.mutation<SubmitSurveyAnswerWithIdApiResponse, SubmitSurveyAnswerWithIdApiArg>({
      query: (queryArg) => ({
        url: `/api/v5/survey/surveys/${queryArg.surveyId}/survey-sheets`,
        method: "POST",
        body: queryArg.submitSurveyAnswerWithCustomerCreatedIdRequestBody,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as autogenApi };
export type GetConfigApiResponse = /** status 200 Ok */ ConfigurationResponse;
export type GetConfigApiArg = void;
export type HealthcheckApiResponse = /** status 200 Ok */ HealthStatusResponse;
export type HealthcheckApiArg = void;
export type GetVersionApiResponse = /** status 200 Ok */ string;
export type GetVersionApiArg = void;
export type RebuildAnalysisFromScratchApiResponse = /** status 200 Ok */ string;
export type RebuildAnalysisFromScratchApiArg = {
  password?: string;
};
export type GetComputationTaskApiResponse = /** status 200 Ok */ ComputationTaskResponse;
export type GetComputationTaskApiArg = {
  computationTaskId: string;
};
export type GetLatestComputationTaskByDocumentIdApiResponse = /** status 200 Ok */ ComputationTaskResponse;
export type GetLatestComputationTaskByDocumentIdApiArg = {
  documentId: string;
};
export type GetNotesApiResponse = /** status 200 Ok */ NoteListResponse;
export type GetNotesApiArg = {
  segmentKey?: SegmentKey;
};
export type CreateNoteApiResponse = /** status 200 Ok */ NoteResponse;
export type CreateNoteApiArg = {
  createNoteRequestBody: CreateNoteRequestBody;
};
export type GetNoteApiResponse = /** status 200 Ok */ NoteResponse;
export type GetNoteApiArg = {
  noteId: string;
};
export type UpdateNoteApiResponse = /** status 200 Ok */ NoteResponse;
export type UpdateNoteApiArg = {
  noteId: string;
  updateNoteRequestBody: UpdateNoteRequestBody;
};
export type DeleteNoteApiResponse = unknown;
export type DeleteNoteApiArg = {
  noteId: string;
};
export type GetEmployeeListApiResponse = /** status 200 Ok */ EmployeeListResponse;
export type GetEmployeeListApiArg = {
  at: string;
  count: number;
  offset: number;
  customerCreatedId?: string;
};
export type RegisterEmployeesApiResponse = /** status 200 Ok */ "ok";
export type RegisterEmployeesApiArg = {
  body: {
    file: Blob;
    effectiveFrom: string;
  };
};
export type GetEmployeeApiResponse = /** status 200 Ok */ EmployeeLifeLogResponse;
export type GetEmployeeApiArg = {
  employeeId: string;
};
export type GetEmployeeTenureApiResponse = /** status 200 Ok */ EmployeeTenureResponse;
export type GetEmployeeTenureApiArg = {
  employeeTenureId: string;
};
export type UpdateEmployeeTenureApiResponse = /** status 200 Ok */ "ok";
export type UpdateEmployeeTenureApiArg = {
  employeeTenureId: string;
  updateEmployeeTenureRequestBody: UpdateEmployeeTenureRequestBody;
};
export type DeleteTenureApiResponse = /** status 200 Ok */ "ok";
export type DeleteTenureApiArg = {
  employeeTenureId: string;
};
export type GetEmployeeAttributesApiResponse = /** status 200 Ok */ EmployeeAttributeResponse[];
export type GetEmployeeAttributesApiArg = {
  employeeTenureId: string;
  at: string;
};
export type UpsertEmployeeAttributesApiResponse = /** status 200 Ok */ "ok";
export type UpsertEmployeeAttributesApiArg = {
  employeeTenureId: string;
  addEmployeeAttributesBody: AddEmployeeAttributesBody;
};
export type DeleteEmployeeAttributesApiResponse = /** status 200 Ok */ "ok";
export type DeleteEmployeeAttributesApiArg = {
  employeeTenureId: string;
  at: string;
};
export type CustomerCreatedIdExistsApiResponse = /** status 200 Ok */ {
  exist: boolean;
};
export type CustomerCreatedIdExistsApiArg = {
  customerCreatedId: string;
};
export type AddEmployeeChangeSetApiResponse = /** status 200 Ok */ CreateEmployeeChangeSetResponse;
export type AddEmployeeChangeSetApiArg = {
  body: {
    file: Blob;
    effectiveFrom: string;
  };
};
export type GetEmployeeChangeSetSummaryApiResponse = /** status 200 Ok */ EmployeeChangeSetSummaryResponse;
export type GetEmployeeChangeSetSummaryApiArg = {
  changeSetId: string;
  count: number;
  offset: number;
};
export type ApplyEmployeeChangeSetApiResponse = /** status 200 Ok */ "ok";
export type ApplyEmployeeChangeSetApiArg = {
  changeSetId: string;
};
export type AddEmployeeAttributeDefinitionApiResponse = /** status 200 Ok */ "ok";
export type AddEmployeeAttributeDefinitionApiArg = {
  addEmployeeDefinitionRequestBody: AddEmployeeDefinitionRequestBody;
};
export type GetEmployeeReportV3ApiResponse = /** status 200 Ok */ EmployeeReportResponse;
export type GetEmployeeReportV3ApiArg = {
  periodId: number;
  axis: AxisLiteralOfTypeofEmployeeReportAxisFactory;
  from?: string;
  to?: string;
  sexes?: string[];
  departments?: string[];
  free1S?: string[];
  free2S?: string[];
  monthsOfServices?: string[];
  ages?: string[];
};
export type DownloadEmployeeCsvApiResponse = /** status 200 Ok */ string;
export type DownloadEmployeeCsvApiArg = {
  at: string;
};
export type CreateHrInterviewFileApiResponse = /** status 200 Ok */ HrInterviewFileResponse[];
export type CreateHrInterviewFileApiArg = {
  createHrInterviewFileRequestBody: CreateHrInterviewFileRequestBody;
};
export type FindHrInterviewFilesApiResponse = /** status 200 Ok */ HrInterviewFileListResponse;
export type FindHrInterviewFilesApiArg = {
  count: NaturalNumber;
  offset: NaturalNumber;
  linkWithInterview?: boolean[];
  uploadUserIds?: string[];
  createdAtStart?: string;
  createdAtEnd?: string;
};
export type GetHrInterviewFileApiResponse = /** status 200 Ok */ HrInterviewFileResponse;
export type GetHrInterviewFileApiArg = {
  hrInterviewFileId: string;
};
export type UpdateHrInterviewFileApiResponse = unknown;
export type UpdateHrInterviewFileApiArg = {
  hrInterviewFileId: string;
  updateHrInterviewFileRequestBody: UpdateHrInterviewFileRequestBody;
};
export type DeleteHrInterviewFileApiResponse = unknown;
export type DeleteHrInterviewFileApiArg = {
  hrInterviewFileId: string;
};
export type GetInterviewFileDownloadUrlApiResponse = /** status 200 Ok */ string;
export type GetInterviewFileDownloadUrlApiArg = {
  hrInterviewFileId: string;
};
export type GetHrInterviewFileUploaderUrlApiResponse = /** status 200 Ok */ GetHrInterviewFileUploaderUrlResponse;
export type GetHrInterviewFileUploaderUrlApiArg = {
  getHrInterviewFileUploaderUrlBody: GetHrInterviewFileUploaderUrlBody;
};
export type CompleteHrInterviewFileUploadApiResponse = unknown;
export type CompleteHrInterviewFileUploadApiArg = {
  uploadId: string;
  completeHrInterviewFileUploadBody: CompleteHrInterviewFileUploadBody;
};
export type GetHrInterviewQualityReportApiResponse = /** status 200 Ok */ HrInterviewQualityReportResponse;
export type GetHrInterviewQualityReportApiArg = {
  axis: HrInterviewQualityReportAxis;
  startDate?: string;
  endDate?: string;
  followUpIsRequired?: boolean[];
  interviewTypeIds?: string[];
  interviewerIds?: string[];
  evaluationItems?: string[];
};
export type CreateInterviewTypeApiResponse = /** status 200 Ok */ InterviewTypeResponse;
export type CreateInterviewTypeApiArg = {
  createInterviewTypeRequestBody: CreateInterviewTypeRequestBody;
};
export type RegisterInterviewTypeApiResponse = /** status 200 Ok */ "ok";
export type RegisterInterviewTypeApiArg = {
  registerInterviewTypesRequestBody: RegisterInterviewTypesRequestBody;
};
export type GetInterviewTypesApiResponse = /** status 200 Ok */ InterviewTypeListResponse;
export type GetInterviewTypesApiArg = {
  includeDeleted?: boolean;
};
export type GetInterviewTypeApiResponse = /** status 200 Ok */ InterviewTypeResponse;
export type GetInterviewTypeApiArg = {
  interviewTypeId: string;
};
export type GetInterviewFormatByTypeIdApiResponse = /** status 200 Ok */ InterviewFormatListResponse;
export type GetInterviewFormatByTypeIdApiArg = {
  interviewTypeId: string;
};
export type CreateInterviewFormatApiResponse = /** status 200 Ok */ InterviewFormatResponse;
export type CreateInterviewFormatApiArg = {
  interviewTypeId: string;
  createInterviewFormatRequestBody: CreateInterviewFormatRequestBody;
};
export type RegisterInterviewFormatsApiResponse = /** status 200 Ok */ "ok";
export type RegisterInterviewFormatsApiArg = {
  interviewTypeId: string;
  registerInterviewFormatsRequestBody: RegisterInterviewFormatsRequestBody;
};
export type UpdateInterviewFormatApiResponse = /** status 200 Ok */ "ok";
export type UpdateInterviewFormatApiArg = {
  interviewFormatId: string;
  updateInterviewFormatRequestBody: UpdateInterviewFormatRequestBody;
};
export type GetInterviewFormatContentsApiResponse = /** status 200 Ok */ InterviewFormatContentResponse[];
export type GetInterviewFormatContentsApiArg = {
  interviewFormatId: string;
};
export type RegisterInterviewFormatContentsApiResponse = /** status 200 Ok */ "ok";
export type RegisterInterviewFormatContentsApiArg = {
  interviewFormatId: string;
  registerInterviewFormatContentsRequestBody: RegisterInterviewFormatContentsRequestBody;
};
export type GetHrInterviewFeedbackQuestionListApiResponse =
  /** status 200 Ok */ HrInterviewFeedbackQuestionListResponse;
export type GetHrInterviewFeedbackQuestionListApiArg = void;
export type UpdateHrInterviewFeedbackQuestionsApiResponse = unknown;
export type UpdateHrInterviewFeedbackQuestionsApiArg = {
  updateHrInterviewFeedbackQuestionsRequestBody: UpdateHrInterviewFeedbackQuestionsRequestBody;
};
export type DeleteHrInterviewFeedbackQuestionApiResponse = unknown;
export type DeleteHrInterviewFeedbackQuestionApiArg = {
  hrInterviewFeedbackQuestionId: string;
};
export type CreateHrInterviewFeedbackAnswersApiResponse = unknown;
export type CreateHrInterviewFeedbackAnswersApiArg = {
  createHrInterviewFeedbackAnswersRequestBody: CreateHrInterviewFeedbackAnswersRequestBody;
};
export type GetHrInterviewsApiResponse = /** status 200 Ok */ HrInterviewListResponse;
export type GetHrInterviewsApiArg = {
  count: NaturalNumber;
  offset: NaturalNumber;
  interviewTypeIds?: string[];
  interviewStatuses?: HrInterviewAnalysisStatusRequest[];
  transcriptionStatuses?: NullHrInterviewTranscriptionStatusRequest[];
  interviewerIds?: string[];
  followUpIsRequired?: boolean[];
  segmentFilters?: string;
  interviewDateStart?: string;
  interviewDateEnd?: string;
  feedbackStatuses?: HrInterviewFeedbackStatus[];
};
export type CreateHrInterviewApiResponse = /** status 200 Ok */ HrInterviewResponse;
export type CreateHrInterviewApiArg = {
  createHrInterviewRequestBody: CreateHrInterviewRequestBody;
};
export type GetHrInterviewApiResponse = /** status 200 Ok */ HrInterviewDetailResponse;
export type GetHrInterviewApiArg = {
  hrInterviewId: string;
};
export type UpdateHrInterviewApiResponse = /** status 200 Ok */ HrInterviewResponse;
export type UpdateHrInterviewApiArg = {
  hrInterviewId: string;
  updateHrInterviewRequestBody: UpdateHrInterviewRequestBody;
};
export type DeleteHrInterviewApiResponse = /** status 200 Ok */ "ok";
export type DeleteHrInterviewApiArg = {
  hrInterviewId: string;
};
export type UpdateFileOfHrInterviewApiResponse = /** status 200 Ok */ "ok";
export type UpdateFileOfHrInterviewApiArg = {
  hrInterviewId: string;
  updateFileOfHrInterviewRequestBody: UpdateFileOfHrInterviewRequestBody;
};
export type AnalyzeHrInterviewApiResponse = /** status 200 Ok */ "ok";
export type AnalyzeHrInterviewApiArg = {
  hrInterviewId: string;
};
export type SummarizeHrInterviewApiResponse = /** status 200 Ok */ "ok";
export type SummarizeHrInterviewApiArg = {
  hrInterviewId: string;
  fromTranscription?: boolean;
};
export type SendHrInterviewFeedbackApiResponse = unknown;
export type SendHrInterviewFeedbackApiArg = {
  hrInterviewId: string;
  sendHrInterviewFeedbackRequestBody: SendHrInterviewFeedbackRequestBody;
};
export type GetHrInterviewReportApiResponse = /** status 200 Ok */ HrInterviewReportResponse;
export type GetHrInterviewReportApiArg = {
  axis: AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory;
  interviewTypeIds?: string[];
  followUpIsRequired?: boolean[];
  interviewDateFrom?: string;
  interviewDateTo?: string;
  subCategoryIds?: string[];
  segmentFilters?: string;
};
export type CreateInterviewFilesApiResponse = /** status 200 Ok */ "ok";
export type CreateInterviewFilesApiArg = {
  body: CreateInterviewFilesRequestBody[];
};
export type GetInterviewFilesApiResponse = /** status 200 Ok */ GetInterviewFilesResponse;
export type GetInterviewFilesApiArg = {
  count: NaturalNumber;
  offset: NaturalNumber;
};
export type RebuildDurationOfInterviewFilesApiResponse = /** status 200 Ok */ "ok";
export type RebuildDurationOfInterviewFilesApiArg = void;
export type GetInterviewFileApiResponse = /** status 200 Ok */ InterviewFileResponse;
export type GetInterviewFileApiArg = {
  interviewRecordingId: string;
};
export type UpdateInterviewFileApiResponse = /** status 200 Ok */ "ok";
export type UpdateInterviewFileApiArg = {
  interviewRecordingId: string;
  updateInterviewFileRequestBody: UpdateInterviewFileRequestBody;
};
export type DeleteInterviewFileApiResponse = unknown;
export type DeleteInterviewFileApiArg = {
  interviewRecordingId: string;
};
export type CreateInterviewAudioFileUploadUrlsApiResponse =
  /** status 200 Ok */ CreateInterviewAudioFileUploadUrlsResponse;
export type CreateInterviewAudioFileUploadUrlsApiArg = {
  createInterviewAudioFileUploadUrlsRequestBody: CreateInterviewAudioFileUploadUrlsRequestBody;
};
export type CompleteInterviewFileMultipartUploadApiResponse = /** status 200 Ok */ "ok";
export type CompleteInterviewFileMultipartUploadApiArg = {
  uploadId: string;
  completeInterviewAudioFileUploadRequestBody: CompleteInterviewAudioFileUploadRequestBody;
};
export type GetInterviewAudioFileUrlApiResponse = /** status 200 Ok */ GetInterviewAudioFileUrlResponse;
export type GetInterviewAudioFileUrlApiArg = {
  interviewFileId: string;
};
export type StartTranscriptionApiResponse = unknown;
export type StartTranscriptionApiArg = {
  interviewRecordingId: string;
};
export type GetTranscriptionApiResponse = /** status 200 Ok */ TranscriptionResponse;
export type GetTranscriptionApiArg = {
  interviewRecordingId: string;
};
export type UpdateTranscriptionApiResponse = /** status 200 Ok */ "ok";
export type UpdateTranscriptionApiArg = {
  interviewRecordingId: string;
  updateTranscriptionRequestBody: UpdateTranscriptionRequestBody;
};
export type SubmitTranscriptionApiResponse = /** status 200 Ok */ "ok";
export type SubmitTranscriptionApiArg = {
  interviewRecordingId: string;
};
export type CreateExEmployeeInterviewApiResponse = /** status 200 Ok */ ExEmployeeInterviewResponse;
export type CreateExEmployeeInterviewApiArg = {
  periodId: number;
  employeeId: string;
  createExEmployeeInterviewRequestBody: CreateExEmployeeInterviewRequestBody;
};
export type CreateExEmployeeInterviewByEmployeeTenureIdApiResponse = /** status 200 Ok */ ExEmployeeInterviewResponse;
export type CreateExEmployeeInterviewByEmployeeTenureIdApiArg = {
  employeeTenureId: string;
  createExEmployeeInterviewRequestBody: CreateExEmployeeInterviewRequestBody;
};
export type GetExEmployeeInterviewsApiResponse = /** status 200 Ok */ ExEmployeeInterviewListResponse;
export type GetExEmployeeInterviewsApiArg = {
  count: NaturalNumber;
  offset: NaturalNumber;
};
export type GetExEmployeeInterviewApiResponse = /** status 200 Ok */ InterviewDetailResponse;
export type GetExEmployeeInterviewApiArg = {
  exEmployeeInterviewId: string;
};
export type EditExEmployeeInterviewApiResponse = unknown;
export type EditExEmployeeInterviewApiArg = {
  exEmployeeInterviewId: string;
  editExEmployeeInterviewRequestBody: EditExEmployeeInterviewRequestBody;
};
export type DeleteExEmployeeInterviewApiResponse = /** status 200 Ok */ "ok";
export type DeleteExEmployeeInterviewApiArg = {
  exEmployeeInterviewId: string;
};
export type AnalyzeExEmployeeInterviewApiResponse = unknown;
export type AnalyzeExEmployeeInterviewApiArg = {
  exEmployeeInterviewId: string;
};
export type UnfixExEmployeeInterviewApiResponse = unknown;
export type UnfixExEmployeeInterviewApiArg = {
  exEmployeeInterviewId: string;
};
export type GetLatestComputationTaskByExEmployeeInterviewIdApiResponse = /** status 200 Ok */ ComputationTaskResponse;
export type GetLatestComputationTaskByExEmployeeInterviewIdApiArg = {
  exEmployeeInterviewId: string;
};
export type SummarizeExEmployeeInterviewApiResponse = unknown;
export type SummarizeExEmployeeInterviewApiArg = {
  exEmployeeInterviewId: string;
};
export type SubmitInterviewFeedbackApiResponse = unknown;
export type SubmitInterviewFeedbackApiArg = {
  interviewFileId: string;
  submitInterviewFeedbackRequest: SubmitInterviewFeedbackRequest;
};
export type GetInterviewFeedbacksApiResponse = /** status 200 Ok */ InterviewFeedbackListResponse;
export type GetInterviewFeedbacksApiArg = {
  userId: string;
  count: number;
  offset: number;
};
export type GetInterviewQualityReportListApiResponse = /** status 200 Ok */ InterviewQualityReportListResponse;
export type GetInterviewQualityReportListApiArg = void;
export type GetInterviewQualityReportApiResponse = /** status 200 Ok */ InterviewQualityReportResponse;
export type GetInterviewQualityReportApiArg = {
  userId: string;
};
export type GetInterviewReportApiResponse = /** status 200 Ok */ InterviewReportScoreResponse;
export type GetInterviewReportApiArg = {
  axis: AxisLiteralOfTypeofInterviewReportGraphAxisFactory;
  from?: string;
  to?: string;
  sexes?: string[];
  departments?: string[];
  free1S?: string[];
  free2S?: string[];
  monthsOfServices?: string[];
  ages?: string[];
  subCategoryIds?: string[];
};
export type GetInterviewKpiReportApiResponse = /** status 200 Ok */ InterviewKpiResponse;
export type GetInterviewKpiReportApiArg = {
  periodId: number;
  sexes?: string[];
  departments?: string[];
  free1S?: string[];
  free2S?: string[];
  monthsOfServices?: string[];
  ages?: string[];
};
export type GetDocumentsByExEmployeeInterviewIdApiResponse = /** status 200 Ok */ DocumentListResponse;
export type GetDocumentsByExEmployeeInterviewIdApiArg = {
  exEmployeeInterviewId: string;
};
export type GetExEmployeeReviewsApiResponse = /** status 200 Ok */ ExEmployeeReviewsSummaryResponse;
export type GetExEmployeeReviewsApiArg = {
  count: NaturalNumber;
  offset: NaturalNumber;
  categoryId?: string;
  subCategoryIds?: string;
  segmentKey?: SegmentKey;
  segmentValue?: string;
  endCreatedAt?: string;
};
export type SearchForInterviewDocumentsApiResponse = /** status 200 Ok */ SearchInterviewDocumentsResponse;
export type SearchForInterviewDocumentsApiArg = {
  count: NaturalNumber;
  offset: NaturalNumber;
  subCategoryId?: string[];
  sex?: string[];
  department?: string[];
  free1?: string[];
  free2?: string[];
  age?: string[];
  monthsOfService?: string[];
  retireDateBegin?: string;
  retireDateEnd?: string;
};
export type GetSurveyReportApiResponse = /** status 200 Ok */ SurveyReportResponse;
export type GetSurveyReportApiArg = {
  surveyId: string;
  axis: AxisLiteralOfTypeofSurveyReportAxisFactory;
  sexes?: string[];
  departments?: string[];
  free1S?: string[];
  free2S?: string[];
  monthsOfServices?: string[];
  ages?: string[];
  subCategoryIds?: string[];
};
export type RegisterSurveyQuestionsApiResponse = /** status 200 Ok */ "ok";
export type RegisterSurveyQuestionsApiArg = {
  registerSurveyQuestionRequestBody: RegisterSurveyQuestionRequestBody;
};
export type GetSurveyQuestionsApiResponse = /** status 200 Ok */ SurveyQuestionMoldResponse[];
export type GetSurveyQuestionsApiArg = void;
export type CreateUserApiResponse = /** status 200 Ok */ UserResponse;
export type CreateUserApiArg = {
  createUserRequestBody: CreateUserRequestBody;
};
export type GetUsersApiResponse = /** status 200 Ok */ UserListResponse;
export type GetUsersApiArg = void;
export type SyncCognitoUsersApiResponse = /** status 200 Ok */ SyncCognitoUsersResponse;
export type SyncCognitoUsersApiArg = {
  syncCognitoUsersRequestBody: SyncCognitoUsersRequestBody;
};
export type GetMyselfApiResponse = /** status 200 Ok */ UserRefResponse;
export type GetMyselfApiArg = void;
export type GetUserApiResponse = /** status 200 Ok */ UserDetailResponse;
export type GetUserApiArg = {
  /** 対象ユーザのID */
  userId: string;
};
export type UpdateUserApiResponse = unknown;
export type UpdateUserApiArg = {
  /** 対象ユーザのID */
  userId: string;
  updateUserRequestBody: UpdateUserRequestBody;
};
export type DeleteUserApiResponse = unknown;
export type DeleteUserApiArg = {
  /** 対象ユーザのID */
  userId: string;
};
export type ResendInvitationApiResponse = unknown;
export type ResendInvitationApiArg = {
  resendInvitationRequestBody: ResendInvitationRequestBody;
};
export type CreateUserGroupApiResponse = /** status 200 Ok */ UserGroupResponse;
export type CreateUserGroupApiArg = {
  createUserGroupRequest: CreateUserGroupRequest;
};
export type GetUserGroupsApiResponse = /** status 200 Ok */ UserGroupsResponse;
export type GetUserGroupsApiArg = {
  count: number;
  offset: number;
};
export type GetUserGroupApiResponse = /** status 200 Ok */ UserGroupResponse;
export type GetUserGroupApiArg = {
  userGroupId: string;
  /** このグループに属しているユーザを何件取得するか */
  count?: number;
  /** このグループに属しているユーザを取得するときのオフセット */
  offset?: number;
};
export type UpdateUserGroupApiResponse = /** status 200 Ok */ UserGroupResponse;
export type UpdateUserGroupApiArg = {
  userGroupId: string;
  partialCreateUserGroupRequest: PartialCreateUserGroupRequest;
};
export type DeleteUserGroupApiResponse = /** status 200 Ok */ "ok";
export type DeleteUserGroupApiArg = {
  userGroupId: string;
};
export type GetUserGroupsOfUserApiResponse = /** status 200 Ok */ UserGroupsResponse;
export type GetUserGroupsOfUserApiArg = {
  /** 対象ユーザのID */
  userId: string;
};
export type SetUsersToUserGroupApiResponse = /** status 200 Ok */ UserGroupResponse;
export type SetUsersToUserGroupApiArg = {
  userGroupId: string;
  userGroupMembersRequest: UserGroupMembersRequest;
};
export type GetInterviewFeedbackSettingsApiResponse = /** status 200 Ok */ InterviewFeedbackSettingsResponse;
export type GetInterviewFeedbackSettingsApiArg = void;
export type UpdateInterviewFeedbackSettingsApiResponse = /** status 200 Ok */ InterviewFeedbackSettingsResponse;
export type UpdateInterviewFeedbackSettingsApiArg = {
  updateInterviewFeedbackSettingsRequest: UpdateInterviewFeedbackSettingsRequest;
};
export type CreateCategoryApiResponse = /** status 200 作成されたカテゴリ */ CategoryResponse;
export type CreateCategoryApiArg = {
  /** カテゴリの情報 */
  createCategoryRequestBody: CreateCategoryRequestBody;
};
export type GetAllCategoriesApiResponse = /** status 200 カテゴリのリスト */ CategoryResponse[];
export type GetAllCategoriesApiArg = void;
export type GetCategoryApiResponse = /** status 200 カテゴリの情報 */ CategoryResponse;
export type GetCategoryApiArg = {
  /** カテゴリID */
  categoryId: string;
};
export type UpdateCategoryApiResponse = unknown;
export type UpdateCategoryApiArg = {
  /** カテゴリID */
  categoryId: string;
  /** 更新したいカテゴリの情報 */
  updateCategoryRequestBody: UpdateCategoryRequestBody;
};
export type DeleteCategoryApiResponse = /** status 200 削除が成功したかどうか */ boolean;
export type DeleteCategoryApiArg = {
  /** カテゴリID */
  categoryId: string;
};
export type CreateSubCategoryApiResponse = /** status 200 作成されたサブカテゴリ */ SubCategoryResponse;
export type CreateSubCategoryApiArg = {
  /** 属するカテゴリのID */
  categoryId: string;
  /** サブカテゴリの情報 */
  createSubCategoryRequestBody: CreateSubCategoryRequestBody;
};
export type GetSubCategoryByCategoryIdApiResponse = /** status 200 カテゴリの情報 */ SubCategoryResponse[];
export type GetSubCategoryByCategoryIdApiArg = {
  /** カテゴリID */
  categoryId: string;
};
export type GetAllSubCategoriesApiResponse = /** status 200 サブカテゴリのリスト */ SubCategoryResponse[];
export type GetAllSubCategoriesApiArg = void;
export type GetSubCategoryBySubCategoryIdApiResponse = /** status 200 取得されたサブカテゴリー */ SubCategoryResponse;
export type GetSubCategoryBySubCategoryIdApiArg = {
  /** サブカテゴリーのID */
  subCategoryId: string;
};
export type UpdateSubCategoryApiResponse = unknown;
export type UpdateSubCategoryApiArg = {
  /** サブカテゴリID */
  subCategoryId: string;
  /** サブカテゴリの情報 */
  updateSubCategoryRequestBody: UpdateSubCategoryRequestBody;
};
export type DeleteSubCategoryApiResponse = /** status 200 削除実行結果 (boolean) のPromise */ boolean;
export type DeleteSubCategoryApiArg = {
  /** サブカテゴリID */
  subCategoryId: string;
};
export type AddSubcategoriesToDocumentApiResponse = /** status 200 Ok */ "ok";
export type AddSubcategoriesToDocumentApiArg = {
  documentId: string;
  addSubCategoriesRequestBody: AddSubCategoriesRequestBody;
};
export type CreateRuleApiResponse = /** status 200 作成されたルール */ RuleWithSubCategoryResponse;
export type CreateRuleApiArg = {
  /** 属するカテゴリのID */
  categoryId: string;
  /** 作成するルールの情報 */
  createRuleRequestBodyString: CreateRuleRequestBodyString;
};
export type GetRulesByCategoryIdApiResponse = /** status 200 ルールのリスト */ RuleResponse[];
export type GetRulesByCategoryIdApiArg = {
  /** カテゴリID */
  categoryId: string;
};
export type DumpRulesApiResponse = /** status 200 Ok */ string;
export type DumpRulesApiArg = void;
export type UpdateRuleApiResponse = unknown;
export type UpdateRuleApiArg = {
  /** ルールID */
  ruleId: string;
  /** ルールの情報 */
  genericUpdateRuleRequestBodyString: GenericUpdateRuleRequestBodyString;
};
export type DeleteRuleApiResponse = /** status 200 Ok */ boolean;
export type DeleteRuleApiArg = {
  /** ルールID */
  ruleId: string;
};
export type GetLatestComputationTaskByRuleIdApiResponse = /** status 200 Ok */ ComputationTaskResponse;
export type GetLatestComputationTaskByRuleIdApiArg = {
  ruleId: string;
};
export type GetRuleSuggestionsByCategoryIdApiResponse = /** status 200 Ok */ RuleSuggestionResponse[];
export type GetRuleSuggestionsByCategoryIdApiArg = {
  categoryId: string;
  algorithm: any;
  query?: string;
  size?: number;
  significantTerms?: boolean;
  filterNonEffectiveRules?: boolean;
};
export type GetRuleSuggestionsBySubCategoryIdApiResponse = /** status 200 おすすめルール */ RuleSuggestionResponse[];
export type GetRuleSuggestionsBySubCategoryIdApiArg = {
  /** サブカテゴリID */
  subCategoryId: string;
  /** ルール推薦アルゴリズム */
  algorithm: any;
  /** ルールを絞り込むためのクエリ */
  query?: string;
  /** おすすめルールの件数 */
  size?: number;
  /** Significant Terms Aggregation を使用し
    このサブカテゴリに特有のルールほど上位に来るようにする。 */
  significantTerms?: boolean;
  filterNonEffectiveRules?: boolean;
};
export type SearchInterviewDocumentsByRawRuleApiResponse =
  /** status 200 クエリにマッチしたドキュメントのリスト */ DocumentResponse[];
export type SearchInterviewDocumentsByRawRuleApiArg = {
  /** Elasticsearch記法による検索クエリ */
  esQuery: string;
  /** 取得したい件数 */
  count: number;
  /** 先頭からのオフセット */
  offset: number;
};
export type StartLabelingSessionApiResponse =
  /** status 200 作成されたラベリングセッション */ StartLabelingSessionResponseBody;
export type StartLabelingSessionApiArg = {
  /** ラベリングセッションの情報 */
  genericStartLabelingSessionRequestBodyString: GenericStartLabelingSessionRequestBodyString;
};
export type GetLabelingResultApiResponse = /** status 200 ラベル付きドキュメントのリスト */ LabeledDocumentListResponse;
export type GetLabelingResultApiArg = {
  /** サブカテゴリーID */
  subCategoryId: string;
  /** 取得する個数 */
  count: number;
  /** オフセット */
  offset: number;
};
export type SetLabelApiResponse = /** status 200 Ok */ LabelType;
export type SetLabelApiArg = {
  /** ラベルID */
  labelId: number;
  /** ラベル情報 */
  setLabelRequestBody: SetLabelRequestBody;
};
export type GetLabelDocumentApiResponse = /** status 200 ドキュメントのリスト */ LabelingDocumentListResponse;
export type GetLabelDocumentApiArg = {
  /** ラベリングセッションID */
  sessionId: string;
  /** 取得する個数 */
  count: number;
  /** オフセット */
  offset: number;
};
export type GetStatsByRuleApiResponse = /** status 200 統計情報 */ RuleStatsResponse;
export type GetStatsByRuleApiArg = {
  /** ルールID */
  ruleId: string;
};
export type GetLabelStatsBySubCategoryIdApiResponse = /** status 200 Ok */ LabelStatsResponse;
export type GetLabelStatsBySubCategoryIdApiArg = {
  subCategoryId: string;
};
export type GetSettingApiResponse = /** status 200 Ok */ SettingResponse;
export type GetSettingApiArg = void;
export type UpdateEmailSettingsApiResponse = /** status 200 Ok */ SettingResponse;
export type UpdateEmailSettingsApiArg = {
  updateEmailSettingsBody: UpdateEmailSettingsBody;
};
export type UpdateGraphScaleSettingsApiResponse = /** status 200 Ok */ SettingResponse;
export type UpdateGraphScaleSettingsApiArg = {
  updateGraphScaleSettingsBody: UpdateGraphScaleSettingsBody;
};
export type RegisterSegmentNameApiResponse = /** status 200 Ok */ "ok";
export type RegisterSegmentNameApiArg = {
  segmentKey: InputSegmentKey;
  registerSegmentNameRequestBody: RegisterSegmentNameRequestBody;
};
export type GetSegmentDefinitionsApiResponse = /** status 200 Ok */ InputSegmentDefinitionResponse[];
export type GetSegmentDefinitionsApiArg = {
  segmentKey: InputSegmentKey;
};
export type CreateSegmentDefinitionApiResponse = /** status 200 Ok */ InputSegmentDefinitionResponse;
export type CreateSegmentDefinitionApiArg = {
  segmentKey: InputSegmentKey;
  createSegmentDefinitionRequestBody: CreateSegmentDefinitionRequestBody;
};
export type UpdateSegmentDefinitionApiResponse = /** status 200 Ok */ boolean;
export type UpdateSegmentDefinitionApiArg = {
  segmentKey: InputSegmentKey;
  inputSegmentDefinitionId: string;
  editSegmentDefinitionRequestBody: EditSegmentDefinitionRequestBody;
};
export type GetCalculatedSegmentConditionSettingApiResponse = /** status 200 Ok */ CalculatedSegmentSettingResponse;
export type GetCalculatedSegmentConditionSettingApiArg = {
  segmentKey: CalculatedSegmentKey;
};
export type RegisterCalculatedSegmentConditionApiResponse = /** status 200 Ok */ "ok";
export type RegisterCalculatedSegmentConditionApiArg = {
  segmentKey: CalculatedSegmentKey;
  registerCalculatedSegmentSettingRequestBody: RegisterCalculatedSegmentSettingRequestBody;
};
export type RestoreInterviewAttributeApiResponse = /** status 200 Ok */ string;
export type RestoreInterviewAttributeApiArg = {
  periodId: number;
  target: "all" | "unfixed";
};
export type GetPeriodsApiResponse = /** status 200 Ok */ PeriodResponse[];
export type GetPeriodsApiArg = void;
export type CreatePeriodApiResponse = /** status 200 Ok */ PeriodResponse;
export type CreatePeriodApiArg = {
  createPeriodRequestBody: CreatePeriodRequestBody;
};
export type GetLatestPeriodApiResponse = /** status 200 Ok */ PeriodResponse;
export type GetLatestPeriodApiArg = void;
export type CarryOverEmployeesIntoNextPeriodApiResponse = /** status 200 Ok */ "ok";
export type CarryOverEmployeesIntoNextPeriodApiArg = {
  carryOverEmployeesToNextPeriodRequestBody: CarryOverEmployeesToNextPeriodRequestBody;
};
export type CreateSurveyApiResponse = /** status 200 Ok */ SurveyResponse;
export type CreateSurveyApiArg = {
  createSurveyRequestBody: CreateSurveyRequestBody;
};
export type GetSurveysApiResponse = /** status 200 Ok */ SurveyListResponse;
export type GetSurveysApiArg = {
  count?: number;
  offset?: number;
};
export type GetSurveyApiResponse = /** status 200 Ok */ SurveyResponse;
export type GetSurveyApiArg = {
  surveyId: string;
};
export type UpdateSurveyApiResponse = /** status 200 Ok */ "ok";
export type UpdateSurveyApiArg = {
  surveyId: string;
  updateSurveyRequestBody: UpdateSurveyRequestBody;
};
export type DeleteSurveyApiResponse = /** status 200 Ok */ "ok";
export type DeleteSurveyApiArg = {
  surveyId: string;
};
export type GetLatestSurveyApiResponse = /** status 200 Ok */ SurveyResponse;
export type GetLatestSurveyApiArg = void;
export type StartSurveyApiResponse = /** status 200 Ok */ "ok";
export type StartSurveyApiArg = {
  surveyId: string;
};
export type UpdateSurveyStatusApiResponse = /** status 200 Ok */ "ok";
export type UpdateSurveyStatusApiArg = {
  surveyId: string;
  updateSurveyStatusRequestBody: UpdateSurveyStatusRequestBody;
};
export type GetLatestComputationTaskBySurveyIdApiResponse = /** status 200 Ok */ ComputationTaskResponse;
export type GetLatestComputationTaskBySurveyIdApiArg = {
  surveyId: string;
};
export type SendSampleSurveySheetApiResponse = unknown;
export type SendSampleSurveySheetApiArg = {
  surveyId: string;
  sendSampleSurveySheetRequestBody: SendSampleSurveySheetRequestBody;
};
export type SendRemindSurveySheetApiResponse = unknown;
export type SendRemindSurveySheetApiArg = {
  surveyId: string;
};
export type GetSurveyAnswersApiResponse = /** status 200 Ok */ SurveyAnswerResponse[];
export type GetSurveyAnswersApiArg = {
  surveyId: string;
  count?: number;
  offset?: number;
};
export type GetSurveySheetApiResponse = /** status 200 Ok */ SurveySheetResponse;
export type GetSurveySheetApiArg = {
  surveySheetId: string;
};
export type SubmitSurveyAnswerApiResponse = /** status 200 Ok */ "ok";
export type SubmitSurveyAnswerApiArg = {
  surveySheetId: string;
  submitSurveyAnswerRequestBody: SubmitSurveyAnswerRequestBody;
};
export type GetSharedSurveySheetApiResponse = /** status 200 Ok */ SharedSurveySheetResponse;
export type GetSharedSurveySheetApiArg = {
  surveyId: string;
};
export type SubmitSurveyAnswerWithIdApiResponse = /** status 200 Ok */ "ok";
export type SubmitSurveyAnswerWithIdApiArg = {
  surveyId: string;
  submitSurveyAnswerWithCustomerCreatedIdRequestBody: SubmitSurveyAnswerWithCustomerCreatedIdRequestBody;
};
export type ConfigurationResponse = {
  globalWebClientId: string;
  globalUserPoolId: string;
  webClientId: string;
  userPoolId: string;
};
export type HealthStatusResponse = {
  backend: true;
  es: boolean;
};
export type ComputationTaskStatuses = "UPDATING" | "DELETING" | "CREATING" | "PENDING" | "FAILED" | "SUCCESSFUL";
export type ComputationTaskResponse = {
  computationTaskId: string;
  ruleId?: string;
  subCategoryId?: string;
  categoryId?: string;
  computationTaskStatus?: ComputationTaskStatuses;
  processingEvent?: string;
  createdAt: string;
  updatedAt: string;
};
export type SegmentKey = "SEX" | "DEPARTMENT" | "FREE1" | "FREE2" | "MONTHS_OF_SERVICE" | "AGE";
export type TurnoverStatsWithForecastResponse = {
  turnoverForecast: number;
  turnoverCount: number;
  beginningEmployeeCount: number;
};
export type SegmentTurnoverStatsWithForecastResponse = {
  stats: TurnoverStatsWithForecastResponse;
  label: string;
};
export type NoteResponse = {
  noteId: string;
  segmentKey?: SegmentKey;
  segmentValue?: string;
  title: string;
  text: string;
  surveyId?: string;
  turnoverSummaries?: SegmentTurnoverStatsWithForecastResponse[];
  createdAt: string;
  updatedAt: string;
};
export type NoteListResponse = {
  notes: NoteResponse[];
  totalCount: number;
};
export type CreateNoteRequestBody = {
  turnoverSummaries?: SegmentTurnoverStatsWithForecastResponse[];
  surveyId?: string;
  text: string;
  title: string;
  segmentValue?: string;
  segmentKey?: SegmentKey;
};
export type UpdateNoteRequestBody = {
  text: string;
  title: string;
};
export type EmployeeAttributeResponse = {
  attributeKey: string;
  attributeValue: string;
  segmentValue: string;
  attributeDefinitionId: string;
  effectiveFrom: string;
  employeeAttributeId: string;
};
export type EmployeeResponse = {
  employeeId: string;
  employeeTenureId: string;
  customerCreatedId: string;
  at: string;
  name?: string;
  email?: string;
  dateOfBirth?: string;
  hireDate: string;
  retireDate?: string;
  attributes: EmployeeAttributeResponse[];
  hasInterviewAssign: boolean;
};
export type EmployeeListResponse = {
  employees: EmployeeResponse[];
  totalCount: number;
};
export type AttributeUnitResponse = {
  attributes: EmployeeAttributeResponse[];
  effectiveFrom: string;
};
export type EmployeeTenureLogResponse = {
  attributeUnits: AttributeUnitResponse[];
  retireDate?: string;
  hireDate: string;
  employeeTenureId: string;
};
export type EmployeeLifeLogResponse = {
  dateOfBirth?: string;
  email?: string;
  name?: string;
  tenures: EmployeeTenureLogResponse[];
  customerCreatedId: string;
  employeeId: string;
};
export type EmployeeTenureResponse = {
  dateOfBirth?: string;
  email?: string;
  name?: string;
  retireDate?: string;
  hireDate: string;
  customerCreatedId: string;
  employeeId: string;
  employeeTenureId: string;
};
export type UpdateEmployeeTenureRequestBody = {
  retireDate: string | null;
  hireDate: string;
};
export type InputSegmentKey = "SEX" | "DEPARTMENT" | "FREE1" | "FREE2";
export type AddEmployeeAttributesBody = {
  attributes: {
    attributeDefinitionId: string;
    attributeKey: InputSegmentKey;
  }[];
  at: string;
};
export type CreateEmployeeChangeSetResponse = {
  employeeChangeSetId: string;
};
export type NominalTypingEmployeeAttributeDefinitionString = {
  _type: "EmployeeAttributeDefinition";
} & string;
export type ChangeSetItemAttributeResponse = {
  attributeKey: string;
  attributeValue?: string;
  isValidValue: boolean;
  currentValue?: string;
  currentDefinitionId?: NominalTypingEmployeeAttributeDefinitionString;
};
export type EmployeeInfoChangeRequest = {
  currentValue?: string;
  requestValue?: string;
};
export type ChangeSetChangeType = "ERROR" | "INSERT" | "UPDATE" | "DELETE";
export type ChangeSetErrorType =
  | "DUPLICATE_CUSTOMER_CREATED_ID"
  | "MISSING_ATTRIBUTE_DEFINITION"
  | "INCONSISTENT_RETIRE_DATE_AND_EFFECTIVE_FROM"
  | "INVALID_HIRE_DATE"
  | "DUPLICATE_TENURE"
  | "NOT_ALLOWED_TO_CHANGE_RETIRE_DATE"
  | "UNKNOWN";
export type EmployeeChangeSetItem2Response = {
  customerCreatedId: string;
  name?: string;
  email?: string;
  dateOfBirth?: string;
  hireDate?: string;
  retireDate?: string;
  rowNumber: number;
  attributes: ChangeSetItemAttributeResponse[];
  infoChangeRequests: {
    retireDate: EmployeeInfoChangeRequest;
    hireDate: EmployeeInfoChangeRequest;
    dateOfBirth: EmployeeInfoChangeRequest;
    email: EmployeeInfoChangeRequest;
    name: EmployeeInfoChangeRequest;
  };
  changeType: ChangeSetChangeType;
  errorMessage?: ChangeSetErrorType;
};
export type EmployeeChangeSetSummaryResponse = {
  employeeChangeSetId: string;
  isValid: boolean;
  changeSets: EmployeeChangeSetItem2Response[];
  totalChangeSetCount: number;
  errorCount: number;
  updateCount: number;
  insertCount: number;
  effectiveFrom: string;
};
export type AddEmployeeDefinitionRequestBody = {
  segmentValue?: string;
  attributeValue: string;
  attributeKey: string;
};
export type EmployeeReportSeriesItem = {
  name: string;
  data: {
    previous: number;
    current: number;
  };
};
export type EmployeeReportResponse = {
  hired: EmployeeReportSeriesItem[];
  currentEmployee: EmployeeReportSeriesItem[];
  turnover: EmployeeReportSeriesItem[];
  beginningEmployee: EmployeeReportSeriesItem[];
  hiredRate: EmployeeReportSeriesItem[];
  turnoverRate: EmployeeReportSeriesItem[];
};
export type AxisLiteralOfTypeofEmployeeReportAxisFactory =
  | "SEX"
  | "DEPARTMENT"
  | "FREE1"
  | "FREE2"
  | "MONTHS_OF_SERVICE"
  | "AGE"
  | "month"
  | "NONE";
export type UserGroupRefResponse = {
  userGroupId: string;
  displayName: string;
};
export type EmployeeRefResponse = {
  employeeId: string;
  employeeTenureId: string;
  customerCreatedId: string;
  name?: string;
  email?: string;
  dateOfBirth?: string;
  hireDate: string;
  retireDate?: string;
};
export type UserRefResponse = {
  updatedAt: string;
  createdAt: string;
  isUnknownUser: boolean;
  isAdmin: boolean;
  givenName: string;
  surname: string;
  userAuthId?: string;
  userId: string;
};
export type InterviewFormatContentResponse = {
  formatContent: string;
  interviewFormatContentId: string;
};
export type InterviewFormatResponse = {
  contents: InterviewFormatContentResponse[];
  displayName: string;
  interviewFormatId: string;
};
export type InterviewTypeResponse = {
  displayName: string;
  interviewTypeId: string;
};
export type HrInterviewAnalysisStatus = "FAILED" | "ANALYZING" | "ANALYZED";
export type HrInterviewRefResponse = {
  createdAt: string;
  userGroup?: UserGroupRefResponse;
  lastFeedbackSentAt?: string;
  employee?: EmployeeRefResponse;
  followUpMemo: string;
  followUpIsRequired?: boolean;
  interviewer: UserRefResponse;
  employeeTenureId?: string;
  interviewDate?: string;
  interviewFormat: InterviewFormatResponse;
  interviewType: InterviewTypeResponse;
  interviewFileId?: string;
  analysisStatus?: HrInterviewAnalysisStatus;
  hrInterviewId: string;
};
export type HrInterviewTranscriptionStatus =
  | "FAILED"
  | "AI_TRANSCRIBING"
  | "BEAUTIFYING"
  | "OVERALL_SUMMARIZING"
  | "TOPIC_SUMMARIZING"
  | "COMPLETED";
export type HrInterviewFileResponse = {
  hrInterview?: HrInterviewRefResponse;
  transcriptionStatus?: HrInterviewTranscriptionStatus;
  createdAt: string;
  duration: number;
  interviewerId?: string;
  uploadUser?: UserRefResponse;
  userGroup?: UserGroupRefResponse;
  originalFileName: string;
  hrInterviewFileId: string;
};
export type CreateHrInterviewFileRequestBody = {
  files: {
    duration: number;
    interviewerId?: string;
    userGroupId?: string;
    originalFileName: string;
    s3FileName: string;
  }[];
};
export type HrInterviewFileListResponse = {
  files: HrInterviewFileResponse[];
  totalCount: number;
};
export type NaturalNumber = number;
export type UpdateHrInterviewFileRequestBody = {
  duration?: number;
  uploadUserId?: string | null;
  interviewerId?: string | null;
  userGroupId?: string | null;
};
export type GetHrInterviewFileUploaderUrlResponse = {
  partCount: number;
  chunkSize: number;
  filename: string;
  partUploadUrls: string[];
  multipartUploadId: string;
};
export type GetHrInterviewFileUploaderUrlBody = {
  /** の音声ファイルの容量（単位：byte） */
  fileSize: number;
};
export type CompleteHrInterviewFileUploadBody = {
  filename: string;
};
export type EvaluationItemAverageScoreResponse = {
  averageScore: number | null;
  evaluationItem: string;
};
export type HrInterviewScoreReportResponse = {
  data: {
    averageScoreList: EvaluationItemAverageScoreResponse[];
    totalAverageScore: number;
    label: string;
  }[];
};
export type HrInterviewCountReportResponse = {
  data: {
    completedInterviewCount: number;
    interviewCount: number;
    label: string;
  }[];
};
export type HrInterviewQualityReportResponse = {
  scoreReport: HrInterviewScoreReportResponse;
  rateReport: HrInterviewCountReportResponse;
};
export type HrInterviewQualityReportAxis = "NONE" | "INTERVIEWER" | "MONTHLY";
export type CreateInterviewTypeRequestBody = {
  order: number;
  displayName: string;
};
export type RegisterInterviewTypesRequestBody = {
  types: {
    order: number;
    displayName: string;
    interviewTypeId?: string;
  }[];
};
export type InterviewTypeListResponse = {
  interviewTypes: InterviewTypeResponse[];
};
export type InterviewFormatListResponse = {
  interviewFormats: InterviewFormatResponse[];
};
export type CreateInterviewFormatRequestBody = {
  contents: {
    order: number;
    formatContent: string;
  }[];
  order: number;
  displayName: string;
};
export type RegisterInterviewFormatsRequestBody = {
  formats: {
    order: number;
    displayName: string;
    interviewFormatId?: string;
  }[];
};
export type UpdateInterviewFormatRequestBody = {
  /** idがある場合は更新、ない場合は新規作成 */
  contents?: {
    order: number;
    formatContent: string;
    interviewFormatContentId?: string;
  }[];
  order?: number;
  displayName?: string;
};
export type RegisterInterviewFormatContentsRequestBody = {
  formatContents: {
    order: number;
    formatContent: string;
    /** idがある場合は更新、ない場合は新規作成 */
    interviewFormatContentId?: string;
  }[];
};
export type HrInterviewFeedbackQuestionResponse = {
  evaluationItem: string;
  question: string;
  hrInterviewFeedbackQuestionId: string;
};
export type HrInterviewFeedbackQuestionListResponse = {
  surveyTitle: string;
  surveyBody: string;
  evaluationItems: string[];
  questions: HrInterviewFeedbackQuestionResponse[];
};
export type UpdateHrInterviewFeedbackQuestionsRequestBody = {
  evaluationItem: string;
  question: string;
  hrInterviewFeedbackQuestionId?: string;
}[];
export type CreateHrInterviewFeedbackAnswersRequestBody = {
  score: number;
  hrInterviewId: string;
  hrInterviewFeedbackQuestionId: string;
}[];
export type HrInterviewFeedbackStatus = "UNSENT" | "ANSWERED" | "UNANSWERED";
export type InterviewAnswerResponse = {
  interviewFormatContentId: string;
  question: string;
  answer: string;
  interviewFormatAnswerId: string;
};
export type HrInterviewResponse = {
  feedbackStatus: HrInterviewFeedbackStatus;
  hrInterviewFile?: HrInterviewFileResponse;
  interviewAnswers: InterviewAnswerResponse[];
} & HrInterviewRefResponse;
export type HrInterviewListResponse = {
  totalCount: number;
  hrInterviews: HrInterviewResponse[];
};
export type HrInterviewAnalysisStatusRequest = HrInterviewAnalysisStatus | "null";
export type NullHrInterviewTranscriptionStatusRequest = HrInterviewTranscriptionStatus | "NULL";
export type CreateHrInterviewRequestBody = {
  userGroupId?: string;
  employeeTenureId?: string;
  hrInterviewFileId?: string;
  interviewDate?: string;
  interviewFormatId: string;
  interviewTypeId: string;
};
export type HrInterviewDocumentStat = {
  subCategoryIds: string[];
  documentId: string;
  offset: number;
  length: number;
  interviewFormatContentId: string;
};
export type AnalysisScoreResponse = {
  subCategoryId: string;
  displayName: string;
  score: number;
};
export type HrInterviewTranscriptionParagraphResponse = {
  updatedAt: string;
  createdAt: string;
  isBeautified: boolean;
  content: string;
  endTime: number;
  startTime: number;
  speakerIndex: number;
  hrInterviewTranscriptionId: string;
  hrInterviewTranscriptionParagraphId: string;
};
export type HrInterviewTranscriptionResponse = {
  updatedAt: string;
  createdAt: string;
  summary?: string;
  paragraphs: HrInterviewTranscriptionParagraphResponse[];
  status: HrInterviewTranscriptionStatus;
  hrInterviewFileId: string;
  hrInterviewTranscriptionId: string;
};
export type HrInterviewDetailResponse = HrInterviewResponse & {
  subCategoryDocumentMap: HrInterviewDocumentStat[];
  analysisScores: AnalysisScoreResponse[];
  summary?: string;
  transcriptions?: HrInterviewTranscriptionResponse;
  attributes?: EmployeeAttributeResponse[];
};
export type UpdateInterviewAnswersParam = {
  answer: string;
  interviewFormatAnswerId: string;
};
export type UpdateHrInterviewRequestBody = {
  userGroupId?: string | null;
  interviewFormatAnswers?: UpdateInterviewAnswersParam[];
  followUpMemo?: string;
  followUpIsRequired?: boolean;
  interviewDate?: string;
  interviewFormatId?: string;
  employeeTenureId?: string;
};
export type UpdateFileOfHrInterviewRequestBody = {
  hrInterviewFileId: string | null;
};
export type SendHrInterviewFeedbackRequestBody = {
  email: string;
};
export type AnalysisScore = {
  dimensions: {
    score: number;
    label: string;
  }[];
  axis: string;
};
export type HrInterviewReportResponse = {
  totalCount: number;
  documents: {
    tags: {
      label: string;
      key: string;
    }[];
    interviewFormatContentId: string;
    documentId: string;
    hrInterviewId: string;
    content: string;
  }[];
  analysisScores: AnalysisScore[];
};
export type AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory =
  | "SEX"
  | "DEPARTMENT"
  | "FREE1"
  | "FREE2"
  | "MONTHS_OF_SERVICE"
  | "AGE"
  | "NONE";
export type CreateInterviewFilesRequestBody = {
  duration: number;
  userGroupId?: string;
  tmpFilename: string;
  originalFilename: string;
  employeeTenureId?: string;
};
export type TranscriptionStatus = "initialized" | "transcribed" | "created" | "checking" | "checked" | "failed";
export type InterviewFileResponse = {
  createdAt: string;
  employee?: {
    employeeTenureId: string;
    name?: string;
    customerCreatedId: string;
  };
  transcriptionStatus?: TranscriptionStatus;
  exEmployeeInterviewId?: string;
  originalFileName: string;
  interviewer?: UserRefResponse;
  userGroup?: UserGroupRefResponse;
  interviewerId?: string;
  duration?: number;
  interviewRecordingId: string;
};
export type GetInterviewFilesResponse = {
  interviewFiles: InterviewFileResponse[];
  totalCount: number;
};
export type UpdateInterviewFileRequestBody = {
  userGroupId?: string | null;
  employeeTenureId?: string | null;
};
export type CreateInterviewAudioFileUploadUrlsResponse = {
  suggest?: EmployeeResponse;
  partUploadId: string;
  partUploadUrls: string[];
  tmpFilename: string;
};
export type CreateInterviewAudioFileUploadUrlsRequestBody = {
  fileSize: number;
  fileExtension?: string;
  partSize: number;
  fileBasename: string;
};
export type CompleteInterviewAudioFileUploadRequestBody = {
  tmpFilename: string;
};
export type GetInterviewAudioFileUrlResponse = {
  presignedUrl: string;
};
export type TranscriptionSentence = {
  endTime?: number;
  startTime?: number;
  speakerLabel: string;
  isBeautified?: boolean;
  enabled: boolean;
  text: string;
};
export type TranscriptionResult = {
  speakers: number;
  sentences: TranscriptionSentence[];
};
export type TranscriptionResponse = {
  text: string;
  status: TranscriptionStatus;
  results: TranscriptionResult;
  retiredSpeakerLabel: string;
  interviewFileId?: string;
  transcriptionId: string;
};
export type ExcludeTranscriptionStatusChecked = "initialized" | "transcribed" | "created" | "checking" | "failed";
export type UpdateTranscriptionRequestBody = {
  text?: string;
  status?: ExcludeTranscriptionStatusChecked;
  results?: TranscriptionResult;
  retiredSpeakerLabel?: string;
};
export type ExEmployeeInterviewResponse = {
  exEmployeeInterviewId: string;
  employee: EmployeeResponse;
  interviewRecordingId?: string;
  summary?: string;
  answer: string;
  task?: ComputationTaskResponse;
  createdAt: string;
  analyzedAt?: string;
  documentCount: number;
};
export type CreateExEmployeeInterviewRequestBody = {
  answer?: string;
};
export type ExEmployeeInterviewListResponse = {
  totalCount: number;
  interviews: ExEmployeeInterviewResponse[];
};
export type RetirementReasonScoreResponse = {
  score: number;
  subCategoryId: string;
  displayName: string;
};
export type RetirementReasonResponse = {
  values: RetirementReasonScoreResponse[];
};
export type InterviewDetailResponse = {
  /** 属性情報 */
  retiredEmployee: {
    birthday?: string;
    retireDate?: string;
    hireDate: string;
    free2?: string;
    free1?: string;
    department?: string;
    sex?: string;
    email?: string;
    name?: string;
    customerCreatedId: string;
    employeeId: string;
  };
  /** インタビューのサマリー */
  summary?: {
    summaryContent: string;
  };
  /** AIによる退職理由判定結果、割合。 */
  retirementReasons: RetirementReasonResponse;
  /** インタビュー内容 */
  interview?: {
    highlights: {
      /** 結び付けられたサブカテゴリID */
      subCategoryIds: string[];
      /** ハイライトされたセンテンス */
      content: string;
      /** 長さ */
      length: number;
      /** 本文中での開始位置 */
      offset: number;
    }[];
    /** 解析された日時 */
    analyzedAt?: string;
    /** インタビュー本文 */
    interviewContent: string;
  };
  /** 音声データ情報 */
  interviewFile?: {
    interviewFileId: string;
    interviewerName?: string;
    createdAt: string;
    originalFileName: string;
  };
};
export type EditExEmployeeInterviewRequestBody = {
  recordingId?: string;
  answer?: string;
};
export type SubmitInterviewFeedbackAnswer = {
  interviewFeedbackAnswerId: string;
  interviewFeedbackQuestionId: string;
};
export type SubmitInterviewFeedbackRequest = {
  comment: string;
  answers: SubmitInterviewFeedbackAnswer[];
};
export type InterviewFeedbackRating = "0" | "1" | "2";
export type InterviewFeedbackQuestionAnswer = {
  rating: InterviewFeedbackRating;
  answer: string;
  interviewFeedbackAnswerId: string;
  question: string;
  priority: number;
  interviewFeedbackQuestionId: string;
};
export type InterviewFeedbackResponse = {
  comment: string;
  questions: InterviewFeedbackQuestionAnswer[];
  qualityScore: number;
  interviewDate: string;
  originalFileName: string;
  interviewRecordingId: string;
};
export type InterviewFeedbackListResponse = {
  feedbacks: InterviewFeedbackResponse[];
};
export type InterviewQualityReportResponse = {
  feedbacks: InterviewFeedbackResponse[];
  averageQualityScore: number;
  interviewCount: number;
  userGroups: {
    displayName: string;
    userGroupId: string;
  }[];
  givenName: string;
  surname: string;
  userId: string;
};
export type InterviewQualityReportListResponse = {
  qualityReports: InterviewQualityReportResponse[];
};
export type InterviewReportScore = {
  score: number;
  displayName: string;
  subCategoryId: string;
};
export type InterviewReportScoreListByAxis = {
  scores: InterviewReportScore[];
  axisName: string;
};
export type InterviewReportScoreResponse = {
  totalCount: number;
  count: number;
  data: InterviewReportScoreListByAxis[];
};
export type AxisLiteralOfTypeofInterviewReportGraphAxisFactory =
  | "SEX"
  | "DEPARTMENT"
  | "FREE1"
  | "FREE2"
  | "MONTHS_OF_SERVICE"
  | "AGE"
  | "NONE"
  | "subCategoryId";
export type InterviewKpiData = {
  axis: string;
  turnoverCount: number;
  interviewCount: number;
  avgDuration: number;
};
export type InterviewKpiResponse = {
  data: InterviewKpiData[];
};
export type DocumentResponse = {
  documentId: string;
  content: string;
};
export type DocumentListResponse = {
  documents: DocumentResponse[];
};
export type ExEmployeeReviewResponse = {
  documentId: string;
  sentence: string;
};
export type ExEmployeeReviewsSummaryResponse = {
  reviews: ExEmployeeReviewResponse[];
  totalCount: number;
};
export type InterviewDocumentResponse = {
  documentId: string;
  sentence: string;
  offset?: number;
  length: number;
  categoryIds: string[];
  subCategoryIds: string[];
  exEmployeeInterviewId: string;
  employee: {
    retireDate?: string;
    monthsOfService?: string;
    age?: string;
    free2?: string;
    free1?: string;
    department?: string;
    sex?: string;
  };
};
export type SearchInterviewDocumentsResponse = {
  totalCount: number;
  interviewDocuments: InterviewDocumentResponse[];
};
export type SurveyReportResponse = {
  responseStats: {
    answeredCount: number;
    sheetCount: number;
    axis: string;
  }[];
  scoreStats: {
    stats: {
      count: number;
      score: number;
    }[];
    axis: string;
  }[];
};
export type AxisLiteralOfTypeofSurveyReportAxisFactory =
  | "SEX"
  | "DEPARTMENT"
  | "FREE1"
  | "FREE2"
  | "MONTHS_OF_SERVICE"
  | "AGE"
  | "NONE"
  | "subCategoryId";
export type CreateSurveyQuestionParams = {
  subCategoryId: string;
  questionText: string;
  priority: number;
};
export type RegisterSurveyQuestionRequestBody = {
  questions: CreateSurveyQuestionParams[];
};
export type SurveyQuestionMoldResponse = {
  surveyQuestionMoldId: string;
  subCategoryId: string;
  priority: number;
  questionText: string;
};
export type UserResponse = {
  updatedAt: string;
  createdAt: string;
  userGroups: UserGroupRefResponse[];
  isGlobalAuth: boolean;
  isAdmin: boolean;
  givenName: string;
  surname: string;
  userAuthId?: string;
  userId: string;
};
export type CreateUserRequestBody = {
  surname: string;
  givenName: string;
  isAdmin: boolean;
  email: string;
  phoneNumber: string;
  userGroupIds: string[];
};
export type UserListResponse = {
  totalCount: number;
  users: UserResponse[];
};
export type SyncCognitoUsersResponse = {
  insertedUsers: number;
};
export type SyncCognitoUsersRequestBody = {
  /** Admin として登録する auth IDs (Cognito の sub) */
  adminAuthIds: string[];
};
export type UserStatusType =
  | "UNKNOWN"
  | "ARCHIVED"
  | "COMPROMISED"
  | "CONFIRMED"
  | "EXTERNAL_PROVIDER"
  | "FORCE_CHANGE_PASSWORD"
  | "RESET_REQUIRED"
  | "UNCONFIRMED";
export type UserDetailResponse = {
  userStatus: UserStatusType;
  userGroups: UserGroupRefResponse[];
  phoneNumber: string;
  email: string;
  updatedAt: string;
  createdAt: string;
  isGlobalAuth: boolean;
  isAdmin: boolean;
  givenName: string;
  surname: string;
  userAuthId?: string;
  userId: string;
};
export type UpdateUserRequestBody = {
  surname?: string;
  givenName?: string;
  isAdmin?: boolean;
  email?: string;
  phoneNumber?: string;
  userGroupIds?: string[];
};
export type ResendInvitationRequestBody = {
  email: string;
};
export type UserGroupResponse = {
  userGroupId: string;
  displayName: string;
  totalUserCount?: number;
  users?: UserRefResponse[];
};
export type CreateUserGroupRequest = {
  displayName: string;
};
export type UserGroupsResponse = {
  totalCount: number;
  userGroups: UserGroupResponse[];
};
export type PartialCreateUserGroupRequest = {
  displayName?: string;
};
export type UserGroupMembersRequest = {
  userIds: string[];
};
export type InterviewFeedbackSettingsAnswer = {
  score: number;
  rating: InterviewFeedbackRating;
  answer: string;
  interviewFeedbackAnswerId: string;
};
export type InterviewFeedbackSettingsQuestion = {
  answerChoices: InterviewFeedbackSettingsAnswer[];
  question: string;
  priority: number;
  interviewFeedbackQuestionId: string;
};
export type InterviewFeedbackSettingsResponse = {
  questions: InterviewFeedbackSettingsQuestion[];
};
export type UpdateInterviewFeedbackSettingsAnswer = {
  /** 選択肢のテキスト */
  answer: string;
  /** 設問に対する評価。UIに対応 */
  rating: InterviewFeedbackRating;
  /** 実際のスコア */
  score: number;
};
export type UpdateInterviewFeedbackSettingsQuestion = {
  priority: number;
  question: string;
  answerChoices: UpdateInterviewFeedbackSettingsAnswer[];
};
export type UpdateInterviewFeedbackSettingsRequest = {
  questions: UpdateInterviewFeedbackSettingsQuestion[];
};
export type CategoryResponseMeta = {
  latestComputationTask?: ComputationTaskResponse;
};
export type CategoryResponse = {
  categoryId: string;
  defaultSubCategoryId?: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  meta?: CategoryResponseMeta;
};
export type CreateCategoryRequestBody = {
  displayName: string;
};
export type UpdateCategoryRequestBody = {
  displayName: string;
};
export type SubCategoryResponseMeta = {
  latestComputationTask?: ComputationTaskResponse;
};
export type SubCategoryResponse = {
  subCategoryId: string;
  categoryId: string;
  displayName: string;
  subCategoryDefinition: string;
  createdAt: string;
  updatedAt: string;
  meta?: SubCategoryResponseMeta;
};
export type CreateSubCategoryRequestBody = {
  subCategoryDefinition: string;
  displayName: string;
};
export type UpdateSubCategoryRequestBody = {
  subCategoryDefinition?: string;
  displayName?: string;
};
export type AddSubCategoriesRequestBody = {
  subCategories: string[];
};
export type RuleResponseMeta = {
  latestComputationTask?: ComputationTaskResponse;
};
export type RuleResponse = {
  ruleId: string;
  esQuery: string;
  subCategoryId: string;
  createdAt: string;
  updatedAt: string;
  meta?: RuleResponseMeta;
};
export type RuleWithSubCategoryResponse = {
  rule: RuleResponse;
  subCategory: SubCategoryResponse;
};
export type CreateRuleRequestBodyString = {
  subCategoryId?: string;
  esQuery: string;
};
export type GenericUpdateRuleRequestBodyString = {
  subCategoryId?: string;
  esQuery?: string;
};
export type RuleSuggestionResponse = {
  esQuery: string;
  metadata: {
    matchedDocumentsCount: number;
  };
};
export type StartLabelingSessionResponseBody = {
  totalCount: number;
  labelingSessionId?: string;
};
export type GenericStartLabelingSessionRequestBodyString = {
  offset: number;
  count: number;
  subCategoryId: string;
};
export type NominalTypingDocumentString = {
  _type: "Document";
} & string;
export type LabelType = "true" | "false" | "unknown";
export type LabeledDocumentResponse = {
  documentId: NominalTypingDocumentString;
  content: string;
  label: LabelType;
  predicted: boolean;
};
export type LabeledDocumentListResponse = {
  documents: LabeledDocumentResponse[];
  totalCount: number;
};
export type SetLabelRequestBody = {
  label: LabelType;
};
export type LabelingDocumentResponse = {
  documentId: string;
  content: string;
  label: LabelType;
  labelId: number;
};
export type LabelingDocumentListResponse = {
  documents: LabelingDocumentResponse[];
  isEnd: boolean;
};
export type RuleStatsResponse = {
  precision: number;
  hitCount: number;
};
export type LabelStatsResponse = {
  recall: number;
  precision: number;
  labeledCount: number;
};
export type RecordSegmentKeyString = {
  SEX?: string;
  DEPARTMENT?: string;
  FREE1?: string;
  FREE2?: string;
  MONTHS_OF_SERVICE?: string;
  AGE?: string;
};
export type SegmentNameSettingResponse = {
  nameMap: RecordSegmentKeyString;
};
export type InputSegmentDefinitionResponse = {
  inputSegmentDefinitionId: string;
  segmentKey: InputSegmentKey;
  segmentValue: string;
  mappedTo?: string;
  isDeprecated: boolean;
};
export type GlobalSettingResponse = {
  activeHrInterview: boolean;
  maxMinutesInKpiReport?: number;
  maxPercentageInKpiReport?: number;
  maxScaleValueInKpiReport?: number;
  maxScalePercentageInSurveyReport?: number;
  maxScaleValueInSurveyReport?: number;
  maxScalePercentageInEmployeeReport?: number;
  maxScaleCurrentEmployeeValueInEmployeeReport?: number;
  maxScaleValueInEmployeeReport?: number;
  maxScalePercentageOfTurnoverRate?: number;
  maxScaleValueOfTurnoverCount?: number;
  feedbackSurveyTitle?: string;
  feedbackSurveyBody?: string;
  feedbackMailSignature?: string;
  feedbackMailBody?: string;
  feedbackMailSubject?: string;
  feedbackMailSenderName?: string;
  emailSignature?: string;
  emailSenderName: string;
};
export type SettingResponse = {
  segmentName: SegmentNameSettingResponse;
  inputSegmentDefinitions: InputSegmentDefinitionResponse[];
  globalSetting: GlobalSettingResponse;
};
export type UpdateEmailSettingsBody = {
  feedbackSurveyTitle?: string;
  feedbackSurveyBody?: string;
  feedbackMailSignature?: string;
  feedbackMailBody?: string;
  feedbackMailSubject?: string;
  feedbackMailSenderName?: string;
  emailSignature?: string;
  emailSenderName?: string;
};
export type UpdateGraphScaleSettingsBody = {
  maxMinutesInKpiReport?: number | null;
  maxPercentageInKpiReport?: number | null;
  maxScaleValueInKpiReport?: number | null;
  maxScalePercentageInSurveyReport?: number | null;
  maxScaleValueInSurveyReport?: number | null;
  maxScalePercentageInEmployeeReport?: number | null;
  maxScaleCurrentEmployeeValueInEmployeeReport?: number | null;
  maxScaleValueInEmployeeReport?: number | null;
  maxScalePercentageOfTurnoverRate?: number | null;
  maxScaleValueOfTurnoverCount?: number | null;
};
export type RegisterSegmentNameRequestBody = {
  name: string;
};
export type CreateSegmentDefinitionRequestBody = {
  mappedTo?: string;
  segmentValue: string;
};
export type EditSegmentDefinitionRequestBody = {
  isDeprecated?: boolean;
  mappedTo?: string | null;
};
export type CalculatedSegmentKey = "MONTHS_OF_SERVICE" | "AGE";
export type CalculatedSegmentSettingResponse = {
  segmentKey: CalculatedSegmentKey;
  selectedCalculationStrategy: string;
};
export type RegisterCalculatedSegmentSettingRequestBody = {
  selectedCalculationStrategy: string;
};
export type PeriodResponse = {
  periodId: number;
  beginningDate: string;
  endDate: string;
  displayName?: string;
};
export type CreatePeriodRequestBody = {
  beginningDate: string;
  endDate?: string;
  displayName?: string;
};
export type CarryOverEmployeesToNextPeriodRequestBody = {
  beginningDate?: string;
  endDate?: string;
  displayName?: string;
};
export type SurveyStatus = "draft" | "running" | "paused" | "completed";
export type SurveyQuestionResponse = {
  surveyQuestionId: string;
  priority: number;
  questionText: string;
};
export type SurveyResponse = {
  surveyId: string;
  useQrcode: boolean;
  sendEmail: boolean;
  displayName: string;
  surveyTitle: string;
  surveyDescription: string;
  emailSubject?: string;
  emailBody?: string;
  targetEmployeeCount: number;
  collectedCount: number;
  emailSheetsCount: number;
  unansweredEmailSheetsCount: number;
  dueDateTime: string;
  status: SurveyStatus;
  questions: SurveyQuestionResponse[];
  startedAt?: string;
  createdAt: string;
  updatedAt: string;
};
export type CreateSurveyRequestBody = {
  useQrcode: boolean;
  sendEmail: boolean;
  displayName: string;
  surveyTitle: string;
  surveyDescription: string;
  emailSubject?: string;
  emailBody?: string;
  dueDateTime: string;
};
export type SurveyListResponse = {
  surveys: SurveyResponse[];
  totalCount: number;
};
export type UpdateSurveyRequestBody = {
  useQrcode?: boolean;
  sendEmail?: boolean;
  displayName?: string;
  surveyTitle?: string;
  surveyDescription?: string;
  emailSubject?: string | null;
  emailBody?: string | null;
  dueDateTime?: string;
};
export type UpdateSurveyStatusRequestBody = {
  status: "running" | "paused" | "completed";
};
export type SendSampleSurveySheetRequestBody = {
  emailAddress: string;
};
export type SurveySheetStatus = "created" | "sent" | "answered";
export type SurveyAnswerResponse = {
  employeeId: string;
  customerCreatedId: string;
  surveyId: string;
  surveySheetId: string;
  status: SurveySheetStatus;
};
export type SurveySheetResponse = {
  surveySheetId: string;
  displayName: string;
  dueDateTime: string;
  emailSubject?: string;
  emailBody?: string;
  title: string;
  description: string;
  questions: SurveyQuestionResponse[];
};
export type Answer = {
  value: number;
  surveyQuestionId: string;
};
export type SubmitSurveyAnswerRequestBody = {
  answers: Answer[];
};
export type SharedSurveySheetResponse = {
  displayName: string;
  dueDateTime: string;
  emailSubject?: string;
  emailBody?: string;
  title: string;
  description: string;
  questions: SurveyQuestionResponse[];
};
export type SubmitSurveyAnswerWithCustomerCreatedIdRequestBody = {
  answers: Answer[];
  customerCreatedId: string;
};
