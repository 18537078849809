/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeRefResponse,
    EmployeeRefResponseFromJSON,
    EmployeeRefResponseFromJSONTyped,
    EmployeeRefResponseToJSON,
    HrInterviewAnalysisStatus,
    HrInterviewAnalysisStatusFromJSON,
    HrInterviewAnalysisStatusFromJSONTyped,
    HrInterviewAnalysisStatusToJSON,
    InterviewFormatResponse,
    InterviewFormatResponseFromJSON,
    InterviewFormatResponseFromJSONTyped,
    InterviewFormatResponseToJSON,
    InterviewTypeResponse,
    InterviewTypeResponseFromJSON,
    InterviewTypeResponseFromJSONTyped,
    InterviewTypeResponseToJSON,
    UserGroupRefResponse,
    UserGroupRefResponseFromJSON,
    UserGroupRefResponseFromJSONTyped,
    UserGroupRefResponseToJSON,
    UserRefResponse,
    UserRefResponseFromJSON,
    UserRefResponseFromJSONTyped,
    UserRefResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewRefResponse
 */
export interface HrInterviewRefResponse {
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewRefResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {UserGroupRefResponse}
     * @memberof HrInterviewRefResponse
     */
    userGroup?: UserGroupRefResponse;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewRefResponse
     */
    lastFeedbackSentAt?: Date;
    /**
     * 
     * @type {EmployeeRefResponse}
     * @memberof HrInterviewRefResponse
     */
    employee?: EmployeeRefResponse;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewRefResponse
     */
    followUpMemo: string;
    /**
     * 
     * @type {boolean}
     * @memberof HrInterviewRefResponse
     */
    followUpIsRequired?: boolean;
    /**
     * 
     * @type {UserRefResponse}
     * @memberof HrInterviewRefResponse
     */
    interviewer: UserRefResponse;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewRefResponse
     */
    employeeTenureId?: string;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewRefResponse
     */
    interviewDate?: Date;
    /**
     * 
     * @type {InterviewFormatResponse}
     * @memberof HrInterviewRefResponse
     */
    interviewFormat: InterviewFormatResponse;
    /**
     * 
     * @type {InterviewTypeResponse}
     * @memberof HrInterviewRefResponse
     */
    interviewType: InterviewTypeResponse;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewRefResponse
     */
    interviewFileId?: string;
    /**
     * 
     * @type {HrInterviewAnalysisStatus}
     * @memberof HrInterviewRefResponse
     */
    analysisStatus?: HrInterviewAnalysisStatus;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewRefResponse
     */
    hrInterviewId: string;
}

export function HrInterviewRefResponseFromJSON(json: any): HrInterviewRefResponse {
    return HrInterviewRefResponseFromJSONTyped(json, false);
}

export function HrInterviewRefResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewRefResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'userGroup': !exists(json, 'userGroup') ? undefined : UserGroupRefResponseFromJSON(json['userGroup']),
        'lastFeedbackSentAt': !exists(json, 'lastFeedbackSentAt') ? undefined : (new Date(json['lastFeedbackSentAt'])),
        'employee': !exists(json, 'employee') ? undefined : EmployeeRefResponseFromJSON(json['employee']),
        'followUpMemo': json['followUpMemo'],
        'followUpIsRequired': !exists(json, 'followUpIsRequired') ? undefined : json['followUpIsRequired'],
        'interviewer': UserRefResponseFromJSON(json['interviewer']),
        'employeeTenureId': !exists(json, 'employeeTenureId') ? undefined : json['employeeTenureId'],
        'interviewDate': !exists(json, 'interviewDate') ? undefined : (new Date(json['interviewDate'])),
        'interviewFormat': InterviewFormatResponseFromJSON(json['interviewFormat']),
        'interviewType': InterviewTypeResponseFromJSON(json['interviewType']),
        'interviewFileId': !exists(json, 'interviewFileId') ? undefined : json['interviewFileId'],
        'analysisStatus': !exists(json, 'analysisStatus') ? undefined : HrInterviewAnalysisStatusFromJSON(json['analysisStatus']),
        'hrInterviewId': json['hrInterviewId'],
    };
}

export function HrInterviewRefResponseToJSON(value?: HrInterviewRefResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'userGroup': UserGroupRefResponseToJSON(value.userGroup),
        'lastFeedbackSentAt': value.lastFeedbackSentAt === undefined ? undefined : (value.lastFeedbackSentAt.toISOString()),
        'employee': EmployeeRefResponseToJSON(value.employee),
        'followUpMemo': value.followUpMemo,
        'followUpIsRequired': value.followUpIsRequired,
        'interviewer': UserRefResponseToJSON(value.interviewer),
        'employeeTenureId': value.employeeTenureId,
        'interviewDate': value.interviewDate === undefined ? undefined : (value.interviewDate.toISOString()),
        'interviewFormat': InterviewFormatResponseToJSON(value.interviewFormat),
        'interviewType': InterviewTypeResponseToJSON(value.interviewType),
        'interviewFileId': value.interviewFileId,
        'analysisStatus': HrInterviewAnalysisStatusToJSON(value.analysisStatus),
        'hrInterviewId': value.hrInterviewId,
    };
}


