import React, { useCallback, useContext } from "react";

import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";

import { CurrentCognitoUserContext } from "..";
import { MINIMUM_PASSWORD_LENGTH, VALID_PASSWORD_REG } from "../../../../../config/PasswordPolicy";
import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { PasswordInput } from "../../../../molecules/PasswordInput";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { LoginButtonWrapper } from "./common/LoginButtonWrapper";
import { LoginErrorMessage } from "./common/LoginErrorMessage";
import { LoginFormsWrapper } from "./common/LoginFormsWrapper";
import { LoginInputWrapper } from "./common/LoginInputWrapper";
import { LoginLogoTitle } from "./common/LoginLogoTitle";
import { LoginPageWrapper } from "./common/LoginPageWrapper";
import { PageTransitionText } from "./common/PageTransitionText";

import { UserStateBeforeSignIn } from ".";

type ResetPasswordFormType = {
  code: string;
  password: string;
  reenteredPassword: string;
};

interface Props {
  email: string;
  setUserStateBeforeSignIn: (userState: UserStateBeforeSignIn) => void;
}

export const ConfirmSignUp: React.FC<Props> = ({ email, setUserStateBeforeSignIn }) => {
  const theme = useAmeTheme();

  const { setCurrentCognitoUser } = useContext(CurrentCognitoUserContext);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      code: "",
      password: "",
      reenteredPassword: "",
    },
  });
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const setUserStateToSignIn = useCallback(() => {
    setUserStateBeforeSignIn("sign-in");
  }, [setUserStateBeforeSignIn]);

  const onSubmit = async (data: ResetPasswordFormType) => {
    try {
      await Auth.forgotPasswordSubmit(email, data.code, data.password);
      const cognitoUser = await Auth.signIn(email, data.password);
      setCurrentCognitoUser(cognitoUser);
      setUserStateToSignIn();
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        enqueueErrorSnackbar({ title: "ユーザが見つかりません。" });
        return;
      }
      if (err.code === "NotAuthorizedException") {
        enqueueErrorSnackbar({ title: "メールアドレスかパスワードが違います。" });
        return;
      }
      if (err.code === "CodeMismatchException") {
        enqueueErrorSnackbar({ title: "検証のコードが正しくありません。" });
        return;
      }
      if (err.code === "ExpiredCodeException") {
        enqueueErrorSnackbar({
          title: "不正なワンタイムコードを使用しています。",
          message: "もう一度、ワンタイムコードを生成してください。",
        });
        return;
      }
      enqueueErrorSnackbar();
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <LoginPageWrapper>
      <LoginLogoTitle />
      <AmeBox
        sx={{
          padding: "40px 24px",
          border: `1px solid ${theme.common.border.gray40}`,
          background: theme.common.background.white,
          borderRadius: "4px",
        }}
      >
        <AmeBox sx={{ width: "100%", paddingBottom: "16px", typography: theme.typography.h6 }}>
          パスワードをもう一度設定してください。
        </AmeBox>

        <LoginFormsWrapper onSubmit={handleSubmit(onSubmit)}>
          <LoginInputWrapper>
            <AmeBox sx={{ margin: "8px 0", color: theme.common.text.black, textAlign: "left" }}>
              ワンタイムコード
            </AmeBox>
            <Controller
              name={"code"}
              control={control}
              rules={{
                required: "ワンタイムコードを入力してください。",
              }}
              render={({ field, fieldState }) => (
                <AmeInput
                  {...field}
                  placeholder={"ワンタイムコード"}
                  error={fieldState.error !== undefined}
                  fullWidth
                />
              )}
            />
            <LoginErrorMessage>{Object.values(errors.code?.types || {}).join("")}</LoginErrorMessage>
          </LoginInputWrapper>
          <LoginInputWrapper>
            <AmeBox sx={{ margin: "8px 0", color: theme.common.text.black, textAlign: "left" }}>
              新しいパスワード
            </AmeBox>
            <Controller
              name={"password"}
              control={control}
              rules={{
                required: "パスワードを入力してください。",
                pattern: {
                  value: VALID_PASSWORD_REG,
                  message: "パスワードは、大小英字、数字、特殊記号をそれぞれ１つ以上使う必要があります。",
                },
                minLength: {
                  value: MINIMUM_PASSWORD_LENGTH,
                  message: "パスワードは８文字以上である必要があります。",
                },
              }}
              render={({ field, fieldState }) => (
                <PasswordInput
                  field={field}
                  placeholder={"新しいパスワード"}
                  errors={errors.password?.types}
                  error={fieldState.error !== undefined}
                />
              )}
            />
            <AmeBox
              sx={{
                margin: "8px 0",
                color: theme.common.text.black,
                textAlign: "left",
                typography: theme.typography.body2,
              }}
            >
              6~20文字で入力してください
            </AmeBox>
          </LoginInputWrapper>
          <LoginInputWrapper>
            <AmeBox sx={{ margin: "8px 0", color: theme.common.text.black, textAlign: "left" }}>
              新しいパスワード(確認用)
            </AmeBox>
            <Controller
              name={"reenteredPassword"}
              control={control}
              rules={{
                required: "パスワードを再入力してください。",
                validate: (input) =>
                  input === getValues("password") || "パスワードと再入力されたパスワードが一致していません。",
              }}
              render={({ field, fieldState }) => (
                <PasswordInput
                  field={field}
                  placeholder={"パスワード"}
                  errors={errors.reenteredPassword?.types}
                  error={fieldState.error !== undefined}
                />
              )}
            />
          </LoginInputWrapper>
          <LoginButtonWrapper>
            <AmeButton type="submit" fullWidth>
              設定
            </AmeButton>
          </LoginButtonWrapper>
        </LoginFormsWrapper>
        <PageTransitionText onClick={setUserStateToSignIn}>サインインへ戻る</PageTransitionText>
      </AmeBox>
    </LoginPageWrapper>
  );
};
