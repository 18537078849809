import React from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import type { UserGroup } from "../../../../../../models/UserGroup";
import { useCreateUserMutation } from "../../../../../../store/hooks/users";
import { AmeFormButtonsLayout } from "../../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { UserEditor } from "../../../../../organisms/settings/members/users/userId/edit/UserEditor";
import type { UserEditFormType } from "../../../../../organisms/settings/members/users/userId/edit/UserEditor";

type Props = {
  userGroups: UserGroup[];
};

export const UserCreateForm: React.FC<Props> = (props) => {
  const { register, control, formState, handleSubmit } = useForm<UserEditFormType>({
    mode: "onChange",
  });
  const createUser = useCreateUserMutation();
  const navigate = useNavigate({});
  const onCancel = () => {
    void navigate({ to: "/hidden-pages/members/users" });
  };
  return (
    <>
      <UserEditor register={register} formState={formState} control={control} userGroups={props.userGroups} />
      <AmeFormButtonsLayout
        onPrimary={handleSubmit(async (data) => {
          await createUser({
            createUserRequestBody: {
              surname: data.surname,
              givenName: data.givenName,
              isAdmin: data.isAdmin == "true" ? true : false,
              email: data.email,
              phoneNumber: "+81" + data.phoneNumber.slice(1),
              userGroupIds: data.userGroupIds.map((userGroup) => {
                return userGroup.userGroupId;
              }),
            },
          });
          void navigate({ to: "/hidden-pages/members/users" });
        })}
        onSecondary={onCancel}
      />
    </>
  );
};
