import React, { useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { RuleSuggestionModel } from "../../../../../../store/hooks/ruleSuggestion";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { SuggestionChip } from "./SuggestionChip";

interface Props {
  suggestions: RuleSuggestionModel[];
}
export const SuggestionArea: React.FC<Props> = ({ suggestions }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (suggestions.length > 0) {
    return (
      <React.Fragment>
        <AmeBox
          sx={{
            position: "relative",
            display: "flex",
            gap: "16px",
            flexWrap: "wrap",
            maxHeight: isExpanded ? "100%" : "50vh",
            overflow: "hidden",
            padding: "12px 0",
          }}
        >
          {suggestions.map((suggestion) => (
            <SuggestionChip
              key={suggestion.esQuery}
              matchedDocumentCount={suggestion.metadata.matchedDocumentsCount}
              esQuery={suggestion.esQuery}
            />
          ))}
        </AmeBox>
        <AmeBox
          sx={{
            margin: "8px 0",
            position: "relative",
            textAlign: "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-20px",
              bottom: "100%",
              right: 0,
              left: 0,
              background: "linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)",
            },
          }}
        >
          <AmeButton
            variant="text"
            endIcon={
              isExpanded ? (
                <ExpandLess
                  sx={{
                    "&.MuiSvgIcon-fontSizeMedium": {
                      fontSize: "28px",
                    },
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    "&.MuiSvgIcon-fontSizeMedium": {
                      fontSize: "28px",
                    },
                  }}
                />
              )
            }
            onClick={() => setIsExpanded((value) => !value)}
          >
            {isExpanded ? "閉じる" : "全て表示"}
          </AmeButton>
        </AmeBox>
      </React.Fragment>
    );
  }

  return null;
};
