/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeReportSeriesItem,
    EmployeeReportSeriesItemFromJSON,
    EmployeeReportSeriesItemFromJSONTyped,
    EmployeeReportSeriesItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeReportResponse
 */
export interface EmployeeReportResponse {
    /**
     * 
     * @type {Array<EmployeeReportSeriesItem>}
     * @memberof EmployeeReportResponse
     */
    hired: Array<EmployeeReportSeriesItem>;
    /**
     * 
     * @type {Array<EmployeeReportSeriesItem>}
     * @memberof EmployeeReportResponse
     */
    currentEmployee: Array<EmployeeReportSeriesItem>;
    /**
     * 
     * @type {Array<EmployeeReportSeriesItem>}
     * @memberof EmployeeReportResponse
     */
    turnover: Array<EmployeeReportSeriesItem>;
    /**
     * 
     * @type {Array<EmployeeReportSeriesItem>}
     * @memberof EmployeeReportResponse
     */
    beginningEmployee: Array<EmployeeReportSeriesItem>;
    /**
     * 
     * @type {Array<EmployeeReportSeriesItem>}
     * @memberof EmployeeReportResponse
     */
    hiredRate: Array<EmployeeReportSeriesItem>;
    /**
     * 
     * @type {Array<EmployeeReportSeriesItem>}
     * @memberof EmployeeReportResponse
     */
    turnoverRate: Array<EmployeeReportSeriesItem>;
}

export function EmployeeReportResponseFromJSON(json: any): EmployeeReportResponse {
    return EmployeeReportResponseFromJSONTyped(json, false);
}

export function EmployeeReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hired': ((json['hired'] as Array<any>).map(EmployeeReportSeriesItemFromJSON)),
        'currentEmployee': ((json['currentEmployee'] as Array<any>).map(EmployeeReportSeriesItemFromJSON)),
        'turnover': ((json['turnover'] as Array<any>).map(EmployeeReportSeriesItemFromJSON)),
        'beginningEmployee': ((json['beginningEmployee'] as Array<any>).map(EmployeeReportSeriesItemFromJSON)),
        'hiredRate': ((json['hiredRate'] as Array<any>).map(EmployeeReportSeriesItemFromJSON)),
        'turnoverRate': ((json['turnoverRate'] as Array<any>).map(EmployeeReportSeriesItemFromJSON)),
    };
}

export function EmployeeReportResponseToJSON(value?: EmployeeReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hired': ((value.hired as Array<any>).map(EmployeeReportSeriesItemToJSON)),
        'currentEmployee': ((value.currentEmployee as Array<any>).map(EmployeeReportSeriesItemToJSON)),
        'turnover': ((value.turnover as Array<any>).map(EmployeeReportSeriesItemToJSON)),
        'beginningEmployee': ((value.beginningEmployee as Array<any>).map(EmployeeReportSeriesItemToJSON)),
        'hiredRate': ((value.hiredRate as Array<any>).map(EmployeeReportSeriesItemToJSON)),
        'turnoverRate': ((value.turnoverRate as Array<any>).map(EmployeeReportSeriesItemToJSON)),
    };
}


