import React, { Children, cloneElement, isValidElement, useMemo } from "react";

import { Box } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

import { ChoosableListItemSize } from "./ChoosableListItem";

interface Props<Value> {
  size?: ChoosableListItemSize;
  children: React.ReactNode;
  onSelect?: (value: Value | undefined) => void;
  forTable?: boolean;
  selectedValue?: Value;
  forSelect?: boolean;
}

export const ChoosableList = <Value,>(props: Props<Value>) => {
  const theme = useAmeTheme();
  const children = useMemo(
    () =>
      Children.map(props.children, (child) => {
        if (isValidElement(child))
          return cloneElement(child, {
            onSelect: props.onSelect,
            size: props.size,
            forTable: props.forTable,
            selectedValue: props.selectedValue,
            forSelect: props.forSelect,
            ...child.props,
          });
        return child;
      }),
    [props],
  );
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        "& > * + *": { borderTop: `1px solid ${theme.common.border.gray40}` },
      }}
    >
      {children}
    </Box>
  );
};
