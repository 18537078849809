import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { AmeButton } from "../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";

export const HiddenPages: React.FC = () => {
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[]} currentTitle="隠しページ一覧" />
      <PageLayoutBody type="wide">
        <AmeButton to={"/settings/surveys/questions"}>質問設定</AmeButton>
        <AmeButton to={"/settings/local-settings"}>ローカル設定</AmeButton>
        <AmeButton to={"/settings/rebuild"}>rebuild</AmeButton>
        <AmeButton to={"/hidden-pages/members/users"}>メンバー一覧</AmeButton>
        <AmeButton to={"/hidden-pages/transcriptions"}>文字起こしリスト</AmeButton>
        <AmeButton to={"/hidden-pages/interview-feedbacks"}>インタビューFB</AmeButton>
        <AmeButton to={"/hidden-pages/feedback-surveys"}>面談後アンケート設定</AmeButton>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/")({
  component: HiddenPages,
});
