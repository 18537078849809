/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GlobalSettingResponse
 */
export interface GlobalSettingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GlobalSettingResponse
     */
    activeHrInterview: boolean;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxMinutesInKpiReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxPercentageInKpiReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScaleValueInKpiReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScalePercentageInSurveyReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScaleValueInSurveyReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScalePercentageInEmployeeReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScaleCurrentEmployeeValueInEmployeeReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScaleValueInEmployeeReport?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScalePercentageOfTurnoverRate?: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingResponse
     */
    maxScaleValueOfTurnoverCount?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    feedbackSurveyTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    feedbackSurveyBody?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    feedbackMailSignature?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    feedbackMailBody?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    feedbackMailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    feedbackMailSenderName?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    emailSignature?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingResponse
     */
    emailSenderName: string;
}

export function GlobalSettingResponseFromJSON(json: any): GlobalSettingResponse {
    return GlobalSettingResponseFromJSONTyped(json, false);
}

export function GlobalSettingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalSettingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeHrInterview': json['activeHrInterview'],
        'maxMinutesInKpiReport': !exists(json, 'maxMinutesInKpiReport') ? undefined : json['maxMinutesInKpiReport'],
        'maxPercentageInKpiReport': !exists(json, 'maxPercentageInKpiReport') ? undefined : json['maxPercentageInKpiReport'],
        'maxScaleValueInKpiReport': !exists(json, 'maxScaleValueInKpiReport') ? undefined : json['maxScaleValueInKpiReport'],
        'maxScalePercentageInSurveyReport': !exists(json, 'maxScalePercentageInSurveyReport') ? undefined : json['maxScalePercentageInSurveyReport'],
        'maxScaleValueInSurveyReport': !exists(json, 'maxScaleValueInSurveyReport') ? undefined : json['maxScaleValueInSurveyReport'],
        'maxScalePercentageInEmployeeReport': !exists(json, 'maxScalePercentageInEmployeeReport') ? undefined : json['maxScalePercentageInEmployeeReport'],
        'maxScaleCurrentEmployeeValueInEmployeeReport': !exists(json, 'maxScaleCurrentEmployeeValueInEmployeeReport') ? undefined : json['maxScaleCurrentEmployeeValueInEmployeeReport'],
        'maxScaleValueInEmployeeReport': !exists(json, 'maxScaleValueInEmployeeReport') ? undefined : json['maxScaleValueInEmployeeReport'],
        'maxScalePercentageOfTurnoverRate': !exists(json, 'maxScalePercentageOfTurnoverRate') ? undefined : json['maxScalePercentageOfTurnoverRate'],
        'maxScaleValueOfTurnoverCount': !exists(json, 'maxScaleValueOfTurnoverCount') ? undefined : json['maxScaleValueOfTurnoverCount'],
        'feedbackSurveyTitle': !exists(json, 'feedbackSurveyTitle') ? undefined : json['feedbackSurveyTitle'],
        'feedbackSurveyBody': !exists(json, 'feedbackSurveyBody') ? undefined : json['feedbackSurveyBody'],
        'feedbackMailSignature': !exists(json, 'feedbackMailSignature') ? undefined : json['feedbackMailSignature'],
        'feedbackMailBody': !exists(json, 'feedbackMailBody') ? undefined : json['feedbackMailBody'],
        'feedbackMailSubject': !exists(json, 'feedbackMailSubject') ? undefined : json['feedbackMailSubject'],
        'feedbackMailSenderName': !exists(json, 'feedbackMailSenderName') ? undefined : json['feedbackMailSenderName'],
        'emailSignature': !exists(json, 'emailSignature') ? undefined : json['emailSignature'],
        'emailSenderName': json['emailSenderName'],
    };
}

export function GlobalSettingResponseToJSON(value?: GlobalSettingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeHrInterview': value.activeHrInterview,
        'maxMinutesInKpiReport': value.maxMinutesInKpiReport,
        'maxPercentageInKpiReport': value.maxPercentageInKpiReport,
        'maxScaleValueInKpiReport': value.maxScaleValueInKpiReport,
        'maxScalePercentageInSurveyReport': value.maxScalePercentageInSurveyReport,
        'maxScaleValueInSurveyReport': value.maxScaleValueInSurveyReport,
        'maxScalePercentageInEmployeeReport': value.maxScalePercentageInEmployeeReport,
        'maxScaleCurrentEmployeeValueInEmployeeReport': value.maxScaleCurrentEmployeeValueInEmployeeReport,
        'maxScaleValueInEmployeeReport': value.maxScaleValueInEmployeeReport,
        'maxScalePercentageOfTurnoverRate': value.maxScalePercentageOfTurnoverRate,
        'maxScaleValueOfTurnoverCount': value.maxScaleValueOfTurnoverCount,
        'feedbackSurveyTitle': value.feedbackSurveyTitle,
        'feedbackSurveyBody': value.feedbackSurveyBody,
        'feedbackMailSignature': value.feedbackMailSignature,
        'feedbackMailBody': value.feedbackMailBody,
        'feedbackMailSubject': value.feedbackMailSubject,
        'feedbackMailSenderName': value.feedbackMailSenderName,
        'emailSignature': value.emailSignature,
        'emailSenderName': value.emailSenderName,
    };
}


