import React from "react";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  documentId: string;
  hrInterviewId: string;
  content: string;
  tags: {
    key: string;
    label: string;
  }[];
}

export const SentenceTable: React.FC<Props> = ({ documentId, hrInterviewId, content, tags }) => {
  const tagText = tags.map((tag) => `#${tag.label}`).join(" ");
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        display: "flex",
        gap: "8px",
        padding: "8px 0",
        borderBottom: `1px solid ${theme.common.border.gray40}`,
      }}
    >
      <AmeBox sx={{ flexGrow: 1 }}>
        <AmeTypography>
          {content}
          <AmeBox component="span" sx={{ marginLeft: "8px", color: theme.common.text.gray20 }}>
            {tagText}
          </AmeBox>
        </AmeTypography>
      </AmeBox>
      <AmeButton
        variant="text"
        to={"/hr-interviews/interviews/$interviewId"}
        params={{ interviewId: hrInterviewId }}
        search={{ documentId: documentId }}
      >
        詳細
      </AmeButton>
    </AmeBox>
  );
};
