import {
  InterviewFeedbackQuestionAnswer,
  InterviewFeedbackResponse,
  InterviewFeedbackSettingsAnswer,
  InterviewFeedbackSettingsQuestion,
  InterviewQualityReportResponse,
} from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export class FeedbackDetail {
  constructor(
    readonly rating: "0" | "1" | "2",
    readonly answer: string,
    readonly interviewFeedbackAnswerId: string,
    readonly question: string,
    readonly priority: number,
    readonly interviewFeedbackQuestionId: string,
  ) {}

  static fromResponse(response: InterviewFeedbackQuestionAnswer): FeedbackDetail {
    return new FeedbackDetail(
      response.rating,
      response.answer,
      response.interviewFeedbackAnswerId,
      response.question,
      response.priority,
      response.interviewFeedbackQuestionId,
    );
  }
}

export class Feedback {
  constructor(
    readonly comment: string,
    readonly feedbackDetails: FeedbackDetail[],
    readonly qualityScore: number,
    readonly interviewDate: Date,
    readonly originalFileName: string,
    readonly interviewRecordingId: string,
  ) {}

  static fromResponse(response: InterviewFeedbackResponse): Feedback {
    return new Feedback(
      response.comment,
      response.questions.map((d) => FeedbackDetail.fromResponse(d)),
      response.qualityScore,
      new Date(response.interviewDate),
      response.originalFileName,
      response.interviewRecordingId,
    );
  }
}

export class InterviewFeedbackReport {
  constructor(
    readonly feedbacks: Feedback[],
    readonly averageQualityScore: number,
    readonly interviewCount: number,
    readonly userGroups: { displayName: string; userGroupId: string }[],
    readonly givenName: string,
    readonly surname: string,
    readonly userId: string,
  ) {}

  static fromResponse(response: InterviewQualityReportResponse): InterviewFeedbackReport {
    return new InterviewFeedbackReport(
      response.feedbacks.map((f) => Feedback.fromResponse(f)),
      response.averageQualityScore,
      response.interviewCount,
      response.userGroups.map((g) => ({ displayName: g.displayName, userGroupId: g.userGroupId })),
      response.givenName,
      response.surname,
      response.userId,
    );
  }
}

export const useInterviewerReportList = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewQualityReportList.useQuery(undefined, {
    pollingInterval: options?.pollingInterval,
    skip: options?.skip,
  });
  return useQueryResult({
    result,
    fromResponse: (r) => r.qualityReports.map((q) => InterviewFeedbackReport.fromResponse(q)),
    onError: options?.onError,
  });
};

export class InterviewFeedbackAnswer {
  constructor(
    readonly answer: string,
    readonly rating: "0" | "1" | "2",
    readonly interviewFeedbackAnswerId: string,
    readonly score: number,
  ) {}

  static fromResponse(res: InterviewFeedbackSettingsAnswer): InterviewFeedbackAnswer {
    return new InterviewFeedbackAnswer(res.answer, res.rating, res.interviewFeedbackAnswerId, res.score);
  }
}

export class InterviewFeedbackQuestion {
  constructor(
    readonly answerChoices: InterviewFeedbackAnswer[],
    readonly question: string,
    readonly priority: number,
    readonly interviewFeedbackQuestionId: string,
  ) {}

  static fromResponse(res: InterviewFeedbackSettingsQuestion): InterviewFeedbackQuestion {
    return new InterviewFeedbackQuestion(
      res.answerChoices.map((a) => InterviewFeedbackAnswer.fromResponse(a)),
      res.question,
      res.priority,
      res.interviewFeedbackQuestionId,
    );
  }
}

export class InterviewQualityReport {
  constructor(
    readonly feedbacks: Feedback[],
    readonly averageQualityScore: number,
    readonly interviewCount: number,
    readonly userGroups: { displayName: string; userGroupId: string }[],
    readonly givenName: string,
    readonly surname: string,
    readonly userId: string,
  ) {}

  static fromResponse(res: InterviewQualityReportResponse): InterviewQualityReport {
    return new InterviewQualityReport(
      res.feedbacks.map((f) => Feedback.fromResponse(f)),
      res.averageQualityScore,
      res.interviewCount,
      res.userGroups.map((g) => ({ displayName: g.displayName, userGroupId: g.userGroupId })),
      res.givenName,
      res.surname,
      res.userId,
    );
  }
}

export const useInterviewFeedbackSetting = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewFeedbackSettings.useQuery(undefined, {
    pollingInterval: options?.pollingInterval,
    skip: options?.skip,
  });
  return useQueryResult({
    result,
    fromResponse: (r) => r.questions.map((q) => InterviewFeedbackQuestion.fromResponse(q)),
    onError: options?.onError,
  });
};
export const useInterviewQualityReport = (interviewerId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewQualityReport.useQuery(
    { userId: interviewerId },
    {
      pollingInterval: options?.pollingInterval,
      skip: options?.skip,
    },
  );
  return useQueryResult({
    result,
    fromResponse: (r) => InterviewQualityReport.fromResponse(r),
    onError: options?.onError,
  });
};

export const useSubmitInterviewFeedback = (options?: UseMutationOptions) => {
  const [mutation] = defaultApi.endpoints.submitInterviewFeedback.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: mutation,
    onError: options?.onError,
  });
};
