import React, { useCallback, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useGetCustomerCreatedIdExistsLazyQuery } from "../../../../store/hooks/employees";
import { useGetSurveySheetBySurveyId, useSubmitSurveyAnswerWithId } from "../../../../store/hooks/surveys";
import { RtkCustomHookError } from "../../../../store/hooks/utils/types";
import { WaitForSuccess } from "../../../molecules/Loading/WaitForSuccess";
import { PublicRouteTemplate } from "../../../molecules/layout/PublicRouteTemplate";
import {
  SurveyQuestionAnswer,
  SurveyQuestionAnswers,
} from "../../../organisms/common/SurveyTemplate/SurveyTemplateBody";
import { SurveyTemplateComplete } from "../../../organisms/common/SurveyTemplate/SurveyTemplateComplete";
import { SurveyQuestionnaireForQr } from "../../../organisms/surveys/answer-page/$surveyId";

const SurveyQrAnswerPage: React.FC = () => {
  const { surveyId } = Route.useParams();
  return <SurveyQrAnswerPageContent surveyId={surveyId} />;
};

interface Props {
  surveyId: string;
}

const SurveyQrAnswerPageContent: React.FC<Props> = (props) => {
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const surveyQuery = useGetSurveySheetBySurveyId(props.surveyId);
  const [getCustomerCreatedIdExists] = useGetCustomerCreatedIdExistsLazyQuery();
  const [answers, setAnswers] = useState<SurveyQuestionAnswers>(new Map());
  const [submitted, setSubmitted] = useState(false);
  const {
    watch,
    register,
    getValues,
    formState: { errors },
  } = useForm<{ customerCreatedId: string }>({
    mode: "onChange",
    defaultValues: {
      customerCreatedId: "",
    },
  });

  const onSurveyRequestError = useCallback(
    (error: RtkCustomHookError) => {
      switch (error?.status) {
        case 404: {
          enqueueErrorSnackbar({
            title: "この従業員番号は本アンケートの対象ではありません。",
            message: "URLが誤っている可能性があります。担当者に問い合わせてください。",
          });
          break;
        }
        default: {
          enqueueErrorSnackbar({ title: "不明なエラーが発生しました。", message: error?.message });
        }
      }
    },
    [enqueueErrorSnackbar],
  );

  const handleChange = useCallback(
    (answer: SurveyQuestionAnswer) => {
      const copied = new Map(answers);
      copied.set(answer.questionId, answer);
      setAnswers(copied);
    },
    [answers, setAnswers],
  );

  const submitSurveyAnswerWithId = useSubmitSurveyAnswerWithId({ onError: onSurveyRequestError });

  const handleSubmit = useCallback(async () => {
    const customerCreatedId = getValues("customerCreatedId");
    const customerCreatedIdExistsResult = await getCustomerCreatedIdExists({ customerCreatedId });
    if (customerCreatedIdExistsResult.isSuccess) {
      if (!customerCreatedIdExistsResult.data.exist) {
        enqueueErrorSnackbar({ title: "従業員番号が存在しません。", message: "従業員番号を確認してください。" });
        return;
      }
    }
    const answerRequest = Array.from(answers.values()).map((answer) => ({
      value: Number(answer.value),
      surveyQuestionId: answer.questionId,
    }));
    const result = await submitSurveyAnswerWithId({
      surveyId: props.surveyId,
      submitSurveyAnswerWithCustomerCreatedIdRequestBody: {
        customerCreatedId,
        answers: answerRequest,
      },
    });
    if (result.isSuccess) {
      setSubmitted(true);
    }
  }, [
    answers,
    submitSurveyAnswerWithId,
    props.surveyId,
    getValues,
    setSubmitted,
    getCustomerCreatedIdExists,
    enqueueErrorSnackbar,
  ]);

  return (
    <PublicRouteTemplate>
      <WaitForSuccess queryState={surveyQuery}>
        {(survey) => (
          <>
            {submitted ? (
              <SurveyTemplateComplete />
            ) : (
              <SurveyQuestionnaireForQr
                answers={answers}
                customerCreatedId={watch("customerCreatedId")}
                title={survey.title}
                description={survey.description}
                questions={survey.questions.map((q) => ({
                  questionId: q.surveyQuestionId,
                  questionText: q.questionText,
                }))}
                onChange={handleChange}
                onSubmit={handleSubmit}
                error={errors.customerCreatedId}
                register={register}
              />
            )}
          </>
        )}
      </WaitForSuccess>
    </PublicRouteTemplate>
  );
};

export const Route = createFileRoute("/surveys/$surveyId/answer-page")({
  component: SurveyQrAnswerPage,
});
