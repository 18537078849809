import React from "react";

import { useGetRuleSuggestionsBySubCategoryId } from "../../../../../../store/hooks/ruleSuggestion";

import { SuggestionArea } from "./SuggestionArea";
import { SuggestionAlgorithm } from "./SuggestionHeader";

interface Props {
  subCategoryId: string;
  algorithm: SuggestionAlgorithm;
  query: string;
  size: number;
}

export const SubCategorySuggestionArea: React.FC<Props> = ({ subCategoryId, algorithm, query, size }) => {
  const {
    isSuccess,
    isFetching,
    data: suggestions,
  } = useGetRuleSuggestionsBySubCategoryId({ subCategoryId, algorithm, query, size });
  return isSuccess && !isFetching ? <SuggestionArea suggestions={suggestions} /> : null;
};
