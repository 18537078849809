/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 属性情報
 * @export
 * @interface InterviewDetailResponseRetiredEmployee
 */
export interface InterviewDetailResponseRetiredEmployee {
    /**
     * 
     * @type {Date}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    birthday?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    retireDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    hireDate: Date;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    free2?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    free1?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    sex?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    customerCreatedId: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseRetiredEmployee
     */
    employeeId: string;
}

export function InterviewDetailResponseRetiredEmployeeFromJSON(json: any): InterviewDetailResponseRetiredEmployee {
    return InterviewDetailResponseRetiredEmployeeFromJSONTyped(json, false);
}

export function InterviewDetailResponseRetiredEmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewDetailResponseRetiredEmployee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'retireDate': !exists(json, 'retireDate') ? undefined : (new Date(json['retireDate'])),
        'hireDate': (new Date(json['hireDate'])),
        'free2': !exists(json, 'free2') ? undefined : json['free2'],
        'free1': !exists(json, 'free1') ? undefined : json['free1'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'customerCreatedId': json['customerCreatedId'],
        'employeeId': json['employeeId'],
    };
}

export function InterviewDetailResponseRetiredEmployeeToJSON(value?: InterviewDetailResponseRetiredEmployee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString()),
        'retireDate': value.retireDate === undefined ? undefined : (value.retireDate.toISOString()),
        'hireDate': (value.hireDate.toISOString()),
        'free2': value.free2,
        'free1': value.free1,
        'department': value.department,
        'sex': value.sex,
        'email': value.email,
        'name': value.name,
        'customerCreatedId': value.customerCreatedId,
        'employeeId': value.employeeId,
    };
}


