import React, { useCallback } from "react";

import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "@tanstack/react-router";

import { SurveySendingMethod } from "../../../../models/constants/values";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";

interface Props {
  icon: React.ReactNode;
  text: string;
  helperText: string;
  method: SurveySendingMethod;
}

export const SelectSurveySendingMethodButton: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    void navigate({
      to: "/surveys/create",
      search: { method: props.method },
    });
  }, [props.method, navigate]);

  return (
    <>
      <AmeButton
        onClick={onClick}
        startIcon={props.icon}
        endIcon={
          <ArrowForwardIos
            sx={{
              "&.MuiSvgIcon-root": {
                fontSize: "16px",
                color: theme.common.text.black,
              },
            }}
          />
        }
        variant="outlined"
        fullWidth
      >
        {props.text}
      </AmeButton>
      <Spacer height="12px" />
      <AmeTypography fontSize="caption">{props.helperText}</AmeTypography>
    </>
  );
};
