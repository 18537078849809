import React, { useMemo } from "react";

import { Control, Controller } from "react-hook-form";

import { InterviewFeedbackQuestion } from "../../../../../store/hooks/interviewer";
import { ChoosableList } from "../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../atoms/list/ChoosableListItem";
import { AmeTableBodyCell, AmeTableRow } from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { NicoChanFace } from "../../../../molecules/InterviewFeedback/NicoChanFace";
import { AmeDropbox_old } from "../../../../molecules/dropbox/AmeDropbox";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { InterviewerFormType } from "./InterviewerFeedbackTable";

interface Props {
  question: InterviewFeedbackQuestion;
  index: number;
  currentValue: string;
  control: Control<InterviewerFormType>;
}

export const InterviewerFeedbackTableRow: React.FC<Props> = ({ question, index, currentValue, control }) => {
  const displaySelected = useMemo(() => {
    const selectedChoice = question.answerChoices.find((choice) => choice.interviewFeedbackAnswerId === currentValue);
    if (selectedChoice) {
      return (
        <AmeBox sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <NicoChanFace rating={selectedChoice.rating} />
          <AmeTypography fontSize="body2">{selectedChoice.answer}</AmeTypography>
        </AmeBox>
      );
    }
    return undefined;
  }, [question, currentValue]);

  return (
    <AmeTableRow key={question.interviewFeedbackQuestionId}>
      <AmeTableBodyCell>
        {question.question}
        {/* TODO: ここ終わってるので治す */}
        <Controller
          control={control}
          defaultValue={question.interviewFeedbackQuestionId}
          render={({ field }) => <input hidden {...field} />}
          name={`qualities.${index}.interviewFeedbackQuestionId`}
        />
      </AmeTableBodyCell>
      <AmeTableBodyCell noPadding>
        <Controller
          name={`qualities.${index}.interviewFeedbackAnswerId`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <AmeDropbox_old
              value={value}
              onChange={onChange}
              displaySelected={displaySelected}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              forTable
            >
              <ChoosableList>
                {question.answerChoices.map((choice) => (
                  <ChoosableListItem key={choice.interviewFeedbackAnswerId} value={choice.interviewFeedbackAnswerId}>
                    <AmeBox sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                      <NicoChanFace rating={choice.rating} />
                      <AmeTypography fontSize="body2">{choice.answer}</AmeTypography>
                    </AmeBox>
                  </ChoosableListItem>
                ))}
              </ChoosableList>
            </AmeDropbox_old>
          )}
        />
      </AmeTableBodyCell>
    </AmeTableRow>
  );
};
