import React from "react";

import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { ReportFilterChipList } from "../../../v2/v2Component/ReportFilterChipList";
import { FilterChipContent } from "../../../v2/v2Component/ReportFilterChipList/ReportFilterChip";
import { DisplayedInterviewsCount } from "../../../v2/v2Component/ReportHeader/DisplayedInterviewsCount";

type Props = {
  count: number;
  selectedFilters: FilterChipContent[];
};

export const HrInterviewListHeader: React.FC<Props> = ({ count, selectedFilters }) => {
  return (
    <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "56px" }}>
      <AmeBox sx={{ flexGrow: 0, flexShrink: 0 }}>
        <DisplayedInterviewsCount count={count} />
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1, flexShrink: 1, overflowX: "scroll", margin: "0 16px", paddingTop: "8px" }}>
        <ReportFilterChipList selectedFilters={selectedFilters} />
      </AmeBox>
    </AmeBox>
  );
};
