import React, { Dispatch, SetStateAction, useCallback, useContext } from "react";

import { ArrowForwardIos, Delete, ExpandLess, ExpandMore } from "@mui/icons-material";

import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../../../muiWrapper/AmeIconButton";
import { RuleRegisterContext } from "../RuleRegisterContext";

interface Props {
  categoryId: string;
  subCategoryId?: string;
  displayName: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onDelete: () => void;
  deletable: boolean;
  isOpen: boolean;
}

export const CategoryMenu: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const { setRuleBuildTarget } = useContext(RuleRegisterContext);

  const onDelete = useCallback(() => {
    if (props.deletable) {
      props.onDelete();
      return;
    }
  }, [props]);
  return (
    <AmeBox sx={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
      <AmeButton
        variant="text"
        onClick={() => {
          setRuleBuildTarget({
            categoryId: props.categoryId,
            subCategoryId: props.subCategoryId,
            displayName: props.displayName,
          });
        }}
        endIcon={
          <ArrowForwardIos
            sx={{
              "&.MuiSvgIcon-fontSizeMedium": {
                fontSize: "16px",
              },
            }}
          />
        }
      >
        ルール作成
      </AmeButton>
      <AmeBox>
        <AmeIconButton onClick={onDelete}>{<Delete htmlColor={theme.common.background.gray120} />}</AmeIconButton>
      </AmeBox>
      <AmeIconButton onClick={() => props.setIsOpen((value) => !value)}>
        {props.isOpen ? <ExpandLess /> : <ExpandMore />}
      </AmeIconButton>
    </AmeBox>
  );
};
