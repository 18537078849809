import React from "react";

import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  comment: string;
}

export const InterviewerQualityDetailComment: React.FC<Props> = ({ comment }) => {
  return (
    <AmeBox>
      <AmeTypography component="h6" color="basicLight">
        コメント
      </AmeTypography>
      <Spacer height="16px" />
      <AmeTypography fontSize="body2">{comment}</AmeTypography>
    </AmeBox>
  );
};
