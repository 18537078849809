import React, { useCallback } from "react";

import { createFileRoute } from "@tanstack/react-router";

import {
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetGroupListQuery,
  useGetGroupQuery,
  useUpdateGroupMutation,
} from "../../../../../../../store/hooks/userGroups";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { AmePagination } from "../../../../../../atoms/pagination/AmePagination";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { GroupCreationDialog } from "../../../../../../organisms/settings/members/groups/index/GroupCreationDialog";
import { GroupTable } from "../../../../../../organisms/settings/members/groups/index/GroupTable";

const ITEMS_PER_PAGE = 20;
export const GroupListPage: React.FC = () => {
  const { page } = Route.useSearch();
  const navigate = Route.useNavigate();
  const onChangePage = useCallback(
    async (pageNumber: number) => {
      await navigate({
        search: (prev) => ({ ...prev, page: pageNumber }),
      });
    },
    [navigate],
  );
  const [openGroupCreationDialog, setOpenGroupCreationDialog] = React.useState(false);
  const createGroupRaw = useCreateGroupMutation();
  const createGroup = useCallback(
    async (groupName: string) => {
      await createGroupRaw({ createUserGroupRequest: { displayName: groupName } });
    },
    [createGroupRaw],
  );
  const deleteGroupRaw = useDeleteGroupMutation();
  const updateGroupRaw = useUpdateGroupMutation();
  const deleteGroup = useCallback(
    async (groupId: string) => {
      await deleteGroupRaw({ userGroupId: groupId });
    },
    [deleteGroupRaw],
  );
  const updateGroup = useCallback(
    async (groupId: string, groupName: string) => {
      await updateGroupRaw({ userGroupId: groupId, partialCreateUserGroupRequest: { displayName: groupName } });
    },
    [updateGroupRaw],
  );
  const useGetGroup = useGetGroupQuery;

  const getUserGroupListQueryState = useGetGroupListQuery(ITEMS_PER_PAGE, ITEMS_PER_PAGE * (page - 1));

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[{ title: "設定", to: "/settings" }]} currentTitle="グループ管理" />
      <PageLayoutBody type="wide">
        <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <AmeTypography component={"h4"}>グループ管理</AmeTypography>
          <AmeButton onClick={() => setOpenGroupCreationDialog(true)}>グループを新規登録</AmeButton>
        </AmeBox>
        <Spacer height={"24px"} />
        <GroupCreationDialog
          open={openGroupCreationDialog}
          onClose={() => {
            setOpenGroupCreationDialog(false);
          }}
          onSubmit={createGroup}
        />
        <WaitForSuccess queryState={getUserGroupListQueryState}>
          {(userGroupList) => (
            <>
              <GroupTable
                userGroupList={userGroupList}
                updateGroup={updateGroup}
                deleteGroup={deleteGroup}
                useGetGroup={useGetGroup}
              />
              <Spacer height={"24px"} />
              <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <AmePagination
                  count={Math.ceil(userGroupList.totalUserCount / ITEMS_PER_PAGE)}
                  onChangePage={onChangePage}
                  page={page}
                />
              </AmeBox>
              <Spacer height={"24px"} />
            </>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type QueryParams = {
  page: number;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/members/groups/")({
  component: GroupListPage,
  validateSearch: (search: Partial<QueryParams>): QueryParams => ({ page: search.page ?? 1 }),
});
