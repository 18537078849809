import React, { useCallback, useState } from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SubCategoryModel } from "../../../../../models/SubCategory";
import { SurveysQuestion } from "../../../../../models/SurveyQuestion";
import { useRegisterSurveyQuestions } from "../../../../../store/hooks/surveys-questions";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { QuestionSettingTable } from "./QuestionSettingTable";
import { SurveyQuestionsPreviewModal } from "./SurveyQuestionsPreviewModal";

interface Props {
  subCategories: SubCategoryModel[];
  defaultSurveyQuestions: SurveysQuestion[];
}

export interface QuestionSettingFormType {
  questions: QuestionSetting[];
}
export interface QuestionSetting {
  questionText: string;
  subCategoryId: string;
}

export const SurveyQuestionsSettingContent: React.FC<Props> = (props) => {
  const { register, control, handleSubmit, getValues } = useForm<QuestionSettingFormType>({
    defaultValues: {
      questions: props.defaultSurveyQuestions,
    },
  });
  const navigate = useNavigate();
  const registerSurveyQuestions = useRegisterSurveyQuestions();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const onSubmit = useCallback(
    async ({ questions }: QuestionSettingFormType) => {
      const result = await registerSurveyQuestions({
        registerSurveyQuestionRequestBody: {
          questions: questions.map((q, i) => ({
            questionText: q.questionText,
            subCategoryId: q.subCategoryId,
            priority: i,
          })),
        },
      });
      if (result.isSuccess) {
        void navigate({ to: "/settings" });
      }
    },
    [registerSurveyQuestions, navigate],
  );
  const onSubmitError = useCallback(() => {
    enqueueErrorSnackbar({ title: "赤枠のセルに値が入っていません。" });
  }, [enqueueErrorSnackbar]);

  const [previewIsOpen, setPreviewIsOpen] = useState<boolean>(false);
  const onClickOpenPreview = useCallback(() => {
    setPreviewIsOpen(true);
  }, [setPreviewIsOpen]);
  const onClosePreview = useCallback(() => {
    setPreviewIsOpen(false);
  }, [setPreviewIsOpen]);

  return (
    <React.Fragment>
      <AmeBox component="form" onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <PageLayoutWrapper>
          <PageLayoutHeader
            title="設問設計"
            endElement={
              <AmeBox component="span" sx={{ display: "inline-flex", gap: "8px", justifyContent: "space-between" }}>
                <AmeButton type="submit">保存</AmeButton>
                <AmeButton variant="outlined" onClick={onClickOpenPreview}>
                  プレビュー
                </AmeButton>
              </AmeBox>
            }
          />

          <PageLayoutBody type="wide">
            <QuestionSettingTable subCategories={props.subCategories} register={register} control={control} />
          </PageLayoutBody>
        </PageLayoutWrapper>
      </AmeBox>
      <SurveyQuestionsPreviewModal
        title="プレビュー"
        description="プレビューです。"
        previewIsOpen={previewIsOpen}
        onClosePreview={onClosePreview}
        questions={getValues("questions")}
      />
    </React.Fragment>
  );
};
