import React, { MouseEventHandler, useMemo } from "react";

import { SystemStyleObject } from "@mui/system";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

type ChipLabel = "+" | "−";

interface Props {
  label: ChipLabel;
  onClick: MouseEventHandler;
}

export const SuggestionChipButton: React.FC<Props> = ({ label, onClick }) => {
  const style = useSuggestionChipStyle(label);
  return (
    <AmeBox sx={style} component="button" onClick={onClick}>
      {label}
    </AmeBox>
  );
};

const useSuggestionChipStyle = (label: ChipLabel): SystemStyleObject => {
  const theme = useAmeTheme();
  return useMemo(() => {
    const style: SystemStyleObject = {
      height: "100%",
      width: "24px",
      border: "none",
      fontWeight: 900,
      backgroundColor: theme.common.background.white,
      color: theme.common.text.gray20,
      "&:hover": {
        backgroundColor: theme.brand.primary[100],
        color: theme.brand.primary.contrastText,
      },
    };
    if (label === "−") {
      return {
        ...style,
        borderTopRightRadius: "100px",
        borderBottomRightRadius: "100px",
      };
    }
    return style;
  }, [theme, label]);
};
