import React, { forwardRef } from "react";

import type { ChangeHandler } from "react-hook-form";

import { AmeBox } from "../../muiWrapper/AmeBox";

import { AmeRadio } from "./AmeRadio";

type Props = {
  data: {
    value: string;
    label: string;
  }[];
  onChange: ChangeHandler;
  name: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
};

export const AmeRadioGroup: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ data, onChange, name, value }, ref) => {
    return (
      <AmeBox sx={{ display: "flex", justifyContent: "left", gap: "16px" }}>
        {data.map((radioButtonData, index) => {
          return (
            <AmeRadio
              key={index}
              name={name}
              value={radioButtonData.value}
              label={radioButtonData.label}
              onChange={onChange}
              checked={radioButtonData.value === value}
              ref={ref}
            />
          );
        })}
      </AmeBox>
    );
  },
);
AmeRadioGroup.displayName = "AmeRadioGroup";
