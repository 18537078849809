import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetInterviewTypes } from "../../../../../../store/hooks/hr-interview-setting";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { HrInterviewTypesSettingContent } from "../../../../../organisms/settings/hr-interview-types/HrInterviewTypesSettingContent";

const InterviewTypesSetting: React.FC = () => {
  const interviewTypesQueryState = useGetInterviewTypes();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader currentTitle={"在籍者面談詳細"} items={[{ title: "設定", to: "/settings" }]} />
      <WaitForSuccess queryState={interviewTypesQueryState}>
        {(interviewTypes) => <HrInterviewTypesSettingContent defaultInterviewTypes={interviewTypes} />}
      </WaitForSuccess>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/hr-interview-types/")({
  component: InterviewTypesSetting,
});
