import React from "react";

import { QueryState } from "../../../store/hooks/utils/parseResult";
import { AmeCircularProgress } from "../../muiWrapper/AmeCircularProgress";

type Props<T> = {
  queryState: QueryState<T>;
  children: (data: T) => React.ReactElement | null;
};

export const WaitForSuccess = <T,>(props: Props<T>) => {
  if (props.queryState.isSuccess) {
    return props.children(props.queryState.data);
  } else {
    return <AmeCircularProgress />;
  }
};
