import React, { MouseEventHandler } from "react";

import { AmeButton } from "../../atoms/button/AmeButton";
import { AmeBox } from "../../muiWrapper/AmeBox";

export interface AmeFormButtonsLayoutProps {
  onPrimary: MouseEventHandler<HTMLButtonElement>;
  primaryText?: string;
  primaryDisabled?: boolean;
  onSecondary?: MouseEventHandler<HTMLButtonElement>;
  secondaryText?: string;
  secondaryDisabled?: boolean;
}

/**
 * フォーム用のボタンのレイアウト
 * アクションボタンの数が２つ以下のときに使用。
 * アクションボタン数が3つ以上の場合は、AmeFormFollowingButtonsLayoutを使用する。
 */
export const AmeFormButtonsLayout: React.FC<AmeFormButtonsLayoutProps> = ({
  onPrimary,
  primaryText,
  primaryDisabled,
  onSecondary,
  secondaryText,
  secondaryDisabled,
}) => {
  return (
    <AmeBox sx={{ display: "flex", justifyContent: "left", gap: "16px" }}>
      <AmeButton onClick={onPrimary} variant="contained" disabled={primaryDisabled}>
        {primaryText ?? "保存"}
      </AmeButton>
      {onSecondary !== undefined ? (
        <AmeButton onClick={onSecondary} variant="outlined" disabled={secondaryDisabled}>
          {secondaryText ?? "キャンセル"}
        </AmeButton>
      ) : null}
    </AmeBox>
  );
};
