/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HrInterviewDocumentStat
 */
export interface HrInterviewDocumentStat {
    /**
     * 
     * @type {Array<string>}
     * @memberof HrInterviewDocumentStat
     */
    subCategoryIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewDocumentStat
     */
    documentId: string;
    /**
     * 
     * @type {number}
     * @memberof HrInterviewDocumentStat
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof HrInterviewDocumentStat
     */
    length: number;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewDocumentStat
     */
    interviewFormatContentId: string;
}

export function HrInterviewDocumentStatFromJSON(json: any): HrInterviewDocumentStat {
    return HrInterviewDocumentStatFromJSONTyped(json, false);
}

export function HrInterviewDocumentStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewDocumentStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subCategoryIds': json['subCategoryIds'],
        'documentId': json['documentId'],
        'offset': json['offset'],
        'length': json['length'],
        'interviewFormatContentId': json['interviewFormatContentId'],
    };
}

export function HrInterviewDocumentStatToJSON(value?: HrInterviewDocumentStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subCategoryIds': value.subCategoryIds,
        'documentId': value.documentId,
        'offset': value.offset,
        'length': value.length,
        'interviewFormatContentId': value.interviewFormatContentId,
    };
}


