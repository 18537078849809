import { useMemo } from "react";

import { useGetCategoryContent } from "../../../../hooks/filterHooks/useCategoryChoiceGroup";
import { useFollowupChoiceGroup } from "../../../../hooks/filterHooks/useFollowupChoiceGroup";
import { useInterviewTypeChoiceGroup } from "../../../../hooks/filterHooks/useInterviewTypeChoiceGroup";
import { useGetSegmentContent } from "../../../../hooks/filterHooks/useSegmentChoiceGroup";
import { HrInterviewType } from "../../../../models/HrInterviewSetting";
import { AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory } from "../../../../store/autogenApi";

export type HrInterviewReportQuery = {
  axis: AxisLiteralOfTypeofHrInterviewReportGraphAxisFactory;
  interviewTypeIds: string[];
  followupRequired: boolean[];
  interviewDateFrom?: string;
  interviewDateTo?: string;
  SEX: string[];
  DEPARTMENT: string[];
  FREE1: string[];
  FREE2: string[];
  AGE: string[];
  MONTHS_OF_SERVICE: string[];
  subCategoryIds: string[];
};

export const useHrInterviewReportChoiceGroups = (
  navigate: (arg: { search: (prev: HrInterviewReportQuery) => HrInterviewReportQuery }) => Promise<void>,
  searchParams: HrInterviewReportQuery,
  interviewTypes: HrInterviewType[],
) => {
  const interviewTypeChoiceGroup = useInterviewTypeChoiceGroup(interviewTypes, {
    filterParams: searchParams,
    navigate: navigate,
  });
  const followUpRequiredChoiceGroup = useFollowupChoiceGroup({ filterParams: searchParams, navigate: navigate });
  const segmentChoiceGroup = useGetSegmentContent(searchParams, navigate);
  const categoryChoiceGroup = useGetCategoryContent(searchParams, navigate);

  return useMemo(() => {
    return [interviewTypeChoiceGroup, followUpRequiredChoiceGroup, segmentChoiceGroup, categoryChoiceGroup];
  }, [followUpRequiredChoiceGroup, interviewTypeChoiceGroup, segmentChoiceGroup, categoryChoiceGroup]);
};
