import React, { useCallback } from "react";

import { Add } from "@mui/icons-material";
import { TablePagination } from "@mui/material";

import { useCreateCategoryMutation } from "../../../../../store/hooks/categories";
import { useCreateSubCategoryMutation } from "../../../../../store/hooks/subCategories";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

export const DEFAULT_ROWS_PER_PAGE = 15;

interface Props {
  categoryId?: string;
  setPage: (index: number) => void;
  page: number;
  itemsLength: number;
}

export const RuleListHeader: React.FC<Props> = (props) => {
  const createCategory = useCreateCategoryMutation();
  const createSubCategory = useCreateSubCategoryMutation();
  const onAddClick = useCallback(async () => {
    if (props.categoryId !== undefined) {
      await createSubCategory({
        categoryId: props.categoryId,
        createSubCategoryRequestBody: {
          displayName: "新規サブカテゴリー",
          subCategoryDefinition: "",
        },
      });
      return;
    }
    await createCategory({
      createCategoryRequestBody: {
        displayName: "新規カテゴリー",
      },
    });
  }, [props.categoryId, createSubCategory, createCategory]);

  return (
    <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 0" }}>
      <AmeButton onClick={onAddClick} size="small" startIcon={<Add />}>
        {props.categoryId !== undefined ? "サブカテゴリーを追加" : "カテゴリーを追加"}
      </AmeButton>
      <TablePagination
        component={"div"}
        count={props.itemsLength}
        onPageChange={(_, index) => {
          props.setPage(index);
        }}
        page={props.page}
        rowsPerPage={DEFAULT_ROWS_PER_PAGE}
        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      />
    </AmeBox>
  );
};
