import React, { useContext } from "react";

import { Outlet, createFileRoute } from "@tanstack/react-router";

import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";

const HrInterviewPage: React.FC = () => {
  const {
    setting: {
      globalSetting: { activateHrInterview },
    },
  } = useContext(DataSourceContext);

  if (activateHrInterview) {
    return <Outlet />;
  } else {
    return <div>このページは利用できません。</div>;
  }
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/hr-interviews")({
  component: HrInterviewPage,
});
