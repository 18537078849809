import React from "react";

import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeFormButtonsLayout } from "../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { AmeBox } from "../../../muiWrapper/AmeBox";

export type SurveyQuestion = {
  questionId: string;
  questionText: string;
};
export type SurveyQuestionAnswer = {
  questionId: string;
  value: string;
};
export type SurveyQuestionAnswers = Map<string, SurveyQuestionAnswer>; // key: surveyQuestionId

type SurveyQuestionnaireBodyProps = {
  children: React.ReactNode;
  description: string;
  submittable: boolean;
  onSubmit: () => void;
};

export const SurveyTemplateBody: React.FC<SurveyQuestionnaireBodyProps> = (props) => {
  return (
    <AmeBox
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "24px",
      }}
    >
      <AmeTypography fontSize="body2">
        <AmeBox sx={{ whiteSpace: "pre-line" }}>{props.description}</AmeBox>
      </AmeTypography>
      {props.children}
      <AmeFormButtonsLayout onPrimary={props.onSubmit} primaryDisabled={!props.submittable} primaryText={"送信"} />
    </AmeBox>
  );
};
