import React, { PropsWithChildren } from "react";

import { generateTimeDisplay } from "../../../../../hooks/useTimer";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { Pause } from "../../../../atoms/icon/Pause";
import { Play } from "../../../../atoms/icon/Play";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

type TProps = {
  play: (second?: number) => void;
  speakerLabel: string;
  second?: number;
  index: number;
  isTarget: boolean;
  isPlaying: boolean;
};
export const TranscriptionRowContainer: React.FC<PropsWithChildren<TProps>> = ({
  play,
  index,
  second,
  speakerLabel,
  isTarget,
  isPlaying,
  children,
}) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        padding: "13px 0",
        display: "flex",
        minHeight: "66px",
        justifyContent: "center",
        alignItems: "stretch",
        opacity: isTarget ? "unset" : 0.5,
      }}
    >
      <AmeBox
        sx={{
          width: "45px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "12px",
          flexShrink: 0,
          borderRight: `1px solid ${theme.common.border.gray40}`,
        }}
      >
        {index}
      </AmeBox>
      <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <AmeBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "24px",
            width: "24px",
            background: isTarget ? theme.brand.primary["100"] : theme.brand.secondary["110"],
            borderRadius: 50,
            color: "#FFF",
          }}
        >
          {speakerLabel}
        </AmeBox>
        <AmeBox
          onClick={() => play(second)}
          sx={{ display: "flex", alignItems: "end", gap: "4px", marginRight: "16px", cursor: "pointer" }}
        >
          <span>{generateTimeDisplay(second)}</span>
          {isPlaying ? <Pause size={16} color={"black"} /> : <Play size={16} color={"black"} />}
        </AmeBox>
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1 }}>{children}</AmeBox>
    </AmeBox>
  );
};
