/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateRuleRequestBodyString
 */
export interface CreateRuleRequestBodyString {
    /**
     * 
     * @type {string}
     * @memberof CreateRuleRequestBodyString
     */
    subCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleRequestBodyString
     */
    esQuery: string;
}

export function CreateRuleRequestBodyStringFromJSON(json: any): CreateRuleRequestBodyString {
    return CreateRuleRequestBodyStringFromJSONTyped(json, false);
}

export function CreateRuleRequestBodyStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRuleRequestBodyString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'esQuery': json['esQuery'],
    };
}

export function CreateRuleRequestBodyStringToJSON(value?: CreateRuleRequestBodyString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subCategoryId': value.subCategoryId,
        'esQuery': value.esQuery,
    };
}


