/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HrInterviewFeedbackQuestionResponse
 */
export interface HrInterviewFeedbackQuestionResponse {
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFeedbackQuestionResponse
     */
    evaluationItem: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFeedbackQuestionResponse
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewFeedbackQuestionResponse
     */
    hrInterviewFeedbackQuestionId: string;
}

export function HrInterviewFeedbackQuestionResponseFromJSON(json: any): HrInterviewFeedbackQuestionResponse {
    return HrInterviewFeedbackQuestionResponseFromJSONTyped(json, false);
}

export function HrInterviewFeedbackQuestionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewFeedbackQuestionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evaluationItem': json['evaluationItem'],
        'question': json['question'],
        'hrInterviewFeedbackQuestionId': json['hrInterviewFeedbackQuestionId'],
    };
}

export function HrInterviewFeedbackQuestionResponseToJSON(value?: HrInterviewFeedbackQuestionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evaluationItem': value.evaluationItem,
        'question': value.question,
        'hrInterviewFeedbackQuestionId': value.hrInterviewFeedbackQuestionId,
    };
}


