import React from "react";

import { useGetCategoryQuery } from "../../../../../store/hooks/categories";
import { useSubCategoriesByCategoryIdQuery } from "../../../../../store/hooks/subCategories";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { RulePaginationContainer } from "../RuleTable/RulePaginationContainer";

import { SubCategoryList } from "./SubCategoryList";

interface Props {
  categoryId: string;
}

export const SubCategoryRuleViewer: React.FC<Props> = ({ categoryId }) => {
  const { isSuccess: categoryIsSuccess, data: category } = useGetCategoryQuery(categoryId);
  const { isSuccess: subCategoryAreSuccess, data: subCategories } = useSubCategoriesByCategoryIdQuery(categoryId, {
    pollingInterval: 1000 * 10,
  });

  if (categoryIsSuccess) {
    return (
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader
          items={[
            {
              title: "設定",
              to: "/settings",
            },
            {
              title: "ルール変更",
              to: "/settings/rules/sub-categories",
            },
            {
              title: "サブカテゴリー",
              to: "/settings/rules",
            },
          ]}
          currentTitle={category.displayName}
        />

        <PageLayoutBody type="wide">
          {subCategoryAreSuccess ? (
            <RulePaginationContainer categoryId={category.categoryId} allItems={subCategories}>
              {(currentItems) => <SubCategoryList subCategories={currentItems} />}
            </RulePaginationContainer>
          ) : null}
        </PageLayoutBody>
      </PageLayoutWrapper>
    );
  }
  return null;
};
