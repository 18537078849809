/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartLabelingSessionResponseBody
 */
export interface StartLabelingSessionResponseBody {
    /**
     * 
     * @type {number}
     * @memberof StartLabelingSessionResponseBody
     */
    totalCount: number;
    /**
     * 
     * @type {string}
     * @memberof StartLabelingSessionResponseBody
     */
    labelingSessionId?: string;
}

export function StartLabelingSessionResponseBodyFromJSON(json: any): StartLabelingSessionResponseBody {
    return StartLabelingSessionResponseBodyFromJSONTyped(json, false);
}

export function StartLabelingSessionResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartLabelingSessionResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'labelingSessionId': !exists(json, 'labelingSessionId') ? undefined : json['labelingSessionId'],
    };
}

export function StartLabelingSessionResponseBodyToJSON(value?: StartLabelingSessionResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'labelingSessionId': value.labelingSessionId,
    };
}


