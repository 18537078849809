import { UserListResponse, UserResponse, UserRefResponse, UserDetailResponse } from "../store/autogenApi";
import type { UserStatusType } from "../store/autogenApi";

import { UserGroupRef } from "./UserGroup";

export class UserDetail {
  private constructor(
    readonly userId: string | undefined,
    readonly givenName: string,
    readonly surname: string,
    readonly isAdmin: boolean,
    readonly userGroups: UserGroupRef[],
    readonly email: string,
    readonly phoneNumber: string,
    readonly userStatus: UserStatusType,
    readonly updatedAt?: Date,
    readonly createdAt?: Date,
  ) {}

  public getFullName(): string {
    return `${this.surname} ${this.givenName}`;
  }

  public static fromResponse(response: UserDetailResponse): UserDetail {
    return new UserDetail(
      response.userId,
      response.givenName,
      response.surname,
      response.isAdmin,
      response.userGroups.map(UserGroupRef.fromResponse),
      response.email,
      response.phoneNumber,
      response.userStatus,
      response.updatedAt ? new Date(response.updatedAt) : undefined,
      response.createdAt ? new Date(response.createdAt) : undefined,
    );
  }
}

export class User {
  private constructor(
    readonly userId: string | undefined,
    readonly givenName: string,
    readonly surname: string,
    readonly isAdmin: boolean,
    readonly userGroups: UserGroupRef[],
    readonly updatedAt?: Date,
    readonly createdAt?: Date,
  ) {}

  public getFullName(): string {
    return `${this.surname} ${this.givenName}`;
  }

  public static fromResponse(response: UserResponse): User {
    return new User(
      response.userId,
      response.givenName,
      response.surname,
      response.isAdmin,
      response.userGroups.map(UserGroupRef.fromResponse),
      response.updatedAt ? new Date(response.updatedAt) : undefined,
      response.createdAt ? new Date(response.createdAt) : undefined,
    );
  }
}

export class UserRef {
  private constructor(
    readonly userId: string,
    readonly givenName: string,
    readonly surname: string,
    readonly isAdmin: boolean,
    readonly isUnknownUser: boolean,
    readonly updatedAt: Date,
    readonly createdAt: Date,
  ) {}

  public getFullName(): string {
    return `${this.surname} ${this.givenName}`;
  }

  public static fromResponse(response: UserRefResponse): UserRef {
    return new UserRef(
      response.userId,
      response.givenName,
      response.surname,
      response.isAdmin,
      response.isUnknownUser,
      new Date(response.updatedAt),
      new Date(response.createdAt),
    );
  }
}

export class UserList {
  constructor(
    readonly totalCount: number,
    readonly users: User[],
  ) {}

  public static fromResponse(response: UserListResponse): UserList {
    return new UserList(response.totalCount, response.users.map(User.fromResponse));
  }
}
