import React, { ChangeEvent, useCallback, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useInterviewQualityReport } from "../../../../../store/hooks/interviewer";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { InterviewerBasicInformation } from "../../../../organisms/v2/interviewers/interviewerId/InterviewerBasicInformation";
import { InterviewerQualityDetail } from "../../../../organisms/v2/interviewers/interviewerId/InterviewerQualityDetail";
import { InterviewerScoreTimeSeriesGraph } from "../../../../organisms/v2/interviewers/interviewerId/InterviewerScoreTimeSeriesGraph";

export const InterviewFeedbackPage: React.FC = () => {
  const { interviewerId } = Route.useParams();
  const interviewQualityReportQuery = useInterviewQualityReport(interviewerId);
  const [selectedInterviewFileId, setSelectedInterviewFileId] = useState<string | undefined>();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedInterviewFileId(e.target.value);
    },
    [setSelectedInterviewFileId],
  );

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "品質全体レポート", to: "/interview-feedbacks" }]}
        currentTitle="品質個別レポート"
      />
      <PageLayoutBody>
        <WaitForSuccess queryState={interviewQualityReportQuery}>
          {(interviewQualityReport) => (
            <AmeBox sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <AmeBox sx={{ width: "100%" }}>
                <InterviewerBasicInformation
                  groupName={interviewQualityReport.userGroups.map(({ displayName }) => displayName).join(", ")}
                  name={`${interviewQualityReport.surname} ${interviewQualityReport.givenName}`}
                  numOfInterviews={interviewQualityReport.interviewCount}
                  averageScore={interviewQualityReport.averageQualityScore}
                />
                <Spacer height="24px" />
                <InterviewerScoreTimeSeriesGraph feedbacks={interviewQualityReport.feedbacks} />
                <Spacer height="24px" />
                <InterviewerQualityDetail
                  feedbacks={interviewQualityReport.feedbacks}
                  selectedInterviewFileId={selectedInterviewFileId}
                  onChange={onChange}
                />
              </AmeBox>
            </AmeBox>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/interview-feedbacks/$interviewerId")({
  component: InterviewFeedbackPage,
});
