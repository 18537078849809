/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TranscriptionResult,
    TranscriptionResultFromJSON,
    TranscriptionResultFromJSONTyped,
    TranscriptionResultToJSON,
    TranscriptionStatus,
    TranscriptionStatusFromJSON,
    TranscriptionStatusFromJSONTyped,
    TranscriptionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface TranscriptionResponse
 */
export interface TranscriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof TranscriptionResponse
     */
    text: string;
    /**
     * 
     * @type {TranscriptionStatus}
     * @memberof TranscriptionResponse
     */
    status: TranscriptionStatus;
    /**
     * 
     * @type {TranscriptionResult}
     * @memberof TranscriptionResponse
     */
    results: TranscriptionResult;
    /**
     * 
     * @type {string}
     * @memberof TranscriptionResponse
     */
    retiredSpeakerLabel: string;
    /**
     * 
     * @type {string}
     * @memberof TranscriptionResponse
     */
    interviewFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof TranscriptionResponse
     */
    transcriptionId: string;
}

export function TranscriptionResponseFromJSON(json: any): TranscriptionResponse {
    return TranscriptionResponseFromJSONTyped(json, false);
}

export function TranscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'status': TranscriptionStatusFromJSON(json['status']),
        'results': TranscriptionResultFromJSON(json['results']),
        'retiredSpeakerLabel': json['retiredSpeakerLabel'],
        'interviewFileId': !exists(json, 'interviewFileId') ? undefined : json['interviewFileId'],
        'transcriptionId': json['transcriptionId'],
    };
}

export function TranscriptionResponseToJSON(value?: TranscriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'status': TranscriptionStatusToJSON(value.status),
        'results': TranscriptionResultToJSON(value.results),
        'retiredSpeakerLabel': value.retiredSpeakerLabel,
        'interviewFileId': value.interviewFileId,
        'transcriptionId': value.transcriptionId,
    };
}


