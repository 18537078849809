import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Elipse: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 6 6" size={6} sx={{ fill: "none" }}>
      <circle cx="3" cy="3" r="3" fill="currentColor" />
    </SvgWrapper>
  );
};
