import React from "react";

import { Add } from "@mui/icons-material";
import { createFileRoute, Link } from "@tanstack/react-router";

import { useSp } from "../../../../../../hooks/useSp";
import { useGetHrInterviewFiles } from "../../../../../../store/hooks/hrInterviewFiles";
import { useGetUserListQuery } from "../../../../../../store/hooks/users";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { AmeIconButton } from "../../../../../atoms/button/AmeIconButton";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmePagination } from "../../../../../atoms/pagination/AmePagination";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import {
  InterviewFileListContent,
  InterviewReportQuery,
} from "../../../../../organisms/hr-interviews/interview-files/index";
import { SpHrInterviewFileTable } from "../../../../../organisms/hr-interviews/interview-files/index/SpHrInterviewFileTable";

const FILE_COUNT_PER_PAGE = 100;

const InterviewFileListPage: React.FC = () => {
  const searchParams = Route.useSearch();
  const sp = useSp();
  const navigate = Route.useNavigate();
  const getUserQuery = useGetUserListQuery();
  const interviewFilesQuery = useGetHrInterviewFiles(
    FILE_COUNT_PER_PAGE,
    (searchParams.page - 1) * FILE_COUNT_PER_PAGE,
    {
      from: searchParams.from,
      to: searchParams.to,
      uploaderUserIds: searchParams.uploaderIds,
      linkWithInterview: searchParams.isLinked,
    },
  );
  return (
    <PageLayoutWrapper>
      <PageLayoutHeader
        title={"在職者音声一覧"}
        endElement={<AmeButton to={"/hr-interviews/interview-files/create"}>音声登録</AmeButton>}
      />
      {sp ? (
        <AmeBox sx={{ position: "fixed", bottom: "24px", right: "24px" }}>
          <Link to={"/hr-interviews/interview-files/create"}>
            <AmeIconButton color={"primary"}>
              <Add color={"inherit"} />
            </AmeIconButton>
          </Link>
        </AmeBox>
      ) : null}
      <PageLayoutBody type={"wide"}>
        <WaitForSuccess queryState={interviewFilesQuery}>
          {(interviewFiles) => (
            <WaitForSuccess queryState={getUserQuery}>
              {(data) => (
                <>
                  {sp ? (
                    <SpHrInterviewFileTable interviewFiles={interviewFiles.items} />
                  ) : (
                    <InterviewFileListContent
                      users={data.users}
                      searchParams={searchParams}
                      interviewFiles={interviewFiles.items}
                      totalCount={interviewFiles.totalCount}
                      navigate={navigate}
                    />
                  )}
                  <Spacer height={"16px"} />
                  <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <AmePagination
                      page={searchParams.page}
                      count={Math.ceil(interviewFiles.totalCount / FILE_COUNT_PER_PAGE)}
                      onChangePage={async (page) => {
                        await navigate({
                          search: (prev) => {
                            return { ...prev, page };
                          },
                        });
                      }}
                    />
                  </AmeBox>
                </>
              )}
            </WaitForSuccess>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/hr-interviews/interview-files/")({
  component: InterviewFileListPage,
  validateSearch: (input: Partial<InterviewReportQuery>): InterviewReportQuery => {
    return {
      page: input.page || 1,
      from: input.from || undefined,
      to: input.to || undefined,
      isLinked: input.isLinked || [],
      uploaderIds: input.uploaderIds || [],
    };
  },
});
