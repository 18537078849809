import React from "react";

import { Control, useFieldArray, UseFormRegister, useFormState } from "react-hook-form";

import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../../../atoms/table/AmeTable";
import { EditableCell } from "../../../../../../atoms/table/EditableCell";
import { HrInterviewAddingRow } from "../../../HrInterviewAddingRow";

import { HrInterviewQuestionSettingOperationCell } from "./HrInterviewQuestionSettingOperationCell";

export interface HrInterviewQuestionForm {
  hrInterviewQuestions: {
    hrInterviewQuestionId?: string;
    hrInterviewQuestionText: string;
  }[];
}

interface Props {
  register: UseFormRegister<HrInterviewQuestionForm>;
  control: Control<HrInterviewQuestionForm, never>;
}

export const HrInterviewQuestionsSettingTable: React.FC<Props> = (props) => {
  const { fields, append, swap, remove } = useFieldArray({
    control: props.control,
    name: "hrInterviewQuestions",
  });
  const formState = useFormState({ control: props.control, name: "hrInterviewQuestions" });

  return (
    <React.Fragment>
      <AmeTable useCustomCellWidth>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell width="128px">表示順番</AmeTableHeadCell>
            <AmeTableHeadCell>設問</AmeTableHeadCell>
            <AmeTableHeadCell width="192px">操作</AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {fields.map((field, index) => (
            <AmeTableRow key={field.id} isDense>
              <AmeTableBodyCell>{index + 1}</AmeTableBodyCell>
              <AmeTableBodyCell noPadding>
                <EditableCell
                  {...props.register(`hrInterviewQuestions.${index}.hrInterviewQuestionText`, {
                    required: "必須項目です。",
                  })}
                  error={Boolean(formState.errors.hrInterviewQuestions?.[index]?.hrInterviewQuestionText)}
                />
              </AmeTableBodyCell>
              <AmeTableBodyCell>
                <HrInterviewQuestionSettingOperationCell
                  index={index}
                  rowLastIndex={fields.length - 1}
                  swap={swap}
                  remove={remove}
                />
              </AmeTableBodyCell>
            </AmeTableRow>
          ))}
          <AmeTableRow>
            <AmeTableHeadCell noPadding colSpan={3}>
              <HrInterviewAddingRow
                append={() => append({ hrInterviewQuestionId: undefined, hrInterviewQuestionText: "" })}
              />
            </AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableBody>
      </AmeTable>
    </React.Fragment>
  );
};
