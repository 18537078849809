import React, { useMemo } from "react";

import { Box } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

type Component = "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
type FontSize = "body1" | "body2" | "caption" | "button";
type TextAlign = "left" | "center" | "right";
type Color = "secondary" | "basic" | "basicLight" | "warning" | "white" | "disabled";

type Props<C extends Component, F extends C extends Exclude<Component, "div" | "p"> ? undefined : FontSize> = {
  children?: React.ReactNode;
  /** デフォルト値：body1。候補値（body1：16px, body2：14px）。componentが、divかpのときのみ指定可能。 */
  fontSize?: F;
  /** デフォルト値：p。 */
  component?: C;
  omit?: boolean;
  color?: Color;
  textAlign?: TextAlign;
};

const NOTO_SANS_JP = "'Noto Sans CJK JP', sans-serif";

export const AmeTypography = <
  C extends Component,
  F extends C extends Exclude<Component, "div" | "p"> ? undefined : FontSize,
>(
  props: Props<C, F>,
) => {
  const fontSize = props.fontSize ?? "body1";
  const component = props.component ?? "p";
  return (
    <Box
      component={component}
      sx={useTypographyStyle(
        component,
        fontSize,
        props.omit ?? false,
        props.color ?? "basic",
        props.textAlign ?? "left",
      )}
    >
      {props.children}
    </Box>
  );
};

const OMIT_STYLE: SystemStyleObject = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const useTypographyStyle = (
  component: Component,
  fontSize: FontSize,
  omit: boolean,
  color: Color,
  textAlign: TextAlign,
): SystemStyleObject[] => {
  const theme = useAmeTheme();
  const colorCode = useMemo<string>(() => {
    switch (color) {
      case "basic":
        return theme.common.text.black;
      case "basicLight":
        return theme.common.text.gray40;
      case "secondary":
        return theme.brand.secondary["100"];
      case "warning":
        return theme.common.text.warning;
      case "white":
        return theme.common.text.white;
      case "disabled":
        return theme.common.text.gray20;
    }
  }, [color, theme]);

  return useMemo(() => {
    const styles: SystemStyleObject[] = [
      {
        fontFamily: NOTO_SANS_JP,
        color: colorCode,
        whiteSpace: "pre-line",
        textAlign: textAlign,
      },
    ];
    if (omit) {
      styles.push(OMIT_STYLE);
    }

    switch (component) {
      case "h1":
        styles.push(theme.typography.h1);
        break;
      case "h2":
        styles.push(theme.typography.h2);
        break;
      case "h3":
        styles.push(theme.typography.h3);
        break;
      case "h4":
        styles.push(theme.typography.h4);
        break;
      case "h5":
        styles.push(theme.typography.h5);
        break;
      case "h6":
        styles.push(theme.typography.h6);
        break;
      case "div":
      case "p":
        switch (fontSize) {
          case "body1":
            styles.push(theme.typography.body1);
            break;
          case "body2":
            styles.push(theme.typography.body2);
            break;
          case "caption":
            styles.push(theme.typography.caption);
            break;
          case "button":
            styles.push(theme.typography.button);
            break;
        }
    }

    return styles;
  }, [
    colorCode,
    omit,
    component,
    theme.typography.h1,
    theme.typography.h2,
    theme.typography.h3,
    theme.typography.h4,
    theme.typography.h5,
    theme.typography.h6,
    theme.typography.body1,
    theme.typography.body2,
    theme.typography.caption,
    theme.typography.button,
    fontSize,
    textAlign,
  ]);
};
