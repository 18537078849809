import React, { useCallback } from "react";

import { EMAIL_REG } from "@amecloud/common-utils/src/utils/Validation";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SurveyModel } from "../../../../../models/Survey";
import { useSendSampleSurveySheet } from "../../../../../store/hooks/surveys";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeFormset } from "../../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmeModal } from "../../../../atoms/modal/AmeModal";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  survey: SurveyModel;
  isOpen: boolean;
  onClose: () => void;
}

export const SendSampleSurveyModal: React.FC<Props> = (props) => {
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ emailAddress: string }>({
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      emailAddress: "",
    },
  });
  const sendSampleSurveySheet = useSendSampleSurveySheet();

  const onSubmit = useCallback(
    async ({ emailAddress }: { emailAddress: string }) => {
      const result = await sendSampleSurveySheet({
        surveyId: props.survey.surveyId,
        sendSampleSurveySheetRequestBody: {
          emailAddress: emailAddress,
        },
      });
      if (result.isSuccess) {
        enqueueSuccessSnackbar({ title: "サンプルメールを送付しました。" });
        props.onClose();
      }
    },
    [sendSampleSurveySheet, enqueueSuccessSnackbar, props],
  );

  return (
    <AmeModal open={props.isOpen} onClose={props.onClose}>
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader items={[]} currentTitle="テスト送付" />
        <PageLayoutBody type="wide">
          <AmeBox component="form" onSubmit={handleSubmit(onSubmit)}>
            <AmeFormset label="メールアドレス" errorMessage={errors.emailAddress?.message} required>
              <AmeInput
                {...register("emailAddress", {
                  required: "メールアドレスは必須です。",
                  validate: (value) => EMAIL_REG.test(value) || "メールアドレスの形式が不正です。",
                })}
                error={errors?.emailAddress?.message !== undefined}
                fullWidth
              />
            </AmeFormset>
            <Spacer height="24px" />
            <AmeBox sx={{ display: "flex", justifyContent: "right", gap: "16px" }}>
              <AmeButton variant="outlined" onClick={props.onClose}>
                キャンセル
              </AmeButton>
              <AmeButton type="submit">送付</AmeButton>
            </AmeBox>
          </AmeBox>
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeModal>
  );
};
