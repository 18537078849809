import { useEffect, useMemo, useState } from "react";

import { Semaphore } from "@amecloud/common-utils/src/utils/Semaphore";

const MAX_CONCURRENCY = 3;
export const useParallelController = () => {
  const [abortController] = useState(new AbortController());
  useEffect(() => {
    return () => abortController.abort();
  }, [abortController]);
  return useMemo(() => {
    const semaphore = new Semaphore(MAX_CONCURRENCY);
    return async (task: () => Promise<void>) => {
      await semaphore.acquire();
      try {
        if (!abortController.signal.aborted) {
          await task();
        }
      } finally {
        semaphore.release();
      }
    };
  }, [abortController]);
};
