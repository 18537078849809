import React, { useMemo } from "react";

import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { Setting } from "../../../../models/Setting";
import { SubCategoryList } from "../../../../models/SubCategory";
import { CategoryModel, useGetAllCategories } from "../../../../store/hooks/categories";
import { Period, useCurrentPeriodQuery, usePeriodsQuery } from "../../../../store/hooks/periods";
import { useGetSetting } from "../../../../store/hooks/settings";
import { useSubCategories } from "../../../../store/hooks/subCategories";
import { WaitForFetch } from "../../../molecules/Loading/WaitForFetch";
import { WaitForSuccess } from "../../../molecules/Loading/WaitForSuccess";

import { DataSourceInitializer } from "./DataSourceInitializer";

interface LocalStorageData<T> {
  value: T;
  setValue: (value: T) => void;
}
interface LocalStorage {
  isWarning: LocalStorageData<boolean>;
}
interface DataSourceContextType {
  latestPeriod: Period;
  period: Period;
  periods: Period[];
  previousPeriod: Period;
  setting: Setting;
  subCategories: SubCategoryList;
  categories: CategoryModel[];
  localStorage: LocalStorage;
}

export const DataSourceContext = React.createContext<DataSourceContextType>({
  latestPeriod: {} as never,
  period: {} as never,
  periods: {} as never,
  previousPeriod: {} as never,
  setting: {} as never,
  subCategories: {} as never,
  categories: {} as never,
  localStorage: {} as never,
});

/**
 * 共通データの存在確認する箇所
 */
export const DataSourceContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const periodsQuery = usePeriodsQuery();
  const settingQuery = useGetSetting();
  const categoriesQuery = useGetAllCategories();
  const subCategoriesQuery = useSubCategories();
  const currentPeriodQuery = useCurrentPeriodQuery();
  const [isWarning, setIsWarning] = useLocalStorage<"on" | "off">("isWarning", "off");
  const localStorage = useMemo<LocalStorage>(() => {
    return {
      isWarning: {
        value: isWarning === "on",
        setValue: (v) => setIsWarning(v ? "on" : "off"),
      },
    };
  }, [isWarning, setIsWarning]);
  return (
    <WaitForFetch queryState={periodsQuery}>
      {(periods: Period[] = []) => {
        if (periods.length) {
          // periodがある場合（通常処理）
          return (
            <WaitForSuccess queryState={currentPeriodQuery}>
              {({ current, previous }) => {
                if (current && previous) {
                  return (
                    <WaitForSuccess queryState={settingQuery}>
                      {(setting) => (
                        <WaitForSuccess queryState={categoriesQuery}>
                          {(categories) => (
                            <WaitForSuccess queryState={subCategoriesQuery}>
                              {(subCategories) => (
                                <DataSourceContext.Provider
                                  value={{
                                    latestPeriod: current,
                                    period: current,
                                    periods: periods,
                                    previousPeriod: previous,
                                    setting: setting,
                                    subCategories: subCategories,
                                    categories: categories,
                                    localStorage: localStorage,
                                  }}
                                >
                                  {props.children}
                                </DataSourceContext.Provider>
                              )}
                            </WaitForSuccess>
                          )}
                        </WaitForSuccess>
                      )}
                    </WaitForSuccess>
                  );
                } else {
                  return <>TODO: impl 「当期」のperiodが存在しません。「当期」がなかった場合の処理に思いを馳せる。</>;
                }
              }}
            </WaitForSuccess>
          );
        } else {
          // periodがない時 => 初めてログインされて、データが一つも存在しない時
          return <DataSourceInitializer />;
        }
      }}
    </WaitForFetch>
  );
};
