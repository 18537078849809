import React from "react";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { formatDateToYYYYMMDD } from "../../../../../../utils/formatter";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { DefinitionList } from "../../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { InterviewAnalysisStatus } from "../../../common/InterviewAnalysisStatus";
import { InterviewFeedbackStatus } from "../../../common/InterviewFeedbackStatus";

interface Props {
  interviewDetail: HrInterviewDetail;
}

export const InterviewOutline: React.FC<Props> = ({ interviewDetail }) => {
  return (
    <PaperWrapper>
      <PaperHeader
        endElement={
          <AmeButton
            variant="outlined"
            color="basic"
            size="small"
            to={"/hr-interviews/interviews/$interviewId/edit"}
            params={{ interviewId: interviewDetail.hrInterviewId }}
          >
            編集
          </AmeButton>
        }
      >
        面談概要
      </PaperHeader>
      <PaperBody>
        <DefinitionList>
          <DefinitionListItem
            label="面談ステータス"
            value={
              <AmeBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <InterviewAnalysisStatus status={interviewDetail.analysisStatus} />
              </AmeBox>
            }
          />
          <DefinitionListItem
            label="アンケートステータス"
            value={
              <AmeBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <InterviewFeedbackStatus status={interviewDetail.feedbackStatus} />
              </AmeBox>
            }
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="面談種類" value={interviewDetail.interviewType.displayName} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="面談フォーマット" value={interviewDetail.interviewFormat.displayName} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            label="面談日"
            value={interviewDetail.interviewDate ? formatDateToYYYYMMDD(interviewDetail.interviewDate) : undefined}
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="面談者" value={interviewDetail.interviewer?.getFullName()} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="面談対象者" value={interviewDetail.employee?.getEmployeeDisplayName()} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="グループ" value={interviewDetail.userGroup?.displayName} />
        </DefinitionList>
      </PaperBody>
    </PaperWrapper>
  );
};
