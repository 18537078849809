/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyReportResponseResponseStats,
    SurveyReportResponseResponseStatsFromJSON,
    SurveyReportResponseResponseStatsFromJSONTyped,
    SurveyReportResponseResponseStatsToJSON,
    SurveyReportResponseScoreStats,
    SurveyReportResponseScoreStatsFromJSON,
    SurveyReportResponseScoreStatsFromJSONTyped,
    SurveyReportResponseScoreStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SurveyReportResponse
 */
export interface SurveyReportResponse {
    /**
     * 
     * @type {Array<SurveyReportResponseResponseStats>}
     * @memberof SurveyReportResponse
     */
    responseStats: Array<SurveyReportResponseResponseStats>;
    /**
     * 
     * @type {Array<SurveyReportResponseScoreStats>}
     * @memberof SurveyReportResponse
     */
    scoreStats: Array<SurveyReportResponseScoreStats>;
}

export function SurveyReportResponseFromJSON(json: any): SurveyReportResponse {
    return SurveyReportResponseFromJSONTyped(json, false);
}

export function SurveyReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responseStats': ((json['responseStats'] as Array<any>).map(SurveyReportResponseResponseStatsFromJSON)),
        'scoreStats': ((json['scoreStats'] as Array<any>).map(SurveyReportResponseScoreStatsFromJSON)),
    };
}

export function SurveyReportResponseToJSON(value?: SurveyReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responseStats': ((value.responseStats as Array<any>).map(SurveyReportResponseResponseStatsToJSON)),
        'scoreStats': ((value.scoreStats as Array<any>).map(SurveyReportResponseScoreStatsToJSON)),
    };
}


