import React, { useCallback, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useGetSurveySheet, useSubmitSurveyAnswer } from "../../../../store/hooks/surveys";
import { RtkCustomHookError } from "../../../../store/hooks/utils/types";
import { WaitForFetch } from "../../../molecules/Loading/WaitForFetch";
import { PublicRouteTemplate } from "../../../molecules/layout/PublicRouteTemplate";
import { SurveyTemplate } from "../../../organisms/common/SurveyTemplate";
import {
  SurveyQuestionAnswer,
  SurveyQuestionAnswers,
} from "../../../organisms/common/SurveyTemplate/SurveyTemplateBody";
import { SurveyTemplateComplete } from "../../../organisms/common/SurveyTemplate/SurveyTemplateComplete";

const SurveyQuestionnairesPage: React.FC = () => {
  const { surveySheetId } = Route.useParams();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onSurveyRequestError = useCallback(
    (error: RtkCustomHookError) => {
      switch (error?.status) {
        case 409: {
          enqueueErrorSnackbar({
            title: "回答済みです",
            message:
              "このアンケートはすでに回答されています。未回答の方でこのメッセージが表示される方は担当者にご連絡ください。",
          });
          break;
        }
        case 404: {
          enqueueErrorSnackbar({
            title: "存在しないアンケートです",
            message: "URLが誤っている可能性があります。担当者に問い合わせてください。",
          });
          break;
        }
        default: {
          enqueueErrorSnackbar({ title: "不明なエラーが発生しました。", message: error?.message });
        }
      }
    },
    [enqueueErrorSnackbar],
  );

  const surveyQuestionsQuery = useGetSurveySheet(surveySheetId, { onError: onSurveyRequestError });
  const [answers, setAnswers] = useState<SurveyQuestionAnswers>(new Map());

  const handleChange = useCallback(
    (answer: SurveyQuestionAnswer) => {
      const copied = new Map(answers);
      copied.set(answer.questionId, answer);
      setAnswers(copied);
    },
    [answers, setAnswers],
  );

  const [submitted, setSubmitted] = useState(false);
  const submitSurveyAnswers = useSubmitSurveyAnswer();
  const handleSubmit = useCallback(async () => {
    const answerRequest = Array.from(answers.values()).map((answer) => ({
      value: Number(answer.value),
      surveyQuestionId: answer.questionId,
    }));
    const result = await submitSurveyAnswers({
      surveySheetId: surveySheetId,
      submitSurveyAnswerRequestBody: { answers: answerRequest },
    });
    if (result.isSuccess) {
      setSubmitted(true);
    }
  }, [answers, submitSurveyAnswers, surveySheetId]);

  return (
    <PublicRouteTemplate>
      <WaitForFetch queryState={surveyQuestionsQuery}>
        {(surveyQuestions) => {
          if (!surveyQuestions) {
            return null;
          }
          if (submitted) {
            return <SurveyTemplateComplete />;
          }
          return (
            <SurveyTemplate
              answers={answers}
              title={surveyQuestions.title}
              questions={surveyQuestions.questions.map((q) => ({
                questionId: q.surveyQuestionId,
                questionText: q.questionText,
              }))}
              description={surveyQuestions.description}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
          );
        }}
      </WaitForFetch>
    </PublicRouteTemplate>
  );
};

export const Route = createFileRoute("/survey-sheets/$surveySheetId/")({
  component: SurveyQuestionnairesPage,
});
