import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const RadioButtonChecked: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16" size={16}>
      <rect width="16" height="16" rx="8" fill="#00ACC1" />
      <rect x="5" y="5" width="6" height="6" rx="3" fill="white" />
    </SvgWrapper>
  );
};
