import React from "react";

import { Link } from "@tanstack/react-router";

import { HrInterviewFile } from "../../../../../models/hrInterview/HrInterviewFile";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { ArrowRight } from "../../../../atoms/icon/ArrowRight";
import { CheckV2Icon } from "../../../../atoms/icon/CheckV2";
import { Elipse } from "../../../../atoms/icon/Elipse";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { IconText } from "../../../../molecules/common/IconText";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  interviewFiles: HrInterviewFile[];
}

export const SpHrInterviewFileTable: React.FC<Props> = ({ interviewFiles }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        border: `1px solid ${theme.common.border.gray40}`,
        "& > * + *": { borderTop: `1px solid ${theme.common.border.gray40}` },
      }}
    >
      {interviewFiles.map((file) => (
        <AmeBox key={file.hrInterviewFileId}>
          <Link
            to={"/hr-interviews/interview-files/$interviewFileId"}
            params={{ interviewFileId: file.hrInterviewFileId }}
          >
            <AmeBox sx={{ display: "flex", alignItems: "center", gap: "16px", padding: "8px 16px", minWidth: 0 }}>
              <AmeBox
                sx={{ display: "flex", flexGrow: 1, flexShrink: 1, minWidth: 0, justifyContent: "space-between" }}
              >
                <AmeBox
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexShrink: 1,
                    flexDirection: "column",
                    minWidth: 0,
                    maxWidth: "60%",
                  }}
                >
                  <AmeBox sx={{ flexShrink: 1, minWidth: 0 }}>
                    <AmeTypography fontSize={"body2"} omit>
                      <AmeBox sx={{ fontWeight: "bold" }} component={"span"}>
                        {file.originalFileName}
                      </AmeBox>
                    </AmeTypography>
                  </AmeBox>
                  <AmeBox sx={{ flexShrink: 1 }}>
                    <AmeTypography omit fontSize={"caption"}>
                      {file.createdAt.toLocaleDateString()}
                    </AmeTypography>
                  </AmeBox>
                </AmeBox>
                <AmeBox
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <AmeBox sx={{ flexShrink: 1, minWidth: 0 }}>
                    {file.hrInterview ? (
                      <IconText icon={<CheckV2Icon color={"success"} />}>
                        <AmeTypography fontSize={"caption"}>面談紐づけ済</AmeTypography>
                      </IconText>
                    ) : (
                      <IconText icon={<Elipse />}>
                        <AmeTypography fontSize={"caption"}>面談紐づけ前</AmeTypography>
                      </IconText>
                    )}
                  </AmeBox>
                </AmeBox>
              </AmeBox>
              <AmeBox>
                <ArrowRight color={"black"} />
              </AmeBox>
            </AmeBox>
          </Link>
        </AmeBox>
      ))}
    </AmeBox>
  );
};
