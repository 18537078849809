import React, { useCallback, useState } from "react";

import { SurveyStatus } from "@amecloud/api-client/src-openapi-autogen";
import { createFileRoute } from "@tanstack/react-router";

import { useGetSurveyList } from "../../../../../store/hooks/surveys";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmePagination } from "../../../../atoms/pagination/AmePagination";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { WaitForData } from "../../../../molecules/Loading/WaitForData";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { SelectSurveySendingMethodModal } from "../../../../organisms/surveys/index/SelectSurveySendingMethodModal";
import { SurveySendingMethodBodyCellContent } from "../../../../organisms/surveys/index/cells/SurveySendingMethodBodyCellContent";
import { SurveyStatusBodyCellContent } from "../../../../organisms/surveys/index/cells/SurveyStatusBodyCellContent";

const SURVEY_COUNT_PER_PAGE = 25;
const SurveyListPage: React.FC = () => {
  const { page } = Route.useSearch();
  const navigate = Route.useNavigate();
  const onChangePage = useCallback(
    async (pageNumber: number) => {
      await navigate({
        search: (prev) => ({ ...prev, page: pageNumber }),
      });
    },
    [navigate],
  );
  const getSurveyListQuery = useGetSurveyList({
    count: SURVEY_COUNT_PER_PAGE,
    offset: SURVEY_COUNT_PER_PAGE * (page - 1),
  });

  const [selectModalIsOpen, setSelectModalIsOpen] = useState<boolean>(false);

  const onClickQuestionnaireCreate = useCallback(() => {
    setSelectModalIsOpen(true);
  }, [setSelectModalIsOpen]);

  const onCloseSelectModal = useCallback(() => {
    setSelectModalIsOpen(false);
  }, [setSelectModalIsOpen]);

  return (
    <>
      <SelectSurveySendingMethodModal isOpen={selectModalIsOpen} onClose={onCloseSelectModal} />
      <PageLayoutWrapper>
        <PageLayoutHeader
          title="アンケート送付管理"
          endElement={<AmeButton onClick={onClickQuestionnaireCreate}>アンケート新規作成</AmeButton>}
        />
        <PageLayoutBody type="wide">
          <AmeTypography component={"h6"}>アンケート送付履歴</AmeTypography>
          <Spacer height={"24px"} />
          <AmeTypography component={"p"} fontSize={"body1"}>
            アンケートのメール送付には最大24時間かかります。
          </AmeTypography>
          <Spacer height={"24px"} />
          <AmeTable>
            <AmeTableHead>
              <AmeTableRow>
                <AmeTableHeadCell>アンケートタイトル</AmeTableHeadCell>
                <AmeTableHeadCell>送付方法</AmeTableHeadCell>
                <AmeTableHeadCell>開始日</AmeTableHeadCell>
                <AmeTableHeadCell>期日</AmeTableHeadCell>
                <AmeTableHeadCell>回答対象</AmeTableHeadCell>
                <AmeTableHeadCell>メール送付対象</AmeTableHeadCell>
                <AmeTableHeadCell>回答済み</AmeTableHeadCell>
                <AmeTableHeadCell>ステータス</AmeTableHeadCell>
              </AmeTableRow>
            </AmeTableHead>
            <AmeTableBody>
              <WaitForSuccess queryState={getSurveyListQuery}>
                {({ surveys }) => (
                  <>
                    {surveys.map((survey) => (
                      <AmeTableRow key={survey.surveyId}>
                        <AmeTableBodyCell>
                          <AmeButton variant="text" to={"/surveys/$surveyId"} params={{ surveyId: survey.surveyId }}>
                            {survey.displayName}
                          </AmeButton>
                        </AmeTableBodyCell>
                        <AmeTableBodyCell>
                          <SurveySendingMethodBodyCellContent
                            useQrcode={survey.useQrcode}
                            sendEmail={survey.sendEmail}
                          />
                        </AmeTableBodyCell>
                        <AmeTableBodyCell>
                          {survey.startedAt ? survey.startedAt.toLocaleDateString("ja-JP") : "-"}
                        </AmeTableBodyCell>
                        <AmeTableBodyCell>{survey.dueDateTime.toLocaleDateString("ja-JP")}</AmeTableBodyCell>
                        <AmeTableBodyCell>{survey.targetEmployeeCount}</AmeTableBodyCell>
                        <AmeTableBodyCell>{survey.emailSheetsCount}</AmeTableBodyCell>
                        <AmeTableBodyCell>
                          {survey.status !== SurveyStatus.DRAFT ? survey.collectedCount : "-"}
                        </AmeTableBodyCell>
                        <AmeTableBodyCell>
                          <SurveyStatusBodyCellContent status={survey.status} />
                        </AmeTableBodyCell>
                      </AmeTableRow>
                    ))}
                  </>
                )}
              </WaitForSuccess>
            </AmeTableBody>
          </AmeTable>
          <WaitForData queryState={getSurveyListQuery}>
            {({ totalCount }) => (
              <AmeBox sx={{ marginTop: "24px", width: "100%", display: "flex", justifyContent: "center" }}>
                <AmePagination
                  count={Math.ceil(totalCount / SURVEY_COUNT_PER_PAGE)}
                  page={page}
                  onChangePage={onChangePage}
                />
              </AmeBox>
            )}
          </WaitForData>
        </PageLayoutBody>
      </PageLayoutWrapper>
    </>
  );
};

type QueryParams = {
  page: number;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/surveys/")({
  component: SurveyListPage,
  validateSearch: (search: Partial<QueryParams>): QueryParams => {
    const page = search.page ?? 1;
    return {
      page: page,
    };
  },
});
