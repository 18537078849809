import React from "react";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

export const SurveyTemplateComplete: React.FC = () => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        bgcolor: theme.common.background.white,
        border: `1px solid ${theme.common.border.gray40}`,
        borderRadius: "8px",
        overflow: "hidden",
        margin: "24px",
      }}
    >
      <AmeBox
        component="h5"
        sx={{
          display: "flex",
          justifyContent: "center",
          bgcolor: theme.brand.secondary[20],
          borderBottom: `1px solid ${theme.common.border.gray40}`,
          paddingY: "16px",
          color: theme.brand.secondary[100],
          ...theme.typography.h5,
        }}
      >
        アンケートを送信しました。
      </AmeBox>
      <AmeBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingY: "24px",
        }}
      >
        <AmeTypography fontSize="body1">ご協力ありがとうございました。</AmeTypography>
        <AmeTypography fontSize="body1">ウィンドウを閉じてください。</AmeTypography>
      </AmeBox>
    </AmeBox>
  );
};
