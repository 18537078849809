import React from "react";

import { useSp } from "../../../../hooks/useSp";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  label: string;
  children: React.ReactNode;
}

export const InterviewRecordingDetailItem: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const sp = useSp();
  return (
    <AmeBox>
      {sp ? (
        <>
          <AmeBox
            sx={{
              borderBottom: `1px solid ${theme.common.border.gray40}`,
              paddingTop: "16px",
              paddingBottom: "16px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AmeTypography color="basicLight" fontSize="caption">
              {props.label}
            </AmeTypography>
            <AmeBox sx={{ width: "50%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <AmeTypography fontSize="body1" textAlign="right">
                {props.children}
              </AmeTypography>
            </AmeBox>
          </AmeBox>
        </>
      ) : (
        <>
          <AmeBox sx={{ borderBottom: `1px solid ${theme.common.border.gray40}`, paddingBottom: "8px" }}>
            <AmeTypography color="basicLight" fontSize="caption">
              {props.label}
            </AmeTypography>
            <AmeTypography fontSize="body1">{props.children}</AmeTypography>
          </AmeBox>
        </>
      )}
    </AmeBox>
  );
};
