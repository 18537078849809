import React from "react";

import { useNavigate } from "@tanstack/react-router";

import { HrInterviewDetail } from "../../../../../../../models/hrInterview/HrInterview";
import { useDeleteHrInterview } from "../../../../../../../store/hooks/hrInterview";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { AmeDialog } from "../../../../../../molecules/dialog/AmeDialog";

import { InterviewDeletionDialogContent } from "./InterviewDeletionDialogContent";

export const InterviewDeletionDialog: React.FC<{ interviewDetail: HrInterviewDetail }> = ({ interviewDetail }) => {
  const deleteInterview = useDeleteHrInterview();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  if (interviewDetail.analysisStatus !== undefined) {
    return null;
  }
  return (
    <>
      <AmeButton variant="outlined" color="basic" onClick={() => setIsOpen(true)}>
        削除
      </AmeButton>
      <AmeDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <InterviewDeletionDialogContent
          onClose={() => setIsOpen(false)}
          onSubmit={async () => {
            await deleteInterview({ hrInterviewId: interviewDetail.hrInterviewId });
            await navigate({
              to: "/hr-interviews/interviews",
              search: {} as never,
            });
          }}
        />
      </AmeDialog>
    </>
  );
};
