import React, { useCallback } from "react";

import { Delete } from "@mui/icons-material";

import { useDeleteRule } from "../../../../../store/hooks/rules";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../../../muiWrapper/AmeIconButton";

interface Props {
  ruleId: string;
}

export const RuleDeleteButton: React.FC<Props> = ({ ruleId }) => {
  const theme = useAmeTheme();
  const deleteRule = useDeleteRule();
  const onClick = useCallback(async () => {
    await deleteRule({ ruleId });
  }, [deleteRule, ruleId]);
  return (
    <AmeBox
      sx={{ display: "inline-flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}
    >
      <AmeIconButton onClick={onClick}>
        <Delete htmlColor={theme.common.background.gray120} />
      </AmeIconButton>
    </AmeBox>
  );
};
