/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewTypeResponse,
    InterviewTypeResponseFromJSON,
    InterviewTypeResponseFromJSONTyped,
    InterviewTypeResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewTypeListResponse
 */
export interface InterviewTypeListResponse {
    /**
     * 
     * @type {Array<InterviewTypeResponse>}
     * @memberof InterviewTypeListResponse
     */
    interviewTypes: Array<InterviewTypeResponse>;
}

export function InterviewTypeListResponseFromJSON(json: any): InterviewTypeListResponse {
    return InterviewTypeListResponseFromJSONTyped(json, false);
}

export function InterviewTypeListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewTypeListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interviewTypes': ((json['interviewTypes'] as Array<any>).map(InterviewTypeResponseFromJSON)),
    };
}

export function InterviewTypeListResponseToJSON(value?: InterviewTypeListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interviewTypes': ((value.interviewTypes as Array<any>).map(InterviewTypeResponseToJSON)),
    };
}


