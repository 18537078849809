/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewDocumentResponse,
    InterviewDocumentResponseFromJSON,
    InterviewDocumentResponseFromJSONTyped,
    InterviewDocumentResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchInterviewDocumentsResponse
 */
export interface SearchInterviewDocumentsResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchInterviewDocumentsResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<InterviewDocumentResponse>}
     * @memberof SearchInterviewDocumentsResponse
     */
    interviewDocuments: Array<InterviewDocumentResponse>;
}

export function SearchInterviewDocumentsResponseFromJSON(json: any): SearchInterviewDocumentsResponse {
    return SearchInterviewDocumentsResponseFromJSONTyped(json, false);
}

export function SearchInterviewDocumentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchInterviewDocumentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'interviewDocuments': ((json['interviewDocuments'] as Array<any>).map(InterviewDocumentResponseFromJSON)),
    };
}

export function SearchInterviewDocumentsResponseToJSON(value?: SearchInterviewDocumentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'interviewDocuments': ((value.interviewDocuments as Array<any>).map(InterviewDocumentResponseToJSON)),
    };
}


