/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRefResponse
 */
export interface UserRefResponse {
    /**
     * 
     * @type {Date}
     * @memberof UserRefResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserRefResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserRefResponse
     */
    isUnknownUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRefResponse
     */
    isAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRefResponse
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRefResponse
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof UserRefResponse
     */
    userAuthId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRefResponse
     */
    userId: string;
}

export function UserRefResponseFromJSON(json: any): UserRefResponse {
    return UserRefResponseFromJSONTyped(json, false);
}

export function UserRefResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRefResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'isUnknownUser': json['isUnknownUser'],
        'isAdmin': json['isAdmin'],
        'givenName': json['givenName'],
        'surname': json['surname'],
        'userAuthId': !exists(json, 'userAuthId') ? undefined : json['userAuthId'],
        'userId': json['userId'],
    };
}

export function UserRefResponseToJSON(value?: UserRefResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'isUnknownUser': value.isUnknownUser,
        'isAdmin': value.isAdmin,
        'givenName': value.givenName,
        'surname': value.surname,
        'userAuthId': value.userAuthId,
        'userId': value.userId,
    };
}


