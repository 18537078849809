import React from "react";

import { ACCEPTABLE_AUDIO_FILE_EXTENSIONS } from "../../../../../utils/constants";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { AudioFilesImporter } from "../common/AudioFilesImporter";

interface Props {
  setInterviewFiles: (files: File[]) => void;
}
export const InterviewFileImporter: React.FC<Props> = (props) => {
  return (
    <>
      <AmeTypography component="h6">音声データのファイルを選択してください</AmeTypography>
      <AmeBox>
        <AmeTypography fontSize={"body2"}>
          {ACCEPTABLE_AUDIO_FILE_EXTENSIONS.join("・")}ファイルに対応しています。1ファイル2GBまで。
        </AmeTypography>
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1 }}></AmeBox>
      <AmeBox sx={{ position: "sticky", bottom: 0 }}>
        <AudioFilesImporter onImported={props.setInterviewFiles} />
      </AmeBox>
    </>
  );
};
