import { SurveysQuestion } from "../../models/SurveyQuestion";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";

export const useGetSurveyQuestions = () => {
  const result = defaultApi.endpoints.getSurveyQuestions.useQuery(undefined);
  return useQueryResult({
    result,
    fromResponse: (response): SurveysQuestion[] => response,
  });
};

export const useRegisterSurveyQuestions = () => {
  const [registerSurveyQuestions] = defaultApi.endpoints.registerSurveyQuestions.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: registerSurveyQuestions,
  });
};
