import React from "react";

import { SelectChangeEvent } from "@mui/material";

import { ChoosableList } from "../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../atoms/list/ChoosableListItem";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeDropbox_old } from "../../../../../molecules/dropbox/AmeDropbox";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props<T extends string> {
  selectedAxis: T;
  axisDisplayNames: Record<T, string>;
  onChange: (e: SelectChangeEvent<T>) => void;
}

export const AxisSelect = <T extends string>({ selectedAxis, axisDisplayNames, onChange }: Props<T>) => {
  return (
    <AmeBox sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <AmeTypography>軸変更</AmeTypography>
      <AmeBox sx={{ width: "160px" }}>
        <AmeDropbox_old
          size="small"
          value={selectedAxis}
          displaySelected={axisDisplayNames[selectedAxis]}
          onChange={onChange}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ChoosableList>
            {Object.entries<string>(axisDisplayNames).map(([key, value]) => (
              <ChoosableListItem key={key} value={key}>
                {value}
              </ChoosableListItem>
            ))}
          </ChoosableList>
        </AmeDropbox_old>
      </AmeBox>
    </AmeBox>
  );
};
