import React, { useCallback } from "react";

import { EMAIL_REG } from "@amecloud/common-utils/src/utils/Validation";
import { Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { LoginButtonWrapper } from "./common/LoginButtonWrapper";
import { LoginDescriptionWrapper } from "./common/LoginDescriptionWrapper";
import { LoginErrorMessage } from "./common/LoginErrorMessage";
import { LoginFormsWrapper } from "./common/LoginFormsWrapper";
import { LoginInputWrapper } from "./common/LoginInputWrapper";
import { LoginLogoTitle } from "./common/LoginLogoTitle";
import { LoginPageWrapper } from "./common/LoginPageWrapper";
import { PageTransitionText } from "./common/PageTransitionText";

import { UserStateBeforeSignIn } from ".";

interface Props {
  setEmail: (email: string) => void;
  setUserStateBeforeSignIn: (userState: UserStateBeforeSignIn) => void;
}

export const SendConfirmationCode: React.FC<Props> = ({ setEmail, setUserStateBeforeSignIn }) => {
  const theme = useAmeTheme();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      email: "",
    },
  });

  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onSubmit = async (data: { email: string }) => {
    try {
      await Auth.forgotPassword(data.email);
      setEmail(data.email);
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        enqueueErrorSnackbar({ title: "ユーザが見つかりません。" });
        return;
      }
      enqueueErrorSnackbar();
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const onClickToSignIn = useCallback(() => {
    setUserStateBeforeSignIn("sign-in");
  }, [setUserStateBeforeSignIn]);

  return (
    <LoginPageWrapper>
      <LoginLogoTitle />
      <AmeBox
        sx={{
          padding: "40px 24px",
          border: `1px solid ${theme.common.border.gray40}`,
          background: theme.common.background.white,
          borderRadius: "4px",
        }}
      >
        <LoginDescriptionWrapper>
          登録済みのメールアドレスを入力してください。
          <br />
          パスワード再設定ページへのメールをお送りします。
        </LoginDescriptionWrapper>

        <LoginFormsWrapper onSubmit={handleSubmit(onSubmit)}>
          <LoginInputWrapper>
            <AmeBox sx={{ margin: "8px 0", color: theme.common.text.black, textAlign: "left" }}>メールアドレス</AmeBox>
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: "メールアドレスを入力してください。",
                pattern: { value: EMAIL_REG, message: "有効なメールアドレスを入力してください。" },
              }}
              render={({ field, fieldState }) => (
                <AmeInput {...field} placeholder={"メールアドレス"} error={fieldState.error !== undefined} fullWidth />
              )}
            />
            <LoginErrorMessage>{Object.values(errors.email?.types || {}).join("")}</LoginErrorMessage>
          </LoginInputWrapper>

          <LoginButtonWrapper>
            <AmeButton type="submit" fullWidth>
              送信
            </AmeButton>
          </LoginButtonWrapper>
        </LoginFormsWrapper>
        <PageTransitionText onClick={onClickToSignIn}>サインインへ戻る</PageTransitionText>
      </AmeBox>
    </LoginPageWrapper>
  );
};
