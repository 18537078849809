/** 小数のフォーマッタ */
export const formatNumber = (num: number | null, digits: number = 1): string => {
  if (num === null) {
    return "-";
  }
  return num.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

/** パーセントへのフォーマッタ */
export const formatToPercent = (num: number | null, digits: number = 1): string => {
  return `${formatNumber(num === null ? null : num * 100, digits)}%`;
};

/** すでに百分率になっているものを％の文字列へ変換するフォーマッタ */
export const formatPercent = (num: number | null, digits: number = 1): string => {
  return `${formatNumber(num, digits)}%`;
};

/** 整数のフォーマッタ */
export const formatInteger = (num: number | null): string => {
  return formatNumber(num, 0);
};
