import React, { useCallback, useState } from "react";

import { HrInterviewDetail } from "../../../../../../../models/hrInterview/HrInterview";
import { useAnalyzeHrInterview } from "../../../../../../../store/hooks/hrInterview";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeDialog } from "../../../../../../molecules/dialog/AmeDialog";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { InterivewConfirmAlertContent } from "../../../../InterviewConfirmAlertContent";

import { InterviewDeletionDialog } from "./InterviewDeletionDialog";
import { SendFeedBack } from "./SendFeedBack";

export const InterviewHeader: React.FC<{ toPDF: () => void; interviewDetail: HrInterviewDetail }> = ({
  interviewDetail,
  toPDF,
}) => {
  const analyzeInterview = useAnalyzeHrInterview();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onSubmit = useCallback(async () => {
    await analyzeInterview({ hrInterviewId: interviewDetail.hrInterviewId });
  }, [analyzeInterview, interviewDetail.hrInterviewId]);

  return (
    <AmeBox
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "16px",
      }}
    >
      <AmeTypography component="h3">{interviewDetail.employee?.getEmployeeDisplayName()}</AmeTypography>
      <AmeBox sx={{ display: "flex", gap: "10px" }}>
        <InterviewDeletionDialog interviewDetail={interviewDetail} />
        <SendFeedBack
          hrInterviewId={interviewDetail.hrInterviewId}
          forceNotify={
            !interviewDetail.lastFeedbackSentAt &&
            !!interviewDetail.interviewDate &&
            interviewDetail.interviewDate < new Date()
          }
        />
        <AmeButton onClick={toPDF} variant="outlined" color="basic">
          帳票ダウンロード
        </AmeButton>
        {interviewDetail.analysisStatus === "ANALYZED" ? null : (
          <>
            <AmeButton
              onClick={onClick}
              variant="contained"
              color="primary"
              disabled={
                interviewDetail.interviewAnswers.every((answer) => !answer.answer) ||
                interviewDetail.analysisStatus === "ANALYZING"
              }
            >
              確定して解析
            </AmeButton>
            <AmeDialog open={isOpen} onClose={onClose}>
              <InterivewConfirmAlertContent onSubmit={onSubmit} onClose={onClose} />
            </AmeDialog>
          </>
        )}
      </AmeBox>
    </AmeBox>
  );
};
