import React, { useMemo } from "react";

import useSound from "use-sound";

import { TranscriptionSentence } from "../../../../../models/Transcription";
import { TranscriptionStatus } from "../../../../../store/autogenApi";
import { TranscriptionChecker } from "../TranscriptionChecker/TranscriptionChecker";
import { TranscriptionEditor } from "../TranscriptionEditor/TranscriptionEditor";

interface Props {
  check: boolean;
  soundUrl: string;
  interviewRecordingId: string;
  currentSpeakerLabel?: string;
  speakers: number;
  transcriptions: TranscriptionSentence[];
  transcriptionStatus: TranscriptionStatus;
  transcriptionText: string;
}

export const TranscriptionSoundLoader: React.FC<Props> = ({
  check,
  soundUrl,
  transcriptions,
  interviewRecordingId,
  speakers,
  transcriptionStatus,
  transcriptionText,
  currentSpeakerLabel,
}) => {
  const [, { sound }] = useSound(soundUrl, { interrupt: true, html5: true });
  // この処理バックエンドに持っていきたい。
  const speakerMap = useMemo(() => {
    const speakerSet = new Set(transcriptions.map((v) => v.speakerLabel));
    return new Map(Array.from(speakerSet).map((v, i) => [v, i]));
  }, [transcriptions]);
  if (sound) {
    if (check) {
      return (
        <TranscriptionChecker
          key={transcriptionText}
          sound={sound}
          transcriptions={transcriptions}
          speakerMap={speakerMap}
          interviewRecordingId={interviewRecordingId}
          transcriptionStatus={transcriptionStatus}
          transcriptionText={transcriptionText}
        />
      );
    } else {
      return (
        <TranscriptionEditor
          sound={sound}
          transcriptions={transcriptions}
          speakerMap={speakerMap}
          speakers={speakers}
          currentSpeakerLabel={currentSpeakerLabel}
          interviewRecordingId={interviewRecordingId}
        />
      );
    }
  } else {
    return <>音声データをロード中です。</>;
  }
};
