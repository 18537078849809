import React from "react";

import { InterviewFeedbackReport } from "../../../../../store/hooks/interviewer";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";

interface Props {
  qualityReports: InterviewFeedbackReport[];
}

export const InterviewerQualityListTable: React.FC<Props> = ({ qualityReports }) => {
  return (
    <AmeTable>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell>インタビュワー</AmeTableHeadCell>
          <AmeTableHeadCell>グループ</AmeTableHeadCell>
          <AmeTableHeadCell>面談回数</AmeTableHeadCell>
          <AmeTableHeadCell>品質スコア平均</AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {qualityReports.map((report) => (
          <AmeTableRow key={report.userId}>
            <AmeTableBodyCell>
              <AmeButton
                variant="text"
                to={"/interview-feedbacks/$interviewerId"}
                params={{ interviewerId: report.userId }}
              >
                {report.surname} {report.givenName}
              </AmeButton>
            </AmeTableBodyCell>
            <AmeTableBodyCell>{report.userGroups.map(({ displayName }) => displayName).join(" ")}</AmeTableBodyCell>
            <AmeTableBodyCell>{`${report.interviewCount}回`}</AmeTableBodyCell>
            <AmeTableBodyCell>{`${report.averageQualityScore.toFixed(1)}点`}</AmeTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
