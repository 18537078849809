import React, { useState } from "react";

import { SurveyStatus } from "@amecloud/api-client/src-openapi-autogen";

import { SurveyModel } from "../../../../../models/Survey";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { SURVEY_SENDING_METHOD_TEXT_AND_ICON } from "../../common/constants";
import { DeleteSurveyModal } from "../modals/DeleteSurveyModal";
import { GetQrCodeModal } from "../modals/GetQrCodeModal";
import { RemindSurveyModal } from "../modals/RemindSurveyModal";

interface Props {
  emailSignature?: string;
  survey: SurveyModel;
  qrCodeModalIsOpen: boolean;
  setQrCodeModalIsOpen: (open: boolean) => void;
}

export const AfterFixedSurveyDetailsHeader: React.FC<Props> = (props) => {
  const [remindSurveyModalIsOpen, setRemindSurveyModalIsOpen] = useState<boolean>(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

  if (props.survey.useQrcode && props.survey.sendEmail) {
    return (
      <>
        <DeleteSurveyModal
          isOpen={deleteModalIsOpen}
          onClose={() => setDeleteModalIsOpen(false)}
          survey={props.survey}
        />
        <GetQrCodeModal
          isOpen={props.qrCodeModalIsOpen}
          onClose={() => props.setQrCodeModalIsOpen(false)}
          survey={props.survey}
        />
        <RemindSurveyModal
          isOpen={remindSurveyModalIsOpen}
          onClose={() => setRemindSurveyModalIsOpen(false)}
          emailSignature={props.emailSignature}
          survey={props.survey}
        />
        <AmeBox sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px" }}>
          <AmeBox sx={{ marginRight: "auto" }}>
            <AmeTypography component="h3">{props.survey.displayName}</AmeTypography>
          </AmeBox>
          <AmeButton variant="outlined" onClick={() => setDeleteModalIsOpen(true)}>
            削除
          </AmeButton>
          <AmeButton
            variant="outlined"
            onClick={() => setRemindSurveyModalIsOpen(true)}
            disabled={props.survey.status === "completed"}
          >
            未回答者へリマインド
          </AmeButton>
          <AmeButton
            variant="outlined"
            startIcon={SURVEY_SENDING_METHOD_TEXT_AND_ICON["qr-code"].icon}
            onClick={() => props.setQrCodeModalIsOpen(true)}
            disabled={props.survey.status === SurveyStatus.COMPLETED}
          >
            QRコードを取得
          </AmeButton>
        </AmeBox>
      </>
    );
  }

  if (props.survey.useQrcode) {
    return (
      <>
        <DeleteSurveyModal
          isOpen={deleteModalIsOpen}
          onClose={() => setDeleteModalIsOpen(false)}
          survey={props.survey}
        />
        <GetQrCodeModal
          isOpen={props.qrCodeModalIsOpen}
          onClose={() => props.setQrCodeModalIsOpen(false)}
          survey={props.survey}
        />
        <AmeBox sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px" }}>
          <AmeBox sx={{ marginRight: "auto" }}>
            <AmeTypography component="h3">{props.survey.displayName}</AmeTypography>
          </AmeBox>
          <AmeButton variant="outlined" onClick={() => setDeleteModalIsOpen(true)}>
            削除
          </AmeButton>
          <AmeButton
            variant="outlined"
            startIcon={SURVEY_SENDING_METHOD_TEXT_AND_ICON["qr-code"].icon}
            onClick={() => props.setQrCodeModalIsOpen(true)}
            disabled={props.survey.status === SurveyStatus.COMPLETED}
          >
            QRコードを取得
          </AmeButton>
        </AmeBox>
      </>
    );
  }

  return (
    <>
      <DeleteSurveyModal isOpen={deleteModalIsOpen} onClose={() => setDeleteModalIsOpen(false)} survey={props.survey} />
      <RemindSurveyModal
        isOpen={remindSurveyModalIsOpen}
        onClose={() => setRemindSurveyModalIsOpen(false)}
        emailSignature={props.emailSignature}
        survey={props.survey}
      />
      <AmeBox sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px" }}>
        <AmeBox sx={{ marginRight: "auto" }}>
          <AmeTypography component="h3">{props.survey.displayName}</AmeTypography>
        </AmeBox>
        <AmeButton variant="outlined" onClick={() => setDeleteModalIsOpen(true)}>
          削除
        </AmeButton>
        <AmeButton
          variant="outlined"
          onClick={() => setRemindSurveyModalIsOpen(true)}
          disabled={props.survey.status === "completed"}
        >
          未回答者へリマインド
        </AmeButton>
      </AmeBox>
    </>
  );
};
