/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RuleResponseMeta,
    RuleResponseMetaFromJSON,
    RuleResponseMetaFromJSONTyped,
    RuleResponseMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface RuleResponse
 */
export interface RuleResponse {
    /**
     * 
     * @type {string}
     * @memberof RuleResponse
     */
    ruleId: string;
    /**
     * 
     * @type {string}
     * @memberof RuleResponse
     */
    esQuery: string;
    /**
     * 
     * @type {string}
     * @memberof RuleResponse
     */
    subCategoryId: string;
    /**
     * 
     * @type {Date}
     * @memberof RuleResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RuleResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {RuleResponseMeta}
     * @memberof RuleResponse
     */
    meta?: RuleResponseMeta;
}

export function RuleResponseFromJSON(json: any): RuleResponse {
    return RuleResponseFromJSONTyped(json, false);
}

export function RuleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ruleId': json['ruleId'],
        'esQuery': json['esQuery'],
        'subCategoryId': json['subCategoryId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'meta': !exists(json, 'meta') ? undefined : RuleResponseMetaFromJSON(json['meta']),
    };
}

export function RuleResponseToJSON(value?: RuleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ruleId': value.ruleId,
        'esQuery': value.esQuery,
        'subCategoryId': value.subCategoryId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'meta': RuleResponseMetaToJSON(value.meta),
    };
}


