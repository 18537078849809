/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TranscriptionStatus {
    INITIALIZED = 'initialized',
    TRANSCRIBED = 'transcribed',
    CREATED = 'created',
    CHECKING = 'checking',
    CHECKED = 'checked',
    FAILED = 'failed'
}

export function TranscriptionStatusFromJSON(json: any): TranscriptionStatus {
    return TranscriptionStatusFromJSONTyped(json, false);
}

export function TranscriptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptionStatus {
    return json as TranscriptionStatus;
}

export function TranscriptionStatusToJSON(value?: TranscriptionStatus | null): any {
    return value as any;
}

