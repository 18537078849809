import React, { useCallback } from "react";

import { SurveyModel } from "../../../../../models/Survey";
import { useStartSurvey } from "../../../../../store/hooks/surveys";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmeModal } from "../../../../atoms/modal/AmeModal";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  survey: SurveyModel;
  onOpenQrModal: () => void;
}

export const StartQrSurveyModal: React.FC<Props> = (props) => {
  const startSurvey = useStartSurvey();

  const onSubmit = useCallback(async () => {
    const result = await startSurvey({
      surveyId: props.survey.surveyId,
    });
    if (result.isSuccess) {
      props.onOpenQrModal();
      return;
    }
  }, [startSurvey, props]);

  return (
    <AmeModal open={props.isOpen} onClose={props.onClose}>
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader items={[]} currentTitle="詳細を確認" />
        <PageLayoutBody>
          <AmeBox
            sx={{
              "> *": { borderBottom: "1px solid #cccccc", padding: "16px 0" },
              "> *:first-child": { paddingTop: "0", paddingBottom: "24px" },
            }}
          >
            <AmeTypography component="h5">
              以下の内容でよろしければ、アンケート開始ボタンを押してください。
            </AmeTypography>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>タイトル</AmeTypography>
              </AmeBox>
              <AmeTypography>{props.survey.surveyTitle}</AmeTypography>
            </AmeBox>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>説明</AmeTypography>
              </AmeBox>
              <AmeTypography>{props.survey.surveyDescription}</AmeTypography>
            </AmeBox>
            <AmeBox sx={{ display: "flex", justifyContent: "start", gap: "24px", alignItems: "start" }}>
              <AmeBox sx={{ width: "120px", flexShrink: 0 }}>
                <AmeTypography>対象人数</AmeTypography>
              </AmeBox>
              <AmeTypography>{props.survey.targetEmployeeCount}人</AmeTypography>
            </AmeBox>
          </AmeBox>
          <Spacer height="24px" />
          <AmeBox sx={{ display: "flex", justifyContent: "end", gap: "16px" }}>
            <AmeButton variant="outlined" onClick={props.onClose}>
              戻る
            </AmeButton>
            <AmeButton onClick={onSubmit}>アンケートを開始</AmeButton>
          </AmeBox>
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeModal>
  );
};
