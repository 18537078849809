/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExEmployeeReviewResponse,
    ExEmployeeReviewResponseFromJSON,
    ExEmployeeReviewResponseFromJSONTyped,
    ExEmployeeReviewResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExEmployeeReviewsSummaryResponse
 */
export interface ExEmployeeReviewsSummaryResponse {
    /**
     * 
     * @type {Array<ExEmployeeReviewResponse>}
     * @memberof ExEmployeeReviewsSummaryResponse
     */
    reviews: Array<ExEmployeeReviewResponse>;
    /**
     * 
     * @type {number}
     * @memberof ExEmployeeReviewsSummaryResponse
     */
    totalCount: number;
}

export function ExEmployeeReviewsSummaryResponseFromJSON(json: any): ExEmployeeReviewsSummaryResponse {
    return ExEmployeeReviewsSummaryResponseFromJSONTyped(json, false);
}

export function ExEmployeeReviewsSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExEmployeeReviewsSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reviews': ((json['reviews'] as Array<any>).map(ExEmployeeReviewResponseFromJSON)),
        'totalCount': json['totalCount'],
    };
}

export function ExEmployeeReviewsSummaryResponseToJSON(value?: ExEmployeeReviewsSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reviews': ((value.reviews as Array<any>).map(ExEmployeeReviewResponseToJSON)),
        'totalCount': value.totalCount,
    };
}


