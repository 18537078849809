import React, { ChangeEvent } from "react";

import { Feedback } from "../../../../../../store/hooks/interviewer";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";

import { InterviewFileSelector } from "./InterviewFileSelector";
import { InterviewerQualityDetailComment } from "./InterviewerQualityDetailComment";
import { InterviewerQualityDetailTable } from "./InterviewerQualityDetailTable";

interface Props {
  feedbacks: Feedback[];
  selectedInterviewFileId: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InterviewerQualityDetail: React.FC<Props> = ({ feedbacks, selectedInterviewFileId, onChange }) => {
  const feedback = feedbacks.find((feedback) => feedback.interviewRecordingId === selectedInterviewFileId);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[]} currentTitle="品質詳細" />
      <PageLayoutBody>
        <InterviewFileSelector feedbacks={feedbacks} selectedFeedback={feedback} onChange={onChange} />
        <Spacer height="16px" />
        {feedback ? (
          <>
            <InterviewerQualityDetailTable feedbackDetails={feedback.feedbackDetails} />
            <Spacer height="16px" />
            <InterviewerQualityDetailComment comment={feedback.comment} />
          </>
        ) : (
          "選択してください。"
        )}
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
