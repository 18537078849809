import React from "react";

import { Employee } from "../../../models/Employee";
import { Setting } from "../../../models/Setting";
import { InputSegmentKey } from "../../../models/constants/values";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../atoms/table/AmeTable";

interface Props {
  employees: Employee[];
  setting: Setting;
}

export const EmployeeTable: React.FC<Props> = (props) => {
  return (
    <AmeTable>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell>従業員番号</AmeTableHeadCell>
          <AmeTableHeadCell>氏名</AmeTableHeadCell>
          <AmeTableHeadCell>メールアドレス</AmeTableHeadCell>
          <AmeTableHeadCell>入社日</AmeTableHeadCell>
          <AmeTableHeadCell>退職日</AmeTableHeadCell>
          <AmeTableHeadCell>{props.setting.segmentNames.SEX}</AmeTableHeadCell>
          <AmeTableHeadCell>{props.setting.segmentNames.DEPARTMENT}</AmeTableHeadCell>
          <AmeTableHeadCell>{props.setting.segmentNames.FREE1}</AmeTableHeadCell>
          <AmeTableHeadCell>{props.setting.segmentNames.FREE2}</AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {props.employees.map((e) => (
          <AmeTableRow key={e.employeeId}>
            <AmeTableBodyCell>{e.customerCreatedId}</AmeTableBodyCell>
            <AmeTableBodyCell>{e.name}</AmeTableBodyCell>
            <AmeTableBodyCell>{e.email}</AmeTableBodyCell>
            <AmeTableBodyCell>{e.hireDate?.toLocaleDateString()}</AmeTableBodyCell>
            <AmeTableBodyCell>{e.retireDate?.toLocaleDateString()}</AmeTableBodyCell>
            <AmeTableBodyCell>{e.attributes.getAttributeByKey(InputSegmentKey.SEX)?.attributeValue}</AmeTableBodyCell>
            <AmeTableBodyCell>
              {e.attributes.getAttributeByKey(InputSegmentKey.DEPARTMENT)?.attributeValue}
            </AmeTableBodyCell>
            <AmeTableBodyCell>{e.attributes.getAttributeByKey(InputSegmentKey.FREE1)?.attributeValue}</AmeTableBodyCell>
            <AmeTableBodyCell>{e.attributes.getAttributeByKey(InputSegmentKey.FREE2)?.attributeValue}</AmeTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
