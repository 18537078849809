import React from "react";

import { InputSegmentKey } from "../../../../models/constants/values";
import { InputSegmentKey as InputSegmentKeyType } from "../../../../store/autogenApi";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { AmeTableBodyCell, AmeTableRow } from "../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  onOpenSegmentNameRegister: (segmentKey: InputSegmentKeyType) => void;
  segmentKey: InputSegmentKeyType;
  segmentDisplayName: string;
  segmentValues: string;
}

export const SegmentRow: React.FC<Props> = ({
  onOpenSegmentNameRegister,
  segmentKey,
  segmentDisplayName,
  segmentValues,
}) => {
  return (
    <AmeTableRow>
      <AmeTableBodyCell>
        <AmeBox sx={{ display: "flex", alignItems: "center" }}>
          <AmeBox sx={{ flexGrow: 1, minWidth: 0 }}>{segmentDisplayName}</AmeBox>
          {InputSegmentKey.FREE1 == segmentKey || InputSegmentKey.FREE2 == segmentKey ? (
            <AmeButton
              size={"small"}
              variant="outlined"
              onClick={() => {
                onOpenSegmentNameRegister(segmentKey);
              }}
            >
              編集
            </AmeButton>
          ) : null}
        </AmeBox>
      </AmeTableBodyCell>
      <AmeTableBodyCell>
        <AmeBox sx={{ display: "flex", alignItems: "center" }}>
          <AmeBox sx={{ flexGrow: 1, minWidth: 0 }}>
            <AmeTypography omit>{segmentValues}</AmeTypography>
          </AmeBox>
          <AmeButton size={"small"} variant="outlined" to={"/settings/segments/$segmentKey"} params={{ segmentKey }}>
            編集
          </AmeButton>
        </AmeBox>
      </AmeTableBodyCell>
    </AmeTableRow>
  );
};
