import React from "react";

import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  count: number;
  // totalCount: number;
}

export const DisplayedInterviewsCount: React.FC<Props> = ({ count }) => {
  return (
    <AmeBox sx={{ display: "flex", alignItems: "bottom", height: "100%" }}>
      <AmeTypography component="h5">{`${count}件`}</AmeTypography>
      {/*<AmeBox sx={{ paddingTop: "4px" }}>*/}
      {/*  <AmeTypography fontSize="button">{`/${totalCount}件`}</AmeTypography>*/}
      {/*</AmeBox>*/}
    </AmeBox>
  );
};
