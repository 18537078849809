import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useSubCategories } from "../../../../../../../store/hooks/subCategories";
import { useGetSurveyQuestions } from "../../../../../../../store/hooks/surveys-questions";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { SurveyQuestionsSettingContent } from "../../../../../../organisms/settings/surveys/questions/SurveyQuestionSettingContent";

const SurveyQuestionsSetting: React.FC = () => {
  const subCategoriesQuery = useSubCategories();
  const surveyQuestionsQuery = useGetSurveyQuestions();

  return (
    <WaitForSuccess queryState={surveyQuestionsQuery}>
      {(surveyQuestions) => (
        <WaitForSuccess queryState={subCategoriesQuery}>
          {(subCategoryList) => (
            <SurveyQuestionsSettingContent
              subCategories={subCategoryList.subCategories}
              defaultSurveyQuestions={surveyQuestions}
            />
          )}
        </WaitForSuccess>
      )}
    </WaitForSuccess>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/surveys/questions/")({
  component: SurveyQuestionsSetting,
});
