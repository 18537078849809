/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RetirementReasonScoreResponse,
    RetirementReasonScoreResponseFromJSON,
    RetirementReasonScoreResponseFromJSONTyped,
    RetirementReasonScoreResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RetirementReasonResponse
 */
export interface RetirementReasonResponse {
    /**
     * 
     * @type {Array<RetirementReasonScoreResponse>}
     * @memberof RetirementReasonResponse
     */
    values: Array<RetirementReasonScoreResponse>;
}

export function RetirementReasonResponseFromJSON(json: any): RetirementReasonResponse {
    return RetirementReasonResponseFromJSONTyped(json, false);
}

export function RetirementReasonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetirementReasonResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': ((json['values'] as Array<any>).map(RetirementReasonScoreResponseFromJSON)),
    };
}

export function RetirementReasonResponseToJSON(value?: RetirementReasonResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': ((value.values as Array<any>).map(RetirementReasonScoreResponseToJSON)),
    };
}


