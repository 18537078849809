import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetHrInterviewFile } from "../../../../../../../store/hooks/hrInterviewFiles";
import { useGetGroupListQuery } from "../../../../../../../store/hooks/userGroups";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { CreateInterviewByFileContent } from "../../../../../../organisms/hr-interviews/interview-files/create-interview";

const CreateInterviewByFilePage: React.FC = () => {
  const { interviewFileId } = Route.useParams();
  const getHrInterviewFileQuery = useGetHrInterviewFile(interviewFileId);
  const getUserGroupsQuery = useGetGroupListQuery();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "在職者音声一覧", to: "/hr-interviews/interview-files" },
          {
            title: "在職者音声詳細",
            to: `/hr-interviews/interview-files/$interviewFileId`,
            params: { interviewFileId: interviewFileId },
          },
        ]}
        currentTitle={"面談作成"}
      />
      <WaitForSuccess queryState={getUserGroupsQuery}>
        {({ userGroups }) => (
          <WaitForSuccess queryState={getHrInterviewFileQuery}>
            {(interviewFile) => <CreateInterviewByFileContent userGroups={userGroups} interviewFile={interviewFile} />}
          </WaitForSuccess>
        )}
      </WaitForSuccess>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-all/hr-interviews/interview-files/$interviewFileId/create-interview",
)({
  component: CreateInterviewByFilePage,
});
