import React, { PropsWithChildren } from "react";

import { PageLayoutBody } from "../../atoms/layout/PageLayoutBody";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeDrawer } from "../../muiWrapper/AmeDrawer";

import { DrawerHeader } from "./DrawerHeader";

export const SPDrawer: React.FC<PropsWithChildren<{ open: boolean; title: string; onClose?: () => void }>> = (
  props,
) => {
  return (
    <AmeDrawer open={props.open} variant={"persistent"} anchor={"bottom"}>
      <AmeBox sx={{ height: "calc(100vh - 48px)", overflow: "scroll", position: "relative" }}>
        <DrawerHeader title={props.title} onClose={props.onClose} />
        <PageLayoutBody type="wide">{props.children}</PageLayoutBody>
      </AmeBox>
    </AmeDrawer>
  );
};
