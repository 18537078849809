/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeRefResponse,
    EmployeeRefResponseFromJSON,
    EmployeeRefResponseFromJSONTyped,
    EmployeeRefResponseToJSON,
    HrInterviewAnalysisStatus,
    HrInterviewAnalysisStatusFromJSON,
    HrInterviewAnalysisStatusFromJSONTyped,
    HrInterviewAnalysisStatusToJSON,
    HrInterviewFeedbackStatus,
    HrInterviewFeedbackStatusFromJSON,
    HrInterviewFeedbackStatusFromJSONTyped,
    HrInterviewFeedbackStatusToJSON,
    HrInterviewFileResponse,
    HrInterviewFileResponseFromJSON,
    HrInterviewFileResponseFromJSONTyped,
    HrInterviewFileResponseToJSON,
    HrInterviewRefResponse,
    HrInterviewRefResponseFromJSON,
    HrInterviewRefResponseFromJSONTyped,
    HrInterviewRefResponseToJSON,
    HrInterviewResponseAllOf,
    HrInterviewResponseAllOfFromJSON,
    HrInterviewResponseAllOfFromJSONTyped,
    HrInterviewResponseAllOfToJSON,
    InterviewAnswerResponse,
    InterviewAnswerResponseFromJSON,
    InterviewAnswerResponseFromJSONTyped,
    InterviewAnswerResponseToJSON,
    InterviewFormatResponse,
    InterviewFormatResponseFromJSON,
    InterviewFormatResponseFromJSONTyped,
    InterviewFormatResponseToJSON,
    InterviewTypeResponse,
    InterviewTypeResponseFromJSON,
    InterviewTypeResponseFromJSONTyped,
    InterviewTypeResponseToJSON,
    UserGroupRefResponse,
    UserGroupRefResponseFromJSON,
    UserGroupRefResponseFromJSONTyped,
    UserGroupRefResponseToJSON,
    UserRefResponse,
    UserRefResponseFromJSON,
    UserRefResponseFromJSONTyped,
    UserRefResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewResponse
 */
export interface HrInterviewResponse {
    /**
     * 
     * @type {HrInterviewFeedbackStatus}
     * @memberof HrInterviewResponse
     */
    feedbackStatus: HrInterviewFeedbackStatus;
    /**
     * 
     * @type {HrInterviewFileResponse}
     * @memberof HrInterviewResponse
     */
    hrInterviewFile?: HrInterviewFileResponse;
    /**
     * 
     * @type {Array<InterviewAnswerResponse>}
     * @memberof HrInterviewResponse
     */
    interviewAnswers: Array<InterviewAnswerResponse>;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {UserGroupRefResponse}
     * @memberof HrInterviewResponse
     */
    userGroup?: UserGroupRefResponse;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewResponse
     */
    lastFeedbackSentAt?: Date;
    /**
     * 
     * @type {EmployeeRefResponse}
     * @memberof HrInterviewResponse
     */
    employee?: EmployeeRefResponse;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewResponse
     */
    followUpMemo: string;
    /**
     * 
     * @type {boolean}
     * @memberof HrInterviewResponse
     */
    followUpIsRequired?: boolean;
    /**
     * 
     * @type {UserRefResponse}
     * @memberof HrInterviewResponse
     */
    interviewer: UserRefResponse;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewResponse
     */
    employeeTenureId?: string;
    /**
     * 
     * @type {Date}
     * @memberof HrInterviewResponse
     */
    interviewDate?: Date;
    /**
     * 
     * @type {InterviewFormatResponse}
     * @memberof HrInterviewResponse
     */
    interviewFormat: InterviewFormatResponse;
    /**
     * 
     * @type {InterviewTypeResponse}
     * @memberof HrInterviewResponse
     */
    interviewType: InterviewTypeResponse;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewResponse
     */
    interviewFileId?: string;
    /**
     * 
     * @type {HrInterviewAnalysisStatus}
     * @memberof HrInterviewResponse
     */
    analysisStatus?: HrInterviewAnalysisStatus;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewResponse
     */
    hrInterviewId: string;
}

export function HrInterviewResponseFromJSON(json: any): HrInterviewResponse {
    return HrInterviewResponseFromJSONTyped(json, false);
}

export function HrInterviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbackStatus': HrInterviewFeedbackStatusFromJSON(json['feedbackStatus']),
        'hrInterviewFile': !exists(json, 'hrInterviewFile') ? undefined : HrInterviewFileResponseFromJSON(json['hrInterviewFile']),
        'interviewAnswers': ((json['interviewAnswers'] as Array<any>).map(InterviewAnswerResponseFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'userGroup': !exists(json, 'userGroup') ? undefined : UserGroupRefResponseFromJSON(json['userGroup']),
        'lastFeedbackSentAt': !exists(json, 'lastFeedbackSentAt') ? undefined : (new Date(json['lastFeedbackSentAt'])),
        'employee': !exists(json, 'employee') ? undefined : EmployeeRefResponseFromJSON(json['employee']),
        'followUpMemo': json['followUpMemo'],
        'followUpIsRequired': !exists(json, 'followUpIsRequired') ? undefined : json['followUpIsRequired'],
        'interviewer': UserRefResponseFromJSON(json['interviewer']),
        'employeeTenureId': !exists(json, 'employeeTenureId') ? undefined : json['employeeTenureId'],
        'interviewDate': !exists(json, 'interviewDate') ? undefined : (new Date(json['interviewDate'])),
        'interviewFormat': InterviewFormatResponseFromJSON(json['interviewFormat']),
        'interviewType': InterviewTypeResponseFromJSON(json['interviewType']),
        'interviewFileId': !exists(json, 'interviewFileId') ? undefined : json['interviewFileId'],
        'analysisStatus': !exists(json, 'analysisStatus') ? undefined : HrInterviewAnalysisStatusFromJSON(json['analysisStatus']),
        'hrInterviewId': json['hrInterviewId'],
    };
}

export function HrInterviewResponseToJSON(value?: HrInterviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbackStatus': HrInterviewFeedbackStatusToJSON(value.feedbackStatus),
        'hrInterviewFile': HrInterviewFileResponseToJSON(value.hrInterviewFile),
        'interviewAnswers': ((value.interviewAnswers as Array<any>).map(InterviewAnswerResponseToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'userGroup': UserGroupRefResponseToJSON(value.userGroup),
        'lastFeedbackSentAt': value.lastFeedbackSentAt === undefined ? undefined : (value.lastFeedbackSentAt.toISOString()),
        'employee': EmployeeRefResponseToJSON(value.employee),
        'followUpMemo': value.followUpMemo,
        'followUpIsRequired': value.followUpIsRequired,
        'interviewer': UserRefResponseToJSON(value.interviewer),
        'employeeTenureId': value.employeeTenureId,
        'interviewDate': value.interviewDate === undefined ? undefined : (value.interviewDate.toISOString()),
        'interviewFormat': InterviewFormatResponseToJSON(value.interviewFormat),
        'interviewType': InterviewTypeResponseToJSON(value.interviewType),
        'interviewFileId': value.interviewFileId,
        'analysisStatus': HrInterviewAnalysisStatusToJSON(value.analysisStatus),
        'hrInterviewId': value.hrInterviewId,
    };
}


