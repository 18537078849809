import React from "react";

import { Link } from "@tanstack/react-router";

import { HrInterview } from "../../../../../models/hrInterview/HrInterview";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { InterviewAnalysisStatus } from "../../common/InterviewAnalysisStatus";
import { InterviewFeedbackStatus } from "../../common/InterviewFeedbackStatus";
import { InterviewTranscriptionStatus } from "../../common/InterviewTranscriptionStatus";

interface Props {
  interviews: HrInterview[];
}

export const HrInterviewTable: React.FC<Props> = ({ interviews }) => {
  return (
    <AmeTable>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell>従業員番号</AmeTableHeadCell>
          <AmeTableHeadCell>被面談者</AmeTableHeadCell>
          <AmeTableHeadCell>面談者</AmeTableHeadCell>
          <AmeTableHeadCell>作成日</AmeTableHeadCell>
          <AmeTableHeadCell>面談日</AmeTableHeadCell>
          <AmeTableHeadCell>面談ステータス</AmeTableHeadCell>
          <AmeTableHeadCell>音声ステータス</AmeTableHeadCell>
          <AmeTableHeadCell>アンケートステータス</AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {interviews.map((interview) => (
          <AmeTableRow key={interview.hrInterviewId}>
            <AmeTableBodyCell>
              <Link to={"/hr-interviews/interviews/$interviewId"} params={{ interviewId: interview.hrInterviewId }}>
                <AmeTypography color={"secondary"}>{interview.employee?.customerCreatedId}</AmeTypography>
              </Link>
            </AmeTableBodyCell>
            <AmeTableBodyCell>{interview.employee?.name}</AmeTableBodyCell>
            <AmeTableBodyCell>{interview.interviewer.getFullName()}</AmeTableBodyCell>
            <AmeTableBodyCell>{interview.createdAt ? formatDateToYYYYMMDD(interview.createdAt) : "-"}</AmeTableBodyCell>
            <AmeTableBodyCell>
              {interview.interviewDate ? formatDateToYYYYMMDD(interview.interviewDate) : "-"}
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              <InterviewAnalysisStatus status={interview.analysisStatus} />
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              <InterviewTranscriptionStatus status={interview.hrInterviewFile?.transcriptionStatus} />
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              <InterviewFeedbackStatus status={interview.feedbackStatus} />
            </AmeTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
