import React from "react";

import { AmeChip } from "../../../../atoms/chip/AmeChip";

export type FilterChipContent = {
  name: string;
  groupName: string;
  clearFilter: () => void;
};

export const ReportFilterChip: React.FC<FilterChipContent> = ({ name, groupName, clearFilter }) => {
  return <AmeChip label={`${groupName}:${name}`} onDelete={clearFilter} disabled={false} selected />;
};
