import React, { ChangeEventHandler, forwardRef } from "react";

import { AmeTextField, AmeTextFieldProps } from "../AmeTextField";

type Props = Omit<AmeTextFieldProps, "multiline" | "rows"> & { onChange?: ChangeEventHandler<HTMLInputElement> };

export const AmeInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return <AmeTextField {...props} ref={ref} />;
});

AmeInput.displayName = AmeInput.name;
