import React from "react";

import type { UserList } from "../../../../../models/User";
import { AmeTable, AmeTableBody, AmeTableHead, AmeTableHeadCell, AmeTableRow } from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";

import { UserDataTableRow } from "./UserDataTableRow";

type Props = {
  data: UserList;
};
export const UserDataTable: React.FC<Props> = (props) => {
  return (
    <AmeTable useCustomCellWidth>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell width="61%">
            <AmeTypography fontSize="body1">氏名</AmeTypography>
          </AmeTableHeadCell>
          <AmeTableHeadCell width="14%">
            <AmeTypography fontSize="body1">ユーザー区分</AmeTypography>
          </AmeTableHeadCell>
          <AmeTableHeadCell width="14%">
            <AmeTypography fontSize="body1">所属グループ数</AmeTypography>
          </AmeTableHeadCell>
          <AmeTableHeadCell width="11%">
            <AmeTypography fontSize="body1">作成日</AmeTypography>
          </AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {props.data.users.map((user) => (
          <UserDataTableRow key={user.userId} user={user}></UserDataTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
