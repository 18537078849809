import React, { useCallback } from "react";

import {
  CategoryModel,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} from "../../../../../store/hooks/categories";

import { CategoryInfo } from "./CategoryInfo";

interface Props {
  categories: CategoryModel[];
}

export const CategoryList: React.FC<Props> = ({ categories }) => {
  const deleteCategory = useDeleteCategoryMutation();
  const updateCategory = useUpdateCategoryMutation();
  const onUpdateCategory = useCallback(
    async (args: { categoryId: string; displayName: string }) => {
      await updateCategory({
        categoryId: args.categoryId,
        updateCategoryRequestBody: {
          displayName: args.displayName,
        },
      });
    },
    [updateCategory],
  );
  return (
    <React.Fragment>
      {categories.map((category) => (
        <CategoryInfo
          key={category.categoryId}
          categoryId={category.categoryId}
          displayName={category.displayName}
          computationTaskStatus={category.computationTaskStatus}
          defaultSubCategoryId={category.defaultSubCategoryId}
          updateCategory={onUpdateCategory}
          deleteCategory={deleteCategory}
        />
      ))}
    </React.Fragment>
  );
};
