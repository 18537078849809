import React, { useMemo } from "react";

import { SubCategoryModel } from "../../../../../../models/SubCategory";
import { RetirementReasonScoreResponse } from "../../../../../../store/autogenApi";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { RetirementReasonChip } from "./RetirementReasonChip";

interface Props {
  selectedRetirementReasonId: string | undefined;
  subCategories: SubCategoryModel[];
  retirementReasons: RetirementReasonScoreResponse[];
  onChangeSelectedRetirementReason: (reasonId: string) => void;
  title?: string;
}

export const RetirementReasons: React.FC<Props> = ({
  selectedRetirementReasonId,
  subCategories,
  retirementReasons,
  onChangeSelectedRetirementReason,
  title,
}) => {
  const retirementReasonIds = useMemo(
    () => retirementReasons.map((reason) => reason.subCategoryId),
    [retirementReasons],
  );
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[]} currentTitle={title ?? "AIによる退職理由判定結果"} />
      <PageLayoutBody type="wide">
        <AmeBox sx={{ display: "flex", flexWrap: "wrap", gap: "8px", columnGap: "8px" }}>
          {subCategories.map((subCategory) => (
            <RetirementReasonChip
              key={subCategory.subCategoryId}
              onChangeSelectedRetirementReason={onChangeSelectedRetirementReason}
              selectedRetirementReasonId={selectedRetirementReasonId}
              subCategory={subCategory}
              retirementReasonIds={retirementReasonIds}
            />
          ))}
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
