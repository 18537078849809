import React, { useMemo, useState } from "react";

import {
  CommonSuggestionAlgorithm,
  SuggestionAlgorithmRelatedToCategory,
  SuggestionAlgorithmRelatedToSubCategory,
} from "@amecloud/api-client/src-openapi-autogen";
import { Search } from "@mui/icons-material";

import { AmeInput } from "../../../../../atoms/forms/input/AmeInput";
import { ChoosableList } from "../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../atoms/list/ChoosableListItem";
import { AmeDropbox_old } from "../../../../../molecules/dropbox/AmeDropbox";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

export type SuggestionAlgorithm =
  | CommonSuggestionAlgorithm
  | SuggestionAlgorithmRelatedToCategory
  | SuggestionAlgorithmRelatedToSubCategory;

interface Algorithm {
  displayName: string;
  algorithm: SuggestionAlgorithm;
}

const algorithmsForSubCategory: Algorithm[] = [
  { displayName: "多い順", algorithm: CommonSuggestionAlgorithm.FREQUENT_WORD },
  {
    displayName: "サブカテゴリーの中で多い順",
    algorithm: SuggestionAlgorithmRelatedToSubCategory.FREQUENT_WORD_IN_SUB_CATEGORY,
  },
  {
    displayName: "サブカテゴリー以外で多い順",
    algorithm: SuggestionAlgorithmRelatedToSubCategory.FREQUENT_WORD_NOT_IN_SUB_CATEGORY,
  },
];
const algorithmsForCategory: Algorithm[] = [
  { displayName: "多い順", algorithm: CommonSuggestionAlgorithm.FREQUENT_WORD },
  {
    displayName: "カテゴリーの中で多い順",
    algorithm: SuggestionAlgorithmRelatedToCategory.FREQUENT_WORD_IN_CATEGORY,
  },
  {
    displayName: "カテゴリー以外で多い順",
    algorithm: SuggestionAlgorithmRelatedToCategory.FREQUENT_WORD_NOT_IN_CATEGORY,
  },
];

interface Props {
  isSubCategory: boolean;
  algorithm: SuggestionAlgorithm;
  setAlgorithm: (value: SuggestionAlgorithm) => void;
  query: string;
  setQuery: (value: string) => void;
}

export const SuggestionHeader: React.FC<Props> = ({ isSubCategory, algorithm, setAlgorithm, query, setQuery }) => {
  const [queryInputValue, setQueryInputValue] = useState<string>(query);
  const algorithms = useMemo(() => (isSubCategory ? algorithmsForSubCategory : algorithmsForCategory), [isSubCategory]);
  const selectedAlgorithm = useMemo(
    () => algorithms.find((_algorithm) => _algorithm.algorithm === algorithm),
    [algorithm, algorithms],
  );

  return (
    <AmeBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 0" }}>
      <AmeBox component="span" sx={{ width: "186px" }}>
        <AmeInput
          onBlur={() => {
            setQuery(queryInputValue);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setQuery(queryInputValue);
            }
          }}
          onChange={(e) => {
            setQueryInputValue(e.target.value);
          }}
          value={queryInputValue}
          startIcon={<Search />}
          placeholder="検索"
          size="small"
          fullWidth
        />
      </AmeBox>
      <AmeBox sx={{ width: "240px" }}>
        <AmeDropbox_old
          value={algorithm}
          onChange={(e) => {
            if (e.target.value === undefined) {
              throw new Error("algorithmがundefinedです。実装が間違っています");
            }
            setAlgorithm(e.target.value);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          displaySelected={selectedAlgorithm?.displayName}
        >
          <ChoosableList>
            {algorithms.map((_algorithm) => (
              <ChoosableListItem key={_algorithm.algorithm} value={_algorithm.algorithm}>
                {_algorithm.displayName}
              </ChoosableListItem>
            ))}
          </ChoosableList>
        </AmeDropbox_old>
      </AmeBox>
    </AmeBox>
  );
};
