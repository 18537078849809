import React from "react";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { SimplePaper } from "../../../atoms/paper/SimplePaper";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { SurveyQuestion, SurveyQuestionAnswer, SurveyQuestionAnswers, SurveyTemplateBody } from "./SurveyTemplateBody";
import { SurveyTemplateHeader } from "./SurveyTemplateHeader";
import { SurveyTemplateQuestionList } from "./SurveyTemplateQuestionList";

interface Props {
  answers: SurveyQuestionAnswers;
  title: string;
  questions: SurveyQuestion[];
  description: string;
  onChange: (answer: SurveyQuestionAnswer) => void;
  onSubmit: () => void;
}

export const SurveyTemplate: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const submittable = props.answers.size === props.questions.length;
  return (
    <SimplePaper noPadding>
      <AmeBox sx={{ position: "sticky", top: 0, backgroundColor: theme.common.background.white }}>
        <SurveyTemplateHeader total={props.questions.length} answered={props.answers.size} title={props.title} />
      </AmeBox>
      <SurveyTemplateBody description={props.description} submittable={submittable} onSubmit={props.onSubmit}>
        <SurveyTemplateQuestionList
          surveyQuestions={props.questions}
          answers={props.answers}
          onChange={props.onChange}
        />
      </SurveyTemplateBody>
    </SimplePaper>
  );
};
