/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CommonSuggestionAlgorithm {
    TEST = 'test',
    FREQUENT_WORD = 'frequentWord'
}

export function CommonSuggestionAlgorithmFromJSON(json: any): CommonSuggestionAlgorithm {
    return CommonSuggestionAlgorithmFromJSONTyped(json, false);
}

export function CommonSuggestionAlgorithmFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommonSuggestionAlgorithm {
    return json as CommonSuggestionAlgorithm;
}

export function CommonSuggestionAlgorithmToJSON(value?: CommonSuggestionAlgorithm | null): any {
    return value as any;
}

