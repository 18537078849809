/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackResponse,
    InterviewFeedbackResponseFromJSON,
    InterviewFeedbackResponseFromJSONTyped,
    InterviewFeedbackResponseToJSON,
    InterviewQualityReportResponseUserGroups,
    InterviewQualityReportResponseUserGroupsFromJSON,
    InterviewQualityReportResponseUserGroupsFromJSONTyped,
    InterviewQualityReportResponseUserGroupsToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewQualityReportResponse
 */
export interface InterviewQualityReportResponse {
    /**
     * 
     * @type {Array<InterviewFeedbackResponse>}
     * @memberof InterviewQualityReportResponse
     */
    feedbacks: Array<InterviewFeedbackResponse>;
    /**
     * 
     * @type {number}
     * @memberof InterviewQualityReportResponse
     */
    averageQualityScore: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewQualityReportResponse
     */
    interviewCount: number;
    /**
     * 
     * @type {Array<InterviewQualityReportResponseUserGroups>}
     * @memberof InterviewQualityReportResponse
     */
    userGroups: Array<InterviewQualityReportResponseUserGroups>;
    /**
     * 
     * @type {string}
     * @memberof InterviewQualityReportResponse
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewQualityReportResponse
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewQualityReportResponse
     */
    userId: string;
}

export function InterviewQualityReportResponseFromJSON(json: any): InterviewQualityReportResponse {
    return InterviewQualityReportResponseFromJSONTyped(json, false);
}

export function InterviewQualityReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewQualityReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbacks': ((json['feedbacks'] as Array<any>).map(InterviewFeedbackResponseFromJSON)),
        'averageQualityScore': json['averageQualityScore'],
        'interviewCount': json['interviewCount'],
        'userGroups': ((json['userGroups'] as Array<any>).map(InterviewQualityReportResponseUserGroupsFromJSON)),
        'givenName': json['givenName'],
        'surname': json['surname'],
        'userId': json['userId'],
    };
}

export function InterviewQualityReportResponseToJSON(value?: InterviewQualityReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbacks': ((value.feedbacks as Array<any>).map(InterviewFeedbackResponseToJSON)),
        'averageQualityScore': value.averageQualityScore,
        'interviewCount': value.interviewCount,
        'userGroups': ((value.userGroups as Array<any>).map(InterviewQualityReportResponseUserGroupsToJSON)),
        'givenName': value.givenName,
        'surname': value.surname,
        'userId': value.userId,
    };
}


