import { HrInterviewQualityReport } from "../../models/hrInterview/HrInterviewQualityReport";
import { HrInterviewQualityReportAxis } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";

export const useGetHrInterviewFeedbackQuestions = () => {
  const result = defaultApi.endpoints.getHrInterviewFeedbackQuestionList.useQuery();
  return useQueryResult({
    result,
    fromResponse: (response) => response,
  });
};

export const useSubmitHrInterviewFeedbacks = () => {
  const [createHrInterviewFeedbackAnswers] = defaultApi.endpoints.createHrInterviewFeedbackAnswers.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createHrInterviewFeedbackAnswers,
  });
};

type HrInterviewQualityReportFilters = {
  axis: HrInterviewQualityReportAxis;
  followUpIsRequired: boolean[];
  from: string | undefined;
  to: string | undefined;
  interviewTypeIds: string[];
  interviewerIds: string[];
  evaluationTypes: string[];
};

export const useGetHrInterviewQualityScoreReport = (filters: HrInterviewQualityReportFilters) => {
  const result = defaultApi.endpoints.getHrInterviewQualityReport.useQuery({
    axis: filters.axis,
    followUpIsRequired: filters.followUpIsRequired,
    startDate: filters.from,
    endDate: filters.to,
    interviewTypeIds: filters.interviewTypeIds,
    interviewerIds: filters.interviewerIds,
    evaluationItems: filters.evaluationTypes,
  });

  return useQueryResult({
    result,
    fromResponse: HrInterviewQualityReport.fromResponse,
  });
};
