/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeAttributeResponse,
    EmployeeAttributeResponseFromJSON,
    EmployeeAttributeResponseFromJSONTyped,
    EmployeeAttributeResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttributeUnitResponse
 */
export interface AttributeUnitResponse {
    /**
     * 
     * @type {Array<EmployeeAttributeResponse>}
     * @memberof AttributeUnitResponse
     */
    attributes: Array<EmployeeAttributeResponse>;
    /**
     * 
     * @type {Date}
     * @memberof AttributeUnitResponse
     */
    effectiveFrom: Date;
}

export function AttributeUnitResponseFromJSON(json: any): AttributeUnitResponse {
    return AttributeUnitResponseFromJSONTyped(json, false);
}

export function AttributeUnitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeUnitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(EmployeeAttributeResponseFromJSON)),
        'effectiveFrom': (new Date(json['effectiveFrom'])),
    };
}

export function AttributeUnitResponseToJSON(value?: AttributeUnitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(EmployeeAttributeResponseToJSON)),
        'effectiveFrom': (value.effectiveFrom.toISOString()),
    };
}


