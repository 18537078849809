import React from "react";

import { UserDetail } from "../../../../../../models/User";
import { CheckV2Icon } from "../../../../../atoms/icon/CheckV2";
import { Elipse } from "../../../../../atoms/icon/Elipse";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { UserDetailField } from "./UserDetailField";

interface Props {
  user: UserDetail;
  userId: string;
}

export const UserProfilePage: React.FC<Props> = (props) => {
  return (
    <>
      <PaperWrapper>
        <PaperHeader>
          <AmeTypography fontSize="body1">ユーザー情報</AmeTypography>
        </PaperHeader>
        <PaperBody>
          <UserDetailField fieldName="氏名">
            <AmeTypography fontSize="body1">
              {props.user.surname}
              {props.user.givenName}
            </AmeTypography>
          </UserDetailField>
          <UserDetailField fieldName="メールアドレス">
            <AmeTypography fontSize="body1">{props.user.email}</AmeTypography>
          </UserDetailField>
          <UserDetailField fieldName="電話番号">
            <AmeTypography fontSize="body1">{props.user.phoneNumber}</AmeTypography>
          </UserDetailField>
          <UserDetailField fieldName="作成日">
            <AmeTypography fontSize="body1">{props.user.createdAt?.toLocaleDateString()}</AmeTypography>
          </UserDetailField>
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px"></Spacer>
      <PaperWrapper>
        <PaperHeader>
          <AmeTypography fontSize="body1">権限</AmeTypography>
        </PaperHeader>
        <PaperBody>
          <UserDetailField fieldName="ユーザー区分">
            <AmeTypography fontSize="body1">{props.user.isAdmin ? "管理者" : "インタビュワー"}</AmeTypography>
          </UserDetailField>
          <UserDetailField fieldName="グループ">
            {props.user.userGroups.length ? (
              <>
                {props.user.userGroups.map((userGroup) => {
                  return (
                    <AmeTypography key={userGroup.userGroupId} fontSize="body1">
                      {userGroup.displayName}
                    </AmeTypography>
                  );
                })}
              </>
            ) : (
              <AmeBox>参加グループはありません</AmeBox>
            )}
          </UserDetailField>
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperHeader>セットアップ状況</PaperHeader>
        <PaperBody>
          <UserDetailField fieldName="ステータス">
            {props.user.userStatus == "FORCE_CHANGE_PASSWORD" ? (
              <AmeBox sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Elipse />
                <AmeTypography fontSize="body1">仮パスワード送付</AmeTypography>
              </AmeBox>
            ) : props.user.userStatus == "CONFIRMED" ? (
              <AmeBox sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <CheckV2Icon color="success" />
                <AmeTypography fontSize="body1">パスワード設定完了</AmeTypography>
              </AmeBox>
            ) : (
              "不明"
            )}
          </UserDetailField>
        </PaperBody>
      </PaperWrapper>
    </>
  );
};
