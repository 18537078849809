import React, { ReactNode } from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  children: ReactNode;
};

export const FilterWrapper: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        border: `1px solid ${theme.common.border.gray40}`,
        borderRadius: "4px",
        boxShadow: theme.common.shadow.main,
        backgroundColor: theme.common.background.gray20,
      }}
    >
      <AmeBox
        sx={{
          padding: "0 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.common.border.gray40}`,
          backgroundColor: theme.common.background.gray40,
          height: "56px",
        }}
      >
        <AmeTypography fontSize="body1">フィルター</AmeTypography>
      </AmeBox>
      {props.children}
    </AmeBox>
  );
};
