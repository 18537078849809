/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserStatusType {
    UNKNOWN = 'UNKNOWN',
    ARCHIVED = 'ARCHIVED',
    COMPROMISED = 'COMPROMISED',
    CONFIRMED = 'CONFIRMED',
    EXTERNAL_PROVIDER = 'EXTERNAL_PROVIDER',
    FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
    RESET_REQUIRED = 'RESET_REQUIRED',
    UNCONFIRMED = 'UNCONFIRMED'
}

export function UserStatusTypeFromJSON(json: any): UserStatusType {
    return UserStatusTypeFromJSONTyped(json, false);
}

export function UserStatusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusType {
    return json as UserStatusType;
}

export function UserStatusTypeToJSON(value?: UserStatusType | null): any {
    return value as any;
}

