import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetGroupListQuery } from "../../../../../../../../store/hooks/userGroups";
import { BreadcrumbPageLayoutHeader } from "../../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../../molecules/Loading/WaitForSuccess";
import { UserCreateForm } from "../../../../../../../organisms/settings/members/users/create/UserCreateForm";

export const UserCreatePage: React.FC = () => {
  const userGroupListQueryState = useGetGroupListQuery();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          {
            title: "設定",
            to: "/settings",
          },
          {
            title: "ユーザー管理",
            to: "/hidden-pages/members/users",
          },
        ]}
        currentTitle="ユーザー作成"
      />
      <PageLayoutBody>
        <WaitForSuccess queryState={userGroupListQueryState}>
          {({ userGroups }) => <UserCreateForm userGroups={userGroups} />}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/members/users/create/")({
  component: UserCreatePage,
});
