import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const EditIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 15 15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        transform="translate(0,1)"
        d="M0 12.5C0 12.8978 0.158035 13.2794 0.43934 13.5607C0.720644 13.842 1.10218 14 1.5 14H12.5C12.8978 14 13.2794 13.842 13.5607 13.5607C13.842 13.2794 14 12.8978 14 12.5V6.5C14 6.36739 13.9473 6.24021 13.8536 6.14645C13.7598 6.05268 13.6326 6 13.5 6C13.3674 6 13.2402 6.05268 13.1464 6.14645C13.0527 6.24021 13 6.36739 13 6.5V12.5C13 12.6326 12.9473 12.7598 12.8536 12.8536C12.7598 12.9473 12.6326 13 12.5 13H1.5C1.36739 13 1.24021 12.9473 1.14645 12.8536C1.05268 12.7598 1 12.6326 1 12.5V1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24021 1.05268 1.36739 1 1.5 1H8C8.13261 1 8.25979 0.947322 8.35355 0.853553C8.44732 0.759785 8.5 0.632608 8.5 0.5C8.5 0.367392 8.44732 0.240215 8.35355 0.146447C8.25979 0.0526784 8.13261 0 8 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V12.5Z"
        fill="currentColor"
      />
      <path
        transform="translate(3,0)"
        d="M11.502 1.93991C11.5954 2.03363 11.6479 2.16057 11.6479 2.2929C11.6479 2.42524 11.5954 2.55218 11.502 2.6459L10.459 3.6899L8.45896 1.68991L9.50196 0.645905C9.59572 0.552169 9.72288 0.499512 9.85546 0.499512C9.98804 0.499512 10.1152 0.552169 10.209 0.645905L11.502 1.93891V1.93991ZM9.75196 4.3959L7.75196 2.3959L0.93896 9.2099C0.883922 9.26493 0.842489 9.33204 0.81796 9.4059L0.0129599 11.8199C-0.00163927 11.8639 -0.00371214 11.9111 0.00697324 11.9562C0.0176586 12.0014 0.0406814 12.0426 0.0734677 12.0754C0.106254 12.1082 0.147512 12.1312 0.192631 12.1419C0.23775 12.1526 0.284951 12.1505 0.32896 12.1359L2.74296 11.3309C2.81672 11.3067 2.88383 11.2656 2.93896 11.2109L9.75196 4.3959Z"
        fill="currentColor"
      />
    </SvgWrapper>
  );
};
