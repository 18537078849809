// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMapWithTransform = <T, V, K extends string | number>(
    list: T[],
    getKey: (item: T) => K,
    getTransformedValue: (item: T, index: number) => V,
    duplicatedKeyHandlingLevel: "THROW_ERROR" | "WARN" | "IGNORE" = "THROW_ERROR",
): Record<K, V> =>
    list.reduce(
        (map, item, index) => {
            const key = getKey(item);
            if (map[key] !== undefined) {
                const errorMessage = () =>
                    `Key ${key} has conflict. already added item: ${JSON.stringify(
                        map[key],
                    )}, new item: ${JSON.stringify(item)}`;
                if (duplicatedKeyHandlingLevel === "THROW_ERROR") {
                    throw Error(errorMessage());
                } else if (duplicatedKeyHandlingLevel === "WARN") {
                    console.warn(errorMessage());
                }
            }
            map[key] = getTransformedValue(item, index);
            return map;
        },
        {} as Record<K, V>,
    );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMap = <T, K extends string | number>(
    list: T[],
    getKey: (item: T) => K,
    duplicatedKeyHandlingLevel: "THROW_ERROR" | "WARN" | "IGNORE" = "THROW_ERROR",
): Record<K, T> => toMapWithTransform(list, getKey, (t) => t, duplicatedKeyHandlingLevel);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupByWithTransform = <T, V, K extends keyof any>(
    list: T[],
    getKey: (item: T) => K,
    getTransformedValue: (item: T, index: number) => V,
): Record<K, V[]> =>
    list.reduce(
        (map, item, index) => {
            const group = getKey(item);
            if (!map[group]) map[group] = [];
            map[group].push(getTransformedValue(item, index));
            return map;
        },
        {} as Record<K, V[]>,
    );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> =>
    groupByWithTransform(list, getKey, (t) => t);

export const distinct = <T extends string | number>(list: T[]): T[] => {
    return Array.from(new Set(list));
};

export const uniqueCheck = <T>(list: Array<T>): boolean => {
    return list.length === new Set(list).size;
};
