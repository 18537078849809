import React, { ChangeEventHandler, forwardRef, KeyboardEvent, useCallback, FocusEventHandler } from "react";

import { OutlinedInput } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

interface Props {
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  endIcon?: React.ReactElement;
  error?: boolean;
  name?: string;
}

export const EditableCell = forwardRef<HTMLInputElement, Props>((props, forwardedRef) => {
  const theme = useAmeTheme();

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  }, []);

  return (
    <OutlinedInput
      inputRef={forwardedRef}
      name={props.name}
      value={props.value}
      fullWidth={true}
      onBlur={props.onBlur}
      onKeyDown={onKeyDown}
      onChange={props.onChange}
      endAdornment={props.endIcon}
      error={props.error}
      sx={{
        borderRadius: "0",
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "&:hover": {
          backgroundColor: theme.common.background.gray40,
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        },
        "&.Mui-focused": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.brand.secondary[100],
          },
        },
        "&.Mui-error": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.common.border.error,
          },
        },
      }}
    />
  );
});

EditableCell.displayName = EditableCell.name;
