import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { User } from "../../models/User";

import { UseChoiceGroupParams } from "./type";

export const useUploaderChoiceGroup = (
  uploaders: User[],
  { filterParams, navigate }: UseChoiceGroupParams<{ uploaderIds: string[] }>,
): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    return {
      groupName: "登録者",
      uiType: "flat",
      choiceOptions: uploaders
        .filter(
          (
            uploader,
          ): uploader is User & {
            userId: string;
          } => uploader.userId !== undefined,
        )
        .map((uploader) => ({
          name: uploader.getFullName(),
          toggleOption: async () =>
            await navigate({
              search: (prev: { uploaderIds: string[] }) => {
                const uploaderIdSet = new Set(prev.uploaderIds);
                if (!uploaderIdSet.delete(uploader.userId!)) {
                  uploaderIdSet.add(uploader.userId!);
                }
                return { ...prev, uploaderIds: Array.from(uploaderIdSet) };
              },
            }),
          isChecked: filterParams.uploaderIds.includes(uploader.userId),
        })),
    };
  }, [filterParams, uploaders, navigate]);
};
