/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NominalTypingEmployeeAttributeDefinitionString,
    NominalTypingEmployeeAttributeDefinitionStringFromJSON,
    NominalTypingEmployeeAttributeDefinitionStringFromJSONTyped,
    NominalTypingEmployeeAttributeDefinitionStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChangeSetItemAttributeResponse
 */
export interface ChangeSetItemAttributeResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangeSetItemAttributeResponse
     */
    attributeKey: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeSetItemAttributeResponse
     */
    attributeValue?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeSetItemAttributeResponse
     */
    isValidValue: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeSetItemAttributeResponse
     */
    currentValue?: string;
    /**
     * 
     * @type {NominalTypingEmployeeAttributeDefinitionString}
     * @memberof ChangeSetItemAttributeResponse
     */
    currentDefinitionId?: NominalTypingEmployeeAttributeDefinitionString;
}

export function ChangeSetItemAttributeResponseFromJSON(json: any): ChangeSetItemAttributeResponse {
    return ChangeSetItemAttributeResponseFromJSONTyped(json, false);
}

export function ChangeSetItemAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeSetItemAttributeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeKey': json['attributeKey'],
        'attributeValue': !exists(json, 'attributeValue') ? undefined : json['attributeValue'],
        'isValidValue': json['isValidValue'],
        'currentValue': !exists(json, 'currentValue') ? undefined : json['currentValue'],
        'currentDefinitionId': !exists(json, 'currentDefinitionId') ? undefined : NominalTypingEmployeeAttributeDefinitionStringFromJSON(json['currentDefinitionId']),
    };
}

export function ChangeSetItemAttributeResponseToJSON(value?: ChangeSetItemAttributeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeKey': value.attributeKey,
        'attributeValue': value.attributeValue,
        'isValidValue': value.isValidValue,
        'currentValue': value.currentValue,
        'currentDefinitionId': NominalTypingEmployeeAttributeDefinitionStringToJSON(value.currentDefinitionId),
    };
}


