import React, { useCallback, useEffect, useState } from "react";

import { requireExactlyOneElement } from "@amecloud/common-utils/src/utils/Assertions";
import { promiseRetry } from "@amecloud/common-utils/src/utils/PromiseUtils";
import { Warning } from "@mui/icons-material";
import { createFileRoute, Link } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../../../hooks/useHrPentestSnackbar";
import { HrInterviewFile } from "../../../../../../../models/hrInterview/HrInterviewFile";
import { useGetHrInterview, useUpdateFileOfHrInterview } from "../../../../../../../store/hooks/hrInterview";
import {
  useCompleteHrInterviewFileUpload,
  useCreateHrInterviewFile,
  useGetHrInterviewFileUploaderUrl,
} from "../../../../../../../store/hooks/hrInterviewFiles";
import { getAudioDuration } from "../../../../../../../utils/AudioUtils";
import { useAmeTheme } from "../../../../../../../utils/styles/AmeTheme";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeFormFollowingButtonsLayout } from "../../../../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import {
  FileProperties,
  InterviewFileNameMapper,
} from "../../../../../../organisms/hr-interviews/InterviewFileNameMapper";
import { AudioFilesImporter } from "../../../../../../organisms/interview-files/register/common/AudioFilesImporter";

const InterviewFilePage: React.FC = () => {
  const { interviewId } = Route.useParams();
  const navigate = Route.useNavigate();

  const interviewQuery = useGetHrInterview(interviewId);

  const theme = useAmeTheme();
  const getPresignedUrl = useGetHrInterviewFileUploaderUrl();
  const completeMultipartUpload = useCompleteHrInterviewFileUpload();
  const createInterviewFile = useCreateHrInterviewFile();
  const updateFile = useUpdateFileOfHrInterview();

  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const [file, setFile] = useState<File>();
  const [interviewFileProperty, setInterviewFileProperty] = useState<FileProperties>();

  const setImportedFiles = useCallback((files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  }, []);

  const uploadFile = useCallback(
    async (file: File, updateProgress: (progressPercentage: number) => void): Promise<{ s3FileName: string }> => {
      updateProgress(0);
      const res = await getPresignedUrl({
        getHrInterviewFileUploaderUrlBody: {
          fileSize: file.size,
        },
      });

      if (!res.isSuccess) {
        enqueueErrorSnackbar();
        throw new Error("Failed to get presigned URL");
      }

      try {
        for (const i of res.data.partUploadUrls.keys()) {
          await promiseRetry(async () => {
            const response = await fetch(res.data.partUploadUrls[i], {
              method: "PUT",
              body: file.slice(i * res.data.chunkSize, (i + 1) * res.data.chunkSize),
            });
            if (!response.ok) {
              throw new Error(`Failed to upload part ${i + 1} of ${res.data.partUploadUrls.length}`);
            }
            updateProgress(((i + 1) / res.data.partUploadUrls.length) * 100);
          });
        }
      } catch {
        enqueueErrorSnackbar({
          title: "音声データのアップロードに失敗しました。",
          message: "ブラウザをリロードして再度お試しください。",
        });
      }
      await completeMultipartUpload({
        uploadId: res.data.multipartUploadId,
        completeHrInterviewFileUploadBody: { filename: res.data.filename },
      });

      return { s3FileName: res.data.filename };
    },
    [completeMultipartUpload, enqueueErrorSnackbar, getPresignedUrl],
  );

  useEffect(() => {
    (async () => {
      if (file) {
        let duration: number;
        try {
          duration = await getAudioDuration(file);
        } catch {
          enqueueErrorSnackbar({
            title: "音声データの読み込みに失敗しました。",
            message: "有効な音声データか確認をしてください。 ファイル名: " + file.name,
          });
          setInterviewFileProperty(
            (current) =>
              current && {
                ...current,
                status: "error",
              },
          );
          throw new Error("Failed to get audio duration");
        }

        setInterviewFileProperty({
          duration: duration,
          file: file,
          s3FileName: "",
          status: "pending",
          percentage: 0,
          fileName: file.name,
        });
        await uploadFile(file, (percentage) => {
          setInterviewFileProperty(
            (current) =>
              current && {
                ...current,
                percentage: percentage,
                status: "uploading",
              },
          );
        }).then(({ s3FileName }) =>
          setInterviewFileProperty(
            (current) =>
              current && {
                ...current,
                status: "uploaded",
                s3FileName: s3FileName,
              },
          ),
        );
      } else {
        setInterviewFileProperty(undefined);
      }
    })();
  }, [enqueueErrorSnackbar, file, uploadFile]);

  const register = useCallback(
    async (fileCandidate?: FileProperties) => {
      if (!fileCandidate) {
        await updateFile({
          hrInterviewId: interviewId,
          updateFileOfHrInterviewRequestBody: {
            hrInterviewFileId: null,
          },
        });
        return;
      }
      const s3FileName = fileCandidate.s3FileName;
      if (!s3FileName) {
        enqueueErrorSnackbar();
        throw new Error("S3 file name is not set");
      }
      const res = await createInterviewFile({
        createHrInterviewFileRequestBody: {
          files: [
            {
              duration: fileCandidate.duration,
              s3FileName: s3FileName,
              originalFileName: fileCandidate.file.name,
              userGroupId: undefined,
              interviewerId: undefined,
            },
          ],
        },
      });
      if (!res.isSuccess) {
        enqueueErrorSnackbar({ title: "音声データの登録に失敗しました。" });
        throw new Error("Failed to create interview file");
      }
      await updateFile({
        hrInterviewId: interviewId,
        updateFileOfHrInterviewRequestBody: {
          hrInterviewFileId: requireExactlyOneElement(res.data).hrInterviewFileId,
        },
      });
    },
    [createInterviewFile, updateFile, enqueueErrorSnackbar, interviewId],
  );

  useEffect(() => {
    if (interviewQuery.data?.hrInterviewFile) {
      const interviewFile: HrInterviewFile = interviewQuery.data.hrInterviewFile;
      setInterviewFileProperty({
        duration: interviewFile.duration,
        file: {} as never,
        s3FileName: interviewFile.hrInterviewFileId,
        status: "exist",
        percentage: 100,
        fileName: interviewFile.originalFileName,
      });
    }
  }, [interviewQuery]);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "在職者面談一覧", to: "/hr-interviews/interviews" },
          { title: "在職者面談詳細", to: "../" },
        ]}
        currentTitle={"音声データ編集"}
      />
      <PageLayoutBody>
        <WaitForSuccess queryState={interviewQuery}>
          {() => (
            <>
              <PaperWrapper>
                <PaperHeader>登録音声データ</PaperHeader>
                <PaperBody>
                  {interviewFileProperty ? (
                    <InterviewFileNameMapper
                      interviewFileProperties={[interviewFileProperty]}
                      removeFile={() => {
                        setFile(undefined);
                        setInterviewFileProperty(undefined);
                      }}
                    />
                  ) : (
                    <AmeBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: `1px solid ${theme.common.border.gray40}`,
                        padding: "8px 0",
                        "&: last-child": { borderBottom: "none" },
                      }}
                    >
                      <AmeBox sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <Warning sx={{ color: `${theme.brand.primary[100]}` }} />
                        <AmeBox sx={{ display: "flex", alignItems: "center" }}>
                          新規登録または
                          <Link to={"/hr-interviews/interview-files"} search={{} as never}>
                            <AmeTypography color={"secondary"}>登録済みの音声</AmeTypography>
                          </Link>
                          から選んでください
                        </AmeBox>
                      </AmeBox>
                    </AmeBox>
                  )}
                </PaperBody>
              </PaperWrapper>
              <Spacer height="24px" />
              <PaperWrapper>
                <PaperHeader>新規登録</PaperHeader>
                <PaperBody>
                  <AudioFilesImporter onImported={setImportedFiles} />
                </PaperBody>
              </PaperWrapper>
            </>
          )}
        </WaitForSuccess>
        {/*<Spacer height="24px" />*/}
        {/*<PaperWrapper>*/}
        {/*  <PaperHeader>登録済みの音声一覧から選択</PaperHeader>*/}
        {/*  <PaperBody>*/}
        {/*    <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "23px" }}>*/}
        {/*      <AmeTypography>音声データを検索して選択する</AmeTypography>*/}

        {/*      <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>*/}
        {/*        <AmeTypography fontSize="body1">登録者</AmeTypography>*/}
        {/*        <AmeDropbox selectedLabel={registrant} size="full">*/}
        {/*          {registrantDatas.map((registrantData, index) => {*/}
        {/*            return (*/}
        {/*              <AmeCommonListItem onClick={() => setRegistrant(registrantData)} key={index}>*/}
        {/*                <AmeTypography>{registrantData}</AmeTypography>*/}
        {/*              </AmeCommonListItem>*/}
        {/*            );*/}
        {/*          })}*/}
        {/*        </AmeDropbox>*/}
        {/*      </AmeBox>*/}

        {/*      <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>*/}
        {/*        <AmeTypography fontSize="body1">アップロード日</AmeTypography>*/}
        {/*        <AmeBox sx={{ display: "flex", justifyContent: "left", gap: "10px", alignItems: "center" }}>*/}
        {/*          <AmeDateInput onCommitDate={(newDate) => setDateFrom(newDate ?? undefined)} value={dateFrom} />*/}
        {/*          <AmeTypography>~</AmeTypography>*/}
        {/*          <AmeDateInput onCommitDate={(newDate) => setDateTo(newDate ?? undefined)} value={dateTo} />*/}
        {/*        </AmeBox>*/}
        {/*      </AmeBox>*/}
        {/*      <AmeBox*/}
        {/*        sx={{*/}
        {/*          width: "100%",*/}
        {/*          maxHeight: "270px",*/}
        {/*          overflowY: "scroll",*/}
        {/*          "& > *": { borderBottom: `1px solid ${theme.common.border.gray40}` },*/}
        {/*          "& > *:hover": { background: theme.common.background.gray20 },*/}
        {/*          "& > *:last-child": { borderBottom: "none" },*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {audioDatasRegistered.map((audioDataRegistered, index) => {*/}
        {/*          return (*/}
        {/*            <AmeBox key={index}>*/}
        {/*              <AmeCommonListItem onClick={() => updateFile(audioDataRegistered)}>*/}
        {/*                <AmeTypography fontSize="body1" color="secondary">*/}
        {/*                  {audioDataRegistered.displayName}*/}
        {/*                </AmeTypography>*/}
        {/*              </AmeCommonListItem>*/}
        {/*            </AmeBox>*/}
        {/*          );*/}
        {/*        })}*/}
        {/*      </AmeBox>*/}
        {/*    </AmeBox>*/}
        {/*  </PaperBody>*/}
        {/*</PaperWrapper>*/}
      </PageLayoutBody>
      <AmeFormFollowingButtonsLayout
        primaryDisabled={Boolean(
          interviewQuery.data?.hrInterviewFile?.hrInterviewFileId &&
            interviewFileProperty !== undefined &&
            interviewFileProperty?.status !== "uploaded",
        )}
        onPrimary={async () => {
          await register(interviewFileProperty!);
          await navigate({
            to: "/hr-interviews/interviews/$interviewId",
            params: { interviewId: interviewId },
          });
        }}
        onSecondary={async () => {
          await navigate({
            to: "/hr-interviews/interviews/$interviewId",
            params: { interviewId: interviewId },
          });
        }}
        secondaryText={"キャンセル"}
      />
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-all/hr-interviews/interviews/$interviewId/interview-file",
)({
  component: InterviewFilePage,
});
