import React from "react";

import { Link } from "@tanstack/react-router";

import { HrInterviewFile } from "../../../../../models/hrInterview/HrInterviewFile";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { CheckV2Icon } from "../../../../atoms/icon/CheckV2";
import { Elipse } from "../../../../atoms/icon/Elipse";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { IconText } from "../../../../molecules/common/IconText";

interface Props {
  interviewFiles: HrInterviewFile[];
}

export const InterviewFileTable: React.FC<Props> = ({ interviewFiles }) => {
  return (
    <AmeTable>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell>ファイル名</AmeTableHeadCell>
          <AmeTableHeadCell>登録者</AmeTableHeadCell>
          <AmeTableHeadCell>アップロード日</AmeTableHeadCell>
          <AmeTableHeadCell>紐づけ先</AmeTableHeadCell>
          <AmeTableHeadCell>音声ステータス</AmeTableHeadCell>
          <AmeTableHeadCell />
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {interviewFiles.map((file) => (
          <AmeTableRow key={file.hrInterviewFileId}>
            <AmeTableBodyCell>
              <Link
                to={"/hr-interviews/interview-files/$interviewFileId"}
                params={{ interviewFileId: file.hrInterviewFileId }}
              >
                <AmeTypography color={"secondary"}>{file.originalFileName}</AmeTypography>
              </Link>
            </AmeTableBodyCell>
            <AmeTableBodyCell>{file.uploadUser?.getFullName()}</AmeTableBodyCell>
            <AmeTableBodyCell>{formatDateToYYYYMMDD(file.createdAt)}</AmeTableBodyCell>
            <AmeTableBodyCell>
              {" "}
              {file.hrInterview?.hrInterviewId ? (
                <Link
                  to={"/hr-interviews/interviews/$interviewId"}
                  params={{ interviewId: file.hrInterview.hrInterviewId }}
                >
                  <AmeTypography color={"secondary"}>{file.linkStatusText}</AmeTypography>
                </Link>
              ) : (
                file.linkStatusText
              )}
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              {file.hrInterview ? (
                <IconText icon={<CheckV2Icon color={"success"} />}>面談紐づけ済</IconText>
              ) : (
                <IconText icon={<Elipse />}>面談紐づけ前</IconText>
              )}
            </AmeTableBodyCell>
            <AmeTableBodyCell>
              <AmeButton
                color={"basic"}
                disabled={file.hrInterview !== undefined}
                variant={"outlined"}
                size={"small"}
                to={"/hr-interviews/interview-files/$interviewFileId/create-interview"}
                params={{ interviewFileId: file.hrInterviewFileId }}
              >
                面談作成
              </AmeButton>
            </AmeTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
