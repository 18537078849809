import React from "react";

import { SurveySendingMethod } from "../../../../models/constants/values";
import { Email } from "../../../atoms/icon/Email";
import { EmailWithQrCode } from "../../../atoms/icon/EmailWithQrCode";
import { QrCode } from "../../../atoms/icon/QrCode";

export const SURVEY_SENDING_METHOD_TEXT_AND_ICON: Record<SurveySendingMethod, { text: string; icon: React.ReactNode }> =
  {
    email: {
      text: "メールから回答してもらう",
      icon: <Email color="inherit" size={18} />,
    },
    "qr-code": {
      text: "QRコードから回答してもらう",
      icon: <QrCode color="inherit" size={16} />,
    },
    both: {
      text: "メールとQRコードを併用する",
      icon: <EmailWithQrCode color="inherit" size={18} />,
    },
  };
