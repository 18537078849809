import { HrInterviewFormat, HrInterviewQuestion, HrInterviewType } from "../../models/HrInterviewSetting";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseQueryOptions } from "./utils/types";

export const useGetInterviewTypes = (includeDeleted: boolean = false, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewTypes.useQuery(
    { includeDeleted },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (response): HrInterviewType[] =>
      response.interviewTypes.map((type) => ({
        hrInterviewTypeId: type.interviewTypeId,
        hrInterviewTypeText: type.displayName,
      })),
    onError: options?.onError,
  });
};

export const useRegisterInterviewType = () => {
  const [registerInterviewType] = defaultApi.endpoints.registerInterviewType.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: registerInterviewType,
  });
};

export const useGetInterviewType = (interviewTypeId: string) => {
  const result = defaultApi.endpoints.getInterviewType.useQuery({ interviewTypeId });
  return useQueryResult({
    result,
    fromResponse: (response): HrInterviewType => ({
      hrInterviewTypeId: response.interviewTypeId,
      hrInterviewTypeText: response.displayName,
    }),
  });
};

// タイプIDによるインタビューフォーマットの取得
export const useGetInterviewFormats = (interviewTypeId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewFormatByTypeId.useQuery(
    { interviewTypeId },
    {
      pollingInterval: options?.pollingInterval,
      skip: options?.skip,
    },
  );
  return useQueryResult({
    result,
    fromResponse: (response): HrInterviewFormat[] =>
      response.interviewFormats.map((format) => ({
        hrInterviewFormatId: format.interviewFormatId,
        hrInterviewFormatText: format.displayName,
      })),
    onError: options?.onError,
  });
};

// インタビューフォーマットの登録
export const useRegisterInterviewFormats = () => {
  const [registerInterviewFormats] = defaultApi.endpoints.registerInterviewFormats.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: registerInterviewFormats,
  });
};

// インタビューフォーマットコンテンツの取得
export const useGetInterviewFormatContents = (interviewFormatId: string) => {
  const result = defaultApi.endpoints.getInterviewFormatContents.useQuery({ interviewFormatId });
  return useQueryResult({
    result,
    fromResponse: (response): HrInterviewQuestion[] =>
      response.map((content) => ({
        hrInterviewQuestionId: content.interviewFormatContentId,
        hrInterviewQuestionText: content.formatContent,
      })),
  });
};

// インタビューフォーマットコンテンツの登録
export const useRegisterInterviewFormatContents = () => {
  const [registerInterviewFormatContents] = defaultApi.endpoints.registerInterviewFormatContents.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: registerInterviewFormatContents,
  });
};
