import React, { useCallback, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../../../hooks/useHrPentestSnackbar";
import { useSp } from "../../../../../../../hooks/useSp";
import { useDeleteInterviewFile, useGetHrInterviewFile } from "../../../../../../../store/hooks/hrInterviewFiles";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../atoms/paper/PaperWrapper";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { AmeDialog } from "../../../../../../molecules/dialog/AmeDialog";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";
import { AudioDeleteDialogContent } from "../../../../../../organisms/hr-interviews/AudioDeleteDialogContent";
import { InterviewFileContent } from "../../../../../../organisms/hr-interviews/interview-files/$interviewFileId/index";

const InterviewFilePage: React.FC = () => {
  const { interviewFileId } = Route.useParams();

  const sp = useSp();

  const getInterviewFileQuery = useGetHrInterviewFile(interviewFileId);
  const deleteInterviewFile = useDeleteInterviewFile();

  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const navigate = Route.useNavigate();

  const onDelete = useCallback(async () => {
    const res = await deleteInterviewFile({ hrInterviewFileId: interviewFileId });
    if (res.isSuccess) {
      enqueueSuccessSnackbar({ title: "音声データを削除しました" });
      await navigate({ to: "/hr-interviews/interview-files", search: {} as never });
    }
  }, [deleteInterviewFile, enqueueSuccessSnackbar, interviewFileId, navigate]);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "在職者音声一覧", to: "/hr-interviews/interview-files" }]}
        currentTitle={"音声データ詳細"}
      />
      <PageLayoutBody>
        <AmeBox sx={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "8px", margin: "24px 0" }}>
          <AmeBox sx={{ marginRight: "auto" }}>
            <WaitForSuccess queryState={getInterviewFileQuery}>
              {(interviewFile) => <AmeTypography component="h3">{interviewFile.originalFileName}</AmeTypography>}
            </WaitForSuccess>
          </AmeBox>
          {sp ? null : (
            <AmeButton
              variant="outlined"
              to={"/hr-interviews/interview-files/$interviewFileId/create-interview"}
              params={{ interviewFileId: interviewFileId }}
            >
              面談作成
            </AmeButton>
          )}
          <AmeButton
            variant="outlined"
            to={"/hr-interviews/interview-files/$interviewFileId/edit"}
            params={{ interviewFileId: interviewFileId }}
          >
            編集
          </AmeButton>
          <AmeButton onClick={onClick} variant="outlined">
            削除
          </AmeButton>
          <AmeDialog open={isOpen} onClose={onClose}>
            <AudioDeleteDialogContent onDelete={onDelete} onClose={onClose} />
          </AmeDialog>
        </AmeBox>
        <PaperWrapper>
          <PaperHeader>音声データ詳細</PaperHeader>
          <PaperBody>
            <WaitForSuccess queryState={getInterviewFileQuery}>
              {(interviewFile) => <InterviewFileContent interviewFile={interviewFile} />}
            </WaitForSuccess>
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-all/hr-interviews/interview-files/$interviewFileId/",
)({
  component: InterviewFilePage,
});
