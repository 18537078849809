import React, { useCallback, useContext } from "react";

import { sum } from "@amecloud/common-utils/src/utils/ArrayUtils";
import { createFileRoute } from "@tanstack/react-router";

import { useGetCategoryContent } from "../../../../../hooks/filterHooks/useCategoryChoiceGroup";
import { useGetSegmentContent } from "../../../../../hooks/filterHooks/useSegmentChoiceGroup";
import { useSelectedFilter } from "../../../../../hooks/useSelectedFilter";
import { AxisLiteralOfTypeofSurveyReportAxisFactory } from "../../../../../store/autogenApi";
import { SurveyReportParams, useSurveyReport } from "../../../../../store/hooks/surveys";
import { formatNumber } from "../../../../../utils/formatNumber";
import { ColumnChart } from "../../../../atoms/charts/ColumnChart";
import { ColumnsWithRatioChart } from "../../../../atoms/charts/ColumnsWithRatioChart";
import { HorizontalStackedBarChart } from "../../../../atoms/charts/HorizontalStackedBarChart";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { V2ContentWrapper } from "../../../../atoms/wrapper/V2ContentWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { ChoiceGroupProps } from "../../../../molecules/filter/ChoiceGroup/type";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { SurveyFilter } from "../../../../organisms/v2/SurveyFilter";
import { ReportHeader } from "../../../../organisms/v2/v2Component/ReportHeader";

type SurveyReportQueryParams = Omit<SurveyReportParams, "surveyId"> & { surveyId: string | undefined };

const SurveyReport: React.FC = () => {
  const search = Route.useSearch();
  const navigate = Route.useNavigate();
  const filterGroups: ChoiceGroupProps[] = [
    useGetSegmentContent(search, navigate),
    useGetCategoryContent(search, navigate),
  ];

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"アンケートレポート"} />
      <PageLayoutBody type={"wide"}>
        <AmeBox sx={{ display: "flex", gap: "24px" }}>
          <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
            <SurveyFilter
              surveyId={search.surveyId}
              setSurveyId={(surveyId) =>
                navigate({
                  search: (prev) => ({
                    ...prev,
                    surveyId: surveyId,
                  }),
                })
              }
              choiceGroups={filterGroups}
            />
          </AmeBox>
          <AmeBox sx={{ flexGrow: 1, minWidth: 0 }}>
            {search.surveyId ? (
              <SurveyReportContent
                surveyId={search.surveyId}
                queryParams={search}
                filterGroups={filterGroups}
                changeAxis={(axis: AxisLiteralOfTypeofSurveyReportAxisFactory) =>
                  navigate({
                    search: (prev) => ({
                      ...prev,
                      axis: axis,
                    }),
                  })
                }
              />
            ) : (
              "アンケートを選択してください"
            )}
          </AmeBox>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

const SurveyReportContent: React.FC<{
  surveyId: string;
  queryParams: SurveyReportQueryParams;
  filterGroups: ChoiceGroupProps[];
  changeAxis: (axis: AxisLiteralOfTypeofSurveyReportAxisFactory) => void;
}> = ({ surveyId, queryParams, filterGroups, changeAxis }) => {
  const {
    setting: {
      segmentNames,
      globalSetting: { maxScaleValueInSurveyReport, maxScalePercentageInSurveyReport },
    },
  } = useContext(DataSourceContext);
  const axisDisplayNames: Record<AxisLiteralOfTypeofSurveyReportAxisFactory, string> = {
    NONE: "軸なし",
    ...segmentNames,
    subCategoryId: "カテゴリ",
  };
  const onChangeAxis = useCallback(
    (e) => {
      changeAxis(e.target.value as AxisLiteralOfTypeofSurveyReportAxisFactory);
    },
    [changeAxis],
  );

  // TODO: ここ単一のEndPointに混ぜる
  const surveyReportQueryForCount = useSurveyReport({ ...queryParams, surveyId: surveyId, axis: "NONE" });
  const surveyReportQuery = useSurveyReport({ ...queryParams, surveyId: surveyId });
  const selectedFilter = useSelectedFilter(filterGroups);

  return (
    <>
      <WaitForSuccess queryState={surveyReportQueryForCount}>
        {({ responseStats }) => {
          return (
            <ReportHeader
              count={sum(responseStats.map(({ answeredCount }) => answeredCount))}
              selectedFilters={selectedFilter}
              axisDisplayNames={axisDisplayNames}
              selectedAxis={queryParams.axis}
              onChangeAxis={onChangeAxis}
            />
          );
        }}
      </WaitForSuccess>
      <PaperWrapper>
        <PaperBody>
          <V2ContentWrapper title={"アンケート回答率"}>
            <WaitForSuccess queryState={surveyReportQuery}>
              {(surveyReport) => {
                const data = surveyReport.responseStats;
                return (
                  <ColumnsWithRatioChart
                    seriesAll={data.map(({ sheetCount }) => sheetCount)}
                    seriesSome={data.map(({ answeredCount }) => answeredCount)}
                    seriesLabels={data.map(({ axis }) => axis)}
                    names={["回答対象数", "回答数", "回答率"]}
                    columnMax={maxScaleValueInSurveyReport}
                    ratioMax={
                      maxScalePercentageInSurveyReport !== undefined ? maxScalePercentageInSurveyReport / 100 : 1
                    }
                    height={300}
                  />
                );
              }}
            </WaitForSuccess>
          </V2ContentWrapper>
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperBody>
          <V2ContentWrapper title={"スコア平均"}>
            <WaitForSuccess queryState={surveyReportQuery}>
              {(surveyReport) => {
                const data = surveyReport.scoreStats;
                const averages = data.map(
                  ({ stats }) =>
                    sum(stats.flatMap((stat) => stat.score * stat.count)) / sum(stats.flatMap((stat) => stat.count)),
                );
                const keys = data.map(({ axis }) => axis);
                return (
                  <ColumnChart
                    tickAmount={5}
                    height={297}
                    max={5}
                    formatter={(value) => formatNumber(value, 2)}
                    series={averages}
                    seriesLabels={keys}
                  />
                );
              }}
            </WaitForSuccess>
          </V2ContentWrapper>
        </PaperBody>
      </PaperWrapper>
      <Spacer height="24px" />
      <PaperWrapper>
        <PaperBody>
          <V2ContentWrapper title={"スコア分布"}>
            <WaitForSuccess queryState={surveyReportQuery}>
              {(surveyReport) => {
                const data = surveyReport.scoreStats;
                const series: { name: string; data: { name: string; value: number }[] }[] = [];
                for (const d of data) {
                  series.push({
                    name: d.axis,
                    data: d.stats.map((stat) => ({
                      name: stat.score.toString(),
                      value: stat.count,
                    })),
                  });
                }
                const oneScoreCount = sum(
                  surveyReport.scoreStats.flatMap((d) => d.stats.filter(({ score }) => score == 1)).map((d) => d.count),
                );
                const totalCount = sum(surveyReport.scoreStats.flatMap((d) => d.stats).map((d) => d.count));
                return (
                  <HorizontalStackedBarChart
                    series={series}
                    legend={{
                      formatter: (seriesName: string) => {
                        return Number(seriesName) + "点";
                      },
                    }}
                    annotations={{
                      xaxis: [
                        {
                          x: (oneScoreCount / totalCount) * 100,
                          label: { text: "1点の平均割合" },
                          strokeDashArray: 0,
                          borderColor: "#016878", // Secondary/Secondary/Secondary120
                        },
                      ],
                    }}
                  />
                );
              }}
            </WaitForSuccess>
          </V2ContentWrapper>
        </PaperBody>
      </PaperWrapper>
    </>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/survey-report/")({
  validateSearch: (search: Partial<SurveyReportQueryParams>): SurveyReportQueryParams => ({
    surveyId: search.surveyId,
    axis: search.axis ?? "NONE",
    SEX: search.SEX ?? [],
    DEPARTMENT: search.DEPARTMENT ?? [],
    FREE1: search.FREE1 ?? [],
    FREE2: search.FREE2 ?? [],
    AGE: search.AGE ?? [],
    MONTHS_OF_SERVICE: search.MONTHS_OF_SERVICE ?? [],
    subCategoryIds: search.subCategoryIds ?? [],
  }),
  component: SurveyReport,
});
