import React, { useCallback, useState } from "react";

import { useSp } from "../../../../hooks/useSp";

import { LaptopSearchDropdown } from "./LaptopSearchDropdown";
import { SpSearchDropdown } from "./SpSearchDropdown";

interface Props<Value> {
  getSuggests: (search: string) => Promise<Value[]>;
  placeholder?: string;
  title: string;
  currentValue?: Value;
  disabled?: boolean;
  onSelect: (data?: Value) => void;
  getId: (data: Value) => string;
  getLabel: (data?: Value) => string;
  renderRow?: (label: string, suggest?: Value) => React.ReactNode;
  getDisabled?: (data?: Value) => boolean;
  height?: number;
  error?: boolean;
}

export const SearchDropdown = <Value,>(props: Props<Value>) => {
  const sp = useSp();

  const [selected, setSelected] = useState<Value | undefined>(props.currentValue);
  const onSelect = useCallback(
    (data?: Value) => {
      props.onSelect(data);
      setSelected(data);
    },
    [props],
  );
  return (
    <>
      {sp ? (
        <SpSearchDropdown
          placeholder={props.placeholder}
          getSuggests={props.getSuggests}
          getId={props.getId}
          getLabel={props.getLabel}
          onSubmit={onSelect}
          value={selected}
          getDisabled={props.getDisabled}
          title={props.title}
          renderRow={props.renderRow}
          disabled={props.disabled}
        />
      ) : (
        <LaptopSearchDropdown
          value={selected}
          placeholder={props.placeholder}
          getSuggests={props.getSuggests}
          getId={props.getId}
          getLabel={props.getLabel}
          renderRow={props.renderRow}
          getDisabled={props.getDisabled}
          onChange={onSelect}
          disabled={props.disabled}
        />
      )}
    </>
  );
};
