import React from "react";

import { AddCircleOutline } from "@mui/icons-material";
import { UseFieldArrayAppend } from "react-hook-form";

import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { QuestionSettingFormType } from "./SurveyQuestionSettingContent";

interface Props {
  append: UseFieldArrayAppend<QuestionSettingFormType, "questions">;
}

export const QuestionAddingRow: React.FC<Props> = (props) => {
  const theme = useAmeTheme();

  return (
    <AmeBox
      sx={{
        borderBottom: `1px solid ${theme.common.border.gray40}`,
        borderLeft: `1px solid ${theme.common.border.gray40}`,
        borderRight: `1px solid ${theme.common.border.gray40}`,
        borderTop: "none",
        width: "100%",
        // TableのRowの高さを参照
        height: "56px",
        textAlign: "left",
        backgroundColor: theme.common.background.white,
        padding: "16px",
        "&:hover": {
          backgroundColor: theme.common.background.gray40,
        },
      }}
      onClick={() => props.append({ questionText: "", subCategoryId: "" })}
    >
      <AmeBox
        component="span"
        sx={{ color: theme.common.text.gray20, display: "inline-flex", alignItems: "center", gap: "8px" }}
      >
        <AddCircleOutline htmlColor={theme.common.text.gray20} fontSize="small" />
        追加
      </AmeBox>
    </AmeBox>
  );
};
