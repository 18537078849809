export const removeFirstIfExists = <T extends string | number>(ts: T[], element: T): T[] => {
    const pos = ts.indexOf(element);
    if (pos == -1) {
        return ts;
    }
    const res: T[] = [...ts];
    res.splice(pos, 1);
    return res;
};

export const appendIfNotExists = <T extends string | number>(ts: T[], ...elements: T[]): T[] => {
    const response = [...ts];
    for (const element of elements) {
        if (!response.includes(element)) {
            response.push(element);
        }
    }
    return response;
};

export const computeDiff = <Element, Key extends number | string>(
    oldList: Element[],
    newList: Element[],
    getKey: (e: Element) => Key,
): { removed: Element[]; added: Element[] } => {
    const oldSet = new Set(oldList.map((e) => getKey(e)));
    const newSet = new Set(newList.map((e) => getKey(e)));
    return {
        removed: oldList.filter((e) => !newSet.has(getKey(e))),
        added: newList.filter((e) => !oldSet.has(getKey(e))),
    };
};

export const splitIntoChunk = <T>(array: Array<T>, chunkSize: number): Array<Array<T>> => {
    if (chunkSize <= 0) {
        throw new Error(`chunkSize must be positive: ${chunkSize}`);
    }
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};

export async function* toAsyncIterable<T>(array: Iterable<T>): AsyncIterable<T> {
    for (const v of array) {
        yield v;
    }
}

export const isNotNull = <T>(v: T | undefined | null): v is T => {
    return v != undefined;
};

export const sum = (v: number[]): number => v.reduce((total, value) => total + value, 0);

export const toArrayFromNullableAndSinglable = <T>(value?: T | T[]): T[] => {
    return value === undefined ? [] : Array.isArray(value) ? value : [value];
};

export function zip<T1, T2>(arr1: T1[], arr2: T2[]): [T1, T2][] {
    if (arr1.length !== arr2.length) {
        throw new Error("配列の長さが一致していません。");
    }

    return arr1.map((_, i) => [arr1[i], arr2[i]]);
}

export const toArrayMap = <T, K>(values: T[], getKey: (v: T) => K): Map<K, T[]> => {
    const map = new Map<K, T[]>();
    for (const value of values) {
        const key = getKey(value);
        const array = map.get(key) ?? [];
        array.push(value);
        map.set(key, array);
    }
    return map;
};
