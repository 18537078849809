import React from "react";

import { Controller, Control } from "react-hook-form";

import { TagCheckboxesFormType } from "..";
import { InterviewReportAxis } from "../../../../../../../store/hooks/interviewReport";
import { AmeCheckbox } from "../../../../../../atoms/checkbox/AmeCheckbox";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface Props {
  name: InterviewReportAxis;
  label: string;
  control: Control<TagCheckboxesFormType>;
}

export const RetirementVoiceListHeaderCheckbox: React.FC<Props> = ({ control, name, label }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <AmeBox {...field} sx={{ display: "flex", gap: "4px" }} onClick={() => field.onChange(!field.value)}>
          <AmeCheckbox checked={field.value} />
          <AmeTypography>{`#${label}`}</AmeTypography>
        </AmeBox>
      )}
    />
  );
};
