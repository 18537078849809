import React, { useContext } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { AmeCheckbox } from "../../../../atoms/checkbox/AmeCheckbox";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";

const LocalSettings: React.FC = () => {
  const {
    localStorage: {
      isWarning: { value: isWarning, setValue: setIsWarning },
    },
  } = useContext(DataSourceContext);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[{ title: "設定", to: "/settings" }]} currentTitle={"ローカル設定"} />
      <PageLayoutBody>
        ヘッダーを赤くする
        <AmeCheckbox
          checked={isWarning}
          onChange={(e) => {
            setIsWarning(e.target.checked);
          }}
        />
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/local-settings")({
  component: LocalSettings,
});
