import React, { ChangeEvent, useCallback, useState } from "react";

import { ComputationTaskStatus } from "../../../../../store/hooks/computationTasks";
import { EditableCell } from "../../../../atoms/table/EditableCell";
import { TaskStatusIcon } from "../../../../molecules/TaskStatusIcon";

interface Props {
  id: string;
  update: (args: { id: string; displayName: string }) => void;
  displayName: string;
  computationTaskStatus: ComputationTaskStatus | null;
}

export const EditableCategoryCell: React.FC<Props> = ({ id, displayName, update, computationTaskStatus }) => {
  const [categoryName, setCategoryName] = useState<string>(displayName);

  const onConfirm = useCallback(() => {
    if (displayName !== categoryName) {
      update({ id, displayName: categoryName });
    }
  }, [displayName, categoryName, update, id]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCategoryName(e.target.value);
    },
    [setCategoryName],
  );

  return (
    <EditableCell
      value={categoryName}
      onChange={onChange}
      onBlur={onConfirm}
      endIcon={
        <TaskStatusIcon
          taskStatus={computationTaskStatus}
          DefaultIcon={(props) => <TaskStatusIcon size={props.size} taskStatus={ComputationTaskStatus.SUCCESSFUL} />}
        />
      }
    />
  );
};
