/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputSegmentKey,
    InputSegmentKeyFromJSON,
    InputSegmentKeyFromJSONTyped,
    InputSegmentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddEmployeeAttributesBodyAttributes
 */
export interface AddEmployeeAttributesBodyAttributes {
    /**
     * 
     * @type {string}
     * @memberof AddEmployeeAttributesBodyAttributes
     */
    attributeDefinitionId: string;
    /**
     * 
     * @type {InputSegmentKey}
     * @memberof AddEmployeeAttributesBodyAttributes
     */
    attributeKey: InputSegmentKey;
}

export function AddEmployeeAttributesBodyAttributesFromJSON(json: any): AddEmployeeAttributesBodyAttributes {
    return AddEmployeeAttributesBodyAttributesFromJSONTyped(json, false);
}

export function AddEmployeeAttributesBodyAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddEmployeeAttributesBodyAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeDefinitionId': json['attributeDefinitionId'],
        'attributeKey': InputSegmentKeyFromJSON(json['attributeKey']),
    };
}

export function AddEmployeeAttributesBodyAttributesToJSON(value?: AddEmployeeAttributesBodyAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeDefinitionId': value.attributeDefinitionId,
        'attributeKey': InputSegmentKeyToJSON(value.attributeKey),
    };
}


