import React, { useState } from "react";

import { requireNonNull } from "@amecloud/common-utils/src/utils/Assertions";
import { Navigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { SurveyModel } from "../../../../models/Survey";
import { SurveySendingMethod, isSurveySendingMethod } from "../../../../models/constants/values";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { AmeFormset } from "../../../atoms/forms/AmeFormset";
import { AmeDateInput } from "../../../atoms/forms/input/AmeDateInput";
import { AmeInput } from "../../../atoms/forms/input/AmeInput";
import { AmeTextarea } from "../../../atoms/forms/textarea/AmeTextarea";
import { PageLayoutBody } from "../../../atoms/layout/PageLayoutBody";
import { PaperBody } from "../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeFormFollowingButtonsLayout } from "../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import {
  PreviewModalQuestion,
  SurveyQuestionsPreviewModal,
} from "../../settings/surveys/questions/SurveyQuestionsPreviewModal";

import { SurveySendingMethodSelector } from "./SurveySendingMethodSelector";

interface Props {
  survey?: SurveyModel;
  method: SurveySendingMethod;
  questions: PreviewModalQuestion[];
  onClickCancel: () => void;
  handleSubmit: (
    method: SurveySendingMethod,
    title: string,
    description: string,
    emailSubject: string,
    emailBody: string,
    dueDateTime: Date,
  ) => Promise<void>;
}

export const SurveyForm: React.FC<Props> = (props) => {
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const [dueDate, setDueDate] = useState<Date | undefined>(props.survey?.dueDateTime);
  const [surveyTitle, setSurveyTitle] = useState(props.survey?.surveyTitle ?? "");
  const [surveyDescription, setSurveyDescription] = useState(props.survey?.surveyDescription ?? "");
  const [mailTitle, setMailTitle] = useState(props.survey?.emailSubject ?? "");
  const [mailText, setMailText] = useState(props.survey?.emailBody ?? "");
  const [open, setOpen] = useState(false);

  if (!isSurveySendingMethod(props.method)) {
    enqueueErrorSnackbar({ title: "不明な送付方法です。", message: "CSにご確認ください。" });
    return <Navigate to={"/surveys"} search={{ page: 1 }} />;
  }

  return (
    <>
      <PageLayoutBody type="wide">
        <SurveyQuestionsPreviewModal
          title={surveyTitle}
          description={surveyDescription}
          previewIsOpen={open}
          onClosePreview={() => setOpen(false)}
          questions={props.questions}
        />
        <AmeBox sx={{ display: "flex", justifyContent: "center" }}>
          <AmeBox sx={{ maxWidth: 750, width: "100%", "> * + *": { marginTop: "24px" } }}>
            <PaperWrapper>
              <PaperHeader>基本設定</PaperHeader>
              <PaperBody>
                <AmeFormset label="アンケート送付方法">
                  <SurveySendingMethodSelector sendingMethod={props.method} />
                </AmeFormset>
                <Spacer height="16px" />
                <AmeFormset
                  label={"期日設定"}
                  required
                  errorMessage={
                    dueDate && dueDate.getTime() < Date.now() ? "本日より先の日付を選択してください。" : undefined
                  }
                >
                  <AmeDateInput onCommitDate={(newDate) => setDueDate(newDate ?? undefined)} value={dueDate} />
                </AmeFormset>
              </PaperBody>
            </PaperWrapper>
            <PaperWrapper>
              <PaperHeader>アンケート内容</PaperHeader>
              <PaperBody>
                <AmeFormset label={"タイトル"} required>
                  <AmeInput
                    placeholder={"組織課題アンケート"}
                    value={surveyTitle}
                    onChange={(e) => {
                      setSurveyTitle(e.target.value);
                    }}
                  />
                </AmeFormset>
                <Spacer height="16px" />
                <AmeFormset label={"説明"} required>
                  <AmeTextarea
                    fullWidth
                    value={surveyDescription}
                    onChange={(e) => {
                      setSurveyDescription(e.target.value);
                    }}
                  />
                </AmeFormset>
              </PaperBody>
            </PaperWrapper>
            {props.method !== "qr-code" ? (
              <PaperWrapper>
                <PaperHeader>メール内容</PaperHeader>
                <PaperBody>
                  <AmeFormset label={"件名"} required>
                    <AmeInput
                      placeholder={"組織課題アンケート"}
                      value={mailTitle}
                      onChange={(e) => {
                        setMailTitle(e.target.value);
                      }}
                    />
                  </AmeFormset>
                  <Spacer height="16px" />
                  <AmeFormset label={"本文"} required>
                    <AmeTextarea
                      fullWidth
                      value={mailText}
                      onChange={(e) => {
                        setMailText(e.target.value);
                      }}
                    />
                  </AmeFormset>
                </PaperBody>
              </PaperWrapper>
            ) : null}
          </AmeBox>
        </AmeBox>
      </PageLayoutBody>
      <AmeFormFollowingButtonsLayout
        primaryDisabled={
          !isAbleToStoreSurvey(props.method, surveyTitle, surveyDescription, mailTitle, mailText, dueDate)
        }
        onPrimary={() =>
          props.handleSubmit(props.method, surveyTitle, surveyDescription, mailTitle, mailText, requireNonNull(dueDate))
        }
        onSecondary={props.onClickCancel}
        extraButtons={
          <AmeButton variant="outlined" onClick={() => setOpen(true)}>
            プレビュー
          </AmeButton>
        }
      />
    </>
  );
};

const isAbleToStoreSurvey = (
  sendingMethod: SurveySendingMethod,
  surveyTitle: string,
  surveyDescription,
  mailTitle: string,
  mailText: string,
  dueDate: Date | undefined,
) => {
  if (sendingMethod === "qr-code") {
    return surveyTitle !== "" && surveyDescription !== "" && dueDate !== undefined;
  }
  return surveyTitle !== "" && surveyDescription !== "" && mailTitle !== "" && mailText !== "" && dueDate !== undefined;
};
