/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSubCategoryRequestBody
 */
export interface UpdateSubCategoryRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateSubCategoryRequestBody
     */
    subCategoryDefinition?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSubCategoryRequestBody
     */
    displayName?: string;
}

export function UpdateSubCategoryRequestBodyFromJSON(json: any): UpdateSubCategoryRequestBody {
    return UpdateSubCategoryRequestBodyFromJSONTyped(json, false);
}

export function UpdateSubCategoryRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSubCategoryRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subCategoryDefinition': !exists(json, 'subCategoryDefinition') ? undefined : json['subCategoryDefinition'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
    };
}

export function UpdateSubCategoryRequestBodyToJSON(value?: UpdateSubCategoryRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subCategoryDefinition': value.subCategoryDefinition,
        'displayName': value.displayName,
    };
}


