import React, { useState } from "react";

import { SurveyStatus } from "@amecloud/api-client/src-openapi-autogen";

import { SurveyModel } from "../../../../../models/Survey";

import { AfterFixedSurveyDetailsHeader } from "./AfterFixedSurveyDetailsHeader";
import { DraftSurveyDetailsHeader } from "./DraftSurveyDetailsHeader";

interface Props {
  emailSignature?: string;
  survey: SurveyModel;
}

export const SurveyDetailsHeader: React.FC<Props> = (props) => {
  // QRコードを回答方法として使用する場合に、アンケート確定後にQRコードのモーダルを表示したいので、このレイヤーに定義している。
  const [qrCodeModalIsOpen, setQrCodeModalIsOpen] = useState<boolean>(false);

  return props.survey.status === SurveyStatus.DRAFT ? (
    <DraftSurveyDetailsHeader
      survey={props.survey}
      emailSignature={props.emailSignature}
      setQrCodeModalIsOpen={setQrCodeModalIsOpen}
    />
  ) : (
    <AfterFixedSurveyDetailsHeader
      survey={props.survey}
      emailSignature={props.emailSignature}
      qrCodeModalIsOpen={qrCodeModalIsOpen}
      setQrCodeModalIsOpen={setQrCodeModalIsOpen}
    />
  );
};
