import React from "react";

import { UserGroup } from "../../../../../../../models/UserGroup";
import { QueryState } from "../../../../../../../store/hooks/utils/parseResult";
import { ChoosableList } from "../../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../../atoms/list/ChoosableListItem";
import { BaseWrapper } from "../../../../../../atoms/wrapper/BaseWrapper";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface Props {
  groupId: string;
  useGetGroup: (userGroupId: string) => QueryState<UserGroup>;
}

export const MemberList: React.FC<Props> = ({ useGetGroup, groupId }) => {
  const groupState = useGetGroup(groupId);

  return (
    <WaitForSuccess queryState={groupState}>
      {(group) => (
        <>
          {group?.users.length ? (
            <BaseWrapper>
              <ChoosableList>
                {group?.users.map((user) => (
                  <ChoosableListItem key={user.userId}>
                    <AmeBox
                      sx={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <span>{user.getFullName()}</span>
                    </AmeBox>
                  </ChoosableListItem>
                ))}
              </ChoosableList>
            </BaseWrapper>
          ) : (
            <BaseWrapper>
              <AmeBox sx={{ padding: "16px" }}>このグループにユーザーは含まれていません。</AmeBox>
            </BaseWrapper>
          )}
        </>
      )}
    </WaitForSuccess>
  );
};
