import React, { useMemo } from "react";

import { removeUndefinedDeep } from "@amecloud/common-utils/src/utils/ObjectUtils";
import { ApexOptions } from "apexcharts";

import { formatInteger, formatToPercent } from "../../../utils/formatNumber";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";

import { AmeChart } from "./AmeChart";

// それぞれのseriesとSome / All のratioを表示するグラフ
interface Props {
  seriesAll: number[];
  seriesSome: number[];
  seriesLabels: string[];
  ratioFormatter?: (value: number | null) => string;
  valueFormatter?: (value: number | null) => string;
  names: string[];
  height?: number;
  width?: number;
  columnMax?: number;
  ratioMax?: number;
}

export const ColumnsWithRatioChart: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const columnSeries = useMemo(() => {
    if (props.seriesAll.length !== props.seriesSome.length || props.seriesAll.length !== props.seriesLabels.length) {
      throw new Error("seriesAll, seriesSome, seriesLabels should have the same length");
    }
    if (props.names.length < 3) {
      throw new Error("names should have at least 3 elements");
    }
    return [
      {
        name: props.names[0],
        type: "column",
        data: props.seriesAll,
        color: theme.brand.secondary[40] ?? "#B2EBF2",
      },
      {
        name: props.names[1],
        type: "column",
        data: props.seriesSome,
        color: theme.brand.secondary[100] ?? "#00ACC1",
      },
      {
        name: props.names[2],
        type: "line",
        data: props.seriesSome.map((s, index) => s / props.seriesAll[index]),
        color: theme.brand.secondary[110] ?? "#ADC2C5",
      },
    ];
  }, [props.seriesAll, props.seriesSome, props.names, props.seriesLabels, theme]);

  const options: ApexOptions = useMemo(() => {
    const formatValue = (value: number | null) => {
      if (props.valueFormatter) {
        return props.valueFormatter(value);
      }
      return formatInteger(value);
    };
    const formatRatio = (value: number | null) => {
      if (props.ratioFormatter) {
        return props.ratioFormatter(value);
      }
      return formatToPercent(value);
    };
    const opt: ApexOptions = {
      chart: {
        zoom: {
          type: "xy",
          enabled: false,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        // X軸系列が1つしかない場合、謎の縦線が表示されるので、strokeを非表示にする
        width: [0, 0, columnSeries[0]?.data.length > 1 ? 3 : 0],
      },
      markers: {
        size: 5,
        colors: theme.common.text.white,
        strokeColors: theme.brand.secondary[110],
      },
      labels: props.names,
      yaxis: [
        {
          max: props.columnMax,
          min: 0,
          seriesName: props.names[0],
          labels: {
            formatter: formatValue,
          },
        },
        {
          max: props.columnMax,
          min: 0,
          seriesName: props.names[0],
          // 2つ目は1つ目とy軸を共有する
          show: false,
          labels: {
            formatter: formatValue,
          },
        },
        {
          seriesName: props.names[2],
          max: props.ratioMax,
          min: 0,
          labels: {
            formatter: formatRatio,
            align: "center",
          },
          opposite: true,
        },
      ],
      xaxis: {
        categories: props.seriesLabels,
        axisTicks: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    };
    return removeUndefinedDeep(opt);
  }, [theme.common.text.white, theme.brand.secondary, props, columnSeries]);
  return <AmeChart height={props.height} width={props.width} options={options} columnSeries={columnSeries} />;
};
