/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HrInterviewCountReportResponse,
    HrInterviewCountReportResponseFromJSON,
    HrInterviewCountReportResponseFromJSONTyped,
    HrInterviewCountReportResponseToJSON,
    HrInterviewScoreReportResponse,
    HrInterviewScoreReportResponseFromJSON,
    HrInterviewScoreReportResponseFromJSONTyped,
    HrInterviewScoreReportResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewQualityReportResponse
 */
export interface HrInterviewQualityReportResponse {
    /**
     * 
     * @type {HrInterviewScoreReportResponse}
     * @memberof HrInterviewQualityReportResponse
     */
    scoreReport: HrInterviewScoreReportResponse;
    /**
     * 
     * @type {HrInterviewCountReportResponse}
     * @memberof HrInterviewQualityReportResponse
     */
    rateReport: HrInterviewCountReportResponse;
}

export function HrInterviewQualityReportResponseFromJSON(json: any): HrInterviewQualityReportResponse {
    return HrInterviewQualityReportResponseFromJSONTyped(json, false);
}

export function HrInterviewQualityReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewQualityReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scoreReport': HrInterviewScoreReportResponseFromJSON(json['scoreReport']),
        'rateReport': HrInterviewCountReportResponseFromJSON(json['rateReport']),
    };
}

export function HrInterviewQualityReportResponseToJSON(value?: HrInterviewQualityReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scoreReport': HrInterviewScoreReportResponseToJSON(value.scoreReport),
        'rateReport': HrInterviewCountReportResponseToJSON(value.rateReport),
    };
}


