import React from "react";

import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { RuleBuildTarget } from "../RuleRegisterContext";

import { RegisterHeader } from "./RegisterHeader";
import { RuleBuilderContextProvider } from "./RuleBuilderContext";
import { RuleSuggestion } from "./RuleSuggestion";

interface Props {
  categoryId: string;
  subCategoryId?: string;
  displayName: string;
  setRuleBuildTarget: (categoryId: RuleBuildTarget | undefined) => void;
}

export const RuleRegister: React.FC<Props> = ({ categoryId, subCategoryId, displayName, setRuleBuildTarget }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      component="aside"
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: theme.zIndex.sidebar,
        backgroundColor: theme.common.background.white,
        padding: "16px",
        width: 900,
      }}
    >
      <AmeBox sx={{ maxWidth: "95%", padding: "16px 0" }}>
        <AmeTypography component="h3" omit>
          ルール作成：{displayName}
        </AmeTypography>
      </AmeBox>
      <RuleBuilderContextProvider>
        <AmeBox
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <RegisterHeader subCategoryId={subCategoryId} categoryId={categoryId} />
          <RuleSuggestion subCategoryId={subCategoryId} categoryId={categoryId} />
        </AmeBox>
      </RuleBuilderContextProvider>
      <AmeBox sx={{ position: "absolute", right: 0, top: 0, margin: "16px 16px 0 0" }}>
        <AmeButton variant="text" onClick={() => setRuleBuildTarget(undefined)}>
          閉じる
        </AmeButton>
      </AmeBox>
    </AmeBox>
  );
};
