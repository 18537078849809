import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { User } from "../../models/User";

import { UseChoiceGroupParams } from "./type";

export const useInterviewerChoiceGroup = (
  interviewers: User[],
  { filterParams, navigate }: UseChoiceGroupParams<{ interviewerIds: string[] }>,
): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    return {
      groupName: "面談者",
      uiType: "flat",
      choiceOptions: interviewers
        .filter(
          (
            interviewer,
          ): interviewer is User & {
            userId: string;
          } => interviewer.userId !== undefined,
        )
        .map((interviewer) => ({
          name: interviewer.getFullName(),
          toggleOption: async () =>
            await navigate({
              search: (prev: { interviewerIds: string[] }) => {
                const interviewerIdSet = new Set(prev.interviewerIds);
                if (!interviewerIdSet.delete(interviewer.userId!)) {
                  interviewerIdSet.add(interviewer.userId!);
                }
                return { ...prev, interviewerIds: Array.from(interviewerIdSet) };
              },
            }),
          isChecked: filterParams.interviewerIds.includes(interviewer.userId),
        })),
    };
  }, [filterParams, interviewers, navigate]);
};
