import React, { useCallback, useContext } from "react";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { RuleBuilderContext } from "../RuleBuilderContext";

import { SuggestionChipButton } from "./SuggestionChipButton";

interface Props {
  esQuery: string;
  matchedDocumentCount: number;
}

export const SuggestionChip: React.FC<Props> = ({ esQuery, matchedDocumentCount }) => {
  const theme = useAmeTheme();
  // field: value、fieldとvalueに分離する。
  const splitEsQuery = esQuery.split(":");
  const field = splitEsQuery[0];
  const value = splitEsQuery.slice(1).join(":");

  const { addRule } = useContext(RuleBuilderContext);

  const onClick = useCallback(
    (not: boolean) => {
      addRule({ field, value, not });
    },
    [addRule, field, value],
  );

  return (
    <AmeBox
      sx={{
        height: "32px",
        border: `1px solid ${theme.common.border.gray40}`,
        borderRadius: "100px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <AmeBox sx={{ display: "inline-flex", padding: "6px 0 6px 8px" }}>
        <AmeBox sx={{ ...theme.typography.body2, marginLeft: "4px", fontWeight: "bold" }}>{value}</AmeBox>
        <AmeBox
          sx={{ ...theme.typography.body2, marginLeft: "4px", fontWeight: "bold", color: theme.common.text.gray40 }}
        >
          {matchedDocumentCount.toLocaleString()}件
        </AmeBox>
      </AmeBox>
      <AmeBox sx={{ display: "inline-flex", height: "100%" }}>
        <SuggestionChipButton onClick={() => onClick(false)} label="+" />
        <SuggestionChipButton onClick={() => onClick(true)} label="−" />
      </AmeBox>
    </AmeBox>
  );
};
