import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const ExcellentFace: React.FC<Omit<SvgIconProps, "color">> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_10169_203792)">
        <path
          d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
          fill="#34C759"
        />
        <path
          d="M4.28502 9.567C4.39986 9.5007 4.53633 9.48273 4.66442 9.51705C4.79251 9.55137 4.90171 9.63516 4.96802 9.75C5.27513 10.2823 5.71707 10.7244 6.24935 11.0316C6.78163 11.3388 7.38545 11.5004 8.00002 11.5C8.61459 11.5004 9.21842 11.3388 9.75069 11.0316C10.283 10.7244 10.7249 10.2823 11.032 9.75C11.0646 9.69268 11.1082 9.64237 11.1603 9.60197C11.2124 9.56156 11.272 9.53187 11.3357 9.5146C11.3993 9.49732 11.4657 9.4928 11.5311 9.5013C11.5965 9.5098 11.6596 9.53115 11.7167 9.56412C11.7738 9.59709 11.8238 9.64103 11.8639 9.69341C11.9039 9.74579 11.9332 9.80558 11.9501 9.86933C11.9669 9.93308 11.971 9.99953 11.9621 10.0649C11.9531 10.1302 11.9314 10.1931 11.898 10.25C11.5031 10.9344 10.935 11.5026 10.2507 11.8976C9.56639 12.2926 8.79013 12.5004 8.00002 12.5C7.20991 12.5004 6.43365 12.2926 5.74936 11.8976C5.06507 11.5026 4.4969 10.9344 4.10202 10.25C4.03572 10.1352 4.01775 9.99869 4.05207 9.8706C4.08639 9.74251 4.17018 9.63331 4.28502 9.567ZM7.00002 6.5C7.00002 7.328 6.55202 8 6.00002 8C5.44802 8 5.00002 7.328 5.00002 6.5C5.00002 5.672 5.44802 5 6.00002 5C6.55202 5 7.00002 5.672 7.00002 6.5ZM11 6.5C11 7.328 10.552 8 10 8C9.44802 8 9.00002 7.328 9.00002 6.5C9.00002 5.672 9.44802 5 10 5C10.552 5 11 5.672 11 6.5Z"
          fill="#34C759"
        />
      </g>
      <defs>
        <clipPath id="clip0_10169_203792">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
