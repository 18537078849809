/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExcludeTranscriptionStatusChecked,
    ExcludeTranscriptionStatusCheckedFromJSON,
    ExcludeTranscriptionStatusCheckedFromJSONTyped,
    ExcludeTranscriptionStatusCheckedToJSON,
    TranscriptionResult,
    TranscriptionResultFromJSON,
    TranscriptionResultFromJSONTyped,
    TranscriptionResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateTranscriptionRequestBody
 */
export interface UpdateTranscriptionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateTranscriptionRequestBody
     */
    text?: string;
    /**
     * 
     * @type {ExcludeTranscriptionStatusChecked}
     * @memberof UpdateTranscriptionRequestBody
     */
    status?: ExcludeTranscriptionStatusChecked;
    /**
     * 
     * @type {TranscriptionResult}
     * @memberof UpdateTranscriptionRequestBody
     */
    results?: TranscriptionResult;
    /**
     * 
     * @type {string}
     * @memberof UpdateTranscriptionRequestBody
     */
    retiredSpeakerLabel?: string;
}

export function UpdateTranscriptionRequestBodyFromJSON(json: any): UpdateTranscriptionRequestBody {
    return UpdateTranscriptionRequestBodyFromJSONTyped(json, false);
}

export function UpdateTranscriptionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTranscriptionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'status': !exists(json, 'status') ? undefined : ExcludeTranscriptionStatusCheckedFromJSON(json['status']),
        'results': !exists(json, 'results') ? undefined : TranscriptionResultFromJSON(json['results']),
        'retiredSpeakerLabel': !exists(json, 'retiredSpeakerLabel') ? undefined : json['retiredSpeakerLabel'],
    };
}

export function UpdateTranscriptionRequestBodyToJSON(value?: UpdateTranscriptionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'status': ExcludeTranscriptionStatusCheckedToJSON(value.status),
        'results': TranscriptionResultToJSON(value.results),
        'retiredSpeakerLabel': value.retiredSpeakerLabel,
    };
}


