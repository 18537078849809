import React from "react";

import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

export const AmeCommonListItem: React.FC<Props> = ({ children, onClick }) => {
  return (
    <AmeBox
      sx={{
        "& > *": { flexGrow: 0, flexShrink: 0 },
        width: "100%",
        padding: "8.5px 14px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "8px",
      }}
      onClick={onClick}
    >
      {children}
    </AmeBox>
  );
};
