import React from "react";

import { FilterGroup } from "../FilterGroup";

import { ChoiceOption } from "./ChoiceOption";
import { ChoiceSubGroup } from "./ChoiceSubGroup";
import { ChoiceGroupProps } from "./type";

export const ChoiceGroup: React.FC<ChoiceGroupProps> = (props) => {
  if (props.uiType === "nested") {
    return (
      <FilterGroup groupName={props.groupName}>
        {props.choiceSubGroups.map((subGroup, index) => {
          return <ChoiceSubGroup key={index} subGroupName={subGroup.subGroupName} options={subGroup.choiceOptions} />;
        })}
      </FilterGroup>
    );
  } else {
    return (
      <FilterGroup groupName={props.groupName}>
        {props.choiceOptions.map((option, index) => {
          return (
            <ChoiceOption
              key={index}
              name={option.name}
              isChecked={option.isChecked}
              toggleOption={option.toggleOption}
            />
          );
        })}
      </FilterGroup>
    );
  }
};
