/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditExEmployeeInterviewRequestBody
 */
export interface EditExEmployeeInterviewRequestBody {
    /**
     * 
     * @type {string}
     * @memberof EditExEmployeeInterviewRequestBody
     */
    recordingId?: string;
    /**
     * 
     * @type {string}
     * @memberof EditExEmployeeInterviewRequestBody
     */
    answer?: string;
}

export function EditExEmployeeInterviewRequestBodyFromJSON(json: any): EditExEmployeeInterviewRequestBody {
    return EditExEmployeeInterviewRequestBodyFromJSONTyped(json, false);
}

export function EditExEmployeeInterviewRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditExEmployeeInterviewRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordingId': !exists(json, 'recordingId') ? undefined : json['recordingId'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
    };
}

export function EditExEmployeeInterviewRequestBodyToJSON(value?: EditExEmployeeInterviewRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordingId': value.recordingId,
        'answer': value.answer,
    };
}


