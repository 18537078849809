/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddEmployeeAttributesBodyAttributes,
    AddEmployeeAttributesBodyAttributesFromJSON,
    AddEmployeeAttributesBodyAttributesFromJSONTyped,
    AddEmployeeAttributesBodyAttributesToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddEmployeeAttributesBody
 */
export interface AddEmployeeAttributesBody {
    /**
     * 
     * @type {Array<AddEmployeeAttributesBodyAttributes>}
     * @memberof AddEmployeeAttributesBody
     */
    attributes: Array<AddEmployeeAttributesBodyAttributes>;
    /**
     * 
     * @type {Date}
     * @memberof AddEmployeeAttributesBody
     */
    at: Date;
}

export function AddEmployeeAttributesBodyFromJSON(json: any): AddEmployeeAttributesBody {
    return AddEmployeeAttributesBodyFromJSONTyped(json, false);
}

export function AddEmployeeAttributesBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddEmployeeAttributesBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AddEmployeeAttributesBodyAttributesFromJSON)),
        'at': (new Date(json['at'])),
    };
}

export function AddEmployeeAttributesBodyToJSON(value?: AddEmployeeAttributesBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(AddEmployeeAttributesBodyAttributesToJSON)),
        'at': (value.at.toISOString()),
    };
}


