import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const CheckboxChecked: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16" size={16}>
      <rect width="16" height="16" fill="white" />
      <rect width="16" height="16" rx="4" fill="#00ACC1" />
      <path
        d="M11.851 4.48912C12.0065 4.33405 12.2135 4.24832 12.4282 4.25002C12.643 4.25173 12.8487 4.34072 13.0019 4.49824C13.1552 4.65575 13.244 4.86946 13.2497 5.09426C13.2554 5.31907 13.1774 5.5374 13.0323 5.70317L8.62721 11.4728C8.55146 11.5583 8.46004 11.6269 8.35841 11.6745C8.25678 11.722 8.14703 11.7477 8.03572 11.7499C7.92441 11.752 7.81383 11.7306 7.71059 11.687C7.60734 11.6434 7.51356 11.5784 7.43484 11.496L4.51354 8.43654C4.43219 8.35715 4.36694 8.26141 4.32168 8.15504C4.27643 8.04866 4.25209 7.93383 4.25013 7.8174C4.24817 7.70096 4.26862 7.5853 4.31027 7.47732C4.35191 7.36934 4.4139 7.27125 4.49253 7.18891C4.57115 7.10656 4.66481 7.04164 4.76792 6.99803C4.87102 6.95441 4.98146 6.93299 5.09264 6.93505C5.20382 6.9371 5.31347 6.96259 5.41504 7.00998C5.51661 7.05738 5.60802 7.12572 5.68383 7.21092L7.9957 9.63094L11.83 4.51455C11.8369 4.50565 11.8432 4.49716 11.851 4.48912Z"
        fill="white"
      />
    </SvgWrapper>
  );
};
