import React from "react";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { CenterDotIcon } from "../../../atoms/icon/CenterDot";
import { DefinitionListItem } from "../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../atoms/paper/PaperBody";
import { PaperWrapper } from "../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

export interface TenureEntry {
  startDate: Date;
  endDate?: Date;
  tenureDetails: {
    effectiveFrom: string;
    department: string;
    free1: string;
    free2: string;
    gender: string;
  };
}

interface EmployeeTenureTableProps {
  tenures: TenureEntry[];
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
  onAddAttribute: (index: number) => void;
}

export const EmployeeTenureTable: React.FC<EmployeeTenureTableProps> = ({
  tenures,
  onDelete,
  onEdit,
  onAddAttribute,
}) => {
  const theme = useAmeTheme();

  return (
    <>
      {tenures.map((tenure, index) => (
        <React.Fragment key={index}>
          <PaperWrapper>
            <PaperBody>
              <AmeBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <AmeBox sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <AmeBox sx={{ color: theme.brand.secondary[100] }}>
                    <CenterDotIcon size={13} color="inherit" sx={{ position: "relative", top: "-1px" }} />
                  </AmeBox>
                  {/* <AmeTypography component="div">{tenure.startDate}入社</AmeTypography> */}
                </AmeBox>
                <AmeBox sx={{ display: "flex", gap: "10px" }}>
                  <AmeButton variant="outlined" color="basic" size="small" onClick={() => onDelete(index)}>
                    削除
                  </AmeButton>
                  <AmeButton variant="outlined" color="basic" size="small" onClick={() => onEdit(index)}>
                    入社日と退社日の編集
                  </AmeButton>
                  <AmeButton variant="outlined" color="basic" size="small" onClick={() => onAddAttribute(index)}>
                    属性定義の追加
                  </AmeButton>
                </AmeBox>
              </AmeBox>

              <Spacer height="14px" />

              <AmeBox sx={{ display: "flex", alignItems: "stretch", gap: "10px", width: "100%" }}>
                <AmeBox
                  sx={{
                    width: "30px",
                    flexShrink: 0,
                    borderLeft: `1px solid ${theme.common.border.gray40}`,
                    marginLeft: "6px",
                  }}
                />
                <AmeBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    border: `1px solid ${theme.common.border.gray40}`,
                    padding: "8px 24px",
                  }}
                >
                  <AmeBox
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      flexShrink: 1,
                      minWidth: 0,
                      overflow: "hidden",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <AmeTypography>{tenure.tenureDetails.effectiveFrom}~</AmeTypography>
                    <DefinitionListItem label={"部門"} value={tenure.tenureDetails.department} />
                    <DefinitionListItem label={"フリー1"} value={tenure.tenureDetails.free1} />
                    <DefinitionListItem label={"フリー2"} value={tenure.tenureDetails.free2} />
                    <DefinitionListItem label={"性別"} value={tenure.tenureDetails.gender} />
                  </AmeBox>
                  <AmeBox
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexShrink: 0,
                      marginLeft: "16px",
                    }}
                  >
                    <AmeButton variant="outlined" color="basic" size="small" onClick={() => onDelete(index)}>
                      削除
                    </AmeButton>
                    <AmeButton variant="outlined" color="basic" size="small" onClick={() => onEdit(index)}>
                      編集
                    </AmeButton>
                  </AmeBox>
                </AmeBox>
              </AmeBox>

              {tenure.endDate && (
                <>
                  <Spacer height="14px" />
                  <AmeBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <AmeBox sx={{ color: theme.brand.secondary[100] }}>
                      <CenterDotIcon size={13} color="inherit" sx={{ position: "relative", top: "-1px" }} />
                    </AmeBox>
                    {/* <AmeTypography>{tenure.endDate}退社</AmeTypography> */}
                  </AmeBox>
                </>
              )}
            </PaperBody>
          </PaperWrapper>

          {index < tenures.length - 1 && <Spacer height="24px" />}
        </React.Fragment>
      ))}
    </>
  );
};
