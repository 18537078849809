import { UserDetail, UserList, UserRef } from "../../models/User";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useGetUserListQuery = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getUsers.useQuery(undefined, {
    pollingInterval: options?.pollingInterval,
    skip: options?.skip,
  });

  return useQueryResult({
    result,
    fromResponse: UserList.fromResponse,
    onError: options?.onError,
  });
};

export const useCreateUserMutation = (options?: UseMutationOptions) => {
  const [createUser] = defaultApi.endpoints.createUser.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createUser,
    onError: options?.onError,
  });
};

export const useGetUserQuery = (userId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getUser.useQuery(
    { userId },
    {
      pollingInterval: options?.pollingInterval,
      skip: options?.skip,
    },
  );

  return useQueryResult({
    result,
    fromResponse: UserDetail.fromResponse,
    onError: options?.onError,
  });
};

export const useUpdateUserMutation = (options?: UseMutationOptions) => {
  const [updateUser] = defaultApi.endpoints.updateUser.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateUser,
    onError: options?.onError,
  });
};

export const useGetMyselfQuery = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getMyself.useQuery(undefined, { pollingInterval: options?.pollingInterval });
  return useQueryResult({
    result,
    fromResponse: UserRef.fromResponse,
    onError: options?.onError,
  });
};

export const useResendInvitation = (options?: UseMutationOptions) => {
  const [resendInvitation] = defaultApi.endpoints.resendInvitation.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: resendInvitation,
    onError: options?.onError,
  });
};
export const useDeleteUserMutation = (options?: UseMutationOptions) => {
  const [deleteUser] = defaultApi.endpoints.deleteUser.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteUser,
    onError: options?.onError,
  });
};
