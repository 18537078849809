/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewDetailResponseInterviewHighlights
 */
export interface InterviewDetailResponseInterviewHighlights {
    /**
     * 結び付けられたサブカテゴリID
     * @type {Array<string>}
     * @memberof InterviewDetailResponseInterviewHighlights
     */
    subCategoryIds: Array<string>;
    /**
     * ハイライトされたセンテンス
     * @type {string}
     * @memberof InterviewDetailResponseInterviewHighlights
     */
    content: string;
    /**
     * 長さ
     * @type {number}
     * @memberof InterviewDetailResponseInterviewHighlights
     */
    length: number;
    /**
     * 本文中での開始位置
     * @type {number}
     * @memberof InterviewDetailResponseInterviewHighlights
     */
    offset: number;
}

export function InterviewDetailResponseInterviewHighlightsFromJSON(json: any): InterviewDetailResponseInterviewHighlights {
    return InterviewDetailResponseInterviewHighlightsFromJSONTyped(json, false);
}

export function InterviewDetailResponseInterviewHighlightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewDetailResponseInterviewHighlights {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subCategoryIds': json['subCategoryIds'],
        'content': json['content'],
        'length': json['length'],
        'offset': json['offset'],
    };
}

export function InterviewDetailResponseInterviewHighlightsToJSON(value?: InterviewDetailResponseInterviewHighlights | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subCategoryIds': value.subCategoryIds,
        'content': value.content,
        'length': value.length,
        'offset': value.offset,
    };
}


