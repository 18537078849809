import React from "react";

import { AmeBox } from "../../muiWrapper/AmeBox";
interface Props {
  children: React.ReactNode;
  type?: "basic" | "wide";
}

export const PageLayoutBody: React.FC<Props> = ({ children, type = "basic" }) => {
  return (
    <AmeBox sx={{ padding: "24px", height: "calc(100% - 48px)" }}>
      <AmeBox sx={{ margin: "auto", maxWidth: type === "basic" ? "720px" : undefined, height: "100%" }}>
        {children}
      </AmeBox>
    </AmeBox>
  );
};
