import React, { useState } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { useEditHrInterview, useGetHrInterview } from "../../../../../../../store/hooks/hrInterview";
import { AmeFormset } from "../../../../../../atoms/forms/AmeFormset";
import { AmeTextarea } from "../../../../../../atoms/forms/textarea/AmeTextarea";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../atoms/paper/PaperWrapper";
import { AmeRadioGroup } from "../../../../../../atoms/radio/AmeRadioGroup";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeFormFollowingButtonsLayout } from "../../../../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";

const FollowupPage: React.FC = () => {
  const { interviewId } = Route.useParams<{ interviewId: string }>();
  const interviewDetailQueryState = useGetHrInterview(interviewId);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "在職者面談一覧", to: "/hr-interviews/interviews" },
          { title: "在職者面談詳細", to: "/hr-interviews/interviews/$interviewId", params: { interviewId } },
        ]}
        currentTitle={"フォローアップ要否編集"}
      />
      <WaitForSuccess queryState={interviewDetailQueryState}>
        {(interviewDetail) => (
          <>
            <FollowupContent
              interviewId={interviewId}
              followUpMemo={interviewDetail.followUpMemo}
              followUpRequired={interviewDetail.followUpIsRequired ?? false}
            />
          </>
        )}
      </WaitForSuccess>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-all/hr-interviews/interviews/$interviewId/followup",
)({
  component: FollowupPage,
});

interface Props {
  interviewId: string;
  followUpMemo: string;
  followUpRequired: boolean;
}

const FollowupContent: React.FC<Props> = ({ interviewId, followUpMemo: initialMemo, followUpRequired }) => {
  const navigate = useNavigate();
  const [isRequired, setIsRequired] = useState(followUpRequired);
  const [followUpMemo, setFollowUpMemo] = useState(initialMemo);
  const editHrInterview = useEditHrInterview();
  return (
    <>
      <PageLayoutBody>
        <PaperWrapper>
          <PaperHeader>フォローアップ要否編集</PaperHeader>
          <PaperBody>
            <AmeRadioGroup
              data={[
                { label: "必要", value: "required" },
                { label: "不要", value: "optional" },
              ]}
              onChange={async (e) => {
                await setIsRequired(e.target.value === "required");
              }}
              name={"followUpRequired"}
              value={isRequired ? "required" : "optional"}
            />
            <Spacer height={"16px"} />
            <AmeFormset label={"フォローアップ内容"}>
              <AmeTextarea
                fullWidth
                disabled={!isRequired}
                value={followUpMemo}
                placeholder={"フォローアップ内容"}
                onChange={(e) => setFollowUpMemo(e.target.value)}
              />
            </AmeFormset>
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
      <AmeFormFollowingButtonsLayout
        onPrimary={async () => {
          await editHrInterview({
            hrInterviewId: interviewId,
            updateHrInterviewRequestBody: {
              followUpIsRequired: isRequired,
              followUpMemo: isRequired ? followUpMemo : undefined,
            },
          });
          await navigate({ to: "/hr-interviews/interviews/$interviewId", params: { interviewId } });
        }}
        onSecondary={async () => {
          await navigate({ to: "/hr-interviews/interviews/$interviewId", params: { interviewId } });
        }}
        secondaryText={"キャンセル"}
      />
    </>
  );
};
