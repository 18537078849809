import React, { useContext, useEffect } from "react";

import { useForm } from "react-hook-form";

import { useGetCategoryQuery } from "../../../../../../store/hooks/categories";
import { useCreateRule } from "../../../../../../store/hooks/rules";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { AmeInput } from "../../../../../atoms/forms/input/AmeInput";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { RuleBuilderContext } from "../RuleBuilderContext";

interface Props {
  categoryId: string;
  subCategoryId?: string;
}

interface RuleFormType {
  categoryId: string;
  esQuery: string;
}

export const RegisterHeader: React.FC<Props> = ({ categoryId, subCategoryId }) => {
  const { isSuccess, isFetching, data: category } = useGetCategoryQuery(categoryId);
  const { register, handleSubmit, reset, setValue } = useForm<RuleFormType>({
    defaultValues: {
      categoryId,
    },
  });
  const { currentRule, setCurrentRule } = useContext(RuleBuilderContext);
  const esRuleInputProps = register("esQuery", { required: true });
  useEffect(() => {
    setValue("esQuery", currentRule);
  }, [setValue, currentRule]);
  const createRule = useCreateRule();
  return isSuccess && !isFetching ? (
    <AmeBox>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (category === undefined) {
            return;
          }
          const { isSuccess } = await createRule({
            createRuleRequestBodyString: {
              esQuery: data.esQuery,
              subCategoryId: subCategoryId ?? category.defaultSubCategoryId,
            },
            categoryId,
          });
          if (isSuccess) {
            setCurrentRule("");
            reset();
          }
        })}
      >
        <AmeBox sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <AmeInput
            {...esRuleInputProps}
            onChange={(e) => {
              setCurrentRule(e.target.value);
            }}
            placeholder="ルールを入力"
            value={currentRule}
            fullWidth
          />
          <AmeBox component="span" sx={{ flexShrink: 0 }}>
            <AmeButton type="submit">完了</AmeButton>
          </AmeBox>
        </AmeBox>
      </form>
    </AmeBox>
  ) : null;
};
