import React, { useState } from "react";

import { useGetEmployeeChangeSetSummary } from "../../../../store/hooks/employees";
import { AmePagination } from "../../../atoms/pagination/AmePagination";
import { WaitForData } from "../../../molecules/Loading/WaitForData";
import { WaitForSuccess } from "../../../molecules/Loading/WaitForSuccess";

import { EmployeeChangeSetPreviewContent2 } from "./EmployeeChangeSetPreviewContent";

interface Props {
  changeSetId: string;
  onSubmit: (changeSetId: string) => Promise<void>;
}

const COUNT_PER_PAGE = 100;

export const EmployeeChangeSetPreview2: React.FC<Props> = ({ changeSetId, onSubmit }) => {
  const [page, setPage] = useState(0);
  const changeSetQuery = useGetEmployeeChangeSetSummary(changeSetId, COUNT_PER_PAGE, 0);
  const paginatedChangeSetQuery = useGetEmployeeChangeSetSummary(changeSetId, COUNT_PER_PAGE, page * COUNT_PER_PAGE);
  return (
    <WaitForData queryState={changeSetQuery}>
      {(data) => (
        <>
          <WaitForSuccess queryState={paginatedChangeSetQuery}>
            {(data2) => (
              <EmployeeChangeSetPreviewContent2
                changeSetItems={data2.changeSets}
                onSubmit={onSubmit}
                errorCount={data2.errorCount}
                changeSetId={changeSetId}
                insertedCount={data2.insertedCount}
                updatedCount={data2.updatedCount}
              />
            )}
          </WaitForSuccess>

          <AmePagination
            page={page + 1}
            count={Math.ceil(data.totalChangeSetCount / COUNT_PER_PAGE)}
            onChangePage={(page) => setPage(page - 1)}
          />
        </>
      )}
    </WaitForData>
  );
};
