/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewDetailResponseInterviewHighlights,
    InterviewDetailResponseInterviewHighlightsFromJSON,
    InterviewDetailResponseInterviewHighlightsFromJSONTyped,
    InterviewDetailResponseInterviewHighlightsToJSON,
} from './';

/**
 * インタビュー内容
 * @export
 * @interface InterviewDetailResponseInterview
 */
export interface InterviewDetailResponseInterview {
    /**
     * 
     * @type {Array<InterviewDetailResponseInterviewHighlights>}
     * @memberof InterviewDetailResponseInterview
     */
    highlights: Array<InterviewDetailResponseInterviewHighlights>;
    /**
     * 解析された日時
     * @type {Date}
     * @memberof InterviewDetailResponseInterview
     */
    analyzedAt?: Date;
    /**
     * インタビュー本文
     * @type {string}
     * @memberof InterviewDetailResponseInterview
     */
    interviewContent: string;
}

export function InterviewDetailResponseInterviewFromJSON(json: any): InterviewDetailResponseInterview {
    return InterviewDetailResponseInterviewFromJSONTyped(json, false);
}

export function InterviewDetailResponseInterviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewDetailResponseInterview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'highlights': ((json['highlights'] as Array<any>).map(InterviewDetailResponseInterviewHighlightsFromJSON)),
        'analyzedAt': !exists(json, 'analyzedAt') ? undefined : (new Date(json['analyzedAt'])),
        'interviewContent': json['interviewContent'],
    };
}

export function InterviewDetailResponseInterviewToJSON(value?: InterviewDetailResponseInterview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'highlights': ((value.highlights as Array<any>).map(InterviewDetailResponseInterviewHighlightsToJSON)),
        'analyzedAt': value.analyzedAt === undefined ? undefined : (value.analyzedAt.toISOString()),
        'interviewContent': value.interviewContent,
    };
}


