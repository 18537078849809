import React, { useCallback } from "react";

import { useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useUpdateEmailSettings } from "../../../../store/hooks/settings";
import { AmeFormset } from "../../../atoms/forms/AmeFormset";
import { AmeInput } from "../../../atoms/forms/input/AmeInput";
import { AmeTextarea } from "../../../atoms/forms/textarea/AmeTextarea";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeFormButtonsLayout } from "../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";

interface FeedbackEmailSettingsFormType {
  feedbackMailSenderName: string;
  feedbackMailSubject: string;
  feedbackMailBody: string;
  feedbackMailSignature: string;
  feedbackSurveyBody: string;
  feedbackSurveyTitle: string;
}

export const FeedbackEmailSettingsForm: React.FC<FeedbackEmailSettingsFormType> = (props) => {
  const navigate = useNavigate();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useHrPentestSnackbar();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FeedbackEmailSettingsFormType>({
    criteriaMode: "all",
    mode: "onChange",
    shouldFocusError: false,
    defaultValues: {
      feedbackMailSenderName: props.feedbackMailSenderName,
      feedbackMailSubject: props.feedbackMailSubject,
      feedbackMailBody: props.feedbackMailBody,
      feedbackMailSignature: props.feedbackMailSignature,
      feedbackSurveyBody: props.feedbackSurveyBody,
      feedbackSurveyTitle: props.feedbackSurveyTitle,
    },
  });
  const onError = useCallback(() => {
    enqueueErrorSnackbar({ title: "面談後アンケート設定の更新に失敗しました。担当者にお問い合わせください。" });
  }, [enqueueErrorSnackbar]);

  const updateEmailSettings = useUpdateEmailSettings({ onError });

  const onSubmit = useCallback(
    async (data: FeedbackEmailSettingsFormType) => {
      const res = await updateEmailSettings({
        updateEmailSettingsBody: {
          feedbackMailSenderName: data.feedbackMailSenderName,
          feedbackMailSubject: data.feedbackMailSubject,
          feedbackMailBody: data.feedbackMailBody,
          feedbackMailSignature: data.feedbackMailSignature,
          feedbackSurveyBody: data.feedbackSurveyBody,
          feedbackSurveyTitle: data.feedbackSurveyTitle,
        },
      });
      if (res.isSuccess) {
        await navigate({ to: "/settings" });
        enqueueSuccessSnackbar({ title: "面談後アンケート設定を更新しました。" });
      }
    },
    [updateEmailSettings, enqueueSuccessSnackbar, navigate],
  );

  return (
    <>
      <AmeFormset label="面談後アンケートメール送信者名" errorMessage={errors.feedbackMailSubject?.message}>
        <AmeInput
          {...register("feedbackMailSenderName", { required: "空の送信者名は設定できません。" })}
          placeholder={"〇〇株式会社 人事部 山田"}
          fullWidth
        />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormset label="面談後アンケートメール件名">
        <AmeTextarea
          {...register("feedbackMailSubject")}
          placeholder={"面談の面談後アンケートをお願いします。"}
          fullWidth
        />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormset label="面談後アンケートメール本文">
        <AmeTextarea {...register("feedbackMailBody")} placeholder={"面談ありがとうございました。・・・"} fullWidth />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormset label="面談後アンケートメール署名">
        <AmeTextarea {...register("feedbackMailSignature")} placeholder={"〇〇株式会社△△部☓☓"} fullWidth />
      </AmeFormset>
      <Spacer height="24px" />

      <AmeFormset label="面談後アンケートタイトル">
        <AmeInput {...register("feedbackSurveyTitle")} placeholder={"面談後アンケート"} fullWidth />
      </AmeFormset>
      <Spacer height="24px" />

      <AmeFormset label="面談後アンケート説明文">
        <AmeTextarea
          {...register("feedbackSurveyBody")}
          placeholder={"この度は面談ありがとうございました。・・・"}
          fullWidth
        />
      </AmeFormset>
      <Spacer height="24px" />
      <AmeFormButtonsLayout onPrimary={handleSubmit(onSubmit)} primaryText="変更" />
    </>
  );
};
