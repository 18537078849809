import React from "react";

import { StagingLevel } from "@amecloud/config/src/AmecloudConfig";

import { getStagingLevel } from "../../../env";

interface Props {
  children: React.ReactElement;
  activationLevels: StagingLevel[];
}

export const DevelopWrapper: React.FC<Props> = ({ children, activationLevels }) => {
  if (activationLevels.includes(getStagingLevel())) {
    return children;
  }
  return null;
};
