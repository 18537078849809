import React, { useContext, useCallback, useMemo } from "react";

import { SelectChangeEvent } from "@mui/material";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { useGetSegmentContent } from "../../../../../hooks/filterHooks/useSegmentChoiceGroup";
import { useSelectedFilter } from "../../../../../hooks/useSelectedFilter";
import { SegmentNames } from "../../../../../models/Setting";
import { EmployeeReportParams, useEmployeeReport2 } from "../../../../../store/hooks/employees";
import { Period } from "../../../../../store/hooks/periods";
import { ColumnChart } from "../../../../atoms/charts/ColumnChart";
import { LineChart } from "../../../../atoms/charts/LineChart";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { SimplePaper } from "../../../../atoms/paper/SimplePaper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { V2ContentWrapper } from "../../../../atoms/wrapper/V2ContentWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { ChoiceGroupProps } from "../../../../molecules/filter/ChoiceGroup/type";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { InfoExplain } from "../../../../organisms/employee-report/InfoExplain";
import { EmployeeFilter } from "../../../../organisms/v2/EmployeeFilter";
import { ReportHeader } from "../../../../organisms/v2/v2Component/ReportHeader";

type EmployeeReportQuery = Omit<EmployeeReportParams, "periodId"> & { periodId?: number };

export const EmployeeReport: React.FC = () => {
  const {
    setting: { segmentNames },
    periods,
  } = useContext(DataSourceContext);
  const searchParams = Route.useSearch();
  const periodId = searchParams.periodId;
  const basePeriod = periods.find((p) => p.periodId === periodId);
  const navigate = useNavigate();

  const rawSegmentFilter = useGetSegmentContent(searchParams, navigate);
  const segmentFilter = useMemo(() => {
    return {
      ...rawSegmentFilter,
      filterSubGroups: rawSegmentFilter.choiceSubGroups.filter(
        ({ subGroupName }) => !["勤続月数", "年齢"].includes(subGroupName),
      ),
    };
  }, [rawSegmentFilter]);
  const filterGroups = [segmentFilter];
  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"従業員情報レポート"} />
      <PageLayoutBody type={"wide"}>
        <AmeBox sx={{ display: "flex", gap: "24px" }}>
          <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
            <EmployeeFilter
              periodId={periodId}
              choiceGroupList={filterGroups}
              setPeriod={async (periodId) =>
                await navigate({
                  search: {
                    ...searchParams,
                    periodId: periodId,
                  },
                })
              }
            />
          </AmeBox>
          {basePeriod ? (
            <EmployeeReportContent
              basePeriod={basePeriod}
              periods={periods}
              segmentNames={segmentNames}
              filterGroups={filterGroups}
            />
          ) : (
            <>期間を選択してください</>
          )}
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

const EmployeeReportContent: React.FC<{
  basePeriod: Period;
  periods: Period[];
  segmentNames: SegmentNames;
  filterGroups: ChoiceGroupProps[];
}> = ({ basePeriod, periods, segmentNames, filterGroups }) => {
  const {
    setting: {
      globalSetting: {
        maxScaleValueInEmployeeReport,
        maxScalePercentageInEmployeeReport,
        maxScaleCurrentEmployeeValueInEmployeeReport,
      },
    },
  } = useContext(DataSourceContext);
  const searchParams = Route.useSearch();
  const axisNames = {
    NONE: "軸なし",
    month: "年月",
    ...segmentNames,
  };
  const previousPeriod = basePeriod ? periods[periods.indexOf(basePeriod) + 1] : undefined;
  const graphTitles = useMemo(() => {
    const graphTitles: string[] = [];
    if (previousPeriod) {
      graphTitles.push(previousPeriod.getDurationYearMonth());
    }

    if (basePeriod) {
      graphTitles.push(basePeriod.getDurationYearMonth());
    }
    return graphTitles;
  }, [previousPeriod, basePeriod]);

  const navigate = useNavigate();
  const onChangeAxis = useCallback(
    (e: SelectChangeEvent<keyof SegmentNames>) => {
      void navigate({
        search: { ...searchParams, axis: e.target.value as keyof typeof axisNames },
      });
    },
    [searchParams, navigate],
  );

  const employeeReportQuery = useEmployeeReport2({ ...searchParams, periodId: basePeriod.periodId });

  return (
    <AmeBox sx={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
      <ReportHeader
        axisDisplayNames={axisNames}
        selectedAxis={searchParams.axis}
        onChangeAxis={onChangeAxis}
        selectedFilters={useSelectedFilter(filterGroups)}
      />
      <SimplePaper>
        <V2ContentWrapper
          title={"在籍人数"}
          extracontent={<InfoExplain predate="2024年3月31日" curdate="2025年3月31日" />}
        >
          <WaitForSuccess queryState={employeeReportQuery}>
            {({ currentEmployeeChartData }) => {
              return (
                <AmeBox sx={{ position: "relative" }}>
                  <ColumnChart
                    series={previousPeriod ? currentEmployeeChartData.series : currentEmployeeChartData.series2}
                    series2={previousPeriod && currentEmployeeChartData.series2}
                    seriesLabels={currentEmployeeChartData.seriesLabels}
                    max={maxScaleCurrentEmployeeValueInEmployeeReport}
                    names={graphTitles}
                    height={300}
                  />
                  {searchParams.axis === "month" ? (
                    <AmeBox
                      sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(222,222,222, 0.4)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AmeBox sx={{ color: "black" }}>年月の軸では、在籍人数のグラフは表示できません。</AmeBox>
                    </AmeBox>
                  ) : null}
                </AmeBox>
              );
            }}
          </WaitForSuccess>
        </V2ContentWrapper>
      </SimplePaper>
      <Spacer height="24px" />
      <AmeBox sx={{ display: "flex", gap: "24px" }}>
        <AmeBox sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
          <SimplePaper>
            <V2ContentWrapper
              title={"退職人数"}
              extracontent={<InfoExplain predate="2024年3月31日" curdate="2025年3月31日" />}
            >
              <WaitForSuccess queryState={employeeReportQuery}>
                {({ turnoverChartData }) => (
                  <ColumnChart
                    series={previousPeriod ? turnoverChartData.series : turnoverChartData.series2}
                    series2={previousPeriod && turnoverChartData.series2}
                    seriesLabels={turnoverChartData.seriesLabels}
                    max={maxScaleValueInEmployeeReport}
                    names={graphTitles}
                    height={300}
                  />
                )}
              </WaitForSuccess>
            </V2ContentWrapper>
          </SimplePaper>
        </AmeBox>
        <AmeBox sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
          <SimplePaper>
            <V2ContentWrapper
              title={"退職率"}
              extracontent={<InfoExplain predate="2024年3月31日" curdate="2025年3月31日" />}
            >
              <WaitForSuccess queryState={employeeReportQuery}>
                {({ turnoverRateChartData }) => (
                  <LineChart
                    series={previousPeriod ? turnoverRateChartData.series : turnoverRateChartData.series2}
                    series2={previousPeriod && turnoverRateChartData.series2}
                    seriesLabels={turnoverRateChartData.seriesLabels}
                    max={
                      maxScalePercentageInEmployeeReport !== undefined ? maxScalePercentageInEmployeeReport / 100 : 1
                    }
                    names={graphTitles}
                    height={300}
                  />
                )}
              </WaitForSuccess>
            </V2ContentWrapper>
          </SimplePaper>
        </AmeBox>
      </AmeBox>
      <Spacer height="24px" />
      <AmeBox sx={{ display: "flex", gap: "24px" }}>
        <AmeBox sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
          <SimplePaper>
            <V2ContentWrapper
              title={"入職人数"}
              extracontent={<InfoExplain predate="2024年3月31日" curdate="2025年3月31日" />}
            >
              <WaitForSuccess queryState={employeeReportQuery}>
                {({ hiredChartData }) => (
                  <ColumnChart
                    series={previousPeriod ? hiredChartData.series : hiredChartData.series2}
                    series2={previousPeriod && hiredChartData.series2}
                    seriesLabels={hiredChartData.seriesLabels}
                    max={maxScaleValueInEmployeeReport}
                    names={graphTitles}
                    height={300}
                  />
                )}
              </WaitForSuccess>
            </V2ContentWrapper>
          </SimplePaper>
        </AmeBox>
        <AmeBox sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 0 }}>
          <SimplePaper>
            <V2ContentWrapper
              title={"入職率"}
              extracontent={<InfoExplain predate="2024年3月31日" curdate="2025年3月31日" />}
            >
              <WaitForSuccess queryState={employeeReportQuery}>
                {({ hiredRateChartData }) => (
                  <LineChart
                    series={previousPeriod ? hiredRateChartData.series : hiredRateChartData.series2}
                    series2={previousPeriod && hiredRateChartData.series2}
                    seriesLabels={hiredRateChartData.seriesLabels}
                    max={
                      maxScalePercentageInEmployeeReport !== undefined ? maxScalePercentageInEmployeeReport / 100 : 1
                    }
                    names={graphTitles}
                    height={300}
                  />
                )}
              </WaitForSuccess>
            </V2ContentWrapper>
          </SimplePaper>
        </AmeBox>
      </AmeBox>
      <Spacer height="24px" />
      <SimplePaper>
        <V2ContentWrapper
          title={"退職人数"}
          extracontent={<InfoExplain predate="2024年3月31日" curdate="2025年3月31日" />}
        >
          <WaitForSuccess queryState={employeeReportQuery}>
            {({ turnoverChartData }) => (
              <ColumnChart
                series={previousPeriod ? turnoverChartData.series : turnoverChartData.series2}
                series2={previousPeriod && turnoverChartData.series2}
                seriesLabels={turnoverChartData.seriesLabels}
                max={maxScaleValueInEmployeeReport}
                names={graphTitles}
                horizontal
                legend={{
                  show: true,
                  position: "bottom",
                  horizontalAlign: "center",
                }}
                dataLabelsFormatter={(value: number) => {
                  return value + "人";
                }}
              />
            )}
          </WaitForSuccess>
        </V2ContentWrapper>
      </SimplePaper>
    </AmeBox>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/employee-report/")({
  component: EmployeeReport,
  validateSearch: (search: Partial<EmployeeReportQuery>): EmployeeReportQuery => {
    return {
      periodId: search.periodId,
      axis: search.axis || "NONE",
      SEX: search.SEX ?? [],
      DEPARTMENT: search.DEPARTMENT ?? [],
      FREE1: search.FREE1 ?? [],
      FREE2: search.FREE2 ?? [],
      AGE: search.AGE ?? [],
      MONTHS_OF_SERVICE: search.MONTHS_OF_SERVICE ?? [],
    };
  },
});
