import React, { useCallback, useContext, useState } from "react";

import { useAbility } from "@casl/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import HomeIcon from "@mui/icons-material/Home";
import { darken } from "@mui/material";
import { Link } from "@tanstack/react-router";

import { useSp } from "../../../../hooks/useSp";
import { CSS_SP_MEDIA_QUERY } from "../../../../utils/mediaQuery";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { HRPentestLogo } from "../../../atoms/icon/HRPentestLogo";
import { Hamburger } from "../../../atoms/icon/Humberger";
import { Setting } from "../../../atoms/icon/Setting";
import { ChoosableList } from "../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../atoms/list/ChoosableListItem";
import { AmeDialog } from "../../../molecules/dialog/AmeDialog";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { AmeMenu } from "../../../muiWrapper/AmeMenu";
import { AbilityContext, ACTION, SUBJECT } from "../Authorizer";
import { DataSourceContext } from "../DataSourceContext";

import { SideBar } from "./SideBar";

export const Header: React.FC = () => {
  const theme = useAmeTheme();
  const {
    localStorage: { isWarning },
    setting: {
      globalSetting: { activateHrInterview },
    },
  } = useContext(DataSourceContext);
  const ability = useAbility(AbilityContext);

  const sp = useSp();

  // サイドバーの開閉の管理
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const handleOpenSideBar = () => {
    setIsSideBarOpen(true);
  };

  const handleCloseSideBar = () => {
    setIsSideBarOpen(false);
  };

  return (
    <AmeBox
      component="header"
      sx={{
        width: "100%",
        position: "sticky",
        top: 0,
        zIndex: theme.zIndex.header,
        display: "flex",
        paddingX: "24px",
        height: "52px",
        bgcolor: isWarning.value ? theme.common.background.warning100 : theme.common.text.black,
        color: theme.common.text.white,
        [CSS_SP_MEDIA_QUERY]: { paddingX: "16px" },
      }}
    >
      <AmeBox sx={{ display: "inline-flex", alignItems: "center", width: "100px" }}>
        <Link to={"/"}>
          <HRPentestLogo />
        </Link>
      </AmeBox>
      <AmeBox sx={{ flexGrow: 1 }} />
      <AmeBox sx={{ display: "flex", marginRight: "-16px" }}>
        {ability.can(ACTION.manage, SUBJECT.all) && !sp ? (
          <>
            <HeaderMenu startIcon={<HomeIcon />} title="ホーム" href="/" />
            <HeaderMenuList
              title="退職者インタビュー"
              menuItems={[
                { title: "音声データ一覧", href: "/interview-files" },
                // { title: "インタビュー一覧", href: "/interviews" },
                { title: "インタビューレポート", href: "/interview-report" },
                { title: "インタビューKPIレポート", href: "/interview-kpi-report" },
                { title: "インタビュー品質レポート", href: "/interview-feedbacks" },
              ]}
            />
            {activateHrInterview && (
              <HeaderMenuList
                title="在職者インタビュー"
                menuItems={[
                  { title: "音声データ一覧", href: "/hr-interviews/interview-files" },
                  { title: "インタビュー一覧", href: "/hr-interviews/interviews" },
                  { title: "インタビューレポート", href: "/hr-interviews/interview-report" },
                  { title: "インタビュー品質レポート", href: "/hr-interviews/quality-report" },
                ]}
              />
            )}
            <HeaderMenuList
              title="従業員"
              menuItems={[
                { title: "従業員一覧", href: "/employees" },
                { title: "従業員情報レポート", href: "/employee-report" },
                // { title: "離職効果測定レポート", href: "/summaries/employees" },
              ]}
            />
            <HeaderMenuList
              title="アンケート"
              menuItems={[
                { title: "アンケート一覧", href: "/surveys" },
                { title: "アンケートレポート", href: "/survey-report" },
                // { title: "アンケート効果測定レポート", href: "/summaries/surveys" },
              ]}
            />
            <HeaderMenu title={<Setting size={16} color="white" />} href="/settings" />
          </>
        ) : (
          <>
            <AmeBox
              onClick={handleOpenSideBar}
              sx={{
                width: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                height: "100%",
                marginLeft: "auto",
                paddingRight: "24px",
              }}
            >
              <Hamburger color="white" size={19} />
            </AmeBox>
            <AmeDialog open={isSideBarOpen} onClose={handleCloseSideBar}>
              <SideBar onClose={handleCloseSideBar} />
            </AmeDialog>
          </>
        )}
      </AmeBox>
    </AmeBox>
  );
};

type HeaderMenuListProps = {
  title: string;
  menuItems: {
    title: string;
    href: string;
  }[];
};

const HeaderMenuList: React.FC<HeaderMenuListProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      setOpenSubMenu(true);
    },
    [setAnchorEl, setOpenSubMenu],
  );
  const handleClose = useCallback(() => {
    setOpenSubMenu(false);
  }, [setOpenSubMenu]);
  return (
    <>
      <HeaderElement
        title={props.title}
        endIcon={openSubMenu ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={handleClick}
      />
      <AmeMenu anchorEl={anchorEl} open={openSubMenu} onClose={handleClose}>
        <ChoosableList>
          {props.menuItems.map((menuItem) => (
            <HeaderMenuListItem
              key={menuItem.title}
              title={menuItem.title}
              href={menuItem.href}
              onClose={handleClose}
            />
          ))}
        </ChoosableList>
      </AmeMenu>
    </>
  );
};

type HeaderMenuListItemProps = {
  title: string;
  href: string;
  onClose: () => void;
};

const HeaderMenuListItem: React.FC<HeaderMenuListItemProps> = (props) => {
  return (
    <Link to={props.href}>
      <ChoosableListItem onSelect={props.onClose}>{props.title}</ChoosableListItem>
    </Link>
  );
};

type HeaderMenuProps = {
  startIcon?: React.ReactNode;
  title: React.ReactNode | string;
  href?: string;
  onClick?: () => void;
};
const HeaderMenu: React.FC<HeaderMenuProps> = (props) => {
  const children = <HeaderElement title={props.title} startIcon={props.startIcon} onClick={props.onClick} />;
  if (props.href) {
    return <Link to={props.href}>{children}</Link>;
  } else {
    return children;
  }
};

type HeaderElementProps = {
  startIcon?: React.ReactNode;
  title: React.ReactNode | string;
  endIcon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};
const HeaderElement: React.FC<HeaderElementProps> = (props) => {
  const theme = useAmeTheme();
  const { onClick } = props;
  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );
  return (
    <AmeBox
      onClick={handleClick}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        paddingRight: props.endIcon ? "10px" : "16px",
        paddingLeft: props.startIcon ? "10px" : "16px",
        cursor: "pointer",
        ":hover": {
          bgcolor: darken(theme.common.text.black, 0.2),
        },
      }}
    >
      {props.startIcon}
      {props.title}
      {props.endIcon}
    </AmeBox>
  );
};
