import React, { useCallback, useState } from "react";

import { Employee } from "../../../../../models/Employee";
import { InterviewFile } from "../../../../../models/InterviewFile";
import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { EditIcon } from "../../../../atoms/icon/Edit";
import { AmeModal } from "../../../../atoms/modal/AmeModal";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../../../muiWrapper/AmeIconButton";
import { EmployeeSelectModalContent } from "../../../interview/EmployeeSelectModalContent";

interface Props {
  file: InterviewFile;
  updateInterviewFile: (interviewFileId: string, snapshotId: string | null) => Promise<void>;
}

export const LinkedEmployeeIdCell: React.FC<Props> = ({ file, updateInterviewFile }) => {
  const theme = useAmeTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClick = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onSubmit = useCallback(
    async (e: Employee) => {
      await updateInterviewFile(file.interviewRecordingId, e.employeeTenureId);
    },
    [updateInterviewFile, file],
  );

  return file.employee ? (
    <AmeTypography fontSize="body2">{`${file.employee.customerCreatedId} ${file.employee.name || ""}`}</AmeTypography>
  ) : (
    <AmeBox
      component="span"
      sx={{ display: "inline-flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}
    >
      <AmeTypography fontSize="body2">選択なし</AmeTypography>
      <AmeIconButton
        sx={{
          border: `1px solid ${theme.common.border.gray40}`,
          borderRadius: "8px",
          padding: "8px",
          color: theme.common.text.gray40,
        }}
        onClick={onClick}
      >
        <EditIcon color="inherit" />
      </AmeIconButton>
      <AmeModal open={isOpen} onClose={onClose}>
        <EmployeeSelectModalContent title="従業員番号の紐づけ" onClose={onClose} onSubmit={onSubmit} />
      </AmeModal>
    </AmeBox>
  );
};
