import React, { useMemo, MouseEventHandler, PropsWithChildren } from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

interface AmeTableProps {
  children: React.ReactNode;
  useCustomCellWidth?: boolean;
}
/**
 * useCustomCellWidthはブラウザが自動で計算してくれるcell幅を使わないときにtrueにしてください。具体的な幅の指定はAmeTableHeadCellに渡してください。
 * ちなみに、非常に長いセル内センテンスの省略をしたい場合は、useCustomCellWidthをtrueにし、各columnの幅を指定しないと、AmeTypographyを使った省略が効きません。
 */
export const AmeTable: React.FC<AmeTableProps> = ({ children, useCustomCellWidth }) => {
  const theme = useAmeTheme();
  return (
    <Table
      sx={{ border: `1px solid ${theme.common.border.gray40}`, tableLayout: useCustomCellWidth ? "fixed" : "auto" }}
    >
      {children}
    </Table>
  );
};

interface AmeTableHeadProps {
  children: React.ReactNode;
}

export const AmeTableHead: React.FC<AmeTableHeadProps> = ({ children }) => {
  return <TableHead>{children}</TableHead>;
};

interface AmeTableBodyProps {
  children: React.ReactNode;
}

export const AmeTableBody: React.FC<AmeTableBodyProps> = ({ children }) => {
  return <TableBody>{children}</TableBody>;
};

export type HighlightType = "informing" | "warning" | undefined;

type AmeTableRowProps = {
  highlightType?: HighlightType;
  isDense?: boolean;
};
export const AmeTableRow: React.FC<PropsWithChildren<AmeTableRowProps>> = ({ children, highlightType }) => {
  const theme = useAmeTheme();
  const backgroundColor = useMemo(() => {
    if (highlightType === "warning") {
      return theme.common.background.warning20;
    }
    if (highlightType === "informing") {
      return theme.brand.secondary[20];
    }
    return theme.common.background.white;
  }, [highlightType, theme]);

  return (
    <TableRow
      sx={{
        backgroundColor: backgroundColor,
      }}
    >
      {children}
    </TableRow>
  );
};

interface AmeTableCellProps {
  children?: React.ReactNode;
  align?: "left" | "center" | "right";
  noPadding?: boolean;
  isDense?: boolean;
}

interface AmeTableHeadCellProps extends AmeTableCellProps {
  width?: number | string;
  // for react-table
  colSpan?: number;
  role?: string;
  title?: string;
  onClick?: MouseEventHandler<HTMLTableCellElement>;
  style?: React.CSSProperties;
}
/**
 * widthはAmeTableのPropsのuseCustomCellWidthをtrueにしないと効きません。
 */
export const AmeTableHeadCell: React.FC<AmeTableHeadCellProps> = ({
  children,
  align,
  width,
  noPadding,
  isDense,
  ...otherProps
}) => {
  const theme = useAmeTheme();
  return (
    <TableCell
      {...otherProps}
      component="th"
      sx={{
        border: `1px solid ${theme.common.border.gray40}`,
        verticalAlign: "middle",
        textAlign: align,
        height: isDense ? "32px" : "56px",
        padding: noPadding ? 0 : "0 16px",
        width: width,
        backgroundColor: theme.common.background.gray40,
      }}
    >
      {children}
    </TableCell>
  );
};

interface AmeTableBodyCellProps extends AmeTableCellProps {
  highlightType?: HighlightType;
  disableEllipsis?: boolean;
  rowSpan?: number;
}
/**
 * isWarningとisInformingの両方がtrueの場合は、isWarningが優先されます。
 */
export const AmeTableBodyCell: React.FC<AmeTableBodyCellProps> = ({
  children,
  align,
  highlightType,
  noPadding,
  isDense,
  disableEllipsis,
  rowSpan,
}) => {
  const theme = useAmeTheme();
  const backgroundColor = useMemo(() => {
    if (highlightType === "warning") {
      return theme.common.background.warning20;
    }
    if (highlightType === "informing") {
      return theme.brand.secondary[20];
    }
    return "inherit";
  }, [highlightType, theme]);

  return (
    <TableCell
      component="td"
      rowSpan={rowSpan}
      sx={[
        {
          maxWidth: "80%",
          border: `1px solid ${theme.common.border.gray40}`,
          height: isDense ? "32px" : "48px",
          padding: noPadding ? 0 : "0 16px",
          verticalAlign: "middle",
          textAlign: align,
          backgroundColor: backgroundColor,
        },
        !disableEllipsis && {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      ]}
    >
      {children}
    </TableCell>
  );
};
