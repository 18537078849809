import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface PaperLayoutProps {
  children: React.ReactNode;
}

export const PaperWrapper: React.FC<PaperLayoutProps> = ({ children }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        width: "100%",
        backgroundColor: theme.common.background.white,
        border: `1px solid ${theme.common.border.gray40}`,
        borderRadius: "4px",
        boxShadow: theme.common.shadow.main,
        color: theme.common.text.black,
      }}
    >
      {children}
    </AmeBox>
  );
};
