/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegisterInterviewFormatsRequestBodyFormats,
    RegisterInterviewFormatsRequestBodyFormatsFromJSON,
    RegisterInterviewFormatsRequestBodyFormatsFromJSONTyped,
    RegisterInterviewFormatsRequestBodyFormatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegisterInterviewFormatsRequestBody
 */
export interface RegisterInterviewFormatsRequestBody {
    /**
     * 
     * @type {Array<RegisterInterviewFormatsRequestBodyFormats>}
     * @memberof RegisterInterviewFormatsRequestBody
     */
    formats: Array<RegisterInterviewFormatsRequestBodyFormats>;
}

export function RegisterInterviewFormatsRequestBodyFromJSON(json: any): RegisterInterviewFormatsRequestBody {
    return RegisterInterviewFormatsRequestBodyFromJSONTyped(json, false);
}

export function RegisterInterviewFormatsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterInterviewFormatsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formats': ((json['formats'] as Array<any>).map(RegisterInterviewFormatsRequestBodyFormatsFromJSON)),
    };
}

export function RegisterInterviewFormatsRequestBodyToJSON(value?: RegisterInterviewFormatsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formats': ((value.formats as Array<any>).map(RegisterInterviewFormatsRequestBodyFormatsToJSON)),
    };
}


