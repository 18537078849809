import React, { useMemo } from "react";

import Lottie from "lottie-react";

import GrayLoadingAnimation from "./GrayLoading.json";
import WhiteLoadingAnimation from "./WhiteLoading.json";

type Size = "small" | "medium" | "large";

interface Props {
  size?: Size;
  color?: "white" | "gray";
}

export const Loading: React.FC<Props> = ({ size = "medium", color = "white" }) => {
  const theme = useLoadingTheme(size);
  return (
    <Lottie
      animationData={color === "white" ? WhiteLoadingAnimation : GrayLoadingAnimation}
      style={{ width: theme.size, height: theme.size, margin: theme.margin }}
      loop
    />
  );
};

const useLoadingTheme = (size: Size): { size: string; margin: string } => {
  return useMemo(() => {
    switch (size) {
      case "small":
        return { size: "60px", margin: "-24px -18px" };
      case "medium":
        return { size: "80px", margin: "-32px -24px" };
      case "large":
        return { size: "120px", margin: "-48px -36px" };
    }
  }, [size]);
};
