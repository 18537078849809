import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";

import { UseChoiceGroupParams } from "./type";

export const useEvaluationTypeChoiceGroup = (
  evaluationTypes: string[],
  { filterParams, navigate }: UseChoiceGroupParams<{ evaluationTypes: string[] }>,
): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    return {
      groupName: "評価項目",
      uiType: "flat",
      choiceOptions: evaluationTypes.map((type) => ({
        name: type,
        toggleOption: async () =>
          await navigate({
            search: (prev: { evaluationTypes: string[] }) => {
              const followUpRequiredSet = new Set(prev.evaluationTypes);
              if (!followUpRequiredSet.delete(type)) {
                followUpRequiredSet.add(type);
              }
              return { ...prev, evaluationTypes: Array.from(followUpRequiredSet) };
            },
          }),
        isChecked: filterParams.evaluationTypes.includes(type),
      })),
    };
  }, [evaluationTypes, filterParams.evaluationTypes, navigate]);
};
