import React from "react";

import { ArrowBackIosNew } from "@mui/icons-material";
import { UseFieldArrayRemove, UseFieldArraySwap } from "react-hook-form";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { AmeIconButton } from "../../../muiWrapper/AmeIconButton";

interface Props {
  index: number;
  swap: UseFieldArraySwap;
  remove: UseFieldArrayRemove;
  rowLastIndex: number;
  hrInterviewTypeId: string;
}

export const HrInterviewTypeSettingOperationsCell: React.FC<Props> = (props) => {
  const theme = useAmeTheme();

  return (
    <React.Fragment>
      <AmeIconButton
        onClick={() => props.swap(props.index, props.index - 1)}
        disabled={props.index == 0}
        sx={{ border: `1px solid ${theme.common.border.gray40}`, marginRight: "10px" }}
      >
        <ArrowBackIosNew sx={{ transform: "rotate(90deg)", fontSize: "18px" }} />
      </AmeIconButton>
      <AmeIconButton
        onClick={() => props.swap(props.index, props.index + 1)}
        disabled={props.index === props.rowLastIndex}
        sx={{ border: `1px solid ${theme.common.border.gray40}`, marginRight: "10px" }}
      >
        <ArrowBackIosNew sx={{ transform: "rotate(-90deg)", fontSize: "18px" }} />
      </AmeIconButton>
      <AmeButton
        size="small"
        variant="outlined"
        to="/settings/hr-interview-types/$hrInterviewTypeId/formats"
        params={{ hrInterviewTypeId: props.hrInterviewTypeId }}
        disabled={props.hrInterviewTypeId === undefined}
      >
        議事録フォーマット設定
      </AmeButton>
      <AmeBox component="span" sx={{ display: "inline-block", width: "10px" }} />
      <AmeButton size="small" variant="outlined" onClick={() => props.remove(props.index)}>
        削除
      </AmeButton>
    </React.Fragment>
  );
};
