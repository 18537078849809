import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import {
  useGetInterviewFormatContents,
  useGetInterviewFormats,
} from "../../../../../../../../../store/hooks/hr-interview-setting";
import { BreadcrumbPageLayoutHeader } from "../../../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../../../molecules/Loading/WaitForSuccess";
import { HrInterviewQuestionsSettingContent } from "../../../../../../../../organisms/settings/hr-interview-types/hr-interview-types/formats/questions/HrInterviewQuestionsSettingContent";

const HrInterviewQuestions: React.FC = () => {
  const { hrInterviewTypeId, hrInterviewFormatId } = Route.useParams();
  const interviewFormatQueryState = useGetInterviewFormats(hrInterviewTypeId);
  const interviewQuestionsQueryState = useGetInterviewFormatContents(hrInterviewFormatId);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        currentTitle={"設問編集"}
        items={[
          { title: "設定", to: "/settings" },
          { title: "在籍者面談詳細", to: "/settings/hr-interview-types" },
          { title: "議事録フォーマット設定", to: "/settings/hr-interview-types/$hrInterviewTypeId/formats" },
        ]}
      />
      <WaitForSuccess queryState={interviewFormatQueryState}>
        {(interviewFormats) => {
          const hrInterviewFormatText =
            interviewFormats.find((format) => format.hrInterviewFormatId === hrInterviewFormatId)
              ?.hrInterviewFormatText || "";

          return (
            <WaitForSuccess queryState={interviewQuestionsQueryState}>
              {(interviewQuestions) => (
                <HrInterviewQuestionsSettingContent
                  defaultInterviewQuestions={interviewQuestions}
                  title={hrInterviewFormatText}
                  hrInterviewFormatId={hrInterviewFormatId}
                />
              )}
            </WaitForSuccess>
          );
        }}
      </WaitForSuccess>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-admin/settings/hr-interview-types/$hrInterviewTypeId/formats/$hrInterviewFormatId/",
)({
  component: HrInterviewQuestions,
});
