/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 音声データ情報
 * @export
 * @interface InterviewDetailResponseInterviewFile
 */
export interface InterviewDetailResponseInterviewFile {
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseInterviewFile
     */
    interviewFileId: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseInterviewFile
     */
    interviewerName?: string;
    /**
     * 
     * @type {Date}
     * @memberof InterviewDetailResponseInterviewFile
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof InterviewDetailResponseInterviewFile
     */
    originalFileName: string;
}

export function InterviewDetailResponseInterviewFileFromJSON(json: any): InterviewDetailResponseInterviewFile {
    return InterviewDetailResponseInterviewFileFromJSONTyped(json, false);
}

export function InterviewDetailResponseInterviewFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewDetailResponseInterviewFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interviewFileId': json['interviewFileId'],
        'interviewerName': !exists(json, 'interviewerName') ? undefined : json['interviewerName'],
        'createdAt': (new Date(json['createdAt'])),
        'originalFileName': json['originalFileName'],
    };
}

export function InterviewDetailResponseInterviewFileToJSON(value?: InterviewDetailResponseInterviewFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interviewFileId': value.interviewFileId,
        'interviewerName': value.interviewerName,
        'createdAt': (value.createdAt.toISOString()),
        'originalFileName': value.originalFileName,
    };
}


