/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LabelType {
    TRUE = 'true',
    FALSE = 'false',
    UNKNOWN = 'unknown'
}

export function LabelTypeFromJSON(json: any): LabelType {
    return LabelTypeFromJSONTyped(json, false);
}

export function LabelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelType {
    return json as LabelType;
}

export function LabelTypeToJSON(value?: LabelType | null): any {
    return value as any;
}

