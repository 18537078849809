import React from "react";

import { useGetSurveyList } from "../../../store/hooks/surveys";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { ChoiceGroup } from "../../molecules/filter/ChoiceGroup";
import { ChoiceGroupProps } from "../../molecules/filter/ChoiceGroup/type";
import { FilterSelect } from "../../molecules/filter/FilterSelect";
import { FilterWrapper } from "../../molecules/filter/FilterWrapper";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  surveyId?: string;
  setSurveyId: (surveyId: string) => void;
  choiceGroups: ChoiceGroupProps[];
};

export const SurveyFilter: React.FC<Props> = (props) => {
  const selectSurveys =
    useGetSurveyList({ count: 10, offset: 0 }).data?.surveys.map((survey) => {
      return {
        name: survey.displayName,
        queryValue: survey.surveyId,
      };
    }) ?? [];
  const theme = useAmeTheme();
  return (
    <>
      <FilterWrapper>
        <AmeBox sx={{ borderBottom: `1px solid ${theme.common.border.gray40}` }}>
          <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "8px", padding: "16px", flexShrink: 1 }}>
            <AmeTypography component="h6">{"対象アンケート"}</AmeTypography>
            <FilterSelect
              options={selectSurveys}
              selectedOption={props.surveyId ?? null}
              setFilterParams={(_, optionsKey) => props.setSurveyId(optionsKey)}
              filterParamKey="surveyId"
            />
          </AmeBox>
        </AmeBox>
        {props.choiceGroups.map((filterGroup, index) => {
          return <ChoiceGroup key={index} {...filterGroup} />;
        })}
      </FilterWrapper>
    </>
  );
};
