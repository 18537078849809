import React, { PropsWithChildren, useEffect } from "react";

import { CellProps } from "react-table";

import { RuleStatsModel, useGetRuleStatsLazyQueryByRuleId } from "../../../../../store/hooks/ruleStats";
import { RuleModel } from "../../../../../store/hooks/rules";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  fetchController: (task: () => Promise<void>) => Promise<void>;
  data: PropsWithChildren<CellProps<RuleModel, string>>;
  children: (stats: RuleStatsModel) => React.ReactNode;
}

export const RuleStatsCell: React.FC<Props> = ({ data, children, fetchController }) => {
  const {
    trigger,
    result: { isSuccess, data: stats },
  } = useGetRuleStatsLazyQueryByRuleId();
  useEffect(() => {
    (async () => {
      await fetchController(async () => {
        await trigger({ ruleId: data.value }, true);
      });
    })();
  }, [data.value, fetchController, trigger]);
  return <AmeBox>{isSuccess && stats ? children(stats) : null}</AmeBox>;
};
