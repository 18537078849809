import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetInterviewFileQuery } from "../../../../../../store/hooks/interview-files";
import { useGetInterviewAudioFileUrl } from "../../../../../../store/hooks/interview-files";
import { useGetTranscription, useStartTranscriptionMutation } from "../../../../../../store/hooks/transcription";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { TranscriptionSoundLoader } from "../../../../../organisms/transcriptions/interviewFileId/TranscriptionLoader";

export const TranscriptionPage: React.FC = () => {
  const { interviewRecordingId } = Route.useParams();
  const startTranscription = useStartTranscriptionMutation();
  const interviewFileState = useGetInterviewFileQuery(interviewRecordingId);
  const params = Route.useSearch();

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "退職インタビュー一覧（文字起こしver）", to: "/hidden-pages/transcriptions" }]}
        currentTitle={"インタビュー詳細"}
      />
      <PageLayoutBody type={"wide"}>
        <WaitForSuccess queryState={interviewFileState}>
          {(interviewFile) =>
            interviewFile.employee ? (
              ["created", "checking", "checked"].includes(interviewFile.transcriptionStatus as string) ? (
                <Transcriber interviewRecordingId={interviewRecordingId} forCheck={params.check ?? false} />
              ) : (
                <>
                  解析が終了していません status: {interviewFile.transcriptionStatus}
                  <AmeButton onClick={() => startTranscription({ interviewRecordingId: interviewRecordingId })}>
                    解析を最初から実行する。
                  </AmeButton>
                </>
              )
            ) : (
              <>従業員が紐付けられていません。従業員を紐付けたインタビュー音声のみ、文字起こし可能です。</>
            )
          }
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

const Transcriber: React.FC<{
  interviewRecordingId: string;
  forCheck: boolean;
}> = ({ interviewRecordingId, forCheck }) => {
  const transcriptionQueryState = useGetTranscription(interviewRecordingId);
  const preSignedUrlQueryState = useGetInterviewAudioFileUrl(interviewRecordingId);
  return (
    <WaitForSuccess queryState={preSignedUrlQueryState}>
      {({ presignedUrl }) => (
        <WaitForSuccess queryState={transcriptionQueryState}>
          {(transcription) => (
            <TranscriptionSoundLoader
              check={forCheck}
              soundUrl={presignedUrl}
              transcriptions={transcription.results.transcriptions}
              speakers={transcription.results.speakers}
              currentSpeakerLabel={transcription.retiredSpeakerLabel}
              interviewRecordingId={interviewRecordingId}
              transcriptionStatus={transcription.status}
              transcriptionText={transcription.text}
            />
          )}
        </WaitForSuccess>
      )}
    </WaitForSuccess>
  );
};

type TranscriptionQueryParams = {
  check?: boolean;
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-admin/interview-files/$interviewRecordingId/transcription",
)({
  component: TranscriptionPage,
  validateSearch: (search: Partial<TranscriptionQueryParams>): TranscriptionQueryParams => {
    return {
      check: search.check ?? false,
    };
  },
});
