import React from "react";

import { SnackbarProvider } from "notistack";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";

import { CustomSnackbarContent } from "./CustomSnackbarContent";
import { SnackbarViaReduxConsumer } from "./SnackbarViaReduxConsumer";

type HrPentestSnackbarMessageType = "success" | "error";
export type HrPentestSnackbarMessageBody = {
  title?: string;
  message?: string;
};
export type HrPentestSnackbarMessage = HrPentestSnackbarMessageBody & {
  type: HrPentestSnackbarMessageType;
};

type Props = {
  children: React.ReactNode | React.ReactNode[];
};
export const HrPentestSnackbarProvider: React.FC<Props> = ({ children }) => {
  const theme = useAmeTheme();
  return (
    <SnackbarProvider
      style={{ zIndex: `${theme.zIndex.snackbar} !important` }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      content={(key, message) => <CustomSnackbarContent id={key} message={message} />}
      maxSnack={5}
    >
      <SnackbarViaReduxConsumer />
      {children}
    </SnackbarProvider>
  );
};
