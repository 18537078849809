import React, { useMemo } from "react";

import { MenuItem, MenuItemProps } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

type Props = Pick<MenuItemProps, "onClick" | "children" | "value" | "selected"> & {
  /**
   * MUIのAutocompleteで使われているカスタムデータ（独自の属性）。
   * これが無いと、AutocompleteのoptionにAmeMenuItemを使ったときに、うまく動作しない。
   */
  "data-option-index"?: number;
};
export const AmeMenuItem: React.FC<Props> = ({
  onClick,
  children,
  value,
  "data-option-index": dataOptionIndex,
  selected,
}) => {
  const style = useMenuItemStyle();
  return (
    <MenuItem sx={style} value={value} onClick={onClick} data-option-index={dataOptionIndex} selected={selected}>
      {children}
    </MenuItem>
  );
};

const useMenuItemStyle = (): SystemStyleObject => {
  const theme = useAmeTheme();
  return useMemo(() => {
    return {
      "&.MuiMenuItem-root": {
        color: theme.common.text.black,
        "&.Mui-selected": {
          backgroundColor: theme.brand.secondary[20],
        },
        "&:hover": {
          bgColor: theme.common.background.gray40,
        },
      },
    };
  }, [theme]);
};
