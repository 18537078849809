import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetHrInterview } from "../../../../../../../store/hooks/hrInterview";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { EditInterviewContent } from "../../../../../../organisms/hr-interviews/interviews/$interviewId/edit";

const EditInterviewPage: React.FC = () => {
  const { interviewId } = Route.useParams<{ interviewId: string }>();
  const interviewDetailQueryState = useGetHrInterview(interviewId);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "在職者音声一覧", to: "/hr-interviews/interviews" },
          {
            title: "在籍者面談詳細",
            to: "/hr-interviews/interviews/$interviewId",
            params: { interviewId: interviewId },
          },
        ]}
        currentTitle={"面談概要編集"}
      />
      <WaitForSuccess queryState={interviewDetailQueryState}>
        {(interviewDetail) => <EditInterviewContent interviewDetail={interviewDetail} />}
      </WaitForSuccess>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/hr-interviews/interviews/$interviewId/edit")({
  component: EditInterviewPage,
});
