import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

import { AmeFormButtonsLayout, AmeFormButtonsLayoutProps } from "./AmeFormButtonsLayout";

interface Props extends AmeFormButtonsLayoutProps {
  extraButtons?: React.ReactNode;
}

/**
 * フォーム用の追従ボタンレイアウト。
 * 以下のケースで使用する。
 * - フォームの項目数が多く、アクションボタンまで遠い場合。
 * - 保存・キャンセル以外のアクションボタンを置き、ユーザーを誘導したい場合。
 */
export const AmeFormFollowingButtonsLayout: React.FC<Props> = ({ extraButtons, ...otherProps }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        position: "sticky",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "16px",
        backgroundColor: theme.common.background.white,
        bottom: 0,
        borderTop: `1px solid ${theme.common.border.gray40}`,
        zIndex: theme.zIndex.header,
      }}
    >
      <AmeBox sx={{ display: "flex", maxWidth: "750px", width: "100%" }}>
        <AmeFormButtonsLayout {...otherProps} />
        {extraButtons !== undefined ? <AmeBox sx={{ marginLeft: "auto" }}>{extraButtons}</AmeBox> : null}
      </AmeBox>
    </AmeBox>
  );
};
