import React, { useCallback, useMemo, useState } from "react";

import { TextField } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useBlocker } from "@tanstack/react-router";
import { Controller, useForm } from "react-hook-form";

import { useAmeSound } from "../../../../../hooks/useAmeSound";
import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { TranscriptionSentence } from "../../../../../models/Transcription";
import { useUpdateTranscription } from "../../../../../store/hooks/transcription";
import { AmeCheckbox } from "../../../../atoms/checkbox/AmeCheckbox";
import { ChoosableList } from "../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../atoms/list/ChoosableListItem";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeFormButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { AmeDialog } from "../../../../molecules/dialog/AmeDialog";
import { AmeDropbox_old } from "../../../../molecules/dropbox/AmeDropbox";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { TranscriptionContainer } from "../TranscriptionChecker/TranscriptionContainer";
import { TranscriptionRowBorder } from "../TranscriptionChecker/TranscriptionRowBorder";
import { TranscriptionRowContainer } from "../TranscriptionChecker/TranscriptionRowContainer";

import { EditorFormType } from "./EditorFormType";
import { TranscriptionEditorDialogContent } from "./TranscriptionEditorDialogContent";

type Props = {
  sound: Howl;
  transcriptions: TranscriptionSentence[];
  speakers: number;
  currentSpeakerLabel?: string;
  speakerMap: Map<string, number>;
  interviewRecordingId: string;
};

export const TranscriptionEditor: React.FC<Props> = ({
  sound,
  transcriptions,
  speakers,
  currentSpeakerLabel,
  speakerMap,
  interviewRecordingId,
}) => {
  const navigate = useNavigate();
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const [submitted, setSubmitted] = useState(false);
  const ameSound = useAmeSound(sound);
  const defaultTargetSpeaker = useMemo(() => {
    const speakerArray = Array.from(speakerMap.entries());
    const current = speakerArray.find(([, value]) => value == speakerMap.get(currentSpeakerLabel!))?.[0];
    return current || speakerArray[0]?.[0];
  }, [currentSpeakerLabel, speakerMap]);
  useBlocker({
    blockerFn: () => window.confirm("変更内容が保存されない可能性があります"),
    condition: !submitted,
  });
  const defaultTargetRange: [number, number] = useMemo(() => {
    const lower = Math.max(
      0,
      transcriptions.findIndex((t) => t.enabled),
    );
    const upper = Math.min(
      transcriptions.length - 1,
      transcriptions.length - 1 - [...transcriptions].reverse().findIndex((t) => t.enabled),
    );
    return [lower, upper];
  }, [transcriptions]);
  const { register, handleSubmit, control, setValue, watch } = useForm<EditorFormType>({
    defaultValues: {
      transcriptions: transcriptions,
      targetSpeaker: defaultTargetSpeaker,
      targetRange: defaultTargetRange,
    },
  });

  const [isOpen, setIsOpen] = useState(false);

  const updateTranscriptionRaw = useUpdateTranscription();
  const updateSentences = useCallback(
    async (data: EditorFormType) => {
      const res = await updateTranscriptionRaw({
        interviewRecordingId: interviewRecordingId,
        updateTranscriptionRequestBody: {
          results: {
            speakers: speakers,
            sentences: data.transcriptions.map((t) => ({
              speakerLabel: t.speakerLabel,
              text: t.text,
              enabled: t.enabled,
              endTime: t.endTime,
              startTime: t.startTimeSec,
            })),
          },
          retiredSpeakerLabel: data.targetSpeaker,
        },
      });
      if (res.isSuccess) {
        enqueueSuccessSnackbar({ title: "文字起こしの保存に成功しました。" });
      }
    },
    [enqueueSuccessSnackbar, interviewRecordingId, speakers, updateTranscriptionRaw],
  );

  const reflectSentences = useCallback(
    async (data: EditorFormType) => {
      setSubmitted(true);
      const result = await updateTranscriptionRaw({
        interviewRecordingId: interviewRecordingId,
        updateTranscriptionRequestBody: {
          results: {
            speakers: speakers,
            sentences: data.transcriptions.map((t) => ({
              speakerLabel: t.speakerLabel,
              text: t.text,
              enabled: t.enabled,
              endTime: t.endTime,
              startTime: t.startTimeSec,
            })),
          },
          retiredSpeakerLabel: data.targetSpeaker,
          text: data.transcriptions
            .filter(({ enabled }) => enabled)
            .map(({ text }) => text)
            .join("\n"),
        },
      });
      if (result.isSuccess) {
        enqueueSuccessSnackbar({ title: "文字起こしを反映しました。" });
        await navigate({ to: `/interview-files/${interviewRecordingId}/transcription?check=true` });
      }
    },
    [updateTranscriptionRaw, interviewRecordingId, speakers, enqueueSuccessSnackbar, navigate],
  );

  const onSave = useCallback(
    async (data: EditorFormType) => {
      if (data.targetRange[0] < 0) {
        throw new Error("開始位置が選択されていません。");
      }
      if (data.targetRange[1] < 0) {
        throw new Error("終了位置が選択されていません。");
      }
      if (data.targetRange[1] < data.targetRange[0]) {
        throw new Error("終了位置が開始位置より前です。");
      }
      if (transcriptions.length <= data.targetRange[1]) {
        throw new Error("終了位置がセンテンス列の長さを超えています。");
      }
      await updateSentences(data);
    },
    [transcriptions.length, updateSentences],
  );

  const startRange = watch("targetRange.0");
  const endRange = watch("targetRange.1");
  const targetSpeaker = watch("targetSpeaker");
  const setEnabled = useCallback(
    ({ start, end, target }: { start?: number; end?: number; target?: string }) => {
      for (let i = 0; i < transcriptions.length; i++) {
        setValue(`transcriptions.${i}.enabled`, false);
        if ((start || startRange) <= i && i <= (end || endRange)) {
          if (transcriptions[i].speakerLabel == (target || targetSpeaker)) {
            setValue(`transcriptions.${i}.enabled`, true);
          }
        }
      }
    },
    [startRange, endRange, targetSpeaker, setValue, transcriptions],
  );

  const selectTargetRange = useCallback(
    (selected: { start?: number; end?: number }) => {
      if (selected.start !== undefined) {
        setEnabled({ start: selected.start });
        setValue("targetRange.0", selected.start);
      }
      if (selected.end !== undefined) {
        setEnabled({ end: selected.end });
        setValue("targetRange.1", selected.end);
      }
    },
    [setValue, setEnabled],
  );

  return (
    <>
      <AmeDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <TranscriptionEditorDialogContent
          open={true}
          title="本当に反映しますか？"
          onSubmit={handleSubmit(reflectSentences)}
          onClose={() => {
            setIsOpen(false);
          }}
        ></TranscriptionEditorDialogContent>
      </AmeDialog>

      <AmeBox sx={{ display: "flex", alignItems: "center", "& > * + *": { marginLeft: "24px" } }}>
        <AmeTypography component={"h4"}>退職者を選択</AmeTypography>

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <AmeBox sx={{ width: 120 }}>
              <AmeDropbox_old
                size={"small"}
                value={value}
                displaySelected={value ? `話者${speakerMap.get(value)}` : "選択なし"}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onChange={(e) => {
                  onChange(e);
                  setEnabled({ target: e.target.value?.toString() });
                }}
              >
                <ChoosableList>
                  {Array.from(speakerMap.entries()).map(([label, v]) => (
                    <ChoosableListItem value={label} key={v}>
                      話者{v}
                    </ChoosableListItem>
                  ))}
                </ChoosableList>
              </AmeDropbox_old>
            </AmeBox>
          )}
          name={"targetSpeaker"}
        />
        <AmeTypography component={"h4"}>範囲を選択</AmeTypography>
        <AmeBox sx={{ display: "flex", alignItems: "center", "& > * + *": { marginLeft: "16px" } }}>
          <Controller
            name={"targetRange.0"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AmeDropbox_old
                size={"small"}
                value={value}
                displaySelected={value >= 0 ? value : "指定なし"}
                onChange={onChange}
              >
                <ChoosableList>
                  <ChoosableListItem value={-1}>指定なし</ChoosableListItem>
                  {transcriptions.map((_, i) => (
                    <ChoosableListItem value={i} key={i}>
                      {i}
                    </ChoosableListItem>
                  ))}
                </ChoosableList>
              </AmeDropbox_old>
            )}
          />
          <span>~</span>
          <Controller
            name={"targetRange.1"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AmeDropbox_old
                size={"small"}
                value={value}
                displaySelected={value >= 0 ? value : "指定なし"}
                onChange={onChange}
              >
                <ChoosableList>
                  <ChoosableListItem value={-1}>指定なし</ChoosableListItem>
                  {transcriptions.map((_, i) => (
                    <ChoosableListItem value={i} key={i}>
                      {i}
                    </ChoosableListItem>
                  ))}
                </ChoosableList>
              </AmeDropbox_old>
            )}
          />
        </AmeBox>
      </AmeBox>
      <TranscriptionContainer sound={ameSound}>
        {transcriptions.map((t, i) => (
          <React.Fragment key={i}>
            <TranscriptionRowBorder select={selectTargetRange} index={i} lastIndex={transcriptions.length} />
            <TranscriptionRowContainer
              index={i}
              isPlaying={ameSound.isPlaying}
              isTarget={t.enabled}
              play={(sec: number) => ameSound.togglePlay(sec)}
              second={t.startTimeSec}
              speakerLabel={speakerMap.get(t.speakerLabel)?.toString() || "0"}
            >
              <AmeBox sx={{ display: "flex" }}>
                <TextField
                  multiline
                  {...register(`transcriptions.${i}.text`)}
                  onFocus={() => ameSound.playIfAutoPlayIsEnabled(t.startTimeSec)}
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    width: "100%",
                    margin: "0",
                    "& > .MuiInputBase-root": {
                      height: "100%",
                      padding: "0",
                      "& > .MuiInputBase-input": {
                        minHeight: "100%",
                      },
                      "& > .MuiOutlinedInput-notchedOutline": { border: "unset" },
                      "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid",
                        margin: "-4px",
                      },
                    },
                  }}
                />
                <Controller
                  name={`transcriptions.${i}.enabled`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <AmeCheckbox
                        disabled={watch("targetSpeaker") !== t.speakerLabel}
                        {...field}
                        checked={field.value}
                      />
                    );
                  }}
                />
              </AmeBox>
            </TranscriptionRowContainer>
          </React.Fragment>
        ))}
        <TranscriptionRowBorder index={transcriptions.length} lastIndex={transcriptions.length} />
      </TranscriptionContainer>
      <AmeFormButtonsLayout
        onPrimary={() => setIsOpen(true)}
        primaryText="反映"
        onSecondary={handleSubmit(onSave)}
        secondaryText="一時保存"
      />
    </>
  );
};
