/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddEmployeeDefinitionRequestBody
 */
export interface AddEmployeeDefinitionRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AddEmployeeDefinitionRequestBody
     */
    segmentValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AddEmployeeDefinitionRequestBody
     */
    attributeValue: string;
    /**
     * 
     * @type {string}
     * @memberof AddEmployeeDefinitionRequestBody
     */
    attributeKey: string;
}

export function AddEmployeeDefinitionRequestBodyFromJSON(json: any): AddEmployeeDefinitionRequestBody {
    return AddEmployeeDefinitionRequestBodyFromJSONTyped(json, false);
}

export function AddEmployeeDefinitionRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddEmployeeDefinitionRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'segmentValue': !exists(json, 'segmentValue') ? undefined : json['segmentValue'],
        'attributeValue': json['attributeValue'],
        'attributeKey': json['attributeKey'],
    };
}

export function AddEmployeeDefinitionRequestBodyToJSON(value?: AddEmployeeDefinitionRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'segmentValue': value.segmentValue,
        'attributeValue': value.attributeValue,
        'attributeKey': value.attributeKey,
    };
}


