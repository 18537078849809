import React from "react";

import { SubCategoryModel } from "../../../../../../models/SubCategory";
import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { HorizontalStackedBarChart } from "../../../../../atoms/charts/HorizontalStackedBarChart";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  interviewDetail: HrInterviewDetail;
  selectedSubCategory?: SubCategoryModel;
}

export const InterviewAnalysisScore: React.FC<Props> = ({ interviewDetail, selectedSubCategory }) => {
  const theme = useAmeTheme();

  if (interviewDetail.analysisStatus !== "ANALYZED") {
    return null;
  }

  return (
    <PaperWrapper>
      <PaperHeader>AIによる解析結果</PaperHeader>
      <PaperBody>
        <AmeBox sx={{ position: "relative" }}>
          <HorizontalStackedBarChart
            series={[
              {
                name: "",
                data:
                  interviewDetail.analysisScores?.map(({ score, displayName }) => ({
                    name: displayName,
                    value: score,
                  })) || [],
              },
            ]}
            sortByfirstSeries
            highlightColumnNames={selectedSubCategory ? [selectedSubCategory.displayName] : undefined}
          />
          {interviewDetail.analysisScores?.length === 0 ? (
            <AmeBox
              sx={{
                backgroundColor: theme.common.background.gray40,
                opacity: 0.8,
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              ヒットするネガティブな要因が無いためグラフが表示できません。
            </AmeBox>
          ) : null}
        </AmeBox>
      </PaperBody>
    </PaperWrapper>
  );
};
