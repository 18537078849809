/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TurnoverStatsWithForecastResponse,
    TurnoverStatsWithForecastResponseFromJSON,
    TurnoverStatsWithForecastResponseFromJSONTyped,
    TurnoverStatsWithForecastResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SegmentTurnoverStatsWithForecastResponse
 */
export interface SegmentTurnoverStatsWithForecastResponse {
    /**
     * 
     * @type {TurnoverStatsWithForecastResponse}
     * @memberof SegmentTurnoverStatsWithForecastResponse
     */
    stats: TurnoverStatsWithForecastResponse;
    /**
     * 
     * @type {string}
     * @memberof SegmentTurnoverStatsWithForecastResponse
     */
    label: string;
}

export function SegmentTurnoverStatsWithForecastResponseFromJSON(json: any): SegmentTurnoverStatsWithForecastResponse {
    return SegmentTurnoverStatsWithForecastResponseFromJSONTyped(json, false);
}

export function SegmentTurnoverStatsWithForecastResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SegmentTurnoverStatsWithForecastResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stats': TurnoverStatsWithForecastResponseFromJSON(json['stats']),
        'label': json['label'],
    };
}

export function SegmentTurnoverStatsWithForecastResponseToJSON(value?: SegmentTurnoverStatsWithForecastResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stats': TurnoverStatsWithForecastResponseToJSON(value.stats),
        'label': value.label,
    };
}


