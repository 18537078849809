import React, { useMemo } from "react";

import { removeUndefinedDeep } from "@amecloud/common-utils/src/utils/ObjectUtils";
import { ApexOptions } from "apexcharts";

import { formatToPercent } from "../../../utils/formatNumber";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";

import { AmeChart } from "./AmeChart";

interface Props {
  series: number[];
  series2?: number[];
  seriesLabels: string[];
  names?: string[];
  max?: number;
  min?: number;
  height?: number;
  width?: number;
  title?: string;
  formatter?: (value: number | null) => string;
}

export const LineChart: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const columnSeries = useMemo(() => {
    if (props.series.length !== props.seriesLabels.length) {
      throw new Error("series and seriesLabels should have the same length");
    }
    if (props.names && props.series2 && props.names.length < 2) {
      throw new Error("names should have at least 2 elements");
    }
    if (!props.series2) {
      return [
        {
          type: "line",
          name: props.names ? props.names[0] : "",
          data: props.series,
          color: theme.brand.secondary[100] ?? "#00ACC1",
        },
      ];
    } else {
      return [
        {
          type: "line",
          name: props.names ? props.names[0] : "",
          data: props.series,
          color: theme.brand.secondary[40] ?? "#B2EBF2",
        },
        {
          type: "line",
          name: props.names ? props.names[1] : "",
          data: props.series2,
          color: theme.brand.secondary[100] ?? "#00ACC1",
        },
      ];
    }
  }, [props.series, props.series2, props.names, props.seriesLabels, theme]);

  const options: ApexOptions = useMemo(() => {
    const ops: ApexOptions = {
      stroke: {
        // X軸系列が1つしかない場合、謎の縦線が表示されるので、strokeを非表示にする
        width: columnSeries[0]?.data.length > 1 ? 3 : 0,
      },
      markers: {
        size: 5,
        colors: theme.common.text.white,
        strokeColors: columnSeries.map((cs) => cs.color),
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        tickPlacement: "between",
      },
      yaxis: {
        max: props.max ?? 1,
        min: props.min ?? 0,
        labels: {
          formatter: (value) => {
            if (props.formatter) {
              return props.formatter(value);
            }
            return formatToPercent(value);
          },
        },
      },
      labels: props.seriesLabels,
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
    };
    return removeUndefinedDeep(ops);
  }, [theme.common.text.white, columnSeries, props]);

  return (
    <AmeChart
      height={props.height}
      width={props.width}
      title={props.title}
      options={options}
      columnSeries={columnSeries}
    />
  );
};
