import React, { useEffect } from "react";

import { DropzoneInputProps, DropzoneRootProps, ErrorCode, FileRejection } from "react-dropzone";

import { useHrPentestSnackbar } from "../../../hooks/useHrPentestSnackbar";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeButton } from "../../atoms/button/AmeButton";
import { Spacer } from "../../atoms/spacers/Spacer";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  fileRejection?: FileRejection;
  helperText?: string;
  buttonText?: string;
  extraText?: string;
}

export const FileDropZone: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  useEffect(() => {
    if (props.fileRejection !== undefined) {
      props.fileRejection.errors.forEach((error) => {
        if (error.code === ErrorCode.FileInvalidType) {
          enqueueErrorSnackbar({
            title: "ファイルの拡張子が不正です。",
            message: "ファイルの拡張子を確認してください。",
          });
        }
        if (error.code === ErrorCode.FileTooLarge) {
          enqueueErrorSnackbar({
            title: "ファイルのサイズが大きすぎます。",
            message: "ファイルのサイズを確認してください。",
          });
        }
      });
    }
  }, [props.fileRejection, enqueueErrorSnackbar]);

  return (
    <AmeBox
      {...props.getRootProps()}
      sx={{
        padding: "32px 24px",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        outline: "none",
        background: theme.common.background.gray20,
        border: "2px dashed #ddd",
        borderRadius: "4px",
        opacity: 1,
        cursor: "pointer",
      }}
    >
      <input {...props.getInputProps()} />
      <AmeTypography>{props.helperText ?? "ここにドラッグ＆ドロップまたは"}</AmeTypography>
      <Spacer height="8px" />
      <AmeButton variant="outlined">{props.buttonText ?? "ファイルを選択"}</AmeButton>
      {props.extraText && (
        <>
          <Spacer height="12px" />
          <AmeTypography fontSize="caption">{props.extraText}</AmeTypography>
        </>
      )}
    </AmeBox>
  );
};
