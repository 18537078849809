import React from "react";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { defaultApi } from "../../../../../../store/defaultApi";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { Update } from "../../../../../atoms/icon/Update";

type Props = {
  interviewDetail: HrInterviewDetail;
};

export const InterviewUpdateButton: React.FC<Props> = ({ interviewDetail }) => {
  const { refetch } = defaultApi.endpoints.getHrInterview.useQuerySubscription({
    hrInterviewId: interviewDetail.hrInterviewId,
  });
  return (
    <AmeButton variant="outlined" size="small" onClick={refetch}>
      <Update color={"inherit"} />
    </AmeButton>
  );
};
