import { InterviewFile, InterviewFileList } from "../../models/InterviewFile";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useGetInterviewFilesQuery = (count: number, offset: number, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewFiles.useQuery(
    { count, offset },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (res): InterviewFileList => ({
      totalCount: res.totalCount,
      interviewFiles: res.interviewFiles.map(InterviewFile.fromResponse),
    }),
    onError: options?.onError,
  });
};

export const useGetInterviewFileQuery = (interviewFileId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewFile.useQuery(
    { interviewRecordingId: interviewFileId },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: InterviewFile.fromResponse,
    onError: options?.onError,
  });
};

export const useCreateInterviewFiles = (options?: UseMutationOptions) => {
  const [createInterviewFiles] = defaultApi.endpoints.createInterviewFiles.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createInterviewFiles,
    onError: options?.onError,
  });
};

export const useUpdateInterviewFile = (options?: UseMutationOptions) => {
  const [updateInterviewFile] = defaultApi.endpoints.updateInterviewFile.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateInterviewFile,
    onError: options?.onError,
  });
};

export const useCreateInterviewAudioFileUploadUrlsLazyQuery = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.createInterviewAudioFileUploadUrls.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};
export const useCompleteMultipartUpload = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.completeInterviewFileMultipartUpload.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};
export const useGetInterviewAudioFileUrl = (interviewFileId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewAudioFileUrl.useQuery(
    { interviewFileId: interviewFileId },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (res) => res,
    onError: options?.onError,
  });
};

export const useGetInterviewAudioFileUrlLazyQuery = () => {
  return defaultApi.endpoints.getInterviewAudioFileUrl.useLazyQuery();
};

export const useDeleteInterviewFile = (options?: UseMutationOptions) => {
  const [rawMutation] = defaultApi.endpoints.deleteInterviewFile.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
    onError: options?.onError,
  });
};

export const useRebuildDurationOfInterviewFiles = () => {
  const [rawMutation] = defaultApi.endpoints.rebuildDurationOfInterviewFiles.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: rawMutation,
  });
};
