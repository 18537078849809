/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SuggestionAlgorithmRelatedToSubCategory {
    FREQUENT_WORD_IN_SUB_CATEGORY = 'frequentWordInSubCategory',
    FREQUENT_WORD_NOT_IN_SUB_CATEGORY = 'frequentWordNotInSubCategory'
}

export function SuggestionAlgorithmRelatedToSubCategoryFromJSON(json: any): SuggestionAlgorithmRelatedToSubCategory {
    return SuggestionAlgorithmRelatedToSubCategoryFromJSONTyped(json, false);
}

export function SuggestionAlgorithmRelatedToSubCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuggestionAlgorithmRelatedToSubCategory {
    return json as SuggestionAlgorithmRelatedToSubCategory;
}

export function SuggestionAlgorithmRelatedToSubCategoryToJSON(value?: SuggestionAlgorithmRelatedToSubCategory | null): any {
    return value as any;
}

