import React from "react";

import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  label: string;
  children: React.ReactNode;
}

export const SurveyDetailContentItem: React.FC<Props> = (props) => {
  return (
    <AmeBox>
      <AmeTypography fontSize="caption">{props.label}</AmeTypography>
      <Spacer height="6px" />
      <AmeTypography>{props.children}</AmeTypography>
    </AmeBox>
  );
};
