/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SegmentKey,
    SegmentKeyFromJSON,
    SegmentKeyFromJSONTyped,
    SegmentKeyToJSON,
    SegmentTurnoverStatsWithForecastResponse,
    SegmentTurnoverStatsWithForecastResponseFromJSON,
    SegmentTurnoverStatsWithForecastResponseFromJSONTyped,
    SegmentTurnoverStatsWithForecastResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateNoteRequestBody
 */
export interface CreateNoteRequestBody {
    /**
     * 
     * @type {Array<SegmentTurnoverStatsWithForecastResponse>}
     * @memberof CreateNoteRequestBody
     */
    turnoverSummaries?: Array<SegmentTurnoverStatsWithForecastResponse>;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestBody
     */
    surveyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestBody
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestBody
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequestBody
     */
    segmentValue?: string;
    /**
     * 
     * @type {SegmentKey}
     * @memberof CreateNoteRequestBody
     */
    segmentKey?: SegmentKey;
}

export function CreateNoteRequestBodyFromJSON(json: any): CreateNoteRequestBody {
    return CreateNoteRequestBodyFromJSONTyped(json, false);
}

export function CreateNoteRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNoteRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'turnoverSummaries': !exists(json, 'turnoverSummaries') ? undefined : ((json['turnoverSummaries'] as Array<any>).map(SegmentTurnoverStatsWithForecastResponseFromJSON)),
        'surveyId': !exists(json, 'surveyId') ? undefined : json['surveyId'],
        'text': json['text'],
        'title': json['title'],
        'segmentValue': !exists(json, 'segmentValue') ? undefined : json['segmentValue'],
        'segmentKey': !exists(json, 'segmentKey') ? undefined : SegmentKeyFromJSON(json['segmentKey']),
    };
}

export function CreateNoteRequestBodyToJSON(value?: CreateNoteRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'turnoverSummaries': value.turnoverSummaries === undefined ? undefined : ((value.turnoverSummaries as Array<any>).map(SegmentTurnoverStatsWithForecastResponseToJSON)),
        'surveyId': value.surveyId,
        'text': value.text,
        'title': value.title,
        'segmentValue': value.segmentValue,
        'segmentKey': SegmentKeyToJSON(value.segmentKey),
    };
}


