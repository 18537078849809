import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetInterviewFormats, useGetInterviewType } from "../../../../../../../../store/hooks/hr-interview-setting";
import { BreadcrumbPageLayoutHeader } from "../../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../../../../molecules/Loading/WaitForSuccess";
import { HrInterviewFormatsSettingContent } from "../../../../../../../organisms/settings/hr-interview-types/hr-interview-types/formats/HrInterviewFormatsSettingContent";

const HrInterviewFormats: React.FC = () => {
  const { hrInterviewTypeId } = Route.useParams();
  const interviewFormatsQueryState = useGetInterviewFormats(hrInterviewTypeId);
  const interviewTypeQueryState = useGetInterviewType(hrInterviewTypeId);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        currentTitle={"議事録フォーマット設定"}
        items={[
          { title: "設定", to: "/settings" },
          { title: "在籍者面談詳細", to: "/settings/hr-interview-types" },
        ]}
      />

      <WaitForSuccess queryState={interviewTypeQueryState}>
        {(interviewType) => (
          <WaitForSuccess queryState={interviewFormatsQueryState}>
            {(interviewFormats) => (
              <HrInterviewFormatsSettingContent
                defaultInterviewFormats={interviewFormats}
                title={interviewType.hrInterviewTypeText}
                hrInterviewTypeId={hrInterviewTypeId}
              />
            )}
          </WaitForSuccess>
        )}
      </WaitForSuccess>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-admin/settings/hr-interview-types/$hrInterviewTypeId/formats/",
)({
  component: HrInterviewFormats,
});
