import React, { forwardRef, ForwardedRef } from "react";

import type { ChangeHandler } from "react-hook-form";

import { AmeBox } from "../../muiWrapper/AmeBox";
import { RadioButtonChecked } from "../icon/RadioButtonChecked";
import { RadioButtonEmpty } from "../icon/RadioButtonEmpty";

type Props = {
  name: string;
  value: string;
  label: string;
  onChange?: ChangeHandler;
  ref: ForwardedRef<HTMLInputElement>;
  checked?: boolean;
};

export const AmeRadio = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <>
      <label style={{ display: "flex", gap: "8px" }}>
        <AmeBox
          sx={{
            // inputのstyle変えるのだるいので、SVGで切り替えるハック
            "&:has(input:checked) ~ .unChecked": { display: "none" },
            ":not(&:has(input:checked)) ~ .checked": { display: "none" },
          }}
        >
          <input
            type={"radio"}
            hidden
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            checked={props.checked}
            ref={ref}
          />
        </AmeBox>
        <span className="checked">
          <RadioButtonChecked />
        </span>
        <span className="unChecked">
          <RadioButtonEmpty />
        </span>

        {props.label}
      </label>
    </>
  );
});
AmeRadio.displayName = "AmeRadio";
