import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import type { UserGroup } from "../../../../../../../models/UserGroup";
import { AmeInput } from "../../../../../../atoms/forms/input/AmeInput";
import { Search } from "../../../../../../atoms/icon/Search";
import { ChoosableList } from "../../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../../atoms/list/ChoosableListItem";
import { AmeDropbox_old } from "../../../../../../molecules/dropbox/AmeDropbox";

interface Props {
  value?: UserGroup;
  placeholder?: string;
  getSuggests: (search: string) => Promise<UserGroup[]>;
  onSubmit: (suggest: UserGroup | undefined) => void;
}

export const LaptopBelongingUserGroupSearchDropDown: React.FC<Props> = ({
  value,
  placeholder,
  getSuggests,
  onSubmit,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [suggests, setSuggests] = useState<UserGroup[]>([]);

  const onChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [setSearchQuery],
  );

  useEffect(() => {
    (async () => {
      if (searchQuery === "") {
        setSuggests([]);
        return;
      }
      const suggestsResult = await getSuggests(searchQuery);
      setSuggests(suggestsResult);
    })();
  }, [getSuggests, searchQuery]);

  return (
    <AmeDropbox_old
      value={value}
      displaySelected={value?.displayName}
      onChange={(e) => onSubmit(e.target.value)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      inputComponent={
        <AmeInput
          placeholder={placeholder}
          value={searchQuery}
          onChange={onChangeInput}
          endIcon={<Search size={16} />}
        />
      }
    >
      <ChoosableList>
        {suggests.map((suggest) => (
          <ChoosableListItem key={suggest.userGroupId} value={suggest}>
            {suggest.displayName}
          </ChoosableListItem>
        ))}
        <ChoosableListItem>選択なし</ChoosableListItem>
      </ChoosableList>
    </AmeDropbox_old>
  );
};
