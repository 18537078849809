import React, { useMemo } from "react";

import { Column, useTable } from "react-table";

import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props<T extends Record<string, any>> {
  data: T[];
  columns: Column<T>[];
  addtionalRow?: React.ReactNode;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DataTable = <T extends Record<string, any>>(props: Props<T>) => {
  const data = useMemo(() => props.data, [props.data]);
  const columns = useMemo(() => props.columns, [props.columns]);
  const tableInstance = useTable({ data, columns });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <AmeTable {...getTableProps()} useCustomCellWidth>
      <AmeTableHead>
        {headerGroups.map((headerGroup) => {
          const { key: headerGroupKey, ...otherHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <AmeTableRow key={headerGroupKey} {...otherHeaderGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key: columnKey, ...otherColumnKeyProps } = column.getHeaderProps();
                return (
                  <AmeTableHeadCell key={columnKey} {...otherColumnKeyProps} width={column.width}>
                    {column.render("Header")}
                  </AmeTableHeadCell>
                );
              })}
            </AmeTableRow>
          );
        })}
      </AmeTableHead>
      <AmeTableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const { key: rowKey, ...otherProps } = row.getRowProps();
          return (
            <AmeTableRow key={rowKey} {...otherProps}>
              {row.cells.map((cell) => {
                const { key: cellKey, ...otherCellProps } = cell.getCellProps();
                return (
                  <AmeTableBodyCell
                    key={cellKey}
                    {...otherCellProps}
                    noPadding={cell.column.id === "subCategoryId" || cell.column.id === "delete-button"}
                  >
                    {cell.render("Cell")}
                  </AmeTableBodyCell>
                );
              })}
            </AmeTableRow>
          );
        })}
        {props.addtionalRow}
      </AmeTableBody>
    </AmeTable>
  );
};
