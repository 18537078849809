import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  noPadding?: boolean;
  children: React.ReactNode;
};
export const SimplePaper: React.FC<Props> = ({ noPadding, children }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        ...(noPadding ? {} : { padding: "24px" }),
        bgcolor: theme.common.background.white,
        border: `1px solid ${theme.common.border.gray40}`,
        boxShadow: theme.common.shadow.main,
        borderRadius: "4px",
      }}
    >
      {children}
    </AmeBox>
  );
};
