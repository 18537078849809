/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewReportScore,
    InterviewReportScoreFromJSON,
    InterviewReportScoreFromJSONTyped,
    InterviewReportScoreToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewReportScoreListByAxis
 */
export interface InterviewReportScoreListByAxis {
    /**
     * 
     * @type {Array<InterviewReportScore>}
     * @memberof InterviewReportScoreListByAxis
     */
    scores: Array<InterviewReportScore>;
    /**
     * 
     * @type {string}
     * @memberof InterviewReportScoreListByAxis
     */
    axisName: string;
}

export function InterviewReportScoreListByAxisFromJSON(json: any): InterviewReportScoreListByAxis {
    return InterviewReportScoreListByAxisFromJSONTyped(json, false);
}

export function InterviewReportScoreListByAxisFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewReportScoreListByAxis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scores': ((json['scores'] as Array<any>).map(InterviewReportScoreFromJSON)),
        'axisName': json['axisName'],
    };
}

export function InterviewReportScoreListByAxisToJSON(value?: InterviewReportScoreListByAxis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scores': ((value.scores as Array<any>).map(InterviewReportScoreToJSON)),
        'axisName': value.axisName,
    };
}


