import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import {
  useGetHrInterview,
  useGetPresignedUrlToGetHrInterviewFile,
} from "../../../../../../../store/hooks/hrInterview";
import { BreadcrumbPageLayoutHeader } from "../../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../../atoms/layout/PageLayoutWrapper";
import { WaitForFetch } from "../../../../../../molecules/Loading/WaitForFetch";
import { WaitForSuccess } from "../../../../../../molecules/Loading/WaitForSuccess";
import { TranscriptionContent } from "../../../../../../organisms/hr-interviews/interviews/$interviewId/transcribe/TranscriptionContent";

const InterviewTranscribePage: React.FC = () => {
  const { interviewId } = Route.useParams();
  const hrInterviewQuery = useGetHrInterview(interviewId);
  const getPresignedUrlQuery = useGetPresignedUrlToGetHrInterviewFile(hrInterviewQuery.data?.interviewFileId);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[
          { title: "在職者音声一覧", to: "/hr-interviews/interview-files" },
          { title: "在職者面談詳細", to: "../" },
        ]}
        currentTitle={"議事録編集"}
      />
      <WaitForFetch queryState={getPresignedUrlQuery}>
        {(url) => (
          <WaitForSuccess queryState={hrInterviewQuery}>
            {(hrInterview) => <TranscriptionContent hrInterview={hrInterview} interviewFileUrl={url} />}
          </WaitForSuccess>
        )}
      </WaitForFetch>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute(
  "/_authenticated/_authorized-for-all/hr-interviews/interviews/$interviewId/transcribe",
)({
  component: InterviewTranscribePage,
});
