/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterInterviewFormatContentsRequestBodyFormatContents
 */
export interface RegisterInterviewFormatContentsRequestBodyFormatContents {
    /**
     * 
     * @type {number}
     * @memberof RegisterInterviewFormatContentsRequestBodyFormatContents
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterInterviewFormatContentsRequestBodyFormatContents
     */
    formatContent: string;
    /**
     * idがある場合は更新、ない場合は新規作成
     * @type {string}
     * @memberof RegisterInterviewFormatContentsRequestBodyFormatContents
     */
    interviewFormatContentId?: string;
}

export function RegisterInterviewFormatContentsRequestBodyFormatContentsFromJSON(json: any): RegisterInterviewFormatContentsRequestBodyFormatContents {
    return RegisterInterviewFormatContentsRequestBodyFormatContentsFromJSONTyped(json, false);
}

export function RegisterInterviewFormatContentsRequestBodyFormatContentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterInterviewFormatContentsRequestBodyFormatContents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': json['order'],
        'formatContent': json['formatContent'],
        'interviewFormatContentId': !exists(json, 'interviewFormatContentId') ? undefined : json['interviewFormatContentId'],
    };
}

export function RegisterInterviewFormatContentsRequestBodyFormatContentsToJSON(value?: RegisterInterviewFormatContentsRequestBodyFormatContents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': value.order,
        'formatContent': value.formatContent,
        'interviewFormatContentId': value.interviewFormatContentId,
    };
}


