import React from "react";

import { SvgIconProps, SvgWrapper } from "./index";

export const AudioFile: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox={"0 0 16 16"}>
      <g>
        <path
          d="M11 6.63962C11 6.48762 10.9653 6.33761 10.8987 6.20101C10.832 6.06441 10.735 5.9448 10.6152 5.85129C10.4954 5.75777 10.3558 5.69281 10.2071 5.66133C10.0584 5.62985 9.90445 5.63268 9.757 5.66962L8.757 5.91962C8.54075 5.97379 8.34881 6.09867 8.21166 6.27441C8.0745 6.45016 8.00001 6.66669 8 6.88962V11.1956C7.68307 11.0642 7.34307 10.9976 7 10.9996C6.5 10.9996 6.026 11.1336 5.662 11.3766C5.302 11.6166 5 12.0046 5 12.4996C5 12.9946 5.301 13.3826 5.662 13.6226C6.026 13.8656 6.501 13.9996 7 13.9996C7.5 13.9996 7.974 13.8656 8.338 13.6226C8.698 13.3826 9 12.9946 9 12.4996V8.88962L11 8.38962V6.63962Z"
          fill="currentColor"
        />
        <path
          d="M14 14V4.5L9.5 0H4C3.46957 0 2.96086 0.210714 2.58579 0.585786C2.21071 0.960859 2 1.46957 2 2V14C2 14.5304 2.21071 15.0391 2.58579 15.4142C2.96086 15.7893 3.46957 16 4 16H12C12.5304 16 13.0391 15.7893 13.4142 15.4142C13.7893 15.0391 14 14.5304 14 14ZM9.5 3C9.5 3.39782 9.65804 3.77936 9.93934 4.06066C10.2206 4.34196 10.6022 4.5 11 4.5H13V14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15H4C3.73478 15 3.48043 14.8946 3.29289 14.7071C3.10536 14.5196 3 14.2652 3 14V2C3 1.73478 3.10536 1.48043 3.29289 1.29289C3.48043 1.10536 3.73478 1 4 1H9.5V3Z"
          fill="currentColor"
        />
      </g>
    </SvgWrapper>
  );
};
