import React, { useCallback, useState } from "react";

import { Link, useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { UserGroup } from "../../../../../models/UserGroup";
import { HrInterviewFile } from "../../../../../models/hrInterview/HrInterviewFile";
import { useGetInterviewTypes } from "../../../../../store/hooks/hr-interview-setting";
import { useCreateHrInterview } from "../../../../../store/hooks/hrInterview";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { DefinitionList } from "../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeFormFollowingButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeDialog } from "../../../../molecules/dialog/AmeDialog";
import { FormatMapper } from "../../FormatMapper";
import { CreateHrInterviewPageContent } from "../../interviews/create";
import { InterviewCreationFrom } from "../../interviews/create/InterviewCreationForm";

interface Props {
  interviewFile: HrInterviewFile;
  userGroups: UserGroup[];
}

export const CreateInterviewByFileContent: React.FC<Props> = ({ interviewFile, userGroups }) => {
  const { register, control, formState, setValue, handleSubmit } = useForm<InterviewCreationFrom>({
    defaultValues: { groupId: userGroups?.[0]?.userGroupId },
  });
  const clearFormatId = useCallback(() => {
    setValue("interviewFormatId", undefined as unknown as string);
  }, [setValue]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const interviewTypesQuery = useGetInterviewTypes();
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const navigate = useNavigate();
  const createHrInterview = useCreateHrInterview();

  return (
    <>
      <AmeDialog open={isOpen} onClose={onClose}>
        <FormatMapper onClose={onClose} interviewFormatId={""} />
      </AmeDialog>
      <PageLayoutBody>
        <PaperWrapper>
          <PaperHeader>面談概要</PaperHeader>
          <PaperBody>
            <WaitForSuccess queryState={interviewTypesQuery}>
              {(types) => (
                <CreateHrInterviewPageContent
                  isDraft={true}
                  interviewTypes={types}
                  userGroups={userGroups}
                  register={register}
                  control={control}
                  formState={formState}
                  clearFormatId={clearFormatId}
                />
              )}
            </WaitForSuccess>
          </PaperBody>
        </PaperWrapper>
        <Spacer height="24px" />
        <PaperWrapper>
          <PaperHeader>音声データ</PaperHeader>
          <PaperBody>
            <DefinitionList>
              <DefinitionListItem label={"アップロード日"} value={formatDateToYYYYMMDD(interviewFile.createdAt)} />
              <DefinitionListItem label={"登録者"} value={interviewFile.uploadUser?.getFullName()} />
              <DefinitionListItem
                label={"アップロードファイル"}
                value={
                  <Link
                    to={"/hr-interviews/interview-files/$interviewFileId"}
                    params={{ interviewFileId: interviewFile.hrInterviewFileId }}
                  >
                    <AmeTypography color={"secondary"}>{interviewFile.originalFileName}</AmeTypography>
                  </Link>
                }
              />
            </DefinitionList>
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
      <AmeFormFollowingButtonsLayout
        onPrimary={handleSubmit(async (data) => {
          const res = await createHrInterview({
            createHrInterviewRequestBody: {
              employeeTenureId: data.employeeTenure.employeeTenureId,
              userGroupId: data.groupId,
              interviewFormatId: data.interviewFormatId,
              interviewTypeId: data.interviewTypeId,
              interviewDate: data.interviewDate && formatDateToYYYYMMDD(data.interviewDate),
              hrInterviewFileId: interviewFile.hrInterviewFileId,
            },
          });
          if (res.isSuccess) {
            await navigate({
              to: "/hr-interviews/interviews/$interviewId",
              params: { interviewId: res.data.hrInterviewId },
            });
          }
        })}
        onSecondary={async () => {
          await navigate({
            to: "/hr-interviews/interview-files/$interviewFileId",
            params: { interviewFileId: interviewFile.hrInterviewFileId },
          });
        }}
        secondaryText={"キャンセル"}
      />
    </>
  );
};
