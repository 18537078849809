import { SurveyModel } from "../../models/Survey";
import { SurveySheet } from "../../models/SurveySheet";
import { SegmentKey } from "../../models/constants/values";
import { AxisLiteralOfTypeofSurveyReportAxisFactory } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useGetSurveyList = (args: { count: number; offset: number }, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getSurveys.useQuery(
    { count: args.count, offset: args.offset },
    { pollingInterval: options?.pollingInterval },
  );
  return useQueryResult({
    result,
    fromResponse: (response) => ({
      surveys: response.surveys.map(SurveyModel.fromResponse),
      totalCount: response.totalCount,
    }),
    onError: options?.onError,
  });
};

export const useGetSurvey = (surveyId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getSurvey.useQuery({ surveyId });
  return useQueryResult({
    result,
    fromResponse: SurveyModel.fromResponse,
    onError: options?.onError,
  });
};

export const useGetSurveySheetBySurveyId = (surveyId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getSharedSurveySheet.useQuery({ surveyId });
  return useQueryResult({
    result,
    fromResponse: SurveySheet.fromResponse,
    onError: options?.onError,
  });
};

export const useGetLatestSurvey = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getLatestSurvey.useQuery();
  return useQueryResult({
    result,
    fromResponse: SurveyModel.fromResponse,
    onError: options?.onError,
  });
};

export const useCreateSurvey = () => {
  const [createSurvey] = defaultApi.endpoints.createSurvey.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createSurvey,
  });
};

export const useUpdateSurvey = () => {
  const [updateSurvey] = defaultApi.endpoints.updateSurvey.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateSurvey,
  });
};

export const useUpdateSurveyStatus = () => {
  const [updateSurveyStatus] = defaultApi.endpoints.updateSurveyStatus.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateSurveyStatus,
  });
};

export const useStartSurvey = () => {
  const [startSurvey] = defaultApi.endpoints.startSurvey.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: startSurvey,
  });
};

export const useDeleteSurvey = () => {
  const [deleteSurvey] = defaultApi.endpoints.deleteSurvey.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: deleteSurvey,
  });
};

export const useSendSampleSurveySheet = () => {
  const [sendSampleSurveySheet] = defaultApi.endpoints.sendSampleSurveySheet.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: sendSampleSurveySheet,
  });
};

export const useSendRemindSurveySheet = () => {
  const [sendRemindSurveySheet] = defaultApi.endpoints.sendRemindSurveySheet.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: sendRemindSurveySheet,
  });
};

export const useGetSurveySheet = (surveySheetId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getSurveySheet.useQuery(
    { surveySheetId },
    { pollingInterval: options?.pollingInterval },
  );
  return useQueryResult({
    result,
    fromResponse: (response) => response,
    onError: options?.onError,
  });
};

export const useSubmitSurveyAnswer = (options?: UseMutationOptions) => {
  const [submitSurveyAnswer] = defaultApi.endpoints.submitSurveyAnswer.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: submitSurveyAnswer,
    onError: options?.onError,
  });
};

export const useSubmitSurveyAnswerWithId = (options?: UseMutationOptions) => {
  const [submitSurveyAnswerWithId] = defaultApi.endpoints.submitSurveyAnswerWithId.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: submitSurveyAnswerWithId,
    onError: options?.onError,
  });
};

export type SurveyReportParams = {
  surveyId: string;
  axis: AxisLiteralOfTypeofSurveyReportAxisFactory;
  subCategoryIds: string[];
} & {
  [key in keyof typeof SegmentKey]: string[];
};

export const useSurveyReport = (params: SurveyReportParams, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getSurveyReport.useQuery(
    {
      surveyId: params.surveyId,
      axis: params.axis,
      sexes: params.SEX,
      departments: params.DEPARTMENT,
      ages: params.AGE,
      free1S: params.FREE1,
      free2S: params.FREE2,
      monthsOfServices: params.MONTHS_OF_SERVICE,
      subCategoryIds: params.subCategoryIds,
    },
    {
      pollingInterval: options?.pollingInterval,
    },
  );
  return useQueryResult({
    result,
    fromResponse: (response) => response,
    onError: options?.onError,
  });
};
