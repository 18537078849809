import React, { useCallback, useState } from "react";

import { User } from "../../../../../../../models/User";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { ArrowLeft } from "../../../../../../atoms/icon/ArrowLeft";
import { ArrowRight } from "../../../../../../atoms/icon/ArrowRight";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeFormButtonsLayout } from "../../../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { UserSelectTable } from "./UserSelectTable";

interface Props {
  groupName: string;
  users: User[];
  selectedUserIds: string[];
  transitToGroupSetting: VoidFunction;
  updateGroupMembers: (memberUserIds: string[]) => void;
}

export const MemberTransferList: React.FC<Props> = ({
  groupName,
  transitToGroupSetting,
  selectedUserIds: defaultSelectedUserIds,
  users,
  updateGroupMembers,
}) => {
  const [selectedUserIds, setSelectedUserIds] = useState(defaultSelectedUserIds);
  const [checkedUserIds, setCheckedUserIds] = useState<string[]>([]);
  const [checkedMemberUserIds, setCheckedMemberUserIds] = useState<string[]>([]);
  const toggleCheckedUser = useCallback(
    (userId: string) => {
      setCheckedUserIds((prevState) => {
        if (prevState.includes(userId)) {
          return [...prevState].filter((id) => id !== userId);
        } else {
          return [...prevState, userId];
        }
      });
    },
    [setCheckedUserIds],
  );
  const toggleCheckedMemberUser = useCallback(
    (userId: string) => {
      setCheckedMemberUserIds((prevState) => {
        if (prevState.includes(userId)) {
          return [...prevState].filter((id) => id !== userId);
        } else {
          return [...prevState, userId];
        }
      });
    },
    [setCheckedMemberUserIds],
  );
  const addUsers = useCallback(() => {
    setSelectedUserIds((prev) => [...prev, ...checkedUserIds]);
    setCheckedUserIds([]);
  }, [checkedUserIds]);
  const removeUsers = useCallback(() => {
    setSelectedUserIds((prev) => [...prev].filter((userId) => !checkedMemberUserIds.includes(userId)));
    setCheckedUserIds([]);
  }, [checkedMemberUserIds]);

  return (
    <>
      <AmeBox sx={{ display: "flex", width: "100%" }}>
        <AmeBox sx={{ flexGrow: "1", flexBasis: 0 }}>
          <UserSelectTable
            title={"全ユーザー"}
            users={users
              .filter((user) => !selectedUserIds.includes(user.userId!))
              .map((user) => ({
                userId: user.userId!,
                displayName: user.getFullName(),
              }))}
            checkedUserIds={checkedUserIds}
            toggleCheckedUser={toggleCheckedUser}
          />
        </AmeBox>
        <AmeBox sx={{ display: "flex", flexDirection: "column", padding: "24px", justifyContent: "center" }}>
          <AmeButton
            onClick={addUsers}
            size={"small"}
            color={"secondary"}
            variant={"outlined"}
            endIcon={<ArrowRight color={"inherit"} />}
          >
            追加
          </AmeButton>
          <Spacer height={"16px"} />
          <AmeButton
            onClick={removeUsers}
            size={"small"}
            color={"basic"}
            variant={"outlined"}
            startIcon={<ArrowLeft color={"inherit"} />}
          >
            解除
          </AmeButton>
        </AmeBox>
        <AmeBox sx={{ flexGrow: "1", flexBasis: 0 }}>
          <UserSelectTable
            title={`「${groupName}」のメンバー`}
            users={selectedUserIds.map((id) => {
              const user = users.find((user) => user.userId === id);
              if (user) {
                return { userId: user.userId!, displayName: user.getFullName() };
              } else {
                return {
                  userId: id,
                  displayName: "不明なユーザー",
                };
              }
            })}
            checkedUserIds={checkedMemberUserIds}
            toggleCheckedUser={toggleCheckedMemberUser}
          />
        </AmeBox>
      </AmeBox>
      <AmeFormButtonsLayout onPrimary={() => updateGroupMembers(selectedUserIds)} onSecondary={transitToGroupSetting} />
    </>
  );
};
