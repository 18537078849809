import React, { useMemo } from "react";

import { removeUndefinedDeep } from "@amecloud/common-utils/src/utils/ObjectUtils";
import { ApexOptions } from "apexcharts";

import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeChart, ColumnSeriesElement } from "../../../../atoms/charts/AmeChart";

interface Props {
  evaluationTypeSeries: (number | null)[][];
  evaluationTypeNames: string[];
  seriesLabels: string[];
  formatter?: (value: number | null) => string;
}

// TODO: atoms/chartsに混ぜる
export const ScoreQualityChart: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const markersShapes = useMemo(
    (): MarkerShapeOptions[] => ["circle", ...props.evaluationTypeSeries.map((_, index) => getMarkerShape(index))],
    [props.evaluationTypeSeries],
  );

  const columnSeries = useMemo((): ColumnSeriesElement[] => {
    return props.evaluationTypeSeries.map((series, index) => ({
      type: "line",
      name: props.evaluationTypeNames[index],
      data: series,
      color: theme.brand.secondary[110] ?? "#ADC2C5",
    }));
  }, [props.evaluationTypeNames, props.evaluationTypeSeries, theme]);

  const options: ApexOptions = useMemo(() => {
    const ops: ApexOptions = {
      stroke: {
        // X軸系列が1つしかない場合、謎の縦線が表示されるので、strokeを非表示にする
        width: columnSeries[0]?.data.length > 1 ? 3 : 0,
      },
      markers: {
        size: 5,
        colors: columnSeries.map(() => theme.common.text.white),
        strokeColors: columnSeries.map((cs) => cs.color),
        shape: markersShapes,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        tickPlacement: "between",
      },
      yaxis: {
        title: {
          text: "スコア(点)",
        },
        min: 0,
        max: 5,
        labels: {
          formatter: (value: number) => Math.round(value).toFixed(0),
        },
      },
      tooltip: {
        y: {
          formatter: (value: number) => (value === null ? "" : value.toFixed(1) + "点"),
        },
      },
      labels: props.seriesLabels,
    };
    return removeUndefinedDeep(ops);
  }, [theme.common.text.white, columnSeries, props, markersShapes]);

  if (!areAllArraysSameLength(props.evaluationTypeNames, props.evaluationTypeSeries)) {
    throw new Error("評価項目名の数と評価項目データの種類数が一致していません");
  }

  return <AmeChart options={options} columnSeries={columnSeries} height={400} markersShapes={markersShapes} />;
};

const areAllArraysSameLength = (...arrays: unknown[][]) => {
  if (arrays.length === 0) {
    return true;
  }
  const length = arrays[0].length;
  return arrays.every((array) => array.length === length);
};

const getMarkerShape = (index: number): MarkerShapeOptions => {
  return MARKER_SHAPE_LIST[index % MARKER_SHAPE_LIST.length];
};

/**
 * マーカーの形状の選択肢
 * 評価項目に対して異なる形状を選択するための選択肢
 * 評価項目が10個以上ある場合は形状が被ってしまうが、どうしようもないので許容する
 */
const MARKER_SHAPE_LIST: MarkerShapeOptions[] = [
  "circle",
  "square",
  "triangle",
  "diamond",
  "star",
  "sparkle",
  "cross",
  "plus",
  "line",
] as const;
