/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSurveyRequestBody
 */
export interface CreateSurveyRequestBody {
    /**
     * 
     * @type {boolean}
     * @memberof CreateSurveyRequestBody
     */
    useQrcode: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSurveyRequestBody
     */
    sendEmail: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyRequestBody
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyRequestBody
     */
    surveyTitle: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyRequestBody
     */
    surveyDescription: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyRequestBody
     */
    emailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSurveyRequestBody
     */
    emailBody?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSurveyRequestBody
     */
    dueDateTime: Date;
}

export function CreateSurveyRequestBodyFromJSON(json: any): CreateSurveyRequestBody {
    return CreateSurveyRequestBodyFromJSONTyped(json, false);
}

export function CreateSurveyRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSurveyRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useQrcode': json['useQrcode'],
        'sendEmail': json['sendEmail'],
        'displayName': json['displayName'],
        'surveyTitle': json['surveyTitle'],
        'surveyDescription': json['surveyDescription'],
        'emailSubject': !exists(json, 'emailSubject') ? undefined : json['emailSubject'],
        'emailBody': !exists(json, 'emailBody') ? undefined : json['emailBody'],
        'dueDateTime': (new Date(json['dueDateTime'])),
    };
}

export function CreateSurveyRequestBodyToJSON(value?: CreateSurveyRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'useQrcode': value.useQrcode,
        'sendEmail': value.sendEmail,
        'displayName': value.displayName,
        'surveyTitle': value.surveyTitle,
        'surveyDescription': value.surveyDescription,
        'emailSubject': value.emailSubject,
        'emailBody': value.emailBody,
        'dueDateTime': (value.dueDateTime.toISOString()),
    };
}


