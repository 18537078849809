import React from "react";

import { BreadcrumbPageLayoutHeader } from "../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../atoms/layout/PageLayoutWrapper";
import { AmeModal } from "../../../atoms/modal/AmeModal";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { SURVEY_SENDING_METHOD_TEXT_AND_ICON } from "../common/constants";

import { SelectSurveySendingMethodButton } from "./SelectSurveySendingMethodButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SelectSurveySendingMethodModal: React.FC<Props> = (props) => {
  return (
    <AmeModal open={props.isOpen} onClose={props.onClose}>
      <PageLayoutWrapper>
        <BreadcrumbPageLayoutHeader items={[]} currentTitle="アンケート新規作成" />
        <PageLayoutBody type="wide">
          <AmeTypography>アンケート送付方法を選択してください</AmeTypography>
          <Spacer height="24px" />
          <SelectSurveySendingMethodButton
            icon={SURVEY_SENDING_METHOD_TEXT_AND_ICON.email.icon}
            text={SURVEY_SENDING_METHOD_TEXT_AND_ICON.email.text}
            helperText="従業員リストに登録されているメールアドレス宛に、アンケート送付して回答いただけます"
            method="email"
          />
          <Spacer height="20px" />
          <SelectSurveySendingMethodButton
            icon={SURVEY_SENDING_METHOD_TEXT_AND_ICON["qr-code"].icon}
            text={SURVEY_SENDING_METHOD_TEXT_AND_ICON["qr-code"].text}
            helperText="メールアドレスを持たない方にQRコードをシステム外で送付して回答いただけます"
            method="qr-code"
          />
          <Spacer height="20px" />
          <SelectSurveySendingMethodButton
            icon={SURVEY_SENDING_METHOD_TEXT_AND_ICON.both.icon}
            text={SURVEY_SENDING_METHOD_TEXT_AND_ICON.both.text}
            helperText="メール、QRコードどちらも使用して回答いただけます"
            method="both"
          />
        </PageLayoutBody>
      </PageLayoutWrapper>
    </AmeModal>
  );
};
