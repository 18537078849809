import React, { useCallback, useContext, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { useGetEmployeeList } from "../../../../../store/hooks/employees";
import { createApiClient } from "../../../../../utils/api/ApiClientFactory";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeDateInput } from "../../../../atoms/forms/input/AmeDateInput";
import { Download } from "../../../../atoms/icon/Download";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { AmePagination } from "../../../../atoms/pagination/AmePagination";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { WaitForData } from "../../../../molecules/Loading/WaitForData";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { EmployeeTable } from "../../../../organisms/employee/EmployeeTable";

const EMPLOYEE_COUNT_PER_PAGE = 10;

export const EmployeePage: React.FC = () => {
  const { period, setting } = useContext(DataSourceContext);
  const [at, setAt] = useState<Date>(new Date());
  const { page } = Route.useSearch();
  const navigate = Route.useNavigate();
  const onChangePage = useCallback(
    async (pageNumber: number) => {
      await navigate({
        search: (prev) => ({ ...prev, page: pageNumber }),
      });
    },
    [navigate],
  );
  const employeesQuery = useGetEmployeeList(at, EMPLOYEE_COUNT_PER_PAGE, EMPLOYEE_COUNT_PER_PAGE * (page - 1));

  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  const onDownload = useCallback(async () => {
    try {
      const res = await (await createApiClient()).downloadEmployeeCSVRaw({ at: formatDateToYYYYMMDD(at) });
      const link = document.createElement("a");
      const objectUrl = URL.createObjectURL(await res.raw.blob());
      link.href = objectUrl;
      link.download = "employees.csv";
      link.click();
      link.remove();
      URL.revokeObjectURL(objectUrl);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      enqueueErrorSnackbar({ title: "従業員リストのダウンロードに失敗しました。" });
    }
  }, [at, enqueueErrorSnackbar]);

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader
        title="従業員一覧"
        endElement={<AmeButton to={"/employees/register"}>従業員リストの更新</AmeButton>}
      />

      <PageLayoutBody type="wide">
        <AmeBox
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AmeBox
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <AmeBox sx={{ display: "flex", alignItems: "center" }}>
              <AmeDateInput value={at} onCommitDate={(date) => setAt(date || period.endDate)} />
              <Spacer width="8px" />
              <span>時点</span>
            </AmeBox>
            <AmeButton startIcon={<Download color={"black"} />} onClick={onDownload} variant={"outlined"}>
              従業員リストをダウンロード
            </AmeButton>
          </AmeBox>
          <Spacer height={"8px"} />
          <WaitForSuccess queryState={employeesQuery}>
            {({ employees }) => <EmployeeTable setting={setting} employees={employees} />}
          </WaitForSuccess>
          <Spacer height={"24px"} />
          <WaitForData queryState={employeesQuery}>
            {({ totalCount }) => (
              <AmePagination
                page={page}
                count={Math.ceil(totalCount / EMPLOYEE_COUNT_PER_PAGE)}
                onChangePage={onChangePage}
              />
            )}
          </WaitForData>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type QueryParams = {
  page: number;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/employees/")({
  component: EmployeePage,
  validateSearch: (search: Partial<QueryParams>): QueryParams => ({ page: search.page ?? 1 }),
});
