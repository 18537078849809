/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubCategoryResponseMeta,
    SubCategoryResponseMetaFromJSON,
    SubCategoryResponseMetaFromJSONTyped,
    SubCategoryResponseMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubCategoryResponse
 */
export interface SubCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof SubCategoryResponse
     */
    subCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryResponse
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryResponse
     */
    subCategoryDefinition: string;
    /**
     * 
     * @type {Date}
     * @memberof SubCategoryResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SubCategoryResponse
     */
    updatedAt: Date;
    /**
     * 
     * @type {SubCategoryResponseMeta}
     * @memberof SubCategoryResponse
     */
    meta?: SubCategoryResponseMeta;
}

export function SubCategoryResponseFromJSON(json: any): SubCategoryResponse {
    return SubCategoryResponseFromJSONTyped(json, false);
}

export function SubCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subCategoryId': json['subCategoryId'],
        'categoryId': json['categoryId'],
        'displayName': json['displayName'],
        'subCategoryDefinition': json['subCategoryDefinition'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'meta': !exists(json, 'meta') ? undefined : SubCategoryResponseMetaFromJSON(json['meta']),
    };
}

export function SubCategoryResponseToJSON(value?: SubCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subCategoryId': value.subCategoryId,
        'categoryId': value.categoryId,
        'displayName': value.displayName,
        'subCategoryDefinition': value.subCategoryDefinition,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'meta': SubCategoryResponseMetaToJSON(value.meta),
    };
}


