import { InterviewFileResponse, TranscriptionStatus } from "../store/autogenApi";

const INTERVIEW_FILE_STATUS = [
  "NOT_CONNECTED",
  "INITIALIZED",
  "AI_TRANSCRIBED",
  "TRANSCRIBING",
  "CHECKING",
  "CHECKED",
  "FAILED",
] as const;
export type InterviewFileStatus = (typeof INTERVIEW_FILE_STATUS)[number];

import { UserRef } from "./User";
import { UserGroupRef } from "./UserGroup";

export class InterviewFile {
  constructor(
    readonly interviewRecordingId: string,
    readonly originalFileName: string,
    readonly duration: number | undefined,
    readonly createdAt: Date,
    readonly employee: { customerCreatedId: string; name?: string; employeeTenureId: string } | undefined,
    readonly exEmployeeInterviewId: string | undefined,
    readonly interviewer?: UserRef,
    readonly userGroup?: UserGroupRef,
    readonly transcriptionStatus?: TranscriptionStatus,
  ) {}

  public static fromResponse(response: InterviewFileResponse): InterviewFile {
    return new InterviewFile(
      response.interviewRecordingId,
      response.originalFileName,
      response.duration,
      new Date(response.createdAt),
      response.employee,
      response.exEmployeeInterviewId,
      response.interviewer && UserRef.fromResponse(response.interviewer),
      response.userGroup && UserGroupRef.fromResponse(response.userGroup),
      response.transcriptionStatus,
    );
  }

  public getStatus(): InterviewFileStatus | undefined {
    if (this.exEmployeeInterviewId) {
      return "CHECKED";
    }
    if (this.employee === undefined) {
      return "NOT_CONNECTED";
    }
    switch (this.transcriptionStatus) {
      case undefined:
        return "TRANSCRIBING";
      case "initialized":
        return "INITIALIZED";
      case "transcribed":
        return "AI_TRANSCRIBED";
      case "created":
        return "TRANSCRIBING";
      case "checking":
        return "CHECKING";
      case "checked":
        return "CHECKED";
      default:
        return "FAILED";
    }
  }

  public getInternalStatusText(): string | undefined {
    switch (this.getStatus()) {
      case "NOT_CONNECTED":
        return "従業員紐付き前";
      case "INITIALIZED":
        return "AI文字起こし中";
      case "AI_TRANSCRIBED":
        return "AI整形中";
      case "TRANSCRIBING":
        return "文字起こし中";
      case "CHECKING":
        return "チェック中";
      case "CHECKED":
        return "文字起こし済";
      case "FAILED":
        return "エラー";
    }
  }

  public getStatusText(): string | undefined {
    switch (this.getStatus()) {
      case "NOT_CONNECTED":
        return "従業員紐付き前";
      case "INITIALIZED":
      case "AI_TRANSCRIBED":
      case "TRANSCRIBING":
      case "CHECKING":
      case "FAILED":
        return "文字起こし中";
      case "CHECKED": {
        if (this.exEmployeeInterviewId) {
          return "文字起こし完了";
        } else {
          return "文字起こし中";
        }
      }
    }
  }
}

export type InterviewFileList = {
  interviewFiles: InterviewFile[];
  totalCount: number;
};
