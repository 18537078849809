import React, { useContext } from "react";

import { useForm } from "react-hook-form";

import { InterviewReportAxis, Sentence } from "../../../../../../store/hooks/interviewReport";
import { QueryState } from "../../../../../../store/hooks/utils/parseResult";
import { SimplePaper } from "../../../../../atoms/paper/SimplePaper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { V2ContentWrapper } from "../../../../../atoms/wrapper/V2ContentWrapper";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { DataSourceContext } from "../../../../common/DataSourceContext";

import { RetirementVoiceList } from "./RetirementVoiceList";
import { RetirementVoiceListHeader } from "./RetirementVoiceListHeader";

export type TagCheckboxesFormType = Record<InterviewReportAxis, boolean>;

interface Props {
  sentencesState: QueryState<Sentence[]>;
}

export const RetirementVoices: React.FC<Props> = ({ sentencesState }) => {
  const { control, watch } = useForm<TagCheckboxesFormType>({
    defaultValues: {
      MONTHS_OF_SERVICE: true,
      SEX: true,
      DEPARTMENT: true,
      FREE1: true,
      FREE2: true,
      AGE: true,
      subCategoryId: true,
    },
  });

  const {
    setting: { segmentNames },
  } = useContext(DataSourceContext);

  return (
    <SimplePaper>
      <V2ContentWrapper title="退職者の声">
        <RetirementVoiceListHeader segmentAxis={segmentNames} control={control} />
        <Spacer height="36px" />
        <WaitForSuccess queryState={sentencesState}>
          {(sentences) => <RetirementVoiceList sentences={sentences} tagsAreChecked={watch()} />}
        </WaitForSuccess>
      </V2ContentWrapper>
    </SimplePaper>
  );
};
