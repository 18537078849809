/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewFeedbackRating {
    _0 = '0',
    _1 = '1',
    _2 = '2'
}

export function InterviewFeedbackRatingFromJSON(json: any): InterviewFeedbackRating {
    return InterviewFeedbackRatingFromJSONTyped(json, false);
}

export function InterviewFeedbackRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFeedbackRating {
    return json as InterviewFeedbackRating;
}

export function InterviewFeedbackRatingToJSON(value?: InterviewFeedbackRating | null): any {
    return value as any;
}

