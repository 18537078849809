import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const ArrowLeft: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 17 17">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8541 14.8531C11.8076 14.8997 11.7525 14.9366 11.6917 14.9618C11.631 14.987 11.5659 15 11.5001 15C11.4343 15 11.3692 14.987 11.3084 14.9618C11.2477 14.9366 11.1925 14.8997 11.1461 14.8531L5.14608 8.85311C5.09952 8.80666 5.06258 8.75148 5.03737 8.69074C5.01216 8.62999 4.99919 8.56487 4.99919 8.49911C4.99919 8.43334 5.01216 8.36822 5.03737 8.30747C5.06258 8.24673 5.09952 8.19155 5.14608 8.14511L11.1461 2.14511C11.24 2.05122 11.3673 1.99848 11.5001 1.99848C11.6329 1.99848 11.7602 2.05122 11.8541 2.14511C11.948 2.23899 12.0007 2.36633 12.0007 2.49911C12.0007 2.63188 11.948 2.75922 11.8541 2.85311L6.20708 8.49911L11.8541 14.1451C11.9006 14.1916 11.9376 14.2467 11.9628 14.3075C11.988 14.3682 12.001 14.4333 12.001 14.4991C12.001 14.5649 11.988 14.63 11.9628 14.6907C11.9376 14.7515 11.9006 14.8067 11.8541 14.8531Z"
        fill="currentColor"
      />
    </SvgWrapper>
  );
};
