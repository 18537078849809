/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SegmentKey,
    SegmentKeyFromJSON,
    SegmentKeyFromJSONTyped,
    SegmentKeyToJSON,
    SegmentTurnoverStatsWithForecastResponse,
    SegmentTurnoverStatsWithForecastResponseFromJSON,
    SegmentTurnoverStatsWithForecastResponseFromJSONTyped,
    SegmentTurnoverStatsWithForecastResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface NoteResponse
 */
export interface NoteResponse {
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    noteId: string;
    /**
     * 
     * @type {SegmentKey}
     * @memberof NoteResponse
     */
    segmentKey?: SegmentKey;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    segmentValue?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    surveyId?: string;
    /**
     * 
     * @type {Array<SegmentTurnoverStatsWithForecastResponse>}
     * @memberof NoteResponse
     */
    turnoverSummaries?: Array<SegmentTurnoverStatsWithForecastResponse>;
    /**
     * 
     * @type {Date}
     * @memberof NoteResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof NoteResponse
     */
    updatedAt: Date;
}

export function NoteResponseFromJSON(json: any): NoteResponse {
    return NoteResponseFromJSONTyped(json, false);
}

export function NoteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noteId': json['noteId'],
        'segmentKey': !exists(json, 'segmentKey') ? undefined : SegmentKeyFromJSON(json['segmentKey']),
        'segmentValue': !exists(json, 'segmentValue') ? undefined : json['segmentValue'],
        'title': json['title'],
        'text': json['text'],
        'surveyId': !exists(json, 'surveyId') ? undefined : json['surveyId'],
        'turnoverSummaries': !exists(json, 'turnoverSummaries') ? undefined : ((json['turnoverSummaries'] as Array<any>).map(SegmentTurnoverStatsWithForecastResponseFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function NoteResponseToJSON(value?: NoteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noteId': value.noteId,
        'segmentKey': SegmentKeyToJSON(value.segmentKey),
        'segmentValue': value.segmentValue,
        'title': value.title,
        'text': value.text,
        'surveyId': value.surveyId,
        'turnoverSummaries': value.turnoverSummaries === undefined ? undefined : ((value.turnoverSummaries as Array<any>).map(SegmentTurnoverStatsWithForecastResponseToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


