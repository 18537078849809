import { sum } from "@amecloud/common-utils/src/utils/ArrayUtils";

import {
  HrInterviewAnalysisStatus,
  HrInterviewDetailResponse,
  HrInterviewRefResponse,
  HrInterviewResponse,
} from "../../store/autogenApi";
import { AttributeList, EmployeeRef } from "../Employee";
import { UserRef } from "../User";
import { UserGroupRef } from "../UserGroup";
import { FeedbackStatus } from "../constants/values";

import { HrInterviewFile } from "./HrInterviewFile";
import { HrInterviewTranscription } from "./HrInterviewTranscription";

export class HrInterviewRef {
  constructor(
    readonly hrInterviewId: string,
    readonly interviewFileId: string | undefined,
    readonly followUpMemo: string,
    readonly followUpIsRequired: boolean | undefined,
    readonly interviewer: UserRef,
    readonly interviewFormat: { interviewFormatId: string; displayName: string },
    readonly interviewType: { interviewTypeId: string; displayName: string },
    readonly interviewDate: Date | undefined,
    readonly employee: EmployeeRef | undefined,
    readonly lastFeedbackSentAt: Date | undefined,
  ) {}

  static fromResponse(response: HrInterviewRefResponse): HrInterviewRef {
    return new HrInterviewRef(
      response.hrInterviewId,
      response.interviewFileId,
      response.followUpMemo,
      response.followUpIsRequired,
      UserRef.fromResponse(response.interviewer),
      {
        interviewFormatId: response.interviewFormat.interviewFormatId,
        displayName: response.interviewFormat.displayName,
      },
      {
        interviewTypeId: response.interviewType.interviewTypeId,
        displayName: response.interviewType.displayName,
      },
      response.interviewDate === undefined ? undefined : new Date(response.interviewDate),
      response.employee !== undefined ? EmployeeRef.fromResponse(response.employee) : undefined,
      response.lastFeedbackSentAt === undefined ? undefined : new Date(response.lastFeedbackSentAt),
    );
  }
}

export class HrInterview implements HrInterviewRef {
  constructor(
    readonly hrInterviewId: string,
    readonly interviewFileId: string | undefined,
    readonly followUpMemo: string,
    readonly followUpIsRequired: boolean | undefined,
    readonly interviewer: UserRef,
    readonly interviewAnswers: { interviewAnswerId: string; answer: string; question: string }[],
    readonly interviewFormat: { interviewFormatId: string; displayName: string },
    readonly interviewType: { interviewTypeId: string; displayName: string },
    readonly interviewDate: Date | undefined,
    readonly employee: EmployeeRef | undefined,
    readonly lastFeedbackSentAt: Date | undefined,
    readonly analysisStatus: HrInterviewAnalysisStatus | undefined,
    readonly hrInterviewFile: HrInterviewFile | undefined,
    readonly createdAt: Date | undefined,
    readonly feedbackStatus: FeedbackStatus,
  ) {}

  static fromResponse(response: HrInterviewResponse): HrInterview {
    return new HrInterview(
      response.hrInterviewId,
      response.interviewFileId,
      response.followUpMemo,
      response.followUpIsRequired,
      UserRef.fromResponse(response.interviewer),
      response.interviewAnswers.map((interviewAnswer) => ({
        interviewAnswerId: interviewAnswer.interviewFormatAnswerId,
        answer: interviewAnswer.answer,
        question: interviewAnswer.question,
      })),
      {
        interviewFormatId: response.interviewFormat.interviewFormatId,
        displayName: response.interviewFormat.displayName,
      },
      {
        interviewTypeId: response.interviewType.interviewTypeId,
        displayName: response.interviewType.displayName,
      },
      response.interviewDate === undefined ? undefined : new Date(response.interviewDate),
      response.employee !== undefined ? EmployeeRef.fromResponse(response.employee) : undefined,
      response.lastFeedbackSentAt === undefined ? undefined : new Date(response.lastFeedbackSentAt),
      response.analysisStatus,
      response.hrInterviewFile && HrInterviewFile.fromResponse(response.hrInterviewFile),
      response.createdAt === undefined ? undefined : new Date(response.createdAt),
      response.feedbackStatus,
    );
  }
}

export class HrInterviewDetail implements HrInterview {
  constructor(
    readonly hrInterviewId: string,
    readonly analysisStatus: HrInterviewAnalysisStatus | undefined,
    readonly interviewFileId: string | undefined,
    readonly followUpMemo: string,
    readonly followUpIsRequired: boolean | undefined,
    readonly interviewer: UserRef,
    readonly interviewAnswers: { interviewAnswerId: string; answer: string; question: string }[],
    readonly interviewFormat: { interviewFormatId: string; displayName: string },
    readonly interviewType: { interviewTypeId: string; displayName: string },
    readonly interviewDate: Date | undefined,
    readonly interviewFile: HrInterviewFile | undefined,
    readonly transcription: HrInterviewTranscription | undefined,
    readonly employee: EmployeeRef | undefined,
    readonly attributes: AttributeList | undefined,
    readonly analysisScores: { score: number; subCategoryId: string; displayName: string }[] | undefined,
    readonly summary: string | undefined,
    readonly lastFeedbackSentAt: Date | undefined,
    readonly hrInterviewFile: HrInterviewFile | undefined,
    readonly userGroup: UserGroupRef | undefined,
    readonly structuredAnswerPairs: QuestionAnswerPair[],
    readonly createdAt: Date | undefined,
    readonly feedbackStatus: FeedbackStatus,
  ) {}

  get followUpStatus(): string {
    return this.followUpIsRequired === undefined ? "未登録" : this.followUpIsRequired ? "必要" : "不要";
  }

  get isEditable(): boolean {
    if (this.transcription?.isTranscribing) {
      return false;
    }
    return !(this.analysisStatus === "ANALYZED" || this.analysisStatus === "ANALYZING");
  }

  static fromResponse(response: HrInterviewDetailResponse): HrInterviewDetail {
    const answerPairs: QuestionAnswerPair[] = [];
    for (const interviewAnswer of response.interviewAnswers) {
      const target = response.subCategoryDocumentMap
        .filter((data) => data.interviewFormatContentId === interviewAnswer.interviewFormatContentId)
        .filter((data) => data.offset !== -1)
        .sort((a, b) => a.offset - b.offset);
      const result: StructuredAnswer[] = [];
      let previousOffset = 0;
      for (const h of target) {
        if (previousOffset !== h.offset) {
          result.push({
            subCategoryIds: [],
            content: interviewAnswer.answer.slice(previousOffset, h.offset),
          });
        }
        result.push({
          documentId: h.documentId,
          subCategoryIds: h.subCategoryIds,
          content: interviewAnswer.answer.slice(h.offset, h.offset + h.length),
        });
        previousOffset = sum(result.map((r) => r.content.length));
      }
      const rest = interviewAnswer.answer.slice(previousOffset);
      if (rest.length) {
        result.push({
          subCategoryIds: [],
          content: rest,
        });
      }

      answerPairs.push({
        interviewFormatContentId: interviewAnswer.interviewFormatContentId,
        question: interviewAnswer.question,
        answers: result,
      });
    }

    return new HrInterviewDetail(
      response.hrInterviewId,
      response.analysisStatus,
      response.interviewFileId,
      response.followUpMemo,
      response.followUpIsRequired,
      UserRef.fromResponse(response.interviewer),
      response.interviewAnswers.map((interviewAnswer) => ({
        interviewAnswerId: interviewAnswer.interviewFormatAnswerId,
        answer: interviewAnswer.answer,
        question: interviewAnswer.question,
      })),
      {
        interviewFormatId: response.interviewFormat.interviewFormatId,
        displayName: response.interviewFormat.displayName,
      },
      {
        interviewTypeId: response.interviewType.interviewTypeId,
        displayName: response.interviewType.displayName,
      },
      response.interviewDate === undefined ? undefined : new Date(response.interviewDate),
      response.hrInterviewFile && HrInterviewFile.fromResponse(response.hrInterviewFile),
      response.transcriptions && HrInterviewTranscription.fromResponse(response.transcriptions),
      response.employee !== undefined ? EmployeeRef.fromResponse(response.employee) : undefined,
      response.attributes !== undefined ? AttributeList.fromResponses(response.attributes) : undefined,
      response.analysisScores.filter(({ score }) => score !== 0),
      response.summary,
      response.lastFeedbackSentAt === undefined ? undefined : new Date(response.lastFeedbackSentAt),
      response.hrInterviewFile && HrInterviewFile.fromResponse(response.hrInterviewFile),
      response.userGroup !== undefined ? UserGroupRef.fromResponse(response.userGroup) : undefined,
      answerPairs,
      response.createdAt === undefined ? undefined : new Date(response.createdAt),
      response.feedbackStatus,
    );
  }
}

type QuestionAnswerPair = {
  interviewFormatContentId: string;
  question: string;
  answers: StructuredAnswer[];
};

type StructuredAnswer = {
  documentId?: string;
  subCategoryIds: string[];
  content: string;
};
