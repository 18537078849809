import React from "react";

import { AmeAccordion } from "../../../atoms/accordions/AmeAccordion";

import { ChoiceOption } from "./ChoiceOption";
import { ChoiceOptionParams } from "./type";

type Props = {
  subGroupName: string;
  options: ChoiceOptionParams[];
};

export const ChoiceSubGroup: React.FC<Props> = ({ subGroupName, options }) => {
  return (
    <AmeAccordion title={subGroupName}>
      {options.map((option, index) => {
        return (
          <ChoiceOption
            key={index}
            name={option.name}
            isChecked={option.isChecked}
            toggleOption={option.toggleOption}
          />
        );
      })}
    </AmeAccordion>
  );
};
