import React, { FormEventHandler } from "react";

import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
  onSubmit: FormEventHandler;
}

export const LoginFormsWrapper: React.FC<Props> = ({ children, onSubmit }) => {
  return (
    <AmeBox component="form" sx={{ marginBottom: "32px" }} onSubmit={onSubmit}>
      {children}
    </AmeBox>
  );
};
