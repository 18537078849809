import React from "react";

import { CalculatedSegmentDefinition } from "../../../../../models/CalculatedSegmentDefinition";
import { CalculatedSegmentKey } from "../../../../../store/autogenApi";
import { AmeTable, AmeTableBody, AmeTableHead, AmeTableHeadCell, AmeTableRow } from "../../../../atoms/table/AmeTable";

import { GraphSegmentSettingTableRow } from "./GraphSegmentSettingTableRow";

interface Props {
  calculatedSegmentConditions: CalculatedSegmentDefinition[];
  onDefinitionUpdate: (segmentKey: CalculatedSegmentKey, strategy: string) => void;
}

export const GraphSegmentSettingTable: React.FC<Props> = (props) => {
  // MEMO: CalculatedSegmentKeyをどう取得するかはbackendの実装依存だけど、一旦これで。
  return (
    <AmeTable useCustomCellWidth>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell width="25%">属性</AmeTableHeadCell>
          <AmeTableHeadCell width="75%">定義</AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {props.calculatedSegmentConditions.map((condition) => (
          <GraphSegmentSettingTableRow
            key={condition.segmentKey}
            segmentKey={condition.segmentKey}
            currentStrategy={condition.selectedCalculationStrategy}
            onDefinitionUpdate={props.onDefinitionUpdate}
          />
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
