import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeCard, AmeCardContent } from "../../muiWrapper/AmeCard";

interface Props {
  title: string;
  children: React.ReactNode;
}

export const StepCard: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  return (
    <AmeCard sx={{ borderRadius: "4px" }} variant="outlined">
      <AmeBox
        sx={{
          height: "56px",
          lineHeight: "56px",
          borderBottom: `1px solid ${theme.common.border.gray40}`,
          backgroundColor: theme.brand.secondary[20],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          letterSpacing: "0.04em",
        }}
      >
        <AmeTypography component="h5" color={"secondary"}>
          {props.title}
        </AmeTypography>
      </AmeBox>

      <AmeCardContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "24px",
          padding: "24px",
        }}
      >
        {props.children}
      </AmeCardContent>
    </AmeCard>
  );
};
