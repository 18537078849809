import { SegmentKey } from "../../models/constants/values";
import { ExEmployeeInterviewListResponse, ExEmployeeInterviewResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { ComputationTaskModel } from "./computationTasks";
import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export type InterviewStatus = "PENDING" | "ANALYZED" | "TENTATIVELY_ANALYZED" | "ANALYZING" | "FAILED";

export class ExEmployeeInterviewModel {
  private constructor(
    public readonly exEmployeeInterviewId: string,
    public readonly employeeId: string,
    public readonly customerCreatedId: string,
    public readonly interviewRecordingId: string | undefined,
    public readonly summary: string | undefined,
    public readonly answer: string,
    public readonly createdAt: Date,
    public readonly documentCount: number,
    public readonly analyzedAt?: Date,
    public readonly task?: ComputationTaskModel,
  ) {}

  public getStatus(): InterviewStatus {
    if (this.task?.computationTaskStatus !== undefined) {
      if (this.task.computationTaskStatus === "FAILED") {
        return "FAILED";
      }
      if (["PENDING", "DELETING", "CREATING", "UPDATING"].includes(this.task.computationTaskStatus)) {
        return "ANALYZING";
      }
    }
    if (this.analyzedAt) {
      return "ANALYZED";
    }
    return "PENDING";
  }

  public getStatusText(): string {
    switch (this.getStatus()) {
      case "ANALYZED":
        return "データ確定済み";
      case "TENTATIVELY_ANALYZED":
        return "データ仮確定";
      case "ANALYZING":
        return "分析中";
      case "PENDING":
        return "データ確定前";
      case "FAILED":
        return "分析失敗";
    }
  }

  public isAnalyzable(): boolean {
    return ["PENDING", "FAILED"].includes(this.getStatus());
  }

  public static fromResponse(exEmployeeInterview: ExEmployeeInterviewResponse): ExEmployeeInterviewModel {
    return new ExEmployeeInterviewModel(
      exEmployeeInterview.exEmployeeInterviewId,
      exEmployeeInterview.employee.employeeId,
      exEmployeeInterview.employee.customerCreatedId,
      exEmployeeInterview.interviewRecordingId,
      exEmployeeInterview.summary,
      exEmployeeInterview.answer,
      new Date(exEmployeeInterview.createdAt),
      exEmployeeInterview.documentCount,
      exEmployeeInterview.analyzedAt ? new Date(exEmployeeInterview.analyzedAt) : undefined,
      exEmployeeInterview.task && ComputationTaskModel.fromResponse(exEmployeeInterview.task),
    );
  }
}

export class ExEmployeeInterviewListModel {
  private constructor(
    public readonly interviews: ExEmployeeInterviewModel[],
    public readonly totalCount: number,
  ) {}

  public static fromResponse(interviewList: ExEmployeeInterviewListResponse): ExEmployeeInterviewListModel {
    return new ExEmployeeInterviewListModel(
      interviewList.interviews.map(ExEmployeeInterviewModel.fromResponse),
      interviewList.totalCount,
    );
  }
}

export const useCreateInterview = () => {
  const [createInterview] = defaultApi.endpoints.createExEmployeeInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createInterview,
  });
};

export const useAnalyzeInteriew = (options?: UseMutationOptions) => {
  const [analyzeExEmployeeInterview] = defaultApi.endpoints.analyzeExEmployeeInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: analyzeExEmployeeInterview,
    onError: options?.onError,
  });
};

export const useEditInterview = (options?: UseMutationOptions) => {
  const [editInterview] = defaultApi.endpoints.editExEmployeeInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: editInterview,
    onError: options?.onError,
  });
};

export const useGetExEmployeeInterviews = (count: number, offset: number, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getExEmployeeInterviews.useQuery(
    { count, offset },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: ExEmployeeInterviewListModel.fromResponse,
    onError: options?.onError,
  });
};

export const useSummarizeInterview = (options?: UseMutationOptions) => {
  const [summarizeInterview] = defaultApi.endpoints.summarizeExEmployeeInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: summarizeInterview,
    onError: options?.onError,
  });
};

export const useGetInterviewDetail = (interviewId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getExEmployeeInterview.useQuery(
    { exEmployeeInterviewId: interviewId },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (data) => ({
      ...data,
      retirementReasons: {
        ...data.retirementReasons,
        values: data.retirementReasons.values.filter(({ score }) => score),
      },
    }),
    onError: options?.onError,
  });
};

export const useUnfixInterview = () => {
  const [unfixInterview] = defaultApi.endpoints.unfixExEmployeeInterview.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: unfixInterview,
  });
};

export type InterviewKpiReportFilters = { [key in keyof typeof SegmentKey]: string[] };

export const useGetInterviewKpiReport = (
  periodId: number,
  searchParams: InterviewKpiReportFilters,
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.getInterviewKpiReport.useQuery(
    {
      periodId: periodId,
      sexes: searchParams.SEX,
      departments: searchParams.DEPARTMENT,
      free1S: searchParams.FREE1,
      free2S: searchParams.FREE2,
      ages: searchParams.AGE,
      monthsOfServices: searchParams.MONTHS_OF_SERVICE,
    },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (data) => data,
    onError: options?.onError,
  });
};

export const useGetInterviewFeedback = (options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewFeedbackSettings.useQuery(undefined, {
    pollingInterval: options?.pollingInterval,
    skip: options?.skip,
  });
  return useQueryResult({
    result,
    fromResponse: (res) => res,
    onError: options?.onError,
  });
};

export const useSetInterviewFeedbackSetting = (options?: UseMutationOptions) => {
  const [updateInterviewFeedbackSettings] = defaultApi.endpoints.updateInterviewFeedbackSettings.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateInterviewFeedbackSettings,
    onError: options?.onError,
  });
};
