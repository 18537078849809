import React from "react";

import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  width?: string;
  height?: string;
  component?: "div" | "span";
};
export const Spacer: React.FC<Props> = ({ width, height, component = "div" }) => (
  <AmeBox
    component={component}
    sx={{
      display: component === "span" ? "inline-block" : undefined,
      width: width ? width : 0,
      height: height ? height : 0,
    }}
  />
);
