import React, { useCallback, useState } from "react";

import { InputSegmentDefinitionModel } from "../../../../../models/Setting";
import { InputSegmentKey as InputSegmentKeyType } from "../../../../../store/autogenApi";
import { AmeCheckbox } from "../../../../atoms/checkbox/AmeCheckbox";
import { AmeTableBodyCell, AmeTableRow } from "../../../../atoms/table/AmeTable";

interface Props {
  segmentDefinition: InputSegmentDefinitionModel;
  onUpdate: (segmentKey: InputSegmentKeyType, definitionId: string, isDeprecated: boolean) => Promise<void>;
}

export const SegmentDefinitionRow: React.FC<Props> = ({ segmentDefinition, onUpdate }) => {
  const [checked, setChecked] = useState<boolean>(!segmentDefinition.isDeprecated);

  const onChange = useCallback(
    async (_: unknown, checked: boolean) => {
      setChecked((prev) => !prev);
      await onUpdate(segmentDefinition.segmentKey, segmentDefinition.inputSegmentDefinitionId, !checked);
    },
    [onUpdate, segmentDefinition.inputSegmentDefinitionId, segmentDefinition.segmentKey],
  );

  return (
    <AmeTableRow>
      <AmeTableBodyCell align="center">
        <AmeCheckbox size="small" checked={checked} onChange={onChange} />
      </AmeTableBodyCell>
      <AmeTableBodyCell>{segmentDefinition.segmentValue}</AmeTableBodyCell>
    </AmeTableRow>
  );
};
