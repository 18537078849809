/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyReportResponseResponseStats
 */
export interface SurveyReportResponseResponseStats {
    /**
     * 
     * @type {number}
     * @memberof SurveyReportResponseResponseStats
     */
    answeredCount: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyReportResponseResponseStats
     */
    sheetCount: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyReportResponseResponseStats
     */
    axis: string;
}

export function SurveyReportResponseResponseStatsFromJSON(json: any): SurveyReportResponseResponseStats {
    return SurveyReportResponseResponseStatsFromJSONTyped(json, false);
}

export function SurveyReportResponseResponseStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyReportResponseResponseStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answeredCount': json['answeredCount'],
        'sheetCount': json['sheetCount'],
        'axis': json['axis'],
    };
}

export function SurveyReportResponseResponseStatsToJSON(value?: SurveyReportResponseResponseStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answeredCount': value.answeredCount,
        'sheetCount': value.sheetCount,
        'axis': value.axis,
    };
}


