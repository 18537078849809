import React, { useContext } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../../organisms/common/DataSourceContext";
import { FeedbackEmailSettingsForm } from "../../../../../organisms/settings/hr-interview-mails";

export const FeedbackMailSetting: React.FC = () => {
  const {
    setting: {
      globalSetting: {
        feedbackMailSenderName,
        feedbackMailSubject,
        feedbackMailBody,
        feedbackMailSignature,
        feedbackSurveyBody,
        feedbackSurveyTitle,
      },
    },
  } = useContext(DataSourceContext);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader currentTitle={"面談後アンケート設定"} items={[{ title: "設定", to: "/settings" }]} />
      <PageLayoutBody>
        <AmeBox sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <PaperWrapper>
            <PaperHeader>
              <AmeTypography fontSize="body1">面談後アンケート設定</AmeTypography>
            </PaperHeader>
            <PaperBody>
              <FeedbackEmailSettingsForm
                feedbackMailSenderName={feedbackMailSenderName || ""}
                feedbackMailSubject={feedbackMailSubject || ""}
                feedbackMailBody={feedbackMailBody || ""}
                feedbackMailSignature={feedbackMailSignature || ""}
                feedbackSurveyTitle={feedbackSurveyTitle || ""}
                feedbackSurveyBody={feedbackSurveyBody || ""}
              />
            </PaperBody>
          </PaperWrapper>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/settings/hr-interview-feedbacks/")({
  component: FeedbackMailSetting,
});
