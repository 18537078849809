/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnalysisScoreDimensions,
    AnalysisScoreDimensionsFromJSON,
    AnalysisScoreDimensionsFromJSONTyped,
    AnalysisScoreDimensionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface AnalysisScore
 */
export interface AnalysisScore {
    /**
     * 
     * @type {Array<AnalysisScoreDimensions>}
     * @memberof AnalysisScore
     */
    dimensions: Array<AnalysisScoreDimensions>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisScore
     */
    axis: string;
}

export function AnalysisScoreFromJSON(json: any): AnalysisScore {
    return AnalysisScoreFromJSONTyped(json, false);
}

export function AnalysisScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisScore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimensions': ((json['dimensions'] as Array<any>).map(AnalysisScoreDimensionsFromJSON)),
        'axis': json['axis'],
    };
}

export function AnalysisScoreToJSON(value?: AnalysisScore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dimensions': ((value.dimensions as Array<any>).map(AnalysisScoreDimensionsToJSON)),
        'axis': value.axis,
    };
}


