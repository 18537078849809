/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackRating,
    InterviewFeedbackRatingFromJSON,
    InterviewFeedbackRatingFromJSONTyped,
    InterviewFeedbackRatingToJSON,
} from './';

/**
 * スコア最大値 = \sum 回答された各設問が取りうる最大スコア
 * 品質スコア = (\sum 各設問でのスコア) / スコア最大値 * 100
 * @export
 * @interface UpdateInterviewFeedbackSettingsAnswer
 */
export interface UpdateInterviewFeedbackSettingsAnswer {
    /**
     * 選択肢のテキスト
     * @type {string}
     * @memberof UpdateInterviewFeedbackSettingsAnswer
     */
    answer: string;
    /**
     * 
     * @type {InterviewFeedbackRating}
     * @memberof UpdateInterviewFeedbackSettingsAnswer
     */
    rating: InterviewFeedbackRating;
    /**
     * 実際のスコア
     * @type {number}
     * @memberof UpdateInterviewFeedbackSettingsAnswer
     */
    score: number;
}

export function UpdateInterviewFeedbackSettingsAnswerFromJSON(json: any): UpdateInterviewFeedbackSettingsAnswer {
    return UpdateInterviewFeedbackSettingsAnswerFromJSONTyped(json, false);
}

export function UpdateInterviewFeedbackSettingsAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInterviewFeedbackSettingsAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer': json['answer'],
        'rating': InterviewFeedbackRatingFromJSON(json['rating']),
        'score': json['score'],
    };
}

export function UpdateInterviewFeedbackSettingsAnswerToJSON(value?: UpdateInterviewFeedbackSettingsAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer': value.answer,
        'rating': InterviewFeedbackRatingToJSON(value.rating),
        'score': value.score,
    };
}


