/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LabelType,
    LabelTypeFromJSON,
    LabelTypeFromJSONTyped,
    LabelTypeToJSON,
} from './';

/**
 * ラベリング中のドキュメント
 * ラベル情報が付与されている。
 * @export
 * @interface LabelingDocumentResponse
 */
export interface LabelingDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof LabelingDocumentResponse
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof LabelingDocumentResponse
     */
    content: string;
    /**
     * 
     * @type {LabelType}
     * @memberof LabelingDocumentResponse
     */
    label: LabelType;
    /**
     * 
     * @type {number}
     * @memberof LabelingDocumentResponse
     */
    labelId: number;
}

export function LabelingDocumentResponseFromJSON(json: any): LabelingDocumentResponse {
    return LabelingDocumentResponseFromJSONTyped(json, false);
}

export function LabelingDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelingDocumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': json['documentId'],
        'content': json['content'],
        'label': LabelTypeFromJSON(json['label']),
        'labelId': json['labelId'],
    };
}

export function LabelingDocumentResponseToJSON(value?: LabelingDocumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'content': value.content,
        'label': LabelTypeToJSON(value.label),
        'labelId': value.labelId,
    };
}


