/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HrInterviewResponse,
    HrInterviewResponseFromJSON,
    HrInterviewResponseFromJSONTyped,
    HrInterviewResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewListResponse
 */
export interface HrInterviewListResponse {
    /**
     * 
     * @type {number}
     * @memberof HrInterviewListResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<HrInterviewResponse>}
     * @memberof HrInterviewListResponse
     */
    hrInterviews: Array<HrInterviewResponse>;
}

export function HrInterviewListResponseFromJSON(json: any): HrInterviewListResponse {
    return HrInterviewListResponseFromJSONTyped(json, false);
}

export function HrInterviewListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'hrInterviews': ((json['hrInterviews'] as Array<any>).map(HrInterviewResponseFromJSON)),
    };
}

export function HrInterviewListResponseToJSON(value?: HrInterviewListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'hrInterviews': ((value.hrInterviews as Array<any>).map(HrInterviewResponseToJSON)),
    };
}


