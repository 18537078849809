import { TranscriptionSentence } from "../../models/Transcription";
import { GetTranscriptionApiResponse, TranscriptionResult } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseQueryOptions } from "./utils/types";

const fromTranscriptionResult = (result: TranscriptionResult) => {
  return {
    speakers: result.speakers,
    transcriptions: result.sentences.map(TranscriptionSentence.fromResponse),
  };
};
const fromGetTranscriptionResult = (response: GetTranscriptionApiResponse) => {
  return {
    transcriptionId: response.transcriptionId,
    interviewFileId: response.interviewFileId,
    retiredSpeakerLabel: response.retiredSpeakerLabel,
    results: fromTranscriptionResult(response.results),
    status: response.status,
    text: response.text,
  };
};
export const useGetTranscription = (interviewRecordingId: string, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getTranscription.useQuery(
    { interviewRecordingId: interviewRecordingId },
    { pollingInterval: options?.pollingInterval },
  );
  return useQueryResult({
    result,
    fromResponse: fromGetTranscriptionResult,
    onError: options?.onError,
  });
};

export const useStartTranscriptionMutation = () => {
  const [startTranscription] = defaultApi.endpoints.startTranscription.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: startTranscription,
  });
};

export const useUpdateTranscription = () => {
  const [updateTranscription] = defaultApi.endpoints.updateTranscription.useMutation();

  return useMutationFunctionWrapper({
    mutationFn: updateTranscription,
  });
};

export const useSubmitTranscription = () => {
  const [submitTranscription] = defaultApi.endpoints.submitTranscription.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: submitTranscription,
  });
};
