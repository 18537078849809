import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Update: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 14 14">
      <g id="Icon">
        <path
          d="M7.00065 1.66927C5.16828 1.66927 3.5507 2.59344 2.59027 4.0026H4.33398V5.33594H0.333984V1.33594H1.66732V3.00218C2.88319 1.38371 4.81908 0.335938 7.00065 0.335938C10.6825 0.335938 13.6673 3.3207 13.6673 7.0026H12.334C12.334 4.05708 9.94619 1.66927 7.00065 1.66927ZM1.66732 7.0026C1.66732 9.94814 4.05513 12.3359 7.00065 12.3359C8.83305 12.3359 10.4506 11.4117 11.4111 10.0026H9.66732V8.66927H13.6673V12.6693H12.334V11.003C11.1181 12.6215 9.18225 13.6693 7.00065 13.6693C3.31875 13.6693 0.333984 10.6845 0.333984 7.0026H1.66732Z"
          fill="black"
        />
      </g>
    </SvgWrapper>
  );
};
