import React, { useCallback, useEffect } from "react";

import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useForm } from "react-hook-form";

import { useGetInterviewTypes } from "../../../../../../store/hooks/hr-interview-setting";
import { useCreateHrInterview } from "../../../../../../store/hooks/hrInterview";
import { useGetGroupListQuery } from "../../../../../../store/hooks/userGroups";
import { formatDateToYYYYMMDD } from "../../../../../../utils/formatter";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeFormFollowingButtonsLayout } from "../../../../../molecules/FormButtonLayouts/AmeFormFollowingButtonsLayout";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { CreateHrInterviewPageContent } from "../../../../../organisms/hr-interviews/interviews/create";
import { InterviewCreationFrom } from "../../../../../organisms/hr-interviews/interviews/create/InterviewCreationForm";

const CreateHrInterviewPage: React.FC = () => {
  const { register, control, handleSubmit, formState, setValue, reset } = useForm<InterviewCreationFrom>({
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const interviewTypesQuery = useGetInterviewTypes();
  const userGroupListQueryState = useGetGroupListQuery();
  const createHrInterview = useCreateHrInterview();
  const clearFormatId = useCallback(() => {
    setValue("interviewFormatId", undefined as unknown as string);
  }, [setValue]);

  useEffect(() => {
    if (userGroupListQueryState.data?.userGroups?.[0]?.userGroupId) {
      reset({ groupId: userGroupListQueryState.data.userGroups[0].userGroupId });
    }
  }, [reset, userGroupListQueryState]);

  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        items={[{ title: "在職者音声一覧", to: "/hr-interviews/interview-files" }]}
        currentTitle={"面談作成"}
      />
      <PageLayoutBody>
        <PaperWrapper>
          <PaperHeader>面談概要</PaperHeader>
          <PaperBody>
            <WaitForSuccess queryState={userGroupListQueryState}>
              {({ userGroups }) => (
                <WaitForSuccess queryState={interviewTypesQuery}>
                  {(types) => (
                    <CreateHrInterviewPageContent
                      isDraft={true}
                      interviewTypes={types}
                      userGroups={userGroups}
                      register={register}
                      control={control}
                      formState={formState}
                      clearFormatId={clearFormatId}
                    />
                  )}
                </WaitForSuccess>
              )}
            </WaitForSuccess>
          </PaperBody>
        </PaperWrapper>
      </PageLayoutBody>
      <AmeFormFollowingButtonsLayout
        onPrimary={handleSubmit(async (data) => {
          const res = await createHrInterview({
            createHrInterviewRequestBody: {
              employeeTenureId: data.employeeTenure.employeeTenureId,
              userGroupId: data.groupId,
              interviewFormatId: data.interviewFormatId,
              interviewTypeId: data.interviewTypeId,
              interviewDate: data.interviewDate && formatDateToYYYYMMDD(data.interviewDate),
            },
          });
          if (res.isSuccess) {
            await navigate({
              to: "/hr-interviews/interviews/$interviewId",
              params: { interviewId: res.data.hrInterviewId },
            });
          }
        })}
        onSecondary={async () => {
          await navigate({
            to: "/hr-interviews/interviews",
            search: {} as never,
          });
        }}
        secondaryText={"キャンセル"}
      />
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/hr-interviews/interviews/create")({
  component: CreateHrInterviewPage,
});
