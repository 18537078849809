/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserResponse,
    UserResponseFromJSON,
    UserResponseFromJSONTyped,
    UserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {number}
     * @memberof UserListResponse
     */
    totalCount: number;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof UserListResponse
     */
    users: Array<UserResponse>;
}

export function UserListResponseFromJSON(json: any): UserListResponse {
    return UserListResponseFromJSONTyped(json, false);
}

export function UserListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'users': ((json['users'] as Array<any>).map(UserResponseFromJSON)),
    };
}

export function UserListResponseToJSON(value?: UserListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'users': ((value.users as Array<any>).map(UserResponseToJSON)),
    };
}


