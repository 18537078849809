import React, { useCallback } from "react";

import { generateTimeDisplay } from "../../../../../../../hooks/useTimer";
import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { Pause } from "../../../../../../atoms/icon/Pause";
import { Play } from "../../../../../../atoms/icon/Play";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface Props {
  paragraphId: string;
  content: string;
  speakerLabel: string;
  startTimeSec: number;
  isPlaying: boolean;
  togglePlay: (sec?: number) => void;
  copyParagraph?: (paragraph: string) => void;
}

export const Paragraph: React.FC<Props> = ({
  togglePlay,
  startTimeSec,
  copyParagraph,
  content,
  isPlaying,
  speakerLabel,
}) => {
  const play = useCallback(() => {
    togglePlay(startTimeSec);
  }, [togglePlay, startTimeSec]);
  const copy = useCallback(() => {
    copyParagraph && copyParagraph(content);
  }, [content, copyParagraph]);
  return (
    <AmeBox sx={{ padding: "20px", display: "flex", gap: "24px" }}>
      <AmeBox>
        <AmeBox sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "4px" }}>
          <AmeButton onClick={copy} disabled={!copyParagraph} color={"basic"} variant={"outlined"} size={"small"}>
            COPY
          </AmeButton>
          <AmeBox sx={{ display: "flex", justifyContent: "center", gap: "3px", cursor: "pointer" }} onClick={play}>
            <AmeTypography component={"p"} fontSize={"caption"}>
              {generateTimeDisplay(startTimeSec)}
            </AmeTypography>
            {isPlaying ? <Pause size={18} color={"black"} /> : <Play size={18} color={"black"} />}
          </AmeBox>
          <AmeTypography component={"p"} fontSize={"caption"}>
            {speakerLabel}
          </AmeTypography>
        </AmeBox>
      </AmeBox>
      <AmeBox>
        <AmeTypography component={"p"} fontSize={"body2"}>
          {content}
        </AmeTypography>
      </AmeBox>
    </AmeBox>
  );
};
