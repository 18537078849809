import React from "react";

import { AmeTableHeadCell } from "../../../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../../../atoms/typography/AmeTypography";

interface Props {
  children: string;
}

export const InterviewerQualityDetailTableHeadCell: React.FC<Props> = ({ children }) => {
  return (
    <AmeTableHeadCell isDense>
      <AmeTypography color="basicLight" fontSize="body2">
        {children}
      </AmeTypography>
    </AmeTableHeadCell>
  );
};
