import React from "react";

import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

export const LoginErrorMessage: React.FC<Props> = ({ children }) => {
  const theme = useAmeTheme();
  return <AmeBox sx={{ color: theme.common.text.error, margin: "8px 0", textAlign: "left" }}>{children}</AmeBox>;
};
