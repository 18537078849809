import React from "react";

import { PaperBody } from "../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../atoms/paper/PaperWrapper";

interface Props {
  children: React.ReactNode;
}

export const TranscriptionTextareaWrapper: React.FC<Props> = ({ children }) => {
  return (
    <PaperWrapper>
      <PaperHeader>インタビュー内容</PaperHeader>
      <PaperBody>{children}</PaperBody>
    </PaperWrapper>
  );
};
