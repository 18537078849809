import React, { useEffect } from "react";

import { HrInterviewDetail } from "../../../../../../models/hrInterview/HrInterview";
import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeButton } from "../../../../../atoms/button/AmeButton";
import { Warning } from "../../../../../atoms/icon/Warning";
import { DefinitionList } from "../../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { IconText } from "../../../../../molecules/common/IconText";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { InterviewUpdateButton } from "./InterviewUpdateButton";

interface Props {
  interviewDetail: HrInterviewDetail;
  selectedSubCategoryId?: string;
  highlightedDocumentId?: string;
}

const createSubCateClassName = (subCategoryId: string) => `subCate-${subCategoryId}`;

export const InterviewAnswers: React.FC<Props> = ({
  interviewDetail,
  selectedSubCategoryId,
  highlightedDocumentId,
}) => {
  const theme = useAmeTheme();
  useEffect(() => {
    if (highlightedDocumentId) {
      const element = document.getElementById(highlightedDocumentId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [highlightedDocumentId]);
  useEffect(() => {
    if (selectedSubCategoryId) {
      const element = document.getElementsByClassName(createSubCateClassName(selectedSubCategoryId))[0];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [selectedSubCategoryId]);

  return (
    <PaperWrapper>
      <PaperHeader
        endElement={
          <>
            <AmeBox sx={{ display: "flex", gap: "16px" }}>
              {interviewDetail.transcription?.isTranscribing ? (
                <InterviewUpdateButton interviewDetail={interviewDetail} />
              ) : null}
              {interviewDetail.isEditable && (
                <AmeButton
                  variant="outlined"
                  color="basic"
                  size="small"
                  to={"/hr-interviews/interviews/$interviewId/transcribe"}
                  params={{ interviewId: interviewDetail.hrInterviewId }}
                >
                  編集
                </AmeButton>
              )}
            </AmeBox>
          </>
        }
      >
        議事録
      </PaperHeader>
      <PaperBody>
        {interviewDetail.interviewAnswers.some((answer) => answer.answer) ? (
          <AmeBox
            sx={[
              { "& > * + *": { marginTop: "16px" } },
              selectedSubCategoryId
                ? {
                    [`& .${createSubCateClassName(selectedSubCategoryId)}`]: {
                      background: theme.common.background.warning40,
                    },
                  }
                : false,
            ]}
          >
            {interviewDetail.structuredAnswerPairs.map((answer, index) => (
              <PaperWrapper key={answer.interviewFormatContentId}>
                <PaperBody>
                  <DefinitionList>
                    <DefinitionListItem label={`設問${index + 1}`} value={answer.question} />
                  </DefinitionList>
                  <DefinitionList>
                    <DefinitionListItem
                      label={`回答${index + 1}`}
                      value={
                        answer.answers.length
                          ? answer.answers.map((a) => (
                              <span
                                key={a.documentId}
                                className={a.subCategoryIds
                                  .map((subCategoryId) => createSubCateClassName(subCategoryId))
                                  .join(" ")}
                                id={a.documentId}
                                style={
                                  !!highlightedDocumentId && highlightedDocumentId === a.documentId
                                    ? { background: theme.brand.secondary[20] }
                                    : undefined
                                }
                              >
                                {a.content}
                              </span>
                            ))
                          : "-"
                      }
                    />
                  </DefinitionList>
                </PaperBody>
              </PaperWrapper>
            ))}
          </AmeBox>
        ) : (
          <IconText icon={<Warning color={"primary"} size={30} />}>
            音声データから生成、または手動登録してください。
          </IconText>
        )}
      </PaperBody>
    </PaperWrapper>
  );
};
