export const generateTimeDisplay = (second?: number) => {
  if (second == undefined) {
    return "不明な時間";
  }
  const hour = Math.floor(second / 60 / 60);
  const min = Math.floor(second / 60) % 60;
  const sec = Math.floor(second % 60);
  if (hour !== 0) {
    return `${hour}:${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
  } else {
    return `${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
  }
};
