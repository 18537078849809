import React from "react";

import { InputSegmentDefinitionModel } from "../../../../../models/Setting";
import { InputSegmentKey as InputSegmentKeyType, InputSegmentKey } from "../../../../../store/autogenApi";
import { AmeTable, AmeTableBody, AmeTableHead, AmeTableHeadCell, AmeTableRow } from "../../../../atoms/table/AmeTable";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { SegmentDefinitionCreator } from "./SegmentDefinitionCreator";
import { SegmentDefinitionRow } from "./SegmentDefinitionRow";

interface Props {
  inputSegmentKey: InputSegmentKey;
  segmentDefinitions: InputSegmentDefinitionModel[];
  onUpdateDefinition: (segmentKey: InputSegmentKeyType, definitionId: string, isDeprecated: boolean) => Promise<void>;
}

export const SettingsSegmentsSegmentKeyContent: React.FC<Props> = ({
  onUpdateDefinition,
  segmentDefinitions,
  inputSegmentKey,
}) => {
  return (
    <React.Fragment>
      <AmeBox sx={{ paddingBottom: "24px" }}>
        <SegmentDefinitionCreator inputSegmentKey={inputSegmentKey} />
      </AmeBox>
      <AmeTable useCustomCellWidth>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell width="64px">有効</AmeTableHeadCell>
            <AmeTableHeadCell>定義</AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {segmentDefinitions.map((segmentDefinition) => (
            <SegmentDefinitionRow
              key={segmentDefinition.inputSegmentDefinitionId}
              onUpdate={onUpdateDefinition}
              segmentDefinition={segmentDefinition}
            />
          ))}
        </AmeTableBody>
      </AmeTable>
    </React.Fragment>
  );
};
