import React from "react";

import { Control, useFieldArray, UseFormRegister, useFormState } from "react-hook-form";

import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../../atoms/table/AmeTable";
import { EditableCell } from "../../../../../atoms/table/EditableCell";
import { HrInterviewAddingRow } from "../../HrInterviewAddingRow";

import { HrInterviewFormatSettingOperationsCell } from "./HrInterviewFormatSettingOperationCell";

export interface InterviewFormatsForm {
  hrInterviewFormats: {
    hrInterviewFormatId?: string;
    hrInterviewFormatText: string;
  }[];
}

interface Props {
  register: UseFormRegister<InterviewFormatsForm>;
  control: Control<InterviewFormatsForm>;
  hrInterviewTypeId: string;
}

export const HrInterviewFormatsSettingTable: React.FC<Props> = (props) => {
  const { fields, prepend, swap, remove, insert } = useFieldArray({
    control: props.control,
    name: "hrInterviewFormats",
  });
  const formState = useFormState({ control: props.control, name: "hrInterviewFormats" });

  const duplicate = (index: number) => {
    insert(index + 1, { ...fields[index], hrInterviewFormatId: `` });
  };

  return (
    <React.Fragment>
      <AmeTable useCustomCellWidth>
        <AmeTableHead>
          <AmeTableRow>
            <AmeTableHeadCell width="128px">表示順番</AmeTableHeadCell>
            <AmeTableHeadCell>名前</AmeTableHeadCell>
            <AmeTableHeadCell width="360px">操作</AmeTableHeadCell>
          </AmeTableRow>
          <AmeTableRow>
            <AmeTableHeadCell noPadding colSpan={3}>
              <HrInterviewAddingRow
                append={() => prepend({ hrInterviewFormatId: undefined, hrInterviewFormatText: "" })}
              />
            </AmeTableHeadCell>
          </AmeTableRow>
        </AmeTableHead>
        <AmeTableBody>
          {fields.map((field, index) => (
            <AmeTableRow key={field.id} isDense>
              <AmeTableBodyCell>{index + 1}</AmeTableBodyCell>
              <AmeTableBodyCell noPadding>
                <EditableCell
                  {...props.register(`hrInterviewFormats.${index}.hrInterviewFormatText`, {
                    required: "必須項目です。",
                  })}
                  error={Boolean(formState.errors.hrInterviewFormats?.[index]?.hrInterviewFormatText)}
                />
              </AmeTableBodyCell>
              <AmeTableBodyCell>
                <HrInterviewFormatSettingOperationsCell
                  index={index}
                  rowLastIndex={fields.length - 1}
                  swap={swap}
                  duplicate={duplicate}
                  hrInterviewFormatId={field.hrInterviewFormatId!}
                  remove={remove}
                  hrInterviewTypeId={props.hrInterviewTypeId}
                />
              </AmeTableBodyCell>
            </AmeTableRow>
          ))}
        </AmeTableBody>
      </AmeTable>
    </React.Fragment>
  );
};
