/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TurnoverStatsWithForecastResponse
 */
export interface TurnoverStatsWithForecastResponse {
    /**
     * 
     * @type {number}
     * @memberof TurnoverStatsWithForecastResponse
     */
    turnoverForecast: number;
    /**
     * 
     * @type {number}
     * @memberof TurnoverStatsWithForecastResponse
     */
    turnoverCount: number;
    /**
     * 
     * @type {number}
     * @memberof TurnoverStatsWithForecastResponse
     */
    beginningEmployeeCount: number;
}

export function TurnoverStatsWithForecastResponseFromJSON(json: any): TurnoverStatsWithForecastResponse {
    return TurnoverStatsWithForecastResponseFromJSONTyped(json, false);
}

export function TurnoverStatsWithForecastResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TurnoverStatsWithForecastResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'turnoverForecast': json['turnoverForecast'],
        'turnoverCount': json['turnoverCount'],
        'beginningEmployeeCount': json['beginningEmployeeCount'],
    };
}

export function TurnoverStatsWithForecastResponseToJSON(value?: TurnoverStatsWithForecastResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'turnoverForecast': value.turnoverForecast,
        'turnoverCount': value.turnoverCount,
        'beginningEmployeeCount': value.beginningEmployeeCount,
    };
}


