import React, { ChangeEvent, useCallback } from "react";

import { useAmeTheme } from "../../../../../utils/styles/AmeTheme";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

interface Props {
  definition: string;
  handleChange: (definition: string) => void;
}

export const SegmentDefinitionCreatorForm: React.FC<Props> = ({ definition, handleChange }) => {
  const theme = useAmeTheme();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleChange(e.target.value);
    },
    [handleChange],
  );

  return (
    <React.Fragment>
      <AmeBox sx={{ display: "flex", gap: "8px", alignItems: "baseline", paddingBottom: "4px" }}>
        <AmeTypography fontSize="body2">名前</AmeTypography>
        <AmeTypography fontSize="caption">
          <AmeBox component="span" sx={{ color: theme.common.text.error }}>
            必須
          </AmeBox>
        </AmeTypography>
      </AmeBox>
      <AmeInput value={definition} onChange={onChange} fullWidth />
    </React.Fragment>
  );
};
