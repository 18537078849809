/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseFromJSONTyped,
    SurveyQuestionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SharedSurveySheetResponse
 */
export interface SharedSurveySheetResponse {
    /**
     * 
     * @type {string}
     * @memberof SharedSurveySheetResponse
     */
    displayName: string;
    /**
     * 
     * @type {Date}
     * @memberof SharedSurveySheetResponse
     */
    dueDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof SharedSurveySheetResponse
     */
    emailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedSurveySheetResponse
     */
    emailBody?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedSurveySheetResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SharedSurveySheetResponse
     */
    description: string;
    /**
     * 
     * @type {Array<SurveyQuestionResponse>}
     * @memberof SharedSurveySheetResponse
     */
    questions: Array<SurveyQuestionResponse>;
}

export function SharedSurveySheetResponseFromJSON(json: any): SharedSurveySheetResponse {
    return SharedSurveySheetResponseFromJSONTyped(json, false);
}

export function SharedSurveySheetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedSurveySheetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'dueDateTime': (new Date(json['dueDateTime'])),
        'emailSubject': !exists(json, 'emailSubject') ? undefined : json['emailSubject'],
        'emailBody': !exists(json, 'emailBody') ? undefined : json['emailBody'],
        'title': json['title'],
        'description': json['description'],
        'questions': ((json['questions'] as Array<any>).map(SurveyQuestionResponseFromJSON)),
    };
}

export function SharedSurveySheetResponseToJSON(value?: SharedSurveySheetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'dueDateTime': (value.dueDateTime.toISOString()),
        'emailSubject': value.emailSubject,
        'emailBody': value.emailBody,
        'title': value.title,
        'description': value.description,
        'questions': ((value.questions as Array<any>).map(SurveyQuestionResponseToJSON)),
    };
}


