import React from "react";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { SurveyStatus } from "../../../../../store/autogenApi";
import { CenterDotIcon } from "../../../../atoms/icon/CenterDot";
import { CheckV2Icon } from "../../../../atoms/icon/CheckV2";

import { SurveyTableBodyCellWithPrefixIconTemplate } from "./SurveyTableBodyCellWithPrefixIconTemplate";

interface Props {
  status: SurveyStatus;
}

export const SurveyStatusBodyCellContent: React.FC<Props> = ({ status }) => {
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();
  if (status === "completed") {
    return <SurveyTableBodyCellWithPrefixIconTemplate icon={<CheckV2Icon color="success" size={12} />} text="完了" />;
  }
  if (status === "running") {
    return <SurveyTableBodyCellWithPrefixIconTemplate icon={<CenterDotIcon size={6} />} text="実施中" />;
  }
  if (status === "paused") {
    return <SurveyTableBodyCellWithPrefixIconTemplate icon={<CenterDotIcon size={6} />} text="休止中" />;
  }
  if (status === "draft") {
    return <SurveyTableBodyCellWithPrefixIconTemplate icon={<CenterDotIcon size={6} />} text="下書き" />;
  }
  enqueueErrorSnackbar();
  return null;
};
