/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SurveyQuestionMoldResponse
 */
export interface SurveyQuestionMoldResponse {
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionMoldResponse
     */
    surveyQuestionMoldId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionMoldResponse
     */
    subCategoryId: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionMoldResponse
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionMoldResponse
     */
    questionText: string;
}

export function SurveyQuestionMoldResponseFromJSON(json: any): SurveyQuestionMoldResponse {
    return SurveyQuestionMoldResponseFromJSONTyped(json, false);
}

export function SurveyQuestionMoldResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyQuestionMoldResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveyQuestionMoldId': json['surveyQuestionMoldId'],
        'subCategoryId': json['subCategoryId'],
        'priority': json['priority'],
        'questionText': json['questionText'],
    };
}

export function SurveyQuestionMoldResponseToJSON(value?: SurveyQuestionMoldResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyQuestionMoldId': value.surveyQuestionMoldId,
        'subCategoryId': value.subCategoryId,
        'priority': value.priority,
        'questionText': value.questionText,
    };
}


