import React, { useCallback } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useGetInterviewFilesQuery } from "../../../../../../store/hooks/interview-files";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { AmePagination } from "../../../../../atoms/pagination/AmePagination";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { InterviewFileTable } from "../../../../../organisms/transcriptions";
import { INTERVIEW_FILES_PER_PAGE } from "../../../_authorized-for-all/interview-files";

export const Transcriptions: React.FC = () => {
  const { page } = Route.useSearch();
  const navigate = Route.useNavigate();
  const onChangePage = useCallback(
    async (pageNumber: number) => {
      await navigate({
        search: (prev) => ({ ...prev, page: pageNumber }),
      });
    },
    [navigate],
  );
  const offset = (page - 1) * INTERVIEW_FILES_PER_PAGE;
  const interviewFileQueryState = useGetInterviewFilesQuery(INTERVIEW_FILES_PER_PAGE, offset, {
    pollingInterval: 1000 * 30,
  });
  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title="退職インタビュー一覧（文字起こしver）" />
      <PageLayoutBody type="wide">
        <WaitForSuccess queryState={interviewFileQueryState}>
          {(interviewList) => (
            <React.Fragment>
              <InterviewFileTable interviewFiles={interviewList.interviewFiles} />
              <Spacer height="24px" />
              <AmeBox sx={{ display: "flex", justifyContent: "center" }}>
                <AmePagination
                  count={Math.ceil(interviewList.totalCount / INTERVIEW_FILES_PER_PAGE)}
                  onChangePage={onChangePage}
                  page={page}
                />
              </AmeBox>
            </React.Fragment>
          )}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

type QueryParams = {
  page: number;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/hidden-pages/transcriptions/")({
  component: Transcriptions,
  validateSearch: (search: Partial<QueryParams>): QueryParams => ({ page: search.page ?? 1 }),
});
