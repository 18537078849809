/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationItemAverageScoreResponse
 */
export interface EvaluationItemAverageScoreResponse {
    /**
     * 
     * @type {number}
     * @memberof EvaluationItemAverageScoreResponse
     */
    averageScore: number | null;
    /**
     * 
     * @type {string}
     * @memberof EvaluationItemAverageScoreResponse
     */
    evaluationItem: string;
}

export function EvaluationItemAverageScoreResponseFromJSON(json: any): EvaluationItemAverageScoreResponse {
    return EvaluationItemAverageScoreResponseFromJSONTyped(json, false);
}

export function EvaluationItemAverageScoreResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationItemAverageScoreResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'averageScore': json['averageScore'],
        'evaluationItem': json['evaluationItem'],
    };
}

export function EvaluationItemAverageScoreResponseToJSON(value?: EvaluationItemAverageScoreResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'averageScore': value.averageScore,
        'evaluationItem': value.evaluationItem,
    };
}


