import React from "react";

import { TranscriptionSentence } from "../../../../../models/Transcription";
import { TranscriptionStatus } from "../../../../../store/autogenApi";

import { TranscriptionFinalChecker } from "./TranscriptionFinalChecker";
import { TranscriptionTextEditor } from "./TranscriptionTextEditor";

interface Props {
  sound: Howl;
  transcriptions: TranscriptionSentence[];
  speakerMap: Map<string, number>;
  interviewRecordingId: string;
  transcriptionStatus: TranscriptionStatus;
  transcriptionText: string;
}

export const TranscriptionChecker: React.FC<Props> = ({
  sound,
  transcriptions,
  speakerMap,
  interviewRecordingId,
  transcriptionStatus,
  transcriptionText,
}) => {
  if (transcriptionStatus === "created") {
    return (
      <TranscriptionTextEditor
        sound={sound}
        speakerMap={speakerMap}
        interviewRecordingId={interviewRecordingId}
        transcriptionText={transcriptionText}
        transcriptions={transcriptions}
      />
    );
  }
  return (
    <TranscriptionFinalChecker
      sound={sound}
      transcriptions={transcriptions}
      speakerMap={speakerMap}
      interviewRecordingId={interviewRecordingId}
      transcriptionStatus={transcriptionStatus}
      transcriptionText={transcriptionText}
    />
  );
};
