import React, { useMemo } from "react";

import { GlobalSettingModel } from "../../../../../models/Setting";
import { UpdateGraphScaleSettingsBody } from "../../../../../store/autogenApi";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../atoms/table/AmeTable";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";

type GraphScaleKey = keyof UpdateGraphScaleSettingsBody;

interface Props {
  globalSetting: GlobalSettingModel;
}

export const GraphScaleSettingTable: React.FC<Props> = ({ globalSetting }) => {
  const currentValues = useMemo<Record<GraphScaleKey, number | undefined>>(() => {
    return {
      maxScaleValueOfTurnoverCount: undefined,
      maxScalePercentageOfTurnoverRate: undefined,
      maxScaleValueInEmployeeReport: globalSetting.maxScaleValueInEmployeeReport,
      maxScaleCurrentEmployeeValueInEmployeeReport: globalSetting.maxScaleCurrentEmployeeValueInEmployeeReport,
      maxScalePercentageInEmployeeReport: globalSetting.maxScalePercentageInEmployeeReport,
      maxScaleValueInSurveyReport: globalSetting.maxScaleValueInSurveyReport,
      maxScaleValueInKpiReport: globalSetting.maxScaleValueInKpiReport,
      maxMinutesInKpiReport: globalSetting.maxMinutesInKpiReport,
      maxScalePercentageInSurveyReport: globalSetting.maxScalePercentageInSurveyReport,
      maxPercentageInKpiReport: globalSetting.maxPercentageInKpiReport,
    };
  }, [globalSetting]);

  return (
    <AmeTable useCustomCellWidth>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell>
            <AmeTypography fontSize="body1">レポート種類</AmeTypography>
          </AmeTableHeadCell>
          <AmeTableHeadCell>
            <AmeTypography fontSize="body1">グラフ名</AmeTypography>
          </AmeTableHeadCell>
          <AmeTableHeadCell>
            <AmeTypography fontSize="body1">単位</AmeTypography>
          </AmeTableHeadCell>
          <AmeTableHeadCell>
            <AmeTypography fontSize="body1">目盛り最大値</AmeTypography>
          </AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        <ScaleSettingRow
          reportType="インタビューKPIレポート"
          graphName="データ取得率"
          unit="人"
          currentValue={currentValues.maxScaleValueInKpiReport}
        />
        <ScaleSettingRow
          reportType="インタビューKPIレポート"
          graphName="データ取得率"
          unit="%"
          currentValue={currentValues.maxPercentageInKpiReport}
        />
        <ScaleSettingRow
          reportType="インタビューKPIレポート"
          graphName="録音時間長さ"
          unit="分"
          currentValue={currentValues.maxMinutesInKpiReport}
        />
        <ScaleSettingRow
          reportType="従業員情報レポート"
          graphName="在籍人数"
          unit="人"
          currentValue={currentValues.maxScaleCurrentEmployeeValueInEmployeeReport}
        />
        <ScaleSettingRow
          reportType="従業員情報レポート"
          graphName="退職人数、入職人数"
          unit="人"
          currentValue={currentValues.maxScaleValueInEmployeeReport}
        />
        <ScaleSettingRow
          reportType="従業員情報レポート"
          graphName="退職率、入職率"
          unit="%"
          currentValue={currentValues.maxScalePercentageInEmployeeReport}
        />
        <ScaleSettingRow
          reportType="アンケートレポート"
          graphName="アンケート回答率"
          unit="%"
          currentValue={currentValues.maxScalePercentageInSurveyReport}
        />
        <ScaleSettingRow
          reportType="アンケートレポート"
          graphName="アンケート回答率"
          unit="人"
          currentValue={currentValues.maxScaleValueInSurveyReport}
        />
      </AmeTableBody>
    </AmeTable>
  );
};

const ScaleSettingRow: React.FC<{
  reportType: string;
  graphName: string;
  unit: string;
  currentValue: number | undefined;
}> = ({ reportType, graphName, unit, currentValue }) => {
  // reportTypeによって遷移先を変更
  const getHref = (type: string) => {
    const routes = {
      インタビューKPIレポート: "/interview-kpi-report",
      従業員情報レポート: "/employee-report",
      アンケートレポート: "/survey-report",
    };
    return routes[type as keyof typeof routes] || "#";
  };

  return (
    <AmeTableRow>
      <AmeTableBodyCell>
        <AmeButton variant="text" to={getHref(reportType)}>
          {reportType}
        </AmeButton>
      </AmeTableBodyCell>
      <AmeTableBodyCell>
        <AmeTypography fontSize="body1">{graphName}</AmeTypography>
      </AmeTableBodyCell>
      <AmeTableBodyCell>
        <AmeTypography fontSize="body1">{unit}</AmeTypography>
      </AmeTableBodyCell>
      <AmeTableBodyCell>
        <AmeTypography fontSize="body1">{currentValue}</AmeTypography>
      </AmeTableBodyCell>
    </AmeTableRow>
  );
};
