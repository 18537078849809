import React from "react";

import { Control } from "react-hook-form";

import { TagCheckboxesFormType } from "..";
import { SegmentNames } from "../../../../../../../models/Setting";
import { SegmentKey } from "../../../../../../../store/autogenApi";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

import { RetirementVoiceListHeaderCheckbox } from "./RetirementVoickListHeaderCheckbox";

interface Props {
  segmentAxis: SegmentNames;
  control: Control<TagCheckboxesFormType>;
}

export const RetirementVoiceListHeader: React.FC<Props> = ({ segmentAxis, control }) => {
  return (
    <AmeBox sx={{ display: "flex", justifyContent: "right", gap: "16px" }}>
      {Object.entries(segmentAxis).map(([segmentKey, segmentLabel]) => (
        <RetirementVoiceListHeaderCheckbox
          key={segmentKey}
          control={control}
          name={segmentKey as SegmentKey}
          label={segmentLabel}
        />
      ))}
    </AmeBox>
  );
};
