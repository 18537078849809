import React, { useCallback } from "react";

import { SubCategoryModel } from "../../../../../models/SubCategory";
import { useDeleteSubCategoryMutation, useUpdateSubCategoryMutation } from "../../../../../store/hooks/subCategories";

import { SubCategoryInfo } from "./SubCategoryInfo";

interface Props {
  subCategories: SubCategoryModel[];
}

export const SubCategoryList: React.FC<Props> = ({ subCategories }) => {
  const deleteSubCategory = useDeleteSubCategoryMutation();
  const updateSubCategory = useUpdateSubCategoryMutation();

  const onUpdateSubCategory = useCallback(
    (args: { subCategoryId: string; displayName: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateSubCategory({
        subCategoryId: args.subCategoryId,
        updateSubCategoryRequestBody: {
          displayName: args.displayName,
        },
      });
    },
    [updateSubCategory],
  );

  return (
    <React.Fragment>
      {subCategories.map((subCategory) => (
        <SubCategoryInfo
          key={subCategory.subCategoryId}
          categoryId={subCategory.categoryId}
          subCategoryId={subCategory.subCategoryId}
          computationTaskStatus={subCategory.computationTaskStatus}
          displayName={subCategory.displayName}
          updateSubCategory={onUpdateSubCategory}
          deleteSubCategory={deleteSubCategory}
        />
      ))}
    </React.Fragment>
  );
};
