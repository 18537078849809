import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Amplify } from "aws-amplify";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { ConfigurationResponse } from "../../../../store/autogenApi";
import { createNonAuthorizedApiClient } from "../../../../utils/api/ApiClientFactory";

import { LoginManager } from "./LoginManager";
export type CognitoUserWithChallengeName = CognitoUser & { challengeName?: string };
export const isGlobalCognitoKey = "isGlobalCognito";

type CurrentCognitoUserContextType = {
  currentCognitoUser: CognitoUserWithChallengeName | undefined;
  setCurrentCognitoUser: (cognitoUser?: CognitoUserWithChallengeName) => void;
  isGlobalCognito: string;
  setIsGlobalCognito: (isGlobalCognito: string) => void;
};
// For React
export const CurrentCognitoUserContext = createContext<CurrentCognitoUserContextType>({
  currentCognitoUser: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setCurrentCognitoUser: (cognitoUser?: CognitoUserWithChallengeName) => {},
  isGlobalCognito: "false",
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setIsGlobalCognito: (isGlobalCognito: string) => {},
});

export const useCognitoUser = (): CognitoUserWithChallengeName | undefined => {
  // CognitoUserAuthenticator を予めアプリケーションのルートコンポーネントで呼んでいないと使えません。
  const { currentCognitoUser } = useContext(CurrentCognitoUserContext);
  return currentCognitoUser;
};
export const Authenticator: React.VFC<{ children: React.ReactNode }> = (props) => {
  const [currentCognitoUserRef, setCurrentCognitoUserRef] = useState<{ ref?: CognitoUserWithChallengeName }>();
  const setCurrentCognitoUser = (cognitoUser?: CognitoUserWithChallengeName) =>
    setCurrentCognitoUserRef({ ref: cognitoUser });
  const currentCognitoUser = useMemo(() => currentCognitoUserRef?.ref, [currentCognitoUserRef]);
  const [cognitoUserIsFetched, setCognitoUserIsFetched] = useState<boolean>(false);

  const isBeforeSignIn = useCallback((cognitoUser?: CognitoUserWithChallengeName): boolean => {
    if (cognitoUser === undefined) {
      return true;
    }
    return cognitoUser.challengeName === "SMS_MFA" || cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED";
  }, []);

  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const [isGlobalCognito, setIsGlobalCognito] = useLocalStorage<string>(isGlobalCognitoKey, "false");

  useEffect(() => {
    (async () => {
      try {
        let result: ConfigurationResponse | undefined = undefined;
        try {
          result = await createNonAuthorizedApiClient().getConfig();
        } catch (_) {
          enqueueErrorSnackbar({ title: "認証情報が取得できませんでした。" });
          throw new Error("認証情報が取得できませんでした。");
        }
        if (isGlobalCognito === "true") {
          Amplify.configure({
            Auth: {
              userPoolId: result.globalUserPoolId,
              userPoolWebClientId: result.globalWebClientId,
              region: "ap-northeast-1",
            },
          });
        } else {
          Amplify.configure({
            Auth: {
              userPoolId: result.userPoolId,
              userPoolWebClientId: result.webClientId,
              region: "ap-northeast-1",
            },
          });
        }
        setCurrentCognitoUser(await Auth.currentAuthenticatedUser());
      } finally {
        setCognitoUserIsFetched(true);
      }
    })();
  }, [enqueueErrorSnackbar, isGlobalCognito]);

  if (!cognitoUserIsFetched) {
    return null;
  }

  return (
    <CurrentCognitoUserContext.Provider
      value={{ currentCognitoUser, setCurrentCognitoUser, isGlobalCognito, setIsGlobalCognito }}
    >
      {isBeforeSignIn(currentCognitoUser) ? <LoginManager /> : props.children}
    </CurrentCognitoUserContext.Provider>
  );
};
