import React from "react";

import { useSp } from "../../../../hooks/useSp";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../muiWrapper/AmeBox";
import { Spacer } from "../../spacers/Spacer";
import { AmeTypography } from "../../typography/AmeTypography";

interface Props {
  label: string;
  value: React.ReactNode;
}

export const DefinitionListItem: React.FC<Props> = ({ label, value }) => {
  const sp = useSp();

  return sp ? (
    <SpDefinitionListItem label={label} value={value} />
  ) : (
    <LaptopDefinitionListItem label={label} value={value} />
  );
};

const LaptopDefinitionListItem: React.FC<Props> = ({ label, value }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        borderBottom: `1px solid ${theme.common.border.gray40}`,
        padding: "0 0 8px",
        flexShrink: 1,
        flexGrow: 1,
        maxWidth: "100%",
        "& > *": { overflowWrap: "break-word" },
      }}
    >
      <AmeTypography fontSize="caption">{label}</AmeTypography>
      <Spacer height="2px" />
      <AmeTypography>{value || "-"}</AmeTypography>
    </AmeBox>
  );
};

const SpDefinitionListItem: React.FC<Props> = ({ label, value }) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        borderBottom: `1px solid ${theme.common.border.gray40}`,
        padding: "16px 0",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <AmeBox sx={{ flexBasis: "50%" }}>
        <Spacer height="6px" />
        <AmeTypography fontSize="caption">{label}</AmeTypography>
      </AmeBox>
      <AmeBox sx={{ flexBasis: "50%" }}>
        <AmeTypography textAlign="right">{value || "-"}</AmeTypography>
      </AmeBox>
    </AmeBox>
  );
};
