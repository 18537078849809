import React from "react";

import { Employee } from "../../../../../models/Employee";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { InterviewFileTmpData } from "../common/useInterviewFileTmpDataList";

import { FileListRegisterWizard } from "./FileListRegisterWizard";
import { InterviewFileImporter } from "./InterviewFileImporter";

interface Props {
  setInterviewFiles: (files: File[]) => void;
  interviewFiles: File[];
  periodId: number;
  uploadInterviewFile: (
    file: File,
    updatePercentage: (percentage: number) => void,
  ) => Promise<{ tmpFilename: string; suggest?: Employee }>;
  onSubmitInterviewFileMaps: (tmpDataList: InterviewFileTmpData[]) => Promise<void>;
}

export const SpInterviewFileRegisterContent: React.FC<Props> = (props) => {
  return (
    <AmeBox sx={{ height: "100%", position: "relative", display: "flex", flexDirection: "column" }}>
      {props.interviewFiles.length ? (
        <FileListRegisterWizard
          interviewFiles={props.interviewFiles}
          periodId={props.periodId}
          setInterviewFiles={props.setInterviewFiles}
          uploadInterviewFile={props.uploadInterviewFile}
          onSubmitInterviewFileMaps={props.onSubmitInterviewFileMaps}
        />
      ) : (
        <InterviewFileImporter setInterviewFiles={props.setInterviewFiles} />
      )}
    </AmeBox>
  );
};
