/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncCognitoUsersRequestBody
 */
export interface SyncCognitoUsersRequestBody {
    /**
     * Admin として登録する auth IDs (Cognito の sub)
     * @type {Array<string>}
     * @memberof SyncCognitoUsersRequestBody
     */
    adminAuthIds: Array<string>;
}

export function SyncCognitoUsersRequestBodyFromJSON(json: any): SyncCognitoUsersRequestBody {
    return SyncCognitoUsersRequestBodyFromJSONTyped(json, false);
}

export function SyncCognitoUsersRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncCognitoUsersRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminAuthIds': json['adminAuthIds'],
    };
}

export function SyncCognitoUsersRequestBodyToJSON(value?: SyncCognitoUsersRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminAuthIds': value.adminAuthIds,
    };
}


