import { Setting } from "../../models/Setting";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useGetSetting = () => {
  const result = defaultApi.endpoints.getSetting.useQuery();
  return useQueryResult({
    result,
    fromResponse: Setting.fromResponse,
  });
};

export const useRegisterSegmentName = () => {
  const [registerSegmentName] = defaultApi.endpoints.registerSegmentName.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: registerSegmentName,
  });
};

export const useRestoreInterviewAttributeMutation = () => {
  const [registerSegmentName] = defaultApi.endpoints.restoreInterviewAttribute.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: registerSegmentName,
  });
};

export const useUpdateEmailSettings = (options?: UseMutationOptions) => {
  const [updateEmailSenderName] = defaultApi.endpoints.updateEmailSettings.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateEmailSenderName,
    onError: options?.onError,
  });
};

export const useUpdateGraphScaleSettings = (options?: UseMutationOptions) => {
  const [updateGraphScaleSettings] = defaultApi.endpoints.updateGraphScaleSettings.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: updateGraphScaleSettings,
    onError: options?.onError,
  });
};
