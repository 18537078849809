import React from "react";

import { AmeButton } from "../../../../../../atoms/button/AmeButton";
import { Email } from "../../../../../../atoms/icon/Email";
import { PaperBody } from "../../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../../atoms/spacers/Spacer";
import { AmeDialog } from "../../../../../../molecules/dialog/AmeDialog";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface Props {
  hrInterviewId: string;
  forceNotify: boolean;
}

export const SendFeedBack: React.FC<Props> = ({ hrInterviewId, forceNotify }) => {
  const [open, setOpen] = React.useState(forceNotify);
  return (
    <>
      <AmeButton variant="outlined" color="basic" onClick={() => setOpen(true)}>
        面談後アンケート送信
      </AmeButton>
      <AmeDialog open={open} onClose={() => setOpen(false)}>
        <PaperWrapper>
          <PaperHeader>面談後アンケート送信</PaperHeader>
          <PaperBody>
            <PaperWrapper>
              <PaperHeader>アンケートの送付方法を選択してください。</PaperHeader>
              <PaperBody>
                <AmeBox sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <AmeBox>
                    <AmeButton
                      disabled
                      startIcon={<Email color={"inherit"} />}
                      fullWidth
                      variant={"outlined"}
                      color={"basic"}
                    >
                      SMSを送信する(開発中)
                    </AmeButton>
                  </AmeBox>
                  <AmeBox>
                    <AmeButton
                      to={"/hr-interviews/interviews/$interviewId/send-email"}
                      params={{ interviewId: hrInterviewId }}
                      startIcon={<Email color={"inherit"} />}
                      fullWidth
                      variant={"outlined"}
                      color={"basic"}
                    >
                      メールを送信する
                    </AmeButton>
                  </AmeBox>
                </AmeBox>
              </PaperBody>
            </PaperWrapper>
            <Spacer height={"16px"} />
          </PaperBody>
        </PaperWrapper>
      </AmeDialog>
    </>
  );
};
