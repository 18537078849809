import React, { useEffect, useMemo, useState } from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateView } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";

import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { getBaseStyle } from "../AmeTextField";

type Props = {
  value?: Date;
  onCommitDate?: (date: Date | null) => void;
  format?: string | undefined;
  views?: readonly DateView[] | undefined;
};

export const AmeDateInput: React.FC<Props> = ({ value, onCommitDate, format = undefined, views = undefined }) => {
  const theme = useAmeTheme();
  const baseStyle = useMemo(() => getBaseStyle(theme, "normal"), [theme]);
  const [date, setDate] = useState(value ?? null);
  useEffect(() => {
    if (!value) {
      setDate(null);
      return;
    }
    setDate((currentDate) => {
      if (currentDate !== value) {
        return value;
      }
      return currentDate;
    });
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <DatePicker sx={baseStyle} value={date} onChange={onCommitDate} format={format} views={views} />
    </LocalizationProvider>
  );
};
