import React, { ChangeEvent } from "react";

import { Feedback } from "../../../../../../store/hooks/interviewer";
import { ChoosableList } from "../../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../../atoms/list/ChoosableListItem";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeDropbox_old } from "../../../../../molecules/dropbox/AmeDropbox";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

interface Props {
  selectedFeedback: Feedback | undefined;
  feedbacks: Feedback[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const InterviewFileSelector: React.FC<Props> = ({ feedbacks, selectedFeedback, onChange }) => {
  return (
    <AmeBox sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
      <AmeTypography component="h6">絞り込み</AmeTypography>
      <AmeBox sx={{ width: "320px" }}>
        <AmeDropbox_old
          size="small"
          value={selectedFeedback?.interviewRecordingId}
          displaySelected={selectedFeedback?.originalFileName}
          onChange={onChange}
        >
          <ChoosableList>
            {feedbacks.map((interviewFile) => (
              <ChoosableListItem key={interviewFile.interviewRecordingId} value={interviewFile.interviewRecordingId}>
                {interviewFile.originalFileName}
              </ChoosableListItem>
            ))}
            <ChoosableListItem>選択なし</ChoosableListItem>
          </ChoosableList>
        </AmeDropbox_old>
      </AmeBox>
    </AmeBox>
  );
};
