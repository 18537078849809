import React from "react";

import { useSp } from "../../../hooks/useSp";
import { CSS_SP_MEDIA_QUERY } from "../../../utils/mediaQuery";
import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";
import { AmeTypography } from "../typography/AmeTypography";

interface Props {
  title: string;
  endElement?: React.ReactNode;
}

export const PageLayoutHeader: React.FC<Props> = ({ title, endElement }) => {
  const theme = useAmeTheme();
  const sp = useSp();
  return (
    <AmeBox
      sx={{
        height: "80px",
        borderBottom: `1px solid ${theme.common.border.gray40}`,
        padding: "0 16px",
        display: "flex",
        alignItems: "center",
        fontSize: theme.typography.body1.fontSize,
        [CSS_SP_MEDIA_QUERY]: {
          height: "52px",
          fontSize: theme.typography.body2.fontSize,
        },
      }}
    >
      <AmeBox sx={{ flexGrow: 1 }}>
        <AmeTypography component="h3" textAlign={sp ? "center" : "left"}>
          {title}
        </AmeTypography>
      </AmeBox>
      {sp ? null : endElement}
    </AmeBox>
  );
};
