/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackResponse,
    InterviewFeedbackResponseFromJSON,
    InterviewFeedbackResponseFromJSONTyped,
    InterviewFeedbackResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFeedbackListResponse
 */
export interface InterviewFeedbackListResponse {
    /**
     * 
     * @type {Array<InterviewFeedbackResponse>}
     * @memberof InterviewFeedbackListResponse
     */
    feedbacks: Array<InterviewFeedbackResponse>;
}

export function InterviewFeedbackListResponseFromJSON(json: any): InterviewFeedbackListResponse {
    return InterviewFeedbackListResponseFromJSONTyped(json, false);
}

export function InterviewFeedbackListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFeedbackListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbacks': ((json['feedbacks'] as Array<any>).map(InterviewFeedbackResponseFromJSON)),
    };
}

export function InterviewFeedbackListResponseToJSON(value?: InterviewFeedbackListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbacks': ((value.feedbacks as Array<any>).map(InterviewFeedbackResponseToJSON)),
    };
}


