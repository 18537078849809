import React from "react";

import { Box } from "@mui/material";

import analyzingGif from "../../../assets/analyzing.gif";

interface Props {
  size?: number;
}

export const Analyzing: React.FC<Props> = ({ size = 16 }) => (
  <Box sx={{ height: size }} component={"img"} src={analyzingGif} alt={"解析中"} />
);
