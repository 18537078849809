import React, { useCallback, useContext } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Tooltip } from "@mui/material";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Controller, useForm } from "react-hook-form";

import { useHrPentestSnackbar } from "../../../../../../hooks/useHrPentestSnackbar";
import { Employee } from "../../../../../../models/Employee";
import { InterviewFile } from "../../../../../../models/InterviewFile";
import { UserGroup } from "../../../../../../models/UserGroup";
import { useGetEmployeeListLazy } from "../../../../../../store/hooks/employees";
import { useGetInterviewFileQuery, useUpdateInterviewFile } from "../../../../../../store/hooks/interview-files";
import { useGetGroupListQuery } from "../../../../../../store/hooks/userGroups";
import { formatDateToYYYYMMDD } from "../../../../../../utils/formatter";
import { useAmeTheme } from "../../../../../../utils/styles/AmeTheme";
import { AmeFormset } from "../../../../../atoms/forms/AmeFormset";
import { BreadcrumbPageLayoutHeader } from "../../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../../atoms/layout/PageLayoutWrapper";
import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { Spacer } from "../../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../../atoms/typography/AmeTypography";
import { AmeFormButtonsLayout } from "../../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { WaitForSuccess } from "../../../../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";
import { MyselfContext } from "../../../../../organisms/common/MyselfContext";
import { SearchDropdown } from "../../../../../organisms/common/SearchDropdown";

export const InterviewFileEditPage: React.FC = () => {
  const { interviewRecordingId } = Route.useParams();
  const interviewFileQuery = useGetInterviewFileQuery(interviewRecordingId);
  const groupListQuery = useGetGroupListQuery(10000, 0);

  return (
    <WaitForSuccess queryState={interviewFileQuery}>
      {(interviewFile) => (
        <WaitForSuccess queryState={groupListQuery}>
          {({ userGroups }) => (
            <InterviewFileEditPageContent
              interviewFile={interviewFile}
              userGroups={userGroups}
              employee={interviewFile.employee}
            />
          )}
        </WaitForSuccess>
      )}
    </WaitForSuccess>
  );
};

interface Props {
  interviewFile: InterviewFile;
  employee: Partial<Employee> | undefined;
  userGroups: UserGroup[];
}

interface FormType {
  employee: Partial<Employee> | undefined;
  userGroupId: string;
}

const InterviewFileEditPageContent: React.FC<Props> = ({ interviewFile, employee, userGroups }) => {
  const theme = useAmeTheme();
  const navigate = useNavigate();
  const { myUserData } = useContext(MyselfContext);

  const { control, handleSubmit, formState } = useForm<FormType>({
    defaultValues: {
      employee: interviewFile.employee,
      userGroupId: interviewFile.userGroup?.userGroupId,
    },
  });
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();

  const getEmployee = useGetEmployeeListLazy();
  const searchEmployees = useCallback(
    async (search: string) => {
      const result = await getEmployee({
        at: formatDateToYYYYMMDD(new Date()),
        count: 10,
        offset: 0,
        customerCreatedId: search,
      });
      return result.data?.employees.map(Employee.fromResponse) ?? [];
    },
    [getEmployee],
  );
  const updateInterviewFile = useUpdateInterviewFile();

  const onSubmit = useCallback(async () => {
    await handleSubmit(async (data) => {
      const result = await updateInterviewFile({
        interviewRecordingId: interviewFile.interviewRecordingId,
        updateInterviewFileRequestBody: {
          employeeTenureId:
            data.employee !== undefined
              ? data.employee.employeeTenureId === interviewFile.employee?.employeeTenureId
                ? undefined
                : data.employee.employeeTenureId
              : null,
          userGroupId: data.userGroupId !== undefined ? data.userGroupId : null,
        },
      });
      if (result.isSuccess) {
        enqueueSuccessSnackbar({ title: "保存しました。" });
        await navigate({ to: `/interview-files/${interviewFile.interviewRecordingId}` });
      }
    })();
  }, [
    handleSubmit,
    updateInterviewFile,
    interviewFile.interviewRecordingId,
    interviewFile.employee?.employeeTenureId,
    enqueueSuccessSnackbar,
    navigate,
  ]);
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader
        currentTitle="音声データ詳細"
        items={[{ title: "音声データ一覧", to: "/interview-files" }]}
      />
      <PageLayoutBody type={"basic"}>
        <AmeTypography component="h5">{interviewFile.originalFileName}</AmeTypography>
        <Spacer height="24px" />
        <PaperWrapper>
          <PaperHeader>音声データ詳細</PaperHeader>
          <PaperBody>
            <AmeTypography fontSize="body2">紐付き先従業員番号</AmeTypography>
            <Spacer height="8px" />
            <AmeBox sx={{ height: "56px" }}>
              <Controller
                render={({ field: { onChange } }) => (
                  <SearchDropdown
                    title={"紐付き先従業員番号"}
                    placeholder={"従業員番号"}
                    currentValue={employee}
                    onSelect={(data) => {
                      onChange(data);
                    }}
                    getId={(data: Employee) => data?.employeeId}
                    getLabel={(data?: Employee) => data?.customerCreatedId || "選択なし"}
                    getDisabled={(data?: Employee) => !!data?.hasInterviewAssign}
                    renderRow={(label: string, employee?: Employee) => (
                      <AmeBox>
                        <span>{label}</span>
                        <span>
                          {employee?.hasInterviewAssign ? (
                            <Tooltip
                              title="すでに紐づけ済みです。"
                              placement="right"
                              sx={{ zIndex: theme.zIndex.tooltip }}
                            >
                              <IconButton size="small">
                                <InfoIcon fontSize="inherit" sx={{ color: theme.common.text.gray20 }} />
                              </IconButton>
                            </Tooltip>
                          ) : employee?.retireDate === undefined ? (
                            <Tooltip
                              title="データ上では現職者で登録済みです。"
                              placement="right"
                              sx={{ zIndex: theme.zIndex.tooltip }}
                            >
                              <IconButton size="small">
                                <InfoIcon fontSize="inherit" sx={{ color: theme.common.text.gray20 }} />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </span>
                      </AmeBox>
                    )}
                    getSuggests={searchEmployees}
                  />
                )}
                name={"employee"}
                control={control}
              />
            </AmeBox>
            <AmeFormset
              label={"グループ名"}
              required={!myUserData.isAdmin}
              errorMessage={formState.errors.userGroupId?.message}
            >
              <Controller
                render={({ field: { onChange } }) => (
                  <SearchDropdown
                    title={"グループの編集"}
                    placeholder={"グループ名"}
                    currentValue={interviewFile.userGroup}
                    onSelect={(data) => {
                      onChange(data?.userGroupId);
                    }}
                    getSuggests={async (search) => userGroups.filter((group) => group.displayName.includes(search))}
                    getId={(data: UserGroup) => data?.userGroupId}
                    getLabel={(data: UserGroup) => data?.displayName || "選択なし"}
                  />
                )}
                control={control}
                name={"userGroupId"}
                rules={{ required: !myUserData.isAdmin ? "グループ名を選択してください。" : undefined }}
              />
            </AmeFormset>
          </PaperBody>
        </PaperWrapper>
        <Spacer height="24px" />
        <AmeFormButtonsLayout
          onPrimary={onSubmit}
          onSecondary={async () => await navigate({ to: `/interview-files/${interviewFile.interviewRecordingId}` })}
        />
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-all/interview-files/$interviewRecordingId/edit")({
  component: InterviewFileEditPage,
});
