import React, { useMemo } from "react";

import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

export type ColumnSeriesElement = {
  name: string;
  type: string;
  data: (number | null)[];
  color: string;
};

interface Props {
  columnSeries: ColumnSeriesElement[];
  title?: string;
  options?: ApexOptions;
  height?: number;
  width?: number;
  type?: "bar" | "line";
  markersShapes?: MarkerShapeOptions[];
}

export const AmeChart: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  const options: ApexOptions = useMemo(() => {
    const colors = props.columnSeries.map((cs) => cs.color);
    return {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
          type: "xy",
        },
      },
      title: {
        text: props.title,
        style: {
          fontSize: "16px",
          fontWeight: 400,
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: props.columnSeries.map((cell) => cell.name),
      colors: colors,
      legend: {
        position: "top",
        horizontalAlign: "right",
        markers: {
          /**
           * 特に指定がない場合は以下の通り
           * - 折れ線: 丸
           * - 棒: 四角
           */
          shape: props.markersShapes || props.columnSeries.map((s) => (s.type === "line" ? "circle" : "square")),
          fillColors: props.columnSeries.map((s) => (s.type === "line" ? theme.common.text.white : s.color)),
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      // hover時とクリック時に色が変化するのを防ぐ
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "darken",
            value: 0.5,
          },
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
      },
      ...props.options,
    };
  }, [theme, props]);

  // colorを入れるとoptions.legend.markers.fillColorsなどが上書きされるので除く
  const series = useMemo(
    () =>
      props.columnSeries.map((s) => ({
        name: s.name,
        type: s.type,
        data: s.data.map((value) => {
          if (value === null) {
            return null;
          }
          if (Number.isNaN(value)) {
            return null;
          }
          if (Number.isFinite(value)) {
            return value;
          }
          return null;
        }),
      })),
    [props],
  );
  return (
    <ReactApexChart height={props.height} width={props.width} options={options} series={series} type={props.type} />
  );
};
