import { Buffer } from "buffer";

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { CircularProgress } from "@mui/material";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/query";
import { createFileRoute } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import chardet from "chardet";
import { endOfMonth, subMonths } from "date-fns";
import iconv from "iconv-lite";

import { useHrPentestSnackbar } from "../../../../../hooks/useHrPentestSnackbar";
import { UpsertableEmployee } from "../../../../../models/Employee";
import { defaultApi } from "../../../../../store/defaultApi";
import { useApplyEmployeeChangeSet } from "../../../../../store/hooks/employees";
import { getRtkCustomHookError, parseRtkError } from "../../../../../store/hooks/utils/mutationFunction";
import { createBlobFromText, saveFile } from "../../../../../utils/fileSaver";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeDateInput } from "../../../../atoms/forms/input/AmeDateInput";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { CsvImporter } from "../../../../organisms/common/CsvImporter";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { EmployeeChangeSetPreview2 } from "../../../../organisms/employee/EmployeeChangeSetPreview2";

export const EmployeeTransactionRegistrationPage: React.FC = () => {
  const { period, setting } = useContext(DataSourceContext);
  const [effectiveFrom, setEffectiveFrom] = useState<Date>(() => endOfMonth(subMonths(new Date(), 3)));
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [changeSetId, setChangeSetId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  const [addChangeSet] = defaultApi.endpoints.addEmployeeChangeSet.useMutation();
  useEffect(() => {
    setChangeSetId(undefined);
    if (!csvFile) return;
    setIsLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let request: MutationActionCreatorResult<any> | undefined = undefined;
    void (async () => {
      const arrayBuffer = await csvFile.arrayBuffer();
      const encoding = chardet.detect(new Uint8Array(arrayBuffer));
      const decodedContent = iconv.decode(Buffer.from(arrayBuffer), encoding!);
      const formData = new FormData();
      formData.append("file", new File([decodedContent], csvFile.name, { type: "text/csv" }));
      formData.append("effectiveFrom", formatDateToYYYYMMDD(effectiveFrom));
      const rawRequest = addChangeSet({
        body: formData as never,
      });
      request = rawRequest;

      const result = await rawRequest;
      if ("error" in result) {
        const parsedError = parseRtkError(result.error);
        // 明示的にabortされた場合はエラー表示を行わない
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (parsedError.type === "others" && parsedError.error.name === "AbortError") {
          return;
        }
        const rtkCustomHookError = getRtkCustomHookError(parsedError);
        enqueueErrorSnackbar({
          title: "エラーが発生しました。",
          message: rtkCustomHookError?.message,
        });
      } else {
        setChangeSetId(result.data.employeeChangeSetId);
      }
      setIsLoading(false);
    })();
    return () => {
      request?.abort();
      setIsLoading(false);
    };
  }, [addChangeSet, csvFile, effectiveFrom, enqueueErrorSnackbar]);

  const onImported = useCallback((file: File) => {
    setCsvFile(file);
  }, []);

  const navigate = useNavigate();
  const applyEmployeeChangeSet = useApplyEmployeeChangeSet();
  const onSubmit = useCallback(
    async (changeSetId: string) => {
      const result = await applyEmployeeChangeSet({ changeSetId: changeSetId });
      if (result.isSuccess) {
        await navigate({ to: "/employees", search: { page: 1 } });
      }
    },
    [navigate, applyEmployeeChangeSet],
  );

  const attributeMapper = useMemo(
    () =>
      UpsertableEmployee.generateUpsertableEmployeeMapper({
        sex: setting.segmentNames.SEX,
        department: setting.segmentNames.DEPARTMENT,
        free1: setting.segmentNames.FREE1,
        free2: setting.segmentNames.FREE2,
      }),
    [setting],
  );

  const downloadTemplate = useCallback(() => {
    const template = Object.values(attributeMapper).join(",");
    return saveFile("HRpentest 一括登録用テンプレート.csv", createBlobFromText(template, "text/csv"));
  }, [attributeMapper]);

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"従業員マスタの更新"} />
      <PageLayoutBody type={"wide"}>
        <AmeTypography component={"h6"}>従業員マスタのファイルを選択してください。</AmeTypography>
        <Spacer height="16px" />
        <AmeButton variant={"outlined"} color={"secondary"} onClick={downloadTemplate}>
          テンプレートをダウンロード
        </AmeButton>
        <Spacer height="32px" />
        <AmeBox sx={{ display: "flex", alignItems: "center" }}>
          <span>有効日</span>
          <Spacer width="8px" />
          <AmeDateInput value={effectiveFrom} onCommitDate={(date) => setEffectiveFrom(date || period.endDate)} />
        </AmeBox>
        <Spacer height="32px" />
        <CsvImporter onImported={onImported} />
        {isLoading && <CircularProgress />}
        {changeSetId ? <EmployeeChangeSetPreview2 changeSetId={changeSetId} onSubmit={onSubmit} /> : null}
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/employees/register")({
  component: EmployeeTransactionRegistrationPage,
});
