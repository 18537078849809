import React, { useCallback, useContext, useMemo } from "react";

import { SelectChangeEvent } from "@mui/material";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { useGetCategoryContent } from "../../../../../hooks/filterHooks/useCategoryChoiceGroup";
import { useGetSegmentContent } from "../../../../../hooks/filterHooks/useSegmentChoiceGroup";
import { useSelectedFilter } from "../../../../../hooks/useSelectedFilter";
import {
  InterviewReportAxis,
  InterviewReportParams,
  useInterviewReport,
  useSearchForInterviewDocuments,
} from "../../../../../store/hooks/interviewReport";
import { formatDateToYYYYMMDD } from "../../../../../utils/formatter";
import { HorizontalStackedBarChart } from "../../../../atoms/charts/HorizontalStackedBarChart";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { SimplePaper } from "../../../../atoms/paper/SimplePaper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { V2ContentWrapper } from "../../../../atoms/wrapper/V2ContentWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { ChoiceGroupProps } from "../../../../molecules/filter/ChoiceGroup/type";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { DataSourceContext } from "../../../../organisms/common/DataSourceContext";
import { InterviewFilter } from "../../../../organisms/v2/InterviewFilter";
import { RetirementVoices } from "../../../../organisms/v2/reports/interviews/RetirementVoice";
import { ReportHeader } from "../../../../organisms/v2/v2Component/ReportHeader";

export const InterviewReport: React.FC = () => {
  const { subCategories } = useContext(DataSourceContext);
  const searchParams = Route.useSearch();

  const navigate = useNavigate();
  const onChangeAxis = useCallback(
    async (e: SelectChangeEvent) => {
      await navigate({
        search: { ...searchParams, axis: e.target.value as InterviewReportAxis },
      });
    },
    [searchParams, navigate],
  );

  const interviewReportAxis = useInterviewReportAxis();
  const interviewReportState = useInterviewReport(searchParams);
  const sentencesState = useSearchForInterviewDocuments(searchParams);
  const filterGroups: ChoiceGroupProps[] = [
    useGetSegmentContent(searchParams, navigate),
    useGetCategoryContent(searchParams, navigate),
  ];
  const selectedFilter = useSelectedFilter(filterGroups);

  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"インタビューレポート"} />
      <PageLayoutBody type={"wide"}>
        <AmeBox sx={{ display: "flex", gap: "24px" }}>
          <AmeBox sx={{ width: "240px", minWidth: "240px" }}>
            <InterviewFilter
              choiceGroups={filterGroups}
              from={searchParams.from}
              to={searchParams.to}
              setDate={async (params) => {
                await navigate({
                  search: (prev) => {
                    const nextParams = { ...prev };
                    if (params.from) {
                      nextParams.from = formatDateToYYYYMMDD(params.from);
                    }
                    if (params.to) {
                      nextParams.to = formatDateToYYYYMMDD(params.to);
                    }
                    return nextParams;
                  },
                });
              }}
            />
          </AmeBox>
          <AmeBox sx={{ flexGrow: 1, minWidth: 0 }}>
            <WaitForSuccess queryState={interviewReportState}>
              {(interviewReport) => (
                <ReportHeader
                  count={interviewReport.count}
                  selectedFilters={selectedFilter}
                  axisDisplayNames={interviewReportAxis}
                  selectedAxis={searchParams.axis}
                  onChangeAxis={onChangeAxis}
                />
              )}
            </WaitForSuccess>
            <SimplePaper>
              <V2ContentWrapper title="AI解析結果">
                <WaitForSuccess queryState={interviewReportState}>
                  {(interviewReport) => {
                    return (
                      <HorizontalStackedBarChart
                        sortByfirstSeries
                        highlightColumnNames={
                          searchParams.subCategoryIds.length
                            ? searchParams.subCategoryIds.map(
                                (id) => subCategories.findBySubCategoryId(id)?.displayName ?? "",
                              )
                            : undefined
                        }
                        series={interviewReport.data.map((d) => ({
                          name: d.axisName,
                          data: d.scores.map((score) => ({
                            name: score.displayName,
                            value: score.score,
                          })),
                        }))}
                      />
                    );
                  }}
                </WaitForSuccess>
              </V2ContentWrapper>
            </SimplePaper>
            <Spacer height="24px" />
            <RetirementVoices sentencesState={sentencesState} />
          </AmeBox>
        </AmeBox>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

const useInterviewReportAxis = (): Record<InterviewReportAxis, string> => {
  const {
    setting: { segmentNames },
  } = useContext(DataSourceContext);

  return useMemo(
    () =>
      ({
        SEX: segmentNames.SEX,
        DEPARTMENT: segmentNames.DEPARTMENT,
        FREE1: segmentNames.FREE1,
        FREE2: segmentNames.FREE2,
        AGE: segmentNames.AGE,
        MONTHS_OF_SERVICE: segmentNames.MONTHS_OF_SERVICE,
        NONE: "軸なし",
        // subCategoryId: "カテゴリ(実装中)",
        // TODO: subCategoryId 追加したら下のキャスト飛ばす
      }) satisfies Record<Exclude<InterviewReportAxis, "subCategoryId">, string> as Record<InterviewReportAxis, string>,
    [segmentNames],
  );
};

type InterviewReportQuery = InterviewReportParams & {
  voice?: string;
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/interview-report/")({
  component: InterviewReport,
  validateSearch: (search: Partial<InterviewReportQuery>): InterviewReportQuery => {
    return {
      voice: search.voice,
      axis: search.axis || "NONE",
      from: search.from,
      to: search.to,
      subCategoryIds: search.subCategoryIds ?? [],
      SEX: search.SEX ?? [],
      DEPARTMENT: search.DEPARTMENT ?? [],
      FREE1: search.FREE1 ?? [],
      FREE2: search.FREE2 ?? [],
      AGE: search.AGE ?? [],
      MONTHS_OF_SERVICE: search.MONTHS_OF_SERVICE ?? [],
    };
  },
});
