import React from "react";

import { differenceInMonths, differenceInYears } from "date-fns";

import { Setting } from "../../../../../models/Setting";
import { InterviewDetailResponse } from "../../../../../store/autogenApi";
import { formatDate } from "../../../../../utils/formatter";
import { BreadcrumbPageLayoutHeader } from "../../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { DefinitionList } from "../../../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../../../atoms/list/DefinitionList/DefinitionListItem";

interface Props {
  attribute: NonNullable<InterviewDetailResponse["retiredEmployee"]>;
  // employee: Employee;
  // interview: ExEmployeeInterviewModel;
  setting: Setting;
}

export const EmployeeAttributes: React.FC<Props> = ({ attribute, setting }) => {
  return (
    <PageLayoutWrapper>
      <BreadcrumbPageLayoutHeader items={[]} currentTitle="属性情報" />
      <PageLayoutBody type="wide">
        <DefinitionList>
          <DefinitionListItem label="従業員番号" value={attribute.customerCreatedId} />
          <DefinitionListItem label="氏名" value={attribute.name} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            label={setting.segmentNames.MONTHS_OF_SERVICE}
            value={
              attribute.retireDate
                ? `${differenceInMonths(new Date(attribute.retireDate), new Date(attribute.hireDate))}ヶ月`
                : undefined
            }
          />

          <DefinitionListItem
            label={setting.segmentNames.AGE}
            value={
              attribute.retireDate && attribute.birthday
                ? `${differenceInYears(new Date(attribute.retireDate), new Date(attribute.birthday))}歳`
                : undefined
            }
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label="入社日" value={formatDate(new Date(attribute.hireDate))} />
          <DefinitionListItem
            label="退社日"
            value={attribute.retireDate ? formatDate(new Date(attribute.retireDate)) : undefined}
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label={setting.segmentNames.DEPARTMENT} value={attribute.department} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label={setting.segmentNames.FREE1} value={attribute.free1} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem label={setting.segmentNames.FREE2} value={attribute.free2} />
        </DefinitionList>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
