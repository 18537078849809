import React from "react";

import { endOfMonth, startOfMonth } from "date-fns";

import { AmeFormset } from "../../atoms/forms/AmeFormset";
import { AmeDateInput } from "../../atoms/forms/input/AmeDateInput";
import { Spacer } from "../../atoms/spacers/Spacer";
import { AmeTypography } from "../../atoms/typography/AmeTypography";

type Props = {
  fromDate: Date | null;
  toDate: Date | null;
  setDateParams: (dateKey: "from" | "to", dateValue: Date | null) => void;
};

export const DateFilter: React.FC<Props> = (props) => {
  return (
    <>
      <AmeTypography fontSize="body2">開始</AmeTypography>
      <AmeFormset
        label={""}
        errorMessage={
          props.fromDate && props.toDate && new Date(props.fromDate).getTime() > new Date(props.toDate).getTime()
            ? "開始日より後の終了日を選択してください"
            : undefined
        }
      >
        <AmeDateInput
          onCommitDate={(newDate) => props.setDateParams("from", newDate && startOfMonth(newDate))}
          value={props.fromDate ? new Date(props.fromDate) : undefined}
          format="yyyy年MM月"
          views={["year", "month"]}
        />
        <Spacer height="8px"></Spacer>
        <AmeTypography fontSize="body2">終了</AmeTypography>
        <Spacer height="8px"></Spacer>
        <AmeDateInput
          onCommitDate={(newDate) => props.setDateParams("to", newDate && endOfMonth(newDate))}
          value={props.toDate ? new Date(props.toDate) : undefined}
          format="yyyy年MM月"
          views={["year", "month"]}
        />
      </AmeFormset>
    </>
  );
};
