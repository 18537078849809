/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentResponse,
    DocumentResponseFromJSON,
    DocumentResponseFromJSONTyped,
    DocumentResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentListResponse
 */
export interface DocumentListResponse {
    /**
     * 
     * @type {Array<DocumentResponse>}
     * @memberof DocumentListResponse
     */
    documents: Array<DocumentResponse>;
}

export function DocumentListResponseFromJSON(json: any): DocumentListResponse {
    return DocumentListResponseFromJSONTyped(json, false);
}

export function DocumentListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(DocumentResponseFromJSON)),
    };
}

export function DocumentListResponseToJSON(value?: DocumentListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documents': ((value.documents as Array<any>).map(DocumentResponseToJSON)),
    };
}


