/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HrInterviewReportResponseTags,
    HrInterviewReportResponseTagsFromJSON,
    HrInterviewReportResponseTagsFromJSONTyped,
    HrInterviewReportResponseTagsToJSON,
} from './';

/**
 * 
 * @export
 * @interface HrInterviewReportResponseDocuments
 */
export interface HrInterviewReportResponseDocuments {
    /**
     * 
     * @type {Array<HrInterviewReportResponseTags>}
     * @memberof HrInterviewReportResponseDocuments
     */
    tags: Array<HrInterviewReportResponseTags>;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewReportResponseDocuments
     */
    interviewFormatContentId: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewReportResponseDocuments
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewReportResponseDocuments
     */
    hrInterviewId: string;
    /**
     * 
     * @type {string}
     * @memberof HrInterviewReportResponseDocuments
     */
    content: string;
}

export function HrInterviewReportResponseDocumentsFromJSON(json: any): HrInterviewReportResponseDocuments {
    return HrInterviewReportResponseDocumentsFromJSONTyped(json, false);
}

export function HrInterviewReportResponseDocumentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HrInterviewReportResponseDocuments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': ((json['tags'] as Array<any>).map(HrInterviewReportResponseTagsFromJSON)),
        'interviewFormatContentId': json['interviewFormatContentId'],
        'documentId': json['documentId'],
        'hrInterviewId': json['hrInterviewId'],
        'content': json['content'],
    };
}

export function HrInterviewReportResponseDocumentsToJSON(value?: HrInterviewReportResponseDocuments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': ((value.tags as Array<any>).map(HrInterviewReportResponseTagsToJSON)),
        'interviewFormatContentId': value.interviewFormatContentId,
        'documentId': value.documentId,
        'hrInterviewId': value.hrInterviewId,
        'content': value.content,
    };
}


