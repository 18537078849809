import React, { useCallback, useState } from "react";

import { useNavigate } from "@tanstack/react-router";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useDeleteInterviewFile } from "../../../../store/hooks/interview-files";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { AmeCheckbox } from "../../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeDialog } from "../../../molecules/dialog/AmeDialog";
import { AmeDialogLayout } from "../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  interviewRecordingId: string;
  disabled: boolean;
}

export const InterviewRecordingDeletingButtonWithDialog: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useHrPentestSnackbar();

  const onError = useCallback(() => {
    enqueueErrorSnackbar({ title: "音声データの削除に失敗しました。" });
  }, [enqueueErrorSnackbar]);

  const deleteInterviewFile = useDeleteInterviewFile({ onError });

  const onClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onSubmit = useCallback(async () => {
    const result = await deleteInterviewFile({ interviewRecordingId: props.interviewRecordingId });
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "音声データを削除しました。" });
      void navigate({ to: "/interview-files", search: { page: 1 } });
      return;
    }
  }, [deleteInterviewFile, props.interviewRecordingId, navigate, enqueueSuccessSnackbar]);

  const onChange = useCallback(() => {
    setIsChecked(!isChecked);
  }, [setIsChecked, isChecked]);

  return (
    <>
      <AmeButton onClick={onClick} variant="outlined" disabled={props.disabled}>
        削除
      </AmeButton>
      <AmeDialog open={isOpen} onClose={onClose}>
        <AmeDialogLayout
          variant="warning"
          onClose={onClose}
          onSubmit={onSubmit}
          submitButtonText="削除"
          title="本当に削除しますか？"
          submitButtonDisabled={!isChecked}
        >
          <AmeTypography>削除すると、元に戻すことができません。</AmeTypography>
          <AmeTypography>問題ない場合は削除ボタンを押してください。</AmeTypography>
          <Spacer height="24px" />
          <AmeBox sx={{ display: "inline-flex", justifyContent: "start", gap: "8px" }}>
            <AmeCheckbox checked={isChecked} onChange={onChange} />
            <AmeTypography>注意事項を確認しました</AmeTypography>
          </AmeBox>
        </AmeDialogLayout>
      </AmeDialog>
    </>
  );
};
