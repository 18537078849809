import { useMemo } from "react";

import { FlatChoiceGroupProps } from "../../components/molecules/filter/ChoiceGroup/type";
import { InterviewStatus } from "../../utils/constants";

import { UseChoiceGroupParams } from "./type";

export const useInterviewStatusChoiceGroup = ({
  filterParams,
  navigate,
}: UseChoiceGroupParams<{
  interviewStatuses: InterviewStatus[];
}>): FlatChoiceGroupProps => {
  return useMemo<FlatChoiceGroupProps>(() => {
    const followUpRequiredChoices = [
      { value: InterviewStatus.DRAFT, label: "下書き" },
      { value: InterviewStatus.ANALYZING, label: "処理中" },
      { value: InterviewStatus.ANALYZED, label: "確定済" },
      { value: InterviewStatus.FAILED, label: "エラー" },
    ];
    return {
      groupName: "面談ステータス",
      uiType: "flat",
      choiceOptions: followUpRequiredChoices.map((choice) => ({
        name: choice.label,
        toggleOption: async () =>
          await navigate({
            search: (prev) => {
              const nextSet = new Set(prev.interviewStatuses);
              if (!nextSet.delete(choice.value!)) {
                nextSet.add(choice.value!);
              }
              return { ...prev, interviewStatuses: Array.from(nextSet) };
            },
          }),
        isChecked: filterParams.interviewStatuses.includes(choice.value),
      })),
    };
  }, [filterParams, navigate]);
};
