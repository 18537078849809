import { SubCategoryResponse } from "../store/autogenApi";
import { ComputationTaskStatus } from "../store/hooks/computationTasks";

export class SubCategoryModel {
  constructor(
    readonly subCategoryId: string,
    readonly categoryId: string,
    readonly displayName: string,
    readonly computationTaskStatus: ComputationTaskStatus | null,
  ) {}

  public static fromResponse(subCategory: SubCategoryResponse): SubCategoryModel {
    return new SubCategoryModel(
      subCategory.subCategoryId,
      subCategory.categoryId,
      subCategory.displayName,
      subCategory.meta?.latestComputationTask?.computationTaskStatus ?? null,
    );
  }
}

export class SubCategoryList {
  constructor(readonly subCategories: SubCategoryModel[]) {}

  public findByCategoryId(categoryId: string): SubCategoryModel[] {
    return this.subCategories.filter((s) => s.categoryId === categoryId);
  }

  public findBySubCategoryId(subCategoryId: string): SubCategoryModel | undefined {
    return this.subCategories.find((s) => s.subCategoryId === subCategoryId);
  }

  public createSubCategoryCategoryMap(): Map<string, string> {
    return new Map<string, string>(this.subCategories.map((s) => [s.subCategoryId, s.categoryId]));
  }

  public static fromResponse(subCategories: SubCategoryResponse[]): SubCategoryList {
    return new SubCategoryList(subCategories.map(SubCategoryModel.fromResponse));
  }
}
