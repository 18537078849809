import { AxisLiteralOfTypeofInterviewReportGraphAxisFactory, SegmentKey } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useQueryResult } from "./utils/parseResult";
import { UseQueryOptions } from "./utils/types";

export type InterviewReportAxis = AxisLiteralOfTypeofInterviewReportGraphAxisFactory;
export type InterviewReportParams = {
  axis: InterviewReportAxis;
  from?: string;
  to?: string;
  subCategoryIds: string[];
} & Record<SegmentKey, string[]>;

export const useInterviewReport = (params: InterviewReportParams, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getInterviewReport.useQuery(
    {
      axis: params.axis,
      from: params.from ?? undefined,
      to: params.to ?? undefined,
      sexes: params.SEX,
      departments: params.DEPARTMENT,
      free1S: params.FREE1,
      free2S: params.FREE2,
      ages: params.AGE,
      monthsOfServices: params.MONTHS_OF_SERVICE,
      // subCategoryIds: searchParams.getAll("SUB_CATEGORY_ID"),
    },
    { pollingInterval: options?.pollingInterval, skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (d) => d,
    onError: options?.onError,
  });
};

export type Sentence = {
  documentId: string;
  exEmployeeInterviewId: string;
  sentence: string;
  segments: Record<SegmentKey, string>;
  subCategories: string[];
};

export const useSearchForInterviewDocuments = (params: InterviewReportParams, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.searchForInterviewDocuments.useQuery(
    {
      count: 100,
      offset: 0,
      sex: params.SEX,
      department: params.DEPARTMENT,
      free1: params.FREE1,
      free2: params.FREE2,
      age: params.AGE,
      monthsOfService: params.MONTHS_OF_SERVICE,
      subCategoryId: params.subCategoryIds,
      retireDateBegin: params.from ?? undefined,
      retireDateEnd: params.to ?? undefined,
    },
    { skip: options?.skip },
  );
  return useQueryResult({
    result,
    fromResponse: (response): Sentence[] =>
      response.interviewDocuments.map<Sentence>((d) => ({
        documentId: d.documentId,
        exEmployeeInterviewId: d.exEmployeeInterviewId,
        sentence: d.sentence,
        segments: {
          SEX: d.employee.sex!,
          DEPARTMENT: d.employee.department!,
          MONTHS_OF_SERVICE: d.employee.monthsOfService!,
          FREE1: d.employee.free1!,
          FREE2: d.employee.free2!,
          AGE: d.employee.age!,
        },
        subCategories: d.subCategoryIds,
      })),
    onError: options?.onError,
  });
};
