import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Error: React.FC<SvgIconProps> = (props) => (
  <SvgWrapper {...props} viewBox="0 0 20 20">
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <circle cx="10" cy="10" r="10" stroke="none" />
      <circle cx="10" cy="10" r="9" fill="none" />
    </g>
    <line
      y1="7"
      transform="translate(12.475 7.525) rotate(45)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
    />
    <line
      y1="7"
      transform="translate(12.475 12.475) rotate(135)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </SvgWrapper>
);
