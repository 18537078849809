/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnalysisScoreDimensions
 */
export interface AnalysisScoreDimensions {
    /**
     * 
     * @type {number}
     * @memberof AnalysisScoreDimensions
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysisScoreDimensions
     */
    label: string;
}

export function AnalysisScoreDimensionsFromJSON(json: any): AnalysisScoreDimensions {
    return AnalysisScoreDimensionsFromJSONTyped(json, false);
}

export function AnalysisScoreDimensionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalysisScoreDimensions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'label': json['label'],
    };
}

export function AnalysisScoreDimensionsToJSON(value?: AnalysisScoreDimensions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'label': value.label,
    };
}


