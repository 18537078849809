import React from "react";

import { AmeCheckbox } from "../../../../../../atoms/checkbox/AmeCheckbox";
import {
  AmeTable,
  AmeTableBody,
  AmeTableBodyCell,
  AmeTableHead,
  AmeTableHeadCell,
  AmeTableRow,
} from "../../../../../../atoms/table/AmeTable";
import { AmeBox } from "../../../../../../muiWrapper/AmeBox";

interface UserSelectTableProps {
  title: string;
  users: { userId: string; displayName: string }[];
  checkedUserIds: string[];
  toggleCheckedUser: (userId: string) => void;
}

export const UserSelectTable: React.FC<UserSelectTableProps> = ({
  title,
  users,
  checkedUserIds,
  toggleCheckedUser,
}) => {
  return (
    <AmeTable>
      <AmeTableHead>
        <AmeTableRow>
          <AmeTableHeadCell>{title}</AmeTableHeadCell>
        </AmeTableRow>
      </AmeTableHead>
      <AmeTableBody>
        {users.map((user) => (
          <AmeTableRow key={user.userId}>
            <AmeTableBodyCell>
              <AmeBox sx={{ display: "flex", alignItems: "center", "& > * + *": { marginLeft: "8px" } }}>
                <AmeCheckbox
                  checked={checkedUserIds.includes(user.userId!)}
                  onChange={() => {
                    toggleCheckedUser(user.userId!);
                  }}
                />
                <span>{user.displayName}</span>
              </AmeBox>
            </AmeTableBodyCell>
          </AmeTableRow>
        ))}
      </AmeTableBody>
    </AmeTable>
  );
};
