import React from "react";

import { useSp } from "../../../../hooks/useSp";
import { AmeBox } from "../../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

/**
 *
 * @example
 * <DefinitionList>
 *  <DefinitionListItem label="ほげ" value="ふが" />
 *  <DefinitionListItem label="ほげ" value="ふが" />
 *  <DefinitionListItem label="ほげ" value="ふが" />
 * </DefinitionList>
 */
export const DefinitionList: React.FC<Props> = ({ children }) => {
  const sp = useSp();
  return sp ? <SpDefinitionList>{children}</SpDefinitionList> : <LaptopDefinitionList>{children}</LaptopDefinitionList>;
};

const LaptopDefinitionList: React.FC<Props> = ({ children }) => {
  const childrenLength = React.Children.toArray(children).length;
  return (
    <AmeBox
      sx={{
        display: "flex",
        gap: "16px",
        flexWrap: "wrap",
        "& + *": { marginTop: "16px" },
        "& > *": { flexBasis: `calc((100% - 16px * ${childrenLength - 1}) / ${childrenLength})` },
      }}
    >
      {children}
    </AmeBox>
  );
};

const SpDefinitionList: React.FC<Props> = ({ children }) => {
  return <AmeBox>{children}</AmeBox>;
};
