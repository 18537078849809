/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";

import { SerializedError } from "@reduxjs/toolkit";
import { BaseQueryFn, FetchBaseQueryError, MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

import { useHrPentestSnackbar } from "../../../hooks/useHrPentestSnackbar";

import { ParsedRtkResponseError, RtkResponseError, RtkCustomHookError } from "./types";

export type MutationState<T> = { isSuccess: true; data: T } | { isSuccess: false; data: undefined };

type MutationFunctionArgs<MutationFunction> =
  MutationFunction extends MutationTrigger<MutationDefinition<infer U, BaseQueryFn, string, any, string>> ? U : never;

type MutationFunctionResponse<MutationFunction> =
  MutationFunction extends MutationTrigger<MutationDefinition<any, BaseQueryFn, string, infer U, string>> ? U : never;

export const useMutationFunctionWrapper = <
  MutationFunction extends MutationTrigger<
    MutationDefinition<any, BaseQueryFn<any, unknown, FetchBaseQueryError, any, any>, string, any, string>
  >,
  Args extends MutationFunctionArgs<MutationFunction>,
  Response extends MutationFunctionResponse<MutationFunction>,
>({
  mutationFn,
  onError,
}: {
  mutationFn: MutationFunction;
  onError?: (error: RtkCustomHookError) => void;
}) => {
  const { enqueueErrorSnackbar } = useHrPentestSnackbar();

  return useCallback(
    async (args: Args): Promise<MutationState<Response>> => {
      const result = await mutationFn(args);
      if ("error" in result) {
        const parsedError = parseRtkError(result.error);
        const rtkCustomHookError = getRtkCustomHookError(parsedError);
        onError
          ? onError(rtkCustomHookError)
          : enqueueErrorSnackbar({
              title: "エラーが発生しました。",
              message: rtkCustomHookError?.message,
            });
        return {
          isSuccess: false,
          data: undefined,
        };
      }
      return { isSuccess: true, data: result.data };
    },
    [enqueueErrorSnackbar, mutationFn, onError],
  );
};

const isRtkResponseError = (
  error: FetchBaseQueryError | SerializedError | RtkResponseError,
): error is RtkResponseError => {
  return (
    "data" in error &&
    // 以下2行はerror.dataがobjectであることのチェック
    error.data !== null &&
    typeof error.data === "object" &&
    "message" in error.data &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore なぜか、tscをかけるとmessageがerror.dataのpropertyとして存在しないと怒られるので。
    typeof error.data.message === "string"
  );
};

/**
 * RTK-Query公式ドキュメントによると、エラーの場合、デフォルト挙動としてRtkResponseErrorで定義している型が返ってくると想定しているとのことだったので、
 * 恐らくRTK-Queryのネイティブ実装もそのようになっていると想定し、頑張ってRtkResponseError型で返すようにする。
 * 実際の挙動としては、サーバー側のエラーはRtkResponseError型として取得し、それ以外のエラーについてはFetchBaseQueryErrorやSerializedError型で取得しているようである。
 * Reference: https://async-transformresponse--rtk-query-docs.netlify.app/concepts/error-handling/#errors-with-a-custom-basequery
 */
export const parseRtkError = (
  error: FetchBaseQueryError | SerializedError | RtkResponseError,
): ParsedRtkResponseError => {
  if (isRtkResponseError(error)) {
    return { type: "rtk-response-error", error };
  }
  return { type: "others", error };
};

/**
 * RTK-Query関連のhooksの呼び出し側が、どのエラー型でも同じ型(RtkCustomHookError)でerror objectを受け取れるように、
 * error objectを加工して、RtkCustomHookErrorを取得する。
 */
export const getRtkCustomHookError = (parsedError: ParsedRtkResponseError): RtkCustomHookError => {
  if (parsedError.type === "rtk-response-error") {
    return { status: parsedError.error.status, message: parsedError.error.data.message };
  }
  return { status: undefined, message: JSON.stringify(parsedError.error) };
};
