import React, { forwardRef, useCallback } from "react";

import { Check, WarningAmber } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, useMediaQuery } from "@mui/material";
import { useSnackbar, SnackbarContent, SnackbarKey, SnackbarMessage } from "notistack";

import { customizedMuiTheme, useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { HrPentestSnackbarMessage } from ".";

type Ref = HTMLDivElement;
type Props = { id: SnackbarKey; message: SnackbarMessage };
export const CustomSnackbarContent = forwardRef<Ref, Props>((props, ref) => {
  const theme = useAmeTheme();
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => closeSnackbar(props.id), [closeSnackbar, props.id]);
  const message: HrPentestSnackbarMessage = JSON.parse(props.message as string);
  const isPc = useMediaQuery(customizedMuiTheme.breakpoints.up("md"));

  return (
    <SnackbarContent ref={ref}>
      <AmeBox
        sx={[
          {
            display: "flex",
            alignItems: "center",
            width: isPc ? "75vw" : "100%",
            padding: "0 8px",
          },
          message.type === "success" && {
            backgroundColor: theme.common.background.success40,
            border: `1px solid ${theme.common.border.success}`,
          },
          message.type === "error" && {
            backgroundColor: theme.common.background.warning20,
            border: `1px solid ${theme.common.border.warning}`,
          },
        ]}
      >
        <AmeBox sx={{ padding: "0 16px" }}>
          {message.type === "success" ? (
            <Check htmlColor={theme.common.text.success} fontSize="large" />
          ) : (
            <WarningAmber htmlColor={theme.common.text.warning} fontSize="large" />
          )}
        </AmeBox>

        <AmeBox sx={{ flexGrow: 1, padding: "8px 0" }}>
          <AmeTypography fontSize="body1">
            <AmeBox component="span" sx={{ fontWeight: "bold" }}>
              {message.title}
            </AmeBox>
          </AmeTypography>
          <AmeTypography fontSize="body2">{message.message}</AmeTypography>
        </AmeBox>
        <IconButton onClick={handleDismiss}>
          <CloseIcon htmlColor={theme.common.text.gray40} />
        </IconButton>
      </AmeBox>
    </SnackbarContent>
  );
});
CustomSnackbarContent.displayName = "CustomSnackbarContent";
