/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewDocumentResponseEmployee
 */
export interface InterviewDocumentResponseEmployee {
    /**
     * 
     * @type {Date}
     * @memberof InterviewDocumentResponseEmployee
     */
    retireDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponseEmployee
     */
    monthsOfService?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponseEmployee
     */
    age?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponseEmployee
     */
    free2?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponseEmployee
     */
    free1?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponseEmployee
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewDocumentResponseEmployee
     */
    sex?: string;
}

export function InterviewDocumentResponseEmployeeFromJSON(json: any): InterviewDocumentResponseEmployee {
    return InterviewDocumentResponseEmployeeFromJSONTyped(json, false);
}

export function InterviewDocumentResponseEmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewDocumentResponseEmployee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retireDate': !exists(json, 'retireDate') ? undefined : (new Date(json['retireDate'])),
        'monthsOfService': !exists(json, 'monthsOfService') ? undefined : json['monthsOfService'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'free2': !exists(json, 'free2') ? undefined : json['free2'],
        'free1': !exists(json, 'free1') ? undefined : json['free1'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
    };
}

export function InterviewDocumentResponseEmployeeToJSON(value?: InterviewDocumentResponseEmployee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retireDate': value.retireDate === undefined ? undefined : (value.retireDate.toISOString()),
        'monthsOfService': value.monthsOfService,
        'age': value.age,
        'free2': value.free2,
        'free1': value.free1,
        'department': value.department,
        'sex': value.sex,
    };
}


