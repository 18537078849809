/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewFeedbackRating,
    InterviewFeedbackRatingFromJSON,
    InterviewFeedbackRatingFromJSONTyped,
    InterviewFeedbackRatingToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewFeedbackSettingsAnswer
 */
export interface InterviewFeedbackSettingsAnswer {
    /**
     * 
     * @type {number}
     * @memberof InterviewFeedbackSettingsAnswer
     */
    score: number;
    /**
     * 
     * @type {InterviewFeedbackRating}
     * @memberof InterviewFeedbackSettingsAnswer
     */
    rating: InterviewFeedbackRating;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackSettingsAnswer
     */
    answer: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewFeedbackSettingsAnswer
     */
    interviewFeedbackAnswerId: string;
}

export function InterviewFeedbackSettingsAnswerFromJSON(json: any): InterviewFeedbackSettingsAnswer {
    return InterviewFeedbackSettingsAnswerFromJSONTyped(json, false);
}

export function InterviewFeedbackSettingsAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewFeedbackSettingsAnswer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'rating': InterviewFeedbackRatingFromJSON(json['rating']),
        'answer': json['answer'],
        'interviewFeedbackAnswerId': json['interviewFeedbackAnswerId'],
    };
}

export function InterviewFeedbackSettingsAnswerToJSON(value?: InterviewFeedbackSettingsAnswer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'rating': InterviewFeedbackRatingToJSON(value.rating),
        'answer': value.answer,
        'interviewFeedbackAnswerId': value.interviewFeedbackAnswerId,
    };
}


