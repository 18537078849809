import React, { useCallback, useState, useMemo, ChangeEvent } from "react";

import { UpsertableEmployee } from "../../../../../models/Employee";
import { useCreatePeriod } from "../../../../../store/hooks/periods";
import { AmeButton } from "../../../../atoms/button/AmeButton";
import { AmeDateSpanInput } from "../../../../atoms/forms/input/AmeDateSpanInput";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeFormButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";
import { FormList } from "../../../../molecules/FormList";
import { StepCards } from "../../../../molecules/StepCards";
import { AmeBox } from "../../../../muiWrapper/AmeBox";
import { CsvImporter } from "../../CsvImporter";

export const DataSourceInitializer: React.FC = () => {
  const createPeriod = useCreatePeriod();
  const [beginningEmployeeFile, setBeginningEmployeeFile] = useState<File | undefined>(undefined);
  const [previousBeginningData, setPreviousBeginningData] = useState("");
  const [previousEndingData, setPreviousEndingData] = useState("");
  const [currentBeginningData, setCurrentBeginningData] = useState("");
  const [currentEndingData, setCurrentEndingData] = useState("");
  const [formState, setFormState] = useState<"steps" | "periodForm" | "fileForm">("steps");
  const isPeriodFilled = useMemo(
    () => currentBeginningData && previousBeginningData && currentEndingData && previousEndingData,
    [currentBeginningData, previousBeginningData, currentEndingData, previousEndingData],
  );

  const onChangeCurrentBeginningData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCurrentBeginningData(e.target.value);
    },
    [setCurrentBeginningData],
  );

  const onChangePreviousBeginningData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPreviousBeginningData(e.target.value);
    },
    [setPreviousBeginningData],
  );

  const onChangePreviousEndingData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPreviousEndingData(e.target.value);
    },
    [setPreviousEndingData],
  );

  const onChangeCurrentEndingData = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCurrentEndingData(e.target.value);
    },
    [setCurrentEndingData],
  );

  // TODO: backendを整えて前期〜今期の生成、期初のデータ登録をすべて同一トランザクションの中に閉じ込める
  //  引数は仮ぎめなので適当に変えていいよ
  const createInitialData = useCallback(
    async (args: {
      beginningDate: string;
      /**
       * こいつの生成は共通処理が別に存在しているので、時間空いたらtyamamotoが切り出す。
       * デザイン実装上は空のリストを投げてくれればOK
       */
      employees: UpsertableEmployee[];
    }) => {
      const currentPeriodBeginningDate = new Date(args.beginningDate);
      const previousPeriodBeginningDate = new Date(currentPeriodBeginningDate);
      previousPeriodBeginningDate.setFullYear(currentPeriodBeginningDate.getFullYear() - 1);

      // 前期の生成
      await createPeriod({ createPeriodRequestBody: { beginningDate: previousPeriodBeginningDate.toISOString() } });
      // 当期の生成
      await createPeriod({
        createPeriodRequestBody: { beginningDate: args.beginningDate },
      });
    },
    [createPeriod],
  );

  return (
    <PageLayoutWrapper>
      <PageLayoutBody type="wide">
        {formState === "steps" && (
          <AmeBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <AmeBox
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <AmeTypography component="h1">HR pentestへようこそ！</AmeTypography>
              <AmeTypography component="p">まず始めに、２つの初期設定を行なってください。</AmeTypography>
            </AmeBox>

            <AmeBox>
              <StepCards
                steps={[
                  {
                    children: (
                      <>
                        <AmeTypography component="p">会計期間の設定をしてください。</AmeTypography>
                        <AmeButton onClick={() => setFormState("periodForm")}>会計期間設定</AmeButton>
                      </>
                    ),
                  },
                  {
                    children: (
                      <>
                        <AmeTypography component="p">期初従業員データの登録をしてください。</AmeTypography>
                        <AmeButton disabled={!isPeriodFilled} onClick={() => setFormState("fileForm")}>
                          期初従業員データの登録
                        </AmeButton>
                      </>
                    ),
                  },
                ]}
              />
            </AmeBox>
          </AmeBox>
        )}

        {formState === "periodForm" && (
          <>
            <FormList title="会計期間設定">
              <AmeDateSpanInput
                label="前期会計期間"
                startProps={{ value: previousBeginningData, onChange: onChangePreviousBeginningData }}
                endProps={{ value: previousEndingData, onChange: onChangePreviousEndingData }}
              />
              <AmeDateSpanInput
                label="今期会計期間"
                startProps={{ value: currentBeginningData, onChange: onChangeCurrentBeginningData }}
                endProps={{ value: currentEndingData, onChange: onChangeCurrentEndingData }}
              />
            </FormList>
            <Spacer height="16px" />
            <AmeFormButtonsLayout
              primaryDisabled={!isPeriodFilled}
              onPrimary={() => {
                setFormState("steps");
              }}
              primaryText="完了"
            />
          </>
        )}

        {formState === "fileForm" && (
          <>
            <CsvImporter
              onImported={(file) => {
                setBeginningEmployeeFile(file);
              }}
            />
            <Spacer height="16px" />
            <AmeFormButtonsLayout
              primaryDisabled={!beginningEmployeeFile}
              onPrimary={() => createInitialData({ beginningDate: currentBeginningData, employees: [] })}
              primaryText="一括登録する"
            />
          </>
        )}
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};
