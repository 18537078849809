import React from "react";

import { AmeBox } from "../../muiWrapper/AmeBox";
interface Props {
  children: React.ReactNode;
}

export const PaperBody: React.FC<Props> = ({ children }) => {
  return <AmeBox sx={{ margin: "24px", flexShrink: 1 }}>{children}</AmeBox>;
};
