import React, { useCallback, useMemo } from "react";

import { useForm } from "react-hook-form";

import { GlobalSettingModel } from "../../../../../models/Setting";
import { UpdateGraphScaleSettingsBody } from "../../../../../store/autogenApi";
import { useUpdateGraphScaleSettings } from "../../../../../store/hooks/settings";
import { AmeFormButtonsLayout } from "../../../../molecules/FormButtonLayouts/AmeFormButtonsLayout";

import { GraphScaleEditor } from "./GraphScaleEditor";

interface Props {
  globalSetting: GlobalSettingModel;
  navigateToList: () => void;
}

export const GraphScaleForm: React.FC<Props> = ({ globalSetting, navigateToList }) => {
  const currentValues = useMemo<UpdateGraphScaleSettingsBody>(
    () => ({
      maxScaleValueOfTurnoverCount: undefined,
      maxScalePercentageOfTurnoverRate: undefined,
      maxScaleValueInEmployeeReport: globalSetting.maxScaleValueInEmployeeReport,
      maxScaleCurrentEmployeeValueInEmployeeReport: globalSetting.maxScaleCurrentEmployeeValueInEmployeeReport,
      maxScalePercentageInEmployeeReport: globalSetting.maxScalePercentageInEmployeeReport,
      maxScaleValueInSurveyReport: globalSetting.maxScaleValueInSurveyReport,
      maxScaleValueInKpiReport: globalSetting.maxScaleValueInKpiReport,
      maxMinutesInKpiReport: globalSetting.maxMinutesInKpiReport,
      maxScalePercentageInSurveyReport: globalSetting.maxScalePercentageInSurveyReport,
      maxPercentageInKpiReport: globalSetting.maxPercentageInKpiReport,
    }),
    [globalSetting],
  );

  const { register, handleSubmit, formState } = useForm<UpdateGraphScaleSettingsBody>({
    mode: "onChange",
    defaultValues: currentValues,
  });

  const updateGraphScale = useUpdateGraphScaleSettings();

  const onSubmit = useCallback(
    async (data: UpdateGraphScaleSettingsBody) => {
      // データの整形 空白時はnullに変換, 数値時は数値に変換
      const formattedData = Object.entries(data).reduce(
        (acc, [key, value]: [string, string | number | null]) => {
          if (value === "") {
            acc[key] = null;
          } else if (value !== undefined && value !== null) {
            acc[key] = typeof value === "string" ? Number(value) || null : value;
          }
          return acc;
        },
        {} as Record<string, number | null>,
      );
      // データの更新
      await updateGraphScale({
        updateGraphScaleSettingsBody: formattedData,
      });
      navigateToList();
    },
    [updateGraphScale, navigateToList],
  );

  return (
    <>
      <GraphScaleEditor register={register} formState={formState} />
      <AmeFormButtonsLayout onPrimary={handleSubmit(onSubmit)} onSecondary={navigateToList} />
    </>
  );
};
