import { useEffect, useState } from "react";

import { SP_MEDIA_QUERY } from "../utils/mediaQuery";

export const useSp = () => {
  const [match, setMatch] = useState(() => {
    const isMatch = matchMedia(SP_MEDIA_QUERY).matches;
    const isPrint = matchMedia("print").matches;
    return isMatch && !isPrint;
  });

  useEffect(() => {
    const mql = matchMedia(SP_MEDIA_QUERY);
    const printMql = matchMedia("print");

    const handler = (e: MediaQueryListEvent) => {
      setMatch(e.matches && !printMql.matches);
    };

    const printHandler = (e: MediaQueryListEvent) => {
      setMatch(mql.matches && !e.matches);
    };

    mql.addEventListener("change", handler);
    printMql.addEventListener("change", printHandler);

    return () => {
      mql.removeEventListener("change", handler);
      printMql.removeEventListener("change", printHandler);
    };
  }, [setMatch]);

  return match;
};
