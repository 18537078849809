import { ComputationTaskResponse } from "../autogenApi";

export const ComputationTaskStatus = {
  UPDATING: "UPDATING",
  DELETING: "DELETING",
  CREATING: "CREATING",
  PENDING: "PENDING",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
} as const;

export type ComputationTaskStatus = (typeof ComputationTaskStatus)[keyof typeof ComputationTaskStatus];

export interface ComputationTaskModel {
  readonly computationTaskId: string;
  readonly ruleId?: string;
  readonly subCategoryId?: string;
  readonly categoryId?: string;
  readonly computationTaskStatus?: ComputationTaskStatus;
  readonly processingEvent?: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface ComputationTasksModel {
  readonly interviews: { [key: string]: ComputationTaskModel };
  readonly categories: { [key: string]: ComputationTaskModel };
  readonly subCategories: { [key: string]: ComputationTaskModel };
  readonly rules: { [key: string]: ComputationTaskModel };
}

export namespace ComputationTaskModel {
  export const fromResponse = (response: ComputationTaskResponse): ComputationTaskModel => {
    return {
      computationTaskId: response.computationTaskId,
      ruleId: response.ruleId ?? undefined,
      subCategoryId: response.subCategoryId ?? undefined,
      categoryId: response.categoryId ?? undefined,
      computationTaskStatus: response.computationTaskStatus ?? undefined,
      processingEvent: response.processingEvent ?? undefined,
      createdAt: new Date(response.createdAt),
      updatedAt: new Date(response.updatedAt),
    };
  };
}
