import { Employee } from "../../models/Employee";
import { EmployeeChangeSet } from "../../models/EmployeeChangeSet";
import { SegmentKey } from "../../models/constants/values";
import { formatDateToYYYYMMDD } from "../../utils/formatter";
import { AxisLiteralOfTypeofEmployeeReportAxisFactory, GetEmployeeListApiResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions, UseQueryOptions } from "./utils/types";

export const useGetEmployeeList = (
  at: Date,
  count: number,
  offset: number,
  searchParams?: { customerCreatedId?: string },
) => {
  const result = defaultApi.endpoints.getEmployeeList.useQuery({
    at: formatDateToYYYYMMDD(at),
    count,
    offset,
    customerCreatedId: searchParams?.customerCreatedId,
  });
  return useQueryResult({
    result,
    fromResponse: (response: GetEmployeeListApiResponse) => ({
      employees: response.employees.map(Employee.fromResponse),
      totalCount: response.totalCount,
    }),
  });
};

export const useGetEmployeeListLazy = () => {
  const [trigger] = defaultApi.endpoints.getEmployeeList.useLazyQuery();
  return trigger;
};

export const useGetCustomerCreatedIdExistsLazyQuery = () => {
  return defaultApi.endpoints.customerCreatedIdExists.useLazyQuery();
};

export const useAddEmployeeChangeSet = (options?: UseMutationOptions) => {
  const [addEmployeeChangeSet] = defaultApi.endpoints.addEmployeeChangeSet.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: addEmployeeChangeSet,
    onError: options?.onError,
  });
};

export const useGetEmployeeChangeSetSummary = (
  changeSetId: string,
  count: number,
  offset: number,
  options?: UseQueryOptions,
) => {
  const result = defaultApi.endpoints.getEmployeeChangeSetSummary.useQuery({ changeSetId, count, offset });
  return useQueryResult({
    result,
    fromResponse: EmployeeChangeSet.fromResponse,
    onError: options?.onError,
  });
};

export const useApplyEmployeeChangeSet = (options?: UseMutationOptions) => {
  const [applyEmployeeChangeSet] = defaultApi.endpoints.applyEmployeeChangeSet.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: applyEmployeeChangeSet,
    onError: options?.onError,
  });
};

export type EmployeeReportParams = {
  periodId: number;
  axis: AxisLiteralOfTypeofEmployeeReportAxisFactory;
} & {
  [key in keyof typeof SegmentKey]: string[];
};

export const useEmployeeReport2 = (params: EmployeeReportParams, options?: UseQueryOptions) => {
  const result = defaultApi.endpoints.getEmployeeReportV3.useQuery(
    {
      periodId: params.periodId,
      axis: params.axis,
      sexes: params.SEX,
      departments: params.DEPARTMENT,
      free1S: params.FREE1,
      free2S: params.FREE2,
    },
    {
      pollingInterval: options?.pollingInterval,
      skip: options?.skip,
    },
  );
  return useQueryResult({
    result,
    fromResponse: (res) => {
      const currentEmployeeChartData = {
        series: res.currentEmployee.map((d) => d.data.previous),
        series2: res.currentEmployee.map((d) => d.data.current),
        seriesLabels: res.currentEmployee.map((d) => d.name),
      };
      const turnoverChartData = {
        series: res.turnover.map((d) => d.data.previous),
        series2: res.turnover.map((d) => d.data.current),
        seriesLabels: res.turnover.map((d) => d.name),
      };
      const hiredChartData = {
        series: res.hired.map((d) => d.data.previous),
        series2: res.hired.map((d) => d.data.current),
        seriesLabels: res.hired.map((d) => d.name),
      };

      const turnoverRateChartData = {
        series: res.turnoverRate.map((d) => d.data.previous),
        series2: res.turnoverRate.map((d) => d.data.current),
        seriesLabels: res.turnoverRate.map((d) => d.name),
      };
      const hiredRateChartData = {
        series: res.hiredRate.map((d) => d.data.previous),
        series2: res.hiredRate.map((d) => d.data.current),
        seriesLabels: res.turnoverRate.map((d) => d.name),
      };

      return {
        currentEmployeeChartData,
        turnoverChartData,
        hiredChartData,
        turnoverRateChartData,
        hiredRateChartData,
        original: res,
      };
    },
    onError: options?.onError,
  });
};
