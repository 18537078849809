import React, { MouseEvent, ReactNode, useMemo } from "react";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeTypography } from "../../atoms/typography/AmeTypography";

import { DropboxSize } from "./AmeDropbox";

interface Props {
  displaySelected?: ReactNode;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  size?: DropboxSize;
  isOpen?: boolean;
  forTable?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const AmeDropboxButton: React.FC<Props> = ({
  displaySelected,
  size,
  onClick,
  isOpen,
  forTable,
  error,
  disabled,
}) => {
  const styles = useDropboxButtonStyles(size, forTable, error);

  return (
    <Box component="button" type="button" sx={styles} onClick={onClick} disabled={disabled}>
      <AmeTypography omit>{displaySelected !== undefined ? displaySelected : "選択なし"}</AmeTypography>
      {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
    </Box>
  );
};

const useDropboxButtonStyles = (
  size: DropboxSize | undefined,
  forTable: boolean | undefined,
  error: boolean | undefined,
) => {
  const theme = useAmeTheme();
  return useMemo(() => {
    const styles: SystemStyleObject = {
      backgroundColor: theme.common.background.white,
      width: "100%",
      height: size === "small" ? "36px" : size === "large" ? "56px" : "48px",
      padding: "0 16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: forTable ? 0 : "8px",
      border: forTable ? "none" : `1px solid ${theme.common.border.gray40}`,
      "&:hover": {
        backgroundColor: theme.brand.secondary[20],
      },
      "&:focus": {
        backgroundColor: theme.common.background.white,
        border: `2px solid ${theme.brand.secondary[100]}`,
      },
      "&:focus-visible": {
        backgroundColor: theme.common.background.white,
        border: `2px solid ${theme.brand.secondary[100]}`,
      },
      "&:disabled": {
        backgroundColor: theme.common.background.gray20,
        "&, & *": {
          color: theme.common.text.gray20,
        },
      },
    };
    if (error) {
      styles.border = `2px solid ${theme.common.border.error}`;
      styles["&:focus"] = {};
    }
    return styles;
  }, [theme, size, forTable, error]);
};
