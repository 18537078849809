/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SurveyQuestionResponse,
    SurveyQuestionResponseFromJSON,
    SurveyQuestionResponseFromJSONTyped,
    SurveyQuestionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SurveySheetResponse
 */
export interface SurveySheetResponse {
    /**
     * 
     * @type {string}
     * @memberof SurveySheetResponse
     */
    surveySheetId: string;
    /**
     * 
     * @type {string}
     * @memberof SurveySheetResponse
     */
    displayName: string;
    /**
     * 
     * @type {Date}
     * @memberof SurveySheetResponse
     */
    dueDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof SurveySheetResponse
     */
    emailSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveySheetResponse
     */
    emailBody?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveySheetResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SurveySheetResponse
     */
    description: string;
    /**
     * 
     * @type {Array<SurveyQuestionResponse>}
     * @memberof SurveySheetResponse
     */
    questions: Array<SurveyQuestionResponse>;
}

export function SurveySheetResponseFromJSON(json: any): SurveySheetResponse {
    return SurveySheetResponseFromJSONTyped(json, false);
}

export function SurveySheetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveySheetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'surveySheetId': json['surveySheetId'],
        'displayName': json['displayName'],
        'dueDateTime': (new Date(json['dueDateTime'])),
        'emailSubject': !exists(json, 'emailSubject') ? undefined : json['emailSubject'],
        'emailBody': !exists(json, 'emailBody') ? undefined : json['emailBody'],
        'title': json['title'],
        'description': json['description'],
        'questions': ((json['questions'] as Array<any>).map(SurveyQuestionResponseFromJSON)),
    };
}

export function SurveySheetResponseToJSON(value?: SurveySheetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveySheetId': value.surveySheetId,
        'displayName': value.displayName,
        'dueDateTime': (value.dueDateTime.toISOString()),
        'emailSubject': value.emailSubject,
        'emailBody': value.emailBody,
        'title': value.title,
        'description': value.description,
        'questions': ((value.questions as Array<any>).map(SurveyQuestionResponseToJSON)),
    };
}


