import { UserGroupResponse, UserGroupsResponse, UserGroupRefResponse } from "../store/autogenApi";

import { UserRef } from "./User";

export class UserGroupList {
  private constructor(
    readonly totalUserCount: number,
    readonly userGroups: UserGroup[],
  ) {}

  public static fromResponse(response: UserGroupsResponse): UserGroupList {
    return new UserGroupList(response.totalCount, response.userGroups.map(UserGroup.fromResponse));
  }
}

export class UserGroup {
  private constructor(
    readonly userGroupId: string,
    readonly displayName: string,
    readonly totalUserCount: number,
    readonly users: UserRef[],
  ) {}

  public static fromResponse(response: UserGroupResponse): UserGroup {
    return new UserGroup(
      response.userGroupId,
      response.displayName,
      response.totalUserCount ?? 0,
      response.users?.map(UserRef.fromResponse) ?? [],
    );
  }
}

export class UserGroupRef {
  private constructor(
    readonly userGroupId: string,
    readonly displayName: string,
  ) {}

  public static fromResponse(response: UserGroupRefResponse): UserGroupRef {
    return new UserGroupRef(response.userGroupId, response.displayName);
  }
}
