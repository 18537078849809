/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 従業員の属性を除いた在籍情報を表すレスポンス
 * @export
 * @interface EmployeeRefResponse
 */
export interface EmployeeRefResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    employeeTenureId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    customerCreatedId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    hireDate: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeRefResponse
     */
    retireDate?: string;
}

export function EmployeeRefResponseFromJSON(json: any): EmployeeRefResponse {
    return EmployeeRefResponseFromJSONTyped(json, false);
}

export function EmployeeRefResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeRefResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'employeeTenureId': json['employeeTenureId'],
        'customerCreatedId': json['customerCreatedId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'hireDate': json['hireDate'],
        'retireDate': !exists(json, 'retireDate') ? undefined : json['retireDate'],
    };
}

export function EmployeeRefResponseToJSON(value?: EmployeeRefResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'employeeId': value.employeeId,
        'employeeTenureId': value.employeeTenureId,
        'customerCreatedId': value.customerCreatedId,
        'name': value.name,
        'email': value.email,
        'dateOfBirth': value.dateOfBirth,
        'hireDate': value.hireDate,
        'retireDate': value.retireDate,
    };
}


