import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";

import { useGetEmployeeList } from "../../../../../store/hooks/employees";
import { AmeInput } from "../../../../atoms/forms/input/AmeInput";
import { ChoosableList } from "../../../../atoms/list/ChoosableList";
import { ChoosableListItem } from "../../../../atoms/list/ChoosableListItem";
import { WaitForData } from "../../../../molecules/Loading/WaitForData";
import { AmeDropbox_old } from "../../../../molecules/dropbox/AmeDropbox";

interface Props {
  value: { employeeTenureId: string; displayName: string } | null;
  disabled?: boolean;
  variant?: "outlined";
  onSelectEmployee: (employee: { employeeTenureId: string; displayName: string } | null) => void;
  forTable?: boolean;
}

export const EmployeeTenureSelector: React.FC<Props> = (props) => {
  const [input, setInput] = useState("");
  const [innerValue, setInnerValue] = useState<{ employeeTenureId: string; displayName: string } | null>(
    props.value ?? null,
  );
  const value = props.value === undefined ? innerValue : props.value;

  const employeesQueryState = useGetEmployeeList(new Date(), 10, 0, {
    customerCreatedId: input,
  });

  return (
    <>
      <WaitForData queryState={employeesQueryState}>
        {({ employees }) => (
          <AmeDropbox_old
            value={value}
            onChange={(e) => {
              props.onSelectEmployee(e.target.value ?? null);
              setInnerValue(e.target.value ?? null);
            }}
            disabled={props.disabled}
            displaySelected={value?.displayName ?? "選択なし"}
            inputComponent={
              <AmeInput
                placeholder="従業員番号"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                endIcon={<SearchIcon />}
              />
            }
            forTable={props.forTable}
          >
            <ChoosableList>
              <ChoosableListItem value={null}>選択なし</ChoosableListItem>
              {employees.map((e) => (
                <ChoosableListItem
                  key={e.employeeId}
                  value={{ displayName: e.getEmployeeDisplayName(), employeeTenureId: e.employeeTenureId }}
                >
                  {e.getEmployeeDisplayName()}
                </ChoosableListItem>
              ))}
            </ChoosableList>
          </AmeDropbox_old>
        )}
      </WaitForData>
    </>
  );
};
