/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeTenureLogResponse,
    EmployeeTenureLogResponseFromJSON,
    EmployeeTenureLogResponseFromJSONTyped,
    EmployeeTenureLogResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeLifeLogResponse
 */
export interface EmployeeLifeLogResponse {
    /**
     * 
     * @type {Date}
     * @memberof EmployeeLifeLogResponse
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLifeLogResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLifeLogResponse
     */
    name?: string;
    /**
     * 
     * @type {Array<EmployeeTenureLogResponse>}
     * @memberof EmployeeLifeLogResponse
     */
    tenures: Array<EmployeeTenureLogResponse>;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLifeLogResponse
     */
    customerCreatedId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLifeLogResponse
     */
    employeeId: string;
}

export function EmployeeLifeLogResponseFromJSON(json: any): EmployeeLifeLogResponse {
    return EmployeeLifeLogResponseFromJSONTyped(json, false);
}

export function EmployeeLifeLogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeLifeLogResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tenures': ((json['tenures'] as Array<any>).map(EmployeeTenureLogResponseFromJSON)),
        'customerCreatedId': json['customerCreatedId'],
        'employeeId': json['employeeId'],
    };
}

export function EmployeeLifeLogResponseToJSON(value?: EmployeeLifeLogResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString()),
        'email': value.email,
        'name': value.name,
        'tenures': ((value.tenures as Array<any>).map(EmployeeTenureLogResponseToJSON)),
        'customerCreatedId': value.customerCreatedId,
        'employeeId': value.employeeId,
    };
}


