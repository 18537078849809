/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatedSegmentKey,
    CalculatedSegmentKeyFromJSON,
    CalculatedSegmentKeyFromJSONTyped,
    CalculatedSegmentKeyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CalculatedSegmentSettingResponse
 */
export interface CalculatedSegmentSettingResponse {
    /**
     * 
     * @type {CalculatedSegmentKey}
     * @memberof CalculatedSegmentSettingResponse
     */
    segmentKey: CalculatedSegmentKey;
    /**
     * 
     * @type {string}
     * @memberof CalculatedSegmentSettingResponse
     */
    selectedCalculationStrategy: string;
}

export function CalculatedSegmentSettingResponseFromJSON(json: any): CalculatedSegmentSettingResponse {
    return CalculatedSegmentSettingResponseFromJSONTyped(json, false);
}

export function CalculatedSegmentSettingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculatedSegmentSettingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'segmentKey': CalculatedSegmentKeyFromJSON(json['segmentKey']),
        'selectedCalculationStrategy': json['selectedCalculationStrategy'],
    };
}

export function CalculatedSegmentSettingResponseToJSON(value?: CalculatedSegmentSettingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'segmentKey': CalculatedSegmentKeyToJSON(value.segmentKey),
        'selectedCalculationStrategy': value.selectedCalculationStrategy,
    };
}


