import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const Play: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <path
        d="M13.3934 9.08363L3.84941 14.8259C3.03945 15.3128 2 14.7233 2 13.7419V2.25725C2 1.27739 3.03795 0.686363 3.84941 1.17474L13.3934 6.91705C13.5777 7.02611 13.7308 7.18376 13.8374 7.37402C13.9439 7.56427 14 7.78034 14 8.00034C14 8.22033 13.9439 8.43641 13.8374 8.62666C13.7308 8.81691 13.5777 8.97456 13.3934 9.08363Z"
        fill="currentColor"
      />
    </SvgWrapper>
  );
};
