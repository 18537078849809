import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";
import { Spacer } from "../../atoms/spacers/Spacer";
import { AmeTypography } from "../../atoms/typography/AmeTypography";
import { ChoiceGroup } from "../../molecules/filter/ChoiceGroup";
import { ChoiceGroupProps } from "../../molecules/filter/ChoiceGroup/type";
import { DateFilter } from "../../molecules/filter/DateFilter";
import { FilterWrapper } from "../../molecules/filter/FilterWrapper";
import { AmeBox } from "../../muiWrapper/AmeBox";

type Props = {
  choiceGroups: ChoiceGroupProps[];
  from: string | undefined;
  to: string | undefined;
  setDate: (params: { from?: Date | null; to?: Date | null }) => void;
};

export const InterviewFilter: React.FC<Props> = ({ choiceGroups, from, to, setDate }) => {
  const theme = useAmeTheme();
  return (
    <FilterWrapper>
      <AmeBox sx={{ borderBottom: `1px solid ${theme.common.border.gray40}` }}>
        <AmeBox sx={{ padding: "16px", flexShrink: 1 }}>
          <AmeTypography component="h6">{"退職日"}</AmeTypography>
          <Spacer height="10px"></Spacer>
          <DateFilter
            fromDate={from ? new Date(from) : null}
            toDate={to ? new Date(to) : null}
            setDateParams={(dateKey, dateValue) => setDate({ [dateKey]: dateValue })}
          />
        </AmeBox>
      </AmeBox>
      {choiceGroups.map((choiceGroup, index) => {
        return <ChoiceGroup key={index} {...choiceGroup} />;
      })}
    </FilterWrapper>
  );
};
