import React from "react";

import { AmeBox } from "../../../../muiWrapper/AmeBox";

import { ReportFilterChip, FilterChipContent } from "./ReportFilterChip";

interface Props {
  selectedFilters: FilterChipContent[];
}

export const ReportFilterChipList: React.FC<Props> = ({ selectedFilters }) => {
  return (
    <AmeBox sx={{ display: "flex", gap: "8px" }}>
      {selectedFilters.map((selectedFilter) => (
        <ReportFilterChip
          key={selectedFilter.name}
          name={selectedFilter.name}
          groupName={selectedFilter.groupName}
          clearFilter={selectedFilter.clearFilter}
        />
      ))}
    </AmeBox>
  );
};
