import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useMemo,
  forwardRef,
  HTMLInputTypeAttribute,
} from "react";

import { InputAdornment, InputBaseProps, InputLabelProps, InputProps, SxProps, TextField, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

import { AmeTheme, useAmeTheme } from "../../../utils/styles/AmeTheme";

type Variant = "normal" | "auto-complete";
export type TextFieldSize = "small" | "medium";

export interface AmeTextFieldProps {
  sx?: SxProps<Theme>;
  size?: TextFieldSize;
  type?: HTMLInputTypeAttribute;
  variant?: Variant;
  rows?: number;
  value?: string;
  defaultValue?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  multiline?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  // for AutoComplete of MUI
  InputLabelProps?: Partial<InputLabelProps>;
  InputProps?: Partial<InputProps>;
  inputProps?: InputBaseProps["inputProps"];
}

export const AmeTextField = forwardRef<HTMLDivElement, AmeTextFieldProps>(({ variant = "normal", ...props }, ref) => {
  const { startIcon, endIcon, onKeyDown, ...otherProps } = props;
  const theme = useAmeTheme();
  const baseStyle = useMemo(() => getBaseStyle(theme, variant), [theme, variant]);
  return (
    <TextField
      {...otherProps}
      sx={baseStyle}
      InputProps={{
        onKeyDown,
        startAdornment: startIcon ? <InputAdornment position="start">{props.startIcon}</InputAdornment> : null,
        endAdornment: endIcon ? <InputAdornment position="end">{props.endIcon}</InputAdornment> : null,
        ...props.InputProps,
      }}
      ref={ref}
    />
  );
});
AmeTextField.displayName = AmeTextField.name;

export const getBaseStyle = (theme: AmeTheme, variant: Variant): SystemStyleObject => {
  return {
    "& .MuiOutlinedInput-root": {
      outline: "none",
      resize: "none",
      "& .MuiInputAdornment-root": {
        zIndex: 2,
      },
      // default
      "& .MuiOutlinedInput-input": {
        zIndex: 1,
        fontSize: theme.typography.body1,
        color: theme.common.text.black,
        "-webkit-text-fill-color": theme.common.text.black,
        fontFamily: "'Noto Sans JP', sans-serif",
        "&::-webkit-input-placeholder": {
          color: theme.common.text.gray40,
          "-webkit-text-fill-color": theme.common.text.gray40,
        },
        "&:-webkit-autofill": {
          boxShadow: "0 0 0 1000px white inset",
          border: `1px solid ${theme.common.border.gray40}`,
          "&:focus": {
            border: `2px solid ${theme.brand.secondary[100]}`,
          },
        },
        "&:autofill": {
          boxShadow: "0 0 0 1000px white inset",
          border: `1px solid ${theme.common.border.gray40}`,
          "&:focus": {
            border: `2px solid ${theme.brand.secondary[100]}`,
          },
        },
      },
      "& fieldset": {
        border: variant === "auto-complete" ? "none" : `1px solid ${theme.common.border.gray40}`,
        backgroundColor: theme.common.background.white,
      },
      "&:hover fieldset": {
        backgroundColor: theme.brand.secondary[20],
        border: variant === "auto-complete" ? "none" : `1px solid ${theme.common.border.gray40}`,
      },
      "&.Mui-focused fieldset": {
        border: `2px solid ${theme.brand.secondary[100]}`,
        backgroundColor: theme.common.background.white,
      },
      // error
      "&.Mui-error": {
        "& fieldset": {
          border: `2px solid ${theme.common.border.error}`,
          backgroundColor: theme.common.background.white,
        },
        "&:hover fieldset": {
          backgroundColor: theme.common.background.warning20,
          border: `2px solid ${theme.common.border.error}`,
        },
        "&.Mui-focused fieldset": {
          border: `2px solid ${theme.common.border.error}`,
          backgroundColor: theme.common.background.white,
        },
      },
      // disabled
      "&.Mui-disabled": {
        "& fieldset": {
          backgroundColor: theme.common.background.gray40,
          border: `1px solid ${theme.common.border.gray40}`,
        },
        "&:hover fieldset": {
          backgroundColor: theme.common.background.gray40,
          border: `1px solid ${theme.common.border.gray40}`,
        },
        "& .MuiOutlinedInput-input": {
          color: theme.common.text.gray20,
          "-webkit-text-fill-color": theme.common.text.gray20,
          "&::-webkit-input-placeholder": {
            opacity: 1,
            color: theme.common.text.gray20,
            "-webkit-text-fill-color": theme.common.text.gray20,
          },
        },
      },
    },
  };
};
