import React from "react";

import { useGetInterviewFormatContents } from "../../../store/hooks/hr-interview-setting";
import { AmeButton } from "../../atoms/button/AmeButton";
import { DefinitionList } from "../../atoms/list/DefinitionList";
import { DefinitionListItem } from "../../atoms/list/DefinitionList/DefinitionListItem";
import { PaperBody } from "../../atoms/paper/PaperBody";
import { PaperHeader } from "../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../atoms/paper/PaperWrapper";
import { WaitForSuccess } from "../../molecules/Loading/WaitForSuccess";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  onClose: () => void;
  interviewFormatId: string;
}

export const FormatMapper: React.FC<Props> = ({ onClose, interviewFormatId }) => {
  const interviewFormatContentsQuery = useGetInterviewFormatContents(interviewFormatId);
  return (
    <>
      <PaperWrapper>
        <PaperHeader>フォーマット確認</PaperHeader>
        <PaperBody>
          <WaitForSuccess queryState={interviewFormatContentsQuery}>
            {(interviewFormatContents) => (
              <AmeBox sx={{ "& > *": { flexDirection: "column" } }}>
                <DefinitionList>
                  {interviewFormatContents.map((content, index) => {
                    return (
                      <DefinitionListItem
                        label={`設問${index + 1}`}
                        value={content.hrInterviewQuestionText}
                        key={content.hrInterviewQuestionId}
                      />
                    );
                  })}
                </DefinitionList>
              </AmeBox>
            )}
          </WaitForSuccess>
          <AmeBox sx={{ display: "flex", justifyContent: "end", marginTop: "23px" }}>
            <AmeButton variant="outlined" onClick={onClose}>
              キャンセル
            </AmeButton>
          </AmeBox>
        </PaperBody>
      </PaperWrapper>
    </>
  );
};
