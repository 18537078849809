import { TranscriptionSentence as RawTranscriptionSentence } from "../store/autogenApi";
export class Transcription {
  readonly transcriptionId: string;
  readonly interviewFileId: string;
  readonly retiredSpeakerLabel: string;
  readonly results: {
    sentences: {
      items: {
        text: string;
        startTime: string;
        endTime: string;
        speakerLabel: string;
      }[];
      enabled: boolean;
      text: string;
      speakerLabel: string;
    }[];
    speakers: number;
  };

  constructor(transcriptionId, interviewFileId, retiredSpeakerLabel, results) {
    this.transcriptionId = transcriptionId;
    this.interviewFileId = interviewFileId;
    this.retiredSpeakerLabel = retiredSpeakerLabel;
    this.results = results;
  }
}

export class TranscriptionSentence {
  constructor(
    readonly text: string,
    readonly startTimeSec: number | undefined,
    readonly endTime: number | undefined,
    readonly speakerLabel: string,
    readonly enabled: boolean,
  ) {}

  public static fromResponse(response: RawTranscriptionSentence): TranscriptionSentence {
    return new TranscriptionSentence(
      response.text,
      response.startTime,
      response.endTime,
      response.speakerLabel,
      response.enabled,
    );
  }
}
