import React, { useCallback, useState } from "react";

import { AmeCheckbox } from "../../../../atoms/checkbox/AmeCheckbox";
import { Spacer } from "../../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../../atoms/typography/AmeTypography";
import { AmeDialogLayout } from "../../../../molecules/dialog/AmeDialogLayout";
import { AmeBox } from "../../../../muiWrapper/AmeBox";

type Props = {
  open: boolean;
  title: string;
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

export const TranscriptionEditorDialogContent: React.FC<Props> = (props) => {
  const [confirmChecked, setConfirmChecked] = useState(false);
  const confirmCheckedOnChange = useCallback(() => {
    setConfirmChecked(!confirmChecked);
  }, [confirmChecked]);
  return (
    <>
      <AmeDialogLayout
        title="本当に反映しますか？"
        onSubmit={props.onSubmit}
        onClose={props.onClose}
        submitButtonDisabled={!confirmChecked}
        submitButtonText="反映"
        variant="caution"
      >
        <AmeBox sx={{ display: "flex", justifyContent: "left" }}>
          <AmeTypography fontSize="caption">
            反映すると、既存の文字起こしが上書きされます。
            <br />
            問題ない場合は反映するボタンを押してください。
          </AmeTypography>
        </AmeBox>
        <Spacer height="24px" />
        <AmeBox sx={{ display: "flex", justifyContent: "left", alignItems: "center", columnGap: 0.7 }}>
          <AmeCheckbox size="small" disabled={false} checked={confirmChecked} onChange={confirmCheckedOnChange} />
          <AmeTypography fontSize="caption">注意事項を確認しました</AmeTypography>
        </AmeBox>
      </AmeDialogLayout>
    </>
  );
};
