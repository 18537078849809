import React from "react";

import { LinkProps } from "@tanstack/react-router";

import { Spacer } from "../../../atoms/spacers/Spacer";
import { AmeTypography } from "../../../atoms/typography/AmeTypography";
import { AmeBox } from "../../../muiWrapper/AmeBox";

import { SettingLinkButton } from "./SettingLinkButton";

interface Props {
  to: LinkProps["to"];
  settingTitle: string;
  description: string;
  // グラフ目盛り設定用の一時的なProps。グラフ目盛り設定ページが出来たら消す。
  disabled?: boolean;
}

export const SettingItem: React.FC<Props> = (props) => {
  return (
    <AmeBox sx={{ maxWidth: "420px", width: "100%" }}>
      <SettingLinkButton to={props.to} disabled={props.disabled}>
        {props.settingTitle}
      </SettingLinkButton>
      <Spacer height="16px" />
      <AmeTypography>{props.description}</AmeTypography>
    </AmeBox>
  );
};
