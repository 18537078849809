/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeTenureResponse
 */
export interface EmployeeTenureResponse {
    /**
     * 
     * @type {Date}
     * @memberof EmployeeTenureResponse
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTenureResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTenureResponse
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeTenureResponse
     */
    retireDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeTenureResponse
     */
    hireDate: Date;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTenureResponse
     */
    customerCreatedId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTenureResponse
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeTenureResponse
     */
    employeeTenureId: string;
}

export function EmployeeTenureResponseFromJSON(json: any): EmployeeTenureResponse {
    return EmployeeTenureResponseFromJSONTyped(json, false);
}

export function EmployeeTenureResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeTenureResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'retireDate': !exists(json, 'retireDate') ? undefined : (new Date(json['retireDate'])),
        'hireDate': (new Date(json['hireDate'])),
        'customerCreatedId': json['customerCreatedId'],
        'employeeId': json['employeeId'],
        'employeeTenureId': json['employeeTenureId'],
    };
}

export function EmployeeTenureResponseToJSON(value?: EmployeeTenureResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString()),
        'email': value.email,
        'name': value.name,
        'retireDate': value.retireDate === undefined ? undefined : (value.retireDate.toISOString()),
        'hireDate': (value.hireDate.toISOString()),
        'customerCreatedId': value.customerCreatedId,
        'employeeId': value.employeeId,
        'employeeTenureId': value.employeeTenureId,
    };
}


