import React from "react";

import { useAmeTheme } from "../../../utils/styles/AmeTheme";

import { SvgIconProps, SvgWrapper } from ".";

export const Check: React.FC<SvgIconProps> = (props) => {
  const theme = useAmeTheme();
  return (
    <SvgWrapper {...props} viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <path
        d="M2838.265,186.409l2.511,2.648,5.79-5.411"
        transform="translate(-2832.416 -176.352)"
        fill="none"
        stroke={theme.common.text.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgWrapper>
  );
};
