import React from "react";

import { PaperBody } from "../../../../../atoms/paper/PaperBody";
import { PaperHeader } from "../../../../../atoms/paper/PaperHeader";
import { PaperWrapper } from "../../../../../atoms/paper/PaperWrapper";
import { AmeBox } from "../../../../../muiWrapper/AmeBox";

import { InterviewerBasicInformationItem } from "./InterviewerBasicInformationItem";

interface Props {
  groupName: string;
  name: string;
  numOfInterviews: number;
  averageScore: number;
}

export const InterviewerBasicInformation: React.FC<Props> = ({ groupName, name, numOfInterviews, averageScore }) => {
  return (
    <PaperWrapper>
      <PaperHeader>基本情報</PaperHeader>
      <PaperBody>
        <AmeBox sx={{ display: "flex", gap: "5%" }}>
          <InterviewerBasicInformationItem label="所属グループ" value={groupName} />
          <InterviewerBasicInformationItem label="氏名" value={name} />
          <InterviewerBasicInformationItem label="インタビュー回数" value={`${numOfInterviews}回`} />
          <InterviewerBasicInformationItem label="品質スコア平均" value={`${averageScore.toFixed(1)}点`} />
        </AmeBox>
      </PaperBody>
    </PaperWrapper>
  );
};
