import React from "react";

import { customizedMuiTheme, useAmeTheme } from "../../../utils/styles/AmeTheme";
import { AmeBox } from "../../muiWrapper/AmeBox";

interface Props {
  children: React.ReactNode;
}

export const PublicRouteTemplate: React.FC<Props> = (props) => {
  const theme = useAmeTheme();
  return (
    <AmeBox
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: theme.common.background.gray20,
        minHeight: "100vh",
      }}
    >
      <AmeBox
        sx={{
          height: "fit-content",
          [customizedMuiTheme.breakpoints.up("md")]: {
            width: "750px",
            paddingY: "24px",
          },
          [customizedMuiTheme.breakpoints.down("md")]: {
            width: "100%",
          },
        }}
      >
        {props.children}
      </AmeBox>
    </AmeBox>
  );
};
