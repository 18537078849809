import {
  GetSettingApiResponse,
  GlobalSettingResponse,
  InputSegmentDefinitionResponse,
  InputSegmentKey,
  RecordSegmentKeyString,
} from "../store/autogenApi";

export class SegmentNames {
  constructor(
    readonly SEX: string,
    readonly DEPARTMENT: string,
    readonly FREE1: string,
    readonly FREE2: string,
    readonly AGE: string,
    readonly MONTHS_OF_SERVICE: string,
  ) {}

  public static fromResponse(response: RecordSegmentKeyString): SegmentNames {
    return new SegmentNames(
      response.SEX || "性別",
      response.DEPARTMENT || "部門",
      response.FREE1 || "フリー１",
      response.FREE2 || "フリー２",
      response.AGE || "年齢",
      response.MONTHS_OF_SERVICE || "勤続月数",
    );
  }
}

export class InputSegmentDefinitionModel {
  constructor(
    readonly inputSegmentDefinitionId: string,
    readonly segmentKey: InputSegmentKey,
    readonly segmentValue: string,
    readonly mappedTo: string | undefined,
    readonly isDeprecated: boolean,
  ) {}

  public static fromResponse(response: InputSegmentDefinitionResponse): InputSegmentDefinitionModel {
    return new InputSegmentDefinitionModel(
      response.inputSegmentDefinitionId,
      response.segmentKey,
      response.segmentValue,
      response.mappedTo,
      response.isDeprecated,
    );
  }
}

export class GlobalSettingModel {
  constructor(
    readonly emailSenderName: string,
    readonly emailSignature: string | undefined,
    readonly feedbackMailSenderName: string | undefined,
    readonly feedbackMailSubject: string | undefined,
    readonly feedbackMailBody: string | undefined,
    readonly feedbackMailSignature: string | undefined,
    readonly feedbackSurveyBody: string | undefined,
    readonly feedbackSurveyTitle: string | undefined,
    readonly maxScaleValueInEmployeeReport: number | undefined,
    readonly maxScaleCurrentEmployeeValueInEmployeeReport: number | undefined,
    readonly maxScalePercentageInEmployeeReport: number | undefined,
    readonly maxScaleValueInSurveyReport: number | undefined,
    readonly maxScalePercentageInSurveyReport: number | undefined,
    readonly maxScaleValueInKpiReport: number | undefined,
    readonly maxPercentageInKpiReport: number | undefined,
    readonly maxMinutesInKpiReport: number | undefined,
    readonly activateHrInterview: boolean,
  ) {}

  public static fromResponse(response: GlobalSettingResponse): GlobalSettingModel {
    return new GlobalSettingModel(
      response.emailSenderName,
      response.emailSignature,
      response.feedbackMailSenderName,
      response.feedbackMailSubject,
      response.feedbackMailBody,
      response.feedbackMailSignature,
      response.feedbackSurveyBody,
      response.feedbackSurveyTitle,
      response.maxScaleValueInEmployeeReport,
      response.maxScaleCurrentEmployeeValueInEmployeeReport,
      response.maxScalePercentageInEmployeeReport,
      response.maxScaleValueInSurveyReport,
      response.maxScalePercentageInSurveyReport,
      response.maxScaleValueInKpiReport,
      response.maxPercentageInKpiReport,
      response.maxMinutesInKpiReport,
      response.activeHrInterview,
    );
  }
}

export class InputSegmentDefinitionMap {
  constructor(readonly definitionMap: Map<string, InputSegmentDefinitionModel>) {}

  public getDefinitionsById(definitionId: string | undefined) {
    return definitionId && (this.definitionMap.get(definitionId)?.segmentValue || "該当なし");
  }

  public static fromResponse(response: InputSegmentDefinitionResponse[]): InputSegmentDefinitionMap {
    return new InputSegmentDefinitionMap(
      new Map(response.map((r) => [r.inputSegmentDefinitionId, InputSegmentDefinitionModel.fromResponse(r)])),
    );
  }
}

export class Setting {
  constructor(
    readonly segmentNames: SegmentNames,
    readonly inputSegmentDefinitionMap: InputSegmentDefinitionMap,
    readonly globalSetting: GlobalSettingModel,
  ) {}

  public static fromResponse(response: GetSettingApiResponse) {
    return new Setting(
      SegmentNames.fromResponse(response.segmentName.nameMap),
      InputSegmentDefinitionMap.fromResponse(response.inputSegmentDefinitions),
      GlobalSettingModel.fromResponse(response.globalSetting),
    );
  }
}
