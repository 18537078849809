import React from "react";

import { SvgIconProps, SvgWrapper } from ".";

export const CenterDotIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 6 6">
      <circle cx="3" cy="3" r="3" fill="currentColor" />
    </SvgWrapper>
  );
};
