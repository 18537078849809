/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateInterviewFileRequestBody
 */
export interface UpdateInterviewFileRequestBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateInterviewFileRequestBody
     */
    userGroupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateInterviewFileRequestBody
     */
    employeeTenureId?: string | null;
}

export function UpdateInterviewFileRequestBodyFromJSON(json: any): UpdateInterviewFileRequestBody {
    return UpdateInterviewFileRequestBodyFromJSONTyped(json, false);
}

export function UpdateInterviewFileRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInterviewFileRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroupId': !exists(json, 'userGroupId') ? undefined : json['userGroupId'],
        'employeeTenureId': !exists(json, 'employeeTenureId') ? undefined : json['employeeTenureId'],
    };
}

export function UpdateInterviewFileRequestBodyToJSON(value?: UpdateInterviewFileRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userGroupId': value.userGroupId,
        'employeeTenureId': value.employeeTenureId,
    };
}


