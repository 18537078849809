import React from "react";

import { createFileRoute } from "@tanstack/react-router";

import { useInterviewerReportList } from "../../../../../store/hooks/interviewer";
import { PageLayoutBody } from "../../../../atoms/layout/PageLayoutBody";
import { PageLayoutHeader } from "../../../../atoms/layout/PageLayoutHeader";
import { PageLayoutWrapper } from "../../../../atoms/layout/PageLayoutWrapper";
import { WaitForSuccess } from "../../../../molecules/Loading/WaitForSuccess";
import { InterviewerQualityListTable } from "../../../../organisms/v2/interviewers/index/InterviewerQualityListTable";

export const InterviewFeedbackList: React.FC = () => {
  const interviewFeedbacksQueryState = useInterviewerReportList();
  return (
    <PageLayoutWrapper>
      <PageLayoutHeader title={"品質全体レポート"} />
      <PageLayoutBody type={"wide"}>
        <WaitForSuccess queryState={interviewFeedbacksQueryState}>
          {(qualityReport) => <InterviewerQualityListTable qualityReports={qualityReport} />}
        </WaitForSuccess>
      </PageLayoutBody>
    </PageLayoutWrapper>
  );
};

export const Route = createFileRoute("/_authenticated/_authorized-for-admin/interview-feedbacks/")({
  component: InterviewFeedbackList,
});
