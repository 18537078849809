/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSubCategoryRequestBody
 */
export interface CreateSubCategoryRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateSubCategoryRequestBody
     */
    subCategoryDefinition: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubCategoryRequestBody
     */
    displayName: string;
}

export function CreateSubCategoryRequestBodyFromJSON(json: any): CreateSubCategoryRequestBody {
    return CreateSubCategoryRequestBodyFromJSONTyped(json, false);
}

export function CreateSubCategoryRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSubCategoryRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subCategoryDefinition': json['subCategoryDefinition'],
        'displayName': json['displayName'],
    };
}

export function CreateSubCategoryRequestBodyToJSON(value?: CreateSubCategoryRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subCategoryDefinition': value.subCategoryDefinition,
        'displayName': value.displayName,
    };
}


