import React from "react";

import { useSp } from "../../../../hooks/useSp";
import { useAmeTheme } from "../../../../utils/styles/AmeTheme";
import { AmeBox } from "../../../muiWrapper/AmeBox";

export const Footer: React.FC = () => {
  const theme = useAmeTheme();
  const isSp = useSp();
  return (
    <AmeBox
      component="footer"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: isSp ? "center" : undefined,
        gap: "24px",
        paddingX: "24px",
        height: "48px",
        bgcolor: theme.common.background.gray40,
        color: theme.common.text.gray40,
      }}
    >
      {isSp ? null : (
        <>
          <FooterElement
            title="利用規約"
            href="https://ameand.notion.site/HR-pentest-e8182f223a8247648db88a092a1bf324"
          />
          <FooterElement
            title="マニュアル"
            href="https://ameand.notion.site/HR-pentest-20f7280d11cf42459562e38efe8370d7"
          />
          <AmeBox sx={{ flexGrow: 1 }} />
        </>
      )}
      <FooterElement title="Copyright &copy; HR pentest" />
    </AmeBox>
  );
};

type FooterElement = {
  title: string;
  href?: string;
};
const FooterElement: React.FC<FooterElement> = ({ title, href }) => {
  const isSp = useSp();
  return (
    <AmeBox sx={{ fontSize: isSp ? "12px" : undefined }}>
      {href ? (
        <a href={href} target={"_blank"} rel="noreferrer">
          {title}
        </a>
      ) : (
        title
      )}
    </AmeBox>
  );
};
