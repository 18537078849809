import { isInvalidDate } from "@amecloud/common-utils/src/utils/DateUtils";
import { removeEmptyFields } from "@amecloud/common-utils/src/utils/JsonUtils";
import { EMAIL_REG } from "@amecloud/common-utils/src/utils/Validation";

import { EmployeeAttributeResponse, EmployeeRefResponse, EmployeeResponse } from "../store/autogenApi";
import { formatDateToYYYYMMDD } from "../utils/formatter";

export class UpsertableEmployee {
  constructor(
    readonly customerCreatedId: string,
    readonly hireDate: Date,
    readonly retireDate: Date | undefined | "",
    readonly name: string | undefined,
    readonly sex: string | undefined,
    readonly department: string | undefined,
    readonly email: string | undefined,
    readonly birthday: Date | undefined | "",
    readonly free1: string | undefined,
    readonly free2: string | undefined,
  ) {}

  public static fromCsvRow(
    row: Record<string, string | undefined>,
    attributeKeyMapper: { [key in keyof UpsertableEmployee]: string },
  ): UpsertableEmployee {
    const customerCreatedId = row[attributeKeyMapper.customerCreatedId];
    if (!customerCreatedId) {
      throw new Error(`${attributeKeyMapper.customerCreatedId}がありません`);
    }

    const hireDate = this.parseDateOrUndefined(row[attributeKeyMapper.hireDate]);
    if (!hireDate) {
      throw new Error(`${attributeKeyMapper.hireDate}がありません`);
    }
    if (isInvalidDate(hireDate)) {
      throw new Error("不正な入社日です。日付を正しく入力してください。");
    }
    const retireDate = this.parseDateOrUndefined(row[attributeKeyMapper.retireDate]);
    if (retireDate) {
      if (isInvalidDate(retireDate)) {
        throw new Error("不正な退職日です。日付を正しく入力してください。");
      }
      if (retireDate.getTime() < hireDate.getTime()) {
        throw new Error("入社日よりも前の年月日が退職日に設定されています。");
      }
    }
    const birthday = this.parseDateOrUndefined(row[attributeKeyMapper.birthday]);
    if (birthday) {
      if (isInvalidDate(birthday)) {
        throw new Error("不正な誕生日です。日付を正しく入力してください。");
      }
      if (hireDate.getTime() < birthday.getTime()) {
        throw new Error("生年月日よりも前の年月日が入社日に設定されています。");
      }
    }

    const email = row[attributeKeyMapper.email];
    if (email && !EMAIL_REG.test(email)) {
      throw new Error(`メールアドレス:"${email}"の形式が誤っています。`);
    }

    return new UpsertableEmployee(
      customerCreatedId,
      hireDate,
      retireDate,
      row[attributeKeyMapper.name],
      row[attributeKeyMapper.sex],
      row[attributeKeyMapper.department],
      email,
      birthday,
      row[attributeKeyMapper.free1],
      row[attributeKeyMapper.free2],
    );
  }

  private static parseDateOrUndefined(dateString: string | undefined): Date | undefined | "" {
    if (dateString === undefined) {
      return undefined;
    }
    if (dateString === "") {
      return "";
    }
    return new Date(dateString);
  }

  public static generateUpsertableEmployeeMapper(
    additionalParams: Partial<EmployeeAttributeMapper>,
  ): EmployeeAttributeMapper {
    return { ...DEFAULT_EMPLOYEE_ATTRIBUTE_KEY_MAPPER, ...removeEmptyFields(additionalParams) };
  }
}

export type EmployeeAttributeMapper = { [key in keyof UpsertableEmployee]: string };
/**
 * CSVヘッダーとemployeeKeyのマッパー
 */
const DEFAULT_EMPLOYEE_ATTRIBUTE_KEY_MAPPER: EmployeeAttributeMapper = {
  customerCreatedId: "従業員番号",
  hireDate: "入社日",
  retireDate: "退職日",
  email: "メールアドレス",
  birthday: "生年月日",
  name: "氏名",
  sex: "性別",
  department: "部門",
  free1: "フリー1",
  free2: "フリー2",
};

export class Attribute2 {
  constructor(
    readonly attributeKey: string,
    readonly attributeValue: string,
    readonly attributeDefinitionId: string,
  ) {}

  public static fromResponse(res: EmployeeAttributeResponse): Attribute2 {
    return new Attribute2(res.attributeKey, res.attributeValue, res.attributeDefinitionId);
  }
}

export class AttributeList {
  constructor(readonly attributes: Attribute2[]) {}

  public getAttributeByKey(key: string): Attribute2 | undefined {
    return this.attributes.find((attribute) => attribute.attributeKey === key);
  }

  public static fromResponses(responses: EmployeeAttributeResponse[]): AttributeList {
    return new AttributeList(responses.map(Attribute2.fromResponse));
  }
}

export class EmployeeRef {
  constructor(
    readonly employeeId: string,
    readonly employeeTenureId: string,
    readonly customerCreatedId: string,
    readonly hireDate: Date,
    readonly retireDate: Date | undefined,
    readonly name: string | undefined,
    readonly email: string | undefined,
    readonly dateOfBirth: Date | undefined,
  ) {}

  public getHireDate(): string {
    return formatDateToYYYYMMDD(this.hireDate);
  }

  public getDateOfBirth(): string | undefined {
    return this.dateOfBirth ? formatDateToYYYYMMDD(this.dateOfBirth) : undefined;
  }

  public getRetireDate(): string | undefined {
    return this.retireDate ? formatDateToYYYYMMDD(this.retireDate) : undefined;
  }

  public getEmployeeDisplayName(): string {
    const info: string[] = [this.customerCreatedId];
    if (this.name) {
      info.push(this.name);
    }
    return info.join(" ");
  }

  public static fromResponse(res: EmployeeRefResponse): EmployeeRef {
    return new EmployeeRef(
      res.employeeId,
      res.employeeTenureId,
      res.customerCreatedId,
      new Date(res.hireDate),
      res.retireDate ? new Date(res.retireDate) : undefined,
      res.name,
      res.email,
      res.dateOfBirth ? new Date(res.dateOfBirth) : undefined,
    );
  }
}

export class Employee extends EmployeeRef {
  constructor(
    readonly employeeId: string,
    readonly employeeTenureId: string,
    readonly customerCreatedId: string,
    readonly hireDate: Date,
    readonly retireDate: Date | undefined,
    readonly name: string | undefined,
    readonly email: string | undefined,
    readonly dateOfBirth: Date | undefined,
    readonly attributes: AttributeList,
    readonly hasInterviewAssign: boolean,
  ) {
    super(employeeId, employeeTenureId, customerCreatedId, hireDate, retireDate, name, email, dateOfBirth);
  }

  public static fromResponse(res: EmployeeResponse): Employee {
    return new Employee(
      res.employeeId,
      res.employeeTenureId,
      res.customerCreatedId,
      new Date(res.hireDate),
      res.retireDate ? new Date(res.retireDate) : undefined,
      res.name,
      res.email,
      res.dateOfBirth ? new Date(res.dateOfBirth) : undefined,
      AttributeList.fromResponses(res.attributes),
      res.hasInterviewAssign,
    );
  }
}
