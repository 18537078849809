import React, { useCallback } from "react";

import { useHrPentestSnackbar } from "../../../../hooks/useHrPentestSnackbar";
import { useRebuildDurationOfInterviewFiles } from "../../../../store/hooks/interview-files";
import { AmeButton } from "../../../atoms/button/AmeButton";
import { BreadcrumbPageLayoutHeader } from "../../../atoms/layout/BreadcrumbPageLayoutHeader";
import { PaperBody } from "../../../atoms/paper/PaperBody";
import { PaperWrapper } from "../../../atoms/paper/PaperWrapper";

export const RebuildDuration: React.FC = () => {
  const { enqueueSuccessSnackbar } = useHrPentestSnackbar();
  const rebuildDuration = useRebuildDurationOfInterviewFiles();

  const onClick = useCallback(async () => {
    const result = await rebuildDuration();
    if (result.isSuccess) {
      enqueueSuccessSnackbar({ title: "音声データの録音時間を更新しました。" });
    }
  }, [rebuildDuration, enqueueSuccessSnackbar]);

  return (
    <PaperWrapper>
      <BreadcrumbPageLayoutHeader items={[]} currentTitle="音声データ録音時間のアップデート" />
      <PaperBody>
        <AmeButton onClick={onClick}>更新</AmeButton>
      </PaperBody>
    </PaperWrapper>
  );
};
