import { GetPeriodsApiResponse, PeriodResponse } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { QueryState, useQueryResult } from "./utils/parseResult";

export class Period {
  constructor(
    readonly periodId: number,
    readonly beginningDate: Date,
    readonly endDate: Date,
  ) {}

  public getDisplayName(): string {
    return `${this.beginningDate.toLocaleDateString()} ~ ${this.endDate.toLocaleDateString()}`;
  }

  public getDurationYearMonth(): string {
    return `${this.beginningDate.getFullYear()}年${
      this.beginningDate.getMonth() + 1
    }月 ~ ${this.endDate.getFullYear()}年${this.endDate.getMonth() + 1}月`;
  }

  public isCurrent(): boolean {
    const now = new Date();
    return this.beginningDate <= now && now <= this.endDate;
  }

  public static fromResponse(period: PeriodResponse): Period {
    return new Period(period.periodId, new Date(period.beginningDate), new Date(period.endDate));
  }
}

export const usePeriodsQuery = () => {
  const result = defaultApi.endpoints.getPeriods.useQuery();
  return useQueryResult<GetPeriodsApiResponse, Period[]>({
    result,
    fromResponse: (response) => response.map(Period.fromResponse),
  });
};

export const useLatestPeriod = () => {
  const result = defaultApi.endpoints.getLatestPeriod.useQuery();
  return useQueryResult({
    result,
    fromResponse: Period.fromResponse,
  });
};

export const useCurrentPeriodQuery = (): QueryState<{ current?: Period; previous?: Period }> => {
  const result = defaultApi.endpoints.getPeriods.useQuery();
  return useQueryResult({
    result,
    fromResponse: (response) => ({
      current: response[0] ? Period.fromResponse(response[0]) : undefined,
      previous: response[1] ? Period.fromResponse(response[1]) : undefined,
    }),
  });
};

export const useCreatePeriod = () => {
  const [createPeriod] = defaultApi.endpoints.createPeriod.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: createPeriod,
  });
};

export const useCarryOverEmployeesIntoNextPeriod = () => {
  const [carryOver] = defaultApi.endpoints.carryOverEmployeesIntoNextPeriod.useMutation();
  return useMutationFunctionWrapper({
    mutationFn: carryOver,
  });
};
