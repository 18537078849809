/* tslint:disable */
/* eslint-disable */
/**
 * @amecloud/backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployeeReportSeriesItemData,
    EmployeeReportSeriesItemDataFromJSON,
    EmployeeReportSeriesItemDataFromJSONTyped,
    EmployeeReportSeriesItemDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmployeeReportSeriesItem
 */
export interface EmployeeReportSeriesItem {
    /**
     * 
     * @type {string}
     * @memberof EmployeeReportSeriesItem
     */
    name: string;
    /**
     * 
     * @type {EmployeeReportSeriesItemData}
     * @memberof EmployeeReportSeriesItem
     */
    data: EmployeeReportSeriesItemData;
}

export function EmployeeReportSeriesItemFromJSON(json: any): EmployeeReportSeriesItem {
    return EmployeeReportSeriesItemFromJSONTyped(json, false);
}

export function EmployeeReportSeriesItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeReportSeriesItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'data': EmployeeReportSeriesItemDataFromJSON(json['data']),
    };
}

export function EmployeeReportSeriesItemToJSON(value?: EmployeeReportSeriesItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'data': EmployeeReportSeriesItemDataToJSON(value.data),
    };
}


