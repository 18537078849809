import { DocumentResponse, SearchInterviewDocumentsByRawRuleRequest } from "@amecloud/api-client/src-openapi-autogen";

import { defaultApi } from "../defaultApi";

import { QueryState, useQueryResult } from "./utils/parseResult";
import { UseQueryOptions } from "./utils/types";

export interface DocumentModel {
  documentId: string;
  content: string;
}

export namespace DocumentModel {
  export const fromResponse = (document: DocumentResponse): DocumentModel => {
    return {
      documentId: document.documentId,
      content: document.content,
    };
  };
}

export namespace DocumentModels {
  export const fromResponse = (documents: DocumentResponse[]): DocumentModel[] => {
    return documents.map(DocumentModel.fromResponse);
  };
}

export const useSearchInterviewDocumentsByRawRule = (
  args: SearchInterviewDocumentsByRawRuleRequest,
  options?: UseQueryOptions,
): QueryState<DocumentModel[]> => {
  const result = defaultApi.endpoints.searchInterviewDocumentsByRawRule.useQuery(args);
  return useQueryResult({
    result,
    fromResponse: DocumentModels.fromResponse,
    onError: options?.onError,
  });
};
