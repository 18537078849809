type Splitter = "slash" | "japanese";

export const formatDate = (date: Date, splitter: Splitter = "slash"): string => {
  if (splitter === "slash") {
    return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getDate()).padStart(
      2,
      "0",
    )}`;
  }
  return `${date.getFullYear()}年${String(date.getMonth() + 1).padStart(2, "0")}月${String(date.getDate()).padStart(
    2,
    "0",
  )}日`;
};

export const formatDateToYYYYMMDD = (date: Date): string => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(
    2,
    "0",
  )}`;
};

const ONE_MINUTE = 60;
const ONE_HOUR = 60 * ONE_MINUTE;
export const formatTime = (timeBySecond: number): string => {
  const seconds = Math.floor(timeBySecond % ONE_MINUTE);
  const minutes = Math.floor((timeBySecond % ONE_HOUR) / ONE_MINUTE);
  if (timeBySecond < ONE_HOUR) {
    return `${minutes}分${seconds.toString().padStart(2, "0")}秒`;
  }
  const hours = Math.floor(timeBySecond / ONE_HOUR);
  return `${hours}時間${minutes.toString().padStart(2, "0")}分${seconds.toString().padStart(2, "0")}秒`;
};
